import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
  makeStyles,
  IconButton,
  Checkbox,
  Button
} from "@material-ui/core";
import {
  EditSharp,
  ExpandMore,
  ExpandLess,
  Remove,
  CloudDownloadOutlined
} from "@material-ui/icons";

import {
  getComparator,
  getDefaultStyles,
  stableSort,
} from "../../../../../services/table";
import DetailsRow from "./components/DetailsRow/DetailsRow";
import QRCode from "qrcode.react";
import { getQRCode } from "../../services/groups";
import KiosksDiagnosticReport from "./KiosksDiagnosticReport";
import { CHECK_IN_INSTRUCTIONS } from "app/common/constant";

const headCells = [
  {
    id: "location",
    numeric: false,
    disablePadding: false,
    label: "Location Name",
  },
  {
    id: "connected",
    numeric: false,
    disablePadding: false,
    label: "Connectivity",
  },
  { id: "power", numeric: false, disablePadding: false, label: "Power" },
  { id: "checkedIn", numeric: false, disablePadding: false, label: "Last Checked In" },


  { id: "actions", numeric: false, disablePadding: false, label: "Actions" },
];

function EnhancedTableHead({ onSelectAllClick, numSelected, rowCount }) {
  return (
    <TableHead id={`setup_locations_table_header`}>
      <TableRow id={`setup_locations_table_header_row`}>
        <TableCell id={`setup_locations_table_header_cell`} padding="checkbox" style={{ width: 45 }} />
        <TableCell id={`setup_locations_table_header_checkbox_cell
        `} padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            id={`setup_locations_table_header_${headCell.id}`}
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles(getDefaultStyles);

export default function SetupLocationsTable(props) {
  const classes = useStyles();
  const [order] = useState("asc");
  const [orderBy] = useState("name");
  const [page, setPage] = useState(props.page);
  const [selected, setSelected] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(props.rowsPerPage);

  const [downloadLocationId, setDownloadLocationId] = useState();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [locationId, setLocationId] = useState("");
  const [showQrCode, setShowQrCode] = useState(false);
  const { REACT_APP_DOWNLOAD_QRCODE_LOCATION_URL } = process.env;
  const {
    rows,
    preselected,
    onExpandRow,
    onEditRowClick,
    resetSelected,
    onSelectLocations,
    handleAction,
    pagination
  } = props;

  const[totalRows,setTotalRows]=useState(pagination.totalRecords)
  

  useEffect(()=>{
    setTotalRows(pagination.totalRecords);
    setPage(props.page)
    setRowsPerPage(props.rowsPerPage)
  },[pagination])

  /**
   * Reset selected customers after updating their group
   */
  useEffect(() => {
    if (!resetSelected) return;

    setSelected([]);
    onSelectLocations([]);
    // eslint-disable-next-line
  }, [resetSelected]);

  useEffect(() => {
    let newSelected = [];
    preselected.forEach((location) => {
      let didFind = rows.filter((l) => l.id == location.id);
      if (didFind.length != 0) {
        newSelected = newSelected.concat(didFind[0].id);
      }
    });
    setSelected(newSelected.concat(selected));
  }, []);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      onSelectLocations(rows.filter((l) => newSelected.includes(l.id)));
      return;
    }
      setSelected([])
      onSelectLocations([]);
      return
  };

  const handleClick = (event, id) => {
    event.stopPropagation();

    let newSelected = [];
    const selectedIndex = selected.indexOf(id);

    if (selectedIndex === -1) newSelected = newSelected.concat(selected, id);
    else if (selectedIndex === 0)
      newSelected = newSelected.concat(selected.slice(1));
    else if (selectedIndex === selected.length - 1)
      newSelected = newSelected.concat(selected.slice(0, -1));
    else if (selectedIndex > 0)
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );

    setSelected(newSelected);
    onSelectLocations(rows.filter((l) => newSelected.includes(l.id)));
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const handleChangePage = (event, newPage) => {
    props.handleChangePage(event, newPage)
  };

  const handleChangeRowsPerPage = (event) => {
    props.handleChangeRowsPerPage(event)
  };

  /**
   * Handle click on Edit icon
   * @param e
   * @param row
   */
  function onEditIconClick(e, row) {
    e.stopPropagation();

    onEditRowClick(row);
  }
  function downloadQRCodeForLocation(downloadLocationId, name) {
    setLocationId( `${REACT_APP_DOWNLOAD_QRCODE_LOCATION_URL}/checkin/${downloadLocationId}`);
    setShowQrCode(true);

    setTimeout(() => {
      getQRCode(downloadLocationId, name);
      setShowQrCode(false);
    }, 0);
  }

 

  /**
   * Handle click on Edit icon
   * @param e
   */
  function onSettingsClick(e, row) {
    e.stopPropagation();
    setDownloadLocationId([row.id, row.name]);
    downloadQRCodeForLocation(row.id, row.name);
    setAnchorEl(anchorEl ? null : e.currentTarget);
  }


  return (
    <>
    <div id={`setup_locations_table_root_wrapper`} className={classes.root}>
      <Paper d={`setup_locations_table_paper_wrapper`} className={classes.paper}>
        <TableContainer d={`setup_locations_table_container_wrapper`}>
          <Table
          d={`setup_locations_table_inner_wrapper`}
            className={classes.table}
            aria-labelledby="tableTitle"
            size="small"
            aria-label="enhanced table"
            >
            <EnhancedTableHead
              numSelected={selected.length}
              onSelectAllClick={handleSelectAllClick}
              rowCount={rows.length}
            />
            <TableBody id={`setup_locations_table_body_wrapper`}>
              {stableSort(rows, getComparator(order, orderBy))
                .map((row) => {
                  const {
                    id,
                    name,
                    connected,
                    power,
                    kiosks,
                    isExpanded,
                  } = row;
                  const isItemSelected = isSelected(id);
                  const labelId = `enhanced-table-checkbox-${id}`;

                  return (
                    <React.Fragment key={id}>
                      <TableRow
                        id={`setup_locations_table_row_${id}`}
                        hover
                        tabIndex={-1}
                        selected={isItemSelected}
                        onClick={() =>
                          kiosks && kiosks.length && onExpandRow(row)
                        }
                      >
                        <TableCell  id={`setup_locations_table_icon_cell_${id}`} align="left" padding="checkbox">
                          {kiosks && kiosks.length ? (
                            <IconButton  id={`setup_locations_table_edit_icon_${id}`}>
                              {isExpanded ? <ExpandLess /> : <ExpandMore />}
                            </IconButton>
                          ) : null}
                        </TableCell>
                        <TableCell
                          id={`setup_locations_table_checkbox_cell_${id}`}
                          padding="checkbox"
                          onClick={(event) => handleClick(event, id)}
                        >
                          <Checkbox
                            id={`setup_locations_table_checkbox_input_cell_${id}`}
                            checked={isItemSelected}
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        </TableCell>
                        <TableCell  id={`setup_locations_table_namr_cell_${id}`} align="left" padding="normal">
                          {name}
                        </TableCell>
                       
                       {!isExpanded ?<KiosksDiagnosticReport kiosks={kiosks}/> : 
                        <>
                        <TableCell><Remove /></TableCell>
                        <TableCell><Remove /></TableCell>
                        </>
                       }
                           

                        <TableCell align="left" padding="normal">
                       
                          <Button 
                           id={`setup_locations_table_download_qr_button_${id}`} 
                            size="large"
                            variant="outlined"
                            color="primary"
                            onClick={(e) => onSettingsClick(e, row)}
                            >
                             <CloudDownloadOutlined style={{marginRight: 6}} /> {CHECK_IN_INSTRUCTIONS} </Button>
                            
                          <IconButton  id={`setup_locations_table_edit_icon_button_${id}`}  onClick={(e) => onEditIconClick(e, row)}>
                            <EditSharp />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                      {isExpanded ? (
                        <DetailsRow
                          onExpandRow={onExpandRow}
                          kiosks={kiosks}
                          handleAction={handleAction}
                        />
                      ) : null}
                    </React.Fragment>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          id={`setup_locations_table_pagination`}
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={totalRows}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
    {showQrCode && (
          <div id={`setup_locations_table_qr_code`} style={{ display: "none" }}>
            <QRCode id="qrCodeEl" size={200} value={locationId} />
          </div>
        )}
      </>
  );
}
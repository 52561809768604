import React, { useEffect, useState } from "react";
import {
  Dialog,
  FormControl,
  Button,
  DialogActions,
  DialogContent,
  TextField,
  Box,
  FormHelperText,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useLocation } from "react-router-dom";
import * as Sentry from '@sentry/react';
import to from 'await-to-js';

import ResponseModal from 'app/components/Helpers/ResponseModal';
import { useCustomResponse } from "app/hooks/useCustomResponse";

import { addingBillValidator, getLocations2 } from '../../services/locations';
import styles from "./add-ons.module.scss";
import { ERROR_MSG_WHILE_CREATING_BILL_VALIDATOR, ERROR_MSG_WHILE_GETTING_COOLER_DETAILS, ERROR_MSG_WHILE_FETCHING_SETUP_LOCATIONS, KIOSK_PARAM, LOCATION_PARAM, PATH_NAME_CASH_COLLECTIONS, CANCEL_TOKEN, ADD_BILLVALIDATOR_DELAY } from "app/common/constant";
import useDebounce from "app/hooks/useDebounce";

export default function AddBillValidator(props) {
  const { setAddLockPopup, proceedBillValidators } = props;
  const [searchLocationText, setSearchLocationText] = useState('');
  const [locations, setLocations] = useState([]);
  const pathName = useLocation().pathname;
  const [kioskList, setKioskList] = useState([]);
  const [accountId, setAccountId] = useState("");
  const [kioskId, setKioskId] = useState("");
  const [locationId, setLocationId] = useState("");
  const [requiredFields, setRequiredFields] = useState(false);

  const [responseValue, setResponseValue] = useCustomResponse();
  const Searchlocationquery = useDebounce(searchLocationText, ADD_BILLVALIDATOR_DELAY); 
  

  useEffect(() => {
      getLocationsList()
  }, [Searchlocationquery])
 

  /**
     * Get list of locations
     */
  async function getLocationsList() {
    const [err, res] = await to(getLocations2(true, true, Searchlocationquery));
    if (err) {
      if(err.message === CANCEL_TOKEN)return;
      Sentry.captureException(err);
      setResponseValue({...responseValue,
        isSuccess:false,
        isMessageOpen: true,
        message:`${ERROR_MSG_WHILE_GETTING_COOLER_DETAILS} ${err}`,
      })
      return console.error(ERROR_MSG_WHILE_FETCHING_SETUP_LOCATIONS, err);
    }
    setLocations(res.data.Locations);
  }

  function getKiosksList(value) {
    let list = [];
    if (value && value.kiosks) {
      list = value.kiosks.map((element) => element)
    }
    setKioskList(list);
    setKioskId("");
  }

  function addBillValidatorsDetails(name, value) {
    let val = value ?? ""
      if(name === LOCATION_PARAM){
        setLocationId(val.id);
        setAccountId(val.accountId);
      }
      else if(name === KIOSK_PARAM){
        setKioskId(val.id);
      }
  }

  async function handleCreateClick() {
    setRequiredFields(false);
    if(!locationId || !kioskId){
      setRequiredFields(true);
      return;
    }
    if(pathName.includes(PATH_NAME_CASH_COLLECTIONS)){
      const [err, res] = await to(addingBillValidator(accountId, locationId, kioskId));

      if(err){
        setResponseValue({
          ...responseValue,
          isSuccess:false,
          isMessageOpen: true,
          message:`${ERROR_MSG_WHILE_CREATING_BILL_VALIDATOR} ${err}`,
        })
        return console.error(ERROR_MSG_WHILE_CREATING_BILL_VALIDATOR, err);
      }
      const {success,message} = res?.data
      setResponseValue({
        ...responseValue,
        isMessageOpen: true,
        isSuccess:success,
        message:message,
      })
      proceedBillValidators();
    }
  }

  return (
    <>
      <Dialog
        id="add_bill_validator_dialog_wrapper"
        open
        style={{zIndex:"2"}}
        onClose={() => setAddLockPopup(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent id="add_bill_validator_dialog_content">
          <Box
            id="add_bill_validator_dialog_content_inner_wrapper_0"
            sx={{
              width: 500,
              height: 55,
              maxWidth: "100%",
              marginBottom: 30,
            }}
          >
            <FormControl id="add_bill_validator_dialog_content_form_control_0" fullWidth className={styles.tierHeader}>
              <Autocomplete
                id="billValidator_location"
                options={locations}
                getOptionLabel={(option) => option.name}
                onChange={(e, value) => {
                    addBillValidatorsDetails(LOCATION_PARAM,value);
                    getKiosksList(value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    id="billValidator_locationSearch"
                    label="Location"
                    placeholder="Location Search"
                    variant="outlined"
                    onChange={(e) => setSearchLocationText(e.target.value)}
                    error={!locationId && requiredFields}
                    helperText={!locationId && requiredFields && (
                      <FormHelperText className={styles.formHelperTextStyle}>
                        Location is Required
                      </FormHelperText>
                    )}
                  />
                )}
              />
            </FormControl>
          </Box>

          <Box
            id="add_bill_validator_dialog_content_inner_wrapper_1"
            sx={{
              width: 500,
              height: 55,
              maxWidth: "100%",
              marginBottom: 20,
            }}
          >
            <FormControl id="add_bill_validator_dialog_content_form_control_1" fullWidth className={styles.tierHeader}>
              <Autocomplete
                id="billValidator_kiosk"
                options={kioskList}
                value={kioskList.find((v) => v.id === kioskId) || ""}
                getOptionLabel={(option) => option.name}
                onChange={(e, value) => addBillValidatorsDetails(KIOSK_PARAM, value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    id="billValidator_kioskSearch"
                    label="Kiosk"
                    placeholder="Kiosk Search"
                    variant="outlined"
                    error={!kioskId && requiredFields}
                    helperText={!kioskId && requiredFields && (
                      <FormHelperText className={styles.formHelperTextStyle}>
                        Kiosk is Required
                      </FormHelperText>
                    )}
                  />
                )}
              />
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions id="add_bill_validator_dialog_action_button_wrapper" style={{ justifyContent: "center" }}>
          <Button
            id="add_bill_validator_dialog_action_cancel_button"
            variant="contained"
            size="large"
            color="primary"
            onClick={() => setAddLockPopup(false)}
          >
            Cancel
          </Button>
          <Button 
            id="add_bill_validator_dialog_action_create_button"
            variant="contained" 
            size="large" 
            color="primary"
            onClick={() => handleCreateClick()}
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
      <ResponseModal
        isSuccess={responseValue?.isSuccess}
        message={responseValue?.message}
        open={responseValue?.isMessageOpen}
        onClose={value => {
          setResponseValue({...responseValue,isMessageOpen:value});
          setAddLockPopup(false);
        }}
      />
    </>
  );
}

/**
 * Create React App entry point. This and `public/index.html` files can not be
 * changed or moved.
 */
// eslint-disable-next-line import/no-extraneous-dependencies
import 'react-app-polyfill/ie11';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import App from './App';
import { setupAxios } from './_metronic';
import store, { persistor } from './app/store/store';

import './index.scss'; // Standard version
import 'socicon/css/socicon.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './_metronic/_assets/plugins/line-awesome/css/line-awesome.css';
import './_metronic/_assets/plugins/flaticon/flaticon.css';
import './_metronic/_assets/plugins/flaticon2/flaticon.css';
/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { REACT_APP_API_URL, PUBLIC_URL } = process.env;

axios.defaults.baseURL = REACT_APP_API_URL;
axios.defaults.headers.common['Content-Type'] = 'application/json';
/* Should not pass Custom header from Client to override Access-Control Headers */
// axios.defaults.headers.common['Access-Control-Allow-Origin'] = 'https://yokeportal.com';
// axios.defaults.headers.common['Access-Control-Allow-Credentials'] = 'true';

/**
 * Inject metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
setupAxios(axios, store);

Sentry.init({
  dsn: "https://1552609f8b894006957263838cfea750@o47564.ingest.sentry.io/5972913",
  integrations: [new Integrations.BrowserTracing()],

// Set tracesSampleRate to 1.0 to capture 100%
// of transactions for performance monitoring.
// We recommend adjusting this value in production
tracesSampleRate: 1.0,
});

ReactDOM.render(
  // eslint-disable-next-line react/jsx-filename-extension
  <App
    store={store}
    persistor={persistor}
    basename={PUBLIC_URL}
  />,
  document.getElementById('root')
);

/* eslint-disable no-script-url */
import React, {useEffect, useState } from 'react';
import { Nav, Tab, Dropdown } from 'react-bootstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useRef } from 'react';
import * as Sentry from '@sentry/react';

import { getNotifications, readNotifications } from 'app/services/notifications';

import HeaderDropdownToggle from '../content/CustomDropdowns/HeaderDropdownToggle';
import { ReactComponent as CompilingIcon } from '../../../_metronic/layout/assets/layout-svg-icons/Compiling.svg';

var moment = require('moment');




export default function UserNotifications(props) {
  const {
    iconType,
    pulse,
    skin,
    bgImage,
    type,
    useSVG,
    icon
  } = props;
  const [key, setKey] = useState('All');
  const [notifications, setNotifications] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [pagination, setPagination] = useState({
  page: 1,
  limit: 10
});

const toggleDropdown = useRef(null);

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false
};

function fetchNotifications() {
  Promise.all([getNotifications(pagination)])
  .then(responses => {
    if (responses && responses[0].data) {
      const { Notif } = responses[0].data;
      setNotifications(responses[0].data.notifications.rows)
    }
  })
  .then(() => {
    setIsLoaded(true);
  })
  .catch(err => {
    Sentry.captureException(err);
    console.error('Error with filters fetching: ', err);
  });
}


  /**
   * Fetch filters' options on component mounting
   */
   useEffect(() => {
    if (isLoaded)
      return;

    fetchNotifications();
  }, []);

  function getHeaderTopBarCssClassList() {
    let result = 'kt-header__topbar-icon ';

    if (pulse)
      result += 'kt-pulse kt-pulse--brand ';

    if (iconType)
      result += `kt-header__topbar-icon--${iconType}`;

    return result;
  }

  function getSvgCssClassList() {
    let result = 'kt-svg-icon ';

    if (iconType)
      result += `kt-svg-icon--${iconType}`;

    return result;
  }

  function getHetBackGroundCssClassList() {
    let result = 'kt-head ';

    if (skin)
      result += `kt-head--skin-${skin} `;

    result += 'kt-head--fit-x kt-head--fit-b';

    return result;
  }

  function backGroundStyle() {
    if (!bgImage)
      return 'none';

    return `url(${bgImage})`;
  }

  function userNotificationsButtonCssClassList() {
    let result = 'btn ';
    if (type)
      result += `btn-label-${type} `;

    result += 'btn-sm btn-bold btn-font-md';
    return result;
  }

  function ulTabsClassList() {
    let result = 'nav nav-tabs nav-tabs-line nav-tabs-bold nav-tabs-line-3x  ';

    if (type)
      result += `nav-tabs-line-${type} `;

    result += 'kt-notification-item-padding-x';

    return result;
  }

  return (
    <Dropdown className="kt-header__topbar-item" drop="down" alignRight>
      <Dropdown.Toggle
        as={HeaderDropdownToggle}
        id="dropdown-toggle-user-notifications"
        ref={toggleDropdown}
      >
        <span className={getHeaderTopBarCssClassList()}>
          {!useSVG && <i className={Boolean(notifications.filter(n => !n.read).length) ? "flaticon2-notification" : icon}
          />}

          {useSVG && (
            <span className={getSvgCssClassList()}>
              <CompilingIcon/>
            </span>
          )}

          <span className="kt-pulse__ring" hidden={!pulse}/>
          <span className="kt-hidden kt-badge kt-badge--dot kt-badge--notify kt-badge--sm"/>

          <span className="kt-menu__item-here"/>

          <span className="kt-menu__link-text">Notifications</span>
        </span>
      </Dropdown.Toggle>

      <Dropdown.Menu
        className="dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-lg"
      >
        <form>
          {/** Head */}
          <div
            className={getHetBackGroundCssClassList()}
            style={{ backgroundImage: backGroundStyle() }}
          >
            <h3 className="kt-head__title">
              User Notifications
              {'   '}
              <span className={userNotificationsButtonCssClassList()}>
                {notifications.filter(n => !n.read).length} New
              </span>
            </h3>

            <Tab.Container
              defaultActiveKey={key}
              className={ulTabsClassList()}
            >
              <Nav
                className={ulTabsClassList()}
                onSelect={e => setKey(e)}
              >
                <Nav.Item className="nav-item">
                  <Nav.Link eventKey="All" className="nav-link show">
                    All
                  </Nav.Link>
                </Nav.Item>
              </Nav>

              <Tab.Content>
                <Tab.Pane eventKey="All">
                  <PerfectScrollbar
                    options={perfectScrollbarOptions}
                    style={{ maxHeight: '100vh', position: 'relative' }}
                  >
                    <div
                      className="kt-notification kt-margin-t-10 kt-margin-b-10"
                      style={{ maxHeight: '40vh', position: 'relative' }}
                    >
                      <div
                        className="kt-notification kt-margin-t-10 kt-margin-b-10 kt-scroll"
                        data-scroll="true"
                        data-height="300"
                        data-mobile-height="200"
                      >
                        
                        {notifications.map((notification) => {     
                        
                        return (
                          <a key={"{notification}"+notification?.id} className="kt-notification__item"
                            style={{
                              backgroundColor: notification?.read ? undefined : "#0096FF"
                            }}
                            onClick={async () => {
                              if(toggleDropdown?.current){
                                toggleDropdown.current.click();
                              }
                             if(!notification?.read){
                              await readNotifications(notification.id);
                              fetchNotifications();
                             }
                             
                            }}
                          >
                          <div className="kt-notification__item-icon">
                            <i className="flaticon2-line-chart kt-font-success"/>
                          </div>
                          <div className="kt-notification__item-details">
                            <div className="kt-notification__item-title">
                              {notification.message}
                            </div>
                            <div className="kt-notification__item-time">
                              {moment(notification.creationDate).fromNow()}
                            </div>
                          </div>
                        </a>
                        ) 
                      })}
                      </div>
                    </div>
                  </PerfectScrollbar>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </form>
      </Dropdown.Menu>
    </Dropdown>
  );
}

import axiosInstance from "app/services/helpers/axiosInstance";
import axios from "axios";
import poster from "./../11x17.png";
import mergeImages from "merge-images";
import { resizeBase64Image } from "app/common/utils/resizeBase64Image";

const mockedGroups = [
  {
    id: 1,
    name: "Group 1",
    locations: 5,
  },
  {
    id: 2,
    name: "Group 2",
    locations: 54,
  },
  {
    id: 3,
    name: "Group 3",
    locations: 123,
  },
  {
    id: 4,
    name: "Group 4",
    locations: 71,
  },
  {
    id: 5,
    name: "Group 5",
    locations: 4,
  },
];

export function getGroups() {
  return mockedGroups;
}

export function getGroupById(id) {
  return mockedGroups.find((g) => g.id === id);
}

/**
 * Get group by id
 * @param id
 */
export function getGroupById2(id) {
  return axios.get("/groups/" + id);
}

/**
 * Update group by id
 * @param id
 */
export function updateGroup(id, payload) {
  return axios.patch("/groups/" + id, payload);
}

/**
 * create location group
 * @param id
 */
export function createNewLocationGroup(accountId, payload) {
  return axios.post(`/accounts/${accountId}/groups/`, payload);
}

// Update Location Group
export function updateLocationGroup(accountId, groupId, payload) {
  return axiosInstance.patch(
    `/accounts/${accountId}/groups/${groupId}`,
    payload
  );
}

// Delete Location Group
export function deleteLocationGroup(accountId, groupId) {
  return axiosInstance.delete(`/accounts/${accountId}/groups/${groupId}`);
}

export const getQRCode = async (downloadLocationId, name) => {
  const qrCodeURL = document
    .getElementById("qrCodeEl")
    .toDataURL("image/png")
    .replace("image/png", "image/octet-stream");
  const dataurl = await resizeBase64Image(qrCodeURL);
  mergeImages([
    { src: poster, x: 0, y: 0 },
    { src: dataurl, x: 2602, y: 3208 },
  ]).then((b64) => {
    let aEl = document.createElement("a");
    aEl.href = b64;
    aEl.download = `${name}_QR_Code.png`;
    document.body.appendChild(aEl);
    aEl.click();
    document.body.removeChild(aEl);
  });
};

export const subHeaderActionTypes = {
  StoreSelectedOperator: "[Set Selected Operator] Action",
  StoreSelectedLocation: "[Set Selected Location] Action",
  ClearSelectedOperator: "[Clear Selected Operator] Action",
  ClearSelectedLocation: "[Clear Selected Location] Action",
  StoreLoyaltyProgram: "[Store Loyalty Program] Action",
  StoreCompressedLocations: "[Store Compressed Locations] Action",
  StoreOperators: "[Store Operators] Action",
  StoreLocations: "[Store Locations] Action",
  storeOperatorAndLocationData:"[Store Operator and Locations Data] Action",
  clearOperatorAndLocationData: "[clearOperatorAndLocationData] Action",

};

export const subHeaderActions = {
  storeSelectedOperator: (operator) => ({
    type: subHeaderActionTypes.StoreSelectedOperator,
    payload: operator,
  }),
  storeSelectedLocation: (location) => ({
    type: subHeaderActionTypes.StoreSelectedLocation,
    payload: location,
  }),
  clearSelectedOperator: () => ({
    type: subHeaderActionTypes.ClearSelectedOperator,
    payload: null,
  }),
  clearSelectedLocation: () => ({
    type: subHeaderActionTypes.ClearSelectedLocation,
    payload: null,
  }),
  storeLoyaltyProgram: (loyaltyProgram) => ({
    type: subHeaderActionTypes.StoreLoyaltyProgram,
    payload: loyaltyProgram,
  }),
  storeCompressedLocations: (compressedLocations) => ({
    type: subHeaderActionTypes.StoreCompressedLocations,
    payload: compressedLocations,
  }),
  storeOperators: (operators) => ({
    type: subHeaderActionTypes.StoreOperators,
    payload: operators,
  }),
  storeLocations: (locations) => ({
    type: subHeaderActionTypes.StoreLocations,
    payload: locations,
  }),
  storeOperatorAndLocationData: (storedOperatorAndLocations) => ({
    type: subHeaderActionTypes.storeOperatorAndLocationData,
    payload: storedOperatorAndLocations,
  }),
  clearOperatorAndLocationData: () => ({
    type: subHeaderActionTypes.clearOperatorAndLocationData,
    payload: [],
  }),
};

import AWS from 'aws-sdk';

/**
 * @ 
 */
export const { REACT_APP_API_URL, REACT_APP_AWS_S3_ACCESSKEYID, REACT_APP_AWS_S3_SECRETACCESSKEY, REACT_APP_AWS_S3_REGION, REACT_APP_AWS_S3_SIGNATUREVERSION, REACT_APP_AWS_S3_BUCKET } = process.env;
/**
 *  @abstract AWS INSTANCE
 * */
AWS.config.update({
    accessKeyId: REACT_APP_AWS_S3_ACCESSKEYID,
    secretAccessKey: REACT_APP_AWS_S3_SECRETACCESSKEY,
    region: REACT_APP_AWS_S3_REGION,
    signatureVersion: REACT_APP_AWS_S3_SIGNATUREVERSION,
  });
  
export const s3 = new AWS.S3();
  
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import to from 'await-to-js';
import {
  InputLabel,
  Button,
  Typography,
  FormControlLabel,
  Switch,
  TextField,
  InputAdornment
} from '@material-ui/core';
import { CloudUpload } from '@material-ui/icons';
import * as Sentry from "@sentry/react";

import { getLocationTheme,updateLocationTheme,uploadLogo } from 'app/services/locations';
import { useCustomResponse } from 'app/hooks/useCustomResponse';
import ResponseModal from 'app/components/Helpers/ResponseModal';
import { uploadImageToAws } from 'app/common/uploadImageToAws';

import styles from './theme.module.scss';
import ColorPickerInput from 'app/components/Helpers/ColorPicker';
import { useSelector } from 'react-redux';

export default function Theme(props) {
  const {
    ThemeId,
  } = props;
  const [theme, setTheme] = useState({
    id: '4f98e785-4dff-4353-8f61-ac3b394bb189',
    type: 'kiosk',
    KioskId: null,
    LocationId: null,
    scanButtonHidden: false,
    lookupButtonHidden: false,
    disableCart: false,
    disableCreditCardPurchases: false,
    coolerButtonHidden: false,
    disableApplePay: true,
    primaryColor: '#3E6F7E',
    secondaryColor: '#E1D3AA',
    bannerColor: '#262B2A',
    creationDate: '2020-09-23T12:11:13.024Z',
    updatedOn: '2020-09-23T12:11:14.197Z',
    deletionDate: null
  });

  const [responseValue, setResponseValue] = useCustomResponse()
  const selectedLocation = useSelector((state)=> state?.subHeader?.selectedLocation)

  useEffect(() => {
    {ThemeId && getTheme()}
  }, [ThemeId])

async function getTheme() {
  const [err, res] = await to(getLocationTheme(ThemeId))
  if (err){
    Sentry.captureException(err);
    setResponseValue({...responseValue, isMessageOpen: true, isSuccess:false,message:`Error while getting theme. ${err}`});
    return console.error(err)
  }   
  setTheme(res.data)
}

async function onSave() {
  let payload = {...theme, LocationId: selectedLocation}
  delete payload.KioskId
  delete payload.updatedOn
  delete payload.creationDate
  delete payload.id
  delete payload.deletionDate
  delete payload.LocationId
  delete payload.type
  delete payload.name      // there is no usage of name in payload (YK-1056)

  const [err, res] = await to(updateLocationTheme(ThemeId, payload))

  if (err){
    Sentry.captureException(err);
    setResponseValue({...responseValue, isMessageOpen: true, isSuccess:false, message:`We had issue updating your theme. ${err}`})
    return console.error(`Couldn't update theme ${err}`)
  } else {
    setResponseValue({...responseValue, isMessageOpen: true, isSuccess:true, message:`Updated theme!`})
  }
}
const randomId = function(length = 6) {  return Math.random().toString(36).substring(2, length+2);};
async function uploadLogoOnClick(event) {
  let [file] = event?.target?.files
  const locationId = theme && theme.LocationId ? theme?.LocationId : selectedLocation
  const [error, createImageRes] = await to(uploadLogo(locationId, ThemeId));
  const [err1, res] = await to(uploadImageToAws(file,createImageRes.data.uploadKey+randomId()));
  setTheme({...theme,meta:{
    ...theme.meta,logo : res
  }})
}
  return (
    <div  id={`theme_outer_wrapper`} className="row">
      <div  id={`theme_outer_inner_wrapper`} className={clsx('d-flex', 'col-12', styles.wrapper)}>
        <div id={`theme_wrapper`} className={clsx(styles.card, 'text-center')}>
          {theme?.meta?.logo ? (
            <div  id={`theme_logo_wrapper`}  className={styles.logoWrapper}>
              <img id={`theme_img_wrapper`}  className={styles.logo} src={theme?.meta?.logo} alt="Theme logo"/>
              <Button
               id={`theme_change_logo_button`} 
              className={styles.button}
              startIcon={<CloudUpload/>}
              size="small"
              variant="outlined"
              component="label"
            >
              Change Logo
              <input
                 id={`theme_change_input_logo`} 
                type="file"
                style={{ display: 'none' }}
                onChange={uploadLogoOnClick}
              />
            </Button>
            </div>
          ) : (
            <Button
              id={`theme_upload_logo_button`} 
              className={styles.button}
              startIcon={<CloudUpload/>}
              size="small"
              variant="outlined"
              component="label"
            >
              Upload Logo       
              <input
                id={`theme_change_upload_logo_input`} 
                type="file"
                accept="image/*"
                style={{ display: 'none' }}
                onChange={uploadLogoOnClick} // YK - 1123,  disabled until it is fixed  
              />
            </Button>
          )} 
        </div>
        <div id={`theme_color_picker`}  className={styles.card}>
          <InputLabel id={`theme_color_picker_input_primary_label`}>
            Primary Color
          </InputLabel>
           <ColorPickerInput 
              value={theme?.primaryColor}
              onChange={e => setTheme({ ...theme, primaryColor: e.target.value })}
            />
        </div>
        <div id={`theme_color_picker_1`}  className={styles.card}>
          <InputLabel id={`theme_color_picker_input_secondary_label`} >
            Secondary Color
          </InputLabel>
            <ColorPickerInput 
              value={theme?.secondaryColor}
              onChange={e => setTheme({ ...theme, secondaryColor: e.target.value })}
            />
        </div>
        <div id={`theme_color_picker_2`}  className={styles.card}>
          <InputLabel id={`theme_color_picker_input_bannar_label`} >
            Banner Color
          </InputLabel>
            <ColorPickerInput 
              value={theme?.bannerColor}
              onChange={e => setTheme({ ...theme, bannerColor: e.target.value })}
            />
        </div>
        <div id={`theme_color_picker_input_secondary_label`}  className={styles.switchBlock}>
          <div id={`theme_color_picker_input_form_wrapper`} >
            <Typography id={`theme_color_picker_input_form_hide_scan_wrapper`} variant="body1">
              Hide Scan Button
            </Typography>
            <FormControlLabel
              id={`theme_color_picker_input_form_control_switch_showing_label`}
              className={styles.switch}
              control={(
                <Switch
                  id={`theme_color_picker_input_form_control_switch_input_label`}
                  checked={theme.scanButtonHidden}
                  onChange={(e, checked) => setTheme({ ...theme, scanButtonHidden: checked })}
                  color="primary"
                />
              )}
              label={theme.scanButtonHidden ? 'Hidden' : 'Showing'}
            />
          </div>
          <div>
            <Typography id={`theme_color_picker_input_hide_look_up_label`} variant="body1">
              Hide Look up Button
            </Typography>
            <FormControlLabel
             id={`theme_color_picker_input_hide_look_up_switch_theme`}
              className={styles.switch}
              control={(
                <Switch
                  checked={theme.lookupButtonHidden}
                  onChange={(e, checked) => setTheme({ ...theme, lookupButtonHidden: checked })}
                  color="primary"
                />
              )}
              label={theme.lookupButtonHidden ? 'Hidden' : 'Showing'}
            />
          </div>
        </div>
        <div className={styles.switchBlock}>
          <div>
            <Typography variant="body1">
              Disable Cart
            </Typography>
            <FormControlLabel
              className={styles.switch}
              control={(
                <Switch
                  checked={theme.disableCart}
                  onChange={(e, checked) => setTheme({ ...theme, disableCart: checked })}
                  color="primary"
                />
              )}
              label={theme.disableCart ? 'Disabled' : 'Enabled'}
            />
          </div>
          <div>
            <Typography variant="body1">
              Disable Credit Card Purchases
            </Typography>
            <FormControlLabel
              className={styles.switch}
              control={(
                <Switch
                  checked={theme.disableCreditCardPurchases}
                  onChange={(e, checked) => setTheme({ ...theme, disableCreditCardPurchases: checked })}
                  color="primary"
                />
              )}
              label={theme.disableCreditCardPurchases ? 'Disabled' : 'Enabled'}
            />
          </div>
        </div>
        <div id={`theme_color_picker_switch_block`} className={styles.switchBlock}>
          <div id={`theme_color_picker_switch_inner_block`}>
            <Typography id={`theme_color_picker_switch_inner_body_block`} variant="body1">
              Hide Cooler Button
            </Typography>
            <FormControlLabel
              id={`theme_color_picker_switch_cooler_body_block`}
              className={styles.switch}
              control={(
                <Switch
                  checked={theme.coolerButtonHidden}
                  onChange={(e, checked) => setTheme({ ...theme, coolerButtonHidden: checked })}
                  color="primary"
                />
              )}
              label={theme.coolerButtonHidden ? 'Hidden' : 'Showing'}
            />
          </div>
        </div>
      </div>
      <ResponseModal
      isSuccess={responseValue?.isSuccess}
      message={responseValue?.message}
      open={responseValue?.isMessageOpen}
      onClose={value => setResponseValue({...responseValue, isMessageOpen:value})}
      />
      <Button
        id={`theme_color_picker_save_button`}
          className={styles.saveButton}
          size="large"
          variant="outlined"
          color="primary"
          disableElevation
          onClick={() => onSave()}
        >
          Save
        </Button>
    </div>
  );
}

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import to from 'await-to-js';
import csvDownload from 'json-to-csv-export';
import { format } from "date-fns";
import 'jspdf-autotable'
import { jsPDF } from "jspdf";
import * as Sentry from "@sentry/react";

import ResponseModal from 'app/components/Helpers/ResponseModal';
import { accessPermission } from 'app/common/helperFunction';
import OperatorSpecificModal from 'app/components/Helpers/OperatorSpecificModal';
import LoadingSpinner from 'app/partials/content/LoadingSpinner';
import { useCustomResponse } from 'app/hooks/useCustomResponse';

import ExportAndCustomFilters from '../../../../partials/content/ExportAndCustomFilters';
import Search from '../../../../partials/content/Customers/Search';
import TabsTable from '../../../../partials/content/Tabs/TabsTable';
import CloseTab from './components/CloseTab/CloseTab';
import EditLimit from './components/EditLimit/EditLimit';
import { archiveTab, closeTab, getTabsForAccount, updateTabLimit } from '../../services/customers';
import { getLoggedUserAccountId2, hasMultipleAccounts } from './../../../../services/helpers/account-id'

import styles from './tabs.module.scss';

export default function Tabs() {
  const {push} = useHistory();
  const [customers, setCustomers] = useState([]);
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [resetSelected, setResetSelected] = useState(false);
  const [tabToEdit, setTabToEdit] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);
  const [operatorSpecificModalOpen, setOperatorSpecificModalOpen] = useState(false);
  const [responseValue, setResponseValue] = useCustomResponse();

  useEffect(() => {
    if(!accessPermission("CustomersAll")){
      push({pathname: "/dashboard"});
    }
    getTabs();
  }, [])

  /**
   * Reset selected customers on moving to group
   */
  useEffect(() => {
    if (!resetSelected)
      return;

    setResetSelected(false);
  }, [resetSelected]);

  /**
   * Handler for CloseTabs callback
   * @param e
   */
  async function onCloseTabs(charge, customers) {  
    await customers.forEach(async customer => {
      const [err, res] = await to(closeTab(charge, 
        customer.tab.balance,
        "840247d9-25d8-4313-9c58-1faec38ae573",
        customer))

        if(err){
          Sentry.captureException(err);
        }

    })

    setResetSelected(true);
  }

  /**
   * Edit limit for selected row
   * @param e
   */
  async function onEditLimit(row, e) {
    if (!e || isNaN(e))
      return setTabToEdit({});
       
    const [err, res] = await to(updateTabLimit(getLoggedUserAccountId2(), row.tab.tabId, parseFloat(e)))

    if (err){
      Sentry.captureException(err);
      setResponseValue({
        ...responseValue,  
        isMessageOpen: true,
        isSuccess: false,
        message:`Error while updating limit. ${err}`
      });
      return console.error(err);
    } 
    
    const customerCopy = customers.filter(c => c.id = row.id)[0]
    customerCopy.tab.limit = e
    // TODO: update limit here
    setTabToEdit({});
  }

  async function getTabs() {
    if(hasMultipleAccounts()){
      setOperatorSpecificModalOpen(true);
      return
    }

    const [err, res] = await to(getTabsForAccount(getLoggedUserAccountId2()))

    setIsLoaded(true);
    if (err){
      Sentry.captureException(err);
      setResponseValue({
        ...responseValue,  
        isMessageOpen: true,
        isSuccess: false,
        message:`Error while getting tabs. ${err}`
      });
      return console.log(err);
    }

    setCustomers(res.data.updatedUsers);
  }

  /**
   * Archive row
   * @param e
   */
  async function onArchiveRow(e) {
    const [err, res] = await to(archiveTab(getLoggedUserAccountId2(),e.tab.tabId))

    if (err){
      Sentry.captureException(err);
      setResponseValue({
        ...responseValue,  
        isMessageOpen: true,
        isSuccess: false,
        message:`Error while archiving tab. ${err}`
      });
      return console.error(err);
    } 
    setCustomers(customers.filter(c => c.email !== e.email));
  }

  async function handleExport(type){
    const [err, res] = await to(getTabsForAccount(getLoggedUserAccountId2()))

    if (err)
      return console.error(err)

    const rows = res.data.updatedUsers;

    if(type === 'pdf'){

      const exportRows = rows.map(ele => [
       ele.email? ele.email: '',
        ele.firstName? ele.firstName: '',
        ele.lastName? ele.lastName: '' ,
        ele.tab.balance? ele.tab.balance: '0',
        ele.tab.limit === 0 ? 'None' : `$ ${ele.tab.limit}`,
        ele.tab.status == 0 ? "Good" : ele.tab.status == 1 ? "Approaching Limit": "Delinquent",
       ]);

        const unit = "pt";
        const size = "A4";
        const orientation = "portrait";
        const marginLeft = 30;
        const doc = new jsPDF(orientation,unit, size);
        doc.setFontSize(15);

        const title = "Tabs";
        const headers = [['Email', 'First Name', 'Last Name', 'Balance', 'Limit', 'Status']];

        let content = {
          startY: 50,
          head: headers,
          body: exportRows,
          styles: {overflow: 'linebreak',
                fontSize: 8},
        };

        doc.text(title, marginLeft, 30);
        doc.autoTable(content);
        doc.save(`Tabs - ${format(new Date(), 'MM/dd/yyyy')}.${type}`);

    } else if (type === 'csv') {

        const exportRows = [];
        rows.forEach(ele => {
          let obj = {
            Email : ele.email? ele.email: '',
            'First Name' : ele.firstName? ele.firstName: '',
            'Last Name': ele.lastName? ele.lastName: '' ,
            Balance: ele.tab.balance? ele.tab.balance: '0',
            Limit: ele.tab.limit === 0 ? 'None' : `$ ${ele.tab.limit}`,
            Status: ele.tab.status == 0 ? "Good" : ele.tab.status == 1 ? "Approaching Limit": "Delinquent",
          }
          exportRows.push(obj);
        })

      csvDownload(exportRows, `Tabs - ${format(new Date(), 'MM/dd/yyyy')}.${type}`);
    }
  }

  return (
    <>
      <ExportAndCustomFilters onExport={handleExport}/>
      {!isLoaded ? (
        <LoadingSpinner/>
      ) : (
      <>
        <div id="tab_container" className={clsx(styles.searchWrapper, 'd-flex', 'justify-content-between', 'align-items-center')}>
          <div  id="tab_inner_container" className="d-flex">
            <Search
              id="tabs"
              query={searchQuery}
              onChange={e => setSearchQuery(e.target.value)}
            />
          </div>
        </div>

        <div className={styles.tableWrapper}>
          <TabsTable
            rows={customers}
            resetSelected={resetSelected}
            onSelectCustomers={e => setSelectedCustomers(e)}
            onEditRowClick={e => setTabToEdit(e)}
            onArchiveRowClick={e => onArchiveRow(e)}
          />
        </div>

        <CloseTab
          selectedCustomers={selectedCustomers}
          onUpdate={onCloseTabs}
        />

        {tabToEdit && tabToEdit.email ? (
          <EditLimit
            currentRow={tabToEdit}
            onUpdate={onEditLimit}
          />
        ) : null}
        <ResponseModal
          isSuccess={responseValue?.isSuccess}
          message={responseValue?.message}
          open={responseValue?.isMessageOpen}
          onClose={value => setResponseValue({...responseValue, isMessageOpen:value})}
        />
      </>
      )}
      { operatorSpecificModalOpen && (
        <OperatorSpecificModal
          open={operatorSpecificModalOpen}
          onClose={(value) => setOperatorSpecificModalOpen(value)}
        />
      )}
    </>
  );
}

import moment from "moment";
import { getHumanReadableDateWithTimeZone } from "./getHumanReadableDateWithTimeZone";
import _ from "lodash";

export const timePeriodFilters = {
    DAYS: 'days',
    WEEKS: 'weeks',
    MONTHS: 'months',
    YEARS: 'years'
  };

export function generateFileNameForReport (docType,reportNamePrefix){
    const {startDate,endDate} = getHumanReadableDateWithTimeZone()
    let urlParams = new URLSearchParams(window.location.search);
    const selectedTimeFilter = urlParams.get("period");
    let dateFilter =''
    let startDatetoEndDate  = (startDate && endDate) ? `${moment(startDate).format('MM-DD-YYYY')}- TO -${moment(endDate).format('MM-DD-YYYY')}` : _.isString(selectedTimeFilter) ? `last${selectedTimeFilter.replace(/s$/, '')}` : '';
    switch (selectedTimeFilter) {
      case timePeriodFilters.DAYS:
        dateFilter = 'Daily Report';
        break;
      case timePeriodFilters.WEEKS:
        dateFilter = 'Weekly Report';
        break;
      case timePeriodFilters.MONTHS:
        dateFilter = 'Monthly Report';
        break;
      case timePeriodFilters.YEARS:
        dateFilter = 'Yearly Report';
        break;
      default:
        dateFilter = '';
        break;
    }
      
      const fileName = `${reportNamePrefix}${dateFilter}-${startDatetoEndDate}.${docType}`
      return fileName
  }

export function triggerReportDownload (responseObject,docType,reportNamePrefix) {
    const url = window.URL.createObjectURL(new Blob([responseObject.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", generateFileNameForReport(docType,reportNamePrefix));
    document.body.appendChild(link);
    link.click();
}
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { kioskActionTypes } from "../actions/kiosks.action";

import { subHeaderActionTypes } from "../actions/subHeader.action";
import { RESET_STATE } from "../actions";

const initialSubHeaderState = {
  selectedOperator: {},
  selectedLocation: "1",
  compressedLocationList: [],
  operatorList: [],
  loyaltyProgram: "globalConnect",
  kiosks: { kiosk: { rows: [] }, meta: { totalItems: 0 } },
  operatorAndLocationsData:[]
};

export const reducer = persistReducer(
  {
    storage,
    key: "yoke-subHeader",
    whitelist: ["selectedOperator", "selectedLocation"],
  },
  (state = initialSubHeaderState, action) => {
    switch (action.type) {
      case subHeaderActionTypes.StoreSelectedOperator: {
        return {
          ...state,
          selectedOperator: action.payload,
        };
      }

      case subHeaderActionTypes.StoreSelectedLocation: {
        return {
          ...state,
          selectedLocation: action.payload,
        };
      }

      case subHeaderActionTypes.ClearSelectedOperator: {
        return {
          ...state,
          selectedOperator: {},
        };
      }

      case subHeaderActionTypes.ClearSelectedLocation: {
        return {
          ...state,
          selectedLocation: {},
        };
      }

      case subHeaderActionTypes.StoreLoyaltyProgram: {
        return {
          ...state,
          loyaltyProgram: action.payload,
        };
      }

      case subHeaderActionTypes.StoreCompressedLocations: {
        return {
          ...state,
          compressedLocationList: action.payload,
        };
      }

      case subHeaderActionTypes.StoreOperators: {
        return {
          ...state,
          operatorList: action.payload,
        };
      }

      case subHeaderActionTypes.StoreLocations: {
        return {
          ...state,
          locationList: action.payload,
        };
      }

      case subHeaderActionTypes.storeOperatorAndLocationData: {
        return {
          ...state,
          operatorAndLocationsData: action.payload,
        };
      }
      case subHeaderActionTypes.clearOperatorAndLocationData: {
        return {
          ...state,
          operatorAndLocationsData: [],
        };
      }

      case kioskActionTypes.GET_KIOSKS_SUCCESS: {
        return {
          ...state,
          kiosks: action.payload,
        };
      }

      case kioskActionTypes.GET_KIOSKS_FAILURE: {
        return {
          ...state,
          kiosks:{
            ...state.kiosks,
            kiosk:{
              ...state.kiosks.kiosk,
              rows:[],
            },
            meta:{
              ...state.kiosks.meta,
              totalItems: 0
            }
          },
        };
      }

      case RESET_STATE: {
        return initialSubHeaderState;
      }

      default: {
        return state;
      }
    }
  }
);

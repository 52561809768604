import React, { useEffect, useState } from 'react';
import { Button, CircularProgress } from "@material-ui/core";
import { StarBorderOutlined, StarHalfOutlined, StarOutlined } from "@material-ui/icons";

/**
 * Featured Product Selector
 * 
 * 2 props are given to the component
 * selectedLocation: the current select location a user is view
 * locationsFeaturedAt: an array of locationIds that features the product
 * 
 * This component allows an end user to feature a product at a location. The end-user can 
 * toggle between states by clicking a button which will feature or unfeature the product.
 * If you have "All Locations" selected, you will toggle for all locations, if you have a 
 * location selected it will only toggle for that location. If a product is featured at 
 * another location, the button will mention "Featured Somewhere", you cannot untoggle a 
 * product for a single location unless that location is selected.
 * 
 * 
 */
const FeaturedProductSelector = (props) => {
    /**
     * 
     * Statues are
     * notFeatured: the default option, set if the product is not featured anywhere
     * featuredHere: set if the product is featured at this location
     * featuredSomewhere: set if the product is featured but not at this location
     */
    const [status, setStatus] = useState('notFeatured');
    const {
            isActive,
            isFeaturedLoaded,
            selectedLocation,
            operatorProductId,
            everyLocationId,
            addFeaturedProduct,
            deleteFeaturedProduct,
            locationsFeaturedAt,
        } = props;

    /**
     * Toggle the featured, not featured 
     */
    function toggleFeature() {
        if (status == 'notFeatured' || status == 'featuredSomewhere') {
            if (selectedLocation == 1) {
                addFeaturedProduct(operatorProductId, true)
            } else {
                addFeaturedProduct(operatorProductId, false)
            }
        }
        if (status == 'featuredHere') {
            if (selectedLocation == 1) {
                deleteFeaturedProduct(operatorProductId, true)
            } else {
                deleteFeaturedProduct(operatorProductId, false)
            }
        }
    }

    useEffect(() => {
        if ((selectedLocation === "1" && checkFeaturedHere(locationsFeaturedAt, everyLocationId)) || locationsFeaturedAt.includes(selectedLocation)) {
            setStatus("featuredHere");
        }

        else if (checkFeaturedSomewhere(locationsFeaturedAt, everyLocationId)) {
            setStatus("featuredSomewhere");
        }    
        else {
            setStatus("notFeatured");
        }
    }, [locationsFeaturedAt]);

    function checkFeaturedHere(locationsFirstList,locationsSecondList) {
        if(locationsFirstList.length < locationsSecondList.length)
          return false;
        return locationsSecondList.every(location => {
          return locationsFirstList.some( firstLocation=> firstLocation === location)
        })
    }

    function checkFeaturedSomewhere(firstList, secondList) {
        return secondList.some(location => firstList.includes(location))
    }

    return (
        <Button
            id={`featured_product_button`}
            onClick={toggleFeature}
            variant="text"
            color="primary"
            disableElevation
            disabled={!isActive}
        > {
            !isFeaturedLoaded
                ? <CircularProgress
                    size="1.5rem"
                    style={{ marginRight: "20px", color: "5D78FF" }}
                  />
                : status === 'notFeatured' 
                    ? <>
                        <StarBorderOutlined />
                        {'Not Featured'}
                      </> 
                    : status === 'featuredSomewhere' 
                    ? <>
                        <StarHalfOutlined />
                        {'Featured Somewhere'}
                      </> 
                    : <>
                        <StarOutlined />
                        {'Featured Here'}
                      </>            
        }
            
        </Button>
    );
};

export default FeaturedProductSelector;

import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { userTransactionReportActionTypes } from "../actions/userTransactionReport.action";
import { RESET_STATE } from "../actions";

const initialUserTransactionReportState = {
  loading: false,
  error: "",
  data: [],
};

export const reducer = persistReducer(
  {
    storage,
    key: "yoke-userTransactionReport",
    whitelist: ["userTransactionReport"],
  },
  (state = initialUserTransactionReportState, action) => {
    switch (action.type) {
      case userTransactionReportActionTypes.GetUserTransactionReport: {
        return {
          ...state,
          loading: true,
        };
      }

      case userTransactionReportActionTypes.StoreUserTransactionReport: {
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      }

      case userTransactionReportActionTypes.ClearUserTransactionReport: {
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      }
      case RESET_STATE: {
        return initialUserTransactionReportState;
      }
      default: {
        return state;
      }
    }
  }
);

import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  DialogTitle
} from '@material-ui/core';
import { DeleteForever } from '@material-ui/icons';

import styles from '../../../../_metronic/_assets/sass/pages/customers.module.scss';

export default function RemoveFromGroup1(props) {
  const [isModalOpen, setModalOpen] = useState(false);
  const {
    onUpdate,
    totalItems,
    categoryOfDeletingItem
  } = props;

  /**
   * Handle cancel click
   */
  function onCancelClick() {
    setModalOpen(false);
  }

  /**
   * Handle remove group
   */
  function onDeleteGroup() {
    setModalOpen(false);
    onUpdate();
  }

  return (
    <>
      <Button
        startIcon={<DeleteForever/>}
        className={styles.btn}
        size="large"
        variant="contained"
        color="primary"
        disableElevation
        onClick={() => setModalOpen(true)}
      >
        Delete Group
      </Button>

      {isModalOpen ? (
        <Dialog
          open
          onClose={onCancelClick}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Delete Group
          </DialogTitle>
          <DialogContent id="alert-dialog-title">
            Are you sure you want to delete this group?
            &nbsp;
            This group contains
            &nbsp;
            <b>
              #
              {totalItems}
              &nbsp;
              {categoryOfDeletingItem}
            </b>
            .
          </DialogContent>
          <DialogActions>
            <Button onClick={onCancelClick} color="primary">
              Cancel
            </Button>
            <Button
              startIcon={<DeleteForever/>}
              onClick={onDeleteGroup}
              autoFocus
              variant="contained"
              color="primary"
              disableElevation
            >
              Delete Group
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
    </>
  );
}

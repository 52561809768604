import { add, sub } from 'date-fns';
import moment from "moment";

import { getLoggedUserAccountId2 } from '../../../services/helpers/account-id';
import {
  reportTypeFilters,
  measureFilters
} from '../../../partials/content/SalesAndReport/salesReport.mock';
import { calculateInitialTime } from 'app/services/helpers/getDefaultParameters';

/**
 * Service function to prepare correct json query from current state
 */
export function prepareJsonQuery({
  selectedReportTypeFilter,
  selectedMeasureFilter,
  selectedPeriod,
  selectedTimeFilter,
}) {
  const today = new Date();
  const id = getLoggedUserAccountId2();
  const selectedReportType = reportTypeFilters.find(item => item.name === selectedReportTypeFilter).alias;
  const selectedMeasure = measureFilters.find(item => item.name === selectedMeasureFilter).alias;
  // list of default table metrics
  const metrics = ['revenue', 'basketValue', 'grossProfit', 'margin', 'tax'];
  const periodStart = sub(today, { [!selectedPeriod ? 'years' : selectedPeriod]: 1 });

  if (!metrics.includes(selectedMeasure))
    metrics.push(selectedMeasure);

  const metricsWithTitles = measureFilters
    .filter(item => metrics.includes(item.alias));

  return {
    query: {
      accountIds: [id],
      timeMetric: selectedTimeFilter,
      dateRanges: [
        {
          datebegin: periodStart.toISOString(),
          dateend: today.toISOString()
        }
      ],
      reports: [
        {
          name: 'summary',
          reportTypeCategory: 'ORDER',
          reportTypes: selectedReportType,
          metrics
        },
        {
          name: 'byLocations',
          reportTypeCategory: 'ORDER',
          enableGroupbyDate: true,
          reportTypes: selectedReportType === 'SalesSummary' ? 'Locations' : selectedReportType,
          metrics
        }
      ]
    },
    reportRelatedData: {
      dateRange: {
        startDate: periodStart,
        endDate: today
      },
      metrics: {
        values: metrics,
        titles: metricsWithTitles,
        selectedMeasure
      }
    }
  };
}

export function preparePayloadObj({
  selectedReportTypeFilter,
  selectedMeasureFilter,
  location,
  storedLocations,
  operator,
  operators,
  urlParams,
}) {
  const today = new Date();
  const selectedTimeFilter = urlParams.get("period");
  const startDate = moment(
    urlParams.get("startDate")
      ? urlParams.get("startDate")
      : calculateInitialTime(today,selectedTimeFilter)
  ).format("MM/DD/YYYY");
  const endDate = moment(
    urlParams.get("endDate") ? urlParams.get("endDate") : today
  ).format("MM/DD/YYYY");

  const payloadObj = storedLocations && storedLocations?.length && {
    locationIds:
      location === "1"
        ? storedLocations?.length && storedLocations?.filter((l) => l.id !== "1").map((l) => l.id)
        : [storedLocations?.length && storedLocations?.find((l) => l.id === location)?.id],
    accountIds:
        operator === "1"
          ? [getLoggedUserAccountId2()]
          : [operator],
    reports: [{
      reportType: selectedReportTypeFilter === "Customers" ? "customers" : "sales",
      metric:
        measureFilters.find(ele => ele.name === selectedMeasureFilter).alias,
      startDate: startDate,
      endDate: endDate,
      timeMetric: selectedTimeFilter,
    }],
  };
  return payloadObj;
} 

import React, { useState, useRef } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem
} from '@material-ui/core';

import styles from './stock-modal.module.scss';

export default function StockModal(props) {
  const {
    open,
    onClose,
    modalData
  } = props;
  const [dropdown, setDropdown] = useState(false);
  const menuAnchor = useRef(null);

  /**
   * On report type click
   */
  function handleReportDownload(reportType) {
    setDropdown(false);
    onClose(true, reportType);
  }

  function handleModalClose() {
    setDropdown(false);
    onClose(false);
  }

  return (
    <>
      <Dialog
        id={`stock_Modal_dailog_wrapper`}
        open={open}
        onClose={handleModalClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <DialogTitle id="simple-modal-title">Success!</DialogTitle>
        <DialogContent id="simple-modal-description" className={styles.wrapper}>
          {modalData.count}
          {' '}
          have been marked for replenishment. Click download to get a PDF or CSV report.
        </DialogContent>
        <DialogActions id="simple-modal-actions">
          <Button
            id={`stock_Modal_cancel_button`}
            color="primary"
            size="large"
            onClick={handleModalClose}
          >
            Cancel
          </Button>
          <Button
            id={`stock_Modal_download_button`}
            size="medium"
            variant="contained"
            color="primary"
            ref={menuAnchor}
            disableElevation
            onClick={() => setDropdown(true)}
          >
            Download
          </Button>

          <Popper id={`stock_Modal_paper_wrapper`} open={dropdown} anchorEl={menuAnchor?.current} role={undefined} transition disablePortal>
            {({ TransitionProps, placement }) => (
              <Grow
                id={`stock_Modal_grow`}
                {...TransitionProps}
                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
              >
                <Paper  id={`stock_Modal_paper_inner_wrapper`}>
                  <ClickAwayListener onClickAway={() => setDropdown(false)}>
                    <MenuList  autoFocusItem={dropdown} id="menu-list-grow">
                      <MenuItem onClick={() => handleReportDownload('pdf')}>
                        Export PDF
                      </MenuItem>
                      <MenuItem onClick={() => handleReportDownload('csv')}>
                        Export CSV
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </DialogActions>
      </Dialog>
    </>
  );
}

const emptyApplication = {
  status: "inProgress",
  companyInfo: {
    name: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    portalUrl: "",
    supportEmail: "",
    dbaName: "",
    dbaPhone: "",
    timezone: "",
  },
  companyAddress: {
    streetLineOne: "",
    streetLineTwo: "",
    city: "",
    postcode: "",
    state: "",
    country: "",
  },
  contactInfo: {
    federalTaxId: "",
    businessType: "",
    ownershipType: "",
    secondaryFirstName: "",
    secondaryLastName: "",
    secondaryPhone: "",
    businessStarted: "",
    acceptCreditCards: false,
    onlinePay: false,
    conflictOfInterests: false,
  },
  owners: [
    {
      id: new Date().getTime(),
      firstName: "",
      lastName: "",
      title: "",
      ssn: "",
      birthDate: "",
      homeAddress: "",
      city: "",
      state: "",
      zipCode: "",
      homePhone: "",
      cellPhone: "",
      driverLicense: "",
      lengthAtHomeAddress: "",
      businessEquity: 0,
    },
  ],
  bankInfo: {
    depositoryBank: "",
    phone: "",
    city: "",
    state: "",
    zip: "",
    accNumber: "",
    routingNumber: "",
    accType: "",
    bankruptcy: false,
    paymentType: "account",
    cardNumber: "",
    cardName: "",
    expDate: "",
    cvNumber: "",
    paymentAccType: "",
    paymentAccName: "",
    paymentBankName: "",
    paymentAccNumber: "",
    paymentRoutingNumber: "",
    paymentCity: "",
    paymentState: "",
  },
  vmsProvider: {
    type: "cantaloupe",
    operatorId: "",
    contactPerson: "",
  },
  vmsInfo: {
     type: 'cantaloupe',
     providerId: '2778c887-4159-44d9-8ca5-846d985a9a3e',
  }
};

export function getUserApplication() {
  return emptyApplication;
}

export const ownership = [
  { code: "", text: "select" },
  { code: "corp", text: "Corporation" },
];

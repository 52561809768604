import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
  makeStyles,
  IconButton,
} from "@material-ui/core";
import { EditSharp } from "@material-ui/icons";
import { format } from "date-fns";

import {
  getDefaultStyles
} from "../../../../services/table";
import DetailsRow from "../../../../components/Ledger/components/LedgerTable/components/DetailsRow/DetailsRow";
import { useEffect } from "react";

const headCells = [
  {
    id: "cartId",
    numeric: false,
    disablePadding: false,
    label: "Cart Id",
  },
  {
    id: "locationName",
    numeric: false,
    disablePadding: false,
    label: "Location Name",
  },
  {
    id: "user", numeric: false, disablePadding: false, label: "User"
  },
  { id: "totalAmount", numeric: false, disablePadding: false, label: "Total Amount" },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "created",
    numeric: false,
    disablePadding: false,
    label: "Created",
  },
  {
    id: "updated",
    numeric: false,
    disablePadding: false,
    label: "Updated",
  },
  { id: "actions", numeric: false, disablePadding: false, label: "Actions" },
];

function EnhancedTableHead({ headCells }) {
  return (
    <TableHead  id={`cart_table_header`}>
      <TableRow id={`cart_table_header_row`}>
        <TableCell id={`cart_table_header_cell`} padding="checkbox" style={{ width: 45 }} />
        {headCells.map((headCell) => (
          <TableCell
          id={`cart_table_header_cell_${headCell.id}`}
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles(getDefaultStyles);

export default function CartTable(props) {
  const {
    rows = [],
    pagination = {
      page: 1,
      limit: 10,
    },
    salesTotal = 0,
    onExpandRow,
    onPaginationChange,
    resetPaginationProp
  } = props;
  const classes = useStyles();
  const [page, setPage] = useState(pagination.page - 1);
  const [rowsPerPage, setRowsPerPage] = useState(pagination.limit);

  /**
   * Handle click on Edit icon
   * @param e
   * @param row
   */

  useEffect(() => {
    if (resetPaginationProp){
      setPage(0);
      setRowsPerPage(10);
    }
  }, [resetPaginationProp]); 

  function onRowClick(e, row) {
    e.stopPropagation();
    onExpandRow(row)
  }

  function handlePaginationChange({ target: { value } }, newPage = -1) {
    if (value) {
      const newSize = parseInt(value, 10);
      const newP = parseInt((page * rowsPerPage + 1) / newSize);
      onPaginationChange({
        page: newP + 1,
        limit: newSize,
      });
      setPage(newP);
      setRowsPerPage(newSize);
    }

    if (newPage >= 0 && typeof newPage !== "object") {
      onPaginationChange({
        page: newPage + 1,
        limit: rowsPerPage,
      });
      setPage(newPage);
    }
  }

  return (
    <div id={`cart_table_root_wrapper`} className={classes.root}>
      <Paper id={`cart_table_paper_wrapper`} className={classes.paper}>
        <TableContainer id={`cart_table_container_wrapper`} >
          <Table
            id={`cart_table_inner_container_wrapper`}
            className={classes.table}
            aria-labelledby="tableTitle"
            size="small"
            aria-label="enhanced table"
          >
            <EnhancedTableHead headCells={headCells} />
            <TableBody id={`cart_table_body_wrapper`}>
              {rows.map((row) => {
                const {
                  id,
                  locationName,
                  userName,
                  status,
                  cart,
                  creationDate,
                  updatedOn,
                  isExpanded,
                } = row;
                const cartTotalValue = cart?.totals?.total;
                return (
                  <React.Fragment key={id}>
                    <TableRow
                      id={`cart_table_row__${id}`}
                      hover
                      tabIndex={-1}
                      selected={isExpanded}
                      onClick={(e) => {
                        onRowClick(e, row);
                      }}
                    >
                      <TableCell id={`cart_table_checkbok_cell_${id}`} align="left" padding="checkbox"></TableCell>
                      <TableCell  id={`cart_table_id_cell_${id}`} align="left" padding="normal">{id}</TableCell>
                      <TableCell id={`cart_table_location_name_cell_${id}`} align="left" padding="normal">{locationName ?? "-"}</TableCell>
                      <TableCell  id={`cart_table_user_name_cell_${id}`} align="left" padding="normal">{userName ?? "-"}</TableCell>
                      <TableCell  id={`cart_table_total_cell_${id}`} align="left" padding="normal">{"$ " + (typeof cartTotalValue === "number" ? cartTotalValue.toFixed(2) : parseFloat(cartTotalValue || 0).toFixed(2))}</TableCell>
                      <TableCell  id={`cart_table_status_cell_${id}`} align="left" padding="normal" style={{color: status == "Purchased" ? "green" : status == "Open" ? "blue": "red", fontWeight: "bold"}}>{status}</TableCell>
                      <TableCell  id={`cart_table_creation_date_cell_${id}`} align="left" padding="normal">{format(new Date(creationDate), "MM/dd/yyyy - hh:mm aa")}</TableCell>
                      <TableCell  id={`cart_table_updated_on_cell_${id}`} align="left" padding="normal">{format(new Date(updatedOn), "MM/dd/yyyy - hh:mm aa")}</TableCell>
                      <TableCell  id={`cart_table_icon_button_cell_${id}`} align="left" padding="normal">
                        <IconButton
                        >
                          <EditSharp />
                        </IconButton>
                      </TableCell>

                      
                    </TableRow>
                    {isExpanded ? (
                          <DetailsRow
                            isLoads={true}
                            isCarts={true}
                            cartId={id}
                            currentRow={row}
                            onIssueRefund={() => {}}
                            onResendReceipt={() => {}}
                            isOrderHistory={false}
                          />
                    ) : null}        
                  </React.Fragment>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          id={`cart_table_pagination`}
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={salesTotal}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handlePaginationChange}
          onRowsPerPageChange={handlePaginationChange}
        />
      </Paper>
    </div>
  );
}

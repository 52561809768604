import React, { useEffect, useState } from "react";
import to from "await-to-js";
import { format } from "date-fns";
import { useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import csvDownload from 'json-to-csv-export';
import * as Sentry from "@sentry/react";
import "jspdf-autotable";

import { getComparator, stableSort } from "app/services/table";
import { accessPermission } from "app/common/helperFunction";
import { getBillValidators } from "app/services/billValidator";
import { getScope, hasMultipleAccounts } from "app/services/helpers/account-id";
import LoadingSpinner from "app/partials/content/LoadingSpinner";
import { useCustomResponse } from "app/hooks/useCustomResponse";

import ResponseModal from "../Helpers/ResponseModal";
import ExportAndCustomFilters from "../../partials/content/ExportAndCustomFilters";
import Search from "../../partials/content/Customers/Search";
import BillValidatorsTable from "./components/BillValidatorsTable/BillValidatorsTable";
import OperatorSpecificModal from "../Helpers/OperatorSpecificModal";
import AddBillValidator from "./AddBillValidator";

import styles from "./add-ons.module.scss";
import { PAGE_TITLE_CASH_COLLECTIONS } from "app/common/constant";
import { ROLE } from "app/common/roleConstant";

export default function CashCollections(props) {
  const { filter: {location, operator} } = props;
  const { push } = useHistory();
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
  });
  const [cashCollections, setCashCollections] = useState([]);
  const [cashCollectionsTotal, setCashCollectionsTotal] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);
  const [addBillValidatorPopup, setAddBillValidatorPopup] = useState(false);
  const [operatorSpecificModalOpen, setOperatorSpecificModalOpen] = useState(false);
  const [responseValue, setResponseValue] = useCustomResponse()
  const order = "asc";
  const orderBy = "id"
  const isSuperAdmin = getScope() === ROLE.SUPERADMIN


  useEffect(() => {
    if (!accessPermission("LedgerAll")) {
      push({ pathname: "/dashboard" });
    }
  }, []);

  /**
   * Get all Add-Ons
   */
  useEffect(() => {
    proceedBillValidators();
    // eslint-disable-next-line
  }, [pagination, searchQuery]);

  /**
   * Get all Add-Ons
   * @returns {Promise<void>}
   */
  async function proceedBillValidators() {

    if(hasMultipleAccounts()){
      setOperatorSpecificModalOpen(true);
      return;
    }

    const [err, res] = await to(getBillValidators(pagination, Boolean(location === "1"), Boolean(operator === "1"), searchQuery));
    setIsLoaded(true);
    if (err) {     
      Sentry.captureException(err);
      setResponseValue({
        ...responseValue,  
        isMessageOpen: true,
        isSuccess: false,
        message: `Error while getting Bill Validators. ${err}`
      })
      return console.error(
        "Error while fetching Add-ons Bill Validators data:",
        err
      );
    }

    const {
      billValidator,
      meta: { totalItems },
    } = res.data;
    
    const soredCashCollections = stableSort(billValidator, getComparator(order, orderBy))
    setCashCollections(soredCashCollections);
    setCashCollectionsTotal(totalItems);
  }

  function handleEditRowClick(id) {
    push({ pathname: `/cashCollections/${id}` });
  }

  function handleAddBillValidator() {
    setAddBillValidatorPopup(!addBillValidatorPopup);
  }

  async function handleExport(type) {
    if (cashCollections.length === 0) return;

    {
      const exportRows = [];
      cashCollections.forEach((ele) => {
        let obj = {
          Id: ele.id ? ele.id : "N/A",
          Location: ele.LocationName ? ele.LocationName : "N/A",
          Kiosk: ele.KioskName ? ele.KioskName : "N/A",
          "Amount Uncollected": ele.AmountUncollected
            ? ele.AmountUncollected
            : "N/A",
          "Last Collected": ele.totalAmount ? ele.totalAmount : "N/A",
        };
        exportRows.push(obj);
      });

      csvDownload(
        exportRows,
        `${PAGE_TITLE_CASH_COLLECTIONS} - ${format(new Date(), "MM/dd/yyyy")}.${type}`
      );
    }
  }

  return (
    <>
      <div id="cash_collections_wrapper" className={styles.wrapper}>
    <ExportAndCustomFilters className={isSuperAdmin && styles.wrapper} onExport={handleExport} hasFilters={false} />
     {
      isSuperAdmin && <Search
      id="billValidator_search"
      label="Search by bill validator id"
      placeholder="Search"
      query={searchQuery}
      onChange={(e) => setSearchQuery(e.target.value)}
    />
     }
      </div>
      {!isLoaded ? (
        <LoadingSpinner/>
      ) : (
        <>
          <BillValidatorsTable
            rows={cashCollections}
            pagination={pagination}
            cashCollectionsTotal={cashCollectionsTotal}
            onEditRowClick={handleEditRowClick}
            onPaginationChange={(value) => setPagination(value)}
          />

          {getScope() === ROLE.SUPERADMIN && (<div id="cash_collections_button_wrapper" className={styles.buttonWrapper}>
            <Button
              id="cash_collections_add_biller_button"
              size="large"
              variant="contained"
              color="primary"
              disableElevation
              onClick={() => handleAddBillValidator()}
            >
              Add Bill Validator
            </Button>
          </div>
           )};
          {addBillValidatorPopup && (
            <AddBillValidator
              setAddLockPopup={setAddBillValidatorPopup}
              proceedBillValidators={() => proceedBillValidators()}
            />
          )}
         
          <ResponseModal
            isSuccess={responseValue?.isSuccess}
            message={responseValue?.message}
            open={responseValue?.isMessageOpen}
            onClose={(value) => setResponseValue({...responseValue, isMessageOpen:value})}
          />
        </>
      )}
      {operatorSpecificModalOpen && (
        <OperatorSpecificModal
          open={operatorSpecificModalOpen}
          onClose={(value) => setOperatorSpecificModalOpen(value)}
        />
      )}
    </>
  );
}

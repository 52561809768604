import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
  makeStyles,
  IconButton,
  Menu,
  MenuItem,
} from "@material-ui/core";
import {
  EditSharp,
  ExpandMore,
  ExpandLess,
  Check,
  Close,
  Remove,
  Settings,
} from "@material-ui/icons";

import {
  getComparator,
  getDefaultStyles,
  stableSort,
} from "../../../../../services/table";

import DetailsRow from "./components/DetailsRow/DetailsRow";
import { useEffect } from "react";
import { PAGINATION_INITIAL_STATE } from "app/common/utils/generic";

const headCells = [
  {
    id: "location",
    numeric: false,
    disablePadding: false,
    label: "Location Name",
  },
  {
    id: "connected",
    numeric: false,
    disablePadding: false,
    label: "Connected",
  },
  { id: "power", numeric: false, disablePadding: false, label: "Power" },
  { id: "actions", numeric: false, disablePadding: false, label: "Actions" },
];

function EnhancedTableHead() {
  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" style={{ width: 45 }} />
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles(getDefaultStyles);

export default function LocationsTable({onPaginationChange, ...props}) {
  const classes = useStyles();
  const [order] = useState("asc");
  const [orderBy] = useState("id");
  const [page, setPage] = useState(PAGINATION_INITIAL_STATE.page - 1);
  const [rowsPerPage, setRowsPerPage] = useState(PAGINATION_INITIAL_STATE.limit);
  const [downloadLocationId, setDownloadLocationId] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const {
    rows,
    onExpandRow,
    onEditRowClick,
    isDetailsRowExpandable = false,
    downloadLocationQRCode,
    pagination
  } = props;


  function handlePaginationChange({ target: { value } }, newPage = -1) {
    if (value) {
      const newSize = parseInt(value, 10);
      const newP = parseInt((page * rowsPerPage + 1) / newSize);

      onPaginationChange({
        page: newP +1,
        limit: newSize
      });
      setPage(newP);
      setRowsPerPage(newSize);
    }

    if (newPage >= 0 && typeof newPage !== 'object') {
      onPaginationChange({
        page: newPage + 1,
        limit: rowsPerPage
      });
      setPage(newPage);
    }
  }

  /**
   * Handle click on Edit icon
   * @param e
   * @param row
   */
  function onEditIconClick(e, row) {
    e.stopPropagation();

    onEditRowClick(row);
  }

  /**
   * Handle click on Edit icon
   * @param e
   */
  function onSettingsClick(e, row) {
    e.stopPropagation();
    setDownloadLocationId(row.id);
    setAnchorEl(anchorEl ? null : e.currentTarget);
  }

  /**
   * Handle settings close
   * @param action
   */
  async function onSettingsClose(action) {
    setAnchorEl(null);
    if (action === "download") {      
      downloadLocationQRCode(downloadLocationId);
    }
  }

  return (
    <div id={`location_table_root_wrapper`} className={classes.root}>
      <Paper id={`location_table_paper_wrapper`} className={classes.paper}>
        <TableContainer id={`location_table_container_wrapper`}>
          <Table
            id={`location_table_wrapper`}
            className={classes.table}
            aria-labelledby="tableTitle"
            size="small"
            aria-label="enhanced table"
          >
            <EnhancedTableHead />
            <TableBody id={`location_table_body_wrapper`}>
              {rows?.map((row) => {
                  const {
                    id,
                    name,
                    connected,
                    power,
                    kiosks,
                    isExpanded,
                  } = row;

                  return (
                    <React.Fragment key={id}>
                      <TableRow
                        id={`location_table_row_${id}`}
                        hover
                        tabIndex={-1}
                        onClick={() =>
                          kiosks && kiosks.length && onExpandRow(row)
                        }
                      >
                        <TableCell id={`location_table_isexpanded_cell_${id}`} align="left" padding="checkbox">
                          {kiosks && kiosks.length ? (
                            <IconButton  id={`location_table_icon_button_cell_${id}`}>
                              {isExpanded ? <ExpandLess /> : <ExpandMore />}
                            </IconButton>
                          ) : null}
                        </TableCell>
                        <TableCell id={`location_table_name_cell_${id}`} align="left" padding="normal">
                          {name}
                        </TableCell>
                        <TableCell id={`location_table_icon_remove_cell_${id}`} align="left" padding="normal">
                          {kiosks && kiosks.length ? (
                            <Remove />
                          ) : (
                            <div>{connected ? <Check /> : <Close />}</div>
                          )}
                        </TableCell>
                        <TableCell id={`location_table_power_cell_${id}`} align="left" padding="normal">
                          {kiosks && kiosks.length ? (
                            <Remove />
                          ) : (
                            <div>{power ? <Check /> : <Close />}</div>
                          )}
                        </TableCell>
                        <TableCell id={`location_table_icon_seting_button_cell_${id}`} align="left" padding="normal">
                          <IconButton onClick={(e) => onSettingsClick(e, row)}>
                            <Settings />
                          </IconButton>

                          <Menu
                            id="menu"
                            anchorEl={anchorEl}
                            keepMounted={false}
                            open={Boolean(anchorEl)}
                            onClose={onSettingsClose}
                          >
                            <MenuItem
                              id={`location_table_download_qr_code_cell_${id}`}
                              onClick={() => onSettingsClose("download")}
                            >
                              Download QR Code
                            </MenuItem>
                          </Menu>

                          <IconButton  id={`location_table_edit_button_cell_${id}`} onClick={(e) => onEditIconClick(e, row)}>
                            <EditSharp />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                      {isExpanded ? (
                        <DetailsRow
                          onExpandRow={onExpandRow}
                          isDetailsRowExpandable={isDetailsRowExpandable}
                          kiosks={kiosks}
                          downloadLocationQRCode={downloadLocationQRCode}
                        />
                      ) : null}
                    </React.Fragment>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
           id={`location_table_pagination`}
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={props?.totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handlePaginationChange}
          onRowsPerPageChange={handlePaginationChange}
        />
      </Paper>
    </div>
  );
}

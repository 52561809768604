import axios from 'axios';

import { CANCEL_TOKEN } from "app/common/constant";

import { getDefaultParameters_V2 } from './helpers/getDefaultParameters';
import axiosInstance from './helpers/axiosInstance';

/**
 * Get the list of users related to current account
 * @param accountId
 * @returns {Promise<AxiosResponse<any>>}
 */

export function getAccountUsers(accountId, limit,page) {
  return axios.get(`/accounts/${accountId}/users` + getDefaultParameters_V2(true) +`&limit=${limit}&page=${page}`);
}

/**
 * Get user by id
 * @param userId
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getUserById(userId) {
  return axios.get(`/users/${userId}`);
}


/**
 * Get role by user id
 * @param userId
 * @returns {Promise<AxiosResponse<any>>}
 */
 export function getUserRoleById(userId) {
  return axios.get(`/users/${userId}/roles`);
}


/**
 * Create a new user
 */
export function createNewUser(user) {
  return axios.post('/users?accountId=1', user);
}

/**
 * assign a user to an account
 */
 export function assignUserToAccount(userId, accountId) {
  return axios.post(`/users/${userId}/accounts/${accountId}`);
}

/**
 * Update existing user details
 */
export function updateUserById(userId, updatedUser) {
  return axios.post(`/users/${userId}`, updatedUser);
}

/**
 * Get the list of groups related to current operator account
 * @param accountId
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getAccountGroups(accountId) {
  return axios.get(`/accounts/${accountId}/groups`);
}

export function getOwnerDetails(currentUserId) {
  return axios.get(`/accounts/${currentUserId}/owner`);
}

/**
 * Get group by id related to current operator account
 * NOTICE: this endpoint not exist
 * @param accountId
 * @param groupId
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getAccountGroupById(accountId, groupId) {
  return axios.get(`/accounts/${accountId}/groups/${groupId}`+getDefaultParameters_V2(true));
}

/**
 * Get the list of all available operators
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getOperators() {
  return axios.get('/accounts');
}

const CancelToken = axios.CancelToken;
let getOperatorV2Cancel = null;




export function getOperatorV2({limit = 10, page = 1} = {}, searchQuery = "",startDate='',endDate='') {
  if(getOperatorV2Cancel){
    getOperatorV2Cancel(CANCEL_TOKEN);
    getOperatorV2Cancel = null;
  }
let url
if (startDate =='' || endDate==''){
  url = `/accounts/list?limit=${limit}&page=${page}${searchQuery ? "&keywords="+searchQuery : ""}`
}else{
  url = `/accounts/list?limit=${limit}&page=${page}${searchQuery ? "&keywords="+searchQuery : ""}&startDate=${startDate}&endDate=${endDate} `
}

return axios.get(url, {
    cancelToken: new CancelToken(function executor(c){
      getOperatorV2Cancel = c;
    })
  });
}

let getOperatorListCancel = null;
export function getOperatorsList({limit = 10, page = 1} = {}, searchQuery = "", allOperators = false) {
  if(getOperatorListCancel){
    getOperatorListCancel(CANCEL_TOKEN);
    getOperatorListCancel = null;
  }
  const url = `/accounts/list?limit=${limit}&page=${page}${searchQuery ? "&keywords="+searchQuery : ""}${allOperators ? "&allOperators="+allOperators : ""}`

  return axios.get(url, {
    cancelToken: new CancelToken(function executor(c){
      getOperatorListCancel = c;
    })
  });
}

/**
 * Get account details for selected operator
 * @param accountId
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getOperatorDetails(accountId, exportType) {
  let url = `/accounts/${accountId}`+getDefaultParameters_V2(true);

  if (exportType)
    url += `&export=${exportType}`;

  return axios.get(url);
}

/**
 * Update current account details
 */
export function updateOperatorDetails(accountId, data) {
  return axiosInstance.patch(`/accounts/${accountId}`, data);
}

export function deleteOperatorDetails(accountId) {
  return axiosInstance.delete(`/accounts/${accountId}`);
}

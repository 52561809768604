import React from 'react';
import { connect } from 'react-redux';
import objectPath from 'object-path';

import Topbar from './Topbar';
import HMenu from './HMenu/HMenu';
import KTHeader from '../../_assets/js/header';
import * as builder from '../../ducks/builder';

class Header extends React.Component {
  headerCommonRef = React.createRef();

  componentDidMount() {
    const options = {};
    if (this.headerCommonRef.current.getAttribute('data-ktheader-minimize') === '1') {
      options['minimize.desktop.on'] = 'kt-header--minimize';
      options['offset.desktop'] = 130;
    }

    // eslint-disable-next-line no-undef,no-new
    new KTHeader(this.headerCommonRef.current, options);
  }

  render() {
    const { menuHeaderDisplay, headerAttributes, headerClasses } = this.props;
    return (
      <div
        className={`kt-header kt-grid__item ${headerClasses}`}
        id="kt_header"
        ref={this.headerCommonRef}
        {...headerAttributes}
      >
        {menuHeaderDisplay ? <HMenu/> : <div/>}
        <Topbar/>
      </div>
    );
  }
}

const mapStateToProps = store => ({
  headerAttributes: builder.selectors.getAttributes(store, { path: 'header' }),
  headerClasses: builder.selectors.getClasses(store, {
    path: 'header',
    toString: true
  }),
  menuHeaderDisplay: objectPath.get(
    store.builder.layoutConfig,
    'header.menu.self.display'
  ),
  fluid:
    objectPath.get(store.builder.layoutConfig, 'header.self.width') === 'fluid'
});

export default connect(mapStateToProps)(Header);

import { CircularProgress } from '@material-ui/core';
import React from 'react'
import styles2 from "_metronic/_assets/sass/loading-text.module.scss";

export default function LoadingSpinner() {
  return (
    <div className={styles2.loadingContainer}>
        <div className={styles2.loadingText}>
            <CircularProgress
                size="5rem"
                style={{ marginRight: "20px", color: "5D78FF" }}
            />
            {"Loading..."}
        </div>
    </div>
  )
}

import React from 'react';
import clsx from 'clsx';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Typography,
  Paper
} from '@material-ui/core';

import styles from '../../../../_metronic/_assets/sass/pages/sales-report.module.scss';

export default function ReportTable({ data, selectedMeasureFilter }) {
  /**
   * Render table cell
   * @param cell
   * @param row
   */
  function renderTableCell(cell, row) {
    const {
      size,
      currency: parentCurrency
    } = row;
    const {
      id,
      hasCurrency,
      className,
      secondaryClassName,
      value: {
        name,
        integer,
        decimal
      },
      rowSpan = 1,
      colSpan = 1,
      align = 'right',
      currency: childCurrency,
      currencyPosition = 'before',
      hasBorder = true
    } = cell;

    return (
      <TableCell
        key={id}
        colSpan={colSpan}
        rowSpan={rowSpan}
        align={align}
        size={size}
        className={clsx({
          [styles.withoutBorder]: !hasBorder,
          [styles.verticalHeader]: rowSpan > 1,
          [styles[className]]: className,
          [styles[secondaryClassName]]: secondaryClassName
        })}
      >
        <Typography variant="body1" color="textPrimary">
          {hasCurrency && currencyPosition === 'before' ? (childCurrency || parentCurrency) : ''}
          {name || (selectedMeasureFilter == "Basket Size" ? integer?.toFixed(1) : integer)}
          {decimal
            ? (
                `.${decimal}`
            ) : null}
          {hasCurrency && currencyPosition === 'after' ? (childCurrency || parentCurrency) : ''}
        </Typography>
      </TableCell>
    );
  }

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {data && data.firstHeadersRow.map(({ id, title, colSpan, className, secondaryClassName }) => (
              <TableCell
                key={id}
                colSpan={colSpan}
                align="center"
                size="small"
                className={clsx({
                  [styles[className]]: className,
                  [styles[secondaryClassName]]: secondaryClassName
                })}
              >
                {title} 
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            {data && data.secondHeadersRow.map(({ id, colSpan = 1, title, align = 'right', className, secondaryClassName }) => (
              <TableCell
                key={id}
                colSpan={colSpan}
                align={align}
                className={clsx({
                  [styles[className]]: className,
                  [styles[secondaryClassName]]: secondaryClassName
                })}
              >
                <Typography variant="body2" color="textSecondary">
                  {title}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data && data.body.map(row => (
            <TableRow key={row.id} hover={row.hover} className={styles[row.className]}>
              {row.rowDetails.map(cell => renderTableCell(cell, row))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

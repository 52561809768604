import React from "react";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import PropTypes from 'prop-types';

/**
 * @param value date
 * @param variant "inline"
 * @param format date formate
 * @param views ["date","month","year"]
 * @param maxDate "date"
 * @callback onChange changing dates
 * @param label string
 * @param disableToolbar boolean
 * @param autoOk boolean
 * @param className Object
 * */ 
const DatePickerComponent = ({value, variant, format, views, maxDate, onChange, label,disableToolbar,autoOk,className}) => {

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DatePicker
        className={className}
        variant={variant}
        margin="none"
        id="period-start"
        label={label}
        views={views}
        disableToolbar={disableToolbar}
        maxDate={maxDate}
        format={format}
        value={value}
        onChange={onChange}
        autoOk={autoOk}
      />
    </MuiPickersUtilsProvider>
  );
};

/**
 * @example default properties
 * */ 
DatePickerComponent.defaultProps = {
    variant: "inline",
    format:"MM/dd/yyyy",
    views:["date"],
    maxDate: new Date(),
    label: "Date",
    disableToolbar: true,
    minDate: new Date(),
    autoOk: true
  };


DatePickerComponent.propTypes = {
    variant: PropTypes.string,
    format: PropTypes.string,
    maxDate: PropTypes.string,
    minDate: PropTypes.string,
    views: PropTypes.arrayOf(PropTypes.string),
    value: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
    disableToolbar: PropTypes.bool,
    autoOk: PropTypes.bool,
    className: PropTypes.object
  };
export default DatePickerComponent;

/**
 * Array with available report types filters
 */
export const reportTypeFilters = [
  {
    id: 1,
    name: 'Sales Summary',
    alias: 'SalesSummary'
  }, {
    id: 2,
    name: 'Locations',
    alias: 'Locations'
  }, {
    id: 3,
    name: 'Customers',
    alias: 'Customers'
  },
  {
    id: 8,
    name: 'Kiosks',
    alias: 'Kiosks'
  }, {
    id: 9,
    name: 'Groups',
    alias: 'Groups'
  }
];

/**
 * Array with available measure filters
 */
export const measureFilters = [
  {
    id: 1,
    name: 'Basket Size',
    alias: 'basketSize'
  }, {
    id: 2,
    name: 'Basket Value',
    alias: 'basketValue'
  }, {
    id: 3,
    name: 'COGs',
    alias: 'COGS'
  }, 
  {
    id: 6,
    name: 'First Sale',
    alias: 'firstSale'
  }, {
    id: 7,
    name: 'Last Sale',
    alias: 'lastSale'
  },  
  {
    id: 9,
    name: 'Gross Profit',
    alias: 'grossProfit'
  }, {
    id: 10,
    name: 'Revenue',
    alias: 'Revenue'
  }, {
    id: 11,
    name: 'Margin',
    alias: 'Margin'
  }, 
  {
    id: 14,
    name: 'Customer Count',
    alias: 'customerCount'
  }
];

/**
 * Time Metric values
 */
 export const timeFilters = [
  {
    id: 1,
    name: 'Days',
    alias: 'days'
  }, {
    id: 2,
    name: 'Weeks',
    alias: 'weeks'
  },
  {
    id: 3,
    name: 'Months',
    alias: 'months'
  },
  {
    id: 4,
    name: 'Years',
    alias: 'years'
  },

]



/**
 * Mocked data for report table
 */
export const mockedData = {
  firstHeadersRow: [
    {
      id: 1,
      title: '',
      colSpan: 2,
      className: 'generalInfo'
    }, {
      id: 2,
      title: 2021,
      colSpan: 4,
      className: 'generalInfo',
      secondaryClassName: 'borderRight'
    }, {
      id: 3,
      title: 'TOTALS BY LOCATION',
      colSpan: 5,
      className: 'aggregated'
    }
  ],
  secondHeadersRow: [
    {
      id: 1,
      title: 'Outlet',
      colSpan: 2,
      align: 'left',
      className: 'borderRight'
    }, {
      id: 2,
      title: '20th Jan'
    }, {
      id: 3,
      title: '27th Jan'
    }, {
      id: 4,
      title: '3rd Feb'
    }, {
      id: 5,
      title: '10th Feb',
      className: 'borderRight'
    }, {
      id: 6,
      title: 'Revenue'
    }, {
      id: 7,
      title: 'Cost of Gods'
    }, {
      id: 8,
      title: 'Gross Profit'
    }, {
      id: 9,
      title: 'Margin'
    }, {
      id: 10,
      title: 'Tax'
    }
  ],
  body: [
    {
      id: 1,
      currency: '$',
      hover: true,
      rowDetails: [
        {
          id: 1,
          value: { name: 'CTLP Micro Market - ATL' },
          colSpan: 2,
          align: 'left',
          className: 'borderRight'
        },
        {
          id: 2,
          value: { integer: 0, decimal: '00' },
          hasCurrency: true
        },
        {
          id: 3,
          value: { integer: 18, decimal: '60' },
          hasCurrency: true
        },
        {
          id: 4,
          value: { integer: 1, decimal: '35' },
          hasCurrency: true
        },
        {
          id: 5,
          value: { integer: 0, decimal: '00' },
          hasCurrency: true,
          className: 'borderRight'
        },
        {
          id: 6,
          value: { integer: 19, decimal: '95' },
          hasCurrency: true
        },
        {
          id: 7,
          value: { integer: 7, decimal: '05' },
          hasCurrency: true
        },
        {
          id: 8,
          value: { integer: 12, decimal: '90' },
          hasCurrency: true
        },
        {
          id: 9,
          value: { integer: 65, decimal: null },
          currency: '%',
          hasCurrency: true,
          currencyPosition: 'after'
        },
        {
          id: 10,
          value: { integer: 0, decimal: '00' },
          hasCurrency: true
        }
      ]
    },
    {
      id: 2,
      currency: '$',
      hover: true,
      rowDetails: [
        {
          id: 1,
          value: { name: 'FreshPay Smart Market' },
          colSpan: 2,
          align: 'left',
          className: 'borderRight'
        },
        {
          id: 2,
          value: { integer: 5, decimal: '68' },
          hasCurrency: true
        },
        {
          id: 3,
          value: { integer: 0, decimal: '00' },
          hasCurrency: true
        },
        {
          id: 4,
          value: { integer: 0, decimal: '00' },
          hasCurrency: true
        },
        {
          id: 5,
          value: { integer: 0, decimal: '00' },
          hasCurrency: true,
          className: 'borderRight'
        },
        {
          id: 6,
          value: { integer: 5, decimal: '68' },
          hasCurrency: true
        },
        {
          id: 7,
          value: { integer: 3, decimal: '40' },
          hasCurrency: true
        },
        {
          id: 8,
          value: { integer: 2, decimal: '28' },
          hasCurrency: true
        },
        {
          id: 9,
          value: { integer: 40, decimal: null },
          currency: '%',
          hasCurrency: true,
          currencyPosition: 'after'
        },
        {
          id: 10,
          value: { integer: 0, decimal: '00' },
          hasCurrency: true
        }
      ]
    },
    {
      id: 3,
      currency: '$',
      hover: false,
      className: 'totals',
      rowDetails: [
        {
          id: 1,
          value: { name: 'Totals' },
          colSpan: 2,
          align: 'left',
          className: 'borderRight',
          secondaryClassName: 'boldText'
        },
        {
          id: 2,
          value: { integer: 5, decimal: '68' },
          hasCurrency: true
        },
        {
          id: 3,
          value: { integer: 18, decimal: '60' },
          hasCurrency: true
        },
        {
          id: 4,
          value: { integer: 1, decimal: '35' },
          hasCurrency: true
        },
        {
          id: 5,
          value: { integer: 0, decimal: '00' },
          hasCurrency: true,
          className: 'borderRight'
        },
        {
          id: 6,
          value: { integer: 25, decimal: '63' },
          hasCurrency: true
        },
        {
          id: 7,
          value: { integer: 10, decimal: '45' },
          hasCurrency: true
        },
        {
          id: 8,
          value: { integer: 15, decimal: '18' },
          hasCurrency: true
        },
        {
          id: 9,
          value: { integer: 59, decimal: null },
          currency: '%',
          hasCurrency: true,
          currencyPosition: 'after'
        },
        {
          id: 10,
          value: { integer: 0, decimal: '00' },
          hasCurrency: true
        }
      ]
    }
  ],
  footer: [
    {
      id: 1,
      currency: '$',
      rowDetails: [
        {
          id: 1,
          value: {
            name: 'TOTALS BY DATE RANGE'
          },
          size: 'small',
          align: 'center',
          rowSpan: 5
        },
        {
          id: 2,
          value: { name: 'Revenue' },
          align: 'left',
          className: 'borderRight'
        },
        {
          id: 3,
          value: { integer: 5, decimal: '68' },
          hasCurrency: true
        },
        {
          id: 4,
          value: { integer: 18, decimal: '60' },
          hasCurrency: true
        },
        {
          id: 5,
          value: { integer: 1, decimal: '35' },
          hasCurrency: true
        },
        {
          id: 7,
          value: { integer: 0, decimal: '00' },
          hasCurrency: true,
          className: 'borderRight'
        },
        {
          id: 8,
          value: {},
          colSpan: 6,
          hasBorder: false
        }
      ]
    },
    {
      id: 2,
      currency: '$',
      rowDetails: [
        {
          id: 0,
          value: { name: 'Cost of Goods' },
          align: 'left',
          className: 'borderRight'
        },
        {
          id: 1,
          value: { integer: 3, decimal: '40' },
          hasCurrency: true
        },
        {
          id: 2,
          value: { integer: 6, decimal: '49' },
          hasCurrency: true
        },
        {
          id: 3,
          value: { integer: 0, decimal: '56' },
          hasCurrency: true
        },
        {
          id: 4,
          value: { integer: 0, decimal: '00' },
          hasCurrency: true,
          className: 'borderRight'
        },
        {
          id: 5,
          value: {},
          colSpan: 6,
          hasBorder: false
        }
      ]
    },
    {
      id: 3,
      currency: '$',
      rowDetails: [
        {
          id: 0,
          value: { name: 'Gross Profit' },
          align: 'left',
          className: 'borderRight'
        },
        {
          id: 1,
          value: { integer: 2, decimal: '28' },
          hasCurrency: true
        },
        {
          id: 2,
          value: { integer: 12, decimal: '11' },
          hasCurrency: true
        },
        {
          id: 3,
          value: { integer: 0, decimal: '79' },
          hasCurrency: true
        },
        {
          id: 4,
          value: { integer: 0, decimal: '00' },
          hasCurrency: true,
          className: 'borderRight'
        },
        {
          id: 5,
          value: {},
          colSpan: 6,
          hasBorder: false
        }
      ]
    },
    {
      id: 4,
      currency: '$',
      rowDetails: [
        {
          id: 0,
          value: { name: 'Margin' },
          align: 'left',
          className: 'borderRight'
        },
        {
          id: 1,
          value: { integer: 40, decimal: null },
          currency: '%',
          hasCurrency: true,
          currencyPosition: 'after'
        },
        {
          id: 2,
          value: { integer: 65, decimal: null },
          currency: '%',
          hasCurrency: true,
          currencyPosition: 'after'
        },
        {
          id: 3,
          value: { integer: 59, decimal: null },
          currency: '%',
          hasCurrency: true,
          currencyPosition: 'after'
        },
        {
          id: 4,
          value: { integer: 0, decimal: null },
          currency: '%',
          hasCurrency: true,
          currencyPosition: 'after',
          className: 'borderRight'
        },
        {
          id: 5,
          value: {},
          colSpan: 6,
          hasBorder: false
        }
      ]
    },
    {
      id: 5,
      currency: '$',
      rowDetails: [
        {
          id: 0,
          value: { name: 'Tax' },
          align: 'left',
          className: 'borderRight'
        },
        {
          id: 1,
          value: { integer: 0, decimal: '00' },
          hasCurrency: true
        },
        {
          id: 2,
          value: { integer: 0, decimal: '00' },
          hasCurrency: true
        },
        {
          id: 3,
          value: { integer: 0, decimal: '00' },
          hasCurrency: true
        },
        {
          id: 4,
          value: { integer: 0, decimal: '00' },
          hasCurrency: true,
          className: 'borderRight'
        },
        {
          id: 5,
          value: {},
          colSpan: 6,
          hasBorder: false
        }
      ]
    }
  ]
};

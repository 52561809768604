import React from 'react';
import clsx from 'clsx';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';

import styles from './inventory.module.scss';

export default function Inventory(props) {
  const {
    inventory = [],
    className
  } = props;
  return (
    <>
      <Table id={`inventory_tabel`} size="small" className={clsx(styles.wrapper, className)}>
        <TableHead id={`inventory_tabel_header`} >
          <TableRow id={`inventory_tabel_header_row`} >
            <TableCell id={`inventory_tabel_header_outlet_cell`} >Outlet</TableCell>
            <TableCell id={`inventory_tabel_header_current_inventory_cell`}>Current Inventory</TableCell>
            <TableCell id={`inventory_tabel_header_apr_cell`}>PAR</TableCell>
          </TableRow>
        </TableHead>
        <TableBody id={`inventory_tabel_body`}>
          {inventory?.map(row => (
            <TableRow id={`inventory_tabel_row_${row.stockId}`} key={row.stockId}>
              <TableCell id={`inventory_tabel_market_cell_${row.stockId}`}>{row.market.name}</TableCell>
              <TableCell id={`inventory_tabel_count_cell_${row.stockId}`}>{row.count}</TableCell>
              <TableCell id={`inventory_tabel_count_cell_value_${row.stockId}`}>{0}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
}

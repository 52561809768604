import React, { useEffect, useState } from 'react';
import to from 'await-to-js';
import { useHistory } from 'react-router-dom';
import csvDownload from 'json-to-csv-export'
import 'jspdf-autotable'
import jsPDF from 'jspdf';
import { format } from 'date-fns';
import * as Sentry from '@sentry/react';

import ResponseModal from 'app/components/Helpers/ResponseModal';
import { accessPermission } from 'app/common/helperFunction';
import { getComparator, stableSort } from 'app/services/table';
import LoadingSpinner from 'app/partials/content/LoadingSpinner';
import { useCustomResponse } from 'app/hooks/useCustomResponse';
import { CANCEL_TOKEN } from "app/common/constant";

import ExportAndCustomFilters from '../../../partials/content/ExportAndCustomFilters';
import SetupOperatorsTable from './components/SetupOperatorsTable/SetupOperatorsTable';
import { getOperatorV2 } from '../../../services/account';

import styles from './setup-operators.module.scss';
import { reportFormats } from 'app/services/reports';

const parents = [{
  id: -1,
  name: 'None'
}, {
  id: 2,
  name: 'Alta'
}];

export default function SetupOperators(props) {
  const { filter: { locations } } = props;
  const { push } = useHistory();
  const [operators, setOperators] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
  });
  const [totalCount, setTotalCount] = useState(0);
  const [responseValue, setResponseValue] = useCustomResponse()

  const order = "asc";
  const orderBy = "id";
  /**
   * Trigger data fetching on component initial rendering
   */
  useEffect(() => {
    if(!accessPermission()){
      push({pathname: "/dashboard"});
    }
    if (locations && locations.length){
      getOperatorsList();
    }
    // eslint-disable-next-line
  }, [pagination]);

  /**
   * Get list of all available operator users
   * QUESTION: should we load here all account related operator users
   * or just general, across all accounts?
   * @returns {Promise<void>}
   */
  async function getOperatorsList() {
    const [err, res] = await to(getOperatorV2(pagination));
    setIsLoaded(true);

    if (err){
      if(err.message === CANCEL_TOKEN) return;
      Sentry.captureException(err);
      setResponseValue({...responseValue, isMessageOpen: true, isSuccess:false, message:`Error while getting operator list. ${err}`});    
      return console.error('Error on setup operators fetch: ', err);
    }
    const {rows, count} = res.data.account;
    const sortedOperators = stableSort(rows, getComparator(order, orderBy));
    setOperators(sortedOperators);
    setTotalCount(count);
  }

  function onOperatorChange(operator, parentId) {
    const updatedOperators = operators.map(item => {
      if (item.id !== operator.id)
        return item;

      return {
        ...item,
        parent: parents.find(p => p.id === parentId)
      };
    });

    setOperators(updatedOperators);
  }

  async function handleExport(type) {
  /**
   * check there is data to download or not
   */
    if(operators.length === 0) return;

    if(type === 'pdf'){

      const exportRows = operators.map(ele => [
        ele.name? ele.name: '',
        ele.id? ele.id: '' ,
        ele.firstName && ele.lastName ? `${ele.firstName} ${ele.lastName}` : 'N/A',
        ele.provider?.name ? ele.provider.name: 'N/A' ,
        ele.processor?.name ? ele.processor.name: 'N/A' ,
       ]);

        const unit = "pt";
        const size = "A4";
        const orientation = "portrait";
        const marginLeft = 30;
        const doc = new jsPDF(orientation,unit, size);
        doc.setFontSize(15);

        const title = "Setup operators";
        const headers = [['Name', 'Account ID', 'Contact', 'Inventory System', 'Credit Card Processor']];

        let content = {
          startY: 50,
          head: headers,
          body: exportRows,
          styles: {overflow: 'linebreak',
                fontSize: 8},
        };

        doc.text(title, marginLeft, 30);
        doc.autoTable(content);
        doc.save(`Setup operators - ${format(new Date(), 'MM/dd/yyyy')}.${type}`);

    } else if (type === 'csv') {

        const exportRows = [];
        operators.forEach(ele => {
          let obj = {
            Name : ele.name? ele.name: '',
            'Account ID' : ele.id? ele.id: "",
            Contact: ele.firstName && ele.lastName ? `${ele.firstName} ${ele.lastName}` : 'N/A' ,
            'Inventory System': ele.provider?.name ? ele.provider.name: 'N/A',
            'Credit Card Processor': ele.processor?.name ? ele.processor.name: 'N/A' ,
          }
          exportRows.push(obj);
        })

      csvDownload(exportRows, `Setup operators - ${format(new Date(), 'MM/dd/yyyy')}.${type}`);
    }
  }

  return (
    <>
      <ExportAndCustomFilters onExport={handleExport} newFilterTag={reportFormats.PDF}/>
      {!isLoaded ? (
        <LoadingSpinner/>
      ) : (
      <>
        <div id={`setup_operators_container`} className={styles.wrapper}>
          <SetupOperatorsTable
            rows={operators}
            totalCount={totalCount}
            parents={parents}
            onChange={onOperatorChange}
            onPaginationChange={(value) => setPagination(value)}
          />
        </div>
        <ResponseModal
          isSuccess={responseValue?.isSuccess}
          message={responseValue?.message}
          open={responseValue?.isMessageOpen}
          onClose={value => setResponseValue({...responseValue, isMessageOpen:value})}
        />
      </>)}
    </>
  );
}

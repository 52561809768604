import React from 'react';
import clsx from 'clsx';
import {
  Avatar,
  Button,
  Card,
  CardContent,
  FormControlLabel,
  Switch,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@material-ui/core';
import { Check, CloudUpload } from '@material-ui/icons';

import styles from './operator-create.module.scss';

const parents = [{
  id: -1,
  name: 'None'
}, {
  id: 2,
  name: 'Alta'
}];

const roles = [{
  id: 1,
  name: 'Account Owner'
}, {
  id: 2,
  name: 'Admin'
}, {
  id: 3,
  name: 'Manager'
}];

export default function OperatorCreate({ newOperator, setNewOperator }) {
  return (
    <Card id={`operator_create_wrapper`}>
      <CardContent id={`operator_create_card_content_wrapper`} className="d-flex">

        <div id={`operator_create_inner_wrapper`} className="col-2 d-flex align-items-center justify-content-center">
          <div id={`operator_create_content_wrapper_0`} className="d-flex flex-column align-items-center">
            <Avatar alt="Remy Sharp" style={{ width: 80, height: 80 }}/>

            <Button
              id={`operator_create_choose_img_btn`}
              className={styles.button}
              startIcon={<CloudUpload/>}
              size="small"
              variant="outlined"
              color="primary"
              component="label"
            >
              Choose Image
              <input
                type="file"
                style={{ display: 'none' }}
              />
            </Button>
          </div>
        </div>

        <div id={`operator_create_content_wrapper_1`} className="col-10">

          <div id={`operator_create_content_inner_wrapper`} className="d-flex justify-content-end col-12">
            <FormControlLabel
              id={`operator_create_form_control`}
              control={(
                <Switch
                  checked={newOperator.enabled}
                  onChange={() => setNewOperator({ ...newOperator, enabled: !newOperator.enabled })}
                  color="secondary"
                />
              )}
              label={newOperator.enabled ? 'Enabled' : 'Disabled'}
            />
          </div>

          <div  id={`operator_create_content_wrapper_2`}  className="d-flex">
            <div id={`operator_create_content_inner_wrapper_1`} className="col-6">
              <TextField
                margin="normal"
                id="Operator_firstName"
                label="First name"
                variant="outlined"
                value={newOperator.firstName}
                onChange={e => setNewOperator({ ...newOperator, firstName: e.target.value })}
                type="text"
                fullWidth
              />
            </div>

            <div  id={`operator_create_content_inner_wrapper_2`} className="col-6">
              <TextField
                margin="normal"
                id="Operator_lastName"
                label="Last name"
                variant="outlined"
                value={newOperator.lastName}
                onChange={e => setNewOperator({ ...newOperator, lastName: e.target.value })}
                fullWidth
              />
            </div>
          </div>

          <div className="d-flex align-items-end">
            <div id={`operator_create_content_inner_wrapper_3`} className="col-6">
              <TextField
                margin="normal"
                id="Operator_password"
                label="Password"
                variant="outlined"
                type="password"
                value={newOperator.password}
                onChange={e => setNewOperator({ ...newOperator, password: e.target.value })}
                fullWidth
              />
            </div>

            <div id={`operator_create_content_inner_wrapper_4`} className={clsx('col-6', styles.select)}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="parent-select-label">Parent</InputLabel>
                <Select
                  labelId="role-select-label"
                  id="Operator_parent_select"
                  value={newOperator.parent.id}
                  onChange={e => setNewOperator({
                    ...newOperator, parent: parents.find(p => p.id === e.target.value)
                  })}
                  label="Parent"
                >
                  {parents.map(role => {
                    const { id, name } = role;
                    return (
                      <MenuItem key={id} value={id}>
                        {name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </div>

          <div className="d-flex align-items-end">
            <div id={`operator_create_content_inner_wrapper_5`} className="col-6">
              <TextField
                margin="normal"
                id="Operator_email"
                label="Email"
                variant="outlined"
                type="email"
                value={newOperator.email}
                onChange={e => setNewOperator({ ...newOperator, email: e.target.value })}
                fullWidth
              />
            </div>
            <div id={`operator_create_content_inner_wrapper_6`} className={clsx('col-6', styles.select)}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="role-select-label">Role</InputLabel>
                <Select
                  labelId="role-select-label"
                  id="Operator_role_select"
                  value={newOperator.role.id}
                  onChange={e => setNewOperator({
                    ...newOperator, role: roles.find(r => r.id === e.target.value)
                  })}
                  label="Role"
                >
                  {roles.map(role => {
                    const { id, name } = role;
                    return (
                      <MenuItem key={id} value={id}>
                        {name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </div>

          <div className={clsx('col-12 d-flex justify-content-between', styles.buttonsWrapper)}>
            <div id={`operator_create_content_inner_wrapper_7`}>
              <Button
                id={`operator_create_save_button`}
                startIcon={<Check/>}
                type="button"
                size="medium"
                variant="contained"
                color="primary"
                disableElevation
                onClick={() => {}}
              >
                Save
              </Button>
            </div>
          </div>
        </div>

      </CardContent>
    </Card>
  );
}

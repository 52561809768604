import React from "react";
import clsx from "clsx";
import {
  Card,
  CardContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Button,
} from "@material-ui/core";
import { countries } from "countries-list";

import styles from "./address.module.scss";

export default function Address(props) {
  const { address, onUpdate, wrapToCard = true, idName, onSave } = props;

  /**
   * Render address form
   * @returns {*}
   */
  function renderForm() {
    return (
      <>
        <div id={`address_wrapper`} className={styles.addressWrapper}>
          <TextField
            className={styles.addressField}
            margin="normal"
            id={`${idName}Address_streetLine1`}
            label="Street Line 1"
            variant="outlined"
            value={address == null ? "" : address.streetOne}
            onChange={(e) =>
              onUpdate({ ...address, streetOne: e.target.value })
            }
            fullWidth
          />

          <TextField
            className={styles.addressField}
            margin="normal"
            id={`${idName}Address_streetLine2`}
            label="Street Line 2"
            variant="outlined"
            placeholder="Optional"
            value={address == null ? "" : address.streetTwo}
            onChange={(e) =>
              onUpdate({ ...address, streetTwo: e.target.value })
            }
            fullWidth
          />

          <TextField
            className={styles.addressField}
            margin="normal"
            id={`${idName}Address_city`}
            label="City"
            variant="outlined"
            value={address == null ? "" : address.city}
            onChange={(e) => onUpdate({ ...address, city: e.target.value })}
            fullWidth
          />

          <TextField
            className={styles.addressField}
            margin="normal"
            id={`${idName}Address_state`}
            label="State"
            variant="outlined"
            value={address == null ? "" : address.state}
            onChange={(e) => onUpdate({ ...address, state: e.target.value })}
            fullWidth
          />

          <TextField
            className={styles.addressField}
            margin="normal"
            id={`${idName}Address_postcode`}
            label="Postcode"
            variant="outlined"
            value={address == null ? "" : address.zip}
            onChange={(e) => onUpdate({ ...address, zip: e.target.value })}
            fullWidth
          />

          <FormControl
            fullWidth
            variant="outlined"
            margin="normal"
            className={styles.addressField}
          >
            <InputLabel htmlFor="country">Country</InputLabel>

            <Select
              label="Country"
              labelId="country"
              id={`${idName}Address_country`}
              className={styles.input}
              value={
                address == null
                  ? "USA"
                  : address.country === "USA"
                  ? "United States"
                  : address.country
              }
              onChange={(e) =>
                onUpdate({ ...address, country: e.target.value })
              }
            >
              {Object.keys(countries).map((countryKey) => {
                const { name } = countries[countryKey];

                return (
                  <MenuItem key={countryKey} value={name}>
                    {name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <Button
            id={`address_save_button`}
            className={styles.saveButton}
            size="large"
            variant="outlined"
            color="primary"
            disableElevation
            onClick={() => onSave()}
          >
            Save
          </Button>
        </div>
      </>
    );
  }

  return (
    <>
      {wrapToCard ? (
        <Card id={`address_card_wrapper`} className={styles.cardWrapper}>
          <CardContent id={`address_card_content_wrapper`} className="d-flex justify-content-between">
            {renderForm()}
          </CardContent>
        </Card>
      ) : (
        <div
          id={`address_card_content_wrapper_1`}
          className={clsx("d-flex justify-content-between", styles.cardWrapper)}
        >
          {renderForm()}
        </div>
      )}
    </>
  );
}

import { s3, REACT_APP_AWS_S3_BUCKET } from "app/services/helpers/getAwsConfig";


export async function uploadImageToAws(file, fileName = "") {
    if (!file) {
      return;
    }
    /**
     * @param upload image to s3 bucket
     * */ 
    const params = { 
      Bucket: REACT_APP_AWS_S3_BUCKET, 
      Key: fileName, 
      Body: file,
    };
    
    const {Bucket , Key } = await s3.upload(params).promise();
    const url = `https://${Bucket}/${Key}`
    return url
  }
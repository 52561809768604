export const fundsReportActionTypes = {
  GetFundsReport: '[Get Fund Report Entities] Action',
  StoreFundsReport: '[Store Fund Transaction Report Entities] Action',
  ClearFundsReport: '[Clear Fund Transaction Report Entities] Action',
};

export const fundsReportActions = {
  GetFundsReport: fundsReports => ({
    type: fundsReportActionTypes.GetFundsReport,
    payload: fundsReports
  }),
  StoreFundsReport: fundsReports => {
    return({
    type: fundsReportActionTypes.StoreFundsReport,
    payload: fundsReports
  })
},
  ClearFundsReport: fundsReportError => ({
    type: fundsReportActionTypes.ClearFundsReport,
    payload: fundsReportError
  }),
};

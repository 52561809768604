import React from "react";
import { Checkbox, FormControlLabel, Typography } from "@material-ui/core";

export default function CartsStatusOptions(props) {
  const { cartsStatus, setCartsStatus } = props;

  const handleStatusChange = (index) => {
    cartsStatus[index].status = !cartsStatus[index].status;
    setCartsStatus([...cartsStatus]);
  };

  return (
    <>
      {cartsStatus.map((eachCart, index) => {
        return (
          <div
            id={`cart_status_options_form_control_label`}
            key={index}
            style={{ marginLeft: 0, display: "flex", alignItems: "center" }}
          >
            <Checkbox
              id={`cart_status_options_check_box`}
              checked={cartsStatus[index].status}
              onChange={(e) => handleStatusChange(index)}
              defaultChecked
              sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
            />

            <Typography style={{ whiteSpace: "nowrap" }}>
              {cartsStatus[index].label}
            </Typography>
          </div>
        );
      })}
    </>
  );
}

import React from 'react';
import clsx from 'clsx';
import Select from 'react-select';
import { Card, CardContent, FormControl, TextField, Typography } from '@material-ui/core';

import BasicPromotion from '../BasicPromotion/BasicPromotion';
import CustomButton from '../../../CustomButton/CustomButton';
import ProductsFilter from '../ProductsFilter/ProductsFilter';

import styles from './advanced-promotion.module.scss';

const products = [{
  id: 1,
  title: 'All',
  value: 'all'
}, {
  id: 2,
  title: 'Specific',
  value: 'specific'
}];

const whenActionsTypes = [
  {
    label: 'Buys the following items',
    value: 'buys'
  }
];

const thenActionsTypes = [
  {
    label: 'Get the following items',
    value: 'get'
  },
  {
    label: 'Get the following discount',
    value: 'discount'
  }
];

export default function AdvancedPromotion(props) {
  const {
    basic,
    quantity,
    productType,
    productsFilter,
    whenFilter,
    thenFilter,
    onFilterChange,
    changeAdvancedPromotion
  } = props;

  function onBasicPromotionFilterChange(value, property) {
    changeAdvancedPromotion({ ...basic,
      Filter: { ...basic.productsFilter, [property]: value } }, 'basic');
  }

  function onBasicPromotionChange(value, property) {
    changeAdvancedPromotion({ ...basic, [property]: value }, 'basic');
  }

  return (
    <>
      <div id={`advance_promotion_container`} className={styles.wrapper}>
        <div id={`basic_promotion_inner_containe`}  className="d-flex align-items-center justify-content-center">
          <b id={`basic_promotion_inner_title`} >When a Customer</b>
          <Select
            value={whenFilter}
            id="when-filter"
            name="when-filter"
            options={whenActionsTypes}
            menuPortalTarget={document.body}
            className={clsx('basic-multi-select', styles.filter)}
            onChange={e => changeAdvancedPromotion(e, 'whenFilter')}
          />
        </div>
        <Card id={`basic_promotion_card_inner_container_wrapper`}  className={clsx(styles.card, 'col-6 d-flex align-self-center')} variant="outlined">
          <CardContent id={`basic_promotion_card_inner_content_wrapper`}  className={styles.content}>
            <div id={`basic_promotion_outer_container_wrapper`}  className="col-12">
              <div id={`basic_promotion_inner_container_wrapper`}  className="d-flex justify-content-between align-items-center">
                <div id={`basic_promotion_bye_container_wrapper`}  className="col-2">
                  <Typography id={`basic_promotion_bye_label`}  variant="h4">Buy</Typography>
                </div>

                <div id={`basic_promotion_form_container`}  className="col-4">
                  <FormControl id={`basic_promotion_form_quantity_container`} fullWidth>
                    <TextField
                      margin="normal"
                      id="quantity"
                      label="Quantity"
                      placeholder="0"
                      value={quantity}
                      onChange={e => changeAdvancedPromotion(e.target.value, 'quantity')}
                      variant="outlined"
                    />
                  </FormControl>
                </div>
                <div id={`basic_promotion_inner_product_container`} className="col-4">
                  <Typography id={`basic_promotion_product_typography`}>Product</Typography>
                  <div className="d-flex">
                    {products.map(product => (
                      <CustomButton
                        {...product}
                        isActive={product.value === productType}
                        onClick={e => changeAdvancedPromotion(e, 'productType')}
                        key={product.id}
                        hasDivider={false}
                        small
                      />
                    ))}
                  </div>
                </div>
              </div>

              {productType !== 'all'
                ? (
                  <ProductsFilter
                    productsFilter={productsFilter}
                    onFilterChange={onFilterChange}
                  />
                )
                : null}
              <div/>
            </div>
          </CardContent>
        </Card>

        <div id={`basic_promotion_outer_desc_container`} className="d-flex align-items-center justify-content-center">
          <b>Then they will</b>
          <Select
            value={thenFilter}
            id="then-filter"
            name="then-filter"
            options={thenActionsTypes}
            menuPortalTarget={document.body}
            className={clsx('basic-multi-select', styles.filter)}
            onChange={e => changeAdvancedPromotion(e, 'thenFilter')}
          />
        </div>

        <Card id={`basic_promotion_card_desc_container`} className={clsx(styles.card, 'col-6 d-flex align-self-center')} variant="outlined">
          <CardContent id={`basic_promotion_card_content_outer_desc_container`} className={styles.content}>
            <BasicPromotion
              {...basic}
              onFilterChange={onBasicPromotionFilterChange}
              changeBasicPromotion={onBasicPromotionChange}
              isPartOfAdvanced
            />
          </CardContent>
        </Card>
      </div>
    </>
  );
}

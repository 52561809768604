import React, { useState } from 'react';
import clsx from 'clsx';
import { Button, Card, CardContent, FormHelperText, TextField } from '@material-ui/core';

import styles from './vms-provider.module.scss';
const { REACT_APP_CANTALOUPE_PROVIDER_ID,REACT_APP_VENDSYS_PROVIDER_ID,REACT_APP_VENDMAX_PROVIDER_ID,REACT_APP_VENDHQ_PROVIDER_ID } = process.env;

export default function VmsProvider(props) {
  const {
    provider = {
      type: 'cantaloupe',
      operatorId: '',
      contactPerson: ''
    },
    isApplicationsView = false,
    onVmsProvider,
    onSubmit,
    onPreviousClick,
    push,
    cancelForm,
    displayButtons = true,
  } = props;

  const [labelText, setLabelText] = useState(false);
  const providersData = [
          {id:REACT_APP_CANTALOUPE_PROVIDER_ID,providerType:'cantaloupe',title:"Cantaloupe"},
          {id:REACT_APP_VENDHQ_PROVIDER_ID,providerType:'vendHq',title:"VendHq"},
          {id:REACT_APP_VENDMAX_PROVIDER_ID,providerType:'vendMax',title:"VendMax"},
          {id:REACT_APP_VENDSYS_PROVIDER_ID,providerType:'vendSys',title:"VendSys"},
          {id:"",providerType:'',title:"Unsure/None"}
                        ]

  function helperText(){
    return(
      <>
        <FormHelperText style={{ color: "red", fontSize: "1rem" }}>This is required!</FormHelperText>
      </>
    )
  }

  function nextPage(){
    if (!provider?.operatorId?.trim() || !provider?.contactPerson?.trim()) {
      setLabelText(true);
    } else {
      setLabelText(false);
      onSubmit();
    }
    }
  

  return (
    <>
      <div>
        <div className={styles.wrapper}>
          <div className={styles.cardWrapper}>
            {providersData?.map((providerItem)=>{
              const {id,providerType,title} = providerItem
              return   <Card
              variant="outlined"
              className={clsx(styles.cardAlign, provider?.type === providerType && styles.activeCard)}
              onClick={() => onVmsProvider({ type: providerType, providerId: id })}
            >
              <CardContent className="d-flex align-items-center justify-content-center" style={{ paddingBottom: 16 }}>
                {title}
              </CardContent>
            </Card>
            })}

          </div>

          <div className={clsx('col-12', styles.fields)}>
            <Card variant="outlined">
              <CardContent>
                <div className={styles.subFields}>
                  <TextField
                    margin="normal"
                    id="operatorId"
                    label="Operator ID"
                    variant="outlined"
                    value={provider?.operatorId || ""}
                    onChange={e => onVmsProvider({ operatorId: e.target.value })}
                    helperText={(!provider?.operatorId?.trim() && labelText) || (provider?.operatorId?.trim() === '' && labelText)? helperText(): ''}
                    fullWidth
                  />
                </div>
                <div className={styles.subFields}>
                  <TextField
                    margin="normal"
                    id="contactPerson"
                    label="Point of Contact"
                    variant="outlined"
                    value={provider?.contactPerson || ""}
                    onChange={e => onVmsProvider({ contactPerson: e.target.value })}
                    helperText={(!provider?.contactPerson?.trim() && labelText) ||(provider?.contactPerson?.trim() === '' && labelText)? helperText() : ''}
                    fullWidth
                  />
                </div>
               {displayButtons && <div className={styles.subFields}>
                  <Button
                    size="large"
                    variant="contained"
                    color="primary"
                    style = {{margin: '0px 20px 10px 0px'}}
                    disableElevation
                    onClick={() => nextPage()}
                  >
                    Submit
                  </Button>
                  <Button
                    size="large"
                    variant="contained"
                    color="primary"
                    style = {{margin: '0px 0px 10px 0px'}}
                    disableElevation
                    onClick={() => (push({ pathname: "/auth/login" }), cancelForm())}
                  >
                    Cancel
                  </Button>
                </div>}
              </CardContent>
            </Card>
          </div>
        </div>
      </div>

      {isApplicationsView ? (
        <div className={styles.buttonWrapper}>
          <Button
            size="large"
            variant="contained"
            color="primary"
            disableElevation
            onClick={() => onPreviousClick()}
          >
            Previous
          </Button>
        </div>
      ) : null}
    </>
  );
}

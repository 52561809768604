import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { promotionsActionTypes } from '../actions/promotions.action';
import { RESET_STATE } from '../actions';

const initialPromotionsState = {
  promotions: {},
  promotionsList: [],
  selectedPromotion: {}
};

export const reducer = persistReducer(
  {
    storage,
    key: 'yoke-promotions',
    whitelist: [
      'promotions',
      'selectedPromotion',
      'promotionsList'
    ]
  },
  (state = initialPromotionsState, action) => {
    switch (action.type) {
      case promotionsActionTypes.StorePromotions: {
        let promotions = {};
        let promotionsList = [];

        if (action.payload && action.payload.length) {
          promotionsList = action.payload.map(promotion => {
            const { id, name, startDate, endDate, status, locations } = promotion;

            return {
              id,
              name,
              startDate,
              endDate,
              status,
              availableOn: locations && locations.length ? locations.length : 0
            };
          });
          promotions = action.payload.reduce((acc, promotion) => {
            const { id } = promotion;

            return {
              ...acc,
              [id]: promotion
            };
          }, {});
        }

        return {
          ...state,
          promotions,
          promotionsList
        };
      }

      case promotionsActionTypes.SelectActivePromotion: {
        const id = action.payload;
        const selectedPromotion = { ...state.promotions[id] };

        return {
          ...state,
          selectedPromotion
        };
      }

      case promotionsActionTypes.ClearSelectedPromotion: {
        return {
          ...state,
          selectedPromotion: {}
        };
      }

      case RESET_STATE: {
        return initialPromotionsState;
      }

      default: {
        return state;
      }
    }
  }
);

import React from 'react';
import MaterialTable from 'material-table';

export default function InviteTable(props) {
  const {
    rows,
    tableRef,
    onUpdate,
    onRowAdd,
    onSelectionChange
  } = props;

  return (
    <MaterialTable
      tableRef={tableRef}
      onSelectionChange={onSelectionChange}
      options={{
        addRowPosition: 'first',
        search: false,
        grouping: false,
        emptyRowsWhenPaging: false,
        pageSize: 25,
        pageSizeOptions: [10, 25, 50],
        sorting: false,
        toolbar: false,
        showTitle: false,
        draggable: false,
        selection: true,
        actionsColumnIndex: 7
      }}
      editable={{
        onRowAdd: newData => new Promise(resolve => {
          onRowAdd(newData);
          resolve();
        }),
        onRowUpdate: newData => new Promise(resolve => {
          onUpdate(newData);
          resolve();
        })
      }}
      columns={[
        { title: 'Email', field: 'email' },
        { title: 'First Name', field: 'firstName' },
        { title: 'Last Name', field: 'lastName' },
        { title: 'Current Location', field: 'currentLocation' },
        {
          title: 'Yoke Cash Balance',
          field: 'yokeCashBalance',
          type: 'numeric',
          render: rowData => (
            <span>
              $
              {rowData.yokeCashBalance}
            </span>
          )
        },
        {
          title: 'Loyalty Points',
          field: 'loyaltyPoints',
          type: 'numeric',
          render: rowData => (
            <span>
              {rowData.loyaltyPoints}
              &nbsp;
              points
            </span>
          )
        }
      ]}
      data={rows}
    />
  );
}

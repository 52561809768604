export const salesReportActionTypes = {
  GetSalesReports: '[Get Sales Report Entities] Action',
  StoreSalesReports: '[Store Sales Report Entities] Action',
  ClearSalesReports: '[Clear Sales Report Entities] Action',
};

export const salesReportingActions = {
  getSalesReports: salesReports => ({
    type: salesReportActionTypes.GetSalesReports,
    payload: salesReports
  }),
  storeSalesReport: salesReports => ({
    type: salesReportActionTypes.StoreSalesReports,
    payload: salesReports
  }),
  ClearSalesReports: (salesReportError) => ({
    type: salesReportActionTypes.ClearSalesReports,
    payload: salesReportError
  }),
};

import React, { useEffect, useState } from "react";
import to from "await-to-js";
import clsx from "clsx";
import {
  Button,
  Card,
  CardContent,
  Divider,
  TextField,
  Typography,
} from "@material-ui/core";
import { Check } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import * as Sentry from "@sentry/react";

import ResponseModal from "app/components/Helpers/ResponseModal";
import { accessPermission } from "app/common/helperFunction";
import { useCustomResponse } from "app/hooks/useCustomResponse";
import { CANCEL_TOKEN, DELETE_LOCATION_GROUP_ERROR_MESSAGE, LOCATIONS, LOCATION_SELECTION_REQUIRED_MESSAGE, UPDATE_LOCATION_GROUP_ERROR_MESSAGE, UPDATE_LOCATION_GROUP_SUCCESS_MESSAGE } from "app/common/constant";
import LoadingSpinner from "app/partials/content/LoadingSpinner";

import {
  getGroupById2,
  createNewLocationGroup,
  updateLocationGroup,
  deleteLocationGroup,
} from "../../../../../../../components/Setup/SetupLocations/services/groups";
import useCustomHeader from "../../../../../../../hooks/useCustomHeader";
import RemoveGroup from "../../../../../../../partials/content/Groups/RemoveGroup";
import Search from "../../../../../../../partials/content/Customers/Search";
import SetupLocationsTable from "../../../SetupLocationsTable/SetupLocationsTable";
import { getLocations } from "../../../../../../../services/locations";
import {
  getLoggedUserAccountId2,
  hasMultipleAccounts,
} from "../../../../../../../services/helpers/account-id";
import OperatorSpecificModal from "../../../../../../Helpers/OperatorSpecificModal";

import styles from "./location-group-details.module.scss";
import { INITIAL_PAGE_INDEX, PAGINATION_INITIAL_STATE } from "app/common/utils/generic";

export default function LocationGroupDetails() {
  const { push } = useHistory();
  const [currentGroup, setCurrentGroup] = useState({
    name: "",
  });
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [resetSelected, setResetSelected] = useState(false);
  const [locations, setLocations] = useState([]);
  const [isLoaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [responseValue, setResponseValue] = useCustomResponse();
  const [operatorSpecificModalOpen, setOperatorSpecificModalOpen] = useState(
    false
  );
  const[pagination,setPagination]=useState({});
  const [rowsPerPage, setRowsPerPage] = useState(PAGINATION_INITIAL_STATE.limit);
  const [page, setPage] = useState(INITIAL_PAGE_INDEX);
  const accountId = getLoggedUserAccountId2();
  const {
    isNew,
    id: groupId,
    arrowBack,
    setSubArrowBack,
    subHeader,
    setSubHeader,
  } = useCustomHeader({
    staticHeader: sessionStorage.getItem("current-group-name"),
  });
  const [savingGroup, setSavingGroup] = useState(false);
  /**
   * Reset selected customers on moving to group
   */
  useEffect(() => {
    if (!resetSelected) return;

    setResetSelected(false);
  }, [resetSelected]);

  /*
   * Search locations
   */
  useEffect(() => {
    proceedCurrentGroup();
  }, [searchQuery]);

  /**
   * Fetch data on component rendering
   */
  useEffect(() => {
    if (!accessPermission("SetupAll")) {
      push({ pathname: "/dashboard" });
    }
    setSubArrowBack((arrowBack.style.display = "block"));
    if (isNew) {
      setSubHeader((subHeader.innerText = "Create New"));
    }
    setLoaded(false);
    proceedCurrentGroup();
  }, []);

  useEffect(()=>{
    proceedCurrentGroup();
  },[page,rowsPerPage])

  async function proceedCurrentGroup() {
    if (hasMultipleAccounts()) {
      setOperatorSpecificModalOpen(true);
      return;
    }

    const [err, locationRes] = await to(getLocations(true, true, searchQuery, null,null,page+1,rowsPerPage ));
    if (err) {
      Sentry.captureException(err);
      if (err.message === CANCEL_TOKEN) return;
      if (!hasMultipleAccounts())
        setResponseValue({
          ...responseValue,
          isMessageOpen: true,
          isSuccess: false,
          message: `Error while getting location list. ${err}`,
        });
      return console.error(err);
    }
    setLocations(locationRes.data.Locations);
    if(locationRes?.data?.pagination){
      const {pagination} = locationRes.data
      setPagination(pagination)
    }
    if (!isNew) {
      const [groupErr, groupRes] = await to(getGroupById2(groupId));

      var preselected = [];
      groupRes.data.grplocs
        .map((x) => x.LocationId)
        .forEach((record) => {
          preselected.push({ id: record });
        });
      setSelectedLocations(preselected);
    }
    setLoaded(true);
  }
  const handleInformationUpdate = () => {
    if (!selectedLocations?.length) {
      setResponseValue({
        ...responseValue,  
        isMessageOpen: true,
        isServerError: false,
        isSuccess: false,
        message: LOCATION_SELECTION_REQUIRED_MESSAGE,
      })
    } else {
      handleSave();
    }
  };
  const handleChangePage = (event,newPage) => {
    setPage(newPage);
  };
 
  const handleChangeRowsPerPage = (event) => {
    const newSize = parseInt(event.target.value);
    const newPage = parseInt(((page * rowsPerPage) + 1) / newSize);
    setRowsPerPage(newSize);
    setPage(newPage);
  };

  async function onDeleteGroup() {
    const [err, res] = await to(deleteLocationGroup(accountId, groupId));
    if (err) {
      Sentry.captureException(err);
      setResponseValue({
        ...responseValue,
        isMessageOpen: true,
        isSuccess: false,
        message: `${DELETE_LOCATION_GROUP_ERROR_MESSAGE}. ${err}`,
      });
      return;
    } 
    if (res &&res?.status === 200) {
      setResponseValue({
        ...responseValue,
        isMessageOpen: true,
        isSuccess: true,
        message: `${res?.data?.message}`,
      });
      }
      return 
  }
  

  async function handleSave() {
    setSavingGroup(true);

    let locationIds = selectedLocations.map((l) => l.id);
    const payload = {
      name: currentGroup.name,
      description: "",
      roles: [],
      locationIds: locationIds,
    };
    if (isNew) {
      const [err, res] = await to(createNewLocationGroup(accountId, payload));
      if (err) {
        Sentry.captureException(err);
        setSavingGroup(false);
      } else {
        push({ pathname: "/setup/groups" });
      }
    } else {
      const pathname = window.location.pathname;
      const groupId = pathname.split("/").pop();

      if (selectedLocations.length) {
        let updateLocationsPayload = selectedLocations.map(
          (selectedLoc) => selectedLoc.id
        );
        const [err, res] = await to(
          updateLocationGroup(accountId, groupId, updateLocationsPayload)
        );
        if (err) {
          Sentry.captureException(err);
          setResponseValue({
            ...responseValue,
            isMessageOpen: true,
            isSuccess: false,
            message: `${UPDATE_LOCATION_GROUP_ERROR_MESSAGE} ${err}`,
          });
          return;
        }
        if (
          res &&
          res?.status === 200 &&
          res?.data?.updatedGroupsData?.length
        ) {
          setResponseValue({
            ...responseValue,
            isMessageOpen: true,
            isSuccess: true,
            message: `${res.data.message}`,
          });
        }
      }
    }

    if (currentGroup.name && selectedLocations.length) {
      setResponseValue({
        ...responseValue,
        isMessageOpen: true,
        isSuccess: true,
        message: UPDATE_LOCATION_GROUP_SUCCESS_MESSAGE,
      });
    }
  }

  if (
    !responseValue?.isMessageOpen &&
    responseValue?.isSuccess &&
    responseValue?.message
  ) {
    push({ pathname: "/setup/groups" });
  }
  /**
   * Expand row
   * @param row
   */
  function onExpandRow(row) {
    const updatedLocations = locations.map((location) => {
      if (location.id === row.id)
        return {
          ...location,
          isExpanded: !location.isExpanded,
        };

      return location;
    });
    setLocations(updatedLocations);
  }

  function trap(e) {
    setSelectedLocations(e);
  }

  return (
    <>
      <Card id={`location_group_details_card_wrapper`}>
        {isNew ? (
          <CardContent id={`location_group_details_card_content_wrapper`}>
            <div className="col-4">
              <TextField
                margin="normal"
                id="title"
                label="Title"
                variant="outlined"
                placeholder="Enter a title"
                type="text"
                value={currentGroup.name}
                onChange={(e) =>
                  setCurrentGroup({ ...currentGroup, name: e.target.value })
                }
                fullWidth
              />
            </div>
          </CardContent>
        ) : null}
      </Card>
      <>
        <div
          className={clsx(styles.wrapper, {
            [styles.noMargin]: !isNew,
          })}
          id={`location_group_details_search_wrapper`}
        >
          <Search
            id="locationGroup"
            label="Search by location name"
            placeholder="Search"
            query={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
        {isLoaded ? (
          <>
            <div
              id={`location_group_details_pick_up_content_wrapper`}
              className={clsx(styles.wrapper, { [styles.noMargin]: !isNew })}
            >
              {isNew ? (
                <>
                  <Typography variant="h4">Pick Locations</Typography>
                  <Divider />
                </>
              ) : null}

              <div
                id={`location_group_details_setup_location_tabel_wrapper`}
                className={styles.tableWrapper}
              >
                <SetupLocationsTable
                  rows={locations}
                  preselected={selectedLocations}
                  onExpandRow={onExpandRow}
                  pagination={pagination}
                  onEditRowClick={({ id }) =>
                    push({ pathname: `/setup/locations/${id}` })
                  }
                  resetSelected={resetSelected}
                  onSelectLocations={(e) => trap(e)}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  rowsPerPage={rowsPerPage}
                  page={page}
                />
              </div>
            </div>
            {!isNew ? (
              <>
                <div
                  id={`location_group_details_button_wrapper`}
                  className="d-flex justify-content-end"
                >
                  <Button
                    startIcon={<Check />}
                    type="button"
                    size="medium"
                    variant="contained"
                    style={{
                      marginRight: "5px",
                      backgroundColor: "#3f51b5",
                      color: "#ffffff",
                    }}
                    color="primary"
                    onClick={handleInformationUpdate}
                    disabled={loading}
                    disableElevation
                    id={`location_group_details_loading_button_wrapper`}
                  >
                    {!loading ? "Save" : "Loading"}
                  </Button>
                  <RemoveGroup
                    totalItems={selectedLocations.length}
                    categoryOfDeletingItem = {LOCATIONS}
                    onUpdate={onDeleteGroup}
                  />
                </div>
              </>
            ) : (
              <div className="d-flex justify-content-end">
                <Button
                  id={`location_group_details_save_button_wrapper`}
                  startIcon={<Check />}
                  type="button"
                  size="medium"
                  variant="contained"
                  color="primary"
                  disabled={savingGroup}
                  disableElevation
                  onClick={handleInformationUpdate}
                >
                  {"Save"}
                </Button>
              </div>
            )}
          </>
        ) : (
          <LoadingSpinner />
        )}
        <ResponseModal
          isSuccess={responseValue?.isSuccess}
          isServerError={responseValue?.isServerError}
          message={responseValue?.message}
          open={responseValue?.isMessageOpen}
          onClose={(value) =>
            setResponseValue({ ...responseValue, isMessageOpen: value })
          }
        />
      </>
      {operatorSpecificModalOpen && (
        <OperatorSpecificModal
          open={operatorSpecificModalOpen}
          onClose={(value) => setOperatorSpecificModalOpen(value)}
        />
      )}
    </>
  );
}

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
  Checkbox,
  IconButton,
  makeStyles
} from '@material-ui/core';
import { EditSharp } from '@material-ui/icons';

import { getDefaultStyles } from '../../../services/table';

const headCells = [
  { id: 'email', numeric: false, disablePadding: true, label: 'Email' },
  { id: 'firstName', numeric: false, disablePadding: false, label: 'First Name' },
  { id: 'lastName', numeric: false, disablePadding: false, label: 'Last Name' },
  { id: 'yokeCashBalance', numeric: false, disablePadding: false, label: 'Yoke Cash Balance' },
  { id: 'loyaltyPoints', numeric: false, disablePadding: false, label: 'Loyalty Points' },
  { id: 'actions', numeric: false, disablePadding: false, label: 'Actions' }
];

function EnhancedTableHead(props) {
  const {
    numSelected,
    rowCount,
    onSelectAllClick
  } = props;

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired
};

const useStyles = makeStyles(getDefaultStyles);

export default function CustomersTable(props) {
  const {
    customersList:{customers: rows =[], customersTotal},
    pagination:{page, limit:rowsPerPage} = {
      page: 1,
      limit: 10
    },
    groupMembers = [],
    onEditRowClick,
    onSelectCustomers,
    selectedCustomersForAdd,
    onPaginationChange,
    onInitialCustomersSelection = () => {},
  } = props;
  const classes = useStyles();
  const [selected, setSelected] = useState(groupMembers.length
    ? rows.filter(row => groupMembers.some((member) => member.id === row.id)) : []);

  useEffect(() => {
    const newSelected = groupMembers.length ? rows.filter(row => 
      groupMembers.some((member) => member.id === row.id) 
      || selectedCustomersForAdd.some((member) => member.id === row.id)) : [] ;
    setSelected(newSelected);
    onSelectCustomers(newSelected);
    onInitialCustomersSelection(newSelected);
  }, [rows])

  useEffect(() => {
    const newSelected = selected.filter( (member) => (
      groupMembers.some(ele => ele.id === member.id) ||
      selectedCustomersForAdd.some(ele => ele.id === member.id)
    ))
    setSelected(newSelected)
  }, [selectedCustomersForAdd])

  /**
   * Handle master toggle click
   */
  function handleSelectAllClick(event) {
    if (event.target.checked) {
      setSelected(rows);
      onSelectCustomers(rows);

      return;
    }

    setSelected([]);
    onSelectCustomers([]);
  }

  /**
   * Handle click on a single user line
   */
  function handleClick(id) {
    const isSelected = selected.some(c => c.id === id);
    let newSelected = [];

    if (isSelected)
      newSelected = selected.filter(c => c.id !== id);
    else
      newSelected = [...selected, rows.find(c => c.id === id)];

    setSelected(newSelected);
    onSelectCustomers(newSelected);
  }

  /**
   * Check if this user is already selected
   */
  function isSelected(id) {
    return selected.some(c => c.id === id);
  }

  /**
   * Handle click on Edit icon
   * @param e
   * @param row
   */
  function onEditIconClick(e, row) {
    e.stopPropagation();

    onEditRowClick(row);
  }

  /**
   * Handle pagination value change
   */
  function handlePaginationChange({ target: { value } }, newPage = -1) {
    if (value) {
      const newSize = parseInt(value, 10);
      const newP = parseInt(((page-1) * rowsPerPage + 1) / newSize) ;
      onPaginationChange({
        page: newP + 1,
        limit: newSize
      });
    }

    if (newPage >= 0 && typeof newPage !== 'object') {
      onPaginationChange({
        page: newPage + 1,
        limit: rowsPerPage
      });
    }
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="small"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              onSelectAllClick={handleSelectAllClick}
              rowCount={rows.length}
            />
            <TableBody>
              {rows.map((row, index) => {
                  const {
                    id,
                    email,
                    firstName,
                    lastName,
                    funds : [{balance: fundBalance}] = [{balance : 0}],
                    points : [{balance: pointAmount}] = [{balance : 0}],
                  } = row;
                  const isItemSelected = isSelected(id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={() => handleClick(id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={id}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </TableCell>
                      <TableCell align="left" padding="none" scope="row">
                        {email}
                      </TableCell>
                      <TableCell align="left" padding="normal">{firstName}</TableCell>
                      <TableCell align="left" padding="normal">{lastName}</TableCell>
                      <TableCell align="left" padding="normal">
                        {`$ ${fundBalance.toFixed(2) ?? 0}`}
                      </TableCell>
                      <TableCell align="left" padding="normal">
                        {`${pointAmount ?? 0} points`}
                      </TableCell>
                      <TableCell align="left" padding="normal">
                        <IconButton onClick={e => onEditIconClick(e, row)}>
                          <EditSharp/>
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={customersTotal}
          rowsPerPage={rowsPerPage}
          page={ page -1 }
          onPageChange={handlePaginationChange}
          onRowsPerPageChange={handlePaginationChange}
        />
      </Paper>
    </div>
  );
}

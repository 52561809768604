import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { RESET_STATE, ledgerActionTypes } from '../actions';

const initialLedgersState = {
  ledgers: {},
  ledgersList: [],
  valueAdds: {},
  valueAddsList: [],
  tabPayments: {},
  tabPaymentsList: [],
  selectedSaleOrder: {}
};

export const reducer = persistReducer(
  {
    storage,
    key: 'yoke-ledgers',
    whitelist: [
      'ledgers',
      'ledgersList',
      'valueAdds',
      'valueAddsList',
      'tabPayments',
      'tabPaymentsList',
      'selectedSaleOrder'
    ]
  },
  (state = initialLedgersState, action) => {
    switch (action.type) {
      case ledgerActionTypes.StoreLedgers: {
        const { entities: ledgers, list: ledgersList } = mapOrdersToEntities(action.payload);

        return {
          ...state,
          ledgers,
          ledgersList
        };
      }

      case ledgerActionTypes.StoreValueAdd: {
        const { entities: valueAdds, list: valueAddsList } = mapOrdersToEntities(action.payload);

        return {
          ...state,
          valueAdds,
          valueAddsList
        };
      }

      case ledgerActionTypes.StoreTabPayment: {
        const { entities: tabPayments, list: tabPaymentsList } = mapOrdersToEntities(action.payload);

        return {
          ...state,
          tabPayments,
          tabPaymentsList
        };
      }

      case ledgerActionTypes.SelectSaleOrder: {
        const { id, type } = action.payload;
        const selectedSaleOrder = { ...state[type][id] };

        return {
          ...state,
          selectedSaleOrder
        };
      }

      case ledgerActionTypes.ClearSelectedLedger: {
        return {
          ...state,
          selectedSaleOrder: {}
        };
      }

      case ledgerActionTypes.ClearLedgersStore: {
        return {
          ...initialLedgersState
        };
      }

      case RESET_STATE: {
        return initialLedgersState;
      }

      default: {
        return state;
      }
    }
  }
);

/**
 * Helper function to map orders to stored data types
 */
function mapOrdersToEntities(array) {
  if (!array || !array.length)
    return { entities: {}, list: [] };

  const list = array.map(ledger => {
    const {
      id,
      AccountId,
      LocationId,
      UserId,
      creationDate,
      DeviceEndId,
      DeviceStartId,
      DeviceTypeEnd,
      DeviceTypeStart,
      order,
      total
    } = ledger;

    return {
      id,
      AccountId,
      LocationId,
      UserId,
      creationDate,
      DeviceEndId,
      DeviceStartId,
      DeviceTypeEnd,
      DeviceTypeStart,
      order,
      total
    };
  });
  const entities = array.reduce((acc, ledger) => {
    const { id } = ledger;

    return {
      ...acc,
      [id]: ledger
    };
  });

  return { entities, list };
}

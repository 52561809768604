import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import * as routerHelpers from '../../router/RouterHelpers';
import { currentUser, setUser } from '../../classes/user';
import { authActionTypes } from '../actions/auth.action';
import { AUTH_TOKEN, LAST_USER_AUTH_TOKEN } from 'app/common/constant';

const initialAuthState = {
  user: undefined,
  authToken: undefined
};

export const reducer = persistReducer(
  { storage, key: 'demo3-auth', whitelist: ['user', 'authToken'] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case authActionTypes.Login: {
        const { authToken } = action.payload;

        return { authToken, user: undefined };
      }

      case authActionTypes.Register: {
        const { authToken } = action.payload;

        return { authToken, user: undefined };
      }

      case authActionTypes.Logout: {
        routerHelpers.forgotLastLocation();
        /**
         * clear localstorage when user tries to logout
         */
        const authToken = localStorage.getItem(AUTH_TOKEN);
        localStorage.clear()
        if (authToken) {
          localStorage.setItem(LAST_USER_AUTH_TOKEN, authToken);
        }
        return initialAuthState;
      }

      case authActionTypes.UserLoaded: {
        const { user } = action.payload;

        return { ...state, user };
      }

      default: {
        if (state.user && !currentUser)
          setUser(state.user);

        return state;
      }
    }
  }
);

import React from 'react';
import { Card, CardContent, Typography } from '@material-ui/core';
import { currentUser } from '../../classes/user';

export default function WelcomeText() {
  return (
    <Card style={{ marginBottom: 20 }}>
      <CardContent style={{ padding: 16 }}>
        <Typography variant="h6">
          Welcome back, {currentUser.first_name}!
          <br/>
          Here's what's happening at your markets.
        </Typography>
      </CardContent>
    </Card>
  );
}

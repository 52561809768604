import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
  makeStyles,
  IconButton,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { EditSharp } from "@material-ui/icons";
import { format } from "date-fns";

import {
  getComparator,
  getDefaultStyles,
  stableSort,
} from "../../../../services/table";
import { ESortOrder, ORDER_BY_UPDATED_DATE } from "app/common/constant";
import { DELETED_USER, DELETED_USERGROUP } from "app/common/giftCardsConst";

const headCells = [
  {
    id: "groupName",
    numeric: false,
    disablePadding: false,
    label: "Name",
  },
  { id: "amount", numeric: false, disablePadding: false, label: "Amount" },
  {
    id: "dateOfIssue",
    numeric: false,
    disablePadding: false,
    label: "Date of Issue",
  },
  { id: "type", numeric: false, disablePadding: false, label: "Type" },
  {
    id: "dateOfReissue",
    numeric: false,
    disablePadding: false,
    label: "Reissued",
  },
  { id: "actions", numeric: false, disablePadding: false, label: "Actions" },
];

function EnhancedTableHead({ headCells }) {
  return (
    <TableHead id={`recurring_table_header`}>
      <TableRow id={`recurring_table_header_row`}>
        {headCells.map((headCell) => (
          <TableCell
            id={`recurring_table_header_label_${headCell.id}`}
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles(getDefaultStyles);

export default function RecurringTable(props) {
const {push} = useHistory();
  const {
    rows = [],
    pagination = {
      page: 1,
      limit: 10,
    },
    giftsTotal = 1,
    onPaginationChange,
  } = props;
  const classes = useStyles();
  const [order] = useState(ESortOrder.DESC);
  const [orderBy] = useState(ORDER_BY_UPDATED_DATE);
  const [page, setPage] = useState(pagination.page - 1);
  const [rowsPerPage, setRowsPerPage] = useState(pagination.limit);

  /**
   * Handle click on Edit icon
   * @param e
   * @param row
   */
   function onEditIconClick (e, row) {
    e.stopPropagation();
    push({ pathname: `/giftCards/${row?.id}`})
  }

  function handlePaginationChange({ target: { value } }, newPage = -1) {
    if (value) {
      const newSize = parseInt(value, 10);
      // calculating page no according new RowPerPage;
      const newP = parseInt((page * rowsPerPage + 1) / newSize);
      onPaginationChange({
        page: newP + 1,
        limit: newSize,
      });
      setPage(newP);
      setRowsPerPage(newSize);
    }

    if (newPage >= 0 && typeof newPage !== "object") {
      onPaginationChange({
        page: newPage + 1,
        limit: rowsPerPage,
      });
      setPage(newPage);
    }
  }

  return (
    <div id={`recurring_table_root_wrapper`} className={classes.root}>
      <Paper id={`recurring_table_paper_wrapper`} className={classes.paper}>
        <TableContainer id={`recurring_table_container_wrapper`}>
          <Table
            id={`recurring_table_inner_container_wrapper`}
            className={classes.table}
            aria-labelledby="tableTitle"
            size="small"
            aria-label="enhanced table"
          >
            <EnhancedTableHead headCells={headCells} />
            <TableBody id={`recurring_table_body_wrapper`}>
              {stableSort(rows, getComparator(order, orderBy)).map((row) => {
                const {
                  id,
                  userGroup,
                  user,
                  amount,
                  creationDate,
                  recurring,
                  recurringIssueMetric,
                  isExpanded,
                } = row;

                return (
                  <React.Fragment key={id}>
                    <TableRow
                      id={`recurring_table_row_${id}`}
                      hover
                      tabIndex={-1}
                      selected={isExpanded}
                    >
                      <TableCell  id={`recurring_table_user_detail_cell_${id}`} align="left" padding="normal">{user ? (user?.email || DELETED_USER) : (userGroup?.name || DELETED_USERGROUP)}</TableCell>
                      <TableCell  id={`recurring_table_amount_cell_${id}`} align="left" padding="normal">{`$ ${amount?.toFixed(2)}`}</TableCell>
                      <TableCell id={`recurring_table_creation_date_cell_${id}`} align="left" padding="normal">
                        {format(new Date(creationDate), 'MM/dd/yyyy - hh:mm aa')}
                      </TableCell>
                      <TableCell  id={`recurring_table_Recurring_cell_${id}`} align="left" padding="normal">
                        {recurring ? "Recurring" : "One time"}
                      </TableCell>
                      <TableCell id={`recurring_table_recurring_metric_cell_${id}`} align="left" padding="normal">{recurringIssueMetric != "0" ? recurringIssueMetric[0]?.toUpperCase() + recurringIssueMetric.slice(1) : 'Never'}</TableCell>
                      <TableCell id={`recurring_table_icon_button_cell_${id}`} align="left" padding="normal">
                        <IconButton
                          onClick={(e)=>onEditIconClick(e,row)}
                        >
                          <EditSharp />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          id={`recurring_table_pagination`}
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={giftsTotal}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handlePaginationChange}
          onRowsPerPageChange={handlePaginationChange}
        />
      </Paper>
    </div>
  );
}

import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { setupLocationActionTypes } from "../actions/setupLocations.action";
import { RESET_STATE } from "../actions";

const initialSetupLocationState = {
  setupLocationList: [],
  setupCurrentLocation:{}
};

export const reducer = persistReducer(
  {
    storage,
    key: "yoke-setupLocationList",
    whitelist: ["setupLocationList","setupCurrentLocation"],
  },
  (state = initialSetupLocationState, action) => {
    switch (action.type) {
      case setupLocationActionTypes.StoreSetupLocations: {
        return {
          ...state,
          setupLocationList: action.payload,
        };
      }

      case setupLocationActionTypes.StoreSetupLocationsSuccess: {
        return {
          ...state,
          setupLocationList: action.payload,
        };
      }

      case setupLocationActionTypes.StoreSetupCurrentLocation: {
        return {
          ...state,
          setupCurrentLocation: action.payload,
        };
      }

      case setupLocationActionTypes.StoreSetupLocationsError: {
        return {
          ...state,
          setupLocationList:[],
        };
      }

      case setupLocationActionTypes.clearSetupLocationsData:
        return {
          ...state,
            setupLocationList: [], // Clear the setup locations data
        };
      
      case RESET_STATE: {
        return initialSetupLocationState;
      }

      default: {
        return state;
      }
    }
  }
);

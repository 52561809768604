import { DEFAULT_DEBOUNCE_DELAY } from 'app/common/constant';
import React, { useState, useEffect } from 'react';
 
// Our hook
 
const useDebounce = (value, delay=DEFAULT_DEBOUNCE_DELAY) => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);
 
  useEffect(() => {
    // Set debouncedValue to value (passed in) after the specified delay
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value]);

  return debouncedValue;
};

export default useDebounce;

import React from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@material-ui/core';

export default function AddKiosk({ currentKiosk, setCurrentKiosk }) {
  return (
    <div id={`add_kiosk_form_wrapper`} className="d-flex col-12">
      <div id={`add_kiosk_form_inner_wrapper_0`} className="d-flex flex-column col-3">
        <TextField
          margin="normal"
          id="name"
          label="Name"
          variant="outlined"
          type="text"
          value={currentKiosk.name}
          onChange={e => setCurrentKiosk({ ...currentKiosk, name: e.target.value })}
          fullWidth
        />

        <FormControl id={`add_kiosk_form_control_barcode`} margin="normal" variant="outlined" fullWidth>
          <InputLabel id="barcode">Barcode Scanner</InputLabel>
          <Select
            labelId="barcode"
            id="barcode"
            value={currentKiosk.barcodeScanner}
            onChange={e => setCurrentKiosk({ ...currentKiosk, barcodeScanner: e.target.value })}
            label="Barcode Scanner"
          >
            {/* TODO: add list of items fetched for selecting */}
            <MenuItem value={1}>Opticon</MenuItem>
          </Select>
        </FormControl>
      </div>

      <div  id={`add_kiosk_form_inner_wrapper_1`} className="d-flex flex-column col-3">
        <TextField
          margin="normal"
          id="serial"
          label="Serial #"
          variant="outlined"
          type="text"
          value={currentKiosk.serialNumber}
          onChange={e => setCurrentKiosk({ ...currentKiosk, serialNumber: e.target.value })}
          fullWidth
        />

        <FormControl id={`add_kiosk_form_control_tablet_type`} margin="normal" variant="outlined" fullWidth>
          <InputLabel id="tabletType">Tablet Type</InputLabel>
          <Select
            labelId="tabletType"
            id="tabletType"
            value={currentKiosk.tabletType}
            onChange={e => setCurrentKiosk({ ...currentKiosk, tabletType: e.target.value })}
            label="Tablet Type"
          >
            {/* TODO: add list of items fetched for selecting */}
            <MenuItem value={1}>Opticon</MenuItem>
          </Select>
        </FormControl>
      </div>

      <div id={`add_kiosk_form_control_Reader_wrapper`} className="d-flex flex-column col-3">
        <FormControl id={`add_kiosk_form_control_Reader`} margin="normal" variant="outlined" fullWidth>
          <InputLabel id="reader">Reader</InputLabel>
          <Select
            labelId="reader"
            id="reader"
            value={currentKiosk.creditCardReader}
            onChange={e => setCurrentKiosk({ ...currentKiosk, creditCardReader: e.target.value })}
            label="Reader"
          >
            {/* TODO: add list of items fetched for selecting */}
            <MenuItem value="1">Opticon</MenuItem>
          </Select>
        </FormControl>

        <FormControl id={`add_kiosk_form_control_mount`}  margin="normal" variant="outlined" fullWidth>
          <InputLabel id="mount">Mount</InputLabel>
          <Select
            labelId="mount"
            id="mount"
            value={currentKiosk.mount}
            onChange={e => setCurrentKiosk({ ...currentKiosk, mount: e.target.value })}
            label="Mount"
          >
            {/* TODO: add list of items fetched for selecting */}
            <MenuItem value={1}>Table Top</MenuItem>
          </Select>
        </FormControl>
      </div>

      <div id={`add_kiosk_form_control_cellular_wrapper`} className="d-flex flex-column col-3">
        <FormControl id={`add_kiosk_form_control_cellular`} margin="normal" variant="outlined" fullWidth>
          <InputLabel id="connectionTypeLabel">Cellular Provider</InputLabel>
          <Select
            labelId="connectionTypeLabel"
            id="connectionType"
            value={currentKiosk.connectionType}
            onChange={e => setCurrentKiosk({ ...currentKiosk, creditCardReader: e.target.value })}
            label="Reader"
          >
            {/* TODO: add list of items fetched for selecting */}
            <MenuItem value="1">Opticon</MenuItem>
          </Select>
        </FormControl>

        <FormControl id={`add_kiosk_form_control_color_wrapper`} margin="normal" variant="outlined" fullWidth>
          <InputLabel id="color">Color</InputLabel>
          <Select
            labelId="color"
            id="color"
            value={currentKiosk.color}
            onChange={e => setCurrentKiosk({ ...currentKiosk, color: e.target.value })}
            label="Color"
          >
            <MenuItem value={1}>Black</MenuItem>
            <MenuItem value={2}>White</MenuItem>
          </Select>
        </FormControl>
      </div>
    </div>
  );
}

import React from 'react';
import {
  Paper,
  IconButton,
  InputBase,
  InputLabel,
  makeStyles
} from '@material-ui/core';
import { SearchOutlined } from '@material-ui/icons';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  iconButton: {
    padding: 10
  },
  wrapper: {
    paddingLeft: 8,
    width: "100%"
  },
  labelSize: {
    fontSize: '1rem'
  }
}));

export default function Search(props) {
  const classes = useStyles();
  const {
    hasLabel = true,
    label = 'Search by name, customer code, tags, or contact details',
    placeholder = 'Search',
    iconPosition = 'end',
    value,
    onChange,
    labelFontSize = '1rem',
    className,
    onKeyDown,
    id = "test",
  } = props;

  const handleSearchClicked = (e) => {
    e.preventDefault();
  }

  return (
    <>
      <div className={clsx(classes.wrapper, className)}>
        {hasLabel ? (
          <InputLabel htmlFor="test" className={clsx(classes.labelSize, className)} style={{ fontSize: labelFontSize }}>
            {label}
          </InputLabel>
        ) : null}
        <Paper component="form" className={classes.root}>
          {iconPosition === 'start' ? (
            <IconButton type="submit" className={classes.iconButton} aria-label="search" onClick={handleSearchClicked}>
              <SearchOutlined/>
            </IconButton>
          ) : null}
          <InputBase
            id={`${id}_search`}
            className={classes.input}
            placeholder={placeholder}
            inputProps={{ 'aria-label': 'search' }}
            value={value}
            onChange={onChange}
            onKeyDown={onKeyDown}
          />
          {iconPosition === 'end' ? (
            <IconButton type="submit" className={classes.iconButton} aria-label="search" onClick={handleSearchClicked}>
              <SearchOutlined/>
            </IconButton>
          ) : null}
        </Paper>
      </div>
    </>
  );
}

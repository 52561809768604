import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';

import styles from './response.module.scss';

export default function ResponseModal(props) {
  const {
    open,
    onClose,
    isSuccess,
    isServerError = true,
    message,
  } = props;
  const [dropdown, setDropdown] = useState(false);

  function handleModalClose() {
    setDropdown(false);
    onClose(false);
  }

  return (
    <>
      <Dialog
        id={`response_modal_dialog_wrapper`}
        open={open}
        onClose={handleModalClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{zIndex:5100}}
      >
        <DialogTitle id="response_modal_simple_modal_title">{isSuccess ? "Done!" : isServerError ? "Sorry, something didn't go as planned!" : "Oops!"}</DialogTitle>
        <DialogContent id="response_modal_simple_modal_description_0" className={styles.wrapper}>
          {message}
        </DialogContent>
        <DialogContent id="response_modal_simple_modal_description_1" className={styles.wrapper}>
          {(!isSuccess && isServerError)? "We've been notified and are working on a fix." : null}
        </DialogContent>
        <DialogActions id="response_modal_simple_modal_actions_wrapper">
          <Button
            id="response_modal_simple_modal_actions_button"
            color="primary"
            size="large"
            onClick={handleModalClose}
          >
            {isSuccess ? 'Got it!' : "Okay"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

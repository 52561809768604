import React from 'react';
import { Link } from 'react-router-dom';

export default function MenuSubmenu(props) {
  const { item } = props;

  function handleExternalLinkClick(page) {
    window.open(page);
  }

  return (
    <ul className="aside-menu_navigation">
      {item.submenu.map(({ title, page, newTab = false }, index) => (
        <li id={`list-item-${index + (12 * 2.1)}`} className="list-item" key={`${index + (12 * 2.1)}`}>
          {!newTab && <Link id={`anchor-element-${index + (12 * 2.1)}`} className="anchor-element" to={`/${page}`}>
            {title}
          </Link>}
          {
            newTab && <a id="anchor-element" key={`${index + (12 * 2.1)}`} className="anchor-element" onClick={()=>handleExternalLinkClick(page)}>{title}</a>
          }
        </li>
      ))}
    </ul>
  );
}

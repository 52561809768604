import { getKiosks } from "app/services/locations";

const GET_KIOSKS = 'GET_KIOSKS';
const GET_KIOSKS_SUCCESS = 'GET_KIOSKS_SUCCESS';
const GET_KIOSKS_FAILURE = 'GET_KIOSKS_FAILURE';

export const getKiosksThunk = (page = 1, limit = 10) => {
  return (dispatch) => {
    dispatch({ type: GET_KIOSKS });
    return getKiosks(page, limit).then(  
      kiosks => 
      dispatch({ type: GET_KIOSKS_SUCCESS, payload: kiosks.data }),
      err => dispatch({ type: GET_KIOSKS_FAILURE, err })
    );
  };
};
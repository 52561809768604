import React, { useState, useEffect } from 'react';
import to from 'await-to-js';
import _ from 'lodash';

import timezones from 'timezones.json';
import {
  Button,
  Divider,
  FormControl,
  InputLabel,
  Typography,
  Card,
  CardContent,
  Select,
  MenuItem,
  TextField,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { data } from 'currency-codes';
import * as Sentry from '@sentry/react';

import ResponseModal from 'app/components/Helpers/ResponseModal';
import { accessPermission } from 'app/common/helperFunction';
import OperatorSpecificModal from 'app/components/Helpers/OperatorSpecificModal';
import LoadingSpinner from 'app/partials/content/LoadingSpinner';

import { getLoggedUserAccountId, getLoggedUserAccountId2, hasMultipleAccounts } from '../../../services/helpers/account-id';
import { getOperatorDetails, updateOperatorDetails, getOwnerDetails } from '../../../services/account';

import styles from './general.module.scss';
import { useCustomResponse } from 'app/hooks/useCustomResponse';

export default function General(props) {
  const {push} = useHistory();
  const { filter: { locations } } = props;
  const [id] = useState(getLoggedUserAccountId2());
  const [currentUserId] = useState(getLoggedUserAccountId());
  const [loading, setLoading] = useState(false);
  const [responseValue, setResponseValue] = useCustomResponse();
  const [currentAccount, setCurrentAccount] = useState({
    name: '',
    subdomain: '',
    website: '',
    timezone: '',
    currency: 'USD',
    address: {
      streetOne: '1 NY Plaza',
      streetTwo: 'S 400',
      state: 'NY',
      country: 'USA',
      zip: '11554'
    }
  });
  const [currentOwner,setCurrentOwner] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
  })
  const [isLoaded, setIsLoaded] = useState(false);
  const [operatorSpecificModalOpen, setOperatorSpecificModalOpen] = useState(false);

  useEffect(() => {
    if(!accessPermission("SetupAll")){
      push({pathname: "/dashboard"});
    }
  }, []);

  /**
   * Fetch data on component mounting
   */
  useEffect(() => {
    if (locations?.length && id)
      getGeneralInformation();

    if(currentUserId)
      getOwnerInfo()
  }, [locations]);

  /*
  * Fetching owner details
  */

  function getOwnerInfo() {

    if(hasMultipleAccounts()){
      setOperatorSpecificModalOpen(true);
      return
    }

    getOwnerDetails(currentUserId)
      .then((res) => {
        if (res?.data?.data) {
          setCurrentOwner({
            firstName: res?.data?.data?.firstName || "",
            lastName: res?.data?.data?.lastName || "",
            phone: res?.data?.data?.phone || "",
            email: res?.data?.data?.email || "",
          });
        }
      })
      .catch((err) => {
        console.error("err", err);
      });
  }

  /**
   * Handle data request for general page
   */

  function getGeneralInformation() {

    if(hasMultipleAccounts()){
      setOperatorSpecificModalOpen(true);
      return
    }

    setLoading(true);

    getOperatorDetails(id)
      .then(res => {
        setLoading(false);
        setCurrentAccount(res.data);
        setIsLoaded(true);

      })     
      .catch(err => {
        Sentry.captureException(err);
        setLoading(false);
        setIsLoaded(true);      
        console.error('General data was not fetched: ', err);
      });

  }

  async function handleInformationUpdate() {
    if(!_.isEmpty(id) && !_.isEmpty(currentAccount)){
      const updatedDefaultTheme = currentAccount.defaultTheme === null ? "00000000-0000-0000-0000-000000000000" : currentAccount.defaultTheme;
      const [err, res] = await to(updateOperatorDetails(id,currentAccount,updatedDefaultTheme))
    if (err) {
      Sentry.captureException(err);
      setResponseValue({...responseValue, isMessageOpen: true, isSuccess:false, message:`We had trouble on saving your information. ${err}`})
      return console.error('General data was not updated: ', err);

    } else {
      setResponseValue({...responseValue, isMessageOpen: true, isSuccess:true, message:`Saved information for ${currentAccount.name}`})
    }
  } else {
    console.log('Invalid data: ',id, currentAccount);
  }
  }

  function renderSaveButton() {
    return (
      <div id={`general_information_save_button_wrapper`}  className={styles.btn}>
        <Button
          id={`general_information_save_button`}
          type="button"
          size="large"
          variant="outlined"
          color="primary"
          onClick={handleInformationUpdate}
          disabled={loading}
          disableElevation
        >
          Save
        </Button>
      </div>
    );
  }

  function renderGeneralInformation() {
    return (
      <>
        <div id={`general_information_card_wrapper`} className={styles.cardWrapper}>
          <div id={`general_information_card_wrapper_0`} className={styles.fieldsWrapper}>
            <TextField
              margin="normal"
              id="storeName"
              label="Store name"
              variant="outlined"
              value={currentAccount.name}
              onChange={e => setCurrentAccount({ ...currentAccount, name: e.target.value })}
              fullWidth
            />
          </div>
          <div id={`general_information_card_wrapper_1`} className={styles.fieldsWrapper}>
            <TextField
              margin="normal"
              id="storeWebsite"
              label="Website"
              variant="outlined"
              value={currentAccount.website}
              onChange={e => setCurrentAccount({ ...currentAccount, website: e.target.value })}
              fullWidth
            />
          </div>
        
          <div id={`general_information_card_wrapper_2`} className={styles.fieldsWrapper}>
            <FormControl id={`general_information_form_control_time_zone`} variant="outlined" fullWidth className={styles.formControl}>
              <InputLabel htmlFor="timezone">
                Timezone
              </InputLabel>

              <Select
                label="Timezone"
                labelId="timezone"
                id="storeTimezone"
                className={styles.input}
                value={currentAccount.timezone}
                onChange={e => setCurrentAccount({ ...currentAccount, timezone: e.target.value })}
              >
                {timezones.map(timezone => {
                  const { text } = timezone;

                  return (
                    <MenuItem key={text} value={text}>
                      {text}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
          <div id={`general_information_card_wrapper_3`}className={styles.fieldsWrapper}>
            <FormControl id={`general_information_form_control_currency`} variant="outlined" fullWidth className={styles.formControl}>
              <InputLabel htmlFor="currency">
                Currency
              </InputLabel>

              <Select
                label="currency"
                labelId="currency"
                id="storeCurrency"
                className={styles.input}
                value={currentAccount.currency}
                onChange={e => setCurrentAccount({ ...currentAccount, currency: e.target.value })}
              >
                {data.map(item => {
                  const { code, currency } = item;

                  return (
                    <MenuItem id={`general_information_menu_item_${code}`} key={code} value={code}>
                      {code}
                      &nbsp;
                      (
                      {currency}
                      )
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
        </div>
      </>
    );
  }

  function renderContactInformation() {
    return (
      <>
        <div id={`contact_information_wrapper_0`} className={styles.contactsField}>
          <TextField
            margin="normal"
            id="contactFirstName"
            defaultValue={currentOwner.firstName ? "" : "Contact First Name"}
            InputProps={{
              readOnly: true,
            }}
            value={currentOwner.firstName || currentAccount.contactNameFirstName}
            fullWidth
          />
        </div>

        <div  id={`contact_information_wrapper_1`}className={styles.contactsField}>
          <TextField
            margin="normal"
            id="contactLastName"
            defaultValue={currentOwner.lastName ? "" : "Contact Last Name"}
              InputProps={{
              readOnly: true,
            }}
            value={currentOwner.lastName || currentAccount.contactLastName}
            fullWidth
          />
        </div>
        
        <div id={`contact_information_wrapper_2`} className={styles.contactsField}>
          <TextField
            margin="normal"
            id="contactEmail"
            defaultValue={currentOwner.email ? "" : "Email"}
            InputProps={{
              readOnly: true,
            }}
            value={currentOwner.email || currentAccount.email}
            fullWidth
          />
        </div>

        <div id={`contact_information_wrapper_3`} className={styles.contactsField}>
          <TextField
            margin="normal"
            id="contactPhone"
            defaultValue={currentOwner.phone ? "" : "Phone Number"}
            InputProps={{
              readOnly: true,
            }}
            value={currentOwner.phone || currentAccount.phone}
            fullWidth
          />
        </div>  
      </>
    );
  }

  return (
    <>
      {!isLoaded && (
        <LoadingSpinner/>
      )}
      <Card id={`general_information_card_root_wrapper`}>
        {isLoaded && (
          <div>
            <CardContent id={`general_information_card_content_wrapper`}>{renderGeneralInformation()}</CardContent>
            <CardContent>
              <div id={`general_information_card_content_inner_wrapper`} className={styles.wrapper}>
                <Typography id={`general_information_contact_typography`} variant="h4">Contact information</Typography>
                <Divider/>
                <div className={styles.cardWrapper}>
                  {renderContactInformation()}
                </div>
              </div>
              {renderSaveButton()}
            </CardContent>
            <ResponseModal
              isSuccess={responseValue?.isSuccess}
              message={responseValue?.message}
              open={responseValue?.isMessageOpen}
              onClose={value => setResponseValue({...responseValue, isMessageOpen:value})}
            />
          </div>
        )}
      </Card>
      {operatorSpecificModalOpen && (
        <OperatorSpecificModal
          open={operatorSpecificModalOpen}
          onClose={(value) => setOperatorSpecificModalOpen(value)}
        />
      )}
    </>
  );
}

import { getTopsalereport } from "app/services/reports"
import { topProductActions } from "app/store/actions/topProduct.action"
import to from "await-to-js"


export const fetchTopProducts = (payload) => async dispatch => {
    dispatch(topProductActions.getTopProducts())
    try {
        const [err, res] = await to(getTopsalereport(payload))
        if(err){
            dispatch(topProductActions.ClearTopProducts())
        }
        const resData = res?.data?.reports?.topProdSales !== null ? res?.data?.reports?.topProdSales : []
        dispatch(topProductActions.StoreTopProducts(resData))
    } catch (error) {
        dispatch(topProductActions.ClearTopProducts())
    }
  }
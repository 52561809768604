import axios from 'axios';

import { CANCEL_TOKEN } from "app/common/constant";

import { getDefaultParameters_V2 } from './helpers/getDefaultParameters';

/**
 * Get loyalties list
 */
const CancelToken = axios.CancelToken;
let getLoyaltiesCancel = null;
export function getLoyalties({ limit = 10, page = 1 }, search) {
  if(getLoyaltiesCancel){
    getLoyaltiesCancel(CANCEL_TOKEN);
    getLoyaltiesCancel = null;
  }
  return axios.get(`/loyalties?limit=${limit}&page=${page}`+getDefaultParameters_V2(false), {
    cancelToken: new CancelToken(function executor(c){
      getLoyaltiesCancel = c;
    })
  });
}

export function getLoyaltiesByLocationId(locationId) {
  return axios.get(`/loyalties?location=${locationId}`);
}


/**
 * Create a new Loyalty
 * @argument object
 * locationId @argument string
 * active @argument Boolean
 * tiers @argument Array  [{ "name": "Tier One", "value": 500, "products":[ {"productId": "", "name": "Popcorn"}]}],
 * ratio @argument Number 300
 */
export function postNewLoyalty(data) {
  return axios.post('/loyalties', data);
}

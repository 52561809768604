import React from 'react';
import {
  Card,
  CardContent,
  FormControl,
  Input,
  InputLabel,
  Typography,
  FormControlLabel,
  Button,
  Switch
} from '@material-ui/core';
import clsx from 'clsx';

import Tags from './components/Tags/Tags';

import styles from './general-information.module.scss';

// temp variable to hide a block
const hideBlock = false;

export default function GeneralInformation(props) {
  const {
    isNew,
    currentLocation,
    onUpdate,
    onSave
  } = props;

  return (
    <Card id={`general_infomation_card_wrapper`} className={styles.cardWrapper}>
      <CardContent id={`general_infomation_card_content_wrapper`}>
        <div id={`general_infomation_card_content_inner_wrapper_0`} className="row">
          <div id={`general_infomation_card_content_inner_container_0`} className="col-4 d-flex justify-content-between">
            <div id={`general_infomation_card_content_currentLocation_container_0`} className={clsx('d-flex', 'flex-column')}>
              <h4>{currentLocation.name}</h4>
            </div>
          </div>
          <div  id={`general_infomation_card_content_inner_wrapper_1`} className="col-8">
            <div id={`general_infomation_card_content_inner_container_1`} className="d-flex justify-content-around">
              <div id={`general_infomation_card_content_location_id_container_0`} className="d-flex flex-column">
                <div>
                  <Typography id={`general_infomation_card_content_location_label`} variant="body1">Location ID</Typography>
                  <Typography id={`general_infomation_card_content_location_value`} variant="h6">{!isNew ? currentLocation.id : 'None'}</Typography>
                </div>
                {/*
                  TODO: change false to !isNew when switch discussed
                */}
                {hideBlock ? (
                  <div id={`general_infomation_card_content_Pause_location_wrapper`} className={styles.formControl}>
                    <Typography id={`general_infomation_card_content_Pause_location_label`} variant="body1">
                      Pause Location
                    </Typography>
                    <FormControlLabel
                      id={`general_infomation_card_content_Pause_location_form_control`}
                      className={styles.switch}
                      control={(
                        <Switch
                        id={`general_infomation_card_content_Pause_location_switch_button`}
                          checked={currentLocation.isEnabled}
                          onChange={(e, checked) => onUpdate({ ...currentLocation, isEnabled: checked })}
                          color="primary"
                        />
                    )}
                      label={currentLocation.isEnabled ? 'Pause' : 'Resume'}
                    />
                    <Typography id={`general_infomation_card_content_Pause_location_description`} variant="body2">
                      Pausing will disable a location
                      without deleting any information
                    </Typography>
                  </div>
                ) : null}
              </div>
              <div id={`general_infomation_card_content_Pause_location_status_wrapper`}>
                <Typography id={`general_infomation_card_content_Pause_location_status_label`} variant="body1">Status</Typography>
                <Typography id={`general_infomation_card_content_Pause_location_status_value`} variant="h6">
                  {currentLocation.status }
                </Typography>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className={clsx('col-8', styles.tagsWrapper)}>
          </div>
          <div className={clsx('col-4', styles.buttonWrapper)}>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

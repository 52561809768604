import React, { useEffect, useState } from "react";
import clsx from "clsx";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  TableCell,
  TableRow,
} from "@material-ui/core";
import { format } from "date-fns";
import to from "await-to-js";

import { EPaymentMethod, paymentMethods } from "app/common/LedgerConst";
import { getCartImage } from "app/services/reports";

import styles from "./details-row.module.scss";

export default function DetailsRow(props) {
  const [cartImage, setCartImage] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);

  const {
    isLoads,
    isCarts,
    currentRow,
    onIssueRefund,
    onResendReceipt,
    isOrderHistory,
    cartId,
  } = props;

  const {
    id,
    [isCarts ? "cart" : "order"]: { products, totals, payment = [{}] },
    status = "",
    statusMessage,
    creationDate,
  } = currentRow;

  useEffect(() => {
    (async () => {
      if (!cartImage) {
        const [err, res] = await to(getCartImage(cartId));

        if (!err) {
          setCartImage(res.data.url);
        }
      }
    })();
  }, [cartId]);

  function renderProduct(product) {
    const { productId, name, quantity, unitprice, unitPrice, price } = product;
    let totalPrice = 0;

    if (isCarts) {
      if (!isNaN(quantity) && !isNaN(price) && quantity > 0 && price >= 0) {
        totalPrice = Number(quantity) * Number(price);
      }
    } else {
      if (!isNaN(quantity) && !isNaN(unitPrice) && quantity > 0 && unitPrice >= 0) {
        totalPrice = Number(quantity) * Number(unitPrice);
      } else if (!isNaN(unitPrice) && unitPrice >= 0) {
        totalPrice = Number(unitPrice);
      }
    }

    const formattedTotalPrice = !isNaN(totalPrice)
      ? `$ ${Number(totalPrice ?? 0).toFixed(2)}`
      : String(parseFloat(totalPrice));
    const unitPriceValue = unitPrice ? (unitPrice || 0)?.toFixed(2) : (unitprice || 0)?.toFixed(2)
    return (
      <div
        id={`ledger_table_details_row_render_product_wrapper_${productId}`}
        key={productId}
        className={clsx("d-flex", "justify-content-between", styles.rowWrapper)}
      >
        <div id={`ledger_table_details_row_render_quantity_${productId}`}>
          <b>{`${quantity} X `}</b>
          {name}
        </div>
        <div
          id={`ledger_table_details_row_price_wrapper_${productId}`}
          className={styles.itemPrice}
        >
          <div id={`ledger_table_details_row_price_inner_wrapper_${productId}`}>
            {`@ $ ${
              isCarts ? price?.toFixed(2) :unitPriceValue
            } `}
          </div>
          <div id={`ledger_table_details_row_unit_price_wrapper_${productId}`}>
            <b>{formattedTotalPrice}</b>
          </div>
        </div>
      </div>
    );
  }

  function getPaymentMethod(payment) {
    return payment?.method === "creditCard"
      ? paymentMethods[payment?.method] + `-(**${payment?.lastFour})`
      : paymentMethods[payment?.method];
  }

  const getPossibleDiscount = (payments, totals) => {
    /***
     * @method for checking payments method 
     */ 
    const checkPaymentMethod = (payment) =>
      payment?.method === EPaymentMethod.GLOBAL_CONNECT_CARD;

    return payments.some(checkPaymentMethod)
      ? `$ ${Number(totals?.savings ?? 0).toFixed(2)}`
      : `$ ${Number(totals?.fee ?? 0).toFixed(2)}`;
  };

  return (
    <TableRow
      id={`ledger_table_details_row_table_expanded_wrapper`}
      className={styles.expandedTable}
    >
      {isLoads ? (
        <>
          <TableCell
            id={`ledger_table_details_cell_table_status_wrapper`}
            padding="default"
            colSpan={9}
            className={styles.infoCell}
          >
            {status.toLowerCase() !== "paid" && (
              <>
                <div
                  id={`ledger_table_details_row_payment_status_wrapper`}
                  className={styles.rowWrapper}
                >
                  {"Payment status :"}
                  <b>{statusMessage ? statusMessage.name : statusMessage}</b>
                </div>
                <Divider />
              </>
            )}

            {products?.map(renderProduct) || null}

            <Divider />

            <div
              id={`ledger_table_details_row_table_bottle_wrapper`}
              className={styles.rowWrapper}
            >
              <div
                id={`ledger_table_details_row_table_bottle_inner_wrapper`}
                className={clsx(
                  "d-flex",
                  "justify-content-between",
                  styles.rowWrapper
                )}
              >
                <div id={`ledger_table_details_row_table_bottle_content`}>
                  {"Bottle Deposit"}
                </div>
                <div id={`ledger_table_details_row_table_deposit_content`}>
                  {`$ ${Number(totals?.deposit ?? 0).toFixed(2) || 0}`}
                </div>
              </div>
              <div
                id={`ledger_table_details_row_table_total_tax_wrapper`}
                className={clsx(
                  "d-flex",
                  "justify-content-between",
                  styles.rowWrapper
                )}
              >
                <div id={`ledger_table_details_row_table_total_tax_label`}>
                  {"Total tax"}
                </div>
                <div id={`ledger_table_details_row_table_total_tax_value`}>
                  {`$ ${Number(totals?.tax).toFixed(2) || 0}`}
                </div>
              </div>
              <div
                id={`ledger_table_details_row_table_possible_disount_wrapper`}
                className={clsx(
                  "d-flex",
                  "justify-content-between",
                  styles.rowWrapper
                )}
              >
                <div
                  id={`ledger_table_details_row_table_possible_disount_label`}
                >
                  {"Possible Discount"}
                </div>
                <div
                  id={`ledger_table_details_row_table_possible_disount_value`}
                >
                  {getPossibleDiscount(payment, totals)}
                </div>
              </div>
            </div>

            <Divider />

            <div
              id={`ledger_table_details_row_table_totals_wrapper`}
              className={clsx(
                "d-flex",
                "justify-content-between",
                styles.rowWrapper,
                styles.mainRow
              )}
            >
              <div id={`ledger_table_details_row_table_totals_label`}>
                <b>{"TOTAL"}</b>
              </div>
              <div id={`ledger_table_details_row_table_totals_value`}>
                <b>{`$ ${Number(totals?.total).toFixed(2) || 0}`}</b>
              </div>
            </div>

            <div
              id={`ledger_table_details_row_table_payment_wrapper`}
              className={clsx("d-flex", "flex-column", styles.smallTable)}
            >
              <div
                id={`ledger_table_details_row_table_payment_value`}
                className={styles.rowWrapper}
              >
                {getPaymentMethod(payment[0])}
                {payment[1] ? ", " + getPaymentMethod(payment[1]) : null}
              </div>
              <Divider />
              <div
                id={`ledger_table_details_row_table_creation_date_value`}
                className={styles.rowWrapper}
              >
                {`${format(new Date(creationDate), "MM/dd/yyyy hh:mm aa")}`}
              </div>
              <Divider />
              <div
                id={`ledger_table_details_row_table_balance_wrapper`}
                className={clsx(
                  "d-flex",
                  "justify-content-between",
                  styles.rowWrapper
                )}
              >
                <div id={`ledger_table_details_row_table_balance_label`}>
                  Balance
                </div>
                <div id={`ledger_table_details_row_table_balance_value`}>
                  <b>$0.00</b>
                </div>
              </div>
            </div>
          </TableCell>
        </>
      ) : (
        <TableCell
          id={`ledger_table_details_row_empty`}
          padding="default"
          colSpan={9}
          className={styles.infoCell}
        ></TableCell>
      )}
      <TableCell
        id={`ledger_table_details_row_table_button_wrapper`}
        padding="default"
        colSpan={1}
        className={styles.actionsCell}
      >
        {!isOrderHistory && !isCarts && (
          <Button
            id={`ledger_table_details_issue_refund_button`}
            disabled={
              status.toLowerCase() !== "paid" ||
              payment[0].method.toLowerCase() == "globalconnectcard"
            }
            type="button"
            color="primary"
            size="medium"
            variant="contained"
            disableElevation
            fullWidth
            className={styles.issueRefund}
            onClick={() => onIssueRefund(id)}
          >
            Issue Refund
          </Button>
        )}
        {!isCarts && (
          <Button
            id={`ledger_table_details_issue_refund_button`}
            type="button"
            color="primary"
            size="medium"
            variant="contained"
            disableElevation
            fullWidth
            onClick={() => onResendReceipt(currentRow)}
          >
            Resend Receipt
          </Button>
        )}
        {isCarts && status.toLowerCase() === "canceled" && (
          <Button
            id={`ledger_table_details_view_photo_button`}
            type="button"
            color="primary"
            size="medium"
            variant="contained"
            disableElevation
            fullWidth
            disabled={!cartImage}
            onClick={() => setModalOpen(true)}
          >
            {cartImage ? "View Photo" : "Image Unavailable"}
          </Button>
        )}
      </TableCell>
      {isModalOpen ? (
        <Dialog
          id={`ledger_table_dialog_modal_wrapper`}
          open
          onClose={() => setModalOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
            Canceled Cart Image
          </DialogTitle>
          <img
            style={{
              alignContent: "center",
              border: "1px solid #ddd",
              borderRadius: 4,
              padding: 5,
              height: 500,
              width: 500,
            }}
            src={cartImage}
          />
          <DialogActions
            id={`ledger_table_dialog_modal_action_wrapper`}
            style={{ alignContent: "center" }}
            className="d-flex justify-content-between"
          >
            <div></div>
            <Button
              id={`ledger_table_dialog_modal_action_close_button`}
              alignContent="ceneter"
              size="medium"
              variant="contained"
              color="primary"
              disableElevation
              onClick={() => setModalOpen(false)}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
    </TableRow>
  );
}

import axios from 'axios';
import { format } from 'date-fns';
import { getDefaultParameters_V2 } from '../../../services/helpers/getDefaultParameters';

/**
 * Array with mocked sales
 */
const mockedSales = [{
  id: 1,
  date: format(new Date(), 'MM/dd/yyyy - hh:mm aa'),
  orderNumber: 1475,
  operator: 'Alta',
  location: 'FreshPay Smart Market',
  customer: 'Not entered',
  device: 'Kiosk',
  paymentMethod: 'Credit Card',
  amount: 15.00,
  transactionId: '7FE15DSHE8KWYE',
  cardType: 'Visa ending in 5458',
  details: {
    customerName: '',
    saleDate: format(new Date(), 'dd MMM yyyy - hh:mm aa'),
    note: '',
    products: [{
      id: 1,
      img: 'https://cdn.shopify.com/s/files/1/0051/7262/5477/t/5/assets/0aa3d39e48fc--bottle-lineup-1l-v2.png?9032',
      name: 'Fiji Water / 500ml',
      price: '$1.49',
      tax: 9
    }, {
      id: 2,
      img: 'https://cdn.shopify.com/s/files/1/0051/7262/5477/t/5/assets/0aa3d39e48fc--bottle-lineup-1l-v2.png?9032',
      name: 'Fiji Water / 100ml',
      price: '$2.10',
      tax: 4
    }]
  }
}, {
  id: 2,
  date: format(new Date(), 'MM/dd/yyyy - hh:mm aa'),
  orderNumber: 1481,
  operator: 'Alta',
  location: 'Smart Market',
  customer: 'Super Client',
  device: 'iOS',
  paymentMethod: 'Yoke Cash',
  amount: 5.00,
  transactionId: '7FE15DSHE8KWYE',
  cardType: 'Visa ending in 5458',
  details: {
    customerName: '',
    saleDate: format(new Date(), 'dd MMM yyyy - hh:mm aa'),
    note: '',
    products: [{
      id: 1,
      img: 'https://cdn.shopify.com/s/files/1/0051/7262/5477/t/5/assets/0aa3d39e48fc--bottle-lineup-1l-v2.png?9032',
      name: 'Fiji Water / 500ml',
      price: '$1.49',
      tax: 9
    }, {
      id: 2,
      img: 'https://cdn.shopify.com/s/files/1/0051/7262/5477/t/5/assets/0aa3d39e48fc--bottle-lineup-1l-v2.png?9032',
      name: 'Fiji Water / 100ml',
      price: '$2.10',
      tax: 4
    }]
  }
}];


/**
 * Get sale by id
 * @param id
 */
export function getSaleById(id) {
  return mockedSales.find(sale => +sale.id === +id);
}

/**
 * Refund an order
 * @param id
 */
export function refund(id, amount, transactionId) {
  return axios.post(`/orders/${id}/refund`, {amount: parseFloat(amount), transactionId: transactionId});
}

/**
 * Resend a receipt
 * @param id
 */
export function receipt(id, email) {
  return axios.post(`/orders/${id}/notify`,{email:email});
}

import React, { useEffect, useState } from "react";
import to from "await-to-js";
import { useHistory, useRouteMatch } from "react-router-dom";
import {
  Card,
  CardContent,
  Divider,
  FormControlLabel,
  Switch,
  Typography,
  Button,
} from "@material-ui/core";
import { format } from "date-fns";
import clsx from "clsx";
import { Check } from "@material-ui/icons";

import ExportAndCustomFilters from "../../../../partials/content/ExportAndCustomFilters";
import EventsTable from "./components/EventsTable/EventsTable";
import useCustomHeader from "../../../../hooks/useCustomHeader";
import { getAddOnById } from "../../../../services/add-ons";
import jsPDF from "jspdf";
import csvDownload from "json-to-csv-export";
import "jspdf-autotable";

import * as Sentry from "@sentry/react";
import ResponseModal from "app/components/Helpers/ResponseModal";
import styles2 from "_metronic/_assets/sass/loading-text.module.scss";
import { accessPermission } from "app/common/helperFunction";

import styles from "./add-ons-details.module.scss";
import { useCustomResponse } from "app/hooks/useCustomResponse";

export default function AddOnsDetails() {
  const {push} = useHistory();
  useCustomHeader({
    staticHeader: "Add-on Details",
  });
  const match = useRouteMatch() || {};
  const { locationId, id } = match.params;
  const [currentAddOn, setCurrentAddOn] = useState({});
  const [loading, setLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [responseValue, setResponseValue] = useCustomResponse()

  /**
   * Get data on component mounting
   */
  useEffect(() => {
    if(!accessPermission("AddonsAll")){
      push({pathname: "/dashboard"});
    }
    getAddOnDetails();
    // eslint-disable-next-line
  }, []);

  async function getAddOnDetails() {
    const [err, res] = await to(getAddOnById(locationId, id));
    setIsLoaded(true);
    if (err) {
      Sentry.captureException(err);
      setResponseValue({
        ...responseValue,
        isMessageOpen: true,
        message: `Error while getting Cooler details. ${err}`,
        isSuccess: false
      })
      return console.error("Add-on details not fetched: ", err);
    }
    setCurrentAddOn(res.data);
  }

  function handleInformationUpdate() {}

  function renderSaveButton() {
    return (
      <div className={clsx("col-12 d-flex justify-content-end", styles.btn)}>
        <Button
          startIcon={<Check />}
          type="button"
          size="medium"
          variant="contained"
          color="primary"
          onClick={handleInformationUpdate}
          disabled={loading}
          disableElevation
        >
          {!loading ? "Save" : "Loading"}
        </Button>
      </div>
    );
  }

  async function handleExport(type) {
    const rows = currentAddOn.unlocks || []

    if (rows.length > 0) {
      if (type === "pdf") {
        const exportRows = rows.map((ele) => [
          !ele.creationDate
            ? "N/A"
            : format(new Date(ele.creationDate), "MM/dd/yyyy - hh:mm aa"),
          !ele.action ? "N/A" : ele.action,
          `${ele.user.firstName} ${ele.user.lastName}`,
          !ele.device ? "N/A" : ele.device,
          ele.locationId ? ele.locationId : "N/A",
        ]);

        const unit = "pt";
        const size = "A4";
        const orientation = "portrait";
        const marginLeft = 30;
        const doc = new jsPDF(orientation, unit, size);
        doc.setFontSize(15);

        const title = "Events";
        const headers = [["Date", "Action", "Customer", "Device", "Location"]];

        let content = {
          startY: 50,
          head: headers,
          body: exportRows,
          styles: { overflow: "linebreak", fontSize: 8 },
        };

        doc.text(title, marginLeft, 30);
        doc.autoTable(content);
        doc.save(`Events - ${format(new Date(), 'MM/dd/yyyy')}.${type}`);
      } else if (type === "csv") {
        const exportRows = [];
        rows.forEach((ele) => {
          let obj = {
            Date: !ele.creationDate
              ? "N/A"
              : format(new Date(ele.creationDate), "MM/dd/yyyy - hh:mm aa"),
            Action: !ele.action ? "N/A" : ele.action,
            Customer: `${ele.user.firstName} ${ele.user.lastName}`,
            Device: !ele.device ? "N/A" : ele.device,
            Location: ele.locationId ? ele.locationId : "N/A",
          };
          exportRows.push(obj);
        });

        csvDownload(exportRows, `Events - ${format(new Date(), 'MM/dd/yyyy')}.${type}`);
      }
    }
  }

  return (
    <>
      <Card id={`add_ons_details_card_container`}  className={styles.card}>
        <CardContent id={`add_ons_details_card_content`} className="d-flex justify-content-between align-items-center">
          <div id={`add_ons_details_card_content_wrapper_1`}>
            <Typography id={`add_ons_details_typography_lock_id_label`} variant="body1">Lock ID</Typography>
            <Typography id={`add_ons_details_typography_lock_id_value`} variant="h6">{currentAddOn.id}</Typography>
          </div>
          <div id={`add_ons_details_card_content_wrapper_2`}>
            <Typography id={`add_ons_details_typography_lock_serial_label`} variant="body1">Lock Serial</Typography>
            <Typography id={`add_ons_details_typography_lock_serial_value`} variant="h6">{currentAddOn.serialNumber}</Typography>
          </div>
          <div id={`add_ons_details_card_content_wrapper_3`}>
            <Typography id={`add_ons_details_typography_date_added_label`} variant="body1">Date Added</Typography>
            <Typography id={`add_ons_details_typography_date_added_value`} variant="h6">
              {!currentAddOn.creationDate
                ? "N/A"
                : format(
                    new Date(currentAddOn.creationDate),
                    "MM/dd/yyyy - hh:mm aa"
                  )}
            </Typography>
          </div>
          <div id={`add_ons_details_card_content_wrapper_4`}>
            <Typography id={`add_ons_details_typography_location_label`} variant="body1">Location</Typography>
            <Typography id={`add_ons_details_typography_location_value`} variant="h6">{currentAddOn.location?.name}</Typography>
          </div>
          <div id={`add_ons_details_card_content_wrapper_5`}className={styles.selectWrapper}>
            <Typography id={`add_ons_details_typography_status_label`} variant="body1">Status</Typography>
            {currentAddOn.status}
          </div>
          <div id={`add_ons_details_card_content_wrapper_6`}>
            <Typography id={`add_ons_details_typography_active_label`} variant="body1">Active</Typography>
            <FormControlLabel
              id={`add_ons_details_form_control_label`}
              className={styles.switch}
              control={
                <Switch
                  id={`add_ons_details_switch_button`}
                  checked={currentAddOn.enabled ? true : false}
                  onChange={(e, checked) =>
                    setCurrentAddOn({ ...currentAddOn, enabled: checked })
                  }
                  color="primary"
                />
              }
            />
          </div>
        </CardContent>
      </Card>

      <div id={`add_ons_details_card_content_wrapper_7`} className={styles.wrapper}>
        <Typography id={`add_ons_details_events`} variant="h4">Events</Typography>
        <Divider />
        <div id={`add_ons_details_card_content_inner_wrapper_1`} className={styles.innerWrapper}>
          <ExportAndCustomFilters hasFilters={false} onExport={handleExport} />
        </div>

        {
          !isLoaded && <div className={styles2.loadingContainer}>
            <p id={`add_ons_details_paragraph_loading`} className ={styles2.loadingText}>
              Loading...
            </p>
          </div>
        }

        <div id={`add_ons_details_card_content_inner_wrapper_2`} className={styles.wrapper}>
          <EventsTable rows={currentAddOn.unlocks || []} />
        </div>
        {renderSaveButton()}
      </div>
      <ResponseModal
      isSuccess={responseValue?.isSuccess}
      message={responseValue.message}
      open={responseValue.isMessageOpen}
      onClose={value => setResponseValue({...responseValue, isMessageOpen:value})}
      />
    </>
  );
}

import { Box, Grid, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React from "react";
import styles from "./../../Dashboard/components/platformInsights/platformInsights.module.scss";

const InsiteLoader = ({ colsNum }) => {
  return (
    <Box className={styles.insight_container}>
      <Typography variant="h1" style={{ width: "100%", height: "100%" }}>
        <Skeleton style={{ height: 40, width: 400 }} />
      </Typography>
      <Grid container wrap="nowrap">
        {[...Array(colsNum)].map((col, index) => (
          <Skeleton
            key={index}
            variant="rectangular"
            width={"100%"}
            height={"100%"}
            style={{ borderRadius: "4px", margin: 2, padding: 5 }}
            sx={{ bgcolor: "gray.500" }}
          >
            <Skeleton variant="h1" style={{ margin: 8, width: "80%" }} />
            <Skeleton variant="rectangular" height={40} style={{ margin: 8 }} />
          </Skeleton>
        ))}
      </Grid>
    </Box>
  );
};

export default InsiteLoader;

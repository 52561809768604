import React, { useState, useEffect } from "react";
import to from "await-to-js";
import { useHistory } from "react-router";
import { isAfter } from "date-fns";
import { Button, Grid, TextField, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import * as Sentry from "@sentry/react";

import ResponseModal from "app/components/Helpers/ResponseModal";

import useCustomerHeader from "../../../hooks/useCustomHeader";
import { closeTicket, getConversation, sendConversation } from "app/services/notifications";
import { useStyles } from "./MessageBoxStyles";

import styles2 from "_metronic/_assets/sass/loading-text.module.scss";
import { useCustomResponse } from "app/hooks/useCustomResponse";

export function TicketsMessageBox({ selectedCustomer }) {
  const styles = useStyles();
  const [newMessage, setNewMessage] = useState("");
  const [msg, setMsg] = useState([]);
  const { id, subHeader, setSubHeader } = useCustomerHeader({
    property: "pageTitle",
    getRelatedItem: () => selectedCustomer,
  });
  const history = useHistory();
  const [isLoaded, setIsLoaded] = useState(false);
  const [pagination, setPagination] = useState({page: 1, limit: 10});
  const [conversationCount, setConversationCount] = useState(0);
  const [responseValue, setResponseValue] = useCustomResponse();

  useEffect(() => {
    setSubHeader(subHeader.innerText = `${id}`)
  }, []);

  useEffect(() => {
    getTicketConversation();
  }, [pagination]);

  const handleSendClick =async () => {
    if (newMessage) {
      //api call to send msg to server
      const msgObject = {
        message: newMessage,
        solved: false,
        private: false,
      }
      const [err, res] = await to(sendConversation(id,msgObject));
      if(err) {
        Sentry.captureException(err);
        setResponseValue({...responseValue, isMessageOpen: true, isSuccess:false, message:`facing problem in sending message. ${err}`});
        return console.error(`failed to send the message. ${err}`);
      }

      //adding new msg in msgArray
      const msgArray = [...msg];
      msgArray.push({
        senderEmail: "You",
        msg: newMessage.split("\n"),
      });
      setMsg(msgArray);
      setNewMessage("");
    }
  };

  async function getTicketConversation() {
    const [err, res] = await to(getConversation(pagination, id));
    setIsLoaded(true);
    if (err) {
      Sentry.captureException(err);
      setResponseValue({
        ...responseValue, 
        isMessageOpen: true,
        isSuccess:false,
        message:`facing problem in loading this conversation. ${err}`
      });
      return console.error(`failed to load the conversation. ${err}`);
    }

    const { rows: tickets } = res.data.supportTicketResponse;
    const { totalItems} = res.data.meta;

    const msgList = tickets.map((ele) => {
      return {
        senderEmail: ele.name ?? ele.postedBy ?? "",
        msg: ele.message ? ele.message.split("\n") : []
      };
    });

    setMsg(msgList.sort( (a,b) => isAfter(a.created ,b.created) ? 1: -1));
    setConversationCount(totalItems);
  }

  const MessageBody = ({ item }) => {
    return (
      <Grid id="tickets_message_body_wrapper" className={styles.msgBox}>
        <Typography id="tickets_message_body_typography"  className={styles.sender}>{item.senderEmail}</Typography>
        {item.msg.map((message, index) => (
          <Typography id="tickets_message_typography_wrapper"  className={styles.msgBody} key={index}>
            {message}
          </Typography>
        ))}
      </Grid>
    );
  };

  async function handleSendAndCloseClick() {
    if (newMessage) {
      //api call to send msg to server
      const msgObject = {
        message: newMessage,
        solved: true,
        private: false,
      }
      const [err, res] = await to(sendConversation(id,msgObject));

      if(err) {
        Sentry.captureException(err);
        setResponseValue({...responseValue, isMessageOpen: true, isSuccess:false, message:`facing problem in send message or close the ticket. ${err}`});
        return console.error(`failed to send and close the ticket. ${err}`);
      }

      handleCloseTicket()
    } 
    else {
      handleCloseTicket()
    }
  };

  async function handleCloseTicket() {
    const ticketClosingObj = { status : "resolved" }
    const [err, res] = await to(closeTicket(id,ticketClosingObj));

      if(err) {
        Sentry.captureException(err);
        setResponseValue({...responseValue, isMessageOpen: true, isSuccess:false, message:`facing problem in closing the ticket. ${err}`});
        return console.error(`failed to Close the ticket. ${err}`);
      }
      setResponseValue({...responseValue, isMessageOpen: true, isSuccess:true, message:'Ticket closed successfully'});
      setTimeout(()=>{
        history.push('/tickets');
      },3000);
  }

  function handlePreviousConversationClick() {
    setConversationCount(conversationCount + 10);
    setPagination(prev => {
      return {page: 1, limit: prev.limit + 10}
    });
  } 
  return (
    <>
      {!isLoaded && (
        <div id="tickets_message_loading_container" className={styles2.loadingContainer}>
          <p id="tickets_message_loading_content" className={styles2.loadingText}>Loading...</p>
        </div>
      )}
      <Grid id="tickets_message_grid_outer_container" className={styles.outerContainer}>
        <Grid id="tickets_message_grid_msg_area" className={styles.msgArea}>
          {msg.map((item, index) => (
            <MessageBody item={item} key={index} />
          ))}
        </Grid>
        <Grid id="tickets_message_grid_input_msg_area_container" className={styles.msgControlArea}>
          <Grid id="tickets_message_grid_input_msg_area" className={styles.inputFieldArea}>
            <TextField
              multiline
              id="ticketReply_textField"
              className={styles.inputField}
              placeholder="Message"
              variant="outlined"
              autoFocus
              value={newMessage}
              onChange={(event) => {
                setNewMessage(event.target.value);
              }}
            />
          </Grid>
          <Grid id="tickets_message_grid_button_wrapper" className={styles.buttonArea}>
            <Button
              id="tickets_message_send_button"
              className={styles.btn}
              variant="contained"
              size="large"
              onClick={handleSendClick}
            >
              {"send"}
            </Button>
            <Button
             id="tickets_message_close_button"
              className={styles.btn}
              variant="contained"
              size="large"
              onClick={handleSendAndCloseClick}
            >
              {"send & close"}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <ResponseModal
        isSuccess={responseValue?.isSuccess}
        message={responseValue?.message}
        open={responseValue?.isMessageOpen}
        onClose={(value) => setResponseValue({...responseValue, isMessageOpen:value})}
      />
    </>
  );
}

export default connect((state) => ({
  selectedCustomer: state.customers.selectedCustomer,
}))(TicketsMessageBox);

export const userTransactionReportActionTypes = {
  GetUserTransactionReport: '[Get User Transaction Report Entities] Action',
  StoreUserTransactionReport: '[Store User Transaction Report Entities] Action',
  ClearUserTransactionReport: '[Clear User Transaction Report Entities] Action',
};

export const userTransactionReportActions = {
  GetUserTransactionReport: userTransactionReport => ({
    type: userTransactionReportActionTypes.GetUserTransactionReport,
    payload: userTransactionReport
  }),
  StoreUserTransactionReport: userTransactionReport => {
    return({
    type: userTransactionReportActionTypes.StoreUserTransactionReport,
    payload: userTransactionReport
  })
},
  ClearUserTransactionReport: (userTransactionReportError) => ({
    type: userTransactionReportActionTypes.ClearUserTransactionReport,
    payload: userTransactionReportError
  }),
};

import React, { useEffect, useMemo, useRef } from 'react';
import { Chart } from 'chart.js';
import { useSelector } from 'react-redux';

import { metronic } from '../../../../../_metronic';

export default function LineChart(props) {
  const {
    value,
    desc,
    color,
    chartData,
    labels = ['1 Jan', '2 Jan', '3 Jan', '4 Jan', '5 Jan', '6 Jan', '7 Jan']
  } = props;
  const ref = useRef();
  const { shape2Color, shape3Color } = useSelector(state => ({
    brandColor: metronic.builder.selectors.getConfig(
      state,
      'colors.state.brand'
    ),
    shape2Color: metronic.builder.selectors.getConfig(
      state,
      'colors.base.shape.2'
    ),
    shape3Color: metronic.builder.selectors.getConfig(
      state,
      'colors.base.shape.3'
    )
  }));

  const data = useMemo(
    () => ({
      labels,
      datasets: [
        {
          fill: true,
          backgroundColor: Chart.helpers
            .color(color)
            .alpha(0.6)
            .rgbString(),

          borderColor: Chart.helpers
            .color(color)
            .alpha(0)
            .rgbString(),

          pointHoverRadius: 4,
          pointHoverBorderWidth: 12,
          pointBackgroundColor: Chart.helpers
            .color('#000000')
            .alpha(0)
            .rgbString(),
          pointBorderColor: Chart.helpers
            .color('#000000')
            .alpha(0)
            .rgbString(),
          pointHoverBackgroundColor: color,
          pointHoverBorderColor: Chart.helpers
            .color('#000000')
            .alpha(0.1)
            .rgbString(),

          data: chartData
        }
      ]
    }),
    // eslint-disable-next-line
    [color]
  );

  useEffect(() => {
    // For more information about the chartjs, visit this link
    // https://www.chartjs.org/docs/latest/getting-started/usage.html

    const chart = new Chart(ref.current, {
      data,
      type: 'line',
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: false,
        scales: {
          xAxes: [
            {
              categoryPercentage: 0.35,
              barPercentage: 0.7,
              display: true,
              scaleLabel: {
                display: false,
                labelString: 'Month'
              },
              gridLines: false,
              ticks: {
                display: true,
                beginAtZero: true,
                fontColor: shape3Color,
                fontSize: 13,
                padding: 10
              }
            }
          ],
          yAxes: [
            {
              categoryPercentage: 0.35,
              barPercentage: 0.7,
              display: true,
              scaleLabel: {
                display: false,
                labelString: 'Value'
              },
              gridLines: {
                color: shape2Color,
                drawBorder: false,
                offsetGridLines: false,
                drawTicks: false,
                borderDash: [3, 4],
                zeroLineWidth: 1,
                zeroLineColor: shape2Color,
                zeroLineBorderDash: [3, 4]
              },
              ticks: {
                display: true,
                beginAtZero: true,
                fontColor: shape3Color,
                fontSize: 13,
                padding: 10,
                callback: function (value) {
                  return value.toFixed(2)
                }
              }
            }
          ]
        },
        title: {
          display: false
        },
        hover: {
          mode: 'ErrorsPage.js'
        },
        tooltips: {
          enabled: true,
          intersect: false,
          mode: 'nearest',
          bodySpacing: 5,
          yPadding: 10,
          xPadding: 10,
          caretPadding: 0,
          displayColors: false,
          backgroundColor: color,
          titleFontColor: '#ffffff',
          cornerRadius: 4,
          footerSpacing: 0,
          titleSpacing: 0
        },
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 5,
            bottom: 5
          }
        }
      }
    });

    return () => {
      chart.destroy();
    };
  }, [data, color, shape2Color, shape3Color]);

  return (
    <div id="kt_widget26" className="kt-widget26">
      <div id="kt_widget26__content" className="kt-widget26__content">
        <span id="kt_widget26__number" className="kt-widget26__number">{value}</span>
        <span id="kt_widget26__desc" className="kt-widget26__desc">{desc}</span>
      </div>
      <div id="kt_widget12__chart" className="kt-widget12__chart" style={{ height: '160px' }}>
        <canvas
          ref={ref}
          width={683}
          height={312}
          id="kt_chart_order_statistics"
        />
      </div>
    </div>
  );
}

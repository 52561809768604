import React, { useEffect, useState } from 'react';
import to from 'await-to-js';
import {
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import { format } from 'date-fns';
import { EditSharp } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import * as Sentry from "@sentry/react";

import { accessPermission } from 'app/common/helperFunction';
import LoadingSpinner from 'app/partials/content/LoadingSpinner';
import ResponseModal from 'app/components/Helpers/ResponseModal';

import { getDefaultStyles } from '../../../../services/table';
import { getApplications } from '../../../../services/applications';
import { useCustomResponse } from 'app/hooks/useCustomResponse';

const statusDictionary = {
  inProgress: 'In Progress',
  paused: 'Paused',
  completed: 'Completed',
  approved: 'Approved',
  denied: 'Denied',
};

export default function ApplicationsTable(props) {
  const { filter: { locations } } = props;
  const { push } = useHistory();
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10
  });
  const [applications, setApplications] = useState([]);
  const [totalApplications, setTotalApplications] = useState(0);
  const useStyles = makeStyles(getDefaultStyles);
  const classes = useStyles();
  const [isLoaded, setIsLoaded] = useState(false);
  const [responseValue, setResponseValue] = useCustomResponse();

  useEffect(() => {
    if(!accessPermission()){
      push({pathname: "/dashboard"});
    }
  }, []);

  /**
   * Get list of all available applications
   * and refresh it on every pagination change
   */
  useEffect(() => {
    if (locations && locations.length)
      getApplicationsList();
  }, [pagination]);

  async function getApplicationsList() {
    const [err, res] = await to(getApplications(pagination));
    setIsLoaded(true);

    if (err){
      Sentry.captureException(err);     
      setResponseValue({...responseValue, isMessageOpen: true, isSuccess:false, message:`Error while fetching application lists ${err}`});
      return console.error('Error with fetching applications list: ', err);
    }
      
    const { rows, count } = res.data.applications;

    setApplications(rows);
    setTotalApplications(count);
  }

  function handlePaginationChange({ target: { value } }, newPage = -1) {
    const { page,limit } = pagination;

    if (value) {
      const newSize = parseInt(value, 10);
      const newP = parseInt(((page -1) * limit + 1) / newSize) ;

      setPagination({
        page: newP + 1,
        limit: newSize,
      });
    }

    if (newPage >= 0 && typeof newPage !== 'object')
      setPagination({
        page: newPage + 1,
        limit
      });
  }

  function onEditIconClick(e, id) {
    e.stopPropagation();
    push({ pathname: `/setup/applications/${id}` });
  }

  return (
    <>
    {!isLoaded ? (
        <LoadingSpinner/>
      ) : (
      <>
        <Paper className={classes.paper}>
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size="small"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Creation Date</TableCell>
                  <TableCell>Updated Date</TableCell>
                  <TableCell>Operator Name</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {applications.map(row => (
                  <TableRow key={row.id}>
                    <TableCell>{format(new Date(row.creationDate), 'MM/dd/yyyy - hh:mm aa')}</TableCell>
                    <TableCell>{format(new Date(row.statusDate), 'MM/dd/yyyy - hh:mm aa')}</TableCell>
                    <TableCell>
                      {row.companyInfo && !row.companyInfo.name ? 'N/A' : row.companyInfo.name}
                    </TableCell>
                    <TableCell>{statusDictionary[row.status]}</TableCell>
                    <TableCell>
                      {row.companyInfo && !row.companyInfo.email ? 'N/A' : row.companyInfo.email}
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={e => onEditIconClick(e, row.id)}>
                        <EditSharp/>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={totalApplications}
            rowsPerPage={pagination.limit}
            page={pagination.page - 1}
            onPageChange={handlePaginationChange}
            onRowsPerPageChange={handlePaginationChange}
          />
        </Paper>
        <ResponseModal
        isSuccess={responseValue?.isSuccess}
        message={responseValue?.message}
        open={responseValue?.isMessageOpen}
        onClose={value => setResponseValue({...responseValue, isMessageOpen:value})}
        />
      </>)}
    </>
  );
}

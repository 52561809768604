import React, { useEffect, useState, useCallback } from 'react';
import to from 'await-to-js';
import clsx from 'clsx';
import {
  Avatar,
  Button,
  Card,
  CardContent,
  FormControlLabel,
  Switch,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
  FormHelperText,
  IconButton
} from '@material-ui/core';
import {
  Check,
  SettingsBackupRestore,
  Visibility,
  VisibilityOff
} from '@material-ui/icons';
import { useHistory } from 'react-router';
import * as Sentry from '@sentry/react';

import { assignUserRole, getRolesForAccount, removeUserFromRole } from 'app/services/roles';
import { getLoggedUserAccountId2 } from 'app/services/helpers/account-id';
import ResponseModal from 'app/components/Helpers/ResponseModal';
import { requestPassword } from 'app/crud/auth.crud';
import { accessPermission } from 'app/common/helperFunction';
import { useCustomResponse } from 'app/hooks/useCustomResponse';

import useCustomerHeader from '../../../../../hooks/useCustomHeader';
import { getUserById, createNewUser, updateUserById, getUserRoleById, assignUserToAccount } from '../../../../../services/account';

import styles from './user-details.module.scss';
import styles2 from '_metronic/_assets/sass/loading-text.module.scss';
import { currentUser } from '../../../../../classes/user';
import { EMAIL_EMPTY_ERROR_MESSAGE, EMAIL_INVALID_ERROR_MESSAGE, EMAIL_PATTERN } from 'app/common/constant';

// temp variable to hide block
const hideBlock = false;

const outlets = [{
  id: 1,
  name: 'Fresh Market Outlet'
}, {
  id: 2,
  name: 'Demo Market'
}, {
  id: 3,
  name: 'Super Ice Cream'
}];

export default function UserDetails(props) {
  const history = useHistory();
  const { filter: { locations } } = props;
  const [userForPage, setUserForPage] = useState({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    enabled: true,
    role: {
      id: 2,
      name: 'Admin'
    },
    notifications: {
      push: false,
      text: false,
      email: false,
    },
  });

  const [fnameError, setFNameError] = useState(false);
  const [lnameError, setLNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [roleError, setRoleError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [usersRoles, setUsersRoles] = useState([])
  const [usersRolesBeforeEdit, setUsersRolesBeforeEdit] = useState([])
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [roles, setRoles] = useState([])
  const [togglePass, setTogglePass] = useState(false);
  const getRelatedItem = useCallback(() => userForPage || { first_name: 'User' }, [userForPage]);

  const [sendToBack, setSendToBack] = useState(false);
  const [isMyAccount, setIsMyAccount] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("")
  const { id, isNew } = useCustomerHeader({
    isCreationAllowed: true,
    createNew: 'User',
    getRelatedItem,
    property: 'first_name'
  });
  const [isLoaded, setIsLoaded] = useState(false);
  const [responseValue, setResponseValue] = useCustomResponse()
  /**
   * Fetch user on component rendering be selected id
   */
  useEffect(() => {

    if(!accessPermission("SetupAll")){
      history.push({pathname: "/dashboard"});
    }

    if (locations && locations.length && !isNew)
      getUserDetails();
      setIsLoaded(true);
     getRoleDetails();
  }, []);

function handlePopupClose() {
  setResponseValue({...responseValue, isMessageOpen:false});
  if(sendToBack)
    history.push('/setup/users');
}

  /**
   * Get user details from the server and store it to the component
   */
  async function getUserDetails() {
     //verifying account with url

    const [err, res] = await to(getUserById(id));
    const [usersRolesErr, usersRolesRes] = await to(getUserRoleById(id));
    setIsLoaded(true);
    if (err){
      Sentry.captureException(err);
      setResponseValue({ ...responseValue, isMessageOpen: true, isSuccess:false, massage:`Error while getting user details. ${err}`});   
      return console.error('Error on user detalis fetch: ', err);
    }
    const userDetails = { ...usersRolesRes.data, first_name: usersRolesRes.data.firstName, last_name: usersRolesRes.data.lastName };
    delete(userDetails['password']);
    setUserForPage(userDetails);
    setUsersRoles(usersRolesRes.data.userroles.map(x => x.RoleId))
    setUsersRolesBeforeEdit(usersRolesRes.data.userroles.map(x => x.RoleId))
    const currentURL = document.URL;
    if(!isMyAccount && currentURL.includes(currentUser.id)){
      setIsMyAccount(true)
    }
  }

  useEffect(() => {
    
  }, [userForPage])

    /**
   * Get role details from the server and store it to the component
   */
     async function getRoleDetails() {
      const [rolesErr, rolesRes] = await to(getRolesForAccount(getLoggedUserAccountId2()));
      if (rolesErr)  
        return console.error('Error on user detalis fetch: ', rolesErr)
      setRoles(rolesRes.data)
    }

 
  /**
   * Function to create/save user on click
   */
  async function handleUserSave() {
    /**
     * varifying password
     */
    if(userForPage.password && confirmPassword && userForPage.password === confirmPassword){
    } else if(!isNew && (userForPage.password || confirmPassword) && userForPage.password !== confirmPassword ){
      setResponseValue({...responseValue, isMessageOpen: true, isSuccess:false, isServerError:false, message:"Password and Confirm Password don't match"})
        return;
    }

    /**
     * validating the email
     */
    let emailPattern = EMAIL_PATTERN
    if (!userForPage?.email || !emailPattern?.test(userForPage?.email)) {
      setResponseValue({
        ...responseValue,
        isMessageOpen: true,
        isSuccess: false,
        isServerError: false,
        message: !userForPage.email ? EMAIL_EMPTY_ERROR_MESSAGE : EMAIL_INVALID_ERROR_MESSAGE
      });
      return;
    }

    const { enabled, role, ...updatedUser } = userForPage;
    let userId = id;

    if (isNew) {
      /**
       * For user create we miss a password input and related data
       */
      setFNameError(false)
      setLNameError(false)
      setEmailError(false)
      setRoleError(false)
      setPasswordError(false)
      if ( !userForPage.first_name || !userForPage.last_name || !userForPage.email || usersRoles.length == 0 || !userForPage.password ) {
        setFNameError("First name cannot be empty.")
        setLNameError("Last name cannot be empty.")
        setEmailError("Email cannot be empty.")
        setRoleError("Role cannot be empty")
        setPasswordError("password cannot be empty")
        return;
      }
      delete updatedUser.notifications
      const [err, res] = await to(createNewUser(updatedUser));

      if (err) {
        if(err.response){
          setResponseValue({ ...responseValue, isMessageOpen: true, isSuccess:false, isServerError:false, message:`${err.response.data.error.message}`});
          return console.error('User was not created: ', err.response.data.error.message.toString());
        } 
        Sentry.captureException(err);
        setResponseValue({...responseValue, isMessageOpen: true, isSuccess:false, isServerError:true, message:`We had an issue creating this user. ${err}`})
        return console.error('User was not created: ', err);
      } 
      userId = res.data.id

      const [assignErr, assignRes] = await to(assignUserToAccount(userId, getLoggedUserAccountId2()))

      if (assignErr) {
        setResponseValue({...responseValue, isMessageOpen: true, isSuccess:false, isServerError:true, message:`We had an assigning this user to your account ${assignErr}`})
        return console.error('User not assigned: ', assignErr);

      }

    } else {

    const [err, res] = await to(updateUserById(id, updatedUser));

    if (err) {
      Sentry.captureException(err);
      setResponseValue({...responseValue, isMessageOpen: true, isSuccess:false, isServerError:true, message:`We had an issue updating this user. ${err}`})
      return console.error(`Issue updating user ${err}`)
    }
    }
    if (usersRolesBeforeEdit.forEach(async role => {
      if(!usersRoles.includes(role)) {
        const [deleteRoleErr, deleteRoleRes] = await to(removeUserFromRole(userId,role))
        if (deleteRoleErr) {
          setResponseValue({...responseValue, isMessageOpen: true, isSuccess:false, isServerError:true, message:`We had an issue adjusting the permissions for this user. ${deleteRoleErr}`})
          return console.error(`Issue assigning roles ${deleteRoleErr}`)
        }
      }
    }));
    console.log(usersRolesBeforeEdit)
    if (usersRoles.forEach(async role => {
      if(!usersRolesBeforeEdit.includes(role)) {
        const [assignRoleErr, assignRoleRes] = await to(assignUserRole(userId,role))
        if (assignRoleErr) {
          setResponseValue({...responseValue, isMessageOpen: true, isSuccess:false, isServerError:true, message:`We had an issue adjusting the permissions for this user. ${assignRoleErr}`})
          return console.error(`Issue assigning roles ${assignRoleErr}`)
        }
      }
    }));

    setSendToBack(true);
    setResponseValue({...responseValue, isMessageOpen: true, isSuccess:true,isServerError:false, message: isNew ? `User added!` : `User Updated`})


  }

  function handleResetPassword() {
    requestPassword(userForPage.email)
    .then((res) => {
      setResponseValue({...responseValue, isMessageOpen: true, isSuccess:true, isServerError:false, message:`${res.data.message}`});
    })
    .catch((res) => {
      setResponseValue({...responseValue, isMessageOpen: true, isSuccess:false, isServerError:false, message:`error while sending password reset link to mail- ${userForPage.email}`});
    })
  }

  const handleOnChangeValue = (event, field) => {
    const tempUserData = {...userForPage}
    if(!tempUserData.notifications){
      tempUserData.notifications = {
        email: false,
        text: false,
        push: false,
      };
    }
    tempUserData.notifications[field] = event.target.checked;
    setUserForPage(tempUserData);
  }

  return (
    <Card id={`users_details_card_container`}>
      {
        !isLoaded && <div id={`users_details_loading_container`} className={styles2.loadingContainer}>
          <p id={`users_details_loading_text`} className ={styles2.loadingText}>
            Loading...
          </p>
        </div>
      }
      <CardContent id={`users_details_card_content_wrapper`} className={styles.mainWrapper}>

        <div id={`users_details_card_avatar_wrapper`} className={styles.avatarWrapper}>
          <div id={`users_details_card_avatar_inner_wrapper`} className="d-flex flex-column align-items-center">
            <Avatar id={`users_details_card_avatar`} src={userForPage.image} alt={userForPage.email} style={{ width: 80, height: 80 }}
            />
          </div>
        </div>

        <div id={`users_details_card_detail_wrapper`} className={styles.detailWrapper}>
            <div id={`users_details_card_detail_fields_wrapper_0`} className={styles.detailFields}>
              <TextField
                margin="normal"
                id="userDetails_firstName"
                label="First name"
                variant="outlined"
                error={fnameError && !userForPage.first_name}
                helperText={(fnameError && !userForPage.first_name) === false ? '' : fnameError}
                value={userForPage.first_name}
                onChange={e => setUserForPage({ ...userForPage, first_name: e.target.value })}
                type="text"
                fullWidth
              />
            </div>

            <div id={`users_details_card_detail_fields_wrapper_1`} className={styles.detailFields}>
              <TextField
                margin="normal"
                id="userDetails_lastName"
                label="Last name"
                variant="outlined"
                error={lnameError && !userForPage.last_name}
                helperText={(lnameError && !userForPage.last_name) === false ? '' : lnameError}
                value={userForPage.last_name}
                onChange={e => setUserForPage({ ...userForPage, last_name: e.target.value })}
                fullWidth
              />
            </div>

            <div id={`users_details_card_detail_fields_wrapper_2`} className={styles.detailFields}>
              <TextField
                margin="normal"
                id="userDetails_email"
                label="Email"
                variant="outlined"
                type="email"
                error={emailError && !userForPage.email}
                helperText={(emailError && !userForPage.email) === false ? '' : emailError}
                value={userForPage.email}
                onChange={e => setUserForPage({ ...userForPage, email: e.target.value })}
                fullWidth
              />
            </div>
            <div id={`users_details_card_detail_fields_wrapper_3`} className={clsx(styles.detailFields, styles.select)}>
              <FormControl id={`users_details_form_control_wrapper`} variant="outlined" fullWidth>
                <InputLabel id="role-select-label">Role</InputLabel>
                <Select
                  multiple={true}
                  labelId="role-select-label"
                  id="userDetails_role_select"
                  error={usersRoles.length == 0 && roleError}
                  value={usersRoles}
                  onChange={e => setUsersRoles(e.target.value)}
                  label="Role"
                >
                  {roles.map(role => {
                    const { id, name } = role;
                    return (
                      <MenuItem id={`users_details_menu_item_wrapper`} key={id} value={id}>
                        {name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <FormHelperText id={`users_details_form_helper_text`} style={{ color: "#FD397A", fontSize: "11.25px", marginLeft: "14px" }}>{usersRoles.length == 0 && roleError}</FormHelperText>
            </div>
            {hideBlock ? (
              <div id={`users_details_card_hide_block_wrapper`} className={clsx(styles.detailFields, styles.select)}>
                <FormControl id={`users_details_form_control_outline_wrapper`} variant="outlined" fullWidth>
                  <InputLabel id="outlet-select-label">Outlet</InputLabel>
                  <Select
                    labelId="outlet-select-label"
                    id="userDetails_outlet_select"
                    value={userForPage.outlet.id}
                    onChange={e => setUserForPage({
                      ...userForPage, outlet: outlets.find(o => o.id === e.target.value)
                    })}
                    label="Outlet"
                  >
                    {outlets.map(role => {
                      const { id, name } = role;
                      return (
                        <MenuItem id={`users_details_outlet_select_${id}`} key={id} value={id}>
                          {name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
            ) : null}
          {isMyAccount && <>
            <div id={`users_details_is_my_account_wrapper_0`} className={styles.detailFields}>
              <TextField
                margin="normal"
                id="userDetails_password"
                label="New Password"
                variant="outlined"
                error={passwordError && !userForPage.password}
                helperText={(passwordError && !userForPage.password) === false ? '' : passwordError}
                value={currentUser.password}
                onChange={e => setUserForPage({ ...userForPage, password: e.target.value })}
                type={showPassword ? 'text' : 'password'}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment>
                      <IconButton onClick={() => setShowPassword(!showPassword)}>
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>

            <div id={`users_details_is_my_account_wrapper_1`} className={styles.detailFields}>
              <TextField
                margin="normal"
                id="userDetails_confirmPassword"
                label="Confirm Password"
                variant="outlined"
                value={confirmPassword}
                onChange={e => setConfirmPassword(e.target.value)}
                type={showConfirmPassword ? 'text' : 'password'}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment>
                      <IconButton onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                        {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </>}
          {isNew? (
          <div id={`users_details_is_new_wrapper_0`} className={styles.detailFields}>
            <TextField
              margin="normal"
              id="userDetails_new_password"
              type={togglePass ? 'text' : 'password'}
              label="Password"
              variant="outlined"
              value={userForPage.password}
              error={passwordError && !userForPage.password}
              helperText={(passwordError && !userForPage.password) === false ? '' : passwordError}
              onChange={e => setUserForPage({ ...userForPage, password: e.target.value })}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {togglePass
                      ? <Visibility onClick={() => setTogglePass(cur => !cur)}/>
                      : <VisibilityOff onClick={() => setTogglePass(cur => !cur)}/>}
                  </InputAdornment>
                )
              }}
              fullWidth
            />        
          </div>
          ) : null }
          {isNew? null:
          <div id={`users_details_notification_setting_wrapper`} className={styles.notificationBody}>
            <p id={`users_details_notification_setting_label`} style={{marginTop: "auto", marginRight: "30px"}}>
              Notification Settings
            </p>
            <div>
              <FormControlLabel
                id={`users_details_form_contral_label_wrapper_0`}
                label="Email"
                control={
                  <Switch
                    id={`users_details_form_contral_switch_0`}
                    checked={userForPage?.notifications?.email}
                    onChange={(event) => handleOnChangeValue(event, 'email')}
                    color="secondary"
                  />
                }
              />
              <FormControlLabel
                id={`users_details_form_contral_wrapper_1`}
                label="Text"
                control={
                  <Switch
                    id={`users_details_form_contral_switch_1`}
                    checked={userForPage?.notifications?.hasOwnProperty("text") ? userForPage.notifications.text : userForPage.notifications.phone}
                    onChange={(event) => handleOnChangeValue(event, 'text')}
                    color="secondary"
                  />
                }
              />
              <FormControlLabel
                id={`users_details_form_contral_wrapper_2`}
                label="Push"
                control={
                  <Switch
                    id={`users_details_form_contral_switch_2`}
                    checked={userForPage?.notifications?.push}
                    onChange={(event) => handleOnChangeValue(event, 'push')}
                    color="secondary"
                  />
                }
              />
            </div>
          </div>
              }

          <div id={`users_details_buttons_outer_wrapper`} className={clsx('col-12 d-flex justify-content-between', styles.buttonsWrapper)}>
          {isNew? null:
            <div id={`users_details_buttons_inner_wrapper`} className="d-flex flex-column align-items-start">
              <Button
                id={`users_details_reset_password_buttons`}
                startIcon={<SettingsBackupRestore/>}
                type="button"
                size="medium"
                variant="contained"
                color="secondary"
                disableElevation
                onClick={handleResetPassword}
              >
                Reset Password
              </Button>
              <i>You will receive an email with instructions.</i>
            </div>
            }

            <div id={`users_details_Save_button_wrapper`}>
              <Button
                id={`users_details_Save_button`}
                startIcon={<Check/>}
                type="button"
                size="medium"
                variant="contained"
                color="primary"
                disableElevation
                onClick={() => handleUserSave()}
              >
                Save
              </Button>
            </div>
          </div>
        </div>

      </CardContent>
      <ResponseModal
      isSuccess={responseValue?.isSuccess}
      isServerError={responseValue?.isServerError}
      message={responseValue?.message}
      open={responseValue?.isMessageOpen}
      onClose={handlePopupClose}
      />
    </Card>
    
  );
}

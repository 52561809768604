import React, { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import {
  Card,
  CardContent,
  Typography,
  Switch,
  Button,
  Divider,
} from "@material-ui/core";
import clsx from "clsx";
import { Check } from "@material-ui/icons";
import to from "await-to-js";
import * as Sentry from "@sentry/react";
import "jspdf-autotable";

import ResponseModal from "app/components/Helpers/ResponseModal";
import { getScope } from "app/services/helpers/account-id";
import { accessPermission } from "app/common/helperFunction";
import { emptyTiersSet } from "app/common/LedgerConst";
import { useCustomResponse } from "app/hooks/useCustomResponse";
import { ROLE } from "app/common/roleConstant";

import ProductTiers from "./components/ProductTiers/ProductTiers";
import {
  getLoyaltiesByLocationId,
  postNewLoyalty,
} from "../../../../services/loyalty";

import styles from "../../../../../_metronic/_assets/sass/pages/loyalty.module.scss";
import MultiSelect from "app/widgets/MultiSelect";
import { useSelector } from "react-redux";
import { ALL_LOCATIONS } from "app/common/constant";

export default function LoyaltyDetails() {
  const { push } = useHistory();
  const [currentLoyalty, setCurrentLoyalty] = useState({});
  const [currentTiers, setTiers] = useState(emptyTiersSet);
  const [loading, setLoading] = useState(false);
  const [selectionLocation, setSelectedLocation] = useState([]);
  const [responseValue, setResponseValue] = useCustomResponse();
  const locationOptions = useSelector(
    (state) =>
      state?.subHeader?.compressedLocationList
  );
  const match = useRouteMatch() || {};
  /**
   * Change subheader text
   */

  useEffect(() => {
    if (!accessPermission("LoyaltyAll")) {
      push({ pathname: "/dashboard" });
    }

    const arrowBack = document.getElementById("subheader-back-button");
    const loyaltyId = match.params.id;
    setSelectedLocation([...selectionLocation, match.params.id]);
    // Show back button
    arrowBack.style.display = "block";
    getLoyalties(loyaltyId);

    return () => {
      arrowBack.style.cssText = "";
    };
    // eslint-disable-next-line
  }, []);

  async function handleInformationUpdate() {
    const optimizedTiers = currentTiers.map((tier) => {
      if (tier.products.length === 0) {
        return tier;
      } else {
        return {
          ...tier,
          products: tier.products.map((product) => {
            return {
              productId: product.productId,
              name: product.name,
              product: product.product,
              operatorProductId: product.operatorProductId,
              id: product.id,
            };
          }),
        };
      }
    });
    const location_Ids = selectionLocation?.includes(ALL_LOCATIONS.id)
      ? ALL_LOCATIONS
      : selectionLocation.length === 0
      ? [match.params.id]
      : selectionLocation;
    const payload = {
      locationId: location_Ids,
      active: currentLoyalty.loyatlyEnabled,
      tiers: optimizedTiers,
    };

    const [err, res] = await to(postNewLoyalty(payload));

    if (err) {
      Sentry.captureException(err);
      setResponseValue({
        ...responseValue,
        isMessageOpen: true,
        isSuccess: false,
        message: `We had trouble updating this locations loyalty. ${err}`,
      });
    } else {
      setResponseValue({
        ...responseValue,
        isMessageOpen: true,
        isSuccess: true,
        message: `Updated loyalty for location ${currentLoyalty.name}`,
      });
      push({ pathname: `/loyalty` });
    }
  }

  function renderSaveButton() {
    return (
      <div className={clsx("col-12 d-flex justify-content-end", styles.btn)}>
        <MultiSelect
          placeholder="Select locations"
          options={locationOptions}
          selectedOption={match.params.id}
          setSelectedOptions={setSelectedLocation}
        />
        <Button
          style={{ width: 150, height: 50, marginTop: 10 }}
          startIcon={<Check />}
          type="button"
          size="large"
          variant="contained"
          color="primary"
          onClick={handleInformationUpdate}
          disabled={loading}
          disableElevation
        >
          {!loading ? "Save" : "Loading"}
        </Button>
      </div>
    );
  }

  async function getLoyalties(loyaltyId) {
    const subheader = document.getElementById("subheader-title");
    const [err, res] = await to(getLoyaltiesByLocationId(loyaltyId));

    if (err) {
      Sentry.captureException(err);
    }

    const location = res?.data?.locations?.rows;
    const locationSelected = location?.find((locationId) => {
      return locationId.id === loyaltyId;
    });
    subheader.innerText = locationSelected.name;
    setCurrentLoyalty(locationSelected);
    if (locationSelected.loyalty) setTiers(locationSelected.loyalty);
  }

  return (
    <>
      <Card>
        <CardContent className="d-flex justify-content-between">
          {getScope() == ROLE.SUPERADMIN ? (
            <div>
              <Typography variant="body1">Location ID</Typography>
              <Typography variant="h6">{currentLoyalty.id}</Typography>
            </div>
          ) : null}
          <div>
            <Typography variant="body1">Loyalty Enabled</Typography>
            <Switch
              checked={currentLoyalty.loyatlyEnabled ? true : false}
              onChange={(e, checked) =>
                setCurrentLoyalty({
                  ...currentLoyalty,
                  loyatlyEnabled: checked,
                })
              }
              size="medium"
              color="primary"
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          </div>
          <div>
            <Typography variant="body1">Points Earned</Typography>
            <Typography variant="h6">{currentLoyalty.points ?? 0}</Typography>
          </div>
          <div>
            <Typography variant="body1">Cash Redeemed</Typography>
            <Typography variant="h6">{currentLoyalty.cash ?? 0}</Typography>
          </div>
        </CardContent>
      </Card>

      <div className={styles.wrapper}>
        <Typography variant="h4">How Users Earn</Typography>
        <Divider />
        <Typography variant="h6" className={styles.text}>
          Users earn 100 points for every dollar they spend and 50 points for
          every dollar of YokeCash added.
        </Typography>
      </div>

      <div className={styles.wrapper}>
        <Typography variant="h4">Product Tiers</Typography>
        <Divider />
        <Typography variant="h6" className={styles.text}>
          Users can redeem points for products based on 5 tiers. Here you can
          decide which products go in which tier.
        </Typography>
        {renderSaveButton()}
        <div className={styles.wrapper}>
          <ProductTiers onChange={setTiers} loyalty={currentTiers} />
        </div>
      </div>
      <ResponseModal
        isSuccess={responseValue?.isSuccess}
        message={responseValue?.message}
        open={responseValue?.isMessageOpen}
        onClose={(value) =>
          setResponseValue({ ...responseValue, isMessageOpen: value })
        }
      />
    </>
  );
}

import axios from 'axios';
import { getDefaultParameters_V2 } from './helpers/getDefaultParameters';

/**
 * Get list of applications
 */
export function getApplications({ limit = 10, page = 1 }) {
  return axios.get(`/applications?page=${page}&limit=${limit}`+getDefaultParameters_V2(false));
}

/**
 * Get application details
 */
export function getApplicationDetails(applicationId) {
  return axios.get(`/applications/${applicationId}`+getDefaultParameters_V2(true));
}

/**
 * Create application from entered data
 * with the following properties
{
  "contactInfo": {},
  "companyAddress": {},
  "companyInfo": {},
  "owners": {},
  "bankInfo": {},
  "vmsInfo": {},
  "status": "inProgress/paused/completed"
}
 */
export function createApplication(applicationData) {
  return axios.post('/applications', applicationData, {
    headers: { 'Content-Type': 'application/json' }
  });
}

/**
 * Update existing application
 * receives same object as createApplication
 */
export function updateApplication(applicationId, applicationData) {
  return axios.post(`/applications/${applicationId}`, applicationData, {
    headers: { 'Content-Type': 'application/json' }
  });
}

export function uploadW9(file, companyName){
  const formData = new FormData()
  formData.append('file', file)
  formData.append('companyName', companyName)
  return axios.post('/applications/w9', formData)

}

/**
 * Approve application by admin user
 */
export function approveApplication(applicationId) {
  return axios.post(`/applications/${applicationId}/approve`);
}

/**
 * Deny application by admin user
 */
export function denyApplication(applicationId) {
  return axios.post(`/applications/${applicationId}/deny`);
}

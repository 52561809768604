import React from "react";
import { Remove } from "@material-ui/icons";
import { TableCell } from "@material-ui/core";
import { id } from "date-fns/locale";
import { format } from 'date-fns';
import _ from "lodash";

function KiosksDiagnosticReport({ kiosks }) {
  const diagnosticReport = kiosks[0]?.diagnosticReport;
  return(
    <>
    <TableCell  id={`setup_locations_table_kiosk_cell_${id}`} align="left" padding="normal">
                         {kiosks && kiosks.length > 1 ? (
                              <Remove />
                            ) : (
                              <div>
                                {!_.isEmpty(diagnosticReport)
                                  ? new Date() -
                                      new Date(
                                        diagnosticReport?.creationDate ?? ""
                                      ) <
                                    60 * 15 * 1000
                                    ? `🌐 Connected via ${
                                      diagnosticReport?.blob?.netStateType != null
                                          ? diagnosticReport?.blob?.netStateType != "none"
                                            ? diagnosticReport?.blob?.netStateType
                                            : "Cell"
                                          : diagnosticReport?.isWifi
                                          ? "WiFi"
                                          : "Cellular"
                                      }`
                                    : `❌ Offline`
                                  : "💭"}
                              </div>
                            )}
                          </TableCell>
                          <TableCell
                            id={`setup_locations_table_power_cell_${id}`}
                            align="left"
                            padding="normal"
                          >
                            {kiosks && kiosks.length > 1 ? (
                              <Remove />
                            ) : (
                              <div>
                               
                                {!_.isEmpty(diagnosticReport)
                                  ? diagnosticReport?.hasPower
                                    ? `🔋 Getting Power - Battery ${diagnosticReport?.batteryLevel}%`
                                    : `🔌 Unplugged ${diagnosticReport?.batteryLevel}%`
                                  : "💭"}
                              </div>
                            )}
                            
                          </TableCell>
                          {kiosks.length <1&&<TableCell
                            id={`setup_locations_details_diagnos_creation_date_cell_${id}`}
                            align="left"
                            padding="normal"
                          >
                            {diagnosticReport
                              ? format(
                                  new Date(
                                    diagnosticReport?.creationDate
                                  ),
                                  "MM/dd/yyyy - hh:mm aa"
                                )
                              : "Never Checked In"}
                          </TableCell>}

    </>
  )
}

export default KiosksDiagnosticReport;

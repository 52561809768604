import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import to from 'await-to-js';
import {
  Card,
  CardContent,
  Typography,
  Paper,
  Tabs,
  Tab
} from '@material-ui/core';
import { connect, useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import * as Sentry from "@sentry/react";
import jsPDF from "jspdf";
import csvDownload from "json-to-csv-export";
import "jspdf-autotable";

import RefundModal from 'app/components/Helpers/RefundModal';
import { receipt } from 'app/components/Ledger/services/sales';
import LedgerTable from 'app/components/Ledger/components/LedgerTable/LedgerTable';
import { accessPermission } from 'app/common/helperFunction';
import { customerActions } from 'app/store/actions';
import ResendModal from 'app/components/Helpers/ResendModel';
import LoadingSpinner from 'app/partials/content/LoadingSpinner';
import { useCustomResponse } from 'app/hooks/useCustomResponse';

import { getLedger, reportFormats } from '../../../../../../services/reports';
import ExportAndCustomFilters from '../../../../../../partials/content/ExportAndCustomFilters';
import useCustomerHeader from '../../../../../../hooks/useCustomHeader';
import ResponseModal from '../../../../../Helpers/ResponseModal';

import styles from './customer-details.module.scss';
import { CANCEL_TOKEN, NOT_APPLICABLE, STATUS_REFUNDED } from 'app/common/constant';
import { CREATION_DATE_FORMAT } from 'app/common/giftCardsConst';

export function CustomerDetails({ selectedCustomer }) {
  const {push} = useHistory();
  const dispatch = useDispatch();
  const prevTab = useSelector(state => state.customers.lastTab)
  const [currentTab, setCurrentTab] = useState(prevTab || 'order');
  const [isLoaded, setIsLoaded] = useState(false);
  const [sales, setSales] = useState([]);
  const [salesTotal, setSalesTotal] = useState(0);

  const [refundModalOpen, setRefundModalOpen] = useState(false);
  const [resendModalOpen, setResendModalOpen] = useState(false);
  const [refundModalTransactions, setRefundModalTransactions] = useState([]);
  const [refundModalOrderId, setRefundModalOrderId] = useState("");
  const [refundModalPrettyId, setRefundModalPrettyId] = useState("");
  const [currentRowData,setCurrentRowData] = useState(null)
  const [selectedRows, setSelectedRows] = useState([]);

  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10
  });
  const { id } = useCustomerHeader({
    property: 'pageTitle',
    getRelatedItem: () => selectedCustomer
  });

  const [responseValue, setResponseValue] = useCustomResponse()

  useEffect(() => {
    if(!accessPermission("CustomersAll")){
      push({pathname: "/dashboard"});
    }
  }, []);

  useEffect(() => {
    getCustomerHistoryData();
  }, [currentTab, pagination]);

  async function getCustomerHistoryData() {
    const [err, res] = await to(getLedger(currentTab, pagination, id, {}));
    if (err) {
      Sentry.captureException(err);
      if(err.message === CANCEL_TOKEN)return;
      setResponseValue({
        ...responseValue,  
        isMessageOpen: true,
        isSuccess: false,
        message:`We had trouble grabbing this customers order history.\n${err}`
      });
      return console.error('Error on Customer history fetch. ', err);
    }
    const { rows, count } = res.data.orders;
    setIsLoaded(true);
    setSales(rows);
    setSalesTotal(count);
  }
  /**
   * Update pagination data on table pagination component change
   */
  function onPaginationChange(newPagination) {
    setPagination(newPagination);
  }

  function getTabName(tabName){
   return (tabName === "order" ? "Transaction History" : tabName === "valueAdd" ? "Balance History" :" Tab History" ); 
  }

  async function handleExport(type) {
    const selectedTab = currentTab;
    const [err, res] = await to(getLedger(selectedTab, {page: 1,limit: salesTotal}, id, {}));
    if(err){
      Sentry.captureException(err);
      setResponseValue({
        ...responseValue,  
        isMessageOpen: true,
        isSuccess: false,
        message:`We had trouble grabbing this customers order history.\n${err}`
      });
      return console.error('Error on Customer history fetch. ', err);
    }

    const { rows } = res.data.orders;

    if (rows.length > 0) {
      if (type === reportFormats.CSV) {
        const exportRows = [];
        rows.forEach((ele) => {
          const {paymentType,creationDate,total,location,status,note,order: { refunds }} = ele
          const isRefunded = refunds && refunds.find((refund) => refund.success === true);
          const transactionStatus = status ? status : NOT_APPLICABLE
          let obj = {
            Date: !creationDate
              ? NOT_APPLICABLE
              : format(new Date(creationDate), CREATION_DATE_FORMAT),
            Amount: !total? "$ 0" : `$${total}`,
            Card: !paymentType ? NOT_APPLICABLE : paymentType,
            Location: !location?.name ? NOT_APPLICABLE : location?.name,
            Status: isRefunded ? STATUS_REFUNDED : transactionStatus,
            Note: note? note :NOT_APPLICABLE,
          };
          exportRows.push(obj);
        });

        csvDownload(exportRows, `${selectedCustomer.firstName} ${selectedCustomer.lastName} / ${getTabName(selectedTab)} - ${format(new Date(), 'MM/dd/yyyy')}.${type}`);
      }
    }
  }

  function onIssueRefund(rowId) {
    const sale = sales.filter(o => o.id == rowId)[0]
    setRefundModalOrderId(rowId)
    setRefundModalPrettyId(sale.order.prettyId)
    setRefundModalTransactions(sale.order.transactions)
    setRefundModalOpen(true)
  }

  function onResendReceipt(currentRow) {
    setResendModalOpen(true);
    setCurrentRowData(currentRow)
  }

  async function handleResendReceipt(row, emailId) {
    const [err,refundResponse] = await to(receipt(row.id, emailId))
    if (err){

      if(err.response){
        setResponseValue({
          ...responseValue,
          isMessageOpen: true,
          isServerError: false,
          isSuccess: false,
          message:`${err.response.data.message}`
        });
        return
      }
      
      Sentry.captureException(err);
      setResponseValue({
        ...responseValue,  
        isMessageOpen: true,
        message:`Failed resending receipt Order #${row.id} - ${err.message}`
      });
    } else {
      setResponseValue({
        ...responseValue,  
        isMessageOpen: true,
        isSuccess:true,
        message:`Successfully resent receipt for Order #${row.id}`
      });
    }
  }
   /**
     * Mark row as selected (checkbox)
     * @param id
     */
   function selectRow(id) {
    if (selectedRows.includes(id))
      setSelectedRows(selectedRows.filter(item => item !== id));
    else
      setSelectedRows([...selectedRows, id]);
  }

  /**
   * Select all rows
   */
  function masterToggle() {
    if (selectedRows.length)
      setSelectedRows([]);
    else
      setSelectedRows(sales.map(({ id }) => id));
  }
  /**
   * Expand row
   * @param row
   */
   function onExpandRow(row) {
    const updatedSales = sales.map((sale) => {
      if (sale.id === row.id)
        return {
          ...sale,
          isExpanded: !sale.isExpanded,
        };

      return sale;
    });
    setSales(updatedSales);
  }

  return (
    <>
      <Card id="customers_details_card_wrapper">
        <CardContent  id="customers_details_card_content" className={styles.container}>
          <div id="customers_details_card_content_inner_wrapper_0" className={styles.innerContainer}>
            <Typography id="customers_details_card_content_typography_user_id_label" variant="body1">User ID</Typography>
            <Typography id="customers_details_card_content_typography_user_id_value" variant="h6">{selectedCustomer.id}</Typography>
          </div>
          <div id="customers_details_card_content_inner_wrapper_1" className={styles.innerContainer}>
            <Typography id="customers_details_card_content_typography_email_label" variant="body1">Email</Typography>
            <Typography id="customers_details_card_content_typography_email_value" variant="h6">{selectedCustomer.email}</Typography>
          </div>
          
          <div id="customers_details_card_content_inner_wrapper_2" className={styles.innerContainer}>
            <Typography id="customers_details_card_content_typography_yoke_cash_balance_label" variant="body1">Yoke Cash Balance</Typography>
            <Typography id="customers_details_card_content_typography_yoke_cash_balance_value" variant="h6">{`$ ${selectedCustomer.funds ? selectedCustomer.funds[0].balance.toFixed(2) : 0}`}</Typography>

          </div>
          <div  id="customers_details_card_content_inner_wrapper_3" className={styles.innerContainer}>
            <Typography id="customers_details_card_content_typography_loyalty_points_label" variant="body1">Loyalty Points</Typography>
            <Typography id="customers_details_card_content_typography_loyalty_points_value" variant="h6">{selectedCustomer.points ? selectedCustomer.points[0].balance.toFixed(2) : 0}</Typography>
          </div>
        </CardContent>
      </Card>
      <div id="customers_details_tabs_wrapper" className={styles.wrapper}>
        <Paper>
          <Tabs
            id="customers_details_tabs"
            value={currentTab}
            onChange={(e, value) => {
              setCurrentTab(value); 
              dispatch(customerActions.storeLastcustomerDetailTab(value))
            }}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab  id="customers_details_tabs_label_transaction_history" label="Transaction History" value="order"/>
            <Tab id="customers_details_tabs_label_balance_history" label="Balance History" value="valueAdd"/>
          </Tabs>
        </Paper>
        <div id="customers_details_export_filter_weapper" className={styles.innerWrapper}>
          <ExportAndCustomFilters onExport={handleExport}/>
        </div>
        { !isLoaded ? (
            <LoadingSpinner/>
          ) : (
            <LedgerTable 
              isLoads={currentTab === 'order'}
              onIssueRefund={onIssueRefund}
              onResendReceipt={onResendReceipt}
              onExpandRow={onExpandRow}
              onPaginationChange={onPaginationChange}
              onEditRowClick={()=>{}}
              selectedRows={selectedRows}
              rows={sales}
              pagination={pagination}
              salesTotal={salesTotal}
              onMasterToggle={() => masterToggle()}
              onSelectRow={id => selectRow(id)}
              hideColumns = {['customer']}
              isHideCustomerName={true}
            />
        )}
      </div>
      <ResponseModal
      isSuccess={responseValue?.isSuccess}
      message={responseValue?.message}
      open={responseValue?.isMessageOpen}
      onClose={value => setResponseValue({...responseValue, isMessageOpen:value})}
      />
      <RefundModal
        open={refundModalOpen}
        transactions={refundModalTransactions}
        orderId={refundModalOrderId}
        prettyId={refundModalPrettyId}
        onClose={value => setRefundModalOpen(value)}
      />
      {Boolean(resendModalOpen) && <ResendModal
        open={resendModalOpen}
        onClose={() =>setResendModalOpen(false)}
        handleResendReceipt={handleResendReceipt}
        row = {currentRowData}
      />}
    </>
  );
}

export default connect(
  state => ({
    selectedCustomer: state.customers.selectedCustomer
  })
)(CustomerDetails);

import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import objectPath from 'object-path';
import { Link, withRouter, useLocation } from 'react-router-dom';
import clsx from 'clsx';

import { removeCSSClass } from '../../utils/utils';
import * as builder from '../../ducks/builder';
import MenuList from './MenuList';
import KTMenu from '../../_assets/js/menu';
import KTUtil from '../../_assets/js/util';
import styles from './aside_menu.module.scss';

function AsideLeft(props) {
  const {
    ulClasses,
    disableAsideSelfDisplay,
    menuConfig,
    layoutConfig,
    asideClassesFromConfig,
    headerLogo,
    asideMenuAttr
  } = props;
  const asideMenuRef = useRef(null);
  const [insideTm, setInsideTm] = useState(null);
  const [outsideTm, setOutsideTm] = useState(null);
  const location = useLocation();

  useEffect(() => {
    /**
     * @method initMenu By ID
     */
    initMenu();

    const options = setupMenuOptions();
    const ktMenu = new KTMenu(asideMenuRef.current, options)
  }, []);

  function initMenu() {
    const { config } = props;
    if (
      objectPath.get(config, 'aside.menu.dropdown') !== 'true'
      && objectPath.get(config, 'aside.self.fixed')
    )
      asideMenuRef.current.setAttribute('data-ktmenu-scroll', '1');

    if (objectPath.get(config, 'aside.menu.dropdown') === 'true') {
      asideMenuRef.current.setAttribute('data-ktmenu-dropdown', '1');
      asideMenuRef.current.setAttribute(
        'data-ktmenu-dropdown-timeout',
        objectPath.get(config, 'aside.menu.submenu.dropdown.hover-timeout')
      );
    }
  }

  function setupMenuOptions() {
    const menuOptions = {
      // vertical scroll
      scroll: null,
      // submenu setup
      submenu: {
        desktop: {
          // by default the menu mode set to accordion in desktop mode
          default: 'dropdown'
        },
        tablet: 'accordion', // menu set to accordion in tablet mode
        mobile: 'accordion' // menu set to accordion in mobile mode
      },
      // accordion setup
      accordion: {
        expandAll: false // allow having multiple expanded accordions in the menu
      }
    };

    // init aside menu
    let menuDesktopMode = 'accordion';
    const dataKtmenuDropdown = asideMenuRef.current.getAttribute(
      'data-ktmenu-dropdown'
    );
    if (dataKtmenuDropdown === '1')
      menuDesktopMode = 'dropdown';

    if (typeof objectPath.get(menuOptions, 'submenu.desktop') === 'object')
      menuOptions.submenu.desktop.default = menuDesktopMode;

    return menuOptions;
  }

  /**
   * Use for fixed left aside menu, to show menu on mouseenter event.
   */
  function mouseEnter() {
    // check if the left aside menu is fixed
    if (document.body.classList.contains('kt-aside--fixed')) {
      if (outsideTm) {
        clearTimeout(outsideTm);
        setOutsideTm(null);
      }

      setInsideTm(setTimeout(() => {
        // if the left aside menu is minimized
        if (
          document.body.classList.contains('kt-aside--minimize')
          && KTUtil.isInResponsiveRange('desktop')
        ) {
          removeCSSClass(document.body, 'kt-aside--minimize');
          document.body.classList.add('kt-aside--minimize-hover');
        }
      }, 50));
    }
  }

  /**
   * Use for fixed left aside menu, to show menu on mouseenter event.
   */
  function mouseLeave() {
    if (document.body.classList.contains('kt-aside--fixed')) {
      if (insideTm) {
        clearTimeout(insideTm);
        setInsideTm(null);
      }

      setOutsideTm(setTimeout(() => {
        // if the left aside menu is expand
        /* eslint-disable-next-line  */
        const kUtilIsResponsiveRange = KTUtil.isInResponsiveRange("desktop");
        const hasMinimizeHover = document.body.classList.contains(
          'kt-aside--minimize-hover'
        );
        if (hasMinimizeHover
          // eslint-disable-next-line no-undef
          && kUtilIsResponsiveRange
        ) {
          // hide back the left aside menu
          removeCSSClass(document.body, 'kt-aside--minimize-hover');
          document.body.classList.add('kt-aside--minimize');
        }
      }, 100));
    }
  }

  return (
    <>
      <div
        id="kt_aside_menu"
        ref={asideMenuRef}
        style={{ maxHeight: '90vh', position: 'relative' }}
        onMouseEnter={mouseEnter}
        onMouseLeave={mouseLeave}
        data-ktmenu-vertical="1"
        className={`${clsx('kt-aside-menu', asideClassesFromConfig)} ${styles.aside_menu_list}` }
        {...asideMenuAttr}
      >
        {disableAsideSelfDisplay && (
          <div id="kt-header-logo" className="kt-header-logo">
            <Link to="">
              <img alt="logo" src={headerLogo}/>
            </Link>
          </div>
        )}
        <ul id="kt-menu__nav"  className={clsx('kt-menu__nav', ulClasses)}>
          <MenuList
            currentUrl={location.pathname}
            menuConfig={menuConfig}
            layoutConfig={layoutConfig}
          />
        </ul>
      </div>
    </>
  );
}

const mapStateToProps = store => ({
  menuConfig: store.builder.menuConfig,
  layoutConfig: store.builder.layoutConfig,
  headerLogo: builder.selectors.getLogo(store),
  asideMenuAttr: builder.selectors.getAttributes(store, { path: 'aside_menu' }),
  disableAsideSelfDisplay:
    builder.selectors.getConfig(store, 'aside.self.display') === false,

  ulClasses: builder.selectors.getClasses(store, {
    path: 'aside_menu_nav',
    toString: true
  }),

  asideClassesFromConfig: builder.selectors.getClasses(store, {
    path: 'aside_menu',
    toString: true
  })
});

export default withRouter(connect(mapStateToProps)(AsideLeft));

/**
 * Point of truth for customer subscribers
 * @type {*[]}
 */
let subscribers = [];

/**
 * Currently logged in User instance
 * @type {User}
 */
export let currentUser = null;

/**
 * Class which encapsulates the structure of the user
 * Contains a method to serialize it to an object (for cookie storage)
 */
class User {
  constructor(data) {
    this.id = data.id || '';
    this.first_name = data.first_name || '';
    this.last_name = data.last_name || '';
    this.fullName = `${data.first_name} ${data.last_name}` || '';
    this.email = data.email || '';
    this.phone = data.phone || '';
    this.image = data.image || '';
    this.permissions = data.permissions || [];
    this.scope = data.scope || '';
    this.jwtExpires = data.jwtExpires || '';
    this.refreshTokenExpires = data.refreshTokenExpires || '';
  }

  /**
   * Serialize the user object to be stored in the cookies
   * @returns {Object}
   */
  serialize() {
    return {
      id: this.id,
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      phone: this.phone,
      image: this.image,
      permissions: this.permissions,
      scope: this.scope,
      jwtExpires: this.jwtExpires,
      refreshTokenExpires: this.refreshTokenExpires
    };
  }

  subscribe(subscriber) {
    subscribers.push(subscriber);
  }

  unsubscribe(subscriber) {
    subscribers = subscribers.filter(item => item !== subscriber);
  }

  emit(user) {
    subscribers.forEach(subscriber => {
      subscriber({ ...user });
    });
  }
}

/**
 * This functions sets the current instance of the user and returns an error when you try to reset it
 * @param data
 */
export function setUser(data) {
  if (!data)
    return clearUser();

  if (Object.keys(data).length === 0 && data.constructor === Object)
    return;

  currentUser = new User(data);
  currentUser.emit(currentUser);
}

/**
 * This function removes all traces of the user
 */
export function clearUser() {
  currentUser = null;
}

/**
 * Determines if user is logged in based on if the instance is null or not
 * @returns {boolean}
 */
export function isLoggedIn() {
  if (!currentUser)
    deserializeUser();

  return currentUser && !!currentUser.userId;
}

/**
 * Get user cookie object. Saved from the server response.
 */
export function deserializeUser() {
  let userObject = {};
  const userData = localStorage.getItem('user');

  try {
    userObject = userData ? JSON.parse(decodeURIComponent(userData)) : {};
  } catch (error) {
    console.error(error);
  }

  setUser(userObject);
}

import React from "react";
import clsx from "clsx";
import {
  Card,
  CardContent,
  FormControlLabel,
  Switch,
  TextField,
  Button,
  InputAdornment,
  MenuItem,
} from "@material-ui/core";

import {
  MONETARYPERCENT,
  FEETYPES,
  blockInvalidChar,
  NUMBER,
  TWO_TIER_ALERT_MESSAGE,
} from "app/common/constant";

import styles from "./twoTierPricing.module.scss";
import _ from "lodash";

export default function TwoTierPricing({
  feeType = "",
  feeAmount = 0,
  feeActive,
  onUpdate,
  wrapToCard = true,
  idName,
  onSave,
}) {
  /**
   * @error
   * checking value matching the condition or not for error
   * for isMonetaryPercentageError value should not be less than 0 or greater than 100
   * isMonetaryAbsoluteError value should not be less than 0 and empty
   * */

  if (!_.isNull(feeAmount)) {
    feeAmount = feeAmount;
  }
  // Error Handeling
  const isMonetaryPercentageError =
    feeType === MONETARYPERCENT &&
    (feeAmount > 100 || feeAmount < 0 || isNaN(feeAmount));
  const isMonetaryAbsoluteError =
    feeType !== MONETARYPERCENT &&
    (feeAmount > 100 || feeAmount < 0 || isNaN(feeAmount));

  const fee =  feeType === MONETARYPERCENT && feeAmount < 1  ? feeAmount * 100 : feeAmount
  
  const showWarningMsg =  feeType !== MONETARYPERCENT && !isNaN(feeAmount) && feeAmount > 1
  
  /**
   * Render two tier pricing form
   * @returns {*}
   */
  function renderForm() {
    return (
      <>
        <div
          id={`two_tier_pricing_form_wrapper`}
          className={styles.outerWrapper}
        >
          <TextField
            className={styles.twotierField}
            margin="normal"
            id={`${idName}_Fee_Type`}
            label="Fee Type"
            variant="outlined"
            name="feeType"
            select
            value={!_.isNull(feeType) ? feeType : MONETARYPERCENT}
            onChange={(e) => onUpdate(e)}
            fullWidth
          >
            {FEETYPES.map((option) => (
              <MenuItem
                id={`two_tier_pricing_option_menu_item`}
                key={option.value}
                value={option.value}
              >
                {option.label}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            className={styles.twotierField}
            margin="normal"
            id={`${idName}_Fee_Amount`}
            label="Fee Amount"
            name="feeAmount"
            variant="outlined"
            placeholder={"eg: 1"}
            type={NUMBER}
            defaultValue={0}
            value={fee}
            onChange={(e) => onUpdate(e)}
            onKeyDown={blockInvalidChar}
            InputProps={{
              inputProps: {
                min: 0,
                max: 100,
              },
              endAdornment: (
                <InputAdornment position="end">
                  {feeType !== MONETARYPERCENT ? "$" : "%"}
                </InputAdornment>
              ),
            }}
            fullWidth
            error={isMonetaryPercentageError || isMonetaryAbsoluteError}
            helperText={
              (isMonetaryPercentageError && TWO_TIER_ALERT_MESSAGE.MONETARY_PERCENT_ERROR) ||
              (isMonetaryAbsoluteError && TWO_TIER_ALERT_MESSAGE.MONETARY_ABSOLUTE_ERROR) || 
              (showWarningMsg && TWO_TIER_ALERT_MESSAGE.MONETARY_ABSOLUTE_WARNING_MSG)
            }
          />

          <FormControlLabel
            control={
              <Switch
                checked={feeActive}
                onChange={(e) => {
                  onUpdate(e);
                }}
                name="feeActive"
              />
            }
            label="Fee Active"
          />
          <div className={styles.twotierSwitch1} />

          <Button
            id={`two_tier_pricing_save button`}
            className={styles.saveButton}
            size="large"
            variant="outlined"
            color="primary"
            disableElevation
            onClick={() => onSave()}
            disabled={isMonetaryPercentageError || isMonetaryAbsoluteError}
          >
            Save
          </Button>
        </div>
      </>
    );
  }

  return (
    <>
      {wrapToCard ? (
        <Card id={`two_tier_pricing_wrapper`} className={styles.cardWrapper}>
          <CardContent
            id={`two_tier_pricing_content`}
            className="d-flex justify-content-between"
          >
            {renderForm()}
          </CardContent>
        </Card>
      ) : (
        <div
          className={clsx("d-flex justify-content-between", styles.cardWrapper)}
          id={`two_tier_pricing_content`}
        >
          {renderForm()}
        </div>
      )}
    </>
  );
}

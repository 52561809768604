import moment from "moment";
import { DEFAULT_DISPLAY_NAME_MAXLENGTH } from "../constant";

export default function getEllipsisFormat(
  value,
  maxLength = DEFAULT_DISPLAY_NAME_MAXLENGTH
) {
  if (value?.length <= maxLength) {
    return value;
  } else {
    return value?.substring(0, maxLength - 3) + "...";
  }
}
export const PAGINATION_INITIAL_STATE = { page: 1, limit: 10 };
export const INITIAL_PAGE_INDEX = 0

export const formatNumber =(number) => {
  const suffixes = ["", "K", "M", "B", "T"];
  let magnitude = 0;
  while (number >= 1000) {
      number /= 1000;
      magnitude++;
  }

  const value = parseFloat(number)?.toFixed(2)
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
}).format(value) + suffixes[magnitude];
}

/**
 * Method to get the given date in UTC format
 * @param date 
 * @returns 
 */
export const getUtcDateFormat = (date = new Date()) => moment(date).utc().format();
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { IconButton, Button } from "@material-ui/core";
import { Add, Close, Delete } from "@material-ui/icons";
import to from "await-to-js";
import * as Sentry from "@sentry/react";

import { customerActions } from "app/store/actions";
import ResponseModal from "app/components/Helpers/ResponseModal";
import { useCustomResponse } from "app/hooks/useCustomResponse";
import { CANCEL_TOKEN } from "app/common/constant";

import CustomersTable from "../../../../../../partials/content/Customers/CustomersTable";
import { getCustomers } from "../../../../../../services/reports";
import { useStyles } from "./Styles";

import styles from "../../../../../../../_metronic/_assets/sass/pages/groups.module.scss";
import styles2 from "_metronic/_assets/sass/loading-text.module.scss";

export default function GroupCustomers(props) {
  const {
    groupMembers = [],
    onInitialCustomersSelection,
    onSelectCustomers,
    setIsLoaded,
    addMemberInGroup,
    onSingleRemoveFromGroup,
    isLoading,
    searchQuery,
    isNew,
    setTotalCustomers,
  } = props;

  const dispatch = useDispatch();
  const { push } = useHistory();
  const bubbleStyles = useStyles();
  const [customersList, setCustomersList] = useState({
    customers: [],
    customersTotal: 0,
  });
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
  });
  const [tempSelectedCustomers, setTempSelectedCustomers] = useState([]);
  const [popupText, setPopupText] = useState("");
  const [responseValue, setResponseValue] = useCustomResponse();

  /**
   * Fetch customers' list
   */
  useEffect(() => {
    getCustomersList();
  }, [searchQuery, pagination]);

  async function getCustomersList() {
    const [err, res] = await to(getCustomers(pagination, searchQuery));

    if (err) {
      Sentry.captureException(err);
      if (err.message === CANCEL_TOKEN) return;
      setResponseValue({
        ...responseValue,
        isMessageOpen: true,
        isSuccess: false,
        message: `Error while getting Customers. ${err}`,
      });
      return console.error("[Customers]. Error while fetching data.");
    }
    var users = res.data.users;

    const formatedUsers = formatUsersInfo(users);

    dispatch(customerActions.storeCustomers(formatedUsers));
    setCustomersList({
      customers: formatedUsers,
      customersTotal: res.data.meta.totalItems,
    });
    setTotalCustomers(groupMembers.length);
    setIsLoaded(true);
  }

  function formatUsersInfo(users) {
    const uniformatUser = [];
    users.map((ele) => {
      uniformatUser.push(ele.user ? ele.user : ele);
    });
    return uniformatUser;
  }

  function handleEditRowClick(e) {
    push({ pathname: `/customers/${e.id}` });
    dispatch(customerActions.selectActiveCustomer(e.id));
  }

  function handleAddClick() {
    setPopupText("Adding...");

    if (tempSelectedCustomers.length) addMemberInGroup(tempSelectedCustomers);

    setTempSelectedCustomers([]);
  }

  function removeFromGroup(customersId) {
    setPopupText("Removing");
    onSingleRemoveFromGroup(customersId);
  }

  function onSelectNewCustomers(currentSelectedCustomers) {
    const newCustomersInCurrentPage = currentSelectedCustomers.filter(
      (customer) => !groupMembers.some((member) => member.id === customer.id)
    );
    const otherPagesSelectedCustomers = tempSelectedCustomers.filter(
      (member) => !customersList.customers.some((user) => user.id === member.id)
    );
    const newSelectedCustomers = [
      ...otherPagesSelectedCustomers,
      ...newCustomersInCurrentPage,
    ];

    setTempSelectedCustomers(newSelectedCustomers);
    onSelectCustomers(currentSelectedCustomers);
    onInitialCustomersSelection(newSelectedCustomers);
  }

  function removeFromSelected(customerId) {
    const customers = tempSelectedCustomers.filter(
      (member) => member.id !== customerId
    );
    setTempSelectedCustomers(customers);
  }

  function Bubble(props) {
    const { customer } = props;
    return (
      <div
        id="group_customer_bubble_container"
        className={bubbleStyles.bubbleContainer}
      >
        <div
          id="group_customer_bubble_customerName"
          className="customerName"
          style={{ whiteSpace: "nowrap", textAlign: "center" }}
        >
          {`${customer.firstName} ${customer.lastName}`}
        </div>
        <IconButton
          id="group_customer_bubble_delete_icon_button"
          onClick={() => {
            removeFromGroup(customer.id);
          }}
        >
          <Delete />
        </IconButton>
      </div>
    );
  }

  function TempBubble(props) {
    const { customer } = props;
    return (
      <div
        id="group_customer_tempBubble_container"
        className={bubbleStyles.bubbleContainer}
      >
        <div
          id="group_customer_tempBubble_customerName"
          className="customerName"
          style={{ whiteSpace: "nowrap", textAlign: "center" }}
        >
          {`${customer.firstName} ${customer.lastName}`}
        </div>
        <IconButton
          id="group_customer_tempBubble_delete_icon_button"
          onClick={() => {
            removeFromSelected(customer.id);
          }}
        >
          <Close />
        </IconButton>
      </div>
    );
  }

  return (
    <>
      {isLoading && (
        <div
          id="group_customers_loading_container"
          className={styles2.loadingContainer}
        >
          <p
            id="group_customers_loading_container_text"
            className={styles2.loadingText}
          >
            {popupText}
          </p>
        </div>
      )}
      {!isNew && groupMembers.length > 0 && (
        <div
          id="group_customers_groupMembers"
          className={bubbleStyles.bubbleBox}
          style={{ marginTop: "10px" }}
        >
          {groupMembers.map((ele, index) => (
            <Bubble customer={ele} key={index} />
          ))}
        </div>
      )}
      {tempSelectedCustomers.length > 0 && (
        <div
          id="group_customers_tempSelectedCustomers_container"
          className={bubbleStyles.container}
        >
          <div
            id="group_customers_tempSelectedCustomers_bubbleBox"
            className={bubbleStyles.bubbleBox}
          >
            {tempSelectedCustomers.map((ele, index) => (
              <TempBubble customer={ele} key={index} />
            ))}
          </div>
          {!isNew && (
            <div
              id="group_customers_addIn_group_button_wrapper"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
                marginTop: "10px",
              }}
            >
              <Button
                id="group_customers_addIn_group_button"
                variant="contained"
                color="primary"
                size="large"
                startIcon={<Add fontSize="large" />}
                className={bubbleStyles.AddButton}
                onClick={handleAddClick}
              >
                Add In Group
              </Button>
            </div>
          )}
        </div>
      )}
      <div id="group_customers_table_wrapper" className={styles.wrapper}>
        <CustomersTable
          customersList={customersList}
          pagination={pagination}
          groupMembers={groupMembers}
          onSelectCustomers={onSelectNewCustomers}
          selectedCustomersForAdd={tempSelectedCustomers}
          onEditRowClick={handleEditRowClick}
          onPaginationChange={(val) => setPagination(val)}
          onInitialCustomersSelection={onInitialCustomersSelection}
        />
      </div>
      <ResponseModal
        isSuccess={responseValue?.isSuccess}
        message={responseValue?.message}
        open={responseValue?.isMessageOpen}
        onClose={(value) =>
          setResponseValue({ ...responseValue, isMessageOpen: value })
        }
      />
    </>
  );
}

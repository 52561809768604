import React from 'react'
import { TextField } from '@material-ui/core';

import styles from './response.module.scss';


const ColorPickerInput =({value, onChange})=>{

    return(
        <div id={`color_picker_input_card`} className={styles.card}>
        <div id={`color_picker_input_inner_wrapper`} className={styles.innerWrapper}>
        <div>
        <input
            id={`color_picker_input_text`}
            className={styles.colorPicker}
            style={ {
                backgroundColor: `${value}`
            }}
            type='color'
            value={value}
            onChange={onChange}
        />
        </div>
        <div id={`color_picker_disabled_input_field`}>
            <TextField
                value={value}
                disabled
                fullWidth
            />
        </div>
        </div>
      </div>
    )
}

export default ColorPickerInput
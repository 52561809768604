import axios from 'axios';
import { getDefaultParameters_V2 } from './helpers/getDefaultParameters';

/**
 * Get list of notifications
 */
export function getNotifications({ limit = 10, page = 1 }) {
  return axios.get(`/notifications?limit=${limit}&page=${page}`+getDefaultParameters_V2(false));
}

export function readNotifications(id) {
  return axios.post(`/notifications/${id}`+getDefaultParameters_V2(true));
}

/**
 * Get list of tickets
 */
 export function getTickets({page = 1, limit = 10}, status = "open") {
  let url = `/tickets?page=${page}&limit=${limit}&status=${status}`+getDefaultParameters_V2(false)
   return axios.get(url);
 }

/**
 * get conversation of tickets
 */
export function getConversation({page = 1, limit = 10}, id) {
  return axios.get(`/tickets/${id}?page=${page}&limit=${limit}`);
}

/**
 * post reply on tickets 
 */
export function sendConversation(id,msgObject){
  return axios.post(`/tickets/${id}`,msgObject);
}

/**
 * patch method to update the ticket status for closing
 */
export function closeTicket(id, ticketClosingObj){
  return axios.post(`/tickets/resolve/${id}`,ticketClosingObj);
}
  
  
import React, { useEffect, useState } from "react";
import {
  Dialog,
  FormControl,
  Button,
  DialogActions,
  DialogContent,
  TextField,
  Box,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { getLocations } from '../../services/locations';
import * as Sentry from '@sentry/react';
import to from 'await-to-js';

import ResponseModal from 'app/components/Helpers/ResponseModal';
import { useCustomResponse } from "app/hooks/useCustomResponse";
import { CANCEL_TOKEN } from "app/common/constant";


import styles from "./add-ons.module.scss";

export default function AddLock(props) {
  const { setAddLockPopup } = props;
  const [searchLocationText, setSearchLocationText] = useState('');
  const [locations, setLocations] = useState([]);
  const [lockData ,setLockData] = useState({location:"", provider:"", lockSerial:""});
  const [responseValue, setResponseValue] = useCustomResponse()
  const providers = [
    {label: "Tritreq" , name: "Tritreq"},
    {label: "Minus40" , name: "Minus40"},
  ]

  

  useEffect(() => {
      getLocationsList()
      return () => {
          setSearchLocationText("");
      }
  }, [searchLocationText])
  /**
     * Get list of locations
     */
   async function getLocationsList() {
    const [err, res] = await to(getLocations(true, true, searchLocationText));
    if (err) {
      Sentry.captureException(err);
      if(err.message === CANCEL_TOKEN)return;
      setResponseValue({
        ...responseValue,
        isMessageOpen: true,
        message: `Error while getting location list. ${err}`,
        isSuccess: false
      })
      return console.error("Error on setup locations fetch: ", err);
    }
    setLocations(res.data.Locations);
  }

  function addLockDetails(name, value) {
    let val = value ?? "";
    const tempLockData = lockData;
    if(name === 'location')
      tempLockData[name] = val.id;
    else if(name === 'provider')
      tempLockData[name] = val.name;
    else tempLockData[name] = val;
    setLockData(tempLockData);
  }

  function handleCreateClick() {
    console.log("lock object values : ",lockData);
  }

  return (
    <>
      <Dialog
          id="add_ons_add_lock_dialog_wrapper"
        open
        style={{zIndex :"2"}}
        onClose={() => setAddLockPopup(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent id="add_ons_add_lock_dialog_content_wrapper">
          <Box
            id="add_ons_add_lock_dialog_content_inner_wrapper_0"
            sx={{
              width: 500,
              height: 55,
              maxWidth: "100%",
              marginBottom: 20,
            }}
          >
            <FormControl id="add_ons_add_lock_dialog_content_form_control_0" fullWidth className={styles.tierHeader}>
              <Autocomplete
                id="addLock_location"
                options={locations}
                noOptionsText={'Searching...'}
                getOptionLabel={(option) => option.name}
                onChange={(e, value) => addLockDetails('location',value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    id="addLock_locationSearch"
                    label=""
                    placeholder="Location Search"
                    variant="outlined"
                    onClick={(e) => setSearchLocationText(e.target.value)}
                  />
                )}
              />
            </FormControl>
          </Box>

          <Box
           id="add_ons_add_lock_dialog_content_inner_wrapper_1"
            sx={{
              width: 500,
              height: 55,
              maxWidth: "100%",
              marginBottom: 20,
            }}
          >
            <FormControl id="add_ons_add_lock_dialog_content_form_control_1" fullWidth className={styles.tierHeader}>
              <Autocomplete
                id="addLock_provider"
                options={providers}
                getOptionLabel={(option) => option.name}
                onChange={(e, value) => addLockDetails('provider', value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    id="addLock_providerSearch"
                    label=""
                    placeholder="Provider Search"
                    variant="outlined"
                  />
                )}
              />
            </FormControl>
          </Box>

          <Box
            id="add_ons_add_lock_dialog_content_inner_wrapper_2"
            sx={{
              width: 500,
              maxWidth: "100%",
              height: 55,
            }}
          >
            <TextField
              fullWidth
              label=""
              placeholder="Lock Serial"
              id="addLock_lockSerial"
              variant="outlined"
              onChange={(e) => addLockDetails('lockSerial', e.target.value)}
            />
          </Box>
        </DialogContent>
        <DialogActions id="add_ons_add_lock_dialog_action_wrapper" style={{ justifyContent: "center" }}>
          <Button
            id="add_ons_add_lock_dialog_cancle_button"
            variant="contained"
            size="large"
            color="primary"
            onClick={() => setAddLockPopup(false)}
          >
            Cancel
          </Button>
          <Button 
            id="add_ons_add_lock_dialog_create_button"
            variant="contained" 
            size="large" 
            color="primary"
            onClick={() => handleCreateClick()}
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
      <ResponseModal
      isSuccess={responseValue?.isSuccess}
      message={responseValue?.message}
      open={responseValue?.isMessageOpen}
      onClose={value => setResponseValue({...responseValue, isMessageOpen: value})}
      />
    </>
  );
}

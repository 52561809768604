import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import to from "await-to-js";
import * as Sentry from "@sentry/react";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@material-ui/core";
import "jspdf-autotable";
import { jsPDF } from "jspdf";
import { format } from "date-fns";

import LoadingSpinner from "app/partials/content/LoadingSpinner";
import ResponseModal from "app/components/Helpers/ResponseModal";
import RefundModal from "app/components/Helpers/RefundModal";
import {
  EPaymentMethod,
  iphoneNames,
  paymentMethods,
} from "app/common/LedgerConst";
import { accessPermission } from "app/common/helperFunction";
import ResendModal from "app/components/Helpers/ResendModel";
import { getScope, hasMultipleAccounts } from "app/services/helpers/account-id";
import OperatorSpecificModal from "app/components/Helpers/OperatorSpecificModal";
import { useCustomResponse } from "app/hooks/useCustomResponse";
import {
  CANCEL_TOKEN,
  CANTALOUPE_CASH,
  CARTS_REPORT_NAME_PREFIX,
  GLOBAL_CONNECT,
  LOADS_REPORT_NAME_PREFIX,
  ORDER_TYPE_ORDER,
  ORDER_TYPE_VALUEADD,
  PAYMENT_TYPES,
  SALES_REPORT_NAME_PREFIX,
  YOKE_LOYALTY_PROGRAM,
} from "app/common/constant";
import {
  generateFileNameForReport,
  triggerReportDownload,
} from "app/services/helpers/reportDownload";
import { PAGINATION_INITIAL_STATE } from "app/common/utils/generic";
import { getLoyaltyProgram } from "_metronic/layout/MenuConfig";

import ExportAndCustomFilters from "../../../../partials/content/ExportAndCustomFilters";
import LedgerTable from "../LedgerTable/LedgerTable";
import {
  getCartReports,
  getCarts,
  getLedger,
  getSalesReports,
  reportFormats,
} from "../../../../services/reports";
import * as fromActions from "../../../../store/actions";
import Search from "../../../../partials/content/Customers/Search";
import { receipt } from "../../services/sales";
import CartTable from "../Carts/CartTable";
import CartsStatusOptions from "../Carts/CartsStatusOptions";

import styles from "./sales.module.scss";
import { ROLE } from "app/common/roleConstant";
import { CustomArray } from "app/common/utils/limitArray";
import BulkRefundModal from "app/components/Helpers/BulkModal";

export function Sales({
  storeLedgers,
  storeValueAdd,
  storeTabPayment,
  selectSaleOrder,
  filter,
}) {
  const { locations, location: selectedLocation } = filter;
  const { location, push } = useHistory();
  const [orderType] = useState(location.pathname.split("/")[2]);
  const [pagination, setPagination] = useState(PAGINATION_INITIAL_STATE);
  const [salesTotal, setSalesTotal] = useState(0);
  const [sales, setSales] = useState([]);
  const [orderIdSearchQuery, setOrderIdSearchQuery] = useState("");
  const [lastFourSearchQuery, setLastFourSearchQuery] = useState("");
  const [refundModalOpen, setRefundModalOpen] = useState(false);
  const [bulkRefundModalOpen, setBulkRefundModalOpen] = useState(false);
  const [resendModalOpen, setResendModalOpen] = useState(false);
  const [resendModalOrder, setResendModalOrder] = useState({});
  const [refundModalTransactions, setRefundModalTransactions] = useState([]);
  const [refundModalOrderId, setRefundModalOrderId] = useState("");
  const [refundModalPrettyId, setRefundModalPrettyId] = useState("");
  const [refundModalRefunds, setRefundModalRefunds] = useState();
  const [isLoaded, setIsLoaded] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  const [creditCardChecked, setCreditCardChecked] = useState(true);
  const [loyaltyChecked, setLoyaltyChecked] = useState(true);
  const [yokeCashChecked, setYokeCashChecked] = useState(true);
  const [tabChecked, setTabChecked] = useState(true);
  const [globalConnectCardChecked, setGlobalConnectCardChecked] = useState(
    true
  );
  const [operatorLoadedChecked, setOperatorLoadedCheck] = useState(true);
  const [cartsStatus, setCartsStatus] = useState([
    { id: 1, label: "Open Carts", value: "Open", status: true },
    { id: 2, label: "Purchase Carts", value: "Purchased", status: true },
    { id: 3, label: "Canceled Carts", value: "Canceled", status: true },
    { id: 4, label: "Abandoned Carts", value: "Abandoned", status: true },
  ]);
  const [operatorSpecificModalOpen, setOperatorSpecificModalOpen] = useState(
    false
  );
  const [cashLoadedChecked, setCashLoadedChecked] = useState(true);
  const [isRefunded, setIsRefunded] = useState(false);
  const [isBulkRefunded, setIsBulkRefunded] = useState(false);
  const [selectedPaymentMethods, setSelectedPaymentMethods] = useState("");
  const [responseValue, setResponseValue] = useCustomResponse();
  const [resetPaginationProp, setResetPaginationProp] = useState(false);
  const [updatedSalesDate, setUpdatedSalesData] = useState([]);
  const loyaltyProgram = useSelector(getLoyaltyProgram);

  const limitedArray = new CustomArray();
  const type = "carts";
  const fontSize = "0.8rem";
  const reportTitlePrefix =
    orderType === "order" ? SALES_REPORT_NAME_PREFIX : LOADS_REPORT_NAME_PREFIX;
  let searchTimeout = null;
  const { page, limit } = pagination;

  useEffect(() => {
    if (!accessPermission("LedgerAll")) {
      push({ pathname: "/dashboard" });
    }
  }, []);

  /**
   * Fetch data on every pagination change and component mounting
   */
  useEffect(() => {
    getSales();
    // eslint-disable-next-line
  }, [
    orderIdSearchQuery,
    lastFourSearchQuery,
    creditCardChecked,
    loyaltyChecked,
    yokeCashChecked,
    tabChecked,
    globalConnectCardChecked,
    operatorLoadedChecked,
    cartsStatus,
    cashLoadedChecked,
    pagination,
    page,
    salesTotal,
    limit,
  ]);
  useEffect(() => {
    if (isRefunded) {
      getSales();
    }
  }, [isRefunded]);

  function getStatusList() {
    let statusList = "";
    cartsStatus.forEach((ele) => {
      if (ele.status) statusList += ele.value + ",";
    });
    statusList = statusList.slice(0, -1);
    return statusList;
  }
  /**
   * Proceed sales
   * @returns {Promise<void>}
   */
  async function getSales() {
    if (hasMultipleAccounts()) {
      setIsLoaded(true);
      setOperatorSpecificModalOpen(true);
      return;
    }

    if (orderType === type) {
      let locationList = "";
      if (selectedLocation === "1") {
        let length = locations.length;
        let iteration = 1;
        for (iteration; iteration < length; iteration++) {
          locationList += locations[iteration].id + ",";
        }
        locationList = locationList.slice(0, -1);
      } else {
        locationList = selectedLocation;
      }

      const [err, res] = await to(
        getCarts(pagination, orderIdSearchQuery, getStatusList(), locationList)
      );

      setIsLoaded(true);
      if (err) {
        Sentry.captureException(err);
        setResponseValue({
          ...responseValue,
          isMessageOpen: true,
          isSuccess: false,
          message: `Error while getting car ${err}`,
        });
        return console.error("[Sales]. Error while fetching data. ", err);
      }
      if (res) {
        const {
          shoppingCart = [],
          meta: { totalItems = 0 },
        } = res.data;
        const newTotalPages = Math.ceil(totalItems / limit);
        const newCurrentPage = Math.min(page, newTotalPages);
        setSalesTotal(totalItems);
        setSales(shoppingCart);
        setResetPaginationProp(false);
        if (newCurrentPage !== page) {
          setPagination(PAGINATION_INITIAL_STATE);
          setResetPaginationProp(true);
        }
      }
    } else {
      setSales([]);

      let deviceList = "";
      if (
        loyaltyProgram === YOKE_LOYALTY_PROGRAM &&
        orderType === ORDER_TYPE_ORDER
      ) {
        if (creditCardChecked)
          deviceList += `${EPaymentMethod.CREDIT_CARD},${EPaymentMethod.CARD_PRESENT},`;
        if (loyaltyChecked) deviceList += `${EPaymentMethod.LOYALTY},`;
        if (yokeCashChecked) deviceList += `${EPaymentMethod.YOKE_CASH},`;
        if (tabChecked) deviceList += `${EPaymentMethod.TAB},`;
      } else if (
        orderType === ORDER_TYPE_VALUEADD &&
        loyaltyProgram !== GLOBAL_CONNECT
      ) {
        if (cashLoadedChecked) deviceList += `${EPaymentMethod.CASH},`;
        if (creditCardChecked)
          deviceList += `${EPaymentMethod.CREDIT_CARD},${EPaymentMethod.CARD_PRESENT},`;
      } else if (
        orderType === ORDER_TYPE_VALUEADD &&
        loyaltyProgram === GLOBAL_CONNECT
      ) {
        if (cashLoadedChecked) deviceList += `${EPaymentMethod.CASH},`;
        if (creditCardChecked)
          deviceList += `${EPaymentMethod.CREDIT_CARD},${EPaymentMethod.CARD_PRESENT},`;
        if (globalConnectCardChecked)
          deviceList += `${EPaymentMethod.GLOBAL_CONNECT_CARD},`;
      } else {
        if (creditCardChecked)
          deviceList += `${EPaymentMethod.CREDIT_CARD},${EPaymentMethod.CARD_PRESENT},`;
        if (globalConnectCardChecked)
          deviceList += `${EPaymentMethod.GLOBAL_CONNECT_CARD},`;
      }
      const isCreditCardGcUnchecked =
        !creditCardChecked && !globalConnectCardChecked;
      const IsYkCashLoyaltyCcYtUnchecked =
        !yokeCashChecked &&
        !loyaltyChecked &&
        !creditCardChecked &&
        !tabChecked;
      const isCashCcGcUnchecked =
        loyaltyProgram === GLOBAL_CONNECT &&
        !cashLoadedChecked &&
        !creditCardChecked &&
        !globalConnectCardChecked;
      const isCashCcUnChecked =
        loyaltyProgram !== GLOBAL_CONNECT &&
        !cashLoadedChecked &&
        !creditCardChecked;

      function paymentMethods() {
        if (
          isCreditCardGcUnchecked ||
          IsYkCashLoyaltyCcYtUnchecked ||
          isCashCcUnChecked ||
          isCashCcGcUnchecked
        ) {
          return "none";
        } else {
          return deviceList;
        }
      }
      setSelectedPaymentMethods(paymentMethods());
      const [err, res] = await to(
        getLedger(orderType, pagination, null, {
          orderId: orderIdSearchQuery,
          lastFour: lastFourSearchQuery,
          deviceList: paymentMethods(),
        })
      );

      setIsLoaded(true);
      if (err) {
        if (err.message === CANCEL_TOKEN) return;
        Sentry.captureException(err);
        setResponseValue({
          ...responseValue,
          isMessageOpen: true,
          isSuccess: false,
          message: `Error while getting sales ${err}`,
        });
        return console.error("[Sales]. Error while fetching data. ", err);
      }
      const { count, rows } = res.data.orders;

      switch (orderType) {
        case "order": {
          storeLedgers(rows);
          break;
        }
        case "valueAdd": {
          storeValueAdd(rows);
          break;
        }
        case "tabPayment": {
          storeTabPayment(rows);
          break;
        }
        default: {
          storeLedgers([]);
        }
      }

      setSalesTotal(count);
      setSales(rows);
    }
  }

  /**
   * Expand row
   * @param row
   */
  function onExpandRow(row) {
    const updatedSales = sales.map((sale) => {
      if (sale.id === row.id)
        return {
          ...sale,
          isExpanded: !sale.isExpanded,
        };

      return sale;
    });

    setSales(updatedSales);
  }

  function getPaymentMethods(payment) {
    let paymentString = "";
    payment.forEach((ele, index) => {
      paymentString =
        paymentString + (index > 0 ? " | " : "") + paymentMethods[ele.method];
    });
    return paymentString;
  }

  function handleExport(docType) {
    if (orderType === type) {
      handleCartsExport(docType);
      return;
    }

    // check there is data to download or not
    if (sales.length === 0) return;

    if (docType === reportFormats.PDF) {
      const exportRows = sales.map((ele) => {
        const {
          order: { refunds = [] },
        } = ele;
        const refundStatus = refunds.find((v) => v.success === true);
        return [
          format(new Date(ele.statusDate), "MM/dd/yyyy - hh:mm aa"),
          ele.order.prettyId ? ele.order.prettyId.split(":")[0] : ele.id ?? "",
          ele.location.name ? ele.location.name : "",
          !ele.user ? "N/A" : `${ele.user.firstName} ${ele.user.lastName}`,
          ele.DeviceTypeStart
            ? `${iphoneNames[ele.DeviceTypeStart] ?? ele.DeviceTypeStart}  `
            : "" + ele.DeviceTypeEnd
            ? iphoneNames[ele.DeviceTypeEnd] ?? ele.DeviceTypeEnd
            : "",
          getPaymentMethods(ele.order.payment),
          `$ ${ele.total}`,
          refundStatus?.success == true ? "Refunded" : ele.status,
        ];
      });

      const unit = "pt";
      const size = "A4";
      const orientation = "portrait";
      const marginLeft = 30;
      const doc = new jsPDF(orientation, unit, size);
      doc.setFontSize(15);

      const title = `${orderType === "order" ? "Sales" : "Loads"}`;
      const headers = [
        [
          "Date",
          "Order No",
          "Location",
          "Customer",
          "Device",
          "Payment Method",
          "Amount",
          "Status",
        ],
      ];

      let content = {
        startY: 50,
        head: headers,
        body: exportRows,
        styles: { overflow: "linebreak", fontSize: 8 },
      };

      doc.text(title, marginLeft, 30);
      doc.autoTable(content);
      doc.save(generateFileNameForReport(docType, reportTitlePrefix));
    } else {
      getSalesReports(docType, selectedPaymentMethods, orderType)
        .then((response) => {
          triggerReportDownload(response, docType, reportTitlePrefix);
        })
        .catch((err) => {
          Sentry.captureException(err);
          setResponseValue({
            ...responseValue,
            isMessageOpen: true,
            isSuccess: false,
            message: `We had a problem downloading Sales Data`,
          });
          console.error("An error occurred:", err);
        });
    }
  }

  function handleCartsExport(type) {
    if (sales.length === 0) return;

    if (type === reportFormats.PDF) {
      const exportRows = sales.map((ele) => [
        ele.id ?? "-",
        ele.locationName ?? "-",
        ele.userName ?? "-",
        "$ " + (ele.cart?.totals?.total || "0.00"),
        ele.status ?? "-",
      ]);

      const unit = "pt";
      const size = "A4";
      const orientation = "portrait";
      const marginLeft = 30;
      const doc = new jsPDF(orientation, unit, size);
      doc.setFontSize(15);

      const title = "Carts";
      const headers = [
        [
          "Cart Id",
          "Location Name",
          "User",
          "Total Amount",
          "Status",
          "First Open",
          "Last Updated",
        ],
      ];

      let content = {
        startY: 50,
        head: headers,
        body: exportRows,
        styles: { overflow: "linebreak", fontSize: 8 },
      };

      doc.text(title, marginLeft, 30);
      doc.autoTable(content);
      doc.save(generateFileNameForReport(type, CARTS_REPORT_NAME_PREFIX));
    } else {
      getCartReports(type, getStatusList())
        .then((response) => {
          triggerReportDownload(response, type, CARTS_REPORT_NAME_PREFIX);
        })
        .catch((err) => {
          Sentry.captureException(err);
          setResponseValue({
            ...responseValue,
            isMessageOpen: true,
            isSuccess: false,
            message: `We had a problem downloading Carts Data`,
          });
          console.error("An error occurred:", err);
        });
    }
  }

  /**
   * Set store property on edit item click
   */
  function handleRowEditClick(row) {
    const { id } = row;
    let type = "ledgers";

    if (orderType === "valueAdd") type = "valueAdds";
    else if (orderType === "tabPayment") type = "tabPayments";

    selectSaleOrder({ id, type });
    push({ pathname: `/sales/${row.id}` });
  }

  const handelBulkRefund = () => {
    let uppatedSales = [];
    if (selectedRows?.length > 0) {
      selectedRows.forEach((selectedRowId) => {
        const sale = sales.filter((o) => o.id === selectedRowId)[0];
        uppatedSales.push({
          ...sale,
          id: selectedRowId,
        });
      });
      setBulkRefundModalOpen(true);
    } else {
      setResponseValue({
        ...responseValue,
        isMessageOpen: true,
        isSuccess: false,
        message: `please select the transaction for which refund need to be initiated`,
      });
    }
    setUpdatedSalesData(uppatedSales);
    return uppatedSales;
  };

  /**
   * Issue refund
   * @param rowId
   */
  function onIssueRefund(rowId) {
    const sale = sales.filter((o) => o.id == rowId)[0];
    if (!sale.order.transactions) {
      setResponseValue({
        ...responseValue,
        isMessageOpen: true,
        isSuccess: false,
        message: `We had a problem loading the transactions for this order`,
      });
    } else {
      setRefundModalOrderId(rowId);
      setRefundModalPrettyId(sale.order.prettyId);
      setRefundModalTransactions(sale.order.transactions);
      setRefundModalOpen(true);
      setRefundModalRefunds(sale.order.refunds);
    }
  }

  /**
   * Resend receipt
   * @param rowId
   */

  function onResendReceipt(row) {
    setResendModalOrder(row);
    setResendModalOpen(true);
  }

  async function handleResendReceipt(row, emailId) {
    const [err, refundResponse] = await to(receipt(row.id, emailId));
    if (err) {
      if (err.response) {
        setResponseValue({
          ...responseValue,
          isMessageOpen: true,
          isSuccess: false,
          isServerError: false,
          message: `${err.response.data.message}`,
        });
        return;
      }

      Sentry.captureException(err);
      setResponseValue({
        ...responseValue,
        isMessageOpen: true,
        message: `Failed resending receipt Order #${row.order?.prettyId.split(
          ":"
        )[0] ?? row.id} - ${err.message}`,
      });
    } else {
      setResponseValue({
        ...responseValue,
        isMessageOpen: true,
        isSuccess: true,
        message: `Successfully resent receipt for Order #${row.order?.prettyId.split(
          ":"
        )[0] ?? row.id} on mail - ${emailId}`,
      });
    }
  }

  /**
   * Mark row as selected (checkbox)
   * @param id
   */
  function selectRow(id) {
    if (selectedRows.includes(id))
      setSelectedRows(selectedRows.filter((item) => item !== id));
    else setSelectedRows([...selectedRows, id]);
  }

  function hasFalseStatus(array) {
    return array?.some(obj => obj.success === false);
  }
  

  /**
   * Select all rows
   */
  function masterToggle() {
    if (selectedRows.length) {
      limitedArray.reset()
      setSelectedRows([]);

    }
    else {
      sales.filter((item) => {
        if ((item?.status === "Paid" && hasFalseStatus(item?.order?.refunds))) {
          limitedArray.push(item?.id);
        }
        else if(item?.status === "Paid" && !item?.order?.refunds){
          limitedArray.push(item?.id);
        }
      });
      setSelectedRows(limitedArray.getValues());
    }
  }

  /**
   * Update pagination data on table pagination component change
   */
  function onPaginationChange(newPagination) {
    setPagination(newPagination);
  }

  function handleCheckBoxClick(type) {
    switch (type) {
      case "creditCard":
        setCreditCardChecked(!creditCardChecked);
        break;
      case "yokeCash":
        setYokeCashChecked(!yokeCashChecked);
        break;
      case "yokeTab":
        setTabChecked(!tabChecked);
        break;
      case "loyalty":
        setLoyaltyChecked(!loyaltyChecked);
        break;
      case "cash":
        setCashLoadedChecked(!cashLoadedChecked);
        break;
      case "globalConnectCard":
        setGlobalConnectCardChecked(!globalConnectCardChecked);
        break;
      default:
        console.log("something went wrong!");
    }
    if (page !== 1) {
      setPagination({ page: 1, limit: limit });
    }
  }

  return (
    <>
      <ExportAndCustomFilters
        newFilterTag={reportFormats.XLS}
        onExport={handleExport}
      />

      <div id="ledger_sales_search_wrapper" className={styles.searchWrapper}>
        <div id="ledger_sales_header_wrapper" className={styles.salesHeader}>
          <div
            id="ledger_sales_search_box_wrapper_0"
            className={styles.searchBoxWrapper}
          >
            <Search
              id="sales_orderId"
              query={orderIdSearchQuery}
              labelFontSize={orderType !== type && fontSize}
              label={
                orderType === type
                  ? "Location Name or User or Amount"
                  : "First Name, Last Name, Email, Balance, Points"
              }
              onChange={(e) => {
                const target = e.target;
                if (searchTimeout) {
                  clearTimeout(searchTimeout);
                  searchTimeout = null;
                }
                searchTimeout = setTimeout(() => {
                  setOrderIdSearchQuery(target.value.toUpperCase());
                }, 400);
              }}
            />
          </div>
          {creditCardChecked && orderType !== type ? (
            <div
              id="ledger_sales_search_box_wrapper_1"
              className={styles.searchBoxWrapper}
            >
              <Search
                id="sales_lastFour"
                query={lastFourSearchQuery}
                labelFontSize={orderType !== type && fontSize}
                label={"Last Four"}
                onChange={(e) => {
                  const target = e.target;
                  if (searchTimeout) {
                    clearTimeout(searchTimeout);
                    searchTimeout = null;
                  }
                  searchTimeout = setTimeout(() => {
                    setLastFourSearchQuery(target.value);
                  }, 400);
                }}
              />
            </div>
          ) : null}
          <div className={styles.checkBoxWrapper}>
            {orderType !== type ? (
              <FormControlLabel
                id="ledger_sales_form_control_label_0"
                style={{ marginLeft: 0 }}
                control={
                  <Checkbox
                    id="ledger_sales_form_control_check_box_0"
                    checked={creditCardChecked}
                    onChange={(e) => handleCheckBoxClick("creditCard")}
                    defaultChecked
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                  />
                }
                label={
                  <Typography
                    id="ledger_sales_credit_card_typograpghy"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    {"Credit Card"}
                  </Typography>
                }
              />
            ) : null}
            {loyaltyProgram == "globalConnect" && orderType !== type ? (
              <>
                <FormControlLabel
                  id="ledger_sales_form_control_label_1"
                  style={{ marginLeft: 0 }}
                  control={
                    <Checkbox
                      id="ledger_sales_form_control_check_box_1"
                      checked={globalConnectCardChecked}
                      onChange={(e) => handleCheckBoxClick("globalConnectCard")}
                      defaultChecked
                      sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                    />
                  }
                  label={
                    <Typography
                      id="ledger_sales_global_connect_card_typograpghy"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      {"Global Connect Card"}
                    </Typography>
                  }
                />
              </>
            ) : orderType === "order" ? (
              <>
                <FormControlLabel
                  id="ledger_sales_form_control_label_1"
                  style={{ marginLeft: 0 }}
                  control={
                    <Checkbox
                      id="ledger_sales_form_control_check_box_1"
                      checked={yokeCashChecked}
                      onChange={(e) => handleCheckBoxClick("yokeCash")}
                      defaultChecked
                      sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                    />
                  }
                  label={
                    <Typography
                      id="ledger_sales_yoke_cash_typograpghy"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      {CANTALOUPE_CASH}
                    </Typography>
                  }
                />
                <FormControlLabel
                  style={{ marginLeft: 0 }}
                  id="ledger_sales_form_control_label_2"
                  control={
                    <Checkbox
                      id="ledger_sales_form_control_tabChecked_check_box"
                      checked={tabChecked}
                      onChange={(e) => handleCheckBoxClick("yokeTab")}
                      defaultChecked
                      sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                    />
                  }
                  label={
                    <Typography
                      id="ledger_sales_yoke_tab_typograpghy"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      {"Yoke Tab"}
                    </Typography>
                  }
                />
                <FormControlLabel
                  id="ledger_sales_form_control_label_3"
                  style={{ marginLeft: 0 }}
                  control={
                    <Checkbox
                      id="ledger_sales_form_control_loyaltyChecked_check_box"
                      checked={loyaltyChecked}
                      onChange={(e) => handleCheckBoxClick("loyalty")}
                      defaultChecked
                      sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                    />
                  }
                  label={
                    <Typography
                      id="ledger_sales_yoke_loyalty_typograpghy"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      {"Loyalty"}
                    </Typography>
                  }
                />
              </>
            ) : null}
            {orderType === "valueAdd" ? (
              <FormControlLabel
                id="ledger_sales_form_control_label_4"
                control={
                  <Checkbox
                    id="ledger_sales_form_control_cashLoadedChecked_check_box"
                    checked={cashLoadedChecked}
                    onChange={(e) => handleCheckBoxClick("cash")}
                    defaultChecked
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                  />
                }
                label={
                  <Typography
                    id="ledger_sales_yoke_cash_typograpghy"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    {"Cash"}
                  </Typography>
                }
              />
            ) : null}
            {orderType === type ? (
              <CartsStatusOptions
                cartsStatus={cartsStatus}
                setCartsStatus={setCartsStatus}
              />
            ) : null}
          </div>
        </div>
      </div>
      {!isLoaded && <LoadingSpinner />}
      {isLoaded && (
        <>
          {getScope() === ROLE.SUPERADMIN && (
            <div
              style={{
                padding: "1rem 0.5rem",
                display: "flex",
                justifyContent: "end",
              }}
            >
              <Button
                size="medium"
                variant="outlined"
                color="primary"
                onClick={handelBulkRefund}
              >
                Issue Bulk Refund
              </Button>
            </div>
          )}
          <div
            id="ledger_sales_yoke_cash_typograpghy"
            className={styles.wrapper}
          >
            {orderType === type ? (
              <CartTable
                rows={sales}
                salesTotal={salesTotal}
                pagination={pagination}
                onPaginationChange={onPaginationChange}
                resetPaginationProp={resetPaginationProp}
                onExpandRow={onExpandRow}
              />
            ) : (
              <LedgerTable
                isLoads={orderType === "order"}
                hideColumns={
                  getScope() !== ROLE.SUPERADMIN ? ["orderNumber"] : []
                }
                onIssueRefund={onIssueRefund}
                onResendReceipt={onResendReceipt}
                onExpandRow={onExpandRow}
                onPaginationChange={onPaginationChange}
                onEditRowClick={handleRowEditClick}
                onMasterToggle={() => masterToggle()}
                onSelectRow={(id) => selectRow(id)}
                rows={sales}
                selectedRows={selectedRows}
                pagination={pagination}
                salesTotal={salesTotal}
              />
            )}
          </div>
          <ResponseModal
            isSuccess={responseValue?.isSuccess}
            isServerError={responseValue?.isServerError}
            message={responseValue?.message}
            open={responseValue?.isMessageOpen}
            onClose={(value) =>
              setResponseValue({ ...responseValue, isMessageOpen: value })
            }
          />
          {getScope() === ROLE.SUPERADMIN && (
            <BulkRefundModal
              reset={()=>limitedArray.reset()}
              setIsRefunded={setIsRefunded}
              open={bulkRefundModalOpen}
              selectedTransactionData={updatedSalesDate}
              onClose={(value) => {
                setBulkRefundModalOpen(value);
                if (isRefunded) {
                  setIsRefunded(false);
                }
              }}
              openResponse={(isSuccess, isServerError, message) => {
                setResponseValue({
                  ...responseValue,
                  isMessageOpen: true,
                  isSuccess: isSuccess,
                  isServerError: isServerError,
                  message: message,
                });
              }}
            />
          )}
          <RefundModal
            setIsRefunded={setIsRefunded}
            open={refundModalOpen}
            transactions={refundModalTransactions}
            orderId={refundModalOrderId}
            prettyId={refundModalPrettyId}
            onClose={(value) => {
              setRefundModalOpen(value);
              if (isRefunded) {
                setIsRefunded(false);
              }
            }}
            openResponse={(isSuccess, isServerError, message) => {
              setResponseValue({
                ...responseValue,
                isMessageOpen: true,
                isSuccess: isSuccess,
                isServerError: isServerError,
                message: message,
              });
            }}
          />
          {Boolean(resendModalOpen) && (
            <ResendModal
              open={resendModalOpen}
              onClose={() => setResendModalOpen(false)}
              handleResendReceipt={handleResendReceipt}
              row={resendModalOrder}
            />
          )}
        </>
      )}
      {operatorSpecificModalOpen && (
        <OperatorSpecificModal
          open={operatorSpecificModalOpen}
          onClose={(value) => {
            setIsLoaded(false);
            setOperatorSpecificModalOpen(value);
          }}
        />
      )}
    </>
  );
}

export default connect(() => ({}), {
  storeLedgers: fromActions.ledgerActions.storeLedgers,
  storeValueAdd: fromActions.ledgerActions.storeValueAdd,
  storeTabPayment: fromActions.ledgerActions.storeTabPayment,
  selectSaleOrder: fromActions.ledgerActions.selectSaleOrder,
})(Sales);

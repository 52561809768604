import { Card, CardContent, CardHeader } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React from "react";
import { CardBody } from "reactstrap";

const PortletLoader = () => {
  return (
    <Card sx={{ maxWidth: 345, m: 2 }}>
      <CardHeader
        style={{backgroundColor:"#F5F7F8"}}
        title={
          <Skeleton
            variant="text"
            width={"60%"}
            height={40}
            sx={{ fontSize: "4rem",bgcolor: "grey.900"}}
          />
        }
      />
      <CardBody>
        <Skeleton variant="text" width={"60%"} sx={{ fontSize: "4rem" }} />
        <Skeleton variant="text" sx={{ fontSize: "2rem" }} />
        <Skeleton
          sx={{ bgcolor: "grey.900" }}
          variant="rectangular"
          width={"100%"}
          height={"100%"}
        >
          <svg
            fill="#d9d9d9"
            viewBox="0 0 1024.00 1024.00"
            xmlns="http://www.w3.org/2000/svg"
            class="icon"
            style={{ height: 150, width: "100%" }}
          >
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g
              id="SVGRepo_tracerCarrier"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke="#CCCCCC"
              stroke-width="2.048"
            ></g>
            <g id="SVGRepo_iconCarrier">
              {" "}
              <path d="M888 792H200V168c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v688c0 4.4 3.6 8 8 8h752c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zm-616-64h536c4.4 0 8-3.6 8-8V284c0-7.2-8.7-10.7-13.7-5.7L592 488.6l-125.4-124a8.03 8.03 0 0 0-11.3 0l-189 189.6a7.87 7.87 0 0 0-2.3 5.6V720c0 4.4 3.6 8 8 8z"></path>{" "}
            </g>
          </svg>
        </Skeleton>
      </CardBody>
    </Card>
  );
};

export default PortletLoader;

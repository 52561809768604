import axios from 'axios';

import { CANCEL_TOKEN } from "app/common/constant";

import axiosInstance from './helpers/axiosInstance';

/**
 * Get list of promotions for provided locations
 */
const CancelToken = axios.CancelToken;
let getPromotionsCancel = null;
export function getPromotions(locationIds) {
  if(getPromotionsCancel){
    getPromotionsCancel(CANCEL_TOKEN);
    getPromotionsCancel = null;
    
  }
  let baseUrl = '/deals/admin';

  if (locationIds && locationIds.length) {
    baseUrl += '?';

    for (let i = 0, n = locationIds.length; i < n; ++i) {
      const locationId = locationIds[i];

      baseUrl += `${i > 0 ? '&' : ''}locations=${locationId}`;
    }
  }
  return axios.get(baseUrl, {
    cancelToken: new CancelToken(function executor(c){
      getPromotionsCancel = c;
    })
  });
}


export function getPromotion(dealId) {
  let baseUrl = `/deals/${dealId}`;

  return axios.get(baseUrl);
}

/**
 * Create new promotion
 * @argument promotion as Object
 */
export function createPromotion(promotion) {
  return axios.post('/deals/admin', promotion);
}

/**
 * Update promotion
 * @argument promationId
 * @argument updatedPromotion
 */
export function updatePromotion(promotionId, updatedPromotion) {
  return axiosInstance.patch(`/deals/admin/${promotionId}`, updatedPromotion);
}

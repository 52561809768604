const { makeStyles } = require("@material-ui/styles");

export const useStyles = makeStyles((theme) => ({
    bubbleContainer: {
        display: "flex",
        alignItems: "center",
        backgroundColor: "#FFFFFF",
        margin: "5px 3px", 
        borderRadius: "5px",
        padding: "0px 5px",
        boxShadow: "7px 8px 13px rgb(212 212 212 / 28%)",
        justifyContent: "center"
    },
    bubbleBox:{
        width: "100%",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "flex-start",
        backgroundColor: "#fffff8",
        padding: "10px",
        borderRadius: "5px",
    },
    container:{
        display:"flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        alignItems: "center",
        marginTop: "10px",
        borderRadius: "5px",
    },
    containerTitle:{
        display:"flex",
        alignItems:"center",
        margin: "5px 15px 5px 3px",
    },
}))
import React, { useState } from "react";
import {
  Button,
  CardContent,
  Divider,
  FormControl,
  InputLabel,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
  FormHelperText,
} from "@material-ui/core";
import { CloudUpload } from "@material-ui/icons";
import clsx from "clsx";
import timezones from "timezones.json";
import { countries } from "countries-list";

import { uploadW9 } from "app/services/applications";

import styles from "./basic-info.module.scss";
import { EMAIL_PATTERN, MOBILE_NUM_PATTERN, NAME_PATTERN, VALID_INPUT_ERROR } from "app/common/constant";

export default function BasicInfo(props) {
  const [w9Uploaded, setW9Uploaded] = useState(false);
  const [w9Error, setW9Error] = useState("")
  const [w9ErrorMsg, setW9ErrorMsg] = useState(false);
  const [labelText, setLabelText] = useState(false);
  const [labelText1, setLabelText1] = useState(false);

  function upload(e) {
    setW9ErrorMsg(false);
    if(e.target.files[0]?.type !== "application/pdf") {
      setW9ErrorMsg(true);
      return;
    }
    uploadW9(e.target.files[0], companyInfo.dbaName).then(res => {     
      if (res.data.success)
      {
        setW9Uploaded(true)
      } else {
        setW9Error(res.data.message)
      }
    })
    .catch(err => {     
      setW9Error(err)
      console.error('Issues with report fetching: ', err);
    });
  }

  const {
    companyInfo = {
      name: "",
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      portalUrl: "",
      supportEmail: "",
      dbaName: "",
      dbaPhone: "",
      timezone: "",
    },
    companyAddress = {
      streetLineOne: "",
      streetLineTwo: "",
      city: "",
      postcode: "",
      state: "",
      country: "",
    },
    isApplicationsView = false,
    onCompanyInfo = ()=>{},
    onCompanyAddress,
    onNextClick,
    push,
    cancelForm,
    displayButtons = true,
  } = props;

  function helperText(message){
    return(
      <>
        <FormHelperText style={{ color: "red", fontSize: "1rem" }}>{message}</FormHelperText>
      </>
    )
  }
//For Basic info validation

  function detailsValidation() {
    setLabelText(false);
    setLabelText1(false);
  
    if (!companyInfo?.firstName?.trim() || !NAME_PATTERN.test(companyInfo?.firstName?.trim())) {
      setLabelText(true);
      return false;
    }
  
    if (!companyInfo?.lastName?.trim() || !NAME_PATTERN.test(companyInfo?.lastName?.trim())) {
      setLabelText(true);
      return false;
    }
  
    if (!EMAIL_PATTERN.test(companyInfo?.email?.trim())) {
      setLabelText1(true);
      return false;
    }
    if (!MOBILE_NUM_PATTERN.test(companyInfo?.phone?.trim())) {
      setLabelText(true);
      return false;
    }
  
    if (!companyInfo?.portalUrl?.trim()) {
      setLabelText(true);
      return false;
    }
    if (!companyAddress?.streetLineOne?.trim()) {
      setLabelText(true);
      return false;
    } 
    return true;
  }
  

  function nextPage(){
    setLabelText(false);
    setLabelText1(false);
    return detailsValidation() && onNextClick();
   
  }

 function editBasicInfo (){
  return detailsValidation() && onNextClick();
  }


  return (
    <>
      <CardContent>
        <Typography variant="h5">Company Information</Typography>
        <p>
          This information is pertaining to the entire account (e.g. company
          information)
        </p>
        <Divider />

        <div className={styles.col8}>
          <div className={styles.col6}>
            <div className="d-flex" style={{ margin: "0 -10px" }}>
              <div className="col-6">
                <TextField
                  margin="normal"
                  id="basicInfo_firstName"
                  label="First Name"
                  variant="outlined"
                  value={companyInfo.firstName || ""}
                  onChange={(e) => onCompanyInfo({ firstName: e.target.value })}
                  helperText={(labelText && (!companyInfo?.firstName?.trim() || !NAME_PATTERN.test(companyInfo?.firstName?.trim()))) ? helperText(VALID_INPUT_ERROR) : ''}
                  fullWidth
                />
              </div>
              <div className="col-6">
                <TextField
                  margin="normal"
                  id="basicInfo_lastName"
                  label="Last Name"
                  variant="outlined"
                  value={companyInfo.lastName || ""}
                  onChange={(e) => onCompanyInfo({ lastName: e.target.value })}
                  helperText={(labelText && (!companyInfo?.lastName?.trim() || !NAME_PATTERN.test(companyInfo?.lastName?.trim()))) ? helperText(VALID_INPUT_ERROR) : ''}

                  fullWidth
                />
              </div>
            </div>
          </div>
          <div className={styles.col6}>
            <TextField
              margin="normal"
              id="basicInfo_contactEmail"
              error={labelText1}
              label="Contact Email"
              variant="outlined"
              value={companyInfo.email || ""}
              onChange={(e) => onCompanyInfo({ email: e.target.value })}
              helperText={(labelText && (!EMAIL_PATTERN.test(companyInfo?.email?.trim()))) ? helperText(VALID_INPUT_ERROR) : ''}
              fullWidth
            />
            <div style={{fontSize: 11.25, color: "red", marginLeft: 15}}>
            {labelText1 && "Enter valid Email"}
            </div>
          </div>
          <div className={styles.col6}>
            <TextField
              margin="normal"
              id="basicInfo_phone"
              label="Phone Number"
              variant="outlined"
              value={companyInfo.phone || ""}
              onChange={(e) => onCompanyInfo({ phone: e.target.value })}
              helperText={(labelText && (!MOBILE_NUM_PATTERN.test(companyInfo?.phone?.trim()))) ? helperText(VALID_INPUT_ERROR) : ''}

 
              fullWidth
            />
          </div>
          <div className={styles.col6}>
            <TextField
              margin="normal"
              id="basicInfo_portalUrl"
              label="Portal URL"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">https://</InputAdornment>
                ),
              }}
              value={companyInfo.portalUrl || ""}
              onChange={(e) => onCompanyInfo({ portalUrl: e.target.value })}
              helperText={!(companyInfo.portalUrl) && labelText && helperText()}
              fullWidth
            />
          </div>
          <div className={styles.col6}>
            <TextField
              margin="normal"
              id="basicInfo_supportEmail"
              label="Support Email"
              variant="outlined"
              value={companyInfo.supportEmail || ""}
              onChange={(e) => onCompanyInfo({ supportEmail: e.target.value })}
              fullWidth
            />
          </div>
          <div className={styles.col6}>
            <TextField
              margin="normal"
              id="basicInfo_companyName"
              label="Company Name"
              variant="outlined"
              value={companyInfo.name || ""}
              onChange={(e) => onCompanyInfo({ name: e.target.value })}
              helperText={!(companyInfo.name) && labelText && helperText()}
              fullWidth
            />
          </div>
          <div className={styles.col6}>
            <TextField
              margin="normal"
              id="basicInfo_dbaName"
              label="DBA Name (Optional)"
              variant="outlined"
              value={companyInfo.dbaName || ""}
              onChange={(e) => onCompanyInfo({ dbaName: e.target.value })}
              fullWidth
            />
          </div>
          <div className={styles.col6}>
            <TextField
              margin="normal"
              id="basicInfo_dbaPhone"
              label="DBA Phone (Optional)"
              variant="outlined"
              value={companyInfo.dbaPhone || ""}
              onChange={(e) => onCompanyInfo({ dbaPhone: e.target.value })}
              fullWidth
            />
          </div>
          <div className={clsx(styles.inputWrapper, styles.col6)}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel htmlFor="timezone">Timezone</InputLabel>
              <Select
                label="Timezone"
                labelId="timezone"
                id="basicInfo_timezone"
                value={companyInfo.timezone || ""}
                onChange={(e) => onCompanyInfo({ timezone: e.target.value })}
              >
                {timezones.map((timezone) => {
                  const { offset, text } = timezone;
                  return (
                    <MenuItem key={text} value={text}>
                      {text}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <div style={{marginLeft: 30}}>
            {!(companyInfo.timezone) && labelText && helperText()}
            </div>
          </div>
        </div>
      </CardContent>
      <CardContent>
        <Typography variant="h5">Company Address</Typography>
        <Divider />
        <div className={styles.col8}>
          <div className={styles.col6}>
            <TextField
              margin="normal"
              id="basicInfo_streetLine1"
              label="Street Line 1"
              variant="outlined"
              value={companyAddress.streetLineOne || ""}
              onChange={(e) =>
                onCompanyAddress({ streetLineOne: e.target.value })
              }
              helperText={!(companyAddress.streetLineOne) && labelText && helperText()}
              fullWidth
            />
          </div>
          <div className={styles.col6}>
            <TextField
              margin="normal"
              id="basicInfo_streetLine2"
              label="Street Line 2"
              variant="outlined"
              placeholder="Optional"
              value={companyAddress.streetLineTwo || ""}
              onChange={(e) =>
                onCompanyAddress({ streetLineTwo: e.target.value })
              }
              fullWidth
            />
          </div>
          <div className={styles.col6}>
            <TextField
              margin="normal"
              id="basicInfo_city"
              label="City"
              variant="outlined"
              value={companyAddress.city || ""}
              onChange={(e) => onCompanyAddress({ city: e.target.value })}
              helperText={!(companyAddress.city) && labelText && helperText()}
              fullWidth
            />
          </div>
          <div className={styles.col6}>
            <TextField
              margin="normal"
              id="basicInfo_postcode"
              label="Postcode"
              variant="outlined"
              value={companyAddress.postcode || ""}
              onChange={(e) => onCompanyAddress({ postcode: e.target.value })}
              helperText={!(companyAddress.postcode) && labelText && helperText()}
              fullWidth
            />
          </div>
          <div className={styles.col6}>
            <TextField
              margin="normal"
              id="basicInfo_state"
              label="State"
              variant="outlined"
              value={companyAddress.state || ""}
              onChange={(e) => onCompanyAddress({ state: e.target.value })}
              helperText={!(companyAddress.state) && labelText && helperText()}
              fullWidth
            />
          </div>
          <div className={styles.col6}>
            <FormControl fullWidth variant="outlined" margin="normal">
              <InputLabel htmlFor="country">Country</InputLabel>
              <Select
                label="Country"
                labelId="country"
                id="basicInfo_country"
                value={companyAddress.country || ""}
                onChange={(e) => onCompanyAddress({ country: e.target.value })}
              >
                {Object.keys(countries).map((countryKey) => {
                  const { name } = countries[countryKey];
                  return (
                    <MenuItem key={countryKey} value={name}>
                      {name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <div style={{marginLeft: 30}}>
               {!(companyAddress.country) && labelText && helperText()}
            </div>
          </div>
        </div>
      </CardContent>
      <CardContent>
        <Typography variant="h5">W9</Typography>
        <Divider />
        <div className="col-12">
          <Typography variant="h6">Please upload a valid W9</Typography>
          {w9Uploaded ? 'Uploaded!' :
          <Button
              className={styles.button}
              startIcon={<CloudUpload/>}
              size="small"
              variant="outlined"
              color="primary"
              component="label"
            >
              Upload W9
              <input
                type="file"
                style={{ display: 'none' }}
                accept="application/pdf"
                onChange={upload}
              />
            </Button>}
            {w9Error}
            {w9ErrorMsg &&  <FormHelperText style={{color: "red", fontSize: "1rem"}}>* choose pdf file to upload</FormHelperText>}
        </div>
      </CardContent>
      {displayButtons && <div className="d-flex justify-content-center">
        {isApplicationsView ? (
          <Button
            size="large"
            variant="contained"
            color="primary"
            disableElevation
            onClick={() => editBasicInfo()}
          >
            Next
          </Button>
        ) : (
          <>
            <Button
              size="large"
              variant="contained"
              color="primary"
              disableElevation
              onClick={() => nextPage()}
            >
              Next
            </Button>

            <Button
              size="large"
              variant="contained"
              color="primary"
              style={{ marginLeft: "20px" }}
              disableElevation
              onClick={() => (push({ pathname: "/auth/login" }), cancelForm())}
            >
              Cancel
            </Button>
          </>
        )}
      </div>}
    </>
  );
}

import React, { useEffect, useState } from 'react';
import to from 'await-to-js';
import csvDownload from 'json-to-csv-export';
import 'jspdf-autotable';
import { jsPDF } from "jspdf";
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import * as Sentry from "@sentry/react";

import LoadingSpinner from 'app/partials/content/LoadingSpinner';
import { accessPermission } from 'app/common/helperFunction';

import LoyaltyTable from '../../partials/content/Loyalty/LoyaltyTable';
import ExportAndCustomFilters from '../../partials/content/ExportAndCustomFilters';
import { getLoyalties } from '../../services/loyalty';
import { hasMultipleAccounts } from '../../services/helpers/account-id';
import ResponseModal from '../Helpers/ResponseModal';
import OperatorSpecificModal from '../Helpers/OperatorSpecificModal';

import styles from '../../../_metronic/_assets/sass/pages/loyalty.module.scss';
import { useCustomResponse } from 'app/hooks/useCustomResponse';
import { reportFormats } from 'app/services/reports';

export default function Loyalty(props) {
  const {push} = useHistory();
  const { filter: {} } = props;
  const [loyalty, setLoyalty] = useState([]);
  const [totalLocations, setTotalLocations] = useState(0);
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10
  });
  const [isLoaded, setIsLoaded] = useState(false);
  const [operatorSpecificModalOpen, setOperatorSpecificModalOpen] = useState(false);
  const [responseValue, setResponseValue] = useCustomResponse()

  useEffect(() => {
    if(!accessPermission("LoyaltyAll")){
      push({pathname: "/dashboard"});
    }
  }, []);

  useEffect(() => {
    getLoyaltiesList();

  }, [pagination]);



  /**
   * Get all loyalty
   * @returns {Promise<void>}
   */
  async function getLoyaltiesList() {
    if(hasMultipleAccounts()){
      setOperatorSpecificModalOpen(true);
      return
    }

    const [err, res] = await to(getLoyalties(pagination));
      
    setIsLoaded(true);
    if (err){
      Sentry.captureException(err);
      setResponseValue({...responseValue,isMessageOpen: true, isSuccess:false,message:`Error while getting loyalty info ${err}`})
      return console.error('[Loyalty]. Error while fetching data.');

    }

    setLoyalty(res.data.locations.rows)
    setTotalLocations(res.data.meta.totalItems);
 
  }

  
  async function handleExport(type) {

    const [err, res] = await to(
      getLoyalties({
        page: 1,
        limit: totalLocations,
      })
    );

    if(err)
    return console.error("[Sales]. Error while fetching data. ", err);
    
    const { rows } = res.data.locations;

    if(type === 'pdf'){

      const exportRows = rows.map(ele => [
        ele.name ?? "",
        ele.pointsIssued ?? 0,
        ele.redeems[0].supplyPrice ? `$ ${ele.redeems[0].supplyPrice}` : `$ 0` ,
        ele.redeems[0].retailPrice ? `$ ${ele.redeems[0].retailPrice}` : `$ 0` ,
        ele.redeems[0].totalProducts ?? 0,
        ele.loyatlyEnabled? 'On':'Off',
       ]);

        const unit = "pt";
        const size = "A4";
        const orientation = "portrait";
        const marginLeft = 30;
        const doc = new jsPDF(orientation,unit, size);
        doc.setFontSize(15);

        const title = "Loyalty";
        const headers = [["Location Name", "Points Issued", "Supply Cost Redeemed", "Retail Value Redeemed", "Products Issued", "Status"]];

        let content = {
          startY: 50,
          head: headers,
          body: exportRows,
          styles: {overflow: 'linebreak', fontSize: 8},
        };

        doc.text(title, marginLeft, 30);
        doc.autoTable(content);
        doc.save(`Loyality - ${format(new Date(), 'MM/dd/yyyy')}.${type}`);

    } else if (type === 'csv') {
 
        const exportRows = [];
        rows.forEach(ele => {
          let obj = {
           'Location Name' : ele.name ? ele.name : "",
           'Points Issued' : ele.pointsIssued ? ele.pointsIssued : "0",
           'Supply Cost Redeemed' : ele.redeems[0].supplyPrice ? `$ ${ele.redeems[0].supplyPrice}` : `$ 0` ,
           'Retail Value Redeemed': ele.redeems[0].retailPrice ? `$ ${ele.redeems[0].retailPrice}` : `$ 0`,
           'Product Issued' : ele.redeems[0].totalProducts ? ele.redeems[0].totalProducts : '0',
           'Status' : ele.loyatlyEnabled ? 'On':'Off',
          }
          exportRows.push(obj);
        })
        
      csvDownload(exportRows, `Loyalty - ${format(new Date(), 'MM/dd/yyyy')}.${type}`);
    }
  }

  return (
    <>
      <ExportAndCustomFilters onExport ={handleExport} newFilterTag={reportFormats.PDF}/>

      {!isLoaded ? (
        <LoadingSpinner/>
      ) : (
      <>
        <div className={styles.wrapper}>
          <LoyaltyTable
            rows={loyalty}
            totalLocations={totalLocations}
            pagination={pagination}
            onPaginationChange={value => setPagination(value)}
            />
        </div>
        <ResponseModal
        isSuccess={responseValue?.isSuccess}
        message={responseValue?.message}
        open={responseValue?.isMessageOpen}
        onClose={value => setResponseValue({...responseValue, isMessageOpen:value})}
        />
      </>)}
      {operatorSpecificModalOpen && (
        <OperatorSpecificModal
          open={operatorSpecificModalOpen}
          onClose={(value) => setOperatorSpecificModalOpen(value)}
        />
      )}
    </>
  );
}

import React, { useEffect, useState } from 'react';
import to from 'await-to-js';
import { useHistory } from 'react-router-dom';
import { jsPDF } from "jspdf";
import csvDownload from 'json-to-csv-export'
import 'jspdf-autotable'
import { format } from 'date-fns';
import * as Sentry from "@sentry/react";

import ResponseModal from 'app/components/Helpers/ResponseModal';
import styles2 from '_metronic/_assets/sass/loading-text.module.scss';
import { useCustomResponse } from 'app/hooks/useCustomResponse';
import { accessPermission } from 'app/common/helperFunction';

import ExportAndCustomFilters from '../../../partials/content/ExportAndCustomFilters';
import Search from '../../../partials/content/Customers/Search';
import LocationsTable from './components/LocationsTable/LocationsTable';
import { getLocations } from '../../../services/locations';

import styles from './locations.module.scss';

export default function Locations() {
  const { push } = useHistory();
  const [searchQuery, setSearchQuery] = useState('');
  const [locations, setLocations] = useState([]);
  const [isLoaded,setIsLoaded] =useState(false);
  const [responseValue, setResponseValue] = useCustomResponse()

  /**
   * Handle data loading on component start
   */
  useEffect(() => {

    if(!accessPermission()){
      push({pathname: "/dashboard"});
    }

    getLocationsList();
  }, []);

  /**
   * Get list of locations
   */
  async function getLocationsList() {
    const [err, res] = await to(getLocations(true, true));

    if (err){
      Sentry.captureException(err);
      setResponseValue({...responseValue, isMessageOpen: true, isSuccess:false,message:`Error while getting location list. ${err}`});     
      return console.error(err);
    }
    
    const locationsList = res.data.Locations.map(location => {
        location.isExpanded = true;
        return location;
    })

    setLocations(locationsList);
    setIsLoaded(true);
  }

  /**
   * Expand row
   * @param row
   */
  function onExpandRow(row) {
    const updatedLocations = locations.map(location => {
      if (location.id === row.id)
        return {
          ...location,
          isExpanded: !location.isExpanded
        };

      return location;
    });

    setLocations(updatedLocations);
  }

  async function handleExport(type) {
    const [err, res] = await to(getLocations(true, true));

    if(err)
    return console.error("Error while fetching Locations data:", err);
    
    const rows = res.data.Locations;

    if(type === 'pdf'){

      const exportRows = rows.map(ele => [
        ele.name? ele.name: '',
        ele.kiosks && ele.kiosks.length
                            ? '-'
                            : ( ele.connected ? 'Yes' : 'No' ),
        ele.kiosks && ele.kiosks.length ? '-'
                            : ( ele.power ? 'Yes' : 'No' ),
       ]);

        const unit = "pt";
        const size = "A4";
        const orientation = "portrait";
        const marginLeft = 30;
        const doc = new jsPDF(orientation,unit, size);
        doc.setFontSize(15);

        const title = "Locations";
        const headers = [['Location Name', 'Connected', 'Power']];

        let content = {
          startY: 50,
          head: headers,
          body: exportRows,
          styles: {overflow: 'linebreak',
                fontSize: 8},
        };

        doc.text(title, marginLeft, 30);
        doc.autoTable(content);
        doc.save(`Locations - ${format(new Date(), 'MM/dd/yyyy')}.${type}`);

    } else if (type === 'csv') {

        const exportRows = [];
        rows.forEach(ele => {
          let obj = {
            'Location Name' :ele.name? ele.name: "",
            Connected : ele.kiosks && ele.kiosks.length ? '-'
            : ( ele.connected ? 'Yes' : 'No' ),
            Power : ele.kiosks && ele.kiosks.length ? '-'
            : ( ele.power ? 'Yes' : 'No' ),
          }
          exportRows.push(obj);
        })

      csvDownload(exportRows, `Locations - ${format(new Date(), 'MM/dd/yyyy')}.${type}`);
    }
  }

  return (
    <>
      <ExportAndCustomFilters onExport={handleExport}/>
      <div id={`location_search_wrapper`} className={styles.wrapper}>
        <Search
          id="location"
          label="Search by location name"
          placeholder="Search"
          query={searchQuery}
          onChange={e => setSearchQuery(e.target.value)}
        />
      </div>

      {
        !isLoaded && <div id={`location_loading_wrapper`} className={styles2.loadingContainer}>
          <p id={`location_loading_content`} className ={styles2.loadingText}>
            Loading...
          </p>
        </div>
      }

      <LocationsTable
        rows={locations}
        onExpandRow={onExpandRow}
        onEditRowClick={({ id }) => push({ pathname: `/locations/${id}` })}
      />
      <ResponseModal
        isSuccess={responseValue?.isSuccess}
        message={responseValue?.message}
        open={responseValue?.isMessageOpen}
        onClose={value => setResponseValue({...responseValue, isMessageOpen:value})}
      />
    </>
  );
}

import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  TextField,
} from "@material-ui/core";
import PropTypes from "prop-types";

export default function ConfirmationModal(props) {
  const { open, onClick, onClose, title, description } = props;
  const [value, setValue] = useState("");
  const [disableFlag, setDisableFlag] = useState(true);

  function handleChange(event) {
    const { value } = event.target;
    if (value === "delete") {
      setDisableFlag(false);
    } else {
      setDisableFlag(true);
    }
    setValue(event.target.value);
  }
  return (
    <>
      <Dialog
        id={`confirmation_modal_dialog_input`}
        open={open}
        onClose={onClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <DialogTitle id="simple-modal-title">{title}</DialogTitle>
        <DialogContent id="simple-modal-description">
          {description ? (
            <Box id={`confirmation_modal_dialog_content_description`} component={"p"}>{description}</Box>
          ) : (
            <Box id={`confirmation_modal_dialog_content_description`} component={"p"}>
              To confirm deletion, enter <strong><i>delete</i></strong> in
              the text input field.
            </Box>
          )}
          <TextField
            variant="outlined"
            autoFocus
            margin="dense"
            id="name"
            label="delete"
            type="text"
            helperText="This action cannot be undone."
            fullWidth
            value={value}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions id="simple-modal-actions">
          <Button
            id={`confirmation_modal_dialog_cancel_button`}
            variant="outlined"
            size="large"
            color="primary"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            id={`confirmation_modal_dialog_delete_operator_button`}
            variant="outlined"
            size="large"
            style={!disableFlag ? { color: "red", borderColor: "red" } : {}}
            disabled={disableFlag}
            onClick={onClick}
          >
            Delete Operator
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

ConfirmationModal.defaultProps = {
  open: false,
  title: "",
  description: "",
  onClick: () => {},
  onClose: () => {},
};

ConfirmationModal.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
  onClick: PropTypes.func,
  onClose: PropTypes.func,
};

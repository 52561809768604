import React, { useState, useEffect } from "react";
import clsx from "clsx";
import {
  Typography,
  InputAdornment,
  OutlinedInput,
  FormControl,
  Divider,
  InputLabel
} from "@material-ui/core";
import Select from "react-select";

import DealTypeButton from "../DealTypeButton/DealTypeButton";
import {
  predefinedDiscounts
} from "../../helpers/emptyDeal";

import styles from "./basic-deal.module.scss";

export default function BasicDeal(props) {
  const {
    discountType = "monetaryAbsolute",
    discountAmount = 0,
    products,
    availableProducts,
    handleChange,
  } = props;
  const [selectedProducts, setSelectedProducts] = useState([]);

  useEffect(() => {
    handleChange({
      actionTargetType: "Product",
      conditionTargetType: "Product",
    });
  }, []);

  /**
   * Map values for the filter input
   */
  useEffect(() => {
    const operatorProductIds = products.map((x) => x.uuidkey);
    setSelectedProducts(
      availableProducts.filter((x) =>
        operatorProductIds.includes(x.operatorProductId)
      )
    );
  }, [availableProducts]);

  function handleSelectChange(e) {
    setSelectedProducts(e ? e : []);
    const operatorProductIds = selectedProducts.map((x) => x.operatorProductId);
    const formattedProducts = operatorProductIds.map(function(
      operatorProductId
    ) {
      return {
        type: "operatorProductId",
        uuidkey: operatorProductId,
        usedfor: "condaction",
        excludeCondition: null,
        excludeAction: null,
      };
    });

    handleChange({ products: formattedProducts });
  }

  function handleDiscountAmountChange(value) {
    if (value[0] == "0") {
      value = value.slice(1);
    }
    const result = { discountAmount: value ? value : "0" };
    handleChange(result);
  }

  return (
    <div id={`basic_deal_wrapper`} className="row">
      <div id={`basic_deal_inner_wrapper`} className={clsx("col-6", "offset-3", styles.basicDealWrapper)}>
        <div id={`basic_deal_inner_wrapper_row`} className="row">
          <div id={`basic_deal_inner_wrapper_col_1`} className="col-2">
            <Typography id={`basic_deal_get_typography`} variant="h4">Get</Typography>
          </div>
          <div id={`basic_deal_inner_wrapper_col_2`} className={clsx("col-2", styles.discountTypes)}>
            {predefinedDiscounts.map(({ id, value, ...rest }) => (
              <DealTypeButton
                key={id}
                value={value}
                {...rest}
                isActive={value === discountType}
                handleClick={(discountType) => handleChange({ discountType })}
              />
            ))}
          </div>
          <div id={`basic_deal_inner_wrapper_col_3`} className="col-4">
            <FormControl fullWidth>
              <OutlinedInput
                id="basic_promotion_value"
                placeholder="0"
                value={discountAmount || 0}
                onChange={(e) => handleDiscountAmountChange(e.target.value)}
                variant="outlined"
                startAdornment={
                  discountType === "monetaryAbsolute" && (
                    <InputAdornment position="start">$</InputAdornment>
                  )
                }
                endAdornment={
                  discountType === "monetaryPercent" && (
                    <InputAdornment position="end">%</InputAdornment>
                  )
                }
              />
            </FormControl>
          </div>
          <Typography id={`basic_deal_off_typography`} variant="h4">Off</Typography>
        </div>
        <div id={`basic_deal_inner_wrapper_row_1`} className="row">
          <div id={`basic_deal_inner_wrapper_col_4`} className="col-12">
            <Divider className={styles.divider} />
            <div id={`basic_deal_inner_wrapper_flex`} className="d-flex flex-column">
              <InputLabel id="filters-label">
                Filter products by name
              </InputLabel>
              <div id={`basic_deal_inner_wrapper_row_flex_1`} className={clsx(styles.container, "d-flex")}>
                <Select
                  isMulti
                  id="basicDeals_products"
                  name="products"
                  options={availableProducts}
                  value={selectedProducts}
                  menuPortalTarget={document.body}
                  className={clsx("basic-multi-select", styles.products)}
                  placeholder="Add a filter"
                  onChange={(e) => handleSelectChange(e)}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.operatorProductId}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
  makeStyles
} from '@material-ui/core';
import { format } from 'date-fns';

import { getComparator, getDefaultStyles, stableSort } from '../../../../../../services/table';

const headCells = [
  { id: 'date', numeric: false, disablePadding: false, label: 'Date' },
  { id: 'action', numeric: false, disablePadding: false, label: 'Action' },
  { id: 'customer', numeric: false, disablePadding: false, label: 'Customer' },
  { id: 'device', numeric: false, disablePadding: false, label: 'Device' },
  { id: 'location', numeric: false, disablePadding: false, label: 'Location' }
];

function EnhancedTableHead() {
  return (
    <TableHead id={`events_table_head`}>
      <TableRow id={`events_table_head_row`}>
        {headCells.map(headCell => (
          <TableCell
            id={`events_table_head_${headCell.id}`}
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles(getDefaultStyles);

export default function EventsTable(props) {
  const classes = useStyles();
  const [order] = useState('asc');
  const [orderBy] = useState('id');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const {
    rows
  } = props;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    const newSize=parseInt(event.target.value, 10);
    const newP = parseInt((page * rowsPerPage + 1) / newSize) ;
    setRowsPerPage(newSize);
    setPage(newP);
  };

  return (
    <div id={`events_table_root_wrapper`} className={classes.root}>
      <Paper id={`events_table_paper_wrapper`} className={classes.paper}>
        <TableContainer id={`events_table_container_wrapper`}>
          <Table
            id={`events_table_wrapper`}
            className={classes.table}
            aria-labelledby="tableTitle"
            size="small"
            aria-label="enhanced table"
          >
            <EnhancedTableHead/>
            <TableBody id={`events_table_body_wrapper`}>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(row => {
                  const {
                    id,
                    creationDate,
                    action,
                    user: { firstName, lastName },
                    device,
                    locationId
                  } = row;

                  return (
                    <TableRow id={`events_table_row_${id}`} hover key={id}>
                      <TableCell id={`events_table_cell_creationDate_${id}`} align="left" padding="normal">
                        {!creationDate
                          ? 'N/A'
                          : format(new Date(creationDate), 'MM/dd/yyyy - hh:mm aa')}
                      </TableCell>
                      <TableCell id={`events_table_cell_action_${id}`} align="left" padding="normal">
                        {!action ? 'N/A' : action}
                      </TableCell>
                      <TableCell id={`events_table_cell_firstName_lastName_${id}`} align="left" padding="normal">
                        {`${firstName} ${lastName}`}
                      </TableCell>
                      <TableCell id={`events_table_cell_device_${id}`} align="left" padding="normal">
                        {!device ? 'N/A' : device}
                      </TableCell>
                      <TableCell id={`events_table_cell_locationId_${id}`} align="left" padding="normal">{locationId}</TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          id={`events_table_pagination`}
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}

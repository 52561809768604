import * as utils from '../../_metronic/utils/utils';


const localStorageLastLocationKey = 'demo1-lastLocation';


function acceptLocation(lastLocation) {
  return !!(lastLocation
    && lastLocation.pathname
    && lastLocation.pathname !== '/'
    && lastLocation.pathname.indexOf('auth') === -1
    && lastLocation.pathname !== '/logout');
}

export function saveLastLocation(lastLocation) {
  const localStorageLocations = utils.getStorage(localStorageLastLocationKey);
  const _useLocations = localStorageLocations
    ? JSON.parse(localStorageLocations)
    : [];

    if(_useLocations.length === 1 && lastLocation?.pathname === "/"){
      _useLocations.pop();
      utils.setStorage(
        localStorageLastLocationKey,
        JSON.stringify(_useLocations),
        120
      );
    }

  if (acceptLocation(lastLocation)) {

    // poping last location if back button is pressed
    if(lastLocation.pathname === _useLocations[_useLocations.length - 2]){
      _useLocations.pop();
      _useLocations.pop();
    }

    // logic to check if it is there already
    if(_useLocations[_useLocations.length -1] !== lastLocation.pathname){
      _useLocations.push(lastLocation.pathname);
    }

    utils.setStorage(
      localStorageLastLocationKey,
      JSON.stringify(_useLocations),
      120
    );
  }
}

export function forgotLastLocation() {
  utils.removeStorage(localStorageLastLocationKey);
}

/**
 * Gets the last location from the user's browsing history, considering if it's the same user or not.
 *
 * @param {boolean|string} isSameUser - Indicates if it's the same user. Should be a boolean or string 'true'/'false'.
 * @returns {string} The last location from the user's browsing history or the root path ('/') if conditions are not met.
 */

export function getLastLocation(isSameUser) {
  const localStorateLocations = utils.getStorage(localStorageLastLocationKey);
  if (isSameUser == 'false' || !localStorateLocations) {
    return '/';
  }
  const _userLocations = JSON.parse(localStorateLocations);
  return _userLocations.length > 0 ? _userLocations.pop() : '/';
}

/**
 * Parses a JSON Web Token (JWT) and extracts the payload.
 *
 * @param {string} token - The JWT token to parse.
 * @returns {object|null} The parsed JSON payload of the JWT token, or null if the token is invalid or null.
 */
export function parseJwt(token) {
  if (!token) {
    return null; // Return null if token is not provided
  }
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
  return JSON.parse(jsonPayload);
}
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import to from "await-to-js";
import { format } from "date-fns";
import * as Sentry from "@sentry/react";
import jsPDF from "jspdf";
import csvDownload from "json-to-csv-export";
import "jspdf-autotable";
import { Lock } from "@material-ui/icons";
import { Button } from "@material-ui/core";

import { accessPermission } from "app/common/helperFunction";
import ExportAndCustomFilters from "../../partials/content/ExportAndCustomFilters";
import Search from "../../partials/content/Customers/Search";
import AddOnsTable from "./components/AddOnsTable/AddOnsTable";
import { getAddOnsList, sendCommandToAddOn } from "../../services/add-ons";
import ResponseModal from "../Helpers/ResponseModal";
import LoadingSpinner from "app/partials/content/LoadingSpinner";
import AddLock from "./AddLock";

import styles from "./add-ons.module.scss";
import { useCustomResponse } from "app/hooks/useCustomResponse";
import { reportFormats } from "app/services/reports";

export default function AddOns() {
  const { push } = useHistory();
  const [type] = useState("coolers");
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
  });
  const [addOns, setAddOns] = useState([]);
  const [addOnsTotal, setAddOnsTotal] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);
  const [addLockPopup, setAddLockPopup] = useState(false);
  const [responseValue, setResponseValue] = useCustomResponse()


  useEffect(() => {
    if (!accessPermission("AddonsAll")) {
      push({ pathname: "/dashboard" });
    }
  }, []);

  /**
   * Get all Add-Ons
   */
  useEffect(() => {
    proceedAddOns();
    // eslint-disable-next-line
  }, [pagination, searchQuery]);

  /**
   * Get all Add-Ons
   * @returns {Promise<void>}
   */
  async function proceedAddOns() {
    const [err, res] = await to(getAddOnsList(pagination, searchQuery));
    setIsLoaded(true);
    if (err) {   
      Sentry.captureException(err);
      setResponseValue({...responseValue, isMessageOpen: true, isSuccess: false, message:`Error while getting Coolers. ${err}` })
      return console.error("Error while fetching Add-ons data:", err);
    }

    const {
      coolers: { rows },
      meta: { totalItems },
    } = res.data;
    rows.forEach((cooler) => {
      const date = new Date(cooler.creationDate);
      cooler.creationDate = format(date, "MM/dd/yyyy - hh:mm aa");
    });

    setAddOns(rows);
    setAddOnsTotal(totalItems);
  }

  /**
   * Handle navigation to the add-on details
   */
  function handleAddOnClick(LocationId, id) {
    push({ pathname: `/add-ons/${type}/${LocationId}/${id}` });
  }

  function handleAddLockClick() {
    setAddLockPopup(!addLockPopup);
  }

  /**
   * Send status update request
   */
  async function handleStatusChange(LocationId, id, command) {
    const [err, res] = await to(sendCommandToAddOn(LocationId, id, command));

    if (err) {
      Sentry.captureException(err);    
      setResponseValue({...responseValue,isMessageOpen: true, isSuccess: false, message:`Error while sending command to  cooler. ${err}` })
      return console.error("Add-on status was not updates: ", err);
    }
    const { success } = res.data;

    if (success)
      setAddOns((cur) =>
        cur.map((a) => {
          if (a.id === id) return { ...a, enabled: !a.enabled };

          return a;
        })
      );
  }

  async function handleExport(type) {
    const [err, res] = await to(
      getAddOnsList(
        {
          page: 1,
          limit: addOnsTotal,
        },
        searchQuery
      )
    );

    if (err) return console.error("Error while fetching Add-ons data:", err);

    const { rows } = res.data.coolers;

    if (type === "pdf") {
      const exportRows = rows.map((ele) => [
        ele.name ? ele.name : "",
        ele.status ? ele.status : "",
        ele.location.name ? ele.location.name : "",
        ele.enabled ? "On" : "Off",
      ]);

      const unit = "pt";
      const size = "A4";
      const orientation = "portrait";
      const marginLeft = 30;
      const doc = new jsPDF(orientation, unit, size);
      doc.setFontSize(15);

      const title = "Coolers";
      const headers = [["Name", "Status", "Location", "Active"]];

      let content = {
        startY: 50,
        head: headers,
        body: exportRows,
        styles: { overflow: "linebreak", fontSize: 8 },
      };

      doc.text(title, marginLeft, 30);
      doc.autoTable(content);
      doc.save(`Add-Ons - ${format(new Date(), 'MM/dd/yyyy')}.${type}`);
    } else if (type === "csv") {
      const exportRows = [];
      rows.forEach((ele) => {
        let obj = {
          Name: ele.name ? ele.name : "",
          Status: ele.status ? ele.status : "",
          Location: ele.location.name ? ele.location.name : "",
          Active: ele.enabled ? "On" : "Off",
        };
        exportRows.push(obj);
      });

      csvDownload(exportRows, `Add-Ons - ${format(new Date(), 'MM/dd/yyyy')}.${type}`);
    }
  }

  return (
    <>
      <ExportAndCustomFilters onExport={handleExport} hasFilters={false} newFilterTag={reportFormats.PDF}/>

      {!isLoaded ? (
        <LoadingSpinner/>
      ) : (
        <>
          <div className={styles.wrapper}>
            <Search
              id="addOns"
              label="Search by safe name, location, or lock id"
              placeholder="Search"
              query={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>

          <AddOnsTable
            rows={addOns}
            pagination={pagination}
            addOnsTotal={addOnsTotal}
            onStatusChange={handleStatusChange}
            onEditRowClick={handleAddOnClick}
            onPaginationChange={(value) => setPagination(value)}
          />

          <div className={styles.buttonWrapper}>
            <Button
               id="add_ons_add_lock_button"
              endIcon={<Lock />}
              size="large"
              variant="contained"
              color="primary"
              disableElevation
              onClick={handleAddLockClick}
            >
              Add Lock
            </Button>
          </div>

          {addLockPopup && <AddLock setAddLockPopup={setAddLockPopup} />}

          <ResponseModal
            isSuccess={responseValue?.isSuccess}
            message={responseValue?.message}
            open={responseValue?.isMessageOpen}
            onClose={(value) => setResponseValue({...responseValue, isMessageOpen: value})}
          />
        </>
      )}
    </>
  );
}

import React, { Component } from 'react';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { TextField } from '@material-ui/core';
import { Link, Redirect } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';

import * as actions from '../../store/actions';
import { requestPassword } from '../../crud/auth.crud';
import ResponseModal from 'app/components/Helpers/ResponseModal';

class ForgotPassword extends Component {
  // eslint-disable-next-line react/state-in-constructor
  state = { 
    isRequested: false,
    showMessage: false,
    responseMessageSuccess: true,
    responseMessageOpen: false,
    responseMessage: "",
  };

  constructor(props) {
    super(props);
    this.openResponse = this.openResponse.bind(this);
  }

  openResponse(isSuccess, message) {
    this.setState({
      responseMessageOpen: true,
      responseMessageSuccess: isSuccess,
      responseMessage: message,
    });
  }

  render() {
    const { intl } = this.props;
    const { isRequested } = this.state;
    const defaultSuccessMessage = "Resent Link sent to your email ,Please follow the instruction mentioned on mail";

    if (isRequested)
      return <Redirect to="/auth"/>;

    return (
      <div className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper">
        <div className="kt-login__body">
          <div className="kt-login__form">
            <div className="kt-login__title">
              <h3>
                <FormattedMessage id="AUTH.FORGOT.TITLE"/>
              </h3>
            </div>

            <Formik
              initialValues={{ email: '' }}
              validate={values => {
                const errors = {};

                if (!values.email)
                  errors.email = intl.formatMessage({
                    id: 'AUTH.VALIDATION.REQUIRED_FIELD'
                  });
                else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                )
                  errors.email = intl.formatMessage({
                    id: 'AUTH.VALIDATION.INVALID_FIELD'
                  });

                return errors;
              }}
              onSubmit={(values, { setStatus, setSubmitting }) => {
                requestPassword(values.email)
                  .then((res) => {
                    this.openResponse(true, res.data?.message || defaultSuccessMessage)
                    setTimeout(() => {
                      this.setState({ isRequested: true });
                    },5000);
                    
                  })
                  .catch(() => {
                    setSubmitting(false);
                    setStatus(
                      intl.formatMessage(
                        { id: 'AUTH.VALIDATION.NOT_FOUND' },
                        { name: values.email }
                      )
                    );
                  });
              }}
            >
              {({
                values,
                status,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting
              }) => (
                <form onSubmit={handleSubmit} className="kt-form">
                  {status && (
                    <div role="alert" className="alert alert-danger">
                      <div className="alert-text">{status}</div>
                    </div>
                  )}

                  <div className="form-group">
                    <TextField
                      type="email"
                      label="Email"
                      margin="normal"
                      fullWidth
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.email}
                      helperText={touched.email && errors.email}
                      error={Boolean(touched.email && errors.email)}
                    />
                  </div>

                  <div className="kt-login__actions">
                    <Link to="/auth">
                      <button
                        type="button"
                        className="btn btn-secondary btn-elevate kt-login__btn-secondary"
                      >
                        Back
                      </button>
                    </Link>

                    <button
                      type="submit"
                      className="btn btn-primary btn-elevate kt-login__btn-primary"
                      disabled={isSubmitting}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
        <ResponseModal
          isSuccess={this.state.responseMessageSuccess}
          message={this.state.responseMessage}
          open={this.state.responseMessageOpen}
          onClose={(value) => {this.setState({responseMessageOpen: value, isRequested: true})}}
        />            
      </div>
    );
  }
}

export default injectIntl(
  connect(
    null,
    actions.authActions
  )(ForgotPassword)
);

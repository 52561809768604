import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Input,
} from "@material-ui/core";
import to from "await-to-js";
import { paymentMethods } from "app/common/LedgerConst";

import { refund } from "../../components/Ledger/services/sales";

import styles from "./response.module.scss";

export default function RefundModal(props) {
  const {
    open,
    onClose,
    prettyId,
    orderId,
    transactions,
    setIsRefunded,
    openResponse,
  } = props;
  const [dropdown, setDropdown] = useState(false);
  const [transactionOneId, setTransactionOneId] = useState("");
  const [transactionOneAmount, setTransactionOneAmount] = useState(0);
  const [firstAmount, setFirstAmount] = useState(0);
  const [secondAmount, setSecondAmount] = useState(0);
  const [transactionOneMethod, setTransactionOneMethod] = useState("");
  const [transactionOneRefund, setTransactionOneRefund] = useState(false);
  const [transactionTwoId, setTransactionTwoId] = useState("");
  const [transactionTwoAmount, setTransactionTwoAmount] = useState(0);
  const [transactionTwoMethod, setTransactionTwoMethod] = useState("");
  const [transactionTwoRefund, setTransactionTwoRefund] = useState(false);

  const [refundMessage, setRefundMessage] = useState("");

  useEffect(() => {
    var count = 0;
    transactions.forEach((elm) => {
      var paymentType = "";
      switch (elm.paymentType) {
        case 0:
          paymentType = paymentMethods.card;
          break;
        case 1:
          paymentType = paymentMethods.giftCard;
          break;
        case 2:
          paymentType = paymentMethods.loyalty;
          break;
        case 3:
          paymentType = paymentMethods.yokeCash;
          break;
        case 4:
          paymentType = paymentMethods.tab;
          break;
        default:
          paymentType = paymentMethods.tab;
      }

      if (count === 0) {
        setTransactionOneId(elm.transactionId);
        setTransactionOneMethod(paymentType);
        setTransactionOneAmount(elm.amount.toFixed(2));
        setFirstAmount(elm.amount.toFixed(2));
      } else {
        setTransactionTwoId(elm.transactionId);
        setTransactionTwoMethod(paymentType);
        setTransactionTwoAmount(elm.amount.toFixed(2));
        setSecondAmount(elm.amount.toFixed(2));
      }
      count++;
    });

    return () => {
      setTransactionOneId("");
      setTransactionOneMethod("");
      setTransactionOneAmount(0);
      setTransactionOneRefund(false);
      setFirstAmount(0);

      setTransactionTwoId("");
      setTransactionTwoMethod("");
      setTransactionTwoAmount(0);
      setTransactionTwoRefund(false);
      setSecondAmount(0);
    };
  }, [transactions]);

  function handleModalClose() {
    setDropdown(false);
    onClose(false);
    setRefundMessage("");
  }

  async function refundAction(transaction) {
    let amount = 0;
    let transactionId = "";
    if (transaction === 1) {
      amount = transactionOneAmount;
      transactionId = transactionOneId;
      if (transactionOneAmount > firstAmount) {
        setRefundMessage(
          `Refund can't be more than ${transactionOneMethod} amount`
        );
        return;
      }
    } else {
      amount = transactionTwoAmount;
      transactionId = transactionTwoId;
      if (transactionTwoAmount > secondAmount) {
        setRefundMessage(
          `Refund can't be more than ${transactionTwoMethod} amount`
        );
        return;
      }
    }

    const [err, refundResponse] = await to(
      refund(orderId, amount, transactionId)
    );
    if (err) {
      console.error(err);
    } else {
      if (refundResponse.data.refundResponse.success === true) {
        setRefundMessage("Refund Successful");
        setIsRefunded(true);
        handleModalClose();
        openResponse(true, false, "Refund Successful");
      } else {
        setRefundMessage("Refund Failed");
      }
    }
    return;
  }

  return (
    <>
      <Dialog
        id={`refund_modal_dialog_wrapper`}
        open={open}
        onClose={handleModalClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <DialogTitle id="simple-modal-title">{`Refund Order`}</DialogTitle>
        <DialogContent id="simple-modal-description" className={styles.wrapper}>
          {`Multiple transactions can be applied towards one order if a customer uses two payment methods on checkout. Please review the following transactions for order ${
            prettyId.split(":")[0]
          } carefully before refunding. Refunding cannot be reversed.`}
        </DialogContent>
        <DialogContent
          id="simple-modal-description"
          className={
            refundMessage === "Refund Successful"
              ? styles.refundedWrapper
              : styles.refundFailWrapper
          }
        >
          {refundMessage}
        </DialogContent>
        <div
          id={`refund_modal_dialog_wrapper_all_button`}
          className={styles.allButton}
        >
          <div
            id={`refund_modal_dialog_wrapper_refund_buttons`}
            className={styles.refundButtons}
          >
            <DialogContent
              id="simple-modal-description"
              className={styles.wrapper}
            >
              {`${transactionOneMethod}: `}
              <Input
                className={styles.input}
                id="transaction_one"
                value={transactionOneAmount}
                error={!transactionOneAmount}
                onClick={() => setRefundMessage("")}
                onChange={(e) =>
                  setTransactionOneAmount(
                    parseFloat(e?.target?.value) || 0
                  )
                }
              />
              <Button
                id={`refund_modal_dialog_refund_buttons`}
                className={styles.buttonWrapper}
                size="large"
                variant="contained"
                color="primary"
                onClick={() => refundAction(1)}
                disableElevation
              >
                Refund
              </Button>
            </DialogContent>
            </div>
            <div className={styles.refundButtonWrapper}>
            {transactionTwoAmount !== 0 ? (
              <DialogContent
                id="simple-modal-description"
                className={styles.wrapper}
              >
                {`${transactionTwoMethod}: `}
                <Input
                  className={styles.input}
                  id="transactionOne"
                  value={transactionTwoAmount}
                  error={!transactionTwoAmount}
                  onClick={() => setRefundMessage("")}
                  onChange={(e) =>
                    setTransactionTwoAmount(
                      parseFloat(e?.target?.value) || 0
                    )
                  }
                />
                <Button
                  id={`refund_modal_dialog_refund_buttons_1`}
                  className={styles.buttonWrapper}
                  size="large"
                  variant="contained"
                  color="primary"
                  onClick={() => refundAction(2)}
                  disableElevation
                >
                  Refund
                </Button>
              </DialogContent>
            ) : null}

          <DialogContent
            id="simple-modal-actions"
            className={styles.cancelWrapper}
          >
            <Button
              color="primary"
              size="large"
              variant="contained"
              onClick={handleModalClose}
              id={`refund_modal_dialog_cancel_buttons`}
            >
              Cancel
            </Button>
            
          </DialogContent>
        </div>
        </div>
      </Dialog>
    </>
    
  );
}

import React from 'react';
import clsx from 'clsx';

import CustomButton from '../CustomButton/CustomButton';
import { ExclusivePromotion } from './components/ExclusivePromotion/ExclusivePromotion';

import styles from './target-promotion.module.scss';
/**
 * Custom buttons
 */
const buttons = [{
  id: 1,
  title: 'Available to Everyone',
  subtitle: 'All customers can receive this promotion.',
  value: 'everyone'
}, {
  id: 2,
  title: 'Exclusive to Some',
  subtitle: 'Only customers within a customer group, or with a promo code, can redeem this promotion.',
  value: 'exclusive'
}];

export default function TargetPromotion(props) {
  const {
    targetType,
    exclusiveOptions,
    changePromotionTarget
  } = props;

  function changeExclusiveOptions(value, property) {
    changePromotionTarget({ ...exclusiveOptions, [property]: value }, 'exclusiveOptions');
  }

  return (
    <div id={`target_promotion_wrapper`} className={styles.wrapper}>
      <div id={`target_promotion_button_wrapper`} className={clsx(styles.targetPromotion, 'd-flex')}>
        {buttons.map(btn => (
          <CustomButton
            {...btn}
            key={btn.id}
            isActive={btn.value === targetType}
            onClick={e => changePromotionTarget(e, 'targetType')}
          />
        ))}
      </div>

      {targetType === 'exclusive' ? (
        <ExclusivePromotion
          {...exclusiveOptions}
          changeExclusiveOptions={changeExclusiveOptions}
        />
      ) : null}
    </div>
  );
}

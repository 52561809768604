import {
  Card,
  CardContent,
  Typography,
} from "@material-ui/core";
import Search from "../../../../partials/content/Customers/Search";
import React, { useEffect, useState } from "react";
import styles from "../../add-ons.module.scss";
import useCustomerHeader from "app/hooks/useCustomHeader";
import { billValidatorDetails } from "app/services/locations";
import to from "await-to-js";
import { format } from "date-fns";
import CashCollectedTable from "./CashCollectedTable";

export default function BillValidatorDetails({ selectedBillValidator }) {
  const [searchQuery, setSearchQuery] = useState("");
  const [pagination, setPagination] = useState({page:1, limit: 10});
  const [billValidator, setBillValidator] = useState({});
  const [cashCollectedTotal, setCashCollectedTotal] = useState(0);

  const { id:billValidatorId } = useCustomerHeader({
    property: "pageTitle",
    getRelatedItem: () => selectedBillValidator,
  });

  useEffect(() => {
    getBillValidatorDetails();
  }, [searchQuery, pagination]);

  async function getBillValidatorDetails() {
    const [err, res] = await to(billValidatorDetails(pagination, billValidatorId, searchQuery));
    const {
      meta: { totalItems },
      billValidator
    } = res.data;

    setBillValidator(billValidator);
    setCashCollectedTotal(totalItems);
  }

  return (
    <>
      <Card id={`bill_validators_details_card_container`} className={styles.container}>
        <CardContent id={`bill_validators_details_card_content_0`} className={styles.innerContainer}>
          <div id={`bill_validators_details_typography_wrapper_0`}>
            <Typography id={`bill_validators_details_typography_0`} variant="h6">
              Bill Validator: { billValidatorId }
            </Typography>
          </div>
          <div id={`bill_validators_details_typography_wrapper_1`}>
            <Typography id={`bill_validators_details_typography_1`} variant="h6">Location Name: { billValidator[0]?.LocationName } </Typography>
          </div>

          <div id={`bill_validators_details_typography_wrapper_2`}>
            <Typography id={`bill_validators_details_typography_2`} variant="h6">Kiosk Name: { billValidator[0]?.KioskName } </Typography>
          </div>

          <div id={`bill_validators_details_typography_wrapper_3`}>
            <Typography id={`bill_validators_details_typography_3`} variant="h6">Total Yoke Cash Collected: {`$ ${billValidator[0]?.amountCollected || 0}` } </Typography>
          </div>
        </CardContent>
        <CardContent id={`bill_validators_details_card_content_1`} className={styles.innerContainer}>
          <div id={`bill_validators_details_typography_wrapper_4`}>
            <Typography id={`bill_validators_details_typography_4`} variant="h6">
              Amount Uncollected: {`$ ${billValidator[0]?.amountUnCollected || 0}` }
            </Typography>
          </div>
          <div id={`bill_validators_details_typography_wrapper_5`}>
            <Typography id={`bill_validators_details_typography_5`} variant="h6">
              Last Collected: { (billValidator[0] && billValidator[0].cashCollected?.length > 0)
                ? format(new Date(billValidator[0].cashCollected[0].collectionDate), 'MM/dd/yyyy - hh:mm aa')
                : ""
             } 
            </Typography>
          </div>
          <div id={`bill_validators_details_typography_wrapper_6`}>
            <Typography id={`bill_validators_details_typography_6`} variant="h6">
              Last Collected By: { (billValidator[0] && billValidator[0].cashCollected?.length > 0)
                ? billValidator[0].cashCollected[0].collectedBy
                : ""
              }
            </Typography>
          </div>
        </CardContent>
      </Card>
      <div id={`bill_validators_details_search_wrapper`} className={styles.wrapper}>
        <Search
          label="Search by Amount or Collected By"
          placeholder="Search"
          query={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>
      <CashCollectedTable
        rows={billValidator[0]?.cashCollected || []}
        pagination={pagination}
        setPagination={setPagination}
        cashCollectedTotal={cashCollectedTotal}
      />
    </>
  );
}

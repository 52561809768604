import React from 'react';
import clsx from 'clsx';
import { Button } from '@material-ui/core';

import styles from '../../../_metronic/_assets/sass/export-and-custom-filters.module.scss';
import { reportFormats } from 'app/services/reports';
// import { NO_PDF } from 'app/common/giftCardsConst';

export default function ExportAndCustomFilters(props) {
  const {
    hasFilters = false,
    hideExport = false,
    onExport,
    newFilterTag
  } = props;

  /**
   * Handle export to PDF
   */
  function onExportToPdf() {
    onExport(reportFormats.PDF);
  }

  /**
   * Handle export to CSV
   */
  function onExportToCsv() {
    onExport(reportFormats.CSV);
  }

  function onExportToXLS() {
    onExport(reportFormats.XLS);
  }

  return (
    <div
      className={clsx('d-flex', {
        'justify-content-between': hasFilters,
        'justify-content-end': !hasFilters
      })}
    >
      {hasFilters ? (
        <Button onClick={()=>{}}>
          + Add Filters
        </Button>
      ) : null}
      {!hideExport ? (
        <div className={styles.exportButtons}>
        {newFilterTag===reportFormats.PDF &&  <Button
            size="large"
            variant="outlined"
            color="primary"
            disableElevation
            onClick={onExportToPdf}
            id="export_and_custom_filters_pdf_button"
          >
            <i className={`fa fa-file-pdf ${styles.exportIcon}`}/>
            Export to PDF
          </Button>}

          <Button
            size="large"
            variant="outlined"
            color="primary"
            disableElevation
            onClick={onExportToCsv}
            id="export_and_custom_filters_csv_button"
          >
            <i className={`fa fa-file-csv ${styles.exportIcon}`}/>
            Export to CSV
          </Button>

          {newFilterTag === reportFormats.XLS &&  <Button
            size="large"
            variant="outlined"
            color="primary"
            disableElevation
            onClick={onExportToXLS}
            id="export_and_custom_filters_xls_button"
          >
            <i className={`fa fa-file-pdf ${styles.exportIcon}`}/>
            Export to XLS
          </Button>}
        </div>
      ) : null}
    </div>
  );
}


import React from 'react';

export const AlertContext = React.createContext({
  info: () => {},
  error: () => {},
  success: () => {},
  warning: () => {}
});

export function NotificationProvider({ children }) {

  /**
   * Main notification trigger function
   * @param {*} severity
   * @param {*} content
   * @param {*} title
   */
  // eslint-disable-next-line

  return (
    <>
      <div style={{ display: 'none' }}>
        Notification are not supported yet
      </div>
    {children}
    </>
  );
}

import { HTTP_RESPONSES } from 'app/common/constant';
import axios from 'axios';
/**
* @protected Env-Variables for S3 Buckets
* */
const { REACT_APP_API_URL } = process.env;
 
/**
* @method axios instance creation
*/

const axiosInstance = axios.create({
    baseURL: REACT_APP_API_URL,
  });
 
  axiosInstance.interceptors.request.use(
    (config) => {
      const accessToken = localStorage.getItem("authToken");
  
      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  
 
  axiosInstance.interceptors.response.use(
    response => response,
    error => {
      if (!error.response) {
        alert('NETWORK ERROR')
    } else {
        const code = error.response.status
        const response = error.response.data
        const originalRequest = error.config;
  
        if (code === HTTP_RESPONSES.AUTHENTICATION_FAILED.code && !originalRequest._retry) {
            originalRequest._retry = true
  
            localStorage.clear();
            window.location.href = "/auth/login";
        }
      }
      return Promise.reject(error)
    }
  );
  
export default axiosInstance;

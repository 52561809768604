/**
 * @enum MONETARYPERCENT
 */
export const MONETARYPERCENT = "monetaryPercent";
export const TWO_TIER_ALERT_MESSAGE = {
  MONETARY_PERCENT_ERROR:
    "Fee Amount cannot be less than 0% or greater then 100%",
  MONETARY_ABSOLUTE_ERROR:
    "Fee Amount cannot be less than 0$ or greater then 100$",
  MONETARY_ABSOLUTE_WARNING_MSG:
    "The amount is higher than the recommended amount and may block transactions from processing.",
};

export const DEFAULT_DISPLAY_NAME_MAXLENGTH = 30;

/**
 * @argument feeTypes<[{}]>
 */
export const FEETYPES = [
  {
    value: "monetaryPercent",
    label: "Monetary Percent",
  },
  {
    value: "monetaryAbsolute",
    label: "Monetary Absolute",
  },
];

/**
 * Available filters
 */
export const FILTERS = [
  {
    id: "1",
    value: "days",
    alias: "Day",
    newAlias: "Today",
  },
  {
    id: "2",
    value: "weeks",
    alias: "Week",
    newAlias: "This Week",
  },
  {
    id: "3",
    value: "months",
    alias: "Month",
    newAlias: "This Month",
  },
  {
    id: "4",
    value: "years",
    alias: "Year",
    newAlias: "This Year",
  },
];

export const DASHBOARDFILTERS = [
  {
    id: "1",
    value: "days",
    alias: "Day",
  },
  {
    id: "2",
    value: "weeks",
    alias: "Week",
  },
  {
    id: "3",
    value: "months",
    alias: "Month",
  },
  {
    id: "4",
    value: "quarters",
    alias: "QUARTER",
  },
];

export const CANCEL_TOKEN = "cancel token";
export const CHECKBOX = "checkbox";
export const NUMBER = "number";

export const blockInvalidChar = (e) =>
  ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

export const ALL_OPERATORS = {
  id: "1",
  name: "All Operators",
  loyaltyProgram: "yoke",
};

export const ALL_LOCATIONS = {
  id: "1",
  name: "All Locations",
};

export const KIOSKS_HEADER = [
  {
    id: "name",
    colSpan: false,
    disablePadding: false,
    label: "Name",
    gcSupported: true,
  },
  {
    id: "connected",
    colSpan: false,
    disablePadding: false,
    label: "Connectivity",
    gcSupported: true,
  },
  {
    id: "power",
    colSpan: false,
    disablePadding: false,
    label: "Power",
    gcSupported: true,
  },
  {
    id: "checkedIn",
    colSpan: 1,
    disablePadding: false,
    label: "Last Checked In",
    gcSupported: true,
  },
  {
    id: "actions",
    colSpan: 1,
    disablePadding: false,
    label: "Actions",
    gcSupported: false,
  },
];
export const TOP_PRODUCTS_HEADER = [
  {
    id: "product",
    colSpan: false,
    disablePadding: false,
    label: "Product",
    gcSupported: true,
  },
  {
    id: "quantity",
    colSpan: false,
    disablePadding: false,
    label: "Quantity",
    gcSupported: true,
  },
  {
    id: "price",
    colSpan: false,
    disablePadding: false,
    label: "Price",
    gcSupported: true,
  },
];

export const GLOBAL_CONNECT = "globalConnect";
export const INFORMATION = "information";
export const BLANK = "_blank";
export const NO_OPENER = "noopener";
export const REBOOT_KIOSK = "Reboot Kiosk";

export const HTTP_HEADER_DETAILS = {
  CONTENT_TYPE: {
    NAME: "Content-Type",
    TEXT_PLAIN: "text/plain",
    CSV: "text/csv",
    MULTIPART: "multipart/form-data",
    FORM: "application/x-www-form-urlencoded",
    XLS: "application/vnd.ms-excel",
    PDF: "application/pdf",
    PNG: "image/png",
    JPEG: "image/jpeg",
    GIF: "image/gif",
    XML: "application/xml",
    HTML: "text/html",
    JSON: "application/json",
    OCTET: "application/octet-stream",
  },
};
export const CUSTOMERS_REPORT_NAME_PREFIX = "Customers Report - ";
export const CARTS_REPORT_NAME_PREFIX = "Cart Report - ";
export const SALES_REPORT_NAME_PREFIX = "Sales Report - ";
export const LOADS_REPORT_NAME_PREFIX = "Loads Report - ";

export const GIFT_CARD_ISSUE_CONSTANTS = {
  ISSUEOPTION: {
    DAILY: "daily",
    WEEKLY: "Weekly",
    MONTHLY: "Monthly",
    YEARLY: "yearly",
  },
  RECURRING_METRIC_TYPE_STRING: "string",
  RECURRING_ISSUE_TYPE_ONCE: "once",
  ISSUE_TYPE_SINGLE_USER: "singleUser",
};

export const GIFTCARDTYPE = {
  ONCE: "once",
  RECURRING: "recurring",
};

export const DASHBOARD_PLAIN_DATE_FORMAT = "MM/dd/yyyy";
export const EMAIL_PATTERN = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const NAME_PATTERN = /^[a-zA-Z '-]+$/;
export const MOBILE_NUM_PATTERN = /^[\d ]{10}$/;

export const EDateFilterRanges = {
  YEARS: "years",
  MONTHS: "months",
  WEEKS: "weeks",
  DAYS: "days",
  QUARTERS: "quarters",
};

export const OPERATOR_PARAM = "operator";
export const CUSTOMERS = "Customers";
export const LOCATIONS = "Locations";
export const DELETE_LOCATION_GROUP_ERROR_MESSAGE =
  "Error while Deleting the locations group- ";
export const UPDATE_LOCATION_GROUP_ERROR_MESSAGE =
  "Error while Updating the locations group- ";
export const UPDATE_LOCATION_GROUP_SUCCESS_MESSAGE = "Locations Group Updated";
export const YOKE_LOYALTY_PROGRAM = "yoke";
export const ORDER_TYPE_ORDER = "order";
export const ORDER_TYPE_VALUEADD = "valueAdd";
export const LOCATION_PARAM = "location";
export const PAGE_TITLE_SALES = "Sales";
export const PAGE_TITLE_LOADS = "Loads";
export const PAGE_TITLE_CARTS = "Carts";
export const EMAIL_EMPTY_ERROR_MESSAGE = "Email cannot be empty";
export const EMAIL_INVALID_ERROR_MESSAGE =
  "Please enter a valid Email address.";
export const LOCATION_SELECTION_REQUIRED_MESSAGE =
  "Please select at least one location before saving";

export const PAGE_TITLE_CASH_COLLECTIONS = "Cash Collections";

export const KIOSK_IN_ESPER = "Kiosk In Esper";
export const KIOSK_IN_ESPER_DISABLE = "Can't Find Device In Esper";

export const IS_SAME_USER = "isSameUser";
export const AUTH_TOKEN = "authToken";
export const LAST_USER_AUTH_TOKEN = "lastUserAuthToken";
export const FETCHING_OPERATORS_FILTERS_ERROR_MESSAGE =
  "Error with operators filters fetching: ";
export const FETCHING_LOCATIONS_FILTERS_ERROR_MESSAGE =
  "Error with locations filters fetching: ";
export const DATE_PICKER_COMPONENT_VIEWS = ["date", "month", "year"];

export const PATH_NAME_CASH_COLLECTIONS = "cashCollections";
export const ERROR_MSG_WHILE_CREATING_BILL_VALIDATOR =
  "Error while creating bill validator. ";
export const ERROR_MSG_WHILE_GETTING_COOLER_DETAILS =
  "Error while getting Cooler details.";
export const ERROR_MSG_WHILE_FETCHING_SETUP_LOCATIONS =
  "Error on setup locations fetch: ";
export const KIOSK_PARAM = "kiosk";

export const VALID_INPUT_ERROR = "Enter a valid input";
export const RECURRING_MATRIX_STRING = "string";

export const TYPE_ONCE = "once";
export const ORDER_BY_UPDATED_DATE = "updatedOn";
export const ESortOrder = {
  ASC: "asc",
  DESC: "desc",
};
export const NO_ACCESS_TO_THAT_LOCATION = `You Don't have Access to this Location Data`;
export const NOT_APPLICABLE = "N/A";
export const STATUS_REFUNDED = "Refunded";

export const HTTP_RESPONSES = {
  SUCCESS: {
    code: 200,
    message: "Success",
  },
  CREATED: {
    code: 201,
    message: "Success",
  },
  ACCEPTED: {
    code: 202,
    message: "Success",
  },
  BAD_REQUEST: {
    code: 400,
    message: "Bad Request. Please correct the request and resubmit.",
  },
  AUTHENTICATION_FAILED: {
    code: 401,
    message: "Access Denied. Please provide valid authorization details.",
  },
  AUTHORIZATION_FAILED: {
    code: 403,
    message: "Access Denied. Please provide valid authorization details.",
  },
  NOT_FOUND: {
    code: 404,
    message:
      "Unable to find the valid resource. Please provide a valid criteria or id.",
  },
  REQUEST_TIMEDOUT: {
    code: 408,
    message: "Request timedout. Please try after some time.",
  },
  UNPROCESSABLE_CONTENT: {
    code: 422,
    message:
      "Validation error. Please correct the error and resubmit the request.",
  },
  SERVICE_UNAVAILABLE: {
    code: 503,
    message: "Service unavailable, Please try after some time.",
  },
  INTERNAL_SERVER_ERROR: {
    code: 500,
    message: "Unexpected Error. Please reach out to application support.",
  },
};
export const DEFAULT_DEBOUNCE_DELAY = 3000;
export const GROUP_CONSTANTS = {
  GROUP_NAME_ERROR: "Group name cannot be empty",
};

export const ADD_BILLVALIDATOR_DELAY = 1500;

export const CHECK_IN_INSTRUCTIONS = "Check-in Instructions";
export const CANTALOUPE_CASH = "Cantaloupe Cash";
export const ACCOUNT_APPLICATION_POPUP = "inProgress";
export const MARKET_INSIGHTS = "Market Platform Insights";

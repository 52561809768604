import React, { useState } from 'react';
import {
  Button,
  Card,
  CardContent
} from '@material-ui/core';
import { Add } from '@material-ui/icons';

import AddKiosk from './components/AddKiosk/AddKiosk';

import styles from './kiosk-new.module.scss';

const kiosk = {
  id: Date.now(),
  name: '',
  serialNumber: '',
  reader: 1,
  barcodeScanner: 1,
  tabletType: 1,
  mount: 1,
  color: 1,
  cellularProvider: '',
  creditCardReader: 1,
  connectionType: ''
};

export default function KioskNew() {
  const [kiosks, setKiosks] = useState([kiosk]);

  /**
   * Change new kiosk
   * @param id
   * @param kiosk
   */
  function changeKiosk(id, kiosk) {
    setKiosks(kiosks.map(kioskItem => {
      if (kioskItem.id !== id)
        return kioskItem;

      return {
        ...kioskItem,
        ...kiosk
      };
    }));
  }

  return (
    <Card id={`kiosk_card_wrapper`} className={styles.cardWrapper}>
      <CardContent id={`kiosk_card_content_wrapper`} className="d-flex flex-column">
        {kiosks.map(kiosk => (
          <AddKiosk
            key={kiosk.id}
            currentKiosk={kiosk}
            setCurrentKiosk={e => changeKiosk(kiosk.id, e)}
          />
        ))}
      </CardContent>
      <CardContent id={`kiosk_card_content_button_wrapper`} className="d-flex justify-content-center">
        <Button
          id={`kiosk_add_kiosk_button`}
          startIcon={<Add/>}
          size="large"
          variant="contained"
          color="primary"
          disableElevation
          onClick={() => setKiosks([...kiosks, { ...kiosk, id: Date.now }])}
        >
          Add Kiosk
        </Button>
      </CardContent>
    </Card>
  );
}

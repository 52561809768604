import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
  makeStyles
} from '@material-ui/core';

import { getDefaultStyles } from '../../../../services/table';
import { format } from 'date-fns';

const headCells = [
  { id: 'amount', numeric: false, disablePadding: false, label: 'Amount' },
  { id: 'collecdBy', numeric: false, disablePadding: false, label: 'Collected By' },
  { id: 'collectionDate', numeric: false, disablePadding: false, label: 'Collection Date' }
];

function EnhancedTableHead() {
  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles(getDefaultStyles);

export default function CashCollectedTable(props) {
  const {
    rows,
    pagination:{page=1, limit:rowsPerPage = 10},
    cashCollectedTotal,
    setPagination
  } = props;
  const classes = useStyles();

  /**
   * Handle pagination value change
   */
  function handlePaginationChange({ target: { value } }, newPage = -1) {
    if (value) {
      const newSize = parseInt(value, 10);
      const newP = parseInt(((page-1) * rowsPerPage + 1) / newSize);

      setPagination({
        page: newP +1,
        limit: newSize
      });
    }

    if (newPage >= 0 && typeof newPage !== 'object') {
      setPagination({
        page: newPage + 1,
        limit: rowsPerPage
      });
    }
  }

  return (
    <div id={`cash_collected_table_root_wrapper`} className={classes.root}>
      <Paper id={`cash_collected_table_paper_wrapper`} className={classes.paper}>
        <TableContainer id={`cash_collected_table_container`}>
          <Table
            id={`cash_collected_table`}
            className={classes.table}
            aria-labelledby="tableTitle"
            size="small"
            aria-label="enhanced table"
          >
            <EnhancedTableHead/>
            <TableBody id={`cash_collected_table_body`}>
              {
                rows.map((row,index) => {
                  const {
                    amount,
                    collectedBy,
                    collectionDate
                  } = row;

                  return (
                    <TableRow
                      hover
                      key={index}
                      id={`cash_collected_table_row_${index}`}
                    >
                      <TableCell id={`cash_collected_table_cell_amount_${index}`} align="left" padding="normal">{`$ ${amount}`}</TableCell>
                      <TableCell id={`cash_collected_table_cell_collectedBy_${index}`} align="left" padding="normal">
                        {collectedBy}
                      </TableCell>
                      <TableCell id={`cash_collected_table_cell_collectionDate_${index}`} align="left" padding="normal">{format(new Date(collectionDate),"MM/dd/yyyy - hh:mm aa")}</TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          id={`cash_collected_table_pagination`}
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={cashCollectedTotal}
          rowsPerPage={rowsPerPage}
          page={page-1}
          onPageChange={handlePaginationChange}
          onRowsPerPageChange={handlePaginationChange}
        />
      </Paper>
    </div>
  );
}

function checkOnlyNumber(event) {
    const keyCode = event.which || event.keyCode;
    return (
        (keyCode >= 48 && keyCode <= 57 && !event.shiftKey) ||
        (keyCode >= 96 && keyCode <= 105)
    )
}

export function ssnValueValidator(ssn) {
    if (!ssn) {
        return true;
    }

    var regx = /^(?!666|000|9\d{2})\d{3}-(?!00)\d{2}-(?!0{4})\d{4}$/
    return regx.test(ssn);
}

export function expDateValueValidator(expDate) {
    if(!expDate){
        return true;
    }

    var regx = /^[01]\d\/\d{2}$/
    return regx.test(expDate);
}

export function ssnKeyValidator(event) {
    const keyCode = event.which || event.keyCode;

    if (event.ctrlKey || event.metaKey) {
      return;
    }
    if (
      !(
        checkOnlyNumber(event) ||
        keyCode === 8 ||
        keyCode === 9 
      )
    ) {
      event.preventDefault();
    }
}

export function yokePointsValidator(event) {
    const keyCode = event.which || event.keyCode;

    if (event.ctrlKey || event.metaKey) {
      return;
    }
    if (
      !(
        checkOnlyNumber(event) ||
        keyCode === 8 ||
        keyCode === 9 
      )
    ) {
      event.preventDefault();
    }
}

export function cvcKeyValidator(event) {
    const keyCode = event.which || event.keyCode;

    if (event.ctrlKey || event.metaKey) {
        return;
    }
    if(
        !(
            checkOnlyNumber(event) ||
            keyCode === 8 ||
            keyCode === 9
        )
    ) {
        event.preventDefault();
    }
}

export function expDateKeyValidator(event) {
    const keyCode = event.which || event.keyCode;

    console.log(keyCode)

    if(event.ctrlKey || event.metaKey) {
        return;
    }
    if(
        !(
            checkOnlyNumber(event) ||
            keyCode === 8 ||
            keyCode === 9 ||
            (keyCode === 191 && !event.shiftKey) ||
            keyCode === 111
        )
    ) {
        event.preventDefault();
    }
}

export function cardNumberKeyValidator(event) {
    const keyCode = event.which || event.keyCode;

    if(event.ctrlKey || event.metaKey) {
        return;
    }
    if(
        !(
            checkOnlyNumber(event) ||
            keyCode === 8 ||
            keyCode === 9
        )
    ) {
        event.preventDefault();
    }
}

export function routingNumberKeyValidator(event) {
    const keyCode = event.which || event.keyCode;

    if(event.ctrlKey || event.metaKey) {
        return;
    }
    if(
        !(
            checkOnlyNumber(event) ||
            keyCode === 8 ||
            keyCode === 9
        )
    ) {
        event.preventDefault();
    }
}
/*eslint-disable*/
let mockedCustomers = [
  {
    id: 1,
    "firstName": "Ware",
    "lastName": "Foreman",
    "email": "Ware.Foreman@email.com",
    "currentLocation": "Haiti, Crumpler",
    "yokeCashBalance": 73.00,
    "loyaltyPoints": 314,
    "group": "Dev Team"
  },
  {
    id: 2,
    "firstName": "Callie",
    "lastName": "Mclaughlin",
    "email": "Callie.Mclaughlin@email.com",
    "currentLocation": "Ireland, Hemlock",
    "yokeCashBalance": 59.97,
    "loyaltyPoints": 44,
    "group": "Management Team"
  },
  {
    id: 3,
    "firstName": "Odonnell",
    "lastName": "Golden",
    "email": "Odonnell.Golden@email.com",
    "currentLocation": "Monaco, Retsof",
    "yokeCashBalance": 92.44,
    "loyaltyPoints": 191,
    "group": "Management Team"
  },
  {
    id: 4,
    "firstName": "Sosa",
    "lastName": "Reid",
    "email": "Sosa.Reid@email.com",
    "currentLocation": "Latvia, Eastvale",
    "yokeCashBalance": 53.20,
    "loyaltyPoints": 168,
    "group": "Dev Team"
  },
  {
    id: 5,
    "firstName": "Marion",
    "lastName": "Jimenez",
    "email": "Marion.Jimenez@email.com",
    "currentLocation": "Wallis and Futuna Islands, Darbydale",
    "yokeCashBalance": 87.66,
    "loyaltyPoints": 349,
    "group": "Management Team"
  },
  {
    id: 6,
    "firstName": "Ford",
    "lastName": "Morrison",
    "email": "Ford.Morrison@email.com",
    "currentLocation": "Sri Lanka, Leola",
    "yokeCashBalance": 27.78,
    "loyaltyPoints": 143,
    "group": "Dev Team"
  },
  {
    id: 7,
    "firstName": "Fox",
    "lastName": "Mccray",
    "email": "Fox.Mccray@email.com",
    "currentLocation": "Nauru, Echo",
    "yokeCashBalance": 68.40,
    "loyaltyPoints": 91,
    "group": "Dev Team"
  },
  {
    id: 8,
    "firstName": "Blanche",
    "lastName": "Wade",
    "email": "Blanche.Wade@email.com",
    "currentLocation": "Afghanistan, Jeff",
    "yokeCashBalance": 92.93,
    "loyaltyPoints": 148,
    "group": "Dev Team"
  },
  {
    id: 9,
    "firstName": "Clare",
    "lastName": "Bass",
    "email": "Clare.Bass@email.com",
    "currentLocation": "Cuba, Cucumber",
    "yokeCashBalance": 75.23,
    "loyaltyPoints": 235,
    "group": "Management Team"
  },
  {
    id: 10,
    "firstName": "Hansen",
    "lastName": "Wright",
    "email": "Hansen.Wright@email.com",
    "currentLocation": "Fiji, Haring",
    "yokeCashBalance": 14.90,
    "loyaltyPoints": 38,
    "group": "Dev Team"
  }
];

/**
 * Add new customer
 * @param customer
 */
export function addNewCustomer(customer) {
  mockedCustomers.push(customer)
}

/**
 * Update array with customers
 * @param customer
 */
export function updateCustomer(customer) {
  mockedCustomers = mockedCustomers.map(c => {
    if (c.id === customer.id)
      return customer;

    return c;
  })
}

/**
 * Remove selected customers
 * @param customers
 */
export function removeCustomers(customers) {
  mockedCustomers = mockedCustomers.filter(c => {
    return !customers.some(item => item.id === c.id)
  });
}

/**
 * Get original array
 */
export function getCustomers() {
  return mockedCustomers;
}

/**
 * Filter customers by searchQuery and (or) group filter
 * @param searchQuery
 */
export function filterCustomers(searchQuery) {
  let filteredCustomers = [...mockedCustomers];

  if (searchQuery)
    filteredCustomers = filterCustomersBySearchQuery(searchQuery, filteredCustomers);


  return filteredCustomers;
}

/**
 * Filter customers by search query
 * @param searchQuery
 * @param array
 */
function filterCustomersBySearchQuery(searchQuery, array) {
  return array.filter(item => {
    const {email, firstName, lastName} = item;

    return email.toLocaleLowerCase().includes(searchQuery.toLocaleLowerCase()) ||
      firstName.toLocaleLowerCase().includes(searchQuery.toLocaleLowerCase()) ||
      lastName.toLocaleLowerCase().includes(searchQuery.toLocaleLowerCase())
  })
}

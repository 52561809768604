import { getStartAndEndDate, getStartDateAndEndDateInISOFormat } from "./getDefaultParameters";

export function getHumanReadableDateWithTimeZone (inputDateObject = getStartAndEndDate()){
const startDateMatch = inputDateObject.match(/startDate=([^&]+)/);
const endDateMatch = inputDateObject.match(/endDate=([^&]+)/);

if (startDateMatch && endDateMatch) {
    const startDateValue = startDateMatch[1];
    const endDateValue = endDateMatch[1];
    return {startDateValue,endDateValue}
}
}
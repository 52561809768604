import axios from 'axios';
import { getLoggedUserAccountId } from './helpers/account-id';
import { getDefaultParameters_V2 } from './helpers/getDefaultParameters';

function setDefaultParameterWithoutLocation(noLocation, noOperator) {

  let paramsList = getDefaultParameters_V2(false).split('&')
  let newParamsList = [] 
  if(noLocation){
    paramsList.forEach(param => {
      if(!param.includes("location")) newParamsList.push(param);
    });
    paramsList = newParamsList;
    newParamsList = [];
  }
  if(noOperator) {
    paramsList.forEach(param => {
      if(!param.includes("operator")) newParamsList.push(param);
    })
    newParamsList.push(`operator=${getLoggedUserAccountId()}`)
    paramsList = newParamsList;
  }
  return (paramsList.join('&'))
}

export function getBillValidators({ limit = 10, page = 1 }, noLocation = false , noOperator = false, searchQuery = "") {
  let defaultParameter = Boolean(noLocation || noOperator) 
    ? setDefaultParameterWithoutLocation(noLocation, noOperator) 
    : getDefaultParameters_V2(false)
  let url = `/billvalidators?limit=${limit}&page=${page}${searchQuery ? "&keywords="+searchQuery : ""}`+defaultParameter
  return axios.get(url);
}
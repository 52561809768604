import React from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  makeStyles
} from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}));

const Filter = props => {
  const classes = useStyles();
  const {
    isFullWidth = false,
    selectedFilter,
    label,
    options,
    variant = 'outlined',
    name,
    idBased = false,
    onChange,
    className,
  } = props;

  return (
    <>
      <FormControl fullWidth={isFullWidth} variant={variant} className={clsx(classes.formControl, className)}>
        {label ? (
          <InputLabel id="filters-label">
            {label}
          </InputLabel>
        ) : null}

        <Select
          labelId="filters-label"
          id="sales-report-filters"
          name={name}
          value={selectedFilter}
          onChange={onChange}
          label={label}
        >
          {options.map(option => {
            const { id, name } = option;

            if (idBased)
              return (
                <MenuItem key={id} value={id}>
                  {name}
                </MenuItem>
              );

            return (
              <MenuItem key={id} value={name}>
                {name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </>
  );
};

export default React.memo(Filter);

import { Button } from "@material-ui/core";
import React from "react";
import { FormattedMessage } from "react-intl";

import "./PortalHelp.scss";

export default function PortalHelp() {
  return (
    <>
      <div className="help__body">
        <div className="help__form">
          <div className="help__title">
            <h3>
              <FormattedMessage id="AUTH.HELP" />
            </h3>
          </div>
          <div className="help__buttonsContainer">
            <a
              href={
                "https://cdn.yokeinc.com/files/Yoke+Terminal+Manual+-+v5.pdf"
              }
              rel="noopener noreferrer"
              target="_blank"
            >
              <Button
                className="help__button"
                variant="contained"
                color="primary"
              >
                Yoke Terminal Manual
              </Button>
            </a>
            <a
              href={
                "https://cdn.yokeinc.com/files/Yoke+Quickstart+Guide+(Kiosk).pdf"
              }
              rel="noopener noreferrer"
              target="_blank"
            >
              <Button
                className="help__button"
                variant="contained"
                color="primary"
              >
                Terminal Quick Start Guide
              </Button>
            </a>

            <a
              href={
                "https://cdn.yokeinc.com/files/Yoke+Quickstart+Guide+(Phone+App).pdf"
              }
              rel="noopener noreferrer"
              target="_blank"
            >
              <Button
                className="help__button"
                variant="contained"
                color="primary"
              >
                Phone App Quick Start Guide
              </Button>
            </a>
            <a
              href={
                "https://cdn.yokeinc.com/files/Yoke+Quickstart+Guide+(Kiosk+%2B+Phone+App).pdf"
              }
              rel="noopener noreferrer"
              target="_blank"
            >
              <Button
                className="help__button"
                variant="contained"
                color="primary"
              >
                Terminal & Phone App Quick Start Guide
              </Button>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

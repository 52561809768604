import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import * as actions from '../../store/actions';
import { LayoutSplashScreen } from '../../../_metronic';

class Logout extends Component {
  componentDidMount() {
    /**
     * removing active operator from redux
     */
    this.props.storeSelectedOperator({ id: "1", name: "All Operator" });
    // eslint-disable-next-line react/destructuring-assignment
    this.props.logout();
    this.props.resetState()
  }

  render() {
    const { hasAuthToken } = this.props;
    return hasAuthToken ? <LayoutSplashScreen/> : <Redirect to="/auth"/>;
  }
}

export default connect(
  ({ auth }) => ({ hasAuthToken: Boolean(auth.authToken) }),
  {
    logout: actions.authActions.logout ,
    resetState: actions.resetStateAction.resetState,
    storeSelectedOperator: actions.subHeaderActions.storeSelectedOperator 
  }
)(Logout);

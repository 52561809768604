import axios from 'axios';

import { currentUser } from '../classes/user';

export const LOGIN_URL = 'api/auth/login';
export const REGISTER_URL = 'api/auth/register';
export const REQUEST_PASSWORD_URL = 'users/password';

export const ME_URL = 'api/me';

export function login(email, password) {
  return axios.post(LOGIN_URL, { email, password });
}

export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function setNewPassword(id, key, password) {
  return axios.post(REQUEST_PASSWORD_URL+`/${id}`, { key, password });
}

export function getUserByToken() {
  return currentUser;
}

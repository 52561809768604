import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
  IconButton,
  makeStyles
} from '@material-ui/core';
import { EditSharp, DeleteForever } from '@material-ui/icons';

import { getComparator, getDefaultStyles, stableSort } from '../../../../../services/table';

const headCells = [
  { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
  { id: 'rate', numeric: false, disablePadding: false, label: 'Rate' },
  { id: 'actions', numeric: false, disablePadding: false, label: 'Actions' }
];

function EnhancedTableHead() {
  return (
    <TableHead  id={`sales_tax_table_header`}>
      <TableRow id={`sales_tax_table_header_row`}>
        {headCells.map(headCell => (
          <TableCell
            id={`sales_tax_table_header_cell_${headCell.id}`}
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles(getDefaultStyles);

export default function SalesTaxTable(props) {
  const classes = useStyles();
  const [order] = useState('asc');
  const [orderBy] = useState('id');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const {
    rows,
    onEditClick,
    onRemoveClick
  } = props;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    const newSize = parseInt(event.target.value, 10);
    const newP = parseInt((page * rowsPerPage + 1) / newSize) ;
    setRowsPerPage(newSize);
    setPage(newP);
  };

  return (
    <div id={`sales_tax_table_root_container`} className={classes.root}>
      <Paper id={`sales_tax_table_paper_container`} className={classes.paper}>
        <TableContainer id={`sales_tax_table_container_wrapper`}>
          <Table
            id={`sales_tax_table_inner_container`}
            className={classes.table}
            aria-labelledby="tableTitle"
            size="small"
            aria-label="enhanced table"
          >
            <EnhancedTableHead/>
            <TableBody id={`sales_tax_table_body_container`}>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(row => {
                  const { id, name, rate } = row;

                  return (
                    <TableRow
                      id={`sales_tax_table_row_${id}`}
                      hover
                      key={id}
                    >
                      <TableCell   id={`sales_tax_table_name_cell_${id}`} align="left" padding="normal">
                        {name}
                      </TableCell>
                      <TableCell  id={`sales_tax_table_rate_cell_${id}`} align="left" padding="normal">
                        {rate}
                        %
                      </TableCell>
                      <TableCell  id={`sales_tax_table_button_cell_wrapper_${id}`} align="left" padding="normal">
                        <IconButton  id={`sales_tax_table_icon_button_cell_${id}`} onClick={onEditClick}>
                          <EditSharp/>
                        </IconButton>
                        <IconButton   id={`sales_tax_table_delete_icon_button_cell_wrapper_${id}`}onClick={onRemoveClick}>
                          <DeleteForever/>
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          id={`sales_tax_table_pagination`}
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}

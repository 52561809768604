/* eslint-disable no-script-url */
import React, { useRef } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';

import HeaderDropdownToggle from '../content/CustomDropdowns/HeaderDropdownToggle';
import { currentUser } from '../../classes/user';
import { toAbsoluteUrl } from '../../../_metronic';

export default function UserProfile(props) {
  const {
    showHi,
    showAvatar,
    showBadge
  } = props;
  const history = useHistory();
  const toggleDropdown = useRef(null);

  return (
    <Dropdown className="kt-header__topbar-item kt-header__topbar-item--user" drop="down" alignRight>
      <Dropdown.Toggle
        as={HeaderDropdownToggle}
        id="dropdown-toggle-user-profile"
        ref={toggleDropdown}
      >
        <div className="kt-header__topbar-user"
        >
          {showHi && (
            <span className="kt-header__topbar-welcome kt-hidden-mobile">
              Hi,
            </span>
          )}

          {showHi && (
            <span className="kt-header__topbar-username kt-hidden-mobile">
                {currentUser?.fullName}
            </span>
          )}

          {showAvatar && <img alt="Pic" src={currentUser?.image}/>}

          {showBadge && (
            <span
              className="
                kt-badge
                kt-badge--username
                kt-badge--unified-success
                kt-badge--lg
                kt-badge--rounded
                kt-badge--bold
              "
            >
              <i className="kt-menu__link-icon flaticon2-user"/>
              {currentUser?.fullName}
            </span>
          )}
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu
        className="
          dropdown-menu-fit
          dropdown-menu-right
          dropdown-menu-anim
          dropdown-menu-top-unround
          dropdown-menu-xl
        "
      >
        <div
          className="kt-user-card kt-user-card--skin-dark kt-notification-item-padding-x"
          style={{
            backgroundImage: `url(${toAbsoluteUrl('/media/misc/bg-1.jpg')})`
          }}
        >
          <div className="kt-user-card__avatar">
            <img alt="Pic" className="kt-hidden" src={currentUser?.image}/>
            <span className="kt-badge kt-badge--lg kt-badge--rounded kt-badge--bold kt-font-success">
              {/* eslint-disable-next-line camelcase */}
              {currentUser?.first_name[0]}
              {/* eslint-disable-next-line camelcase */}
              {currentUser?.last_name[0]}
            </span>
          </div>
          <div className="kt-user-card__name">{currentUser?.fullName}</div>
        </div>
        <div className="kt-notification">
          <div className="kt-notification__item"
            onClick={() => {
              if(toggleDropdown?.current){
                toggleDropdown.current.click();
              }
              history.push(`/setup/users/${currentUser?.id}`);
            }}
          >
            <div className="kt-notification__item-icon">
              <i className="flaticon2-calendar-3 kt-font-success"/>
            </div>
            <div className="kt-notification__item-details">
              <div className="kt-notification__item-title kt-font-bold">
                My Profile
              </div>
              <div className="kt-notification__item-time">
                Account settings and more
              </div>
            </div>
          </div>
          <div className="kt-notification__item" 
            onClick={() => {
              if(toggleDropdown?.current){
                toggleDropdown.current.click();
              }
              history.push("/help");
            }}
          >
            <div className="kt-notification__item-icon">
              <i className="flaticon2-information kt-font-success"/>
            </div>
            <div className="kt-notification__item-details">
              <div className="kt-notification__item-title kt-font-bold">
                Help
              </div>
              <div className="kt-notification__item-time">
                FAQ, Support Articles & Contact
              </div>
            </div>
          </div>
          <div className="kt-notification__custom">
            <Link
              to="/logout"
              className="btn btn-label-brand btn-sm btn-bold"
            >
              {' '}
              Sign Out
              {' '}
            </Link>
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}

import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
  makeStyles,
  IconButton,
} from "@material-ui/core";
import { EditSharp, Remove } from "@material-ui/icons";

import { getDefaultStyles } from "../../../../../services/table";

const headCells = [
  { id: "name", numeric: false, disablePadding: false, label: "Operator Name" },
  {
    id: "accountId",
    numeric: false,
    disablePadding: false,
    label: "Account ID",
  },
  {
    id: "contact",
    numeric: false,
    disablePadding: false,
    label: "Contact User",
  },
  {
    id: "inventorySystem",
    numeric: false,
    disablePadding: false,
    label: "Inventory System",
  },
  {
    id: "creditCardProcessor",
    numeric: false,
    disablePadding: false,
    label: "Credit Card Processor",
  },
  {
    id: "loyaltyProgram",
    numeric: false,
    disablePadding: false,
    label: "Loyalty Program",
  },
  { id: "notes", numeric: false, disablePadding: false, label: "Notes" },
  { id: "actions", numeric: false, disablePadding: false, label: "Actions" },
];

function EnhancedTableHead() {
  return (
    <TableHead id={`operator_table_header`}>
      <TableRow id={`operator_table_header_row`}>
        {headCells.map((headCell) => (
          <TableCell
            id={`operator_table_header_cell_${headCell.id}`}
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles(getDefaultStyles);

export default function OperatorsTable(props) {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { rows, totalCount, onEditRowClick, pagination, onPaginationChange } = props;

  function handlePaginationChange({ target: { value } }, newPage = -1) {
    if (value) {
      const newSize = parseInt(value, 10);
      const newP = parseInt((page * rowsPerPage + 1) / newSize);

      onPaginationChange({
        page: newP + 1,
        limit: newSize,
      });
      setPage(newP);
      setRowsPerPage(newSize);
    }

    if (newPage >= 0 && typeof newPage !== "object") {
      onPaginationChange({
        page: newPage + 1,
        limit: rowsPerPage,
      });
      setPage(newPage);
    }
  }

  /**
   * Handle click on Edit icon
   * @param e
   * @param row
   */
  function onEditIconClick(e, row) {
    e.stopPropagation();
    onEditRowClick(row);
  }

  return (
    <div id={`operator_table_root_wrapper`} className={classes.root}>
      <Paper id={`operator_table_paper_wrapper`} className={classes.paper}>
        <TableContainer id={`operator_table_container_wrapper`}>
          <Table
            id={`operator_table_inner_wrapper`}
            className={classes.table}
            aria-labelledby="tableTitle"
            size="small"
            aria-label="enhanced table"
          >
            <EnhancedTableHead />
            <TableBody id={`operator_table_body_wrapper`}>
              {rows.map(row => {
                const {
                  id,
                  name,
                  firstName,
                  lastName,
                  provider: { name: inventoryManagementSystem } = {name: ""},
                  processor: { name: paymentProcessor } = {name: ""},
                  loyaltyProgram,
                  notes,
                } = row;

                return (
                  <TableRow id={`operator_table_row_${id}`} hover key={id} tabIndex={-1}>
                    <TableCell id={`operator_table_name_cell_${id}`} align="left" padding="normal">
                      {name}
                    </TableCell>
                    <TableCell id={`operator_table_id_cell_${id}`}  align="left" padding="normal">
                      {id}
                    </TableCell>
                    <TableCell id={`operator_table_full_name_cell_${id}`}  align="left" padding="normal">
                      {firstName && lastName
                        ? `${firstName} ${lastName}`
                        : "N/A"}
                    </TableCell>
                    <TableCell id={`operator_table_inventory_cell_${id}`}  align="left" padding="normal">
                      {!inventoryManagementSystem
                        ? "N/A"
                        : inventoryManagementSystem}
                    </TableCell>
                    <TableCell id={`operator_table_payment_processor_cell_${id}`}  align="left" padding="normal">
                      {!paymentProcessor ? "N/A" : paymentProcessor}
                    </TableCell>
                    <TableCell id={`operator_table_loyalty_program_cell_${id}`}  align="left" padding="normal">
                      {!loyaltyProgram ? "N/A" : loyaltyProgram == 'globalConnect' ? 'Global Connect' : 'Yoke'}
                    </TableCell>
                    <TableCell id={`operator_table_notes_cell_${id}`}  align="left" padding="normal">
                      {notes || <Remove />}
                    </TableCell>
                    <TableCell id={`operator_table_icon_button_cell_${id}`}  align="left" padding="normal">
                      <IconButton onClick={(e) => onEditIconClick(e, row)}>
                        <EditSharp />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          id={`operator_table_pagination`}
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handlePaginationChange}
          onRowsPerPageChange={handlePaginationChange}
        />
      </Paper>
    </div>
  );
}

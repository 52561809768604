import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import to from 'await-to-js';
import clsx from 'clsx';
import * as Sentry from "@sentry/react";

import { accessPermission } from 'app/common/helperFunction';
import LoadingSpinner from 'app/partials/content/LoadingSpinner';
import { useCustomResponse } from 'app/hooks/useCustomResponse';
import { CANCEL_TOKEN } from "app/common/constant";

import Search from '../../../../partials/content/Customers/Search';
import ExportAndCustomFilters from '../../../../partials/content/ExportAndCustomFilters';
import StockControlTable from './components/StockControlTable/StockControlTable';
import { createPrekit, getInventoryStocks } from '../../../../services/products';
import ResponseModal  from '../../../Helpers/ResponseModal';
import StockModal from './components/StockControlTable/components/StockModal/StockModal';

import styles from './stock-control.module.scss';

export default function StockControl(props) {
  const {push}  = useHistory();
  const { filter: { locations } } = props;
  const [stocks, setStocks] = useState([]);
  const [totalStocks, setTotalStocks] = useState(0);
  const [stockModalOpen, setStockModalOpen] = useState();
  const [stockModalData, setStockModalData] = useState({id: 0, count:0});
  const [searchQuery, setSearchQuery] = useState();
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10
  });
  const [isLoaded, setIsLoaded] = useState(false);
  const [responseValue, setResponseValue] = useCustomResponse();

  useEffect(() => {
    if(!accessPermission("ProductsAll")){
      push({pathname: "/dashboard"});
    }
  }, []);

  /**
   * Fetch data on component mounting
   */
  useEffect(() => {
    if (locations && locations.length)
      getStockList();
  }, [pagination, searchQuery]);

  /**
   * Get stock control information
   */
  async function getStockList() {
    const [err, res] = await to(getInventoryStocks(pagination, searchQuery));
   
    setIsLoaded(true);
    if (err){
      Sentry.captureException(err);
      if(err.message === CANCEL_TOKEN) return;
      setResponseValue({ ...responseValue, isMessageOpen: true, isSuccess:false, message:`Error while getting stock list. ${err}`});
      return console.error('Stocks data not fetched: ', err);
    }
    const { locations, meta: { totalItems } } = res.data;
    setStocks(locations.map(l => ({ ...l, isExpanded: false })));
    setTotalStocks(totalItems);
  }

  /**
   * Expand row
   * @param id
   */
  function expandRow(id) {
    const updateStocks = stocks.map(stock => {
      if (stock && stock.id === id)
        return {
          ...stock,
          isExpanded: !stock.isExpanded
        };

      return stock;
    });

    setStocks(updateStocks);
  }

  
  /**
   * Create prekit item
   */
  async function handlePrekit(id) {
    const [err, res] = await to(createPrekit(id))

    if (err) {
      Sentry.captureException(err);
      setResponseValue({...responseValue, isMessageOpen: true, isSuccess:false, message:`Something went wrong creating pick.\n${err}`});
      return console.log(err)
    }
    
    setStockModalData({id: res.data.id, count: res.data.numberOfProducts})
    setStockModalOpen(true)
      const updateStocks = stocks.map(stock => {
        if (stock && stock.id === id)
        stock.prekits.push(res.data)
          return {
            ...stock,
          };
      });
      setStocks(updateStocks);
  }
  
  function handleClose() {
    setResponseValue({...responseValue, isMessageOpen:false})
    setStockModalOpen(false)
  }

  return (
    <>
      <ExportAndCustomFilters hasFilters={false} hideExport/>
      {!isLoaded ? (
        <LoadingSpinner/>
      ) : (
      <>
        <div  id={`stock_control_wrapper`} className={clsx(styles.searchWrapper, 'd-flex', 'justify-content-between')}>
        <div id={`stock_inner_control_wrapper`} className="d-flex justify-content-between">
          <div id={`stock_control_search_wrapper`} className="col-4">
            <Search
              id="stockControl"
              iconPosition="start"
              label="Search for Locations"
              placeholder="Search"
              query={searchQuery}
              onChange={e => setSearchQuery(e.target.value)}
            />
          </div>
        </div>
        </div>
        <div id={`stock_control_table_wrapper`} className={styles.addSpace}></div>
        <StockControlTable
          rows={stocks}
          pagination={pagination}
          totalStocks={totalStocks}
          onExpandRow={e => expandRow(e)}
          onPaginationChange={value => setPagination(value)}
          onPrekit={e => handlePrekit(e)}
        />
        <ResponseModal
          open={responseValue?.isMessageOpen}
          isSuccess={responseValue?.isSuccess}
          message={responseValue?.message}
          onClose={handleClose}
        />
        <StockModal
          open={stockModalOpen}
          modalData={stockModalData}
          onClose={handleClose}
        />
      </>)}
    </>
  );
}

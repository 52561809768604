import React, { useState, useEffect } from "react";
import to from "await-to-js";
import clsx from "clsx";

import {
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import "jspdf-autotable";
import { jsPDF } from "jspdf";
import * as Sentry from "@sentry/react";

import LoadingSpinner from "app/partials/content/LoadingSpinner";
import { accessPermission } from "app/common/helperFunction";
import {
  getLoggedUserAccountId2,
  hasMultipleAccounts,
} from "app/services/helpers/account-id";
import { CANCEL_TOKEN, CUSTOMERS_REPORT_NAME_PREFIX } from "app/common/constant";

import ExportAndCustomFilters from "../../../../partials/content/ExportAndCustomFilters";
import CustomersTable from "../../../../partials/content/Customers/CustomersTable";
import Search from "../../../../partials/content/Customers/Search";
import Filter from "../../../../partials/content/Filter";
import IssueYokeCredit from "../../../../partials/content/Customers/IssueYokeCredit";
import { getCustomerReports, getCustomers, reportFormats } from "../../../../services/reports";
import { inviteUsers } from "../../services/customers";
import { getUserGroups } from "../../../../services/groups";
import OperatorSpecificModal from "../../../Helpers/OperatorSpecificModal";
import ResponseModal from "../../../Helpers/ResponseModal";
import { customerActions } from "../../../../store/actions";

import styles from "../../../../../_metronic/_assets/sass/pages/customers.module.scss";
import { useCustomResponse } from "app/hooks/useCustomResponse";
import { generateFileNameForReport, triggerReportDownload } from "app/services/helpers/reportDownload";
import { NO_PDF } from "app/common/giftCardsConst";


let searchTimeout = null;

export default function Customers() {
  const { push } = useHistory();
  const dispatch = useDispatch();
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
  });
  const [customersList, setCustomersList] = useState({customers: [], customersTotal: 0});
  const [filters, setFilters] = useState([]);
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [selectedGroupsFilter, setSelectedGroupsFilter] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [isModalOpen, setModalOpen] = useState(false);
  const [operatorSpecificModalOpen, setOperatorSpecificModalOpen] = useState(false);
  const [isLoaded, setIsLoaded] = useState(true);
  const [responseValue, setResponseValue] = useCustomResponse();
  /**
   * Get all customers
   */
  useEffect(() => {
    if(!accessPermission("CustomersAll")){
      push({pathname: "/dashboard"});
    }
    if(hasMultipleAccounts()){
      setOperatorSpecificModalOpen(true);
      return
    }
    getGroupsForFilters();
  }, []);

  /**
   * Get all customers
   */
  useEffect(() => {
    if(hasMultipleAccounts()){
      setOperatorSpecificModalOpen(true);
      return
    }
    getCustomersData(searchQuery, selectedGroupsFilter);
  }, [pagination, searchQuery, selectedGroupsFilter]);

  /**
   * Async function to get All Groups for filters
   */
  async function getGroupsForFilters() {

    const id = getLoggedUserAccountId2();
    const [err, res] = await to(getUserGroups(id, { limit: 100, page: 1 }));
    if (err) {
      Sentry.captureException(err);
      setResponseValue({
        ...responseValue,  
        isMessageOpen: true,
        isSuccess: false,
        message: `We had trouble grabbing your customers groups.\nError: ${err}`
      });
      return console.error("[Groups]. Error while fetching data.");
    }
    setFilters([
      { id: 0, name: "All Groups", description: "" },
      ...res?.data?.groups,
    ]);

    if (!selectedGroupsFilter) setSelectedGroupsFilter(0);
  }

  /**
   * Get all customers
   * @returns {Promise<void>}
   */
  async function getCustomersData(search, groupFilter) {
    setIsLoaded(false);
    const [err, res] = await to(getCustomers(pagination, search.trim(), groupFilter));
    if (err) {
      if(err.message === CANCEL_TOKEN)return;
      Sentry.captureException(err);
      setResponseValue({
        ...responseValue,  isMessageOpen: true,
        isSuccess: false,
        message: `We had trouble grabbing your customers.\nError: ${err}`
      });
      return console.error(`[Customers]. Error while fetching data. ${err}`);
    }
    var users = res.data.users;
    const formatedUsers = formatUsersInfo(users);
    dispatch(customerActions.storeCustomers(formatedUsers));
    setCustomersList({customers: formatedUsers, customersTotal: res.data.meta.totalItems});
    setIsLoaded(true);
  }

  function formatUsersInfo(users) {
    const uniformatUser = [];
    users.forEach((ele) => {
      uniformatUser.push(ele.user ? ele.user : ele);
    })
    return uniformatUser;
  }
  /*
   * Handle export
   */
  async function handleExport(type) {
    
    if(customersList.customers.length === 0 ) return;

    const {customers: rows} = customersList;
    if (type === reportFormats.PDF) {
      const exportRows = rows.map((ele) => [
        ele.email ? ele.email : "",
        ele.firstName ? ele.firstName : "",
        ele.lastName ? ele.lastName : "",
        ele.funds ? `$ ${ele.funds[0].balance?.toFixed(2)}` || "$ 0.00" : "$ 0.00",
        ele.points ? `${ele.points[0].balance?? "0"} points` : "0 points",
      ]);

      const unit = "pt";
      const size = "A4";
      const orientation = "portrait";
      const marginLeft = 30;
      const doc = new jsPDF(orientation, unit, size);
      doc.setFontSize(15);

      const title = "Customers";
      const headers = [
        [
          "Email",
          "First Name",
          "Last Name",
          "Yoke Cash Balance",
          "Loyalty Points",
        ],
      ];

      let content = {
        startY: 50,
        head: headers,
        body: exportRows,
        styles: { overflow: "linebreak", fontSize: 8 },
      };

      doc.text(title, marginLeft, 30);
      doc.autoTable(content);
      doc.save(generateFileNameForReport(type,CUSTOMERS_REPORT_NAME_PREFIX));
    } else {
      getCustomerReports(type,selectedGroupsFilter) .then((response) => {
       triggerReportDownload(response,type,CUSTOMERS_REPORT_NAME_PREFIX)
      }) .catch((err) => {
        Sentry.captureException(err)
        setResponseValue({
          ...responseValue,  
          isMessageOpen: true,
         isSuccess:false,
        message:`We had a problem downloading Customers Data`
      });
        console.error("An error occurred:", err);
      });
    }
  }

  /**
   * Prepare data for customer details before navigation
   */
  function handleEditCustomerClick(e) {
    push({ pathname: `/customers/${e.id}` });
    dispatch(customerActions.selectActiveCustomer(e.id));
  }

  /**
   * Add new cash
   */
  function onIssueYokeCash() {
    getCustomersData(searchQuery, selectedGroupsFilter);
  }

  const handleFileInput = async (e) => {
    if(!e.target.files[0]?.name.includes(".csv")){
      setResponseValue({
        ...responseValue,
        isMessageOpen: true,
        isSuccess:false,
        isServerError:false,
        message: `Invalid file uploaded`
      });
      return ;
    }
    const [error, invites] = await to(
      inviteUsers(e.target.files[0], getLoggedUserAccountId2())
    );
    if(error) {
      if(error.response.status === 400){
        setResponseValue({
          ...responseValue,
          isMessageOpen: true,
          isSuccess:false,
          isServerError:false,
          message: error.response.data.message
        });
        return;
      }
      setResponseValue({
        ...responseValue,  
        isMessageOpen: true,
        isSuccess:false,
        message: `We had trouble in uploading file.\nError: ${error}`
      });
      return console.error(`We had trouble in uploading file. ${error}`);
    }
    if (invites.status == 201) {
      const invitesLength = invites.data?.data?.invited?.length
      if (invitesLength) {
        setResponseValue({
          ...responseValue,  
          isMessageOpen: true,
          isSuccess:true,
          message: `Sent ${invitesLength} invites.`,
        });
        return;
      }
      else {
        setResponseValue({
          ...responseValue,  
          isMessageOpen: true,
          message: invites?.data?.message
        });
        return;
      }
    } else {
      setResponseValue({
        ...responseValue,  
        isMessageOpen: true,
        isSuccess: false,
        message: `We had trouble inviting these customers.\nError: ${error}`
      });
      return;
    }
  };

  return (
    <>
      <ExportAndCustomFilters newFilterTag = {reportFormats.XLS}  onExport={handleExport}/>
      <div
        id="customer_container_wrapper"
        className={clsx(
          styles.searchWrapper,
          "d-flex",
          "justify-content-between",
          "align-items-center"
        )}
      >
        <div id="customer_search_wrapper" className="d-flex">
          <Search
            id="customers_search_field"
            query={searchQuery}
            onChange={(e) => {
              const target = e.target;
              if (searchTimeout) {
                clearTimeout(searchTimeout);
                searchTimeout = null;
              }
              searchTimeout = setTimeout(() => {
                setSearchQuery(target.value);
              }, 400);
            }}
            onKeyDown={(e) => {
              const value = e.target.value;
              if(value.charAt(value.length-1) === " " && e.keyCode === 32) {
                e.preventDefault();
              }
            }}
          />

          <Filter
            label="Group"
            options={filters}
            idBased={true}
            selectedFilter={selectedGroupsFilter}
            onChange={(e) => setSelectedGroupsFilter(e.target.value)}
          />
        </div>
        
        <div
          id="customers_inner_wrapper"
          className={clsx(
            styles.searchWrapper,
            "align-items-right"
          )}
        >
          <IssueYokeCredit
            selectedCustomers={selectedCustomers}
            onUpdate={onIssueYokeCash}
            groupsList={filters}
          />

          <ButtonGroup>
            <input
              accept="csv/*"
              style={{ display: "none", marginLeft:"20px" }}
              id="raised-button-file"
              type="file"
              onChange={handleFileInput}
            />
            <label id="customers_upload_invite_csv_label" htmlFor="raised-button-file">
              <Button
                id="customers_upload_invite_csv_button"
                style={{margin:"0 20px"}}
                size="large"
                variant="outlined"
                color="secondary"
                disableElevation
                component="span"
              >
                Upload Invite CSV
              </Button>
            </label>
          </ButtonGroup>

          <Button
            id="customers_upload_create_group_button"
            size="large"
            variant="contained"
            color="primary"
            disableElevation
            onClick={() => push({ pathname: "/groups/new" })}
          >
            Create Group
          </Button>
        </div>
      </div>

      {!isLoaded ? (
        <LoadingSpinner/>
      ) : (
        <>
          <div id="customers_customers_tables_wrapper" className={styles.wrapper}>
            <CustomersTable
              customersList={customersList}
              pagination={pagination}
              onSelectCustomers={(e) => setSelectedCustomers(e)}
              onEditRowClick={handleEditCustomerClick}
              onPaginationChange={setPagination}
            />
          </div>
        </>
      )}
      {operatorSpecificModalOpen && (
        <OperatorSpecificModal
          open={operatorSpecificModalOpen}
          onClose={(value) => setOperatorSpecificModalOpen(value)}
        />
      )}
      <ResponseModal
        isSuccess={responseValue?.isSuccess}
        message={responseValue?.message}
        open={responseValue?.isMessageOpen}
        isServerError={responseValue?.isServerError}
        onClose={(value) => setResponseValue({...responseValue, isMessageOpen: value})}
      />
      {isModalOpen ? (
        <Dialog
        id="customers_dialog_wrapper"
          open
          onClose={() => setModalOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Invited!</DialogTitle>
          <DialogContent   id="customers_dialog_content" className="d-flex flex-column">
            {responseValue?.message}
          </DialogContent>
          <DialogActions id="customers_dialog_action_wrapper">
            <Button id="customers_dialog_action_close_button" onClick={() => setModalOpen(false)} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
    </>
  );
}


import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import * as builder from '../../ducks/builder';
import { toAbsoluteUrl } from '../../utils/utils';

function Brand(props) {
  const { brandClasses } = props;

  return (
    <div
      className={`kt-aside__brand kt-grid__item ${brandClasses}`}
      id="kt_aside_brand"
    >
      <div className="kt-aside__brand-logo">
        <Link to="">
          <img alt="logo" src={toAbsoluteUrl('/media/logos/logo-4.png')}/>
          <div className='kt-aside__logo_text'>Home</div>
        </Link>
      </div>
    </div>
  );
}

const mapStateToProps = store => ({
  brandClasses: builder.selectors.getClasses(store, {
    path: 'brand',
    toString: true
  })
});

export default connect(mapStateToProps)(Brand);

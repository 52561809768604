import React from 'react';
import clsx from 'clsx';
import { Divider, Typography } from '@material-ui/core';

import styles from './dea-type-button.module.scss';

export default function DealTypeButton(props) {
  const {
    title,
    description,
    variant,
    value,
    hasDivider,
    isActive,
    handleClick
  } = props;

  return (
    <div
      id={`dealDetails_button_type_wrapper`}
      className={clsx(
        'text-center',
        styles.card,
        { [styles.active]: isActive }
      )}
      onClick={() => handleClick(value)}
    >
      <Typography id={`dealDetails_button_type_wrapper_title`} variant={variant}>
        {title}
      </Typography>
      {hasDivider ? <Divider className={styles.divider}/> : null}
      {description ? (
        <Typography id={`dealDetails_button_type_wrapper_description`} variant="subtitle1" color="textSecondary">
          {description}
        </Typography>
      ) : null}
    </div>
  );
}

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { addDays } from 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  Card,
  CardContent,
  Divider,
  FormControl,
  TextField,
  InputLabel,
  Select,
  Typography,
  MenuItem,
  Button,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import { Close, Check } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';

import { accessPermission } from 'app/common/helperFunction';

import PromotionType from './components/PromotionType/PromotionType';
import TargetPromotion from './components/TargetPromotion/TargetPromotion';
import useCustomHeader from '../../../../hooks/useCustomHeader';
import * as fromActions from '../../../../store/actions';

import styles from './promotion-details.module.scss';

const emptyPromotion = {
  priceAdjustMaxUnits: null,
  actionLimits: {
    amount: '1',
    quantity: 1
  },
  name: '',
  description: '',
  tags: null,
  couponCode: null,
  canEarnLoyalty: false,
  status: 'active',
  startDate: new Date(),
  endDate: new Date(),
  targetAudienceType: '',
  frequencyMetric: '',
  frequencyQuantity: 1,
  frequencyMaxUse: 99999,
  maxUse: null,
  maxTotalUse: null,
  maxTotalUseCounter: null,
  promoOverlap: '',
  conditionTargetType: '',
  thresholdAmount: '0',
  thresholdQuantity: 0,
  actionTargetType: '', // 'ProductAny' | 'AllChart'
  actionTargetLimitAmount: '1',
  actionTargetLimitQuantity: 1,
  discountType: 'monetaryAbsolute', // 'monetaryAbsolute'
  discountAmount: '1',
  discountAmountCurrency: 'USD',
  combo: null,
  priceAdjustMinBundleSize: null,
  priceAdjustUnitAmount: null,
  priceAdjustBundleStep: null,
  parentDealId: null,
  prevDealId: null,
  nextDealId: null,
  creationDate: new Date().toISOString(),
  deletionDate: null,
  locations: [],
  products: []
};

const oldPromotion = {
  general: {
    name: '',
    description: '',
    startDate: new Date(),
    endDate: addDays(new Date(), 1)
  },
  promotionType: {
    selectedType: 'basic',
    basic: {
      discountType: '%',
      discountValue: '',
      productType: 'specific',
      productsFilter: {
        filter: {
          label: 'Include',
          value: 'include'
        },
        products: [{
          label: 'Water',
          value: 'water'
        }, {
          label: 'Coca-Cola',
          value: 'coca-cola'
        }]
      }
    },
    advanced: {
      whenFilter: {
        label: 'Buys the following items',
        value: 'buys'
      },
      thenFilter: {
        label: 'Get the following items',
        value: 'get'
      },
      quantity: 0,
      productType: 'specific',
      productsFilter: {
        filter: {
          label: 'Include',
          value: 'include'
        },
        products: [{
          label: 'Water',
          value: 'water'
        }]
      },
      basic: {
        discountType: 'free',
        discountValue: '',
        productType: 'specific',
        productsFilter: {
          filter: {
            label: 'Include',
            value: 'include'
          },
          products: [{
            label: 'Water',
            value: 'water'
          }]
        }
      }
    }
  },
  promotionTarget: {
    targetType: 'exclusive',
    exclusiveOptions: {
      type: 'promo-code',
      promoCodes: [{
        id: 1,
        code: '',
        number: ''
      }]
    }
  },
  offerLoyalty: false
};

export function PromotionDetails({
  selectedPromotion,
  selectActivePromotion,
  clearSelectedPromotion
}) {
  const { id, isNew } = useCustomHeader({
    property: 'name',
    getRelatedItem: () => selectedPromotion,
    isCreationAllowed: true,
    createNew: 'Promotion'
  });
  const { push } = useHistory();
  const [promotion, setPromotion] = useState(oldPromotion);
  const [deal, setDeal] = useState(isNew ? emptyPromotion : selectedPromotion);

  useEffect(() => {
    if(!accessPermission("PromosAndCouponsAll")){
      push({pathname: "/dashboard"});
    }
  }, []);

  /**
   * Change subheader text
   */
  useEffect(() => {
    selectActivePromotion(id);

    return () => {
      clearSelectedPromotion();
    };
  }, [id]);

  /**
   * Change general section property
   */
  function changeDealProperty({ target: { value, name } }) {
    setDeal({
      ...deal,
      [name]: value
    });
  }

  /**
   * Change promotion type
   */
  function changePromotionType(value, property) {
    setPromotion({
      ...promotion,
      promotionType: {
        ...promotion.promotionType,
        [property]: value
      }
    });
  }

  /**
   * Change promotion target
   */
  function changePromotionTarget(value, property) {
    setPromotion({
      ...promotion,
      promotionTarget: {
        ...promotion.promotionTarget,
        [property]: value
      }
    });
  }

  return (
    <Card id={`promotiond_details_card_wrapper`}>
      <CardContent id={`promotiond_details_card_content_wrapper_0`} className={clsx('d-flex', 'justify-content-end', styles.headerWrapper)}>
        <Button
          id={`promotiond_details_cancel_button`}
          className={styles.cancelBtn}
          startIcon={<Close/>}
          type="button"
          size="medium"
          variant="outlined"
          color="secondary"
          disableElevation
          onClick={() => push({ pathname: '/promotions' })}
        >
          Cancel
        </Button>
        <Button
          id={`promotiond_details_save_button`}
          startIcon={<Check/>}
          type="button"
          size="medium"
          variant="contained"
          color="primary"
          disableElevation
          onClick={() =>{}}
        >
          Save
        </Button>
      </CardContent>

      <CardContent id={`promotiond_details_card_content_wrapper_1`}>
        <Typography id={`promotiond_details_general_typography`} variant="h5">General</Typography>
        <Divider/>
        <div id={`promotiond_details_general_form_outer_wrapper`} className={clsx('row', styles.rowWrapper)}>
          <div id={`promotiond_details_general_form_inner_wrapper`} className="col-8">
            <div id={`promotiond_details_general_form_sub_container_0`} className="row">
              <div id={`promotiond_details_general_form_control_wrapper_0`} className="col-5">
                <FormControl id={`promotiond_details_general_personal_detail`} fullWidth>
                  <TextField
                    inputProps={{
                      maxLength: 30
                    }}
                    id="promotionName"
                    name="name"
                    label="Promotion Name"
                    placeholder="Enter a Promotion Name"
                    helperText="Max Characters: 30"
                    value={deal.name}
                    onChange={changeDealProperty}
                    variant="outlined"
                  />
                </FormControl>
              </div>
              <div  id={`promotiond_details_general_form_control_wrapper_1`} className="col-7">
                <FormControl  id={`promotiond_details_general_description`} fullWidth>
                  <TextField
                    inputProps={{
                      maxLength: 70
                    }}
                    id="shortDescription"
                    name="description"
                    label="Short Description"
                    placeholder="Provide a Short Description to explain this product"
                    helperText="Max Characters: 70"
                    value={deal.description}
                    onChange={changeDealProperty}
                    variant="outlined"
                  />
                </FormControl>
              </div>
            </div>
            <div id={`promotiond_details_general_form_picker_container`} className={clsx('row', styles.rowWrapper)}>
              <div id={`promotiond_details_general_form_picker_wrapper_0`}  className="col-4">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disablePast={isNew}
                    className={styles.noMargin}
                    views={['date', 'month' ,'year']}
                    disableToolbar
                    inputVariant="outlined"
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="start-date"
                    name="startDate"
                    label="Start Date"
                    autoOk
                    KeyboardButtonProps={{
                      'aria-label': 'change date'
                    }}
                    value={deal.startDate}
                    onChange={changeDealProperty}
                  />
                </MuiPickersUtilsProvider>
              </div>
              <div id={`promotiond_details_general_form_picker_wrapper_1`}  className="col-4">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disablePast={isNew}
                    className={styles.noMargin}
                    views={['date', 'month' ,'year']}
                    disableToolbar
                    inputVariant="outlined"
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="end-date"
                    name="endDate"
                    label="End Date"
                    autoOk
                    KeyboardButtonProps={{
                      'aria-label': 'change date'
                    }}
                    value={deal.endDate}
                    onChange={changeDealProperty}
                  />
                </MuiPickersUtilsProvider>
              </div>
              <div id={`promotiond_details_general_form_picker_wrapper_2`}  className="col-4">
                <FormControl id={`promotiond_details_general_form_control_outlet_container`}  variant="outlined" fullWidth>
                  <InputLabel id="outlet">Outlet</InputLabel>
                  <Select
                    labelId="outlet"
                    id="outlet"
                    value={1}
                    onChange={() => {}}
                    label="Outlet"
                  >
                    <MenuItem value={1}>All Outlets</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
          <div id={`promotiond_details_promotional_form_container`}  className="col-4">
            <div id={`promotiond_details_promotional_form_inner_container`} className={styles.preview}>
              <div id={`promotiond_details_promotional_icon_wrapper`}  className="d-flex">
                <i className="fa fa-ticket-alt"/>
                <div  id={`promotiond_details_promotional_name_wrapper`}  className={clsx('d-flex', 'flex-column', styles.title)}>
                  <Typography  id={`promotiond_details_promotional_name_typography`}  variant="h5">
                    {deal.name ? deal.name : 'Promotion Name'}
                  </Typography>
                  <Typography id={`promotiond_details_promotional_description_typography`}  variant="subtitle1" color="textSecondary">
                    {deal.description
                      ? deal.description
                      : 'Provide a short description to explain this promotion.'}

                  </Typography>
                </div>
              </div>
              <Divider/>
              <Typography id={`promotiond_details_promotional_content_typography`}  className={styles.caption} variant="caption" color="textSecondary">
                The name and description will be shown to both cashiers and customers,
                to identify and explain the promotion
              </Typography>
            </div>
          </div>
        </div>
      </CardContent>

      <CardContent id={`promotiond_details_promotional_type_wrapper`} >
        <Typography id={`promotiond_details_promotional_type_label`} variant="h5">Type of Promotion</Typography>
        <Divider/>
        <PromotionType {...promotion.promotionType} changePromotionType={changePromotionType}/>
      </CardContent>

      <CardContent id={`promotiond_details_promotional_target_wrapper`}>
        <Typography  id={`promotiond_details_promotional_target_label`} variant="h5">Target this Promotion</Typography>
        <Divider/>
        <TargetPromotion {...promotion.promotionTarget} changePromotionTarget={changePromotionTarget}/>
      </CardContent>

      <CardContent  id={`promotiond_details_promotional_offer_wrapper`}>
        <Typography  id={`promotiond_details_promotional_offer_label`} variant="h5">Offer Loyalty with Promotion1</Typography>
        <Divider/>
        <div  id={`promotiond_details_promotional_description_wrapper`} className={styles.loyalty}>
          <div id={`promotiond_details_promotional_description_inner_wrapper`} className="d-flex align-items-center">
            <div id={`promotiond_details_promotional_description_content_wrapper`} className="col-3">
              <Typography id={`promotiond_details_promotional_description`} variant="body1">
                Choose to offer or not offer Loyalty to your customers as part of this promotion.
              </Typography>
            </div>
            <div id={`promotiond_details_promotional_checkbox_description_wrapper`} className="col-9">
              <FormControlLabel
                id={`promotiond_details_promotional_checkbox_form_control_wrapper`}
                control={(
                  <Checkbox
                    checked={promotion.offerLoyalty}
                    onChange={e => setPromotion({ ...promotion, offerLoyalty: e.target.checked })}
                    name="loyalty"
                    color="primary"
                  />
                )}
                label="Customers Can Earn Loyalty With This Promotion"
              />
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

export default connect(
  state => ({
    selectedPromotion: state.promotions.selectedPromotion
  }),
  {
    selectActivePromotion: fromActions.promotionsActions.selectActivePromotion,
    clearSelectedPromotion: fromActions.promotionsActions.clearSelectedPromotion
  }
)(PromotionDetails);

import { put, takeLatest } from 'redux-saga/effects';

import { getUserByToken } from '../../crud/auth.crud';

export const authActionTypes = {
  Login: '[Login] Action',
  Logout: '[Logout] Action',
  Register: '[Register] Action',
  ForgotPassword: '[Forgot Password] Action',
  UserRequested: '[Request User] Action',
  UserLoaded: '[Load User] Auth API'
};

export const authActions = {
  login: authToken => ({ type: authActionTypes.Login, payload: { authToken } }),
  register: authToken => ({
    type: authActionTypes.Register,
    payload: { authToken }
  }),
  logout: () => ({ type: authActionTypes.Logout }),
  forgotPassword: email => ({ type: authActionTypes.ForgotPassword, payload: { email } }),
  requestUser: user => ({ type: authActionTypes.UserRequested, payload: { user } }),
  fulfillUser: user => ({ type: authActionTypes.UserLoaded, payload: { user } })
};

export function* saga() {
  yield takeLatest(authActionTypes.Login, function* loginSaga() {
    yield put(authActions.requestUser());
  });

  yield takeLatest(authActionTypes.Register, function* registerSaga() {
    yield put(authActions.requestUser());
  });

  yield takeLatest(authActionTypes.UserRequested, function* userRequested() {
    const user = yield getUserByToken();

    yield put(authActions.fulfillUser(user));
  });
}

import axios from 'axios';
import { getDefaultParameters_V2 } from '../../../services/helpers/getDefaultParameters';
import axiosInstance from 'app/services/helpers/axiosInstance';

/**
 * Get list of customers
 */
export function getCustomers() {
  return axios.get('/users'+getDefaultParameters_V2(true));
}

/**
 * Get list of customer groups
 */
export function getCustomerGroups() {
  return axios.get('/users/groups'+getDefaultParameters_V2(true));
}


export function issueYokeCash(userId, amount, locationId) {
  const payload = {
    userId: userId,
    value: parseFloat(amount),
    locationId,
    order: {
      payment: []
    }    
  }
  return axios.post('/wallets/funds', payload)
}

export function issueYokePoints(userId, amount, locationId) {
  const payload = {
    userId: userId,
    value: amount,
    locationId,
    isLoyaltyPoints: true,
    order: {
      payment: []
    }
  }
  return axios.post('/wallets/funds', payload)
}

export function inviteUsers(file, accountId) {
  var formData = new FormData();
  formData.append("file", file);
  return axios.post('/invites?accountId='+accountId, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
  })
}

export function getTabsForAccount(accountId) {
  return axios.get(`/accounts/${accountId}/tabs/`)
}

export function updateTabLimit(accountId, tabId, limit) {
  return axiosInstance.patch(`/accounts/${accountId}/tabs/${tabId}`, {limit: limit})
}

export function archiveTab(accountId, tabId) {
  return axiosInstance.delete(`/accounts/${accountId}/tabs/${tabId}`)
}

export function closeTab(charge, amount, locationId, customer) {
  const payment = {
    method: "creditCard",
    userCard: customer.defaultCardId,
    currency: "USD",
    amount: parseFloat(amount)
  }
  
  let payload = {
    userId: customer.id,
    locationId: locationId,
    value: parseFloat(amount),
    order: {
      payment: []
    }
  }

  if (charge)
    payload.order.payment.push(payment)

  return axios.post(`/wallets/tabs`, payload)
}
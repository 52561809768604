import React, { Component } from "react";
import { Formik } from "formik";
import { connect } from "react-redux";
import { TextField } from "@material-ui/core";
import { Link, Redirect } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";

import * as actions from "../../store/actions";
import { setNewPassword } from "../../crud/auth.crud";

class Password extends Component {
  // eslint-disable-next-line react/state-in-constructor
  state = { passwordReset: false ,
    redirect: false,
  };

  render() {
    const { intl } = this.props;
    const { passwordReset, redirect } = this.state;
    const urlParams = new URLSearchParams(window.location.search);
    const userId = urlParams.get("id");
    const key = urlParams.get("key");

    return (
      <div className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper">
        <div className="kt-login__body">
          <div className="kt-login__form">
            <div className="kt-login__title">
              <h3>
                <FormattedMessage id="AUTH.RESET"/>
              </h3>
            </div>

            <Formik
              initialValues={{ password: "", confirmPassword: "" }}
              validate={(values) => {
                const errors = {};

                if (!values.password)
                  errors.password = intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                  });

                if (!values.confirmPassword)
                  errors.confirmPassword = intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                  });
                else if (values.password !== values.confirmPassword)
                  errors.confirmPassword = intl.formatMessage({
                    id: " Password and Confirm Password not match",
                  });

                return errors;
              }}
              onSubmit={(values, { setStatus, setSubmitting }) => {
                setNewPassword(userId, key, values.password)
                  .then((response) => {
                    this.setState({ passwordReset: true });
                    setStatus(
                      intl.formatMessage({
                        id: response.data.message,
                      })
                    );
                    setTimeout(()=>{
                      this.setState({ ...this.state, redirect: true });
                    },5000);
                  })
                  .catch((err) => {
                    setSubmitting(false);
                    this.setState({ passwordReset: true });
                    setStatus(
                      intl.formatMessage({ id: err.response?.data.message })
                    );
                    setTimeout(()=>{
                      this.setState({ ...this.state, redirect: true });
                    },5000);
                  });
              }}
            >
              {({
                values,
                status,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <form onSubmit={handleSubmit} className="kt-form">
                  {status && (
                    <div role="alert" className="alert alert-danger">
                      <div className="alert-text">{status}</div>
                    </div>
                  )}

                  {!passwordReset && (
                    <>
                      <div className="form-group">
                        <TextField
                          type="password"
                          margin="normal"
                          label="Password"
                          className="kt-width-full"
                          name="password"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.password}
                          helperText={touched.password && errors.password}
                          error={Boolean(touched.password && errors.password)}
                        />
                      </div>

                      <div className="form-group">
                        <TextField
                          type="password"
                          margin="normal"
                          label="Confirm Password"
                          className="kt-width-full"
                          name="confirmPassword"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.confirmPassword}
                          helperText={
                            touched.confirmPassword && errors.confirmPassword
                          }
                          error={Boolean(
                            touched.confirmPassword && errors.confirmPassword
                          )}
                        />
                      </div>

                      <div className="kt-login__actions">
                        <Link to="/auth/forgot-password">
                          <button
                            type="button"
                            className="btn btn-secondary btn-elevate kt-login__btn-secondary"
                          >
                            Back
                          </button>
                        </Link>

                        <button
                          type="submit"
                          className="btn btn-primary btn-elevate kt-login__btn-primary"
                          disabled={isSubmitting}
                        >
                          Reset
                        </button>
                      </div>
                    </>
                  )}
                </form>
              )}
            </Formik>
          </div>
        </div>
        {redirect && <Redirect to="auth/login" />}
      </div>
    );
  }
}

export default injectIntl(connect(null, actions.authActions)(Password));

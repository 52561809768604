import React from 'react';
import { connect } from 'react-redux';
import objectPath from 'object-path';
import { useHistory, withRouter } from 'react-router-dom';
import { IconButton } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import MainSubheaderFilter from '../../../app/partials/content/MainSubheaderFilters/MainSubheaderFilter';
import { LayoutContextConsumer } from '../LayoutContext';
import * as builder from '../../ducks/builder';
import { getScope } from 'app/services/helpers/account-id';

function SubHeader(props) {
  const { push } = useHistory();

  const {
    subheaderCssClasses,
    subheaderContainerCssClasses,
    subheaderMobileToggle,
    cashedPath,
    onChange,
  } = props;

  return (
    <div
      id="kt_subheader"
      className={`kt-subheader ${subheaderCssClasses} kt-grid__item`}
    >
      <div className={`kt-container ${subheaderContainerCssClasses}`}>
        <div className="kt-subheader__main">
          <IconButton
            id="subheader-back-button"
            onClick={() => push({ pathname: cashedPath })}
          >
            <ArrowBack />
          </IconButton>
          {subheaderMobileToggle && (
            <button
              type="button"
              className="kt-subheader__mobile-toggle kt-subheader__mobile-toggle--left"
              id="kt_subheader_mobile_toggle"
            >
              <span />
            </button>
          )}

          <LayoutContextConsumer>
            {({ subheader: { title } }) => (
              <>
                <h3 className="kt-subheader__title" id="subheader-title">
                  {title}
                </h3>
              </>
            )}
          </LayoutContextConsumer>
        </div>
        <div
          className="kt-subheader__toolbar"
          style={{
            display:
              getScope() === "super admin" || getScope() === "user admin"
                ? "block"
                : "none",
          }}
        >
          <div className="kt-subheader__wrapper">
            <MainSubheaderFilter onChange={onChange} />
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = store => ({
  subheaderCssClasses: builder.selectors.getClasses(store, {
    path: 'subheader',
    toString: true
  }),
  subheaderContainerCssClasses: builder.selectors.getClasses(store, {
    path: 'subheader_container',
    toString: true
  }),
  config: store.builder.layoutConfig,
  menuConfig: store.builder.menuConfig,
  subheaderMobileToggle: objectPath.get(
    store.builder.layoutConfig,
    'subheader.mobile-toggle'
  )
});

export default withRouter(connect(mapStateToProps)(SubHeader));

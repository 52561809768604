import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import {
  Button,
  Card,
  CardContent,
  Divider,
  FormControl,
  TextField,
  Typography
} from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { connect } from 'react-redux';
import { format } from 'date-fns';

import { accessPermission } from 'app/common/helperFunction';
import useCustomerHeader from '../../../../hooks/useCustomHeader';

import styles from './sale-details.module.scss';

/**
 * Possible taxes
 * @type {({label: string, value: number}|{label: string, value: number})[]}
 */
// eslint-disable-next-line no-unused-vars
const taxes = [{
  label: 'California (9%)',
  value: 9
}, {
  label: 'California (4%)',
  value: 4
}];

export function SaleDetails({ selectedSaleOrder }) {
  const {push} = useHistory();
  useCustomerHeader({
    staticHeader: 'Edit sale'
  });
  const [currentSale, setCurrentSale] = useState({
    details: {
      customerName: '',
      saleDate: '',
      note: '',
      products: []
    }
  });

  useEffect(() => {
    if(!accessPermission("LedgerAll")){
      push({pathname: "/dashboard"});
    }
  }, []);

  return (
    <Card id="ledger_sales_details_card_wrapper" className={styles.card}>
      <CardContent id="ledger_sales_details_card_content_wrapper_0" className="d-flex justify-content-between align-items-center">
        <div id="ledger_sales_details_card_content_inner_wrapper_0">
          <Typography id="ledger_sales_details_card_content_date_label" variant="body1">Date</Typography>
          <Typography id="ledger_sales_details_card_content_date_value"  variant="h6">
            {format(new Date(selectedSaleOrder.creationDate), 'MM/dd/yyyy - hh:mm aa')}
          </Typography>
        </div>
        <div id="ledger_sales_details_card_content_inner_wrapper_1">
          <Typography id="ledger_sales_details_card_content_order_number_label"  variant="body1">Order Number</Typography>
          <Typography id="ledger_sales_details_card_content_order_number_value"  variant="h6">{selectedSaleOrder.id}</Typography>
        </div>
        <div id="ledger_sales_details_card_content_inner_wrapper_2">
          <Typography id="ledger_sales_details_card_content_operator_label"  variant="body1">Operator</Typography>
          <Typography id="ledger_sales_details_card_content_operator_value" variant="h6">
            {!selectedSaleOrder.AccountId ? 'N/A' : selectedSaleOrder.AccountId}
          </Typography>
        </div>
        <div id="ledger_sales_details_card_content_inner_wrapper_3">
          <Typography id="ledger_sales_details_card_content_location_label" variant="body1">Location</Typography>
          <Typography id="ledger_sales_details_card_content_location_value"  variant="h6">{selectedSaleOrder.LocationId}</Typography>
        </div>
        <div id="ledger_sales_details_card_content_inner_wrapper_4">
          <Typography id="ledger_sales_details_card_content_customer_label"  variant="body1">Customer</Typography>
          <Typography id="ledger_sales_details_card_content_customer_value"  variant="h6">{selectedSaleOrder.UserId}</Typography>
        </div>
        <div id="ledger_sales_details_card_content_inner_wrapper_5">
          <Typography id="ledger_sales_details_card_content_device_label"  variant="body1">Device</Typography>
          <Typography id="ledger_sales_details_card_content_device_value"  variant="h6">
            {`${!selectedSaleOrder.DeviceTypeStart
              ? 'N/A' : selectedSaleOrder.DeviceTypeStart} ${!selectedSaleOrder.DeviceStartId
              ? 'N/A' : selectedSaleOrder.DeviceStartId} `}
            -
            {` ${!selectedSaleOrder.DeviceTypeEnd
              ? 'N/A' : selectedSaleOrder.DeviceTypeEnd} ${!selectedSaleOrder.DeviceEndId
              ? 'N/A' : selectedSaleOrder.DeviceEndId}`}
          </Typography>
        </div>
      </CardContent>
      <CardContent id="ledger_sales_details_card_content_wrapper_1" className="d-flex justify-content-between align-items-center">
        <div id="ledger_sales_details_card_content_inner_wrapper_6">
          <Typography id="ledger_sales_details_card_content_heart_land_label" variant="body1">Heartland Transaction ID</Typography>
          <Typography id="ledger_sales_details_card_content_heart_land_value" variant="h6">N/A</Typography>
        </div>
        <div id="ledger_sales_details_card_content_inner_wrapper_7">
          <Typography id="ledger_sales_details_card_content_card_type_label" variant="body1">Card Type</Typography>
          <Typography id="ledger_sales_details_card_content_card_type_value" variant="h6">{selectedSaleOrder.userCard}</Typography>
        </div>
        <div/>
        <div/>
        <div/>
        <div/>
      </CardContent>

      <CardContent id="ledger_sales_details_card_content_wrapper_2" className={styles.wrapper}>
        <Typography id="ledger_sales_details_card_content_products_label" variant="h4">Products</Typography>
        <Divider/>
        {selectedSaleOrder.order.products.length ? selectedSaleOrder.order.products.map(product => {
          const { productId, name, img, unitprice, tax } = product;

          return (
            <div id={`ledger_sales_details_card_content_product_inner_wrapper_${productId}`} key={productId} className={clsx(styles.innerWrapper, 'd-flex', 'align-items-center')}>
              <div id={`ledger_sales_details_card_content_product_image_wrapper_${productId}`} className={styles.imgWrapper}>
                {!img ? 'No image' : (
                  <img src={img} alt={name} className={clsx(styles.img)}/>
                )}
              </div>
              <div id={`ledger_sales_details_card_content_product_inner_name_wrapper_${productId}`}>
                <Typography id="ledger_sales_details_card_content_name_label" variant="body1">Name</Typography>
                <Typography id="ledger_sales_details_card_content_name_value" variant="h6">{name}</Typography>
              </div>
              <div id={`ledger_sales_details_card_content_product_inner_price_wrapper_${productId}`}>
                <Typography id="ledger_sales_details_card_content_price_label" variant="body1">Price</Typography>
                <Typography id="ledger_sales_details_card_content_price_value" variant="h6">{unitprice}</Typography>
              </div>
              <div id={`ledger_sales_details_card_content_product_inner_tax_wrapper_${productId}`}>
                <Typography id="ledger_sales_details_card_content_tax_label" variant="body1">Tax</Typography>
                <Typography id="ledger_sales_details_card_content_tax_value" variant="h6">{tax}</Typography>
              </div>
            </div>
          );
        }) : null}

      </CardContent>

      <CardContent id="ledger_sales_details_card_content_wrapper_3" className={styles.wrapper}>
        <Typography id="ledger_sales_details_card_content_details_label" variant="h4">Details</Typography>
        <Divider/>
        <div id={`ledger_sales_details_card_content_form_wrapper`} className={clsx(styles.innerWrapper, 'd-flex', 'justify-content-between')}>
          <FormControl  id={`ledger_sales_details_card_content_form_controller`} fullWidth className={styles.inputWrapper}>
            <TextField
              id="customerName"
              label="Customer Name"
              placeholder="Enter a customer name"
              value={currentSale.details.customerName}
              onChange={e => setCurrentSale({ ...currentSale,
                details: {
                  ...currentSale.details, customerName: e.target.value
                } })}
              variant="outlined"
            />
          </FormControl>

          <FormControl  id={`ledger_sales_details_card_content_form_saleDate`} fullWidth className={styles.inputWrapper}>
            <TextField
              id="saleDate"
              label="Sale Date"
              placeholder="Enter a sale date"
              value={currentSale.details.saleDate}
              onChange={e => setCurrentSale({ ...currentSale,
                details: {
                  ...currentSale.details, saleDate: e.target.value
                } })}
              variant="outlined"
            />
          </FormControl>

          <FormControl  id={`ledger_sales_details_card_content_form_note`} fullWidth className={styles.inputWrapper}>
            <TextField
              id="note"
              label="Notes"
              placeholder="Enter notes"
              multiline
              rows="1"
              rowsMax="4"
              value={currentSale.details.note}
              onChange={e => setCurrentSale({ ...currentSale,
                details: {
                  ...currentSale.details, note: e.target.value
                } })}
              variant="outlined"
            />
          </FormControl>
        </div>
      </CardContent>

      <CardContent id="ledger_sales_details_card_content_wrapper_4" className="d-flex justify-content-end">
        <Button
          id="ledger_sales_details_card_content_save_button"
          startIcon={<Check/>}
          type="button"
          size="medium"
          variant="contained"
          color="primary"
          disableElevation
        >
          Save
        </Button>
      </CardContent>
    </Card>
  );
}

export default connect(
  state => ({
    selectedSaleOrder: state.ledgers.selectedSaleOrder
  }),
  {}
)(SaleDetails);

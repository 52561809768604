import { ROLE } from 'app/common/roleConstant';
import { getScope, getPermissionBlocksForAccount } from '../../../src/app/services/helpers/account-id';
import { GLOBAL_CONNECT, PAGE_TITLE_CARTS, PAGE_TITLE_CASH_COLLECTIONS, PAGE_TITLE_LOADS, PAGE_TITLE_SALES, YOKE_LOYALTY_PROGRAM } from 'app/common/constant';

export function hasPermissionTo(page) {
  const permissionBlocks = getPermissionBlocksForAccount()
  if (permissionBlocks) {
    if (getScope() ===  ROLE.SUPERADMIN) {
      return true
    }
    const permissions = permissionBlocks.permissions.map(p => p.permission.name)
    return getScope() === ROLE.USERADMIN || getScope() ===  ROLE.SUPERADMIN ? true : permissions.includes(page) 
  } 
  return false
}

export const getLoyaltyProgram =(state)=>{
  return getScope() === ROLE.SUPERADMIN ? state?.subHeader?.selectedOperator?.loyaltyProgram || YOKE_LOYALTY_PROGRAM : state?.subHeader?.operatorList[0]?.loyaltyProgram || YOKE_LOYALTY_PROGRAM
}

const getMenuConfig = (loyaltyProgram) => {
  return {
  header: {
    self: {},
    items: [
    ]
  },
  aside: {
    self: {},
    items: [
      {
        title: hasPermissionTo("SalesAndReportingAll") || getScope() === ROLE.SUPERADMIN ? 'Dashboard' : null,
        root: true,
        icon: 'flaticon2-architecture-and-city',
        page: 'dashboard',
        translate: 'MENU.DASHBOARD'
      },
      {
        title: hasPermissionTo("SalesAndReportingAll") || getScope() === ROLE.SUPERADMIN ? 'Sales & Reporting' : null,
        root: true,
        icon: 'flaticon2-chart',
        page: 'report/sales',
      },
      {
        title: loyaltyProgram === GLOBAL_CONNECT ? null : hasPermissionTo("CustomersAll") || getScope() === ROLE.SUPERADMIN ? 'Customers' : null,
        root: true,
        icon: 'flaticon2-user',
        page: '',
        submenu: [
          { title: 'Customers', page: 'customers' },
          { title: 'Groups', page: 'groups' },
          { title: 'Gift Cards', page: 'giftCards'},
        ]
      },
      {
        title: hasPermissionTo("LedgerAll") || getScope() === ROLE.SUPERADMIN ? 'Ledger' : null,
        root: true,
        icon: 'flaticon2-open-text-book',
        page: '',
        submenu: [
          { title: PAGE_TITLE_SALES, page: 'sales/order' },
          { title: loyaltyProgram === GLOBAL_CONNECT ? null : PAGE_TITLE_LOADS, page: 'sales/valueAdd' },
          loyaltyProgram == GLOBAL_CONNECT && { title: PAGE_TITLE_CARTS, page: 'sales/carts'},
          { title: PAGE_TITLE_CASH_COLLECTIONS, page: 'cashCollections'},
        ]
      },
      {
        title: loyaltyProgram === GLOBAL_CONNECT ? null : hasPermissionTo("LoyaltyAll") || getScope() === ROLE.SUPERADMIN ? 'Loyalty' : null,
        root: true,
        bullet: 'dot',
        icon: 'flaticon2-favourite',
        page: 'loyalty'
      },
      {
        title: hasPermissionTo("ProductsAll") || getScope() === ROLE.SUPERADMIN ? 'Products & Stock' : null,
        root: true,
        icon: 'flaticon2-tag',
        page: '',
        submenu: [
          { title: 'Products', page: 'products/all' },
          { title: loyaltyProgram === GLOBAL_CONNECT ? null : 'Stock Control', page: 'products/stock-control' }
        ]
      },
      {
        title: loyaltyProgram === GLOBAL_CONNECT ? null : hasPermissionTo("PromosAndCouponsAll") || getScope() === ROLE.SUPERADMIN ? 'Deals' : null,
        root: true,
        icon: 'flaticon2-gift-1',
        page: 'promotions'
      },
      {
        title: getScope() === ROLE.SUPERADMIN ? 'Operators' : '',
        root: true,
        icon: 'flaticon2-circular-arrow',
        page: 'operators',
      },
      {
        title: hasPermissionTo("SetupAll") || getScope() === ROLE.SUPERADMIN ? 'Setup' : null,
        root: true,
        icon: 'flaticon2-gear',
        page: '',
        submenu: [
          { title: 'General', page: 'setup/general' },
          { title: 'Users', page: 'setup/users' },
          { title: getScope() === ROLE.SUPERADMIN ? 'Operators' : '', page: 'setup/operators' },
          { title: 'Locations', page: 'setup/locations' },
          { title: getScope() === ROLE.SUPERADMIN ? 'Applications' : '', page: 'setup/applications' }
        ]
      },
      {
        title: hasPermissionTo("AssetsAll") || getScope() === ROLE.SUPERADMIN ? 'Assets' : null,
        root: true,
        icon: 'flaticon2-box-1',
        page: 'help/assets',
      },
      {
        title: getScope() === ROLE.SUPERADMIN ? 'External Tools' : null,
        root: true,
        icon: 'flaticon2-graphic-design',
        page:'',
        submenu:[
          { title: 'Meraki', page: 'https://account.meraki.com/secure/login/dashboard_login', newTab: true},
          { title: 'Esper', page: 'https://cantaloupe.esper.cloud/', newTab: true},
          { title: 'Triteq', page: 'http://portal.freshtraq.com', newTab: true},
          { title: 'WorldNet', page: 'https://payments.worldnettps.com/merchant/selfcare/', newTab: true},
          { title: 'Heartland', page: 'https://developer.heartlandpaymentsystems.com/Account/SignIn?returnUrl=http://developer.heartlandpaymentsystems.com/', newTab: true},
          { title: 'Zendesk', page: 'https://yokepayments.zendesk.com/', newTab: true}
        ]
      },
    ]
  }
}
}

export default getMenuConfig;

import React, { useEffect, useState } from 'react';
import to from 'await-to-js';
import { Divider, Typography } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import * as Sentry from "@sentry/react";

import ResponseModal from 'app/components/Helpers/ResponseModal';
import { useCustomResponse } from 'app/hooks/useCustomResponse';

import Kiosk from './components/Kiosk/Kiosk';
import Address from './components/Address/Address';
import Theme from './components/Theme/Theme';
import GeneralInformation from './components/GeneralInformation/GeneralInformation';
import useCustomHeader from '../../../../../hooks/useCustomHeader';
import {
  getLocation,
  sendKioskCommand,
  updateLocationDetails
} from '../../../../../services/locations';

import styles from '../../../../../../_metronic/_assets/sass/pages/loyalty.module.scss';
import { accessPermission } from 'app/common/helperFunction';
import { useSelector } from 'react-redux';
import { getLoyaltyProgram } from '_metronic/layout/MenuConfig';

export default function LocationDetails(props) {
  const { push } = useHistory();
  const {
    filter: { locations }
  } = props;
  const [currentLocation, setCurrentLocation] = useState({
    AddressId: '',
    ThemeId: '',
    accountId: '',
    creationDate: '',
    deletionDate: null,
    id: '',
    name: '',
    orphaned: null,
    redirectTo: null,
    sourceLocationId: '',
    sourceMarketId: '',
    updatedOn: '',
    tags: [],
    isEnabled: false,
    address: {
      id: '',
      streetOne: '',
      streetTwo: '',
      state: '',
      country: '',
      zip: '',
      primary: false,
      creationDate: '',
      updatedOn: '',
      deletionDate: null,
      locationId: '',
      accountId: '',
      userId: null
    },
    theme: {
      id: '',
      type: '',
      KioskId: null,
      LocationId: null,
      scanButtonHidden: false,
      lookupButtonHidden: false,
      disableCart: false,
      disableCreditCardPurchases: false,
      coolerButtonHidden: false,
      disableApplePay: true,
      primaryColor: '',
      secondaryColor: '',
      bannerColor: '',
      creditCardReaderHardware: '',
      barcodeScannerHardware: '',
      meta: {
        logo: ''
      },
      creationDate: '',
      updatedOn: '',
      deletionDate: null
    },
    status: ''
  });
  /**
   * Change subheader text
   */
  const { id } = useCustomHeader({
    property: 'name'
  });
  const [isThemeExpanded, setIsThemeExpanded] = useState(false)
  const [responseValue, setResponseValue] = useCustomResponse()
  const loyaltyProgram = useSelector(getLoyaltyProgram);

  
  /**
   * Fetch data on component rendering
   */
  useEffect(() => {

    if (!accessPermission()) {
      console.log("user have no access for this page");
      push({ pathname: "/dashboard" });
    }

    if (locations && locations.length)
      getCurrentLocation();
    // eslint-disable-next-line
  }, []);

  /**
   * Fetch location data
   */
  async function getCurrentLocation() {
    if (id === 'new')
      return;

    const [err, res] = await to(getLocation(id, true));

    if (err) {
      Sentry.captureException(err);
      setResponseValue({...responseValue, isMessageOpen: true, isSuccess:false, message:`Error while getting current location. ${err}`});     
      return console.error('Error with fetching the location details', err);
    }

    setCurrentLocation(res.data.Location);
  }

  /**
   * Send kiosk command to the server
   */
  async function handleKioskCommand(kioskId, command) {
    const [err, res] = await to(sendKioskCommand(id, kioskId, command));

    if (err) {
      Sentry.captureException(err);
      setResponseValue({...responseValue, isMessageOpen: true, isSuccess:false,message:`Error while sending kiosk command. ${err}`});
      return console.error('Error while kiosk command sending: ', err);
    }
    setResponseValue({...responseValue, isMessageOpen: true, isSuccess:true,message:`Successfully send kiosk command.`}); 
  }

  /**
   * Handle update for existing location
   */
  async function handleLocationSave() {
    /**
     * Injecting account id in address object from the currentLocation
     * */
    const currentLocationDetail = {
      ...currentLocation,
      address: {
        ...currentLocation.address,
        accountId: currentLocation.accountId
      }
    }
    const [err, res] = await to(updateLocationDetails(id, currentLocationDetail));

    if (err) {
      Sentry.captureException(err);
      setResponseValue({...responseValue, isMessageOpen: true, isSuccess:false,message:`Error while saving kiosk. ${err}`});    
      return console.error('Error while location save or create: ', err);
    }

  }

  return (
    <>
      <div id={`location_details_general_information_wrapper`}>
        <GeneralInformation
          currentLocation={currentLocation}
          onUpdate={location => setCurrentLocation(location)}
          onSave={() => handleLocationSave()}
        />
      </div>
      <div id={`location_details_kiosk_information_wrapper`} className={styles.wrapper}>
        <Typography id={`location_details_kiosk_label`}  variant="h4">Kiosks</Typography>
        <Divider />
        {currentLocation && currentLocation.kiosks
          ? currentLocation.kiosks.map((kiosk, index) => (
            <Kiosk
              index={index}
              key={kiosk.id}
              sendCommand={command => handleKioskCommand(kiosk.id, command)}
              {...kiosk}
            />
          ))
          : null}
      </div>

      {Boolean(loyaltyProgram != 'globalConnect') &&
        <div id={`location_details_theme_wrapper`}  className={styles.wrapper}>
          <Typography
            variant="h4"
            onClick={() => { setIsThemeExpanded(!isThemeExpanded) }}
            className={styles.themeWrapper}
            id={`location_details_theme_label`} 
          >
            {"Theme"}
            {isThemeExpanded ? <ExpandLess /> : <ExpandMore />}
          </Typography>
          <Divider />
          {isThemeExpanded && <Theme
            {...currentLocation}
            onUpdate={theme => setCurrentLocation({ ...currentLocation, theme })}
          />}
        </div>
      }

      <div id={`location_details_address_wrapper`}  className={styles.wrapper}>
        <Typography id={`location_details_address_label`}  variant="h4">Address</Typography>
        <Divider />
        <Address
          {...currentLocation}
          onUpdate={address => setCurrentLocation({ ...currentLocation, address })}
          idName={'location'}
          onSave={() => handleLocationSave()}
        />
      </div>
      <ResponseModal
        isSuccess={responseValue?.isSuccess}
        message={responseValue?.message}
        open={responseValue?.isMessageOpen}
        onClose={value => setResponseValue({...responseValue, isMessageOpen:value})}
      />
    </>
  );
}

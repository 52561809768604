export const grossProfitObj = {
        data: [],
        labels: [],
        currValue: 0,
        prevValue: 0,
        diff: 0,
        total: 0,
};

export const revenueObj = {
    data: [],
    prevData: [],
    labels: [],
    currValue: 0,
    prevValue: 0,
    diff: 0,
    total: 0,
};

export const averageSaleObj = {
    data: [],
    labels: [],
    currValue: 0,
    prevValue: 0,
    diff: 0,
};

export const averageItemsPerSaleObj = {
    data: [],
    labels: [],
    currValue: 0,
    prevValue: 0,
    diff: 0,
};

export const platformInsightsObj = [
    {
        index: 1,
        name: "Operators",
        value: 0
    },
    {
        index: 2,
        name: "Locations",
        value: 0
    },
    {
        index: 3,
        name: "Users",
        value: 0
    },
    {
        index: 4,
        name: "Transactions",
        value: 0
    },
    {
        index: 5,
        name: "Market Funds",
        value: 0
    }
];


export const KIOSK_TITLE = 'Kiosks';
export const TOP_PRODUCT_SOLD = 'Top Products Sold'
import React, { useMemo } from 'react';
import clsx from 'clsx';
import { Typography, InputAdornment, OutlinedInput, FormControl } from '@material-ui/core';

import ProductsFilter from '../ProductsFilter/ProductsFilter';
import CustomButton from '../../../CustomButton/CustomButton';

import styles from './basic-promotion.module.scss';

const predefinedDiscounts = [{
  id: 1,
  title: '%',
  value: '%'
}, {
  id: 2,
  title: '$',
  value: '$'
}];

const products = [{
  id: 1,
  title: 'All',
  value: 'all'
}, {
  id: 2,
  title: 'Specific',
  value: 'specific'
}];

export default function BasicPromotion(props) {
  const {
    discountType,
    discountValue,
    productType,
    productsFilter,
    onFilterChange,
    changeBasicPromotion,
    isPartOfAdvanced = false
  } = props;
  const discounts = useMemo(() => {
    if (!isPartOfAdvanced)
      return predefinedDiscounts;

    return [{
      id: 3,
      title: 'Free',
      value: 'free'
    }, ...predefinedDiscounts];
  }, [isPartOfAdvanced]);

  return (
    <div id={`basic_promotion_wrapper`} className={clsx('row', { [styles.wrapper]: !isPartOfAdvanced })}>
      {!isPartOfAdvanced ? <div className="col-3"/> : null}
      <div id={`basic_promotion_inner_wrapper`} className={clsx(isPartOfAdvanced ? 'col-12' : 'col-6')}>
        <div id={`basic_promotion_inner_container`} className="d-flex justify-content-between align-items-center">
          <div id={`basic_promotion_inner_container_wrapper_0`} className={clsx({
            'col-2': !isPartOfAdvanced,
            'col-1': isPartOfAdvanced
          })}
          >
            <Typography id={`basic_promotion_get_label`} variant="h4">Get</Typography>
          </div>
          <div className={clsx({
            'col-2': !isPartOfAdvanced,
            'col-3': isPartOfAdvanced
          })}
          >
            <Typography id={`basic_promotion_discount_label`}>Discount</Typography>
            <div id={`basic_promotion_inner_container_wrapper_1`}  className="d-flex">
              {discounts.map(discount => (
                <CustomButton
                  {...discount}
                  buttonsCount={discounts.length}
                  hasMinWidth={!isPartOfAdvanced}
                  isActive={discount.value === discountType}
                  onClick={e => changeBasicPromotion(e, 'discountType')}
                  key={discount.id}
                  hasDivider={false}
                  small
                />
              ))}
            </div>
          </div>
          <div id={`basic_promotion_inner_container_wrapper_2`}  className={clsx('align-self-end', {
            'col-4': !isPartOfAdvanced,
            'col-3': isPartOfAdvanced
          })}
          >
            <FormControl id={`basic_promotion_form_control_wrapper`}  fullWidth>
              <OutlinedInput
                id="discount"
                placeholder="0"
                value={discountValue}
                onChange={e => changeBasicPromotion(e.target.value, 'discountValue')}
                variant="outlined"
                startAdornment={discountType === '$' && (
                  <InputAdornment
                    position="start"
                  >
                    $
                  </InputAdornment>
                )}
                endAdornment={discountType === '%' && (
                  <InputAdornment
                    position="end"
                  >
                    %
                  </InputAdornment>
                )}
              />
            </FormControl>
          </div>
          <div id={`basic_promotion_inner_container_wrapper_3`}  className={clsx({
            'col-4': !isPartOfAdvanced,
            'col-3': isPartOfAdvanced
          })}
          >
            <Typography id={`basic_promotion_product_label`} >Product</Typography>
            <div className="d-flex">
              {products.map(product => (
                <CustomButton
                  {...product}
                  hasMinWidth={!isPartOfAdvanced}
                  isActive={product.value === productType}
                  onClick={e => changeBasicPromotion(e, 'productType')}
                  key={product.id}
                  hasDivider={false}
                  small
                />
              ))}
            </div>
          </div>
        </div>

        {productType !== 'all'
          ? (
            <ProductsFilter
              productsFilter={productsFilter}
              onFilterChange={onFilterChange}
            />
          )
          : null}
        <div/>
      </div>
      {!isPartOfAdvanced ? <div id={`basic_promotion_is_partof_advance`}  className="col-3"/> : null}
    </div>
  );
}

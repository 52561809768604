import React, { useEffect, useState } from "react";
import to from "await-to-js";
import { AddShoppingCart, ExpandMore, ExpandLess } from "@material-ui/icons";
import { Button, Divider, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { useDispatch, useSelector } from "react-redux";
import ResponseModal from "app/components/Helpers/ResponseModal";
import { accessPermission } from "app/common/helperFunction";
import { getScope } from "app/services/helpers/account-id";
import TwoTierPricing from "app/components/Operators/Locations/components/LocationDetails/components/TwoTier/TwoTierPricing";
import GeneralInformation from "app/components/Operators/Locations/components/LocationDetails/components/GeneralInformation/GeneralInformation";
import Kiosk from "app/components/Operators/Locations/components/LocationDetails/components/Kiosk/Kiosk";
import Theme from "app/components/Operators/Locations/components/LocationDetails/components/Theme/Theme";
import Address from "app/components/Operators/Locations/components/LocationDetails/components/Address/Address";
import KioskNew from "app/components/Operators/Locations/components/LocationDetails/components/KioskNew/KioskNew";
import { useCustomResponse } from "app/hooks/useCustomResponse";
import { ROLE } from "app/common/roleConstant";
import {
  CANCEL_TOKEN,
  CHECKBOX,
  MONETARYPERCENT,
  NO_ACCESS_TO_THAT_LOCATION,
  NUMBER,
} from "app/common/constant";
import { setupLocationFilter } from "app/common/utils/setupLocation.utils";
import { setupLocationActions } from "app/store/actions/setupLocations.action";

import useCustomHeader from "../../../../../hooks/useCustomHeader";
import { LayoutSplashScreen } from "../../../../../../_metronic";
import {
  getLocation,
  sendKioskCommand,
  updateLocationDetails,
} from "../../../../../services/locations";

import styles from "./setup-location-details.module.scss";
import { useLocation } from 'react-router-dom';
import _ from "lodash";

export default function SetupLocationDetails() {
  const { push, ...rest } = useHistory();
  const dispatch = useDispatch();
  const locationLists = useSelector(
    (state) => state?.setupLocation?.setupLocationList
  );
  const [currentLocation, setCurrentLocation] = useState({
    AddressId: "",
    ThemeId: "",
    accountId: "",
    creationDate: "",
    deletionDate: null,
    id: "",
    name: "",
    orphaned: null,
    redirectTo: null,
    sourceLocationId: "",
    sourceMarketId: "",
    updatedOn: "",
    feeType: "",
    feeAmount: "",
    feeActive: false,
    tags: [],
    isEnabled: false,
    address: {
      accountId: "",
      country: "",
      creationDate: "",
      deletionDate: null,
      id: "",
      locationId: "",
      primary: null,
      state: "",
      streetOne: "",
      streetTwo: "",
      updatedOn: "",
      userId: null,
      zip: "",
    },
    theme: {
      KioskId: null,
      LocationId: null,
      bannerColor: "",
      barcodeScannerHardware: "",
      coolerButtonHidden: null,
      creationDate: "",
      creditCardReaderHardware: "",
      deletionDate: null,
      disableCart: null,
      disableCreditCardPurchases: null,
      id: "",
      lookupButtonHidden: null,
      meta: null,
      primaryColor: "",
      scanButtonHidden: null,
      secondaryColor: "",
      type: "",
      updatedOn: "",
    },
    status: "",
  });
  const [isLoaded, setIsLoaded] = useState(false);
  const { isNew, id } = useCustomHeader({
    property: "name",
  });
  const [isThemeExpanded, setIsThemeExpanded] = useState(false);
  const [responseValue, setResponseValue] = useCustomResponse();
  let userHasAccess = null
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  let operatorFromURLParams = params.get('operator'); 
  const isSuperAdmin = getScope() ===  ROLE.SUPERADMIN
  /**
   * Trigger data loading on component rendering
   */
  useEffect(() => {
    if (!accessPermission("SetupAll")) {
      push({ pathname: "/dashboard" });
    }
    
    if (!isLoaded) getLocationDetails();
    // eslint-disable-next-line
  }, [isLoaded]);

  function checkingUserAccess (operatorIdFromResponse) {
    if(operatorFromURLParams && operatorIdFromResponse){
        if (operatorFromURLParams !== operatorIdFromResponse) {
          setResponseValue({
            ...responseValue,
            isMessageOpen: true,
            isSuccess:false,
            isServerError:false,
            message: NO_ACCESS_TO_THAT_LOCATION
          });
        }
        return
    }
  }
  function handlePopupClose () {
    setResponseValue({ ...responseValue, isMessageOpen: false })
    if (!userHasAccess) {
      push({ pathname: "/setup/locations" });
    }
  }

  /**
   * Fetch location data
   */
  async function getLocationDetails() {
    if (id === "new") return;

    const [err, res] = await to(getLocation(id, true));
    if (err) {
      if (err.message === CANCEL_TOKEN) return;
      Sentry.captureException(err);
      setResponseValue({
        ...responseValue,
        isMessageOpen: true,
        isSuccess: false,
        message: `Error while getting location details. ${err}`,
      });
      return console.error("Error with location fetch: ", err);
    }
    const updateLocation = setupLocationFilter(
      locationLists,
      res?.data?.Location
    );
    dispatch(setupLocationActions.storeSetupCurrentLocations(updateLocation));
    setCurrentLocation(updateLocation);
    (isSuperAdmin && operatorFromURLParams != 1 ) && checkingUserAccess(updateLocation?.accountId)
    setIsLoaded(true);
  }

  /**
   * Send kiosk command to the server
   */
  async function handleKioskCommand(kioskId, command) {
    const [err, res] = await to(sendKioskCommand(id, kioskId, command));

    if (err) {
      Sentry.captureException(err);
      setResponseValue({
        ...responseValue,
        isMessageOpen: true,
        isSuccess: false,
        message: `Error sending kiosk command. ${err}`,
      });
      return console.error("Error while kiosk command sending: ", err);
    }
    setResponseValue({
      ...responseValue,
      isMessageOpen: true,
      isSuccess: true,
      message: `Successfully send kiosk command.`,
    });
  }

  const getFeeAmount = ({ feeAmount, feeType, ...currentLocation }) => {
    if (feeType === MONETARYPERCENT && feeAmount >= 1) {
      return {
        ...currentLocation,
        feeAmount: feeAmount / 100,
        feeType,
      };
    } else {
      return { ...currentLocation, feeAmount, feeType };
    }
  };

  /**
   * Handle update for existing location
   */
  function handleLocationSave({ success, error }) {
    let updatedLocation = {
      ...currentLocation,
      feeType: !_.isNull(currentLocation?.feeType) ? currentLocation?.feeType : MONETARYPERCENT,
      feeAmount: !_.isNull(currentLocation?.feeAmount) ? currentLocation?.feeAmount : 0
    };
  
    if (updatedLocation.feeType === MONETARYPERCENT) {
      updatedLocation = getFeeAmount({ ...updatedLocation });
    }

    updateLocationDetails(id, updatedLocation)
      .then((res) => {
        setResponseValue({
          ...responseValue,
          isMessageOpen: true,
          isSuccess: true,
          message: success,
        });

        // updated in store
        dispatch(
          setupLocationActions.storeSetupCurrentLocations(res?.data?.Location)
        );
      })
      .catch((err) => {
        setResponseValue({
          ...responseValue,
          isMessageOpen: true,
          isSuccess: false,
          message: `${error}`,
        });
        Sentry.captureException(err);
        console.error("Error while location save or create: ", err);
      });
  }

  const handelChange = (e) => {
    setCurrentLocation({
      ...currentLocation,
      [e.target.name]:
        e.target.type === CHECKBOX
          ? e.target.checked
          : e.target.type === NUMBER && parseInt(e.target.value) < 0
          ? "0"
          : e.target.value,
    });
  };

  return (
    <>
      {isLoaded ? (
        <>
          {isNew ? (
            <div
              id={`setup_locations_details_order_equipment_wrapper`}
              className="d-flex justify-content-end"
            >
              <Button
                id={`setup_locations_details_button_order_equipment`}
                color="primary"
                startIcon={<AddShoppingCart />}
                variant="contained"
                size="medium"
                onClick={() =>
                  push({ pathname: "/setup/locations/1/equipment" })
                }
              >
                Order Equipment
              </Button>
            </div>
          ) : null}

          {getScope() === ROLE.SUPERADMIN ? (
            <>
              <div id={`setup_locations_details_general_info_wrapper`}>
                <GeneralInformation
                  isNew={isNew}
                  currentLocation={currentLocation}
                  onUpdate={(location) => setCurrentLocation(location)}
                  onSave={() =>
                    handleLocationSave({
                      success: "Thank you for filling out your information!",
                      error: "Unable to save your information ",
                    })
                  }
                />
              </div>
            </>
          ) : null}
          <div
            id={`setup_locations_details_kiosk_wrapper`}
            className={styles.wrapper}
          >
            <Typography id={`setup_locations_details_kiosk_title`} variant="h4">
              Kiosks
            </Typography>
            <Divider />
            {!isNew && currentLocation && currentLocation.kiosks ? (
              currentLocation.kiosks.map((kiosk) => (
                <Kiosk
                  key={kiosk.id}
                  sendCommand={(command) =>
                    handleKioskCommand(kiosk.id, command)
                  }
                  {...kiosk}
                />
              ))
            ) : (
              <KioskNew {...currentLocation.kiosks} />
            )}
          </div>
          <div
            id={`setup_locations_details_two_tier_pricing_wrapper`}
            className={styles.wrapper}
          >
            <Typography variant="h4">Two Tier Pricing</Typography>
            <Divider />
            <TwoTierPricing
              {...currentLocation}
              onUpdate={(e) => handelChange(e)}
              idName={"setupLocation"}
              onSave={() =>
                handleLocationSave({
                  success: "Successfully added Two Tier Pricing",
                  error: "Unable to save two tier pricing ",
                })
              }
            />
          </div>
          <div
            id={`setup_locations_details_theme_wrapper`}
            className={styles.wrapper}
          >
            <Typography
              variant="h4"
              onClick={() => {
                setIsThemeExpanded(!isThemeExpanded);
              }}
              className={styles.themeWrapper}
            >
              {"Theme"}
              {isThemeExpanded ? <ExpandLess /> : <ExpandMore />}
            </Typography>
            <Divider />
            {isThemeExpanded && (
              <Theme
                {...currentLocation}
                onUpdate={(theme) =>
                  setCurrentLocation({ ...currentLocation, theme })
                }
              />
            )}
          </div>

          <div
            id={`setup_locations_details_address_wrapper`}
            className={styles.wrapper}
          >
            <Typography variant="h4">Address</Typography>
            <Divider />
            <Address
              {...currentLocation}
              onUpdate={(address) =>
                setCurrentLocation({ ...currentLocation, address })
              }
              idName={"setupLocation"}
              onSave={() =>
                handleLocationSave({
                  success:
                    "Thank you for filling out your address information!",
                  error: "Unable to save your address information",
                })
              }
            />
          </div>
        </>
      ) : (
        <LayoutSplashScreen />
      )}
      <ResponseModal
        isSuccess={responseValue?.isSuccess}
        message={responseValue?.message}
        open={responseValue?.isMessageOpen}
        onClose={handlePopupClose}
      />
    </>
  );
}

import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
  IconButton,
  makeStyles
} from '@material-ui/core';
import { EditSharp } from '@material-ui/icons';

import { getComparator, getDefaultStyles, stableSort } from '../../../../services/table';

const headCells = [
  { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
  { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
  { id: 'location', numeric: false, disablePadding: false, label: 'Location' },
  { id: 'isActive', numeric: false, disablePadding: false, label: 'Active' },
  { id: 'actions', numeric: false, disablePadding: false, label: 'Actions' }
];

function EnhancedTableHead() {
  return (
    <TableHead id={`add_ons_table_EnhancedTableHead`}>
      <TableRow id={`add_ons_table_EnhancedTabl_row`} >
        {headCells.map(headCell => (
          <TableCell
            id={`add_ons_table_EnhancedTabl_cell_${headCell.id}`} 
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles(getDefaultStyles);

export default function AddOnsTable(props) {
  const {
    rows,
    pagination,
    addOnsTotal,
    onEditRowClick,
    onPaginationChange
  } = props;
  const classes = useStyles();
  const [order] = useState('asc');
  const [orderBy] = useState('id');
  const [page, setPage] = useState(pagination.page - 1);
  const [rowsPerPage, setRowsPerPage] = useState(pagination.limit);

  /**
   * Handle pagination value change
   */
  function handlePaginationChange({ target: { value } }, newPage = -1) {
    if (value) {
      const newSize = parseInt(value, 10);
      const newP = parseInt((page * rowsPerPage + 1) / newSize);

      onPaginationChange({
        page: newP +1,
        limit: newSize
      });
      setPage(newP);
      setRowsPerPage(newSize);
    }

    if (newPage >= 0 && typeof newPage !== 'object') {
      onPaginationChange({
        page: newPage + 1,
        limit: rowsPerPage
      });
      setPage(newPage);
    }
  }

  return (
    <div id={`add_ons_table_root_wrapper`} className={classes.root}>
      <Paper id={`add_ons_table_paper_wrapper`} className={classes.paper}>
        <TableContainer id={`add_ons_table_container`}>
          <Table
            id={`add_ons_table_inner_wrapper`}
            className={classes.table}
            aria-labelledby="tableTitle"
            size="small"
            aria-label="enhanced table"
          >
            <EnhancedTableHead/>
            <TableBody  id={`add_ons_table_body_wrapper`}>
              {stableSort(rows, getComparator(order, orderBy))
                .map(row => {
                  const {
                    id,
                    LocationId,
                    name,
                    status,
                    location: { name: locationName },
                    enabled,
                    creationDate
                  } = row;

                  return (
                    <TableRow
                      id={`add_ons_table_row_${id}`}
                      hover
                      onClick={() => onEditRowClick(LocationId, id)}
                      key={id}
                    >
                      <TableCell id={`add_ons_table_cell_name_${id}`} align="left" padding="normal">{name}</TableCell>
                      <TableCell  id={`add_ons_table_cell_status_${id}`} align="left" padding="normal" onClick={e => e.stopPropagation()}>
                      {status}
                      </TableCell>
                      <TableCell id={`add_ons_table_cell_location_name_${id}`} align="left" padding="normal">{locationName}</TableCell>
                      <TableCell id={`add_ons_table_cell_enabled_${id}`} align="left" padding="normal">
                        {enabled ? 'On' : 'Off'}
                      </TableCell>
                      <TableCell id={`add_ons_table_cell_action_button_${id}`} align="left" padding="normal">
                        <IconButton onClick={() => onEditRowClick(LocationId, id)}>
                          <EditSharp/>
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
        id={`add_ons_table_pagination`}
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={addOnsTotal}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handlePaginationChange}
          onRowsPerPageChange={handlePaginationChange}
        />
      </Paper>
    </div>
  );
}

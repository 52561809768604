export const promotionsActionTypes = {
  StorePromotions: '[Get Promotions Entities] Action',
  SelectActivePromotion: '[Select Active Promotion from List] Action',
  ClearSelectedPromotion: '[Clear Selected Promotion Property] Action'
};

export const promotionsActions = {
  storePromotions: promotions => ({
    type: promotionsActionTypes.StorePromotions,
    payload: [...promotions]
  }),
  selectActivePromotion: promotionId => ({
    type: promotionsActionTypes.SelectActivePromotion,
    payload: promotionId
  }),
  clearSelectedPromotion: () => ({
    type: promotionsActionTypes.ClearSelectedPromotion,
    payload: null
  })
};

import React, { useState } from 'react';
import {
  Checkbox,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles
} from '@material-ui/core';
import { EditSharp } from '@material-ui/icons';
import { format } from 'date-fns';

import { locationsForProduct } from 'app/services/products';

import ProductRowExpanded from '../ProductRowExpanded/ProductRowExpanded';
import { getDefaultStyles } from '../../../../../../services/table';
import FeaturedProductSelector from './FeaturedProductSelector';

import styles from './products-table.module.scss';

const headCells = [
  { id: 'checkbox', numeric: false, disablePadding: false, label: '' },
  { id: 'featuredProduct', numeric: false, disablePadding: false, label: 'Featured' },
  { id: 'image', numeric: false, disablePadding: false, label: '' },
  { id: 'name', numeric: false, disablePadding: false, label: 'Product' },
  { id: 'brand', numeric: false, disablePadding: false, label: 'Brand' },
  { id: 'supplier', numeric: false, disablePadding: false, label: 'Supplier' },
  { id: 'revenue', numeric: false, disablePadding: false, label: 'Revenue' },
  { id: 'retailPrice', numeric: false, disablePadding: false, label: 'Retail Price' },
  { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
  { id: 'createdDate', numeric: false, disablePadding: false, label: 'Created' },
  { id: 'actions', numeric: false, disablePadding: false, label: 'Actions' }
];
const useStyles = makeStyles(getDefaultStyles);

function EnhancedTableHead(props) {
  const {
    onMasterToggle,
    selectedRows,
    rowsCount,
    checkAllProductHaveImages,
  } = props;

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" style={{ width: 45 }}/>
        {headCells.map(headCell => {
          if (headCell.id === 'checkbox' && headCell.id !== 'image')
            return (
              <TableCell
                key={headCell.id}
                align="right"
                padding="checkbox"
              >
                <Checkbox
                  indeterminate={selectedRows.length > 0 && selectedRows.length < rowsCount}
                  checked={selectedRows.length === rowsCount}
                  inputProps={{ 'aria-label': 'select all products' }}
                  onChange={() => onMasterToggle()}
                />
              </TableCell>
            );

          if(headCell.id !== "image" || checkAllProductHaveImages())
            return (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? 'right' : 'left'}
                padding={headCell.disablePadding ? 'none' : 'normal'}
              >
                {headCell.label}
              </TableCell>
            );
        })}
      </TableRow>
    </TableHead>
  );
}

export default function ProductsTable(props) {
  const classes = useStyles();
  const {
    rows,
    totalProducts,
    selectedRows,
    pagination:{page, limit:rowsPerPage},
    onEditRowClick,
    onMasterToggle,
    onSelectRow,
    onDuplicateProduct,
    onPaginationChange,
    addFeaturedProduct,
    deleteFeaturedProduct,
    featuredProducts,
    everyLocationId,
    isFeaturedLoaded,
  } = props;
  const isSelected = id => selectedRows.indexOf(id) !== -1;
  const urlParams = new URLSearchParams(window.location.search);
  const locationId = urlParams.get("location");
  const [isAllHaveImages, setIsAllHaveImages] = useState(true);

  /**
   * Handle pagination change
   * @param {Synthetic Event} param0
   * @param {number} newPage
   */
  function handlePaginationChange({ target: { value } }, newPage = -1) {
    if (value) {
      const newSize = parseInt(value, 10);
      const newP = parseInt(((page-1) * rowsPerPage + 1) / newSize) ;

      onPaginationChange({
        page: newP + 1,
        limit: newSize
      });
    }

    if (newPage >= 0 && typeof newPage !== 'object') {
      onPaginationChange({
        page: newPage + 1,
        limit: rowsPerPage
      });
    }
  }

  function onEditIconClick(e, id) {
    e.stopPropagation();

    onEditRowClick(id);
  }

  function checkAllProductHaveImages() {
    let i=0;
    for(i; i < rows.length; i++){
      if(rows[i].product.images.length === 0) {
        setIsAllHaveImages(false);
        return false;
      }
    }
    setIsAllHaveImages(true);
    return true;
  }

  return (
    <>
      <div id={`product_table_root_wrapper`} className={classes.root}>
        <Paper id={`product_table_paper_wrapper`}  className={classes.paper}>
          <TableContainer id={`product_table_container_wrapper`} >
            <Table
              id={`product_table_wrapper`} 
              className={classes.table}
              aria-labelledby="tableTitle"
              size="small"
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                selectedRows={selectedRows}
                rowsCount={rows.length}
                onMasterToggle={() => onMasterToggle()}
                checkAllProductHaveImages={checkAllProductHaveImages} 
              />
              <TableBody id={`product_table_body_wrapper`} >
                {rows.map((row,index) => {
                    const {
                      productId,
                      operatorProductId,
                      product: {
                        brand,
                        images,
                        supplier,
                      },
                      name,
                      cost,
                      price,
                      active,
                      created,
                      isExpanded
                    } = row;

                    return (
                      <React.Fragment key={index}>
                        <TableRow
                          id={`product_table_row`} 
                          hover
                          tabIndex={-1}
                          selected={isExpanded}
                        >
                          <TableCell id={`product_table_checkboc_cell`}  align="left" padding="checkbox">
                          </TableCell>
                          <TableCell  id={`product_table_product_cell`}  align="center" padding="checkbox">
                            <Checkbox
                              id={`product_table_product_checkbox`} 
                              checked={isSelected(productId)}
                              inputProps={{ 'aria-label': 'select all products' }}
                              onChange={() => onSelectRow(productId)}
                            />
                          </TableCell>

                          <TableCell id={`product_table_select_product_cell`}  align="center" padding="checkbox">
                            <FeaturedProductSelector
                              isActive={active}
                              isFeaturedLoaded={isFeaturedLoaded}
                              selectedLocation={locationId}
                              operatorProductId={operatorProductId}
                              everyLocationId={everyLocationId}
                              locationsFeaturedAt={locationsForProduct(operatorProductId, featuredProducts) ?? []}
                              addFeaturedProduct={(id, everywhere) => addFeaturedProduct(id, everywhere)}
                              deleteFeaturedProduct={(id, everywhere) => deleteFeaturedProduct(id, everywhere)}
                            />
                          </TableCell>


                          {isAllHaveImages && (
                            <TableCell id={`product_table_image_cell`} align="left" padding="none">
                              {images && images.length ? (
                                <img
                                  className={styles.productImage}
                                  src={images[0].cdn + images[0].fileName}
                                  alt={name}
                                />
                              )
                                : null}
                            </TableCell>
                          )}
                          <TableCell id={`product_table_name_cell`} align="left" padding="none">
                            {name}
                          </TableCell>
                          <TableCell id={`product_table_brand_cell`} align="left" padding="none">
                            {!brand ? 'N/A' : (brand.name || 'N/A')}
                          </TableCell>
                          <TableCell id={`product_table_supplier_cell`} align="left" padding="none">
                            {!supplier ? 'N/A' : supplier.name}
                          </TableCell>
                          <TableCell id={`product_table_cost_cell`} align="left" padding="none">
                            $
                            {(+price - +cost).toFixed(2)}
                          </TableCell>
                          <TableCell id={`product_table_price_cell`} align="left" padding="none">
                            $
                            {price.toFixed(2)}
                          </TableCell>
                          <TableCell id={`product_table_active_cell`} align="left" padding="none">
                            {!active ? 'Deactivated' : 'Active'}
                          </TableCell>
                          <TableCell id={`product_table_created_date_cell`} align="left" padding="none">
                            {format(new Date(created), 'MM/dd/yyyy - hh:mm aa')}
                          </TableCell>
                          <TableCell id={`product_table_icon_button_cell`} align="left" padding="none" width="100px">
                            <IconButton onClick={e => onEditIconClick(e, operatorProductId)}>
                              <EditSharp/>
                            </IconButton>
                          </TableCell>
                        </TableRow>
                        {isExpanded ? (
                          <ProductRowExpanded
                            product={row}
                            onDuplicateProduct={() => onDuplicateProduct(productId)}
                          />
                        ) : null}
                      </React.Fragment>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            id={`product_table_pagination`}
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={totalProducts}
            rowsPerPage={rowsPerPage}
            page={page-1}
            onPageChange={handlePaginationChange}
            onRowsPerPageChange={handlePaginationChange}
          />
        </Paper>
      </div>
    </>
  );
}

export const ledgerActionTypes = {
  StoreLedgers: '[Get Ledger Entities] Action',
  StoreValueAdd: '[Get Value Add Orders] Action',
  StoreTabPayment: '[Get Tab Payment Orders] Action',
  SelectSaleOrder: '[Select Active Sale Order from List] Action',
  ClearSelectedSaleOrder: '[Clear Selected Sale Order property from Store] Action',
  ClearLedgersStore: '[Clear Ledgers Store to empty data from storage] Action'
};

export const ledgerActions = {
  storeLedgers: ledgers => ({
    type: ledgerActionTypes.StoreLedgers,
    payload: [...ledgers]
  }),
  storeValueAdd: valueAdds => ({
    type: ledgerActionTypes.StoreValueAdd,
    payload: [...valueAdds]
  }),
  storeTabPayment: tabPayments => ({
    type: ledgerActionTypes.StoreTabPayment,
    payload: [...tabPayments]
  }),
  selectSaleOrder: selectQuery => ({
    type: ledgerActionTypes.SelectSaleOrder,
    payload: selectQuery
  }),
  clearSelectedSaleOrder: () => ({
    type: ledgerActionTypes.ClearSelectedSaleOrder,
    payload: null
  }),
  clearLedgersStore: () => ({
    type: ledgerActionTypes.ClearLedgersStore,
    payload: null
  })
};

import React, { useState } from 'react';
import clsx from 'clsx';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
  IconButton,
  makeStyles, Avatar
} from '@material-ui/core';
import { EditSharp } from '@material-ui/icons';
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';

import { getComparator, getDefaultStyles, stableSort } from '../../../../../services/table';
import { useEffect } from 'react';

const headCells = [
  { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
  { id: 'role', numeric: false, disablePadding: false, label: 'Role' },
  { id: 'lastActive', numeric: false, disablePadding: false, label: 'Last Active' },
  { id: 'actions', numeric: false, disablePadding: false, label: 'Actions' }
];

function EnhancedTableHead() {
  return (
    <TableHead id={`users_table_head`}>
      <TableRow id={`users_table_head_row`}>
        {headCells.map(headCell => (
          <TableCell
            id={`users_table_head_cell_${headCell.id}`}
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles({
  ...getDefaultStyles,
  nameInfo: {
    marginLeft: 20
  },
  name: {
    marginBottom: 5
  }
});

export default function UsersTable(props) {
  const { push } = useHistory();
  const classes = useStyles();
 

  const [page, setPage] = useState(props?.page);
  const [rowsPerPage, setRowsPerPage] = useState(props?.rowsPerPage);
  const {
    rows,
    pagination  
  } = props;

  const[totalRows,setTotalRows]=useState(pagination?.totalRecords);

  useEffect(() => {
    setTotalRows(pagination?.totalRecords);
    setPage(props?.page);
    setRowsPerPage(props?.rowsPerPage);
  });

  const handleChangePage = (event, newPage) => {
    props.handleChangePage(event, newPage)
  };

  const handleChangeRowsPerPage = (event) => {
    props.handleChangeRowsPerPage(event)
  };

  const getRolesNameList = (userRoles) => {
    let roles = "";
    userRoles.forEach(element => {
      roles += (roles ? ", " : "") + element.role.name;
    });
    return roles ? roles : '_' ;
  }

  return (
    <div id="users_table_root_container"  className={classes.root}>
      <Paper id="users_table_paper_container" className={classes.paper}>
        <TableContainer id="users_table_inner_container">
          <Table
             id="users_table_container"
            className={classes.table}
            aria-labelledby="tableTitle"
            size="small"
            aria-label="enhanced table"
          >
            <EnhancedTableHead/>
            <TableBody  id="users_table_body_container">
              {stableSort(rows, getComparator)
                .map(row => {
                  const { 
                    id,
                    firstName,
                    lastName,
                    email,
                    userroles,
                    updatedOn,
                  } = row;

                  return (
                    <TableRow
                      id={`users_table_row_wrapper_${id}`}
                      hover
                      onClick={() => push({ pathname: `/setup/users/${id}` })}
                      key={id}
                    >
                      <TableCell id={`users_table_cell_wrapper_${id}`} align="left" padding="normal">
                        <div id={`users_table_avatar_wrapper_${id}`} className="d-flex">
                          <Avatar id={`users_table_avatar_${id}`} alt={`${firstName} ${lastName}`} className={classes.large}/>
                          <div  id={`users_table_content_wrapper_${id}`} className={clsx(classes.nameInfo, 'd-flex', 'flex-column', 'justify-content-center')}>
                            <p id={`users_table_user_detail_wrapper_${id}`} className={classes.name}>
                              {firstName}
                              &nbsp;
                              {lastName}
                            </p>
                            <i>{email}</i>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell id={`users_table_user_role_${id}`} align="left" padding="normal">
                        {!userroles ? 'N/A' : getRolesNameList(userroles)}
                      </TableCell>
                      <TableCell id={`users_table_update_on_${id}`} align="left" padding="normal">
                        {format(new Date(updatedOn), 'MM/dd/yyyy - hh:mm aa')}
                      </TableCell>
                      <TableCell id={`users_table_edit_button_${id}`} align="left" padding="normal">
                        <IconButton onClick={() => push({ pathname: `/setup/users/${id}` })}>
                          <EditSharp/>
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          id={`users_table_pagination`}
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={totalRows}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}

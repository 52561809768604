import React, { useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  TextField
} from '@material-ui/core';
import queryString from "query-string";
import { Autocomplete } from '@material-ui/lab';
import * as Sentry from "@sentry/react";

import { getComparator, stableSort } from "app/services/table";
import { hasMultipleAccounts } from 'app/services/helpers/account-id';
import { getOperatorV2 } from 'app/services/account';
import { subHeaderActions } from 'app/store/actions';
import { ALL_LOCATIONS, ALL_OPERATORS, CANCEL_TOKEN, EDateFilterRanges, LOCATION_PARAM, OPERATOR_PARAM } from "app/common/constant";

import styles from './response.module.scss';

const defaultFilters = {
  period: EDateFilterRanges.MONTHS,
  startDate: null,
  endDate: null,
  location: ALL_LOCATIONS.id,
  operator: ALL_OPERATORS.id,
};

export default function OperatorSpecificModal(props) {
  const {
    open,
    onClose,
  } = props;

  const { location, push } = useHistory();

  const [selectedFilters, setSelectedFilters] = useState(
    !location.search ? defaultFilters : getSerializedFilters(location.search)
  );
  const [searchOperatorOptions, setSearchOperatorOptions] = useState([]);
  const [operatorSearchText, setOperatorSearchText] = useState("");
  const [operatorSelected, setOperatorSelected] = useState(true);
  const [newOperator, setNewOperator] = useState(null)
  const dispatch = useDispatch();

  function handleModalClose() {
    if(newOperator) {
      console.log(JSON.stringify(newOperator));
      setOperatorSelected(true);
      handleFilterChange(ALL_LOCATIONS.id, LOCATION_PARAM);
      handleFilterChange(newOperator.id, OPERATOR_PARAM);
      setNewOperator(null)
      onClose(true)
      setTimeout(() => {
        dispatch(subHeaderActions.storeSelectedOperator(newOperator));
        dispatch(subHeaderActions.storeLoyaltyProgram(newOperator.loyaltyProgram));
      }, 1000);
    }else{
      setOperatorSelected(false);
      onClose(true)
    }
  }

  const handleFilterChange = useCallback((value, key) => {
    setSelectedFilters((cur) => ({ ...cur, [key]: value }));
  }, []);

  useEffect(() => {
    if(selectedFilters.operator && selectedFilters.operator != 1 ){
      push({ search: stringifyFiltersToSearch(selectedFilters) });
    }
  }, [selectedFilters]);

  useEffect(() => {
    if(hasMultipleAccounts()){
      setSearchOperatorOptions([...searchOperatorOptions, ALL_OPERATORS])
    }
    getOperatorOptions();
  },[operatorSearchText])

  function getOperatorOptions() {
    Promise.all([getOperatorV2({ limit: 10, page: 1 }, operatorSearchText)])
      .then(([operatorsRes]) => {
        if (operatorsRes && operatorsRes.data) {
          const mappedOperators = operatorsRes.data.account.rows.map(
            (operator) => {
              const { id, name, loyaltyProgram } = operator;
              return { id, name, loyaltyProgram };
            }
          );
          const sortedOperatorsList = stableSort(
            mappedOperators,
            getComparator("asc", "name")
          );
          setSearchOperatorOptions([
            ...sortedOperatorsList,
          ]);
        }
      })
      .catch((err) => {
        if(err.message === CANCEL_TOKEN)return;
        Sentry.captureException(err);
        console.error("Error with Operators filters fetching: ", err);
      });
  }

  function handleOperatorChange(value) {
    let val = value ?? ""
    setNewOperator(val);
    if(!operatorSelected)
      setOperatorSelected(true);
  }

  function handleTextChange(text) {
      setOperatorSearchText(text);
  }

  return (
    <>
      <Dialog
        id={`operator_specific_dialog_wrapper`}
        style={{zIndex :"2"}}
        open={open}
        onClose={handleModalClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <DialogTitle id="simple-modal-title">Hold on!</DialogTitle>
        <DialogContent id="simple-modal-description" className={styles.wrapper}>
          You currently manage more than one Operator in Yoke,
           and this action or actions on this page are Operator specific.
            Please select one of the operators you manage from the below dropdown before continuing.
        </DialogContent>
        <DialogActions id="simple-modal-actions">
        <FormControl>
        <Autocomplete
          id={`operator_specific_auto_complete`}
          variant="outlined"
          getOptionLabel={(option) => option.name}
          defaultValue={searchOperatorOptions.find((v) => v.name[0])}
          selectedFilter={selectedFilters.operator}
          onChange={(event, option) => handleOperatorChange(option)}
          options={searchOperatorOptions}
          style={{ width: 220, marginLeft: "10px" ,display: "grid"}}
          renderInput={(params) => (
            <TextField 
              variant="outlined"
              id={`operator_specific_text_field`}
              {...params} 
              onChange={(e) => handleTextChange(e.target.value)}
              onClick={(e) => handleTextChange(e.target.value)}
              placeholder={"Select Operator"}
              helperText={!operatorSelected && (
                <FormHelperText id={`operator_specific_form_helper_text`} style={{ color: "red", fontSize: "10px", marginLeft: "0px" }}>
                  * Operator is required!
                </FormHelperText>
              )}
            />
          )}
        />
        </FormControl>
          <Button
            id={`operator_specific_okay_button`}
            color="primary"
            size="large"
            onClick={handleModalClose}
          >
            Okay!
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

function getSerializedFilters(search) {
  const parsedSearch = queryString.parse(search);
  for (const key in parsedSearch)
    if (parsedSearch[key] && (key === "startDate" || key === "endDate")) {
      const dateString = parsedSearch[key];
      parsedSearch[key] = new Date(dateString);
    }
  return parsedSearch;
}

function stringifyFiltersToSearch(filters) {
  if (!filters) return "";
  const preparedFilters = {};
  for (const key of Object.keys(filters)){
    if (filters[key]) preparedFilters[key] = filters[key].toString();
  }
  return queryString.stringify(preparedFilters);
}
import { ROLE } from "app/common/roleConstant";
import { isEmpty,isObject } from "lodash";

export function getLoggedUserAccountId() {
  return getLoggedUserAccountId2();
}

/**
 * to check user has Multiple Account and any one is not selected
 * @returns boolean
 */
export function hasMultipleAccounts() {
  const storageData = localStorage.getItem('yoke:permissions');
  const block = storageData && storageData.length ? JSON.parse(storageData) : null;
  const accountIds = block?.permissions?.map(b => b.accountId).filter((v, i, a) => a.indexOf(v) === i) || [];
  const operator = new URLSearchParams(window.location.search).get('operator');
  return  (getScope() == ROLE.SUPERADMIN) ? operator == 1 : (accountIds.length > 1 && operator == 1)
  
}

/**
 * to check user has Multiple Accounts
 * @returns boolean
 */
export function hasMultipleAccounts2() {
  const storageData = localStorage.getItem('yoke:permissions');
  const block = storageData && storageData.length ? JSON.parse(storageData) : null;
  const accountIds = block?.permissions?.map(b => b.accountId).filter((v, i, a) => a.indexOf(v) === i) || [];
  const operator = new URLSearchParams(window.location.search).get('operator');
  return (getScope() == ROLE.SUPERADMIN) ? true : (accountIds.length > 1 && operator == 1)
}

export function getLoggedUserAccountId2() {
  const storageData = localStorage.getItem('yoke:permissions');
  const block = storageData && storageData.length ? JSON.parse(storageData) : null;
  const operator = new URLSearchParams(window.location.search).get('operator');
  const id = operator == 1 ? block?.permissions?.[0] : operator
  /**
   * @description
   * checking if id is an object
   * if object then we are passing as id.accountId
   * else id
   * */ 
  if(id){
    return !isObject(id) ? id : id?.accountId;
  }
}

export function getScope() {
  const storageData = localStorage.getItem('yoke:scope')

  return storageData;
}

/*
* Get the permissions set for this user at this operator, or the operator selected
*/
export function getPermissionBlocksForAccount() {
  const storageData = localStorage.getItem('yoke:permissions')
  if (storageData == null)
    return null
  const jsonBlocks = JSON.parse(storageData)
  const permissionBlock = jsonBlocks.permissions?.filter(b => b.accountId == getLoggedUserAccountId2())[0]
  if (permissionBlock != null)
    return permissionBlock;
  else 
    return false
}

import React, { useEffect, useRef } from 'react';

import KTScrolltop from '../../../_metronic/_assets/js/scrolltop';

export default function ScrollTop() {
  const scrollTopCommonRef = useRef(null);

  useEffect(() => {
    const scrollTopOptions = {
      offset: 300,
      speed: 600
    };
    const scroll = new KTScrolltop(scrollTopCommonRef.current, scrollTopOptions);
  }, []);

  return (
    <div
      id="kt_scrolltop"
      className="kt-scrolltop"
      ref={scrollTopCommonRef}
    >
      <i className="la la-arrow-up"/>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import to from "await-to-js";
import { connect, useSelector } from "react-redux";
import clsx from "clsx";
import DateFnsUtils from "@date-io/date-fns";
import {
  Card,
  CardContent,
  Divider,
  FormControl,
  TextField,
  InputLabel,
  Select,
  Typography,
  MenuItem,
  Button,
  FormControlLabel,
  Checkbox,
  FormHelperText,
} from "@material-ui/core";
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { Close, Check } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import moment from "moment";
import * as Sentry from "@sentry/react";

import { getLocations } from "app/services/locations";
import ResponseModal from "app/components/Helpers/ResponseModal";
import { getLoggedUserAccountId2 } from "app/services/helpers/account-id";
import { accessPermission } from "app/common/helperFunction";

import DealTypeButton from "./components/DealTypeButton/DealTypeButton";
import BasicDeal from "./components/BasicDeal/BasicDeal";
import AdvancedDeal from "./components/AdvancedDeal/AdvancedDeal";
import ExclusiveAudience from "./components/ExclusiveAudience/ExclusiveAudience";
import useCustomHeader from "../../../../hooks/useCustomHeader";
import * as fromActions from "../../../../store/actions";
import RedeemedLimit from "./components/RedeemedLimit/RedeemedLimit";
import {
  getPromotion,
  createPromotion,
  updatePromotion,
} from "../../../../services/promotions";

import {
  availableTargetAudience,
} from "./helpers/emptyDeal";

import styles from "./deal-details.module.scss";
import styles2 from "_metronic/_assets/sass/loading-text.module.scss";
import { useCustomResponse } from "app/hooks/useCustomResponse";


export function DealDetails({
  selectedPromotion,
  clearSelectedPromotion,
  filter: { locations },
}) {
  const { id, isNew } = useCustomHeader({
    property: "name",
    getRelatedItem: () => selectedPromotion,
    isCreationAllowed: true,
    createNew: "Promotion",
  });
  const { push } = useHistory();
  const [deal, setDeal2] = useState({});
  const [selectedDealType, setSelectedDealType] = useState("advanced");
  const [selectedFrequency, setSelectedFrequency] = useState("none");
  const [selectedTargetAudience, setSelectedTargetAudience] = useState("ALL");
  const [products, setProducts] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [subHeaderName, setSubHeaderName] = useState("");
  const [tags, setTags] = useState("");
  const [dealReceived, setDealReceived] = useState(false);
  const [updateDeal, setUpdateDeal] = useState({
    locations: {
      add: [],
      remove: [],
    },
  });
  const [goBackActive, setGoBackActive] = useState(false);
  const [isLocationSelected, setIsLocationSelected] = useState(true);
  const [isNameSet, setNameIsSet] = useState(true);
  const [noThresholdQuantity, setNoThresholdQuantity] = useState(false);
  const [noConditionProducts2, setNoConditionProducts2] = useState(false);
  const [noThresholdAmount, setNoThresholdAmount] = useState(false);  
  const [noConditionTargetType, setNoConditionTargetType] = useState(false);  
  const [noTargetActionType, setNoTargetActionType] = useState(false);
  const [noDiscountAmount, setNoDiscountAmount] = useState(false);
  const [noDiscountAmount2, setNoDiscountAmount2] = useState(false);
  const [noRedeemedLimit, setNoRedeemedLimit] = useState(false)
  const [noActionProducts, setNoActionProducts] = useState(false); 
  const [isBuyValue, setBuyValue] = useState(true);
  const [isSpendValue, setSpendValue] = useState(true);
  const [isGetValue, setGetValue] = useState(true);
  const [isRedeemValue, setRedeemValue] = useState(true);
  const [responseValue, setResponseValue] = useCustomResponse();
  const isAlphaNumeric = str => /^[0-9-]+$/gi.test(str);

  const locations2 = useSelector(
    (state) => state.subHeader.compressedLocationList
  );

  function setDeal(deal) {
    setDeal2(deal);
  }

  /**
   * Fetch on component init
   */
  useEffect(() => {
    if (!accessPermission("PromosAndCouponsAll")) {
      push({ pathname: "/dashboard" });
    }

    if (products.length) return;
    handleSelectedLocations();
    getDeal();
    setSelectedDealType("advanced");
    return () => clearSelectedPromotion();
  }, []);

  useEffect(() => {
    const subheader = document.getElementById("subheader-title");
    /**
     * Manually set subheader
     */
    subheader.innerText = subHeaderName;
  }, [subHeaderName]);

  /**
   * Handle request and store locations inside the component
   */
   function FindOutSelectedLocations(locations) {
    const filteredLocationsId = locations.map((ele) => {
      return ele.id;
    });
    setSelectedLocations(filteredLocationsId);
  }

  async function getDeal() {
    if (id != "new") {
      const [err, res] = await to(getPromotion(id));
      setIsLoaded(true);
      if (err) {
        Sentry.captureException(err);
      }
      setSubHeaderName(res?.data?.name);
      setDeal(res?.data);
      setDealReceived(true);
      setTags(res?.data?.tags);
      FindOutSelectedLocations(res?.data?.locations);
    } else {
      setSubHeaderName("Create New");
      setIsLoaded(true);

      let date = new Date();
      setDeal({
        status: "active",
        actionTargetType: "",
        conditionTargetType: "",
        discountAmount: null,
        discountType: "monetaryPercent",
        image: "",
        AccountId: getLoggedUserAccountId2(),
        tags: [],
        locations: [],
        products: [],
        frequencyMetric: "order",
        startDate: moment(date).format("MM/DD/YYYY h:mm a"),
        endDate: moment(date).format("MM/DD/YYYY h:mm a"),
      });
    }
  }


  function parseDealType() {
    setSelectedDealType("advanced");
  }

  function parseTarget() {
    if (
      deal?.targetAudienceType === "ALL" &&
      deal?.couponCode === null &&
      deal?.userGroupId === null
    )
      setSelectedTargetAudience("ALL");
    else setSelectedTargetAudience("exclusive");
  }

  function handleSelectedLocations() {
    if (deal?.location) setSelectedLocations(deal.locations.map((l) => l.id));
  }

  function selectLocationsChange(value) {
    if (value.length > 0 && !isLocationSelected) {
      setIsLocationSelected(true);
    }
    if (value.length === 0) {
      setIsLocationSelected(false);
    }
    setSelectedLocations(value);
    var formattedLocations = value.map(function(id) {
      return {
        id: id,
      };
    });

    setDeal({ ...deal, locations: formattedLocations });
    var dealLocationsIds = [];
    if (deal.locations) {
      dealLocationsIds = deal.locations?.map((loc) => {
        return loc.id;
      });
    }

    let addLocations = [];
    let removeLocations = [];

    value.forEach((id) => {
      if (!dealLocationsIds.includes(id)) {
        addLocations.push(id);
      }
    });
    dealLocationsIds.forEach((id) => {
      if (!value.includes(id)) {
        removeLocations.push(id);
      }
    });

    setUpdateDeal({
      ...updateDeal,
      locations: { add: addLocations, remove: removeLocations },
    });
  }

  /**
   * Get related deal from store by url param
   */
  useEffect(() => {
    if (id != "new") {
      parseTarget();
      parseDealType();
    }

  }, [id, deal]);

  /**
   * Update component state according to the received Deal
   */
  useEffect(() => {
    const {
      frequencyMetric,
      frequencyQuantity,
    } = selectedPromotion;

    if (!frequencyMetric && !frequencyQuantity) setSelectedFrequency("none");
    else if (!frequencyMetric && !!frequencyQuantity)
      setSelectedFrequency("promotion");
  }, [selectedPromotion]);

  /**
   * Change general section property
   */
  function changeDealProperty({ target: { value, name } }) {
    setDeal({
      ...deal,
      [name]: value,
    });
    if (name === "endDate" || name === "status") {
      setUpdateDeal({ ...updateDeal, [name]: value });
    }
  }

  function changeTagsProperty({ target: { value, name } }) {
    const tagsArray = value.split(",");
    setTags(tagsArray);
    const tagsArrayTrimmed = tagsArray.map((item) => item.trim());
    // poping last element if its empty
    if (tagsArray[tagsArray.length - 1] === "") tagsArrayTrimmed.pop();

    setDeal({
      ...deal,
      [name]: tagsArrayTrimmed,
    });
  }

  function changeDealProperty2({ target: { name } }) {
    const isEarnLoyalty = !deal?.canEarnLoyalty;
    setDeal({ ...deal, [name]: isEarnLoyalty });
  }

  /**
   * Add new updated value to the deal object
   */
  function updateDealProperty(update) {
    setDeal((cur) => ({ ...cur, ...update }));
    setNoRedeemedLimit(true);
  }

  /**
   * Send entered/updated data to store on server
   */
  
  async function handleUpdatePromotion() {
      setNoThresholdQuantity(false);
      setNoConditionProducts2(false);
      setNoThresholdAmount(false);
      setNoConditionTargetType(false);
      setNoDiscountAmount(false);
      setNoActionProducts(false);
      setNoDiscountAmount2(false);
      setNoTargetActionType(false);
      setNameIsSet(true);
      setNoRedeemedLimit(false);
      setIsLocationSelected(true);
      setSpendValue(true);
      setGetValue(true);
      setBuyValue(true);
      setRedeemValue(true);
    if(!deal?.name || deal?.locations.length === 0 || deal.conditionTargetType === "" || deal.actionTargetType === "" || !deal?.maxUse
       || (!deal?.thresholdAmount || !deal?.discountAmount) && (deal.conditionTargetType === "AllCart" || deal.actionTargetType === "AllCart")
       || (!deal.thresholdQuantity || !deal?.discountAmount || deal?.products.length == 0) && (deal.conditionTargetType === "Product" || deal.actionTargetType === "Product")
      ) {
      if(!deal.name){
        setNameIsSet(false);
      }
      if(deal?.locations.length === 0){
        setIsLocationSelected(false);
      }
      if(deal.conditionTargetType === "Product"){
        setNoThresholdQuantity(true);
        setNoConditionProducts2(true);
      }else if (deal.conditionTargetType === "AllCart"){
        setNoThresholdAmount(true);
      }else{
        setNoConditionTargetType(true);
      }
      if(deal.actionTargetType === "Product"){
        setNoDiscountAmount(true);
        setNoActionProducts(true);
      }else if(deal.actionTargetType === "AllCart"){
        setNoDiscountAmount2(true);
      }else{
        setNoTargetActionType(true);
      }
      if(!deal?.maxUse){
        setNoRedeemedLimit(true);
      }
      return;
    }
    if((deal?.thresholdQuantity < 0 || !isAlphaNumeric(deal?.thresholdQuantity)) && deal.conditionTargetType === "Product"){
      setBuyValue(false);
      if(deal?.discountAmount < 0 || !isAlphaNumeric(deal?.discountAmount)){
        setGetValue(false);
      }
      if(deal?.maxUse < 0 || !isAlphaNumeric(deal?.maxUse)){
        setRedeemValue(false);
      }
      return;
    }else if((deal?.thresholdAmount < 0 || !isAlphaNumeric(deal?.thresholdAmount)) && deal.conditionTargetType === "AllCart"){
      setSpendValue(false);
      if(deal?.discountAmount < 0 || !isAlphaNumeric(deal?.discountAmount)){
        setGetValue(false);
      }
      if(deal?.maxUse < 0 || !isAlphaNumeric(deal?.maxUse)){
        setRedeemValue(false);
      }
      return;
    }else if(deal?.discountAmount < 0 || !isAlphaNumeric(deal?.discountAmount) || deal?.maxUse < 0 || !isAlphaNumeric(deal?.maxUse)){
      setGetValue(false);
      setRedeemValue(false);
      return;
    }
        
    let newDeal = deal;
    if (newDeal.id) {
      delete newDeal.id;
      newDeal.status = 'active'
      const productsList = newDeal.products.map((ele) => {
        return {
          excludeAction: ele.excludeAction,
          excludeCondition: ele.excludeCondition,
          key: ele.key,
          type: ele.type,
          usedfor: ele.usedfor,
          uuidkey: ele.uuidkey,
        };
      });

      newDeal = { ...newDeal, products: productsList };
    }
    if(selectedTargetAudience === "ALL"){
      newDeal = { ...newDeal, couponCode: null, userGroupId: null}
    }

    const [err, res] = await to(
      createPromotion([
        {
          ...newDeal,
          locations: newDeal.locations?.map((loc) => {
            return { id: loc.id };
          }),
        },
      ])
    );
    if (err) {
      setResponseValue({
        ...responseValue,  
        message: `There was a problem creating deal. ${err}`,
        isSuccess: false,
        isMessageOpen: true,
      });
      Sentry.captureException(err);
    } else {
      setResponseValue({
        ...responseValue,
        message: "Deal created!",
        isSuccess: true,
        isMessageOpen: true,
      });
      push({ pathname: `/promotions` });

    }
  }

  /**
   * Send entered/updated data to store on server
   */
  async function deactivateDeal() {
    const [err, res] = await to(
      updatePromotion(deal.id, { status: "inactive" })
    );
    if (err) {
      Sentry.captureException(err);
      setResponseValue({
        ...responseValue,
        message: `There was a problem deactiving the deal. ${err}`,
        isSuccess: false,
        isMessageOpen: true,
      });
    } else {
      setResponseValue({
        ...responseValue,
        message: "Deal deactivated!",
        isSuccess: true,
        isMessageOpen: true,
      });
      push({ pathname: `/promotions` });
    }
  }

  function locationItems() {
    return locations2.map((location) => (
      <MenuItem value={location.id} key={location.id}>
        {location.name}
      </MenuItem>
    ));
  }

  function handleCouponChange(name, value) {
    setDeal({ ...deal, [name]: value });
  }

  function handleResponseModelClose(value) {
    setResponseValue({
      ...responseValue,
      isMessageOpen: value,
    });
    
    if (goBackActive) push({ pathname: `/promotions` });
  }

  return (
    <Card id={`deal_details_card_root_wrapper`}>
      {!isLoaded && (
        <div id={`deal_details_card_loading_wrapper`} className={styles2.loadingContainer}>
          <p id={`deal_details_card_loading_content`} className={styles2.loadingText}>Loading...</p>
        </div>
      )}
      <CardContent
      id={`deal_details_card_content_wrapper_0`}
        className={clsx("d-flex", "justify-content-end", styles.headerWrapper)}
      >
        {!isNew ? (
          <Button
            id={`deal_details_cancel_button`}
            className={styles.cancelBtn}
            startIcon={<Close />}
            type="button"
            size="medium"
            variant="outlined"
            color="secondary"
            disableElevation
            onClick={() => deactivateDeal()}
          >
            Cancel Deal
          </Button>
        ) : null}

        <Button
         id={`deal_details_save_button`}
          startIcon={<Check />}
          type="button"
          size="medium"
          variant="contained"
          color="primary"
          disableElevation
          onClick={() => handleUpdatePromotion()}
        >
          {isNew ? "Save" : "Save as new deal"}
        </Button>
      </CardContent>

      <CardContent  id={`deal_details_card_content_wrapper_1`}>
        <Typography id={`deal_details_card_general_title`} variant="h5">General</Typography>
        <Divider />

        <div id={`deal_details_card_row_outer_wrapper`} className={clsx(styles.rowWrapper, styles.removeMargin)}>
          <div id={`deal_details_card_general_form_wrapper`} className={styles.generalForms}>
            <div id={`deal_details_card_general_form_row_wrapper`}  className={styles.generalFormRow}>
              <div id={`deal_details_card_general_form_field_wrapper_0`}  className={styles.generalFromField}>
                <FormControl id={`deal_details_card_general_form_control_0`} fullWidth>
                  <TextField
                    inputProps={{
                      maxLength: 30,
                    }}
                    id="dealDetails_promotionName"
                    name="name"
                    label="Promotion Name"
                    helperText="Max Characters: 30"
                    value={deal?.name ? deal?.name : ""}
                    onChange={changeDealProperty}
                    variant="outlined"
                    required
                  />
                </FormControl>
                {!isNameSet && !(deal?.name) && (
                  <FormHelperText id={`deal_details_card_general_form_control_helper_text_1`} style={{ color: "red", fontSize: "1rem", paddingLeft: "30px" }}>
                    This is required!
                  </FormHelperText>
                )}
              </div>
              <div id={`deal_details_card_general_form_field_wrapper_1`} className={styles.generalFromField}>
                <FormControl id={`deal_details_card_general_form_control_1`} fullWidth>
                  <TextField
                    id="dealDetails_shortDescription"
                    inputProps={{
                      maxLength: 70,
                    }}
                    name="description"
                    label="Short Description"
                    helperText="Max Characters: 70"
                    value={deal?.description ? deal?.description : ""}
                    onChange={changeDealProperty}
                    variant="outlined"
                  />
                </FormControl>
              </div>
              <div  id={`deal_details_card_general_form_field_wrapper_2`} className={styles.generalFromField} >
                <FormControl id={`deal_details_card_general_form_control_2`} fullWidth>
                  <TextField
                    inputProps={{
                      maxLength: 70,
                    }}
                    id="dealDetails_tagsName"
                    name="tags"
                    label="Tags"
                    value={tags}
                    onChange={changeTagsProperty}
                    variant="outlined"
                  />
                </FormControl>
              </div>
              <div id={`deal_details_card_general_form_field_wrapper_2`} className={styles.generalFromField}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDateTimePicker
                    disablePast={false}
                    className={styles.noMargin}
                    inputVariant="outlined"
                    variant="inline"
                    format="MM/dd/yyyy h:mm a"
                    margin="normal"
                    id="dealDetails_start-date"
                    name="startDate"
                    label="Start Date & Time"
                    allowKeyboardControl
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    value={deal?.startDate}
                    onChange={(e) =>
                      changeDealProperty({
                        target: { name: "startDate", value: e },
                      })
                    }
                  />
                </MuiPickersUtilsProvider>
              </div>
              <div id={`deal_details_card_general_form_field_wrapper_3`} className={styles.generalFromField}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDateTimePicker
                    disablePast={true}
                    className={styles.noMargin}
                    inputVariant="outlined"
                    variant="inline"
                    format="MM/dd/yyyy h:mm a"
                    margin="normal"
                    id="dealDetails_end-date"
                    name="endDate"
                    label="End Date & Time"
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    value={deal?.endDate}
                    onChange={(e) =>
                      changeDealProperty({
                        target: { name: "endDate", value: e },
                      })
                    }
                  />
                </MuiPickersUtilsProvider>
              </div>
              <div id={`deal_details_card_general_form_field_wrapper_4`} className={styles.generalFromField}>
                <FormControl id={`deal_details_card_general_form_control_3`} variant="outlined" fullWidth>
                  <InputLabel id="outlet">Locations</InputLabel>
                  <Select
                    multiple={true}
                    labelId="outlet"
                    id="dealDetails_locations"
                    value={selectedLocations}
                    onChange={(e) => selectLocationsChange(e.target.value)}
                    label="Location"
                  >
                    {locationItems()}
                  </Select>
                </FormControl>
                {!isLocationSelected && !(selectedLocations.length > 0) && (
                  <FormHelperText id={`deal_details_card_general_form_control_helper_text_2`} style={{ color: "red", fontSize: "1rem", paddingLeft: "30px" }}>
                    This is required!
                  </FormHelperText>
                )}
              </div>
            </div>
          </div>
          <div id={`deal_details_card_general_form_field_wrapper_5`} className={styles.preview}>
            <div id={`deal_details_card_general_form_field_wrapper_6`} className="d-flex">
              <i className="fa fa-ticket-alt" />
              <div id={`deal_details_card_general_form_field_wrapper_7`} className={clsx("d-flex", "flex-column", styles.title)}>
                <Typography id={`deal_details_card_general_form_field_wrapper_7`} variant="h5">
                  {deal?.name ? deal?.name : "Promotion Name"}
                </Typography>
                <Typography id={`deal_details_card_general_form_field_desceiption_typography`} variant="subtitle1" color="textSecondary">
                  {deal?.description
                    ? deal?.description
                    : "Provide a short description to explain this promotion."}
                </Typography>
              </div>
            </div>
            <Divider />
            <Typography
              id={`deal_details_card_general_form_field_caption_desceiption_typography`}
              className={styles.caption}
              variant="caption"
              color="textSecondary"
            >
              The name and description will be shown to both cashiers and
              customers, to identify and explain the promotion
            </Typography>
          </div>
        </div>
      </CardContent>

      <CardContent id={`deal_details_card_content_wrapper_2`} >
        <Typography id={`deal_details_card_typography_label`} variant="h5">Deal Details</Typography>
        <Divider />

        {selectedDealType === "basic" && (
          <BasicDeal
            discountType={deal?.discountType}
            discountAmount={deal?.discountAmount}
            products={deal?.products || []}
            availableProducts={products}
            handleChange={updateDealProperty}
          />
        )}

        {selectedDealType === "advanced" && (
          <AdvancedDeal
            deal={deal}
            availableProducts={products}
            setDeal={setDeal}
            products={deal?.products || []}
            handleChange={updateDealProperty}
            isNew={isNew}
            isBuyValue={isBuyValue}
            isSpendValue={isSpendValue}
            isGetValue={isGetValue}
            noThresholdQuantity={noThresholdQuantity}
            noConditionProducts2={noConditionProducts2}
            noThresholdAmount={noThresholdAmount}
            noConditionTargetType={noConditionTargetType}
            noDiscountAmount={noDiscountAmount}
            noDiscountAmount2={noDiscountAmount2}
            noTargetActionType={noTargetActionType}
            noActionProducts={noActionProducts}
          />
        )}

        <RedeemedLimit 
          handleChange={updateDealProperty} 
          maxUse={deal.maxUse} 
          noRedeemedLimit={noRedeemedLimit} 
          isRedeemValue={isRedeemValue} 
        />
      </CardContent>

      <CardContent id={`deal_details_card_content_wrapper_3`}>
        <Typography  id={`deal_details_card_content_typography_target`} variant="h5">Target this Promotion</Typography>
        <Divider />
        <div  id={`deal_details_card_content_target_wrapper`} className={styles.targetAudienceWrapper}>
          {availableTargetAudience.map(({ id, value, ...rest }) => (
            <DealTypeButton
              key={id}
              {...rest}
              value={value}
              isActive={value === selectedTargetAudience}
              handleClick={setSelectedTargetAudience}
            />
          ))}
        </div>

        {selectedTargetAudience === "exclusive" ? (
          <ExclusiveAudience
            userGroupId={deal?.userGroupId || ""}
            promoCodes={deal?.couponCode || ""}
            redemptions={deal?.redemptions || ""}
            handleChange={handleCouponChange}
            isNew={isNew}
          />
        ) : null}
      </CardContent>

      <CardContent id={`deal_details_card_content_wrapper_4`}>
        <Typography id={`deal_details_card_content_loyalty_label`} variant="h5">Offer Loyalty with Promotion</Typography>
        <Divider />
        <div id={`deal_details_card_content_loyalty_wrapper`} className={styles.loyalty}>
          <div id={`deal_details_card_content_inner_loyalty_wrapper`} className={styles.loyaltyMsg}>
            <Typography id={`deal_details_card_content_loyalty_description`} variant="subtitle2">
              Choose to offer or not offer Loyalty to your customers as part
              of this promotion.
            </Typography>
          </div>
          <div id={`deal_details_card_content_inner_loyalty_body_wrapper`} className={styles.loyaltyBody}>
            <FormControlLabel
              id={`deal_details_card_content_loyalty_form_control`}
              control={
                <Checkbox
                  id="dealDetails_earnLoyalty"
                  checked={deal?.canEarnLoyalty ? true : false}
                  onChange={changeDealProperty2}
                  name="canEarnLoyalty"
                  color="primary"
                />
              }
              label="Customers Can Earn Loyalty With This Promotion"
            />
          </div>
        </div>
      </CardContent>
      <ResponseModal
        isSuccess={responseValue?.isSuccess}
        message={responseValue?.message}
        open={responseValue?.isMessageOpen}
        isServerError={responseValue?.isServerError}
        onClose={(value) => handleResponseModelClose(value)}
      />
    </Card>
  );
}

export default connect(
  (state) => ({
    selectedPromotion: state.promotions.selectedPromotion,
  }),
  {
    selectActivePromotion: fromActions.promotionsActions.selectActivePromotion,
    clearSelectedPromotion:
      fromActions.promotionsActions.clearSelectedPromotion,
  }
)(DealDetails);

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import to from 'await-to-js';
import {
  Tabs,
  Tab,
  Paper,
  Button,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { isAfter } from 'date-fns';
import { useHistory } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import { accessPermission } from 'app/common/helperFunction';
import { hasMultipleAccounts } from 'app/services/helpers/account-id';
import LoadingSpinner from 'app/partials/content/LoadingSpinner';

import PromotionsTable from './components/PromotionsTable/PromotionsTable';
import { getPromotions } from '../../services/promotions';
import * as fromActions from '../../store/actions';
import ResponseModal from '../Helpers/ResponseModal';
import OperatorSpecificModal from '../Helpers/OperatorSpecificModal';

import styles from './promotions.module.scss';
import { useCustomResponse } from 'app/hooks/useCustomResponse';
import { ALL_LOCATIONS } from 'app/common/constant';

let currentSelectedTab = null;

export function Promotions(props) {
  const {
    filter: { locations, location },
    storePromotions,
    promotionsList
  } = props;
  const { push } = useHistory();
  const [promotions, setPromotions] = useState(promotionsList);
  const [currentTab, setCurrentTab] = useState(currentSelectedTab ?? 'current');
  const [isLoaded, setIsLoaded] = useState(false);
  const [operatorSpecificModalOpen, setOperatorSpecificModalOpen] = useState(false);
  const [responseValue, setResponseValue] = useCustomResponse();
  
  useEffect(() => {
    if (!accessPermission("PromosAndCouponsAll")) {
      push({ pathname: "/dashboard" });
    }
  }, []);

  /**
   * Change promotions on tab change
   */
  useEffect(() => {
    setPromotions(filterPromotionsForTabs());
  }, [currentTab, promotionsList]);

  /**
   * Fetch data only when locations context has the data
   */
  useEffect(() => {
    if (locations && locations.length)
      getPromotionsData();
  }, [locations]);

  /**
   * Handle data fetch and error processing for promotions
   */
  async function getPromotionsData() {
    if(hasMultipleAccounts()){
      setOperatorSpecificModalOpen(true);
      return
    }
    const locationIds = location === ALL_LOCATIONS.id
      ? locations?.filter(eachLocation => eachLocation.id !== ALL_LOCATIONS.id).map(eachLocation => eachLocation.id)
      : [locations?.find(eachLocation => eachLocation.id === location)?.id];
    const [err, res] = await to(getPromotions(locationIds));

    if (err) {
      Sentry.captureException(err);
      setResponseValue({...responseValue, isMessageOpen: true, isSuccess:false, message:`Error while getting promotion details. ${err}`});     
      return console.error('Promotions not fetched: ', err);
    }
    storePromotions(res.data.Deals);
    setIsLoaded(true);
  }

  function filterPromotionsForTabs() {
    return promotionsList.filter(
      promotion => (
        currentTab === 'current'
        && promotion && promotion.endDate
        && !isAfter(new Date(), new Date(promotion.endDate)) && promotion.status == 'active'
      ) || (
          currentTab === 'past'
          && promotion && promotion.endDate
          && isAfter(new Date(), new Date(promotion.endDate))
        ) || (
          currentTab === 'inactive'
          && promotion && promotion.status
          && promotion.status == 'inactive'
          && !isAfter(new Date(), new Date(promotion.endDate)))
         || currentTab === 'all'
    );
  }

  return (
    <>
    {!isLoaded ? (
      <LoadingSpinner/>
    ) : (
    <>
      <div id={`promotiond_button_wrapper`} className={styles.buttonWrapper}>
        <Button
          id={`promotiond_add_button`} 
          startIcon={<Add />}
          size="large"
          variant="contained"
          color="primary"
          disableElevation
          onClick={() => push({ pathname: '/promotions/new' })}
        >
          Add Promotion
        </Button>
      </div>

      <Paper id={`promotiond_paper_tabs_wrapper`}  className={styles.tabsWrapper}>
        <Tabs
           id={`promotiond_paper_tabs_inner_wrapper`}
          value={currentTab}
          onChange={(e, value) => { setCurrentTab(value); currentSelectedTab = value }}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab  id={`promotiond_paper_upcomming_tab`} label="Current & Upcoming" value="current" />
          <Tab id={`promotiond_paper_expire_tab`} label="Expired" value="past" />
          <Tab id={`promotiond_paper_canceled_tab`} label="Canceled" value="inactive" />

          <Tab id={`promotiond_paper_all_tab`} label="All" value="all" />
        </Tabs>
      </Paper>
      <PromotionsTable
        currentTab
        rows={promotions}
      />
      <ResponseModal
        isSuccess={responseValue?.isSuccess}
        message={responseValue?.message}
        open={responseValue?.isMessageOpen}
        onClose={value => setResponseValue({...responseValue, isMessageOpen:value})}
      />
    </>)}
      {operatorSpecificModalOpen && (
        <OperatorSpecificModal
          open={operatorSpecificModalOpen}
          onClose={(value) => setOperatorSpecificModalOpen(value)}
        />
      )}
    </>
  );
}

export default connect(
  state => ({
    promotions: state.promotions.promotions,
    promotionsList: state.promotions.promotionsList
  }),
  {
    storePromotions: fromActions.promotionsActions.storePromotions
  }
)(Promotions);

import React, { useRef, useMemo } from 'react';
import clsx from 'clsx';
import objectPath from 'object-path';
import { useHistory, Link } from 'react-router-dom';

import MenuItemText from './MenuItemText';
import MenuSubmenu from './MenuSubmenu';

const isDropdown = () => document.body.classList.contains('kt-aside-menu--dropdown');

export default function MenuItem(props) {
  const { push } = useHistory();
  const {
    item,
    currentUrl,
    parentItem
  } = props;
  const asideLeftLIRef = useRef();
  const submenuToggle = useMemo(() => {
    if (item.toggle === 'click')
      return 'click';

    if (objectPath.get(item, 'submenu.type') === 'tabs')
      return 'tabs';

    return 'hover';
  }, [item]);

  /**
   * Use for fixed left aside menu, to show menu on mouseenter event.
   */
  function mouseEnter() {
    if (!isDropdown())
      return;

    if (!item.submenu)
      return;

    asideLeftLIRef.current.classList.add('item-hovered');
  }

  /**
   * Mouse Leave event
   */
  function mouseLeave() {
    if (!isDropdown())
      return;

    if (item.submenu && submenuToggle === 'hover')
      asideLeftLIRef.current.classList.remove('item-hovered');
  }

  function isMenuItemIsActive(item) {
    if (item.submenu)
      return isMenuRootItemIsActive(item);

    if (!item.page)
      return false;

    return currentUrl.startsWith(`/${item.page}`);
  }

  function isMenuRootItemIsActive(item) {
    for (const subItem of item.submenu)
      if (isMenuItemIsActive(subItem))
        return true;

    return false;
  }

  function onParentLinkClick() {
    const firstChildLink = item.submenu[0].page;
    const firstChildNewTab = item.submenu[0].newTab ?? false;

    !firstChildNewTab && push({ pathname: `/${firstChildLink}` });
  }

  return (
    <li
      ref={asideLeftLIRef}
      id={"kt-menu__item"}
      aria-haspopup="true"
      data-placement="right"
      data-ktmenu-submenu-mode={item.mode}
      onMouseEnter={mouseEnter}
      onMouseLeave={mouseLeave}
      className={clsx(
        'kt-menu__item',
        {
          'navigation-link': item.submenu,
          'kt-menu__item--open kt-menu__item--here': isMenuItemIsActive(item) && item.submenu,
          'kt-menu__item--active kt-menu__item--here': isMenuItemIsActive(item) && !item.submenu,
          'kt-menu__item--icon-only': item['icon-only']
        },
        item['custom-class']
      )}
      data-ktmenu-dropdown-toggle-class={item['dropdown-toggle-class']}
    >
      {!item.submenu && (
        <Link
          to={`/${item.page}`}
          className="kt-menu__link kt-menu__toggle"
          id="kt-menu__link kt-menu__toggle"
        >
          <MenuItemText item={item} parentItem={parentItem}/>
        </Link>
      )}

      {item.submenu && (
        // eslint-disable-next-line
        <a  id="kt-menu__link kt-menu__toggle" className="kt-menu__link kt-menu__toggle" onClick={() => onParentLinkClick(item)}>
          <MenuItemText item={item} parentItem={parentItem}/>
        </a>
      )}

      {item.submenu && (
        <div id="navigation-submenu" className="navigation-submenu">
          <MenuSubmenu
            item={item}
            parentItem={item}
            currentUrl={currentUrl}
          />
        </div>
      )}
    </li>
  );
}

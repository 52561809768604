import React from "react";
import { FormHelperText, Grid, TextField, Typography } from "@material-ui/core";

export default function RedeemedLimit(props) {
    const { handleChange, maxUse, noRedeemedLimit, isRedeemValue } = props;
  return (
      <div id={`deal_details_redeemed_limit_wrapper`}>
        <Grid id={`deal_details_redeemed_limit_grid_container`} container  direction="row" justifyContent="center" alignItems="center">
          <Grid id={`deal_details_redeemed_limit_inner_grid_container`} style={{marginTop: 15}} container direction="row" justifyContent="center" alignItems="center">
            <Typography id={`deal_details_redeemed_limit_typography`} variant="h6">{"Which can be redeemed"}</Typography>
            <TextField
              id="dealDetails_redeemLimit"
              style={{marginRight: 10, marginLeft: 10}}
              placeholder="0.00"
              value={maxUse || null}
              variant="outlined"
              onChange={(e) => handleChange({"maxUse" : e.target.value})}
              error={!isRedeemValue}
              helperText={!isRedeemValue ? "Enter Valid Value" : ""}
            />
            <Typography  id="dealDetails_times" variant="h6">{"times per order"}</Typography>
          </Grid>
            {noRedeemedLimit && !maxUse && (
              <FormHelperText  id="dealDetails_redeemLimit_time_helper_text" style={{ color: "red", fontSize: "1rem", paddingLeft: "30px"}}>
                This is required!
              </FormHelperText>
            )}
        </Grid>
      </div>
  );
}

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
  Checkbox,
  IconButton,
  makeStyles
} from '@material-ui/core';

import { EditSharp } from '@material-ui/icons';
import ReactTooltip from "react-tooltip";

import { getDefaultStyles } from '../../../services/table';

const headCells = [
  { id: 'id', numeric: false, disablePadding: true, label: 'Ticket ID' },
  { id: 'requester', numeric: false, disablePadding: false, label: 'Requester' },
  { id: 'requesterUpdated', numeric: false, disablePadding: false, label: 'Requester Updated Date' },
  { id: 'description', numeric: false, disablePadding: false, label: 'Description' },
  { id: 'actions', numeric: false, disablePadding: false, label: 'Actions' }
];

function EnhancedTableHead(props) {
  const {
    numSelected,
    rowCount,
    onSelectAllClick
  } = props;

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired
};

const useStyles = makeStyles(getDefaultStyles);

export default function TicketTable(props) {
  const classes = useStyles();
  const [selected, setSelected] = useState([]);
  const {
    rows,
    onEditRowClick,
    setPagination,
    totalTickets,
    pagination:{ page, limit:rowsPerPage } = { page:1, limit:10 }
  } = props;

  /**
   * Handle pagination change
   * @param {Synthetic Event} param0
   * @param {number} newPage
   */
   function handlePaginationChange({ target: { value } }, newPage = -1) {
    if (value) {
      const newSize = parseInt(value, 10);
      const newP = parseInt(((page-1) * rowsPerPage + 1) / newSize) ;

      setPagination({
        page: newP + 1,
        limit: newSize
      });
    }

    if (newPage >= 0 && typeof newPage !== 'object') {
      setPagination({
        page: newPage + 1,
        limit: rowsPerPage
      });
    }
  }


  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1)
      newSelected = newSelected.concat(selected, name);
    else if (selectedIndex === 0)
      newSelected = newSelected.concat(selected.slice(1));
    else if (selectedIndex === selected.length - 1)
      newSelected = newSelected.concat(selected.slice(0, -1));
    else if (selectedIndex > 0)
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );

    setSelected(newSelected);
  };

  const isSelected = id => selected.indexOf(id) !== -1;

  /**
   * Handle click on Edit icon
   * @param e
   * @param row
   */
  function onEditIconClick(e, row) {
    e.stopPropagation();

    onEditRowClick(row);
  }
  var moment = require('moment');

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="small"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={()=>{}}
              rowCount={rows.length}
            />
            <TableBody>
              {rows.map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={event => handleClick(event, row.id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </TableCell>
                      <TableCell component="th" id={labelId} scope="row" padding="none">
                        {row.id}
                      </TableCell>
                      <TableCell align="left" padding="normal">
                        {row.User ? row?.User?.firstName +" "+ row?.User?.lastName : ''}
                      </TableCell>
                      <TableCell align="left" padding="normal">{moment(row.updated).fromNow()}</TableCell>
                      <TableCell align="left" padding="normal">
                        {row.description.length <= 30 ? row.description : 
                          <div>
                            <ReactTooltip
                              id={`descriptionTip1${index}`}
                              place="top"
                              effect="solid"
                            >
                              {row.description}
                            </ReactTooltip>
                            <a data-tip data-for={`descriptionTip1${index}`}>
                              {row.description.slice(0,28) + "..."}  
                            </a>
                          </div>
                        }
                      </TableCell>
                      <TableCell align="left" padding="normal">
                        <IconButton onClick={e => onEditIconClick(e, row)}>
                          <EditSharp/>
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={totalTickets}
          rowsPerPage={rowsPerPage}
          page={page -1}
          onPageChange={handlePaginationChange}
          onRowsPerPageChange={handlePaginationChange}
        />
      </Paper>
    </div>
  );
}

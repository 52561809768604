import React, { useState } from 'react';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormControl
} from '@material-ui/core';
import { format } from 'date-fns';

import styles from './barcodes.module.scss';

export default function Barcodes(props) {
  const [open, setOpen] = useState(false);
  const [barcode, setBarcode] = useState('');
  const {
    barcodes = [],
    onAdd
  } = props;

  /**
   * Close barcode modal
   */
  function handleClose() {
    setOpen(false);
    setBarcode('');
  }

  function submitNewBarcode() {
    const newBarcode = {
      code: barcode,
      custom: true
    };

    onAdd(newBarcode);
    setBarcode('');
    setOpen(false);
  }

  return (
    <>
      <Table size="small" className={styles.wrapper}>
        <TableHead>
          <TableRow>
            <TableCell>Barcode</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {barcodes.map(row => (
            <TableRow key={row.barcodeId}>
              <TableCell>{row.code}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <DialogTitle id="simple-modal-title">Add new barcode</DialogTitle>
        <DialogContent className={styles.dialogInput} id="simple-modal-description">
          <FormControl fullWidth>
            <TextField
              id="barcodeCreation"
              label="Add Barcode"
              placeholder="Enter Barcode"
              variant="outlined"
              value={barcode}
              onChange={e => setBarcode(e.target.value)}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            size="large"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            size="medium"
            variant="contained"
            color="primary"
            disableElevation
            onClick={submitNewBarcode}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

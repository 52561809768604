import React, { useState, useEffect } from "react";
import to from "await-to-js";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import * as Sentry from "@sentry/react";
import {
  Button,
} from "@material-ui/core";
import csvDownload from "json-to-csv-export";
import "jspdf-autotable";
import { jsPDF } from "jspdf";
import { format } from "date-fns";
import { useDispatch } from "react-redux";

import LoadingSpinner from "app/partials/content/LoadingSpinner";
import ResponseModal from "app/components/Helpers/ResponseModal";
import { accessPermission } from "app/common/helperFunction";
import {
  getLoggedUserAccountId,
  hasMultipleAccounts,
} from "app/services/helpers/account-id";
import { getGiftCards, reportFormats } from "app/services/reports";
import OperatorSpecificModal from "app/components/Helpers/OperatorSpecificModal";
import { customerActions } from "app/store/actions";
import { CREATION_DATE_FORMAT, DATE_FORMAT, DELETED_USER, DELETED_USERGROUP, ONETIME, RECURRING } from "app/common/giftCardsConst";

import ExportAndCustomFilters from "../../../../partials/content/ExportAndCustomFilters";
import RecurringTable from "./RecurringTable";

import styles from "./giftCards.module.scss";
import { useCustomResponse } from "app/hooks/useCustomResponse";

export default function GiftCards() {
  const { push } = useHistory();
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
  });
  const [giftsTotal, setGiftsTotal] = useState(0);
  const [gifts, setGifts] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [operatorSpecificModalOpen, setOperatorSpecificModalOpen] = useState(
    false
  );
  const [responseValue, setResponseValue] = useCustomResponse()
  const dispatch = useDispatch()

  useEffect(() => {
    if (!accessPermission("GiftCards")) {
      push({ pathname: "/dashboard" });
    }
  }, []);

  /**
   * Fetch data on every pagination change and component mounting
   */
  useEffect(() => {
    getGifts();
    // eslint-disable-next-line
  }, [pagination]);

  /**
   * Proceed sales
   * @returns {Promise<void>}
   */
  async function getGifts() {
    if (hasMultipleAccounts()) {
      setOperatorSpecificModalOpen(true);
      return;
    }

    setIsLoaded(false);
    setGifts([]);

    const id = getLoggedUserAccountId();
    const [err, res] = await to(getGiftCards(id, pagination));

    setIsLoaded(true);
    if (err) {
      Sentry.captureException(err);
      setResponseValue({...responseValue, isMessageOpen: true, isSuccess:false, message:`Error while getting GiftCards ${err}`});     
      return console.error("[GiftCards]. Error while fetching data. ", err);
    }
    const { count, rows } = res.data.IssuedGiftCard;
    dispatch(customerActions.storeGiftCards(rows));
    setGiftsTotal(count);
    setGifts(rows);
  }

  async function handleExport(type) {
    const id = getLoggedUserAccountId();
    const [err, res] = await to(getGiftCards(id, {page:1, limit: giftsTotal}))

    if (err) {
      Sentry.captureException(err);
      setResponseValue({...responseValue,isMessageOpen: true, isSuccess:false, message:`Error while getting GiftCards ${err}`});    
      return console.error("[GiftCards]. Error while fetching data. ", err);
    }

    const {rows} = res.data.IssuedGiftCard;

    if(type === 'pdf'){

      const exportRows = rows.map(ele => [
        ele.user ? (ele.user?.email || DELETED_USER) : (ele.userGroup?.name || DELETED_USERGROUP),
        ele.amount? `$ ${ele.amount.toFixed(2)}`: '$ 0.00' ,
        ele.creationDate ? format(new Date(ele.creationDate), CREATION_DATE_FORMAT) : '',
        ele.recurring ? RECURRING : ONETIME ,
        ele.recurringIssueMetric != "0" ? ele.recurringIssueMetric[0].toUpperCase() + ele.recurringIssueMetric.slice(1) : 'Never',
       ]);

        const unit = "pt";
        const size = "A4";
        const orientation = "portrait";
        const marginLeft = 30;
        const doc = new jsPDF(orientation,unit, size);
        doc.setFontSize(15);

        const title = "Gift Cards";
        const headers = [['Name','Amount', 'Date of Issue', 'Type', 'Reissued']];

        let content = {
          startY: 50,
          head: headers,
          body: exportRows,
          styles: {overflow: 'linebreak',
                fontSize: 8},
        };

        doc.text(title, marginLeft, 30);
        doc.autoTable(content);
        doc.save(`Gift Cards - ${format(new Date(), DATE_FORMAT )}.${type}`);

    } else if (type === 'csv') {

        const exportRows = [];
        rows.forEach(ele => {
          let obj = {
            'Name': ele.user ? (ele.user?.email || DELETED_USER) : (ele.userGroup?.name || DELETED_USERGROUP),
            'Amount' : ele.amount? `$ ${ele.amount.toFixed(2)}`: "$ 0.00",
            'Date of Issue': ele.creationDate ? format(new Date(ele.creationDate), CREATION_DATE_FORMAT) : 'N/A' ,
            'Type': ele.recurring ? RECURRING : ONETIME,
            'Reissued': ele.recurringIssueMetric != "0" ? ele.recurringIssueMetric[0].toUpperCase() + ele.recurringIssueMetric.slice(1) : 'Never',
          }
          exportRows.push(obj);
        })

      csvDownload(exportRows, `Gift Cards - ${format(new Date(), DATE_FORMAT)}.${type}`);
    }
  }

  /**
   * Update pagination data on table pagination component change
   */
  function onPaginationChange(newPagination) {
    setPagination(newPagination);
  }

  return (
    <>
      <ExportAndCustomFilters onExport={handleExport}  newFilterTag={reportFormats.PDF}/>
      {!isLoaded ? (
        <LoadingSpinner/>
      ) : (
      <>
        <div
          id={`gift_cards_root_wrapper`}
          className={clsx(
            styles.searchWrapper,
            "d-flex",
            "justify-content-end",
            "align-items-center"
          )}
        >
          <div id={`gift_cards_button_wrapper`} className={styles.buttonWrapper}>
            <Button
              id={`gift_cards_add_gift_cards_button`} 
              size="large"
              variant="contained"
              color="primary"
              disableElevation
              onClick={() => push({ pathname: '/giftCards/new' })}
            >
              Add Gift Cards
            </Button>
          </div>
        </div>

        <div id={`gift_cards_recurring_table_wrapper`} className={styles.wrapper}>
          <RecurringTable
            rows={gifts}
            giftsTotal={giftsTotal}
            pagination={pagination}
            onPaginationChange={onPaginationChange}
          />
        </div>

        <ResponseModal
          isSuccess={responseValue?.isSuccess}
          isServerError={responseValue?.isServerError}
          message={responseValue?.message}
          open={responseValue?.isMessageOpen}
          onClose={(value) => setResponseValue({...responseValue, isMessageOpen:value})}
        />
      </>)}
      {operatorSpecificModalOpen && (
        <OperatorSpecificModal
          open={operatorSpecificModalOpen}
          onClose={(value) => setOperatorSpecificModalOpen(value)}
        />
      )}
    </>
  );
}

import axios from 'axios';
import { format } from 'date-fns';

import { CANCEL_TOKEN } from "app/common/constant";

import { getLoggedUserAccountId } from './helpers/account-id';
import { getDefaultParameters_V2, getStartDateAndEndDateInISOFormat } from './helpers/getDefaultParameters';


/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */

/**
 * Get stock information by current location id
 * @param {string} locationId
 * @param {'pdf' | 'csv'} exportType
 */
export function getLocationStocks(locationId, exportType) {
  let url = `/locations/${locationId}?includeInventory=true`;

  if (exportType)
    url += `&export=${exportType}`;

  return axios.get(url);
}

function setProductsQueryString() {

  const urlParams = new URLSearchParams(window.location.search);
  const today = new Date();
  const selectedTimeFilter = urlParams.get("period")
  const operatorId = getLoggedUserAccountId();
  const location = urlParams.get("location");
  
  const newParamsList = [""];
  const  { startDate, endDate } = getStartDateAndEndDateInISOFormat()
  newParamsList.push(`operator=${operatorId}`);
  newParamsList.push(`location=${location}`)
  newParamsList.push(`startDate=${startDate}`)
  newParamsList.push(`endDate=${endDate}`)
  return (newParamsList.join('&'));
}

/**
 * Get list of products
 */
const CancelToken = axios.CancelToken;
 let getLocationsProductsCancel = null;
export function getLocationsProducts({ page, limit }, filters, getAll = false) {
  if(getLocationsProductsCancel){
    getLocationsProductsCancel(CANCEL_TOKEN);
    getLocationsProductsCancel = null;
  }
  let productsUrl = `/locations/products?limit=${limit}&page=${page}` + setProductsQueryString();
  if (filters && Object.keys(filters).length) {
   
    const entries = Object.entries(filters);
    for (let i = 0, n = entries.length; i < n; ++i) {
      const [key, value] = entries[i];
      let val = value;
      if (value != null && value.includes('All'))
        continue;
        
      if (key === "status")
      val = value == "Enabled" ? 1 : 0;
      productsUrl += `&${key}=${val}`;
    }
  }
  if (getAll)
    productsUrl = '/locations/products'+getDefaultParameters_V2(true);
  return axios.get(productsUrl, {
    cancelToken: new CancelToken(function executor(c){
      getLocationsProductsCancel = c;
    })
  });
}

/**
 * Get list of product filters 
 */
let getProductsFilterCancel = null
export function getProductsFilters() {
  if(getProductsFilterCancel){
    getProductsFilterCancel(CANCEL_TOKEN);
    getProductsFilterCancel = null;
  }
  let url = 'locations/productsFilter';
  return axios.get(url, {
    cancelToken: new CancelToken(function executor(c){
      getProductsFilterCancel = c;
    })
  });
}

/**
 * Get selected product details
 */
export function getLocationsProductById(productId) {
  return axios.get(`/locations/products/${productId}?includeStocks=true`+getDefaultParameters_V2(false));
}

/**
 * create product images
 */
 export function createProductImage(productId) {
  return axios.post(`/locations/products/${productId}/images`);
}
/**
 * Get Stock Control page info
 */
let getInventoryStocksCancel = null;
export function getInventoryStocks({ page, limit }, keywords = null) {
  if(getInventoryStocksCancel){
    getInventoryStocksCancel(CANCEL_TOKEN);
    getInventoryStocksCancel = null;
  }
  let url = `/locations/stocks?limit=${limit}&page=${page}`+getDefaultParameters_V2(false)
  if (keywords)
    url = `${url}&keywords=${keywords}`

    return axios.get(url, {
      cancelToken: new CancelToken(function executor(c){
        getInventoryStocksCancel = c;
      })
    });
}

/**
 * Crate prekit for stock
 */
export function createPrekit(locationId) {
  return axios.post(`/locations/${locationId}/stocks/prekits/`);
}

/**
 * Get report to download for prekits
 */
export function getInventoryPrekitsReport(id, exportType) {
  return axios.get(`/locations/stocks/prekits/${id}?export=${exportType}`, {responseType: 'blob'});
}

/**
 * Get a list of top products
 * 
 * @param {UUID} locationId 
 * @returns a list of top products for a location
 */
export function getTopProducts(locationId) {
  return axios.get(`/locations/${locationId}/products/top`);
}

/**
 * Get featured products
 * 
 * @returns A list of featured products
 */
let getTopFeaturedCancel = null;
export function getTopFeatured() {
  if(getTopFeaturedCancel){
    getTopFeaturedCancel(CANCEL_TOKEN);
    getTopFeaturedCancel = null;
  }
  return axios.get(`/locations/products/featured`, {
    cancelToken: new CancelToken(function executor(c){
      getTopFeaturedCancel = c;
    })
  });
}

/**
 * Add a feature product
 * 
 * @param {JSON} payload 
 * @returns a success or failure of adding a product
 */
export function addTopProduct(payload) {
  console.log(payload)
  return axios.post(`/locations/products/top`, payload);
}

/**
 * Delete featured product
 * 
 * @param {JSON} payload
 * @returns a successful of deleting a product
 */
export function deleteTopProduct(payload) {
  return axios.post(`/locations/products/top/remove`,payload);

}

export function locationsForProduct(productId, set) {
  try {
    const s = set[productId].map((value) => {
       return value.locationId;
     });
     return s;
   } catch (error) {
     console.log(`eee ${error}`)
   }
}
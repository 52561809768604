import React, { useRef } from 'react';
import clsx from 'clsx';
import { Button, Card, CardActions, CardContent } from '@material-ui/core';
import { Add } from '@material-ui/icons';

import styles from './images.module.scss';

export default function Images(props) {
  const {
    images = [],
    onAddImage,
  } = props;
  const fileInput = useRef(null);
  /**
   * Pass file added by user
   */
  function handleFileInput(e) {
    const [file] = e.target.files;
      onAddImage(file);
  }

  return (
    <>
      <div id={`images_container`} className={clsx('d-flex flex-wrap', styles.wrapper)}>
        {images.map(({ id, cdn, fileName }) => (
          <div id={`images_inner_container_${id}`} key={id} className={clsx(styles.cardWrapper, 'col-3')}>
            <Card id={`images_card_inner_container_${id}`} variant="outlined">
              <CardContent id={`images_inner_card_content_container_${id}`} className="text-center">
                <img className={styles.imageFrame} src={cdn+fileName} alt=""/>
              </CardContent>
              <CardActions id={`images_card_action_${id}`} className="d-flex justify-content-center">

              </CardActions>
            </Card>
          </div>
        ))}
        <div className={clsx(styles.imageBox, 'col-3')}>
          <input className={styles.fileInput} type="file" accept='image/*' ref={fileInput} onChange={handleFileInput}/>
          <Button
            startIcon={<Add/>}
            type="button"
            color="primary"
            size="medium"
            variant="contained"
            disableElevation
            onClick={() => fileInput.current.click()}
          >
            Add Image
          </Button>
        </div>
      </div>
    </>
  );
}

import React, { useState, useCallback, useEffect } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import generate from 'shortid';
import to from 'await-to-js';
import {
  FormControl,
  TextField,
  Card,
  CardHeader,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemIcon,
  IconButton,
} from '@material-ui/core';
import {
  Delete
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';

import { emptyTiersSet } from 'app/common/LedgerConst';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { getLocationsProducts } from '../../../../../../services/products';

import styles from './product-tiers.module.scss';
import useDebounce from 'app/hooks/useDebounce';

export default function ProductTiers(props) {

  const {
    loyalty,
    onChange
  } = props;

  const useStyles = makeStyles({
    customTextField: {
      "& input::placeholder": {
        fontSize: "0.7rem"
      }
    }
  });
  const classes = useStyles();
  const [tiers, setTiers] = useState(emptyTiersSet);

  const [values, setValues] = useState([]);
  const [search, setSearch] = useState('');
  const searchQuery = useDebounce(search,1500)
  const header = useCallback(index => `Tier ${index + 1} (${(index + 1) * 2500} Points)`, []);
  const getFullProductName = useCallback(product => {
    const { name } = product;

    return `${name}`;
  }, []);
  const getItemStyle = useCallback((isDragging, draggableStyle) => ({
    ...draggableStyle,
    zIndex: isDragging ? 91 : 90,
    background: isDragging ? 'lightgrey' : 'white'
  }), []);
  const getListStyle = useCallback(isDraggingOver => ({
    background: isDraggingOver ? 'lightblue' : 'white'
  }), []);
  const [products, setProducts] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10
  });

  const [filters, setFilters] = useState({
    keywords: '',
    status: 'Enabled'
  });

    /**
   * Get all products
   */
     useEffect(() => {
        if (loyalty && loyalty.tiers)
          setTiers(loyalty.tiers);
        else if(loyalty) 
          setTiers(loyalty)
        // eslint-disable-next-line
    }, [loyalty]);

    useEffect(() => {
      if(search){
        handleGetProducts()
      }
      else {
        setProducts([])
      }
    }, [searchQuery])

    function updateTiers(tiers) {
      setTiers(tiers)
      onChange(tiers)

    }

  /**
   * Render card header with autocomplete
   * @param tierId
   * @returns {*}
   */
  function renderHeader(tierId) {
    return (
      <FormControl fullWidth className={styles.tierHeader}>
        <Autocomplete
          id="combo-box-demo"
          noOptionsText={'Searching...'}
          fullWidth
          options={products}
          getOptionLabel={option => option.name}
          onChange={(e, value) => addProduct(tierId, value)}
          value={values}
          renderInput={params => (
            <TextField
              {...params}
              classes={{ root: classes.customTextField }}
              id="note"
              label="Add product"
              placeholder="Type to search products"
              fullWidth
              variant="outlined"
              onChange={handleTypeSearch}
              onClick={handleTypeSearch}
            />
          )}
        />
      </FormControl>
    );
  }

  /**
   * Event fires on drag end
   * @param result
   */
  function onDragEnd(result) {
    const { destination, source } = result;

    if (!destination)
      return;

    const updatedTiers = tiers;
    const sourceTier = updatedTiers.find(tier => tier.id === source.droppableId);
    const destinationTier = updatedTiers.find(tier => tier.id === destination.droppableId);
    const sourceItem = sourceTier.products.splice(+source.index, 1)[0];
    destinationTier.products.splice(+destination.index, 0, sourceItem);
    updateTiers(updatedTiers);
  }

  /**
   * Remove item
   * @param tierId
   * @param productId
   */
  function removeItem(tierId, productId) {
    updateTiers(tiers.map(tier => {
      if (tier.id !== tierId)
        return tier;

      return {
        ...tier,
        products: tier.products.filter(p => p.id !== productId)
      };
    }));
  }

  function handleTypeSearch(event) {
    setSearch(event?.target?.value)
  }

  function handleGetProducts(){
    getProducts({
      ...filters,
      keywords: searchQuery,
    });
  }

  async function getProducts(filters){
    const products = await to(getLocationsProducts(pagination,filters));
    setProducts(products[1]?.data?.data?.products)
  }

  /**
   * check product already added to any tier
   */
  function checkProductAdded(tier, product) {
    return {
      ...tier,
      products: tier.products.filter((ele) => {
        return ele.productId !== product.productId;
      }),
    };
  }

  /**
   * Add item to price tier
   * @param tierId
   * @param product
   */
  function addProduct(tierId, product) {
    if (!product)
      return;

      updateTiers(
        tiers.map((tier) => {
          if (tier.id !== tierId) {
            return checkProductAdded(tier, product);
          } else {
            if (
              tier.products.some((ele) => {
                return ele.productId === product.productId;
              })
            )
              return tier;

            return {
              ...tier,
              products: [...tier.products, { ...product, id: generate() }],
            };
          }
        })
      );
    
    setValues([]);
  }

  return (
    <div className={styles.mainWrapper}>
      <DragDropContext onDragEnd={onDragEnd}>
        {tiers.map((tier, index) => (
          <div
            className={styles.tier}
            key={tier.id}
          >
            {renderHeader(tier.id)}

            <Card className={styles.wrapper} variant="outlined">
              <CardHeader
                title={header(index)}
              />
              <Droppable droppableId={tier.id.toString()}>
                {(provided, snapshot) => (
                  <List
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    style={getListStyle(snapshot.isDraggingOver)}
                  >
                    {tier.products.map((product, index) => (
                      <Draggable key={product.id} draggableId={product.id ? product.id.toString() : "0"} index={index}>
                        {(provided, snapshot) => (
                          <ListItem
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            <ListItemAvatar>
                              {/* <Avatar> */}
                              {product.product && product.product.images && product.product.images.length ? (
                              <img
                                height={30}
                                width={30}
                                className={styles.productImage}
                                src={product.product.images[0].cdn + product.product.images[0].fileName}
                                alt={product.name}
                              />
                            )
                              : null}
                              {/* </Avatar> */}
                            </ListItemAvatar>
                            <ListItemText
                              primary={getFullProductName(product)}
                            />
                            <ListItemIcon>
                              <IconButton onClick={() => removeItem(tier.id, product.id)}>
                                <Delete/>
                              </IconButton>
                            </ListItemIcon>
                          </ListItem>
                        )}
                      </Draggable>
                    ))}

                    {provided.placeholder}
                  </List>
                )}
              </Droppable>
            </Card>
          </div>
        ))}
      </DragDropContext>
    </div>
  );
}

import React from 'react';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import { format } from 'date-fns';

export default function StockRowExpanded(props) {
  const {
    prekits,
    onDownload
  } = props;

  return (
    <>
      <TableRow id={`stock_control_expanded_table_row`}>
        <TableCell id={`stock_control_expanded_table_cell`} padding="default" colSpan={7} align="center">
          <Table id={`stock_control_expanded_table`} size="small" style={{ marginBottom: '20px' }}>
            <TableHead id={`stock_control_expanded_table_head`}>
              <TableRow id={`stock_control_expanded_table_head_row`}>
                <TableCell id={`stock_control_expanded_table_head_date_cell`}>Date</TableCell>
                <TableCell id={`stock_control_expanded_table_head_number_cell`}>Number of Products</TableCell>
                <TableCell id={`stock_control_expanded_table_head_action_cell`}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody id={`stock_control_expanded_table_body`}>
              {prekits.map(row => (
                <TableRow id={`stock_control_expanded_table_body_row_${row.id}`} key={row.id}>
                  <TableCell id={`stock_control_expanded_table_body_creation_cell_${row.id}`}>{format(new Date(row.creationDate), 'MM/dd/yyyy - hh:mm aa')}</TableCell>
                  <TableCell id={`stock_control_expanded_table_body_product_cell_${row.id}`}>{row.numberOfProducts}</TableCell>
                  <TableCell id={`stock_control_expanded_table_body_button_cell_${row.id}`}>
                    <Button
                      id={`stock_control_expanded_doenload_button_${row.id}`}
                      type="button"
                      color="primary"
                      size="small"
                      variant="contained"
                      disableElevation
                      onClick={() => onDownload({ id: row.id, count: row.numberOfProducts })}
                    >
                      Download
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableCell>
      </TableRow>
    </>
  );
}

import React, { useState, useEffect } from "react";
import to from "await-to-js";
import { useRouteMatch, useHistory } from "react-router-dom";
import { Button, Card, CardContent, Typography } from "@material-ui/core";
import clsx from "clsx";
import { Check } from "@material-ui/icons";
import * as Sentry from "@sentry/react";

import ResponseModal from "app/components/Helpers/ResponseModal";
import { accessPermission } from "app/common/helperFunction";

import BasicInfo from "./components/BasicInfo/BasicInfo";
import MerchantApplication from "./components/MerchantApplication/MerchantApplication";
import PaymentAuth from "./components/PaymentAuth/PaymentAuth";
import VmsProvider from "./components/VmsProvider/VmsProvider";
import { getUserApplication } from "../../services/operator-application.mock";
import {
  getApplicationDetails,
  approveApplication,
  denyApplication,
  createApplication,
  updateApplication,
} from "../../../../services/applications";

import styles from "./operator-application.module.scss";
import { useCustomResponse } from "app/hooks/useCustomResponse";
import { ACCOUNT_APPLICATION_POPUP } from "app/common/constant";

let count = 1;

export default function OperatorApplication() {
  const { push } = useHistory();
  const [page, setPage] = useState(1);
  const [open, setOpen] = useState(false);
  const [goBackActive, setGoBackActive] = useState(false);
  const [userApplication, setUserApplication] = useState({});
  const match = useRouteMatch() || {};
  const { id } = match.params;
  const isApplicationsView = match.params && match.params.id;


  const newOwner = {
    id: +new Date().getTime(),
    firstName: "",
    lastName: "",
    title: "",
    ssn: "",
    birthDate: "",
    homeAddress: "",
    city: "",
    state: "",
    zipCode: "",
    homePhone: "",
    cellPhone: "",
    driverLicense: "",
    lengthAtHomeAddress: "",
    businessEquity: 0,
  };
  const [ownerLabel, setOwnerLabel] = useState(false);
  const [responseValue, setResponseValue] = useCustomResponse()

  

  /**
   * Get data on component rendering or prepare empty structure for filling
   */
  useEffect(() => {
    if(isApplicationsView &&  !accessPermission()){
      push({pathname: "/dashboard"});
    }

    if (!isApplicationsView) setUserApplication(getUserApplication());
    else {
      getApplicationById();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    window.scroll(0, 0);
  }, [page]);

  async function getApplicationById() {
    const [err, res] = await to(getApplicationDetails(id));

    if (err){
      Sentry.captureException(err);
      setResponseValue({...responseValue, isMessageOpen: true, isSuccess:false,message:`Error while getting application by id. ${err}`});     
       return console.error("Error with application details fetching: ", err);
    }

    const { owners, ...application } = res.data;

    if (!!owners && Object.keys(owners).length && Array.isArray(owners.data))
      setUserApplication({ ...application, owners: [...owners.data] });
    else setUserApplication({ ...application, owners: [newOwner] });
  }

  async function handleApplicationStatusChange(status) {
    const [err, res] = await to(
      status === "approve" ? approveApplication(id) : denyApplication(id)
    );

    if (err){
      if(err.response) {
        setResponseValue({ ...responseValue, isMessageOpen: true, isSuccess:false, message:`${err.response.data.message}`});
        return
      }
      else {
        Sentry.captureException(err);
        setResponseValue({ ...responseValue, isMessageOpen: true, isSuccess:false, message:`Error while updating application status. ${err}`});
        return console.error("Error with changing application status: ", err);
      }
    }
    const message = status === "approve" ? "approved" : "Rejected"; 
    setResponseValue({ ...responseValue, isMessageOpen: true, isSuccess:true, message:`Application ${message}`});
    setGoBackActive(true);

    setUserApplication({
      ...userApplication,
      status,
    });
  }

  function updateUserApplication(update, key) {
    const updateApplication = {
      ...userApplication,
      [key]: {
        ...userApplication[key],
        ...update,
      },
    };

    setUserApplication(updateApplication);
  }

  function updateOwnersInfo(update, ownerId) {
    const owners = [...userApplication.owners];
    let ownerIndex;

    userApplication.owners.forEach((owner, index) => {
      if (owner.id === ownerId) ownerIndex = index;
    });

    const newOwner = {
      ...userApplication.owners[ownerIndex],
      ...update,
    };

    owners.splice(ownerIndex, 1, newOwner);
    setUserApplication({ ...userApplication, owners });
  }

  function addOwner() {
    setUserApplication({
      ...userApplication,
      owners: [...userApplication.owners, newOwner],
    });
  }

  function removeOwner(id) {  
    const newOwners = userApplication.owners.filter((item) => item.id !== id);

    setUserApplication({
      ...userApplication,
      owners: [...newOwners],
    });
  }

  function handleOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
    handleFullFormSubmit();
  }

  function finishSetup() {
    if(process.env.NODE_ENV === "development"){
      setPage(2);
    }else{
      setPage(4)
    }
    setOpen(false);
  }

  function goToTab(page) {
      setPage(page);
  }

  function goToPreviousPage(page){
    if(process.env.NODE_ENV === "development"){
      setPage(page);
    }else{
      setPage(1)
    }
  }

  function goToNextPage(value) {
      if(process.env.NODE_ENV === "development"){
        setPage(value);
      }else{
        setPage(4)
        count = 4;
      }
  }


  async function handleFullFormSubmit() {
    const { owners: data, id: currentId, ...application } = userApplication;
    const dataToSend = { ...application, owners: { data } };
    const [err, res] = await to(
      !isApplicationsView
        ? createApplication(dataToSend)
        : updateApplication(id, dataToSend)
    );

    if (err){
      Sentry.captureException(err);
      setResponseValue({ ...responseValue, isMessageOpen: true, isSuccess:false, message:`Error while saving application. ${err}`});    
      return console.error("Error with saving the application: ", err);
    } else {
      if(res?.data?.status === ACCOUNT_APPLICATION_POPUP){
      setResponseValue({ ...responseValue, isMessageOpen: true, isSuccess:true, message:"Application sent successfully"})
    }}
      
    const { owners, ...updatedApplication } = res.data;

    if (Object.keys(owners).length)
      setUserApplication({ ...updatedApplication, owners: [...owners.data] });
    else setUserApplication({ ...updatedApplication, owners: [] });
  }

  function handleResponseModelClose(value) {
    setResponseValue({...responseValue, isMessageOpen:value});
    if(goBackActive){
    push({ pathname: `/setup/applications` });
    }
    else if (!id) push({ pathname: "/auth/login" });
  }

  return (
    <>
      <Card variant="outlined">
        <CardContent>
          <div
            className={clsx(
              styles.headerSpace,
              "d-flex",
              "justify-content-between"
            )}
          >
            <Typography variant="h3" className={styles.headerSpace}>
              Account Application
            </Typography>
            {isApplicationsView ? (
              <div>
                <Button
                  color="primary"
                  size="large"
                  style={{ marginRight: 20 }}
                  onClick={handleFullFormSubmit}
                >
                  Save Changes
                </Button>
                <Button
                  size="medium"
                  variant="contained"
                  color="primary"
                  disableElevation
                  style={{ marginRight: 20 }}
                  startIcon={<Check />}
                  onClick={() => handleApplicationStatusChange("approve")}
                >
                  Accept Application
                </Button>
                <Button
                  color="primary"
                  size="large"
                  onClick={() => handleApplicationStatusChange("deny")}
                >
                  Reject Application
                </Button>
              </div>
            ) : null}
          </div>

          <div className={styles.progressWrapper}
           style={{ justifyContent: (process.env.NODE_ENV !== "development") && "center"}} 
           >
            <Card
              variant="outlined"
              className={clsx(styles.itemBlock, page === 1 && styles.active)} 
              onClick={() => { if(count >= 0) goToTab(1)}}
              >
              <CardContent className={styles.itemContent}>
                1. Basic Info
              </CardContent>
            </Card>
            <div className={styles.separatorBlock}
             style={{ margin: (process.env.NODE_ENV !== "development") && "3px"}}
               />

            {process.env.NODE_ENV === "development" &&
            <>
             <Card
              variant="outlined"
              className={clsx(styles.itemBlock, page === 2 && styles.active)}
              onClick={() => { if(count >= 2)goToTab(2)}}
               >
              <CardContent className={styles.itemContent}>
                2. Merchant Processing Application
              </CardContent>
             </Card>

             <div className={styles.separatorBlock} />

             <Card
              variant="outlined"
              className={clsx(styles.itemBlock, page === 3 && styles.active)}
              onClick={() => { if(count >= 3)goToTab(3)}}
               >
              <CardContent className={styles.itemContent}>
                3. Recurring Payment Authorization
              </CardContent>
             </Card>
             
             <div className={styles.separatorBlock} /> 
            </>
            }

            <Card
              variant="outlined"  
              className={clsx(styles.itemBlock, page === 4 && styles.active)} 
              onClick={() => { if(count >= 4)goToTab(4)}}
              >
              <CardContent className={styles.itemContent}>
              {process.env.NODE_ENV === "development" ? "4." : "2."} VMS Provider
              </CardContent>
            </Card>

          </div>

          {page === 1 ? (
            <BasicInfo
              companyInfo={userApplication.companyInfo}
              companyAddress={userApplication.companyAddress}
              open={open}
              onCompanyInfo={(newInfo) =>
                updateUserApplication(newInfo, "companyInfo")
              }
              onCompanyAddress={(newInfo) =>
                updateUserApplication(newInfo, "companyAddress")
              }
              onOpen={() => handleOpen()}
              onClose={() => handleClose()}
              onFinishSetup={() => {count = count<2 ? 2 :count ; finishSetup()}}
              onNextClick={() => {count = count<2 ? 2 :count ; goToNextPage(2)}}
              isApplicationsView={isApplicationsView}
              push={push}
              cancelForm={() => count = 1}
            />
          ) : null}
          {page === 2 && process.env.NODE_ENV === "development" ? (
            <MerchantApplication
              ownerLabel={ownerLabel}
              merchantInfo={userApplication.merchantInfo}
              bankInfo={userApplication.bankInfo}
              owners={userApplication.owners}
              contactInfo={userApplication.contactInfo}
              onContactInfo={(newInfo) =>
                updateUserApplication(newInfo, "contactInfo")
              }
              onAddOwner={() => addOwner()}
              onRemoveOwner={removeOwner}
              onUpdateOwner={(newInfo, ownerId) =>
                updateOwnersInfo(newInfo, ownerId)
              }
              onBankInfo={(newInfo) =>
                updateUserApplication(newInfo, "bankInfo")
              }
              onNextClick={() => { count= count<3 ? 3 : count ; goToNextPage(3)}}
              onPreviousClick={() => goToPreviousPage(1)}
              cancelForm={() => count = 1}
              isApplicationsView={isApplicationsView}
              push={push}
            />
          ) : null}
          {page === 3 && process.env.NODE_ENV === "development" ? (
            <PaymentAuth
              bankInfo={userApplication.bankInfo}
              paymentAuth={userApplication.paymentAuth}
              onPaymentAuth={(newInfo) =>
                updateUserApplication(newInfo, "bankInfo")
              }
              onNextClick={() => { count = count<4 ? 4 : count; goToNextPage(4)}}
              onPreviousClick={() => goToPreviousPage(2)}
              isApplicationsView={isApplicationsView}
              push={push}
              cancelForm={() => count = 1}
            />
          ) : null}
          {page === 4 ? (
            <VmsProvider
              provider={userApplication.vmsInfo}
              onVmsProvider={(newInfo) =>
                updateUserApplication(newInfo, "vmsInfo")
              }
              onPreviousClick={() => goToPreviousPage(3)}
              onSubmit={handleFullFormSubmit}
              isApplicationsView={isApplicationsView}
              push={push}
              cancelForm={() => count = 1}
            />
          ) : null}
        </CardContent>
      </Card>
      <ResponseModal
      isSuccess={responseValue?.isSuccess}
      message={responseValue?.message}
      open={responseValue?.isMessageOpen}
      onClose={value => handleResponseModelClose(value)}
      />
    </>
  );
}

import React, { useState, useEffect, useMemo } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { Formik } from "formik";
import { useHistory } from "react-router-dom";
import * as Sentry from "@sentry/react";

import { getTickets } from "app/services/notifications";
import LoadingSpinner from "app/partials/content/LoadingSpinner";
import { hasMultipleAccounts } from "app/services/helpers/account-id";
import { accessPermission } from "app/common/helperFunction";

import {
  addTicket,
  updateTicket,
  filterTickets,
} from "../../partials/content/TicketTable/tickets.mock";
import TicketTable from "../../partials/content/TicketTable/TicketTable";
import OperatorSpecificModal from "../Helpers/OperatorSpecificModal";

import styles from "../../../_metronic/_assets/sass/pages/ticket.module.scss";

export default function Tickets() {
  const [isModalOpen, setModalOpen] = useState(false);
  const [ticketToEdit, setTicketToEdit] = useState({
    title: "",
    description: "",
  });
  const [isNew, setIsNew] = useState(true);
  const [selectedVisibilityFilter, setSelectedVisibilityFilter] = useState();
  const [selectedStatusFilter, setSelectedStatusFilter] = useState();
  const [isLoaded, setIsLoaded] = useState(false);
  const [tickets, setTickets] = useState([]);
  const { push, location } = useHistory();
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10
  });
  const [totalTickets, setTotalTickets] = useState(0);
  const [operatorSpecificModalOpen, setOperatorSpecificModalOpen] = useState(false);

  function fetchTickets() {
    if(location.pathname !== "/tickets" && hasMultipleAccounts()){
        setOperatorSpecificModalOpen(true);
      return;
    }
    setOperatorSpecificModalOpen(false);
    Promise.all([getTickets(pagination)])
      .then((responses) => {
        if (responses[0].data) {
          const tickets = responses[0].data.supportTickets.rows;
          setTickets(tickets);
          setTotalTickets(responses[0].data.meta.totalItems);
        } 
      })
      .then(() => {
        setIsLoaded(true);
      })
      .catch((err) => {
        Sentry.captureException(err);       
        console.error("Error with filters fetching: ", err);
      });
  }


  /**
   * Change subheader text
   */
  useEffect(() => {
    if(!accessPermission("Tickets")){
      push({pathname: "/dashboard"});
    }

    const subheader = document.getElementById("subheader-title");
    /**
     *  Manually set subheader
     */
    subheader.innerText = "Tickets";

  }, []);

  useEffect(() => {
    fetchTickets();
  },[pagination]);

  /**
   * Handle filter change
   */
  useEffect(() => {
    setTickets(filterTickets(selectedVisibilityFilter, selectedStatusFilter));
  }, [selectedVisibilityFilter, selectedStatusFilter]);

  /**
   * Reset form on modal close
   */
  useEffect(() => {
    if (isModalOpen) return;

    setTicketToEdit({
      title: "",
      description: "",
    });
    setIsNew(true);
  }, [isModalOpen]);

  /**
   * Add New ticket to the table
   * @param title
   * @param description
   */
  function onAddNewTicket({ title, description }) {
    const updatedTickets = {
      id: Date.now(),
      title,
      description,
      priority: "Normal",
      requester: "John Doe",
      requesterUpdated: "Feb 07 2020",
      group: "Support",
      isOpen: true,
      type: "Solved",
    };

    addTicket(updatedTickets);
    setTickets(filterTickets(selectedVisibilityFilter, selectedStatusFilter));
    setModalOpen(false);
  }

  /**
   * Update tickets
   * @param updatedTicket
   */
  function onUpdateTicket(updatedTicket) {
    updateTicket(updatedTicket);
    setTickets(filterTickets(selectedVisibilityFilter, selectedStatusFilter));

    setModalOpen(false);
  }
  
  /**
   * redirect to chat box with edited row
   */
  function onClickOpenMessageBox(e) {
    push({ pathname: `/tickets/${e.id}` });

  }

  return (
    <>
      <div id="tickets_root_wrapper" className={styles.wrapper}>
        <Dialog
          id="tickets_dialog_wrapper"
          open={isModalOpen}
          onClose={() => setModalOpen(false)}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            {isNew ? "New Ticket" : "Reply To Ticket"}
          </DialogTitle>
          <DialogContent id="tickets_dialog_content_wrapper">
            <Formik
              id="tickets_formik_wrapper"
              initialValues={{ ...ticketToEdit }}
              validate={(values) => {
                const errors = {};

                if (!values.title) errors.title = "Title is required field.";

                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                if (isNew) onAddNewTicket(values);
                else onUpdateTicket(values);

                setSubmitting(false);
              }}
            >
              {({ values, errors, touched, handleChange, handleSubmit }) => (
                <form onSubmit={handleSubmit} noValidate autoComplete="off">
                  From Customer:
                  <div className="box">{values.description}</div>
                  <TextField
                    autoFocus
                    margin="normal"
                    id="message"
                    label="Message"
                    variant="outlined"
                    type="text"
                    required
                    onChange={handleChange}
                    value={values.title}
                    error={errors.title && touched.title}
                    fullWidth
                  />
                  <DialogActions id="tickets_action_button_wrapper">
                    <Button
                      id="tickets_cancel_action_button"
                      onClick={() => setModalOpen(false)}
                      color="primary"
                      size="large"
                    >
                      Cancel
                    </Button>
                    <Button
                    id="tickets_add_action_button"
                      type="submit"
                      color="primary"
                      size="large"
                      variant="contained"
                      disableElevation
                    >
                      {isNew ? "Add New Ticket" : "Reply to Ticket"}
                    </Button>
                  </DialogActions>
                </form>
              )}
            </Formik>
          </DialogContent>
        </Dialog>
      </div>
      {!isLoaded ? (
        <LoadingSpinner/>
      ) : (
        <TicketTable
          rows={tickets}
          onEditRowClick={onClickOpenMessageBox}
          totalTickets={totalTickets}
          pagination={pagination}
          setPagination={setPagination}
        />
      )}
      {operatorSpecificModalOpen && (
        <OperatorSpecificModal
          open={operatorSpecificModalOpen}
          onClose={(value) => setOperatorSpecificModalOpen(value)}
        />
      )}
    </>
  );
}

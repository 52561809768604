import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Input,
  InputAdornment,
  InputLabel,
} from "@material-ui/core";

export default function EditLimit(props) {
  const { currentRow, onUpdate } = props;
  const [limit, setLimit] = useState(currentRow.limit);

  return (
    <Dialog
      id="tab_alert_dialog_container"
      open
      onClose={() => onUpdate(currentRow, null)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="tab_alert_dialog_title" style={{ textAlign: "center" }}>
        Edit Limit
      </DialogTitle>
      <DialogContent id="tab_alert_dialog_content" className="d-flex flex-column">
        <FormControl id="limit_form_control" fullWidth>
          <InputLabel id="limit_label" htmlFor="limit">Limit</InputLabel>
          <Input
            type="number"
            id="limit"
            value={limit}
            onChange={(e) => setLimit(e.target.value)}
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
          />
        </FormControl>
      </DialogContent>
      <DialogActions id="tab_action_button">
        <Button id="tab_cancel_primary_button" onClick={() => onUpdate(currentRow, null)} color="primary">
          Cancel
        </Button>
        <Button
          id="tab_save_primary_button"
          onClick={() => onUpdate(currentRow, limit)}
          autoFocus
          variant="contained"
          color="primary"
          disableElevation
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

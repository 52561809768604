import React from 'react';
import { Divider, InputLabel } from '@material-ui/core';
import clsx from 'clsx';
import Select from 'react-select';

import styles from './products-filter.module.scss';

const allProducts = [{
  label: 'Snow',
  value: 'snow'
}];

const filters = [{
  label: 'Include',
  value: 'include'
}, {
  label: 'Exclude',
  value: 'exclude'
}];

export default function ProductsFilter(props) {
  const {
    productsFilter,
    onFilterChange
  } = props;

  return (
    <>
      <Divider className={styles.divider}/>
      <div id={`product_type_wrapper`} className="d-flex flex-column">
        <InputLabel id="filters-label">
          Filter products by tag, type, brand, supplier or SKU
        </InputLabel>
        <div id={`product_type_inner_wrapper`}  className={clsx(styles.container, 'd-flex')}>
          <Select
            value={productsFilter.filter}
            id="filter"
            name="filter"
            options={filters}
            menuPortalTarget={document.body}
            className={clsx('basic-multi-select', styles.filter)}
            onChange={e => onFilterChange(e, 'filter')}
          />
          <Select
            value={productsFilter.products}
            isMulti
            id="products"
            name="products"
            options={allProducts}
            menuPortalTarget={document.body}
            className={clsx('basic-multi-select', styles.products)}
            placeholder="Add a filter"
            onChange={e => onFilterChange(e, 'products')}
          />
        </div>
      </div>
    </>
  );
}

import React, { useEffect, useState } from 'react';
import to from 'await-to-js';
import { useHistory } from 'react-router-dom';
import 'jspdf-autotable';
import { Box, Button, Step, StepLabel, Stepper, Typography, Paper, StepContent, TextField, CardContent, Divider, Grid, Item, CircularProgress } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import * as Sentry from "@sentry/react";

import { getComparator, stableSort } from 'app/services/table';
import BasicInfo from 'app/components/OperatorApplication/components/OperatorApplication/components/BasicInfo/BasicInfo';
import VmsProvider from 'app/components/OperatorApplication/components/OperatorApplication/components/VmsProvider/VmsProvider';
import { getLocationsThemes } from 'app/services/locations';
import { CANCEL_TOKEN } from "app/common/constant";

import { getOperatorV2 } from '../../../services/account';



const steps = [
    {
        label: 'Yoke Account Setup',
    },
    {
        label: 'Merchant Account Setup',
    },
    {
        label: 'Inventory Account Setup',
    },
    {
        label: 'Additional Setup',
    },
    {
        label: 'Complete Onboard',
    },
];

const loyaltyPrograms = [{id:1, name:"Global Connect"}]
let searchTimeout = null;



export default function AddOperator(props) {
    const { push } = useHistory();
    const [operatorSearchQuery, setOperatorSearchQuery] = useState('');
    const [themesSearchQuery, setThemesSearchQuery] = useState('');
    const [operatorsList, setOperatorsList] = useState([{id:1, name:"New Merchant"}]);
    const [themesList, setThemesList] = useState([{id:1, name:"default Theme"}]);
    const [kiosksList, setKiosksList] = useState([{id:1, name:"default kiosk"}]);

    const [activeStep, setActiveStep] = React.useState(0);

    useEffect(()=>{
        getOperatorsList();
        getThemes();
    },[])

    useEffect(()=>{
        getOperatorsList();
    },[operatorSearchQuery])

    useEffect(()=>{
        getThemes();
    },[themesSearchQuery])

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    function handleTextChange(value, type) {
        if(searchTimeout){
            clearTimeout(searchTimeout)
            searchTimeout = null;
        }

        if(type === "operator"){   
            searchTimeout = setTimeout(()=>{
                setOperatorSearchQuery(value);
            },400);
        }
        else if(type === "theme"){  
            searchTimeout = setTimeout(()=>{
                setThemesSearchQuery(value);
            },400);
        }
    }

    function handleLocationChange() {

    }

  async function getOperatorsList() {
    const [err, res] = await to(getOperatorV2({page:1,limit:10}, operatorSearchQuery));

    if (err){
      if(err.message === CANCEL_TOKEN)return;
      Sentry.captureException(err);    
      return console.error('Error while fetching operators', err);
    }

    const mappedOperators = res.data.account.rows.map(
        (operator) => {
          const { id, name } = operator;
          return { id, name };
        }
      );
      const sortedOperatorsList = stableSort(
        mappedOperators,
        getComparator("asc", "name")
      );
      setOperatorsList([
        ...sortedOperatorsList,
      ]);
  }

  async function getThemes() {
    const [err, res] = await to(getLocationsThemes({page:1,limit:10}, themesSearchQuery));

    if (err){
      if(err.message === CANCEL_TOKEN)return;
      Sentry.captureException(err);     
      return console.error('Error while fetching themes', err);
    }

    const mappedThemes = res.data.themes.map((theme)=>{
        const {id, name} = theme;
        return {id, name};
    });
    const sortedThemesList = stableSort(
        mappedThemes, getComparator("asc", "id")
    );
    setThemesList([...sortedThemesList]);
  }


    return (
        <Box id={`add_operator_wrapper`} sx={{ maxWidth: 800 }}>
            <Stepper id={`add_operator_stepper_wrapper`} activeStep={activeStep} orientation="vertical">
                {steps.map((step, index) => (
                    <Step id={`add_operator_steps_${index}`} key={step.label}>
                        <StepLabel
                            id={`add_operator_steps_label_${index}`}
                            optional={
                                index === 4 ? (
                                    <Typography variant="caption">Last step</Typography>
                                ) : null
                            }
                        >
                            {step.label}
                        </StepLabel>
                        <StepContent id={`add_operator_steps_content_${index}`}>
                            {index === 0 ? (<BasicInfo displayButtons={false} />) : null}
                            {index === 1 ? (<div>
                                <CardContent id={`add_operator_steps_card_content_${index}`}>
                                    <Typography id={`add_operator_steps_card_content_label_${index}`} variant="h5">Setup Merchant Account</Typography>
                                    <p>
                                        Please select the Yoke merchant account that will be used for this operator. If this operator is expected to be merchant of record please follow the standard Heartland and Worldnet steps and select "Create new merchant account".
                                    </p>
                                    <Autocomplete
                                        id={`add_operator_steps_auto_complete`}
                                        options={operatorsList}
                                        getOptionLabel={(option) => option.name}
                                        onChange={(event, option) => handleLocationChange(option?.id)}
                                        style={{ minWidth: 160, marginLeft: "10px", display: "grid" }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                required
                                                onChange={(e) => handleTextChange(e.target.value, "operator")}
                                                placeholder={"Create new merchant account"}
                                            />

                                        )}
                                    />
                                </CardContent>
                            </div>


                            ) : null}
                            {index === 2 ? (<div>
                                <CardContent  id={`add_operator_steps_card_content_${index}`}>
                                    <Typography  id={`add_operator_steps_card_content_label_${index}`} variant="h5">Setup Inventory Account</Typography>
                                    <p>
                                        Please fill out the inventory information for this operator.
                                    </p>
                                    <Divider />
                                    <VmsProvider displayButtons={false} />
                                </CardContent></div>) : null}
                            {index === 3 ? (<div>
                                <CardContent id={`add_operator_steps_card_content_${index}`}>
                                    <Typography id={`add_operator_steps_card_content_label_${index}`} variant="h5">Additional Setup</Typography>
                                    <p>
                                        Please select any defaults settings for this account.
                                    </p>
                                    <Typography id={`add_operator_steps_card_content_sub_title_${index}`} variant="subtitle1">Default Theme</Typography>
                                    <Autocomplete
                                        id={`add_operator_steps_auto_complete_${index}`}
                                        options={themesList}
                                        getOptionLabel={(option) => option.id}
                                        onChange={(event, option) => handleLocationChange(option?.id)}
                                        style={{ minWidth: 160, marginLeft: "10px", display: "grid" }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                required
                                                onChange={(e) => handleTextChange(e.target.value, "theme")}
                                                placeholder={"No default theme"}
                                            />

                                        )}
                                    />
                                    <Typography id={`add_operator_steps_card_content_sub_title_1_${index}`} variant="subtitle1">Default Kiosk Configuration</Typography>
                                    <Autocomplete
                                         id={`add_operator_steps_kiosk_auto_complete_${index}`}
                                        options={kiosksList}
                                        getOptionLabel={(option) => option.name}
                                        onChange={(event, option) => handleLocationChange(option?.id)}
                                        style={{ minWidth: 160, marginLeft: "10px", display: "grid" }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                required
                                                onChange={(e) => handleTextChange(e.target.value, "location")}
                                                onClick={(e) => handleTextChange(e.target.value, "location")}
                                                placeholder={"No default Kiosk configuration"}
                                            />

                                        )}
                                    />
                                    <Typography id={`add_operator_steps_card_content_sub_title_2_${index}`} variant="subtitle1">Loyalty Program</Typography>
                                    <Autocomplete
                                        id={`add_operator_steps_loyalty_program_auto_complete_${index}`}
                                        options={loyaltyPrograms}
                                        getOptionLabel={(option) => option.name}
                                        onChange={(event, option) => handleLocationChange(option?.id)}
                                        style={{ minWidth: 160, marginLeft: "10px", display: "grid" }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                required
                                                onChange={(e) => handleTextChange(e.target.value, "location")}
                                                onClick={(e) => handleTextChange(e.target.value, "location")}
                                                placeholder={"Yoke Loyalty Program"}
                                            />

                                        )}
                                    />
                                </CardContent>
                            </div>) : null}
                            {index === 4 ? (<div>
                                <CardContent id={`add_operator_steps_card_content_${index}`}>
                                    <div style={{paddingBottom:10}}>
                                    <Typography id={`add_operator_steps_card_title_1_${index}`} variant="h5">Complete Onboard</Typography>
                                    <p>
                                        Please review the following information and if everything is correct complete the form.
                                    </p>
                                    <Divider />
                                    <Box>
                                        <Typography id={`add_operator_steps_card_title_2_${index}`} variant="h6">User Admin Account</Typography>
                                        <Typography id={`add_operator_steps_card_email_${index}`} variant="subtitle2">Email</Typography>
                                        mark@yokepayments.com
                                        <Typography id={`add_operator_steps_card_name_${index}`} variant="subtitle2">Name</Typography>
                                        Mark Masterson
                                        <Typography id={`add_operator_steps_card_role_${index}`} variant="subtitle2">Role</Typography>
                                        Test role
                                    </Box>
                                    </div>
                                    <div style={{paddingBottom:10}}>
                                    <Typography id={`add_operator_steps_card_operator_${index}`} variant="h6">Operator</Typography>
                                    <Typography id={`add_operator_steps_card_operator_name_${index}`} variant="subtitle2">Operator Name</Typography>
                                    Test Operator Name
                                    <Typography id={`add_operator_steps_card_vms_type_${index}`} variant="subtitle2">VMS Type</Typography>
                                    Seed
                                    <Typography id={`add_operator_steps_card_operator_id_${index}`} variant="subtitle2">Operator ID</Typography>
                                    vending
                                    <Typography id={`add_operator_steps_card_merchant_account_${index}`} variant="subtitle2">Merchant Account</Typography>
                                    Creating new
                                    </div>
                                    <div style={{paddingBottom:10}}>
                                    <Typography id={`add_operator_steps_card_additional_${index}`} variant="h6">Addtional</Typography>
                                    <Typography id={`add_operator_steps_card_default_${index}`} variant="subtitle2">Default Theme</Typography>
                                    None
                                    <Typography id={`add_operator_steps_card_configration_${index}`} variant="subtitle2">Default Kiosk Configuration</Typography>
                                    None
                                    <Typography id={`add_operator_steps_card_loyalty_program_${index}`} variant="subtitle2">Loyalty Program</Typography>
                                    Global Connect
                                    </div>

                                </CardContent></div>) : null}

                            <Box sx={{ mb: 2 }}>
                                <div   id={`add_operator_steps_button_wrapper`}>
                                    <Button
                                        id={`add_operator_steps_finish_button`}
                                        variant="contained"
                                        onClick={handleNext}
                                        sx={{ mt: 1, mr: 1 }}
                                    >
                                        {index === steps.length - 1 ? 'Finish' : 'Continue'}
                                    </Button>
                                    <Button
                                          id={`add_operator_steps_back_button`}
                                        disabled={index === 0}
                                        onClick={handleBack}
                                        sx={{ mt: 1, mr: 1 }}
                                    >
                                        Back
                                    </Button>
                                </div>
                            </Box>
                        </StepContent>
                    </Step>
                ))}
            </Stepper>
            {activeStep === steps.length && (
                <Paper id={`add_operator_steps_paper_wrapper`} square elevation={0} sx={{ p: 3 }}>
                    <Typography id={`add_operator_steps_heading`}>All steps completed - you&apos;re finished</Typography>
                    <CircularProgress />

                    <Button id={`add_operator_steps_reset_button`} onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                        Reset
                    </Button>
                </Paper>
            )}
        </Box>
    );
}
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { Button, Divider, Typography, CardContent } from '@material-ui/core';
import { Add } from '@material-ui/icons';

import SalesTaxTable from './components/SalesTaxTable/SalesTaxTable';
import TaxLocationsTable from './components/TaxLocationsTable/TaxLocationsTable';

import styles from './tax.module.scss';

const mockedSalesTax = [{
  id: 1,
  name: 'Bottle Tax',
  rate: 6
}, {
  id: 2,
  name: 'California',
  rate: 9
}, {
  id: 3,
  name: 'Florida Sales Tax',
  rate: 6
}, {
  id: 4,
  name: 'Kansas Sales Tax',
  rate: 7.5
}, {
  id: 5,
  name: 'US Default Sales Tax',
  rate: 4
}];
const mockedLocations = [{
  id: 1,
  name: 'FreshPay Smart Market',
  defaultTax: 2
}, {
  id: 2,
  name: 'Demo Market',
  defaultTax: 3
}, {
  id: 3,
  name: 'Grand Central Market',
  defaultTax: 1
}, {
  id: 4,
  name: 'Troy\'s Emporium',
  defaultTax: -1
}, {
  id: 5,
  name: 'Clothes 4 Sale',
  defaultTax: -1
}];

export default function Tax() {
  const {push} = useHistory();
  // eslint-disable-next-line no-unused-vars
  const [salesTax, setSalesTax] = useState(mockedSalesTax);
  // eslint-disable-next-line no-unused-vars
  const [locations, setLocations] = useState(mockedLocations);

  function renderAddButton() {
    return (
      <div id={`tax_card_content_render_add_button_wrapper`} className={clsx('d-flex justify-content-end', styles.btn)}>
        <Button
          id={`tax_card_content_render_add_button`}
          startIcon={<Add/>}
          size="large"
          variant="contained"
          color="primary"
          disableElevation
        >
          Add
        </Button>
      </div>
    );
  }

  return (
    <>
      <CardContent id={`tax_card_content_wrapper`}>
        <div id={`tax_card_content_inner_wrapper`} className={styles.wrapper}>
          <Typography id={`tax_card_content_typography_sales_tax`} variant="h4" className="d-flex justify-content-between">
            Sales Tax
            {renderAddButton()}
          </Typography>
          <Divider/>

          <div id={`tax_card_content_sales_table`} className={styles.wrapper}>
            <SalesTaxTable
              rows={salesTax}
              onEditClick={() => {}}
              onRemoveClick={() => {}}
            />
          </div>
        </div>
      </CardContent>
      <CardContent id={`tax_card_content_locations_container`}>
        <div id={`tax_card_content_locations_wrapper_container`} className={styles.wrapper}>
          <Typography id={`tax_card_content_locations_typography_label`} variant="h4">Locations</Typography>
          <Divider/>

          <div id={`tax_card_content_locations_table`} className={styles.wrapper}>
            <TaxLocationsTable
              rows={locations}
              taxes={salesTax}
              onChangeDefaultTax={() => {}}
            />
          </div>
        </div>
      </CardContent>
    </>
  );
}

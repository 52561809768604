import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
  IconButton,
  makeStyles
} from '@material-ui/core';
import { Remove, EditSharp } from '@material-ui/icons';
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';

import { getComparator, getDefaultStyles, stableSort } from '../../../../../services/table';

const headCells = [
  { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
  { id: 'lastSaved', numeric: false, disablePadding: false, label: 'Last Saved' },
  { id: 'actions', numeric: false, disablePadding: false, label: 'Actions' }
];

function EnhancedTableHead() {
  return (
    <TableHead id={`roles_table_header`}>
      <TableRow id={`roles_table_header_row`}>
        {headCells.map(headCell => (
          <TableCell
            id={`roles_table_header_cell_${headCell.id}`}
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles(getDefaultStyles);

export default function RolesTable(props) {
  const classes = useStyles();
  const { push } = useHistory();
  const [order] = useState('asc');
  const [orderBy] = useState('id');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const {
    rows
  } = props;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    const newSize = parseInt(event.target.value, 10);
    const newP = parseInt((page * rowsPerPage + 1) / newSize) ;
    setRowsPerPage(newSize);
    setPage(newP);
  };

  return (
    <div id={`roles_table_root_container`} className={classes.root}>
      <Paper  id={`roles_table_paper_container`} className={classes.paper}>
        <TableContainer  id={`roles_table_container`}>
          <Table
            id={`roles_table_inner_container`}
            className={classes.table}
            aria-labelledby="tableTitle"
            size="small"
            aria-label="enhanced table"
          >
            <EnhancedTableHead/>
            <TableBody  id={`roles_table_body_container`}>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(row => {
                  const { id, name, updatedOn } = row;

                  return (
                    <TableRow
                      hover
                      key={id}
                      id={`roles_table_row_${id}`}
                    >
                      <TableCell  id={`roles_table_cell_name_${id}`} align="left" padding="normal">
                        {name}
                      </TableCell>
                      <TableCell id={`roles_table_cell_update_on_${id}`} align="left" padding="normal">
                        {format(new Date(updatedOn), 'MM/dd/yyyy - hh:mm aa') || <Remove/>}
                      </TableCell>
                      <TableCell id={`roles_table_cell_button_wrapper_${id}`} align="left" padding="normal">
                      {Boolean (name.includes("Admins")) ? null : 
                        <IconButton id={`roles_table_cell_edit_button_${id}`} onClick={() => push({ pathname: `/setup/roles/${id}` })}>
                        <EditSharp/>
                        </IconButton>
                        }
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          id={`roles_table_pagination`}
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}

import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { fundsReportActionTypes } from "../actions/fundsreport.action";
import { RESET_STATE } from "../actions";

const initialFundsReportsState = {
  loading: false,
  error: "",
  data: null,
};

export const reducer = persistReducer(
  {
    storage,
    key: "yoke-fundsReports",
    whitelist: ["fundsReports"],
  },
  (state = initialFundsReportsState, action) => {
    switch (action.type) {
      case fundsReportActionTypes.GetFundsReport: {
        return {
          ...state,
          loading: true,
        };
      }

      case fundsReportActionTypes.StoreFundsReport: {
        return {
          ...state,
          loading: false,
          data: {
            ...action.payload,
          },
        };
      }

      case fundsReportActionTypes.ClearFundsReport: {
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      }
      case RESET_STATE: {
        return initialFundsReportsState;
      }
      default: {
        return state;
      }
    }
  }
);

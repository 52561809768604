import axios from 'axios';
import { getDefaultParameters_V2 } from './helpers/getDefaultParameters';

/**
 * Get list of triteq add-ons
 */
export function getAddOnsList({ limit = 10, page = 1 }, keywords = null) {
  let url = `/locations/coolers?limit=${limit}&page=${page}`+getDefaultParameters_V2(false)

  if (keywords)
    url += `&keywords=${keywords}`
  return axios.get(url);
}

/**
 * Get add-on for current location by ID
 */
export function getAddOnById(locationId, id) {
  return axios.get(`/locations/${locationId}/coolers/${id}`);
}

/**
 * Change add-on status
 */
export function sendCommandToAddOn(locationId, id, command) {
  return axios.post(`/locations/${locationId}/coolers/${id}?command=${command}`);
}

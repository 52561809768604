import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
  makeStyles,
  FormControl,
  Select,
  MenuItem
} from '@material-ui/core';

import { getComparator, getDefaultStyles, stableSort } from '../../../../../services/table';

import styles from '../../../../Operators/Locations/components/LocationDetails/components/Address/address.module.scss';

const headCells = [
  { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
  { id: 'defaultTax', numeric: false, disablePadding: false, label: 'Default Sales Tax' }
];

function EnhancedTableHead() {
  return (
    <TableHead  id={`tax_table_location_table_header`}>
      <TableRow  id={`tax_table_location_table_header_row`}>
        {headCells.map(headCell => (
          <TableCell
            id={`tax_table_location_table_header_cell_${headCell.id}`}
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles(getDefaultStyles);

export default function TaxLocationsTable(props) {
  const classes = useStyles();
  const [order] = useState('asc');
  const [orderBy] = useState('id');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const {
    rows,
    taxes,
    onChangeDefaultTax
  } = props;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    const newSize = parseInt(event.target.value, 10);
    const newP = parseInt((page * rowsPerPage + 1) / newSize) ;
    setRowsPerPage(newSize);
    setPage(newP);
  };

  return (
    <div id={`tax_table_location_table_root_wrapper`} className={classes.root}>
      <Paper id={`tax_table_location_table_root_wrapper`} className={classes.paper}>
        <TableContainer id={`tax_table_location_table_container`}>
          <Table
            id={`tax_table_location_table`}
            className={classes.table}
            aria-labelledby="tableTitle"
            size="small"
            aria-label="enhanced table"
          >
            <EnhancedTableHead/>
            <TableBody id={`tax_table_location_table_body_container`}>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(row => {
                  const { id, name, defaultTax } = row;

                  return (
                    <TableRow
                      id={`tax_table_location_table_row_${id}`}
                      hover
                      key={id}
                    >
                      <TableCell id={`tax_table_location_table_cell_name_${id}`} align="left" padding="normal">
                        {name}
                      </TableCell>
                      <TableCell id={`tax_table_location_table_cell_rate_select_${id}`} align="left" padding="normal">
                        <FormControl  id={`tax_table_location_table_cell_form_control_${id}`} className={styles.formControl}>
                          <Select
                            labelId="tax"
                            id="tax"
                            className={styles.input}
                            value={defaultTax}
                            onChange={e => onChangeDefaultTax(row, e.target.value)}
                          >
                            {taxes.map(tax => {
                              const { name, rate, id } = tax;

                              return (
                                <MenuItem  id={`tax_table_location_table_cell_menu_${id}`} key={id} value={id}>
                                  {name}
                                  &nbsp;
                                  (
                                  {rate}
                                  %
                                  )
                                </MenuItem>
                              );
                            })}

                            <MenuItem  id={`tax_table_location_table_cell_menu_item_${id}`} key={id} value={-1}>
                              No Tax (0%)
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
         id={`tax_table_location_table_pagination`}
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}

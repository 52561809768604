import React, { useState } from 'react';
import to from 'await-to-js';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { TextField } from '@material-ui/core';
import clsx from 'clsx';
import * as Sentry from "@sentry/react";

import * as actions from '../../store/actions';
import { login } from '../../services/auth';
import { setUser } from '../../classes/user';
import {parseJwt} from '../../router/RouterHelpers.js'
import { AUTH_TOKEN, IS_SAME_USER, LAST_USER_AUTH_TOKEN } from 'app/common/constant';

function Login(props) {
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: '2.5rem'
  });

  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({ paddingRight: '3.5rem' });
  };

  const disableLoading = () => {
    setLoading(false);
    setLoadingButtonStyle({ paddingRight: '2.5rem' });
  };
  const determineIsSameUserWithTokens = () => {
    const lastUserAuthToken = localStorage.getItem(LAST_USER_AUTH_TOKEN)
    const  newAuthToken = localStorage.getItem(AUTH_TOKEN)
    const lastUser = parseJwt(lastUserAuthToken)
    const currentUser = parseJwt(newAuthToken)

    if (lastUser && currentUser) {
      localStorage.setItem(IS_SAME_USER, String(lastUser.email === currentUser.email));
    } else {
      localStorage.setItem(IS_SAME_USER, 'false'); 
    }
    
  }

  return (
    <>
      <div className="kt-login__head">
        <span className="kt-login__signup-label">
          Don't have an account yet?
        </span>
        &nbsp;&nbsp;
        <Link to="/auth/operator-application" className="kt-link kt-login__signup-link">
          Apply now!
        </Link>
      </div>

      <div className="kt-login__body">
        <div className="kt-login__form">
          <div className="kt-login__title">
            <h3>
              <FormattedMessage id="AUTH.LOGIN.TITLE"/>
            </h3>
          </div>

          <Formik
            initialValues={{
              email: '',
              password: ''
            }}
            validate={values => {
              const errors = {};

              if (!values.email)
                // https://github.com/formatjs/react-intl/blob/master/docs/API.md#injection-api
                errors.email = intl.formatMessage({
                  id: 'AUTH.VALIDATION.REQUIRED_FIELD'
                });
              else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              )
                errors.email = intl.formatMessage({
                  id: 'AUTH.VALIDATION.INVALID_FIELD'
                });

              if (!values.password)
                errors.password = intl.formatMessage({
                  id: 'AUTH.VALIDATION.REQUIRED_FIELD'
                });

              return errors;
            }}
            onSubmit={async (values, { setStatus, setSubmitting }) => {
              enableLoading();

              const [err, res] = await to(login(values.email, values.password));

              if (err) {
                disableLoading();
                setSubmitting(false);
                setStatus(
                  intl.formatMessage({
                    id: 'AUTH.VALIDATION.INVALID_LOGIN'
                  })
                );

                return;
              }
              Sentry.setUser({ email: res.data.email, id:  res.data.id});

              setUser(res.data);

              localStorage.setItem(
                'yoke:user-account-id',
                JSON.stringify(res.data.permissions?.permissions)
              );
              localStorage.setItem(
                'yoke:permissions',
                JSON.stringify(res.data.permissions)
              );

              localStorage.setItem(
                'yoke:scope',
                res.data.scope
              );
              localStorage.setItem(
                'yoke:user-account-permissions',
                JSON.stringify(res.data.permissions.userPermissions)
              );
              localStorage.setItem('persist:demo3-auth', JSON.stringify({
                user: res.data,
                accessToken: 'access-token-8f3ae836da744329a6f93bf20594b5cc'
              }));
              localStorage.setItem(AUTH_TOKEN,  res?.data?.authorization)
              disableLoading();
              determineIsSameUserWithTokens()
              props.login('access-token-8f3ae836da744329a6f93bf20594b5cc');
            }}
          >
            {({
              values,
              status,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting
            }) => (
              <form
                noValidate
                autoComplete="off"
                className="kt-form"
                onSubmit={handleSubmit}
              >
                {status ? (
                  <div role="alert" className="alert alert-danger">
                    <div className="alert-text">{status}</div>
                  </div>
                ) : (
                 <div></div>
                )}

                <div className="form-group">
                  <TextField
                    type="email"
                    label="Email"
                    margin="normal"
                    className="kt-width-full"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                    helperText={touched.email && errors.email}
                    error={Boolean(touched.email && errors.email)}
                  />
                </div>

                <div className="form-group">
                  <TextField
                    type="password"
                    margin="normal"
                    label="Password"
                    className="kt-width-full"
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.password}
                    helperText={touched.password && errors.password}
                    error={Boolean(touched.password && errors.password)}
                  />
                </div>

                <div className="kt-login__actions">
                  <Link
                    to="/auth/forgot-password"
                    className="kt-link kt-login__link-forgot"
                  >
                    <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON"/>
                  </Link>

                  <button
                    id="kt_login_signin_submit"
                    type="submit"
                    disabled={isSubmitting}
                    className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                      {
                        'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': loading
                      }
                    )}`}
                    style={loadingButtonStyle}
                  >
                    Sign In
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}

export default injectIntl(
  connect(
    null,
    actions.authActions
  )(Login)
);

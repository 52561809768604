import React from 'react';
import clsx from 'clsx';
import { Divider, Typography } from '@material-ui/core';

import styles from './custom-button.module.scss';

export default function CustomButton(props) {
  const {
    isActive,
    title,
    subtitle,
    value,
    small,
    onClick,
    buttonsCount = 2,
    hasMinWidth = true,
    hasDivider = true
  } = props;

  return (
    <div
      id={`custom_button_container`}
      className={clsx('text-center', styles.card, {
        [styles.active]: isActive,
        [styles.withMinWidth]: hasMinWidth,
        [styles.customButtonCounts]: buttonsCount > 2
      })}
      onClick={() => onClick(value)}
    >
      <Typography id={`custom_title_button_container`} variant={small ? 'h6' : 'h5'}>
        {title}
      </Typography>
      {hasDivider ? <Divider className={styles.divider}/> : null}
      {subtitle ? (
        <Typography id={`custom_sub_title_button_container`} variant="subtitle1" color="textSecondary">
          {subtitle}
        </Typography>
      ) : null}
    </div>
  );
}

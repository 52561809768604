import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
  Button,
  makeStyles
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { getDefaultStyles, stableSort, getComparator } from '../../../services/table';

const headCells = [
  { id: 'locationName', numeric: false, disablePadding: false, label: 'Location Name' },
  { id: 'points', numeric: false, disablePadding: false, label: 'Points Issued' },
  { id: 'cost', numeric: false, disablePadding: false, label: 'Supply Cost Redeemed' },
  { id: 'retail', numeric: false, disablePadding: false, label: 'Retail Value Redeemed' },
  { id: 'issued', numeric: false, disablePadding: false, label: 'Products Issued' },
  { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
  { id: 'actions', numeric: false, disablePadding: false, label: 'Actions' }
];

function EnhancedTableHead() {
  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles(getDefaultStyles);

export default function LoyaltyTable(props) {
  const {
    rows,
    pagination,
    totalLocations,
    onPaginationChange
  } = props;
  const { push } = useHistory();
  const classes = useStyles();
  const [order] = useState('asc');
  const [orderBy] = useState('name');
  const [selected] = useState([]);
  const [page, setPage] = useState(pagination.page - 1);
  const [rowsPerPage, setRowsPerPage] = useState(pagination.limit);

   /**
   * Handle pagination value change
   */
  function handlePaginationChange({ target: { value } }, newPage = -1) {
    if (value) {
      const newSize = parseInt(value, 10);
      const newP = parseInt((page * rowsPerPage + 1) / newSize) ;

      onPaginationChange({
        page: newP + 1,
        limit: newSize
      });
      setPage(newP);
      setRowsPerPage(newSize);
    }

    if (newPage >= 0 && typeof newPage !== 'object') {
      onPaginationChange({
        page: newPage + 1,
        limit: rowsPerPage
      });
      setPage(newPage);
    }
  }

  function checkCreateOrEditLoyalty(loyalty) {
    let isLoyalty = false
    if (loyalty && loyalty.tiers)
      isLoyalty =  loyalty.tiers.some((element) => {return element.products.length > 0 });
    else if (loyalty)
    isLoyalty =  loyalty.some((element) => {return element.products.length > 0 });
    return isLoyalty;
  }

  return (
    <>
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="small"
            aria-label="enhanced table"
          >
            <EnhancedTableHead/>
            <TableBody>
                  {stableSort(rows, getComparator(order, orderBy))
                    .map(row => {
                      const {
                        id,  name, pointsIssued, redeems, loyatlyEnabled, loyalty 
                      } = row;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={id}
                    >
                      <TableCell align="left" padding="normal" scope="row">
                        {name}
                      </TableCell>
                      <TableCell align="left" padding="normal">{pointsIssued ?? 0}</TableCell>
                      <TableCell align="left" padding="normal">
                        $
                        {redeems[0].supplyPrice ?? 0}
                      </TableCell>
                      <TableCell align="left" padding="normal">
                        $
                        {redeems[0].retailPrice ?? 0}
                      </TableCell>
                      <TableCell align="left" padding="normal">
                        {redeems[0].totalProducts ?? 0}
                      </TableCell>
                      <TableCell align="left" padding="normal">{loyatlyEnabled ? 'On' : 'Off'}</TableCell>
                      <TableCell align="left" padding="normal">
                        <Button
                          size="medium"
                          variant="contained"
                          color={checkCreateOrEditLoyalty(loyalty) ? 'default' : 'primary'}
                          disableElevation
                          onClick={() => push({ pathname: `/loyalty/${id}` })}
                        >
                          { checkCreateOrEditLoyalty(loyalty) ? 'edit loyalty program' : 'create loyalty program'}
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={totalLocations}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handlePaginationChange}
          onRowsPerPageChange={handlePaginationChange}
        />
      </Paper>
    </div>
    </>
  );
}

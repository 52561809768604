import React from 'react';
import clsx from 'clsx';

import CustomButton from '../CustomButton/CustomButton';
import BasicPromotion from './components/BasicPromotion/BasicPromotion';
import AdvancedPromotion from './components/AdvancedPromotion/AdvancedPromotion';

import styles from './promotion-type.module.scss';

/**
 * Custom buttons
 */
const buttons = [{
  id: 1,
  title: 'Basic',
  subtitle: 'Offer customers a discount.',
  value: 'basic'
}, {
  id: 2,
  title: 'Advanced',
  subtitle: 'Offer customers a discount or gift, based on what they buy or how much they spend.',
  value: 'advanced'
}];

export default function PromotionType(props) {
  const {
    selectedType,
    basic,
    advanced,
    changePromotionType
  } = props;

  function changeProductFilter(value, property) {
    changePromotionType({ ...basic, productsFilter: { ...basic.productsFilter, [property]: value } }, 'basic');
  }

  function changeAdvancedProductFilter(value, property) {
    changePromotionType({ ...advanced, productsFilter: { ...advanced.productsFilter, [property]: value } }, 'advanced');
  }

  return (
    <div id={`promotion_type_wrapper`} className={styles.wrapper}>
      <div id={`promotion_type_inner_wrapper`} className={clsx(styles.promotionType, 'd-flex')}>
        {buttons.map(btn => (
          <CustomButton
            {...btn}
            key={btn.id}
            isActive={selectedType === btn.value}
            onClick={e => changePromotionType(e, 'selectedType')}
          />
        ))}
      </div>

      {selectedType === 'basic' ? (
        <BasicPromotion
          {...basic}
          changeBasicPromotion={(value, property) => changePromotionType({ ...basic, [property]: value }, 'basic')}
          onFilterChange={changeProductFilter}
        />
      ) : (
        <AdvancedPromotion
          {...advanced}
          changeAdvancedPromotion={
            (value, property) => changePromotionType({ ...advanced, [property]: value }, 'advanced')
          }
          onFilterChange={changeAdvancedProductFilter}
        />
      )}
    </div>
  );
}

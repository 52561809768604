/**
 * Filter Array By Id
 * @argument givenArray
 * @argument itenId 
 */
export function filterItemById(givenArray,itemId) {
  try {
      const matchingItem = givenArray.find((item) => item?.id === itemId);
      if (matchingItem) {
        return matchingItem;
      } else {
        return false
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
}

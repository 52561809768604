import React from "react";
import {
  TableRow,
  TableCell,
  Menu,
  MenuItem,
  IconButton,
} from "@material-ui/core";
import {
  Check,
  Close,
  ExpandLess,
  ExpandMore,
  Settings,
} from "@material-ui/icons";
import { useState } from "react";

export default function DetailsRow(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const {
    kiosks,
    isDetailsRowExpandable,
    onExpandRow = () => {},
    downloadLocationQRCode,
  } = props;
  const [downloadKioskLocationId, setDownloadKioskLocationId] = useState();

  /**
   * Handle click on Edit icon
   * @param e
   */
  function onSettingsClick(e, row) {
    e.stopPropagation();
    setDownloadKioskLocationId(row.id);
    setAnchorEl(anchorEl ? null : e.currentTarget);
  }

  /**
   * Handle settings close
   * @param action
   */
  function onSettingsClose(action) {
    setAnchorEl(null);

    if (action === "download") 
      downloadLocationQRCode(downloadKioskLocationId);
  }

  return (
    <>
      {kiosks.map((row) => {
        const { id, name, lastSync, power, isExpanded } = row;

        return (
          <TableRow id={`details_row_wrapper_${id}`}  hover key={id}>
            <TableCell id={`details_checkbox_cell_${id}`} align="left" padding="checkbox">
              {isDetailsRowExpandable ? (
                <IconButton onClick={() => onExpandRow(row, true)}>
                  {isExpanded ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
              ) : null}
            </TableCell>
            <TableCell id={`details_name_cell_${id}`} align="center" padding="normal">
              {name}
            </TableCell>
            <TableCell id={`details_lastsync_cell_${id}`} align="left" padding="normal">
              {typeof lastSync === "boolean" || lastSync === null ? (
                <div>{lastSync ? <Check /> : <Close />}</div>
              ) : (
                lastSync
              )}
            </TableCell>
            <TableCell id={`details_power_cell_${id}`} align="left" padding="normal">
              {power ? <Check /> : <Close />}
            </TableCell>
            <TableCell id={`details_setting_cell_${id}`} align="left" padding="normal">
              <IconButton id={`details_icon_setting_button_cell_${id}`} onClick={(e) => onSettingsClick(e, row)}>
                <Settings />
              </IconButton>
              <Menu
                id="menu"
                anchorEl={anchorEl}
                keepMounted={false}
                open={Boolean(anchorEl)}
                onClose={onSettingsClose}
              >
                <MenuItem id={`details_download_qa_code_cell_${id}`} onClick={() => onSettingsClose("download")}>
                  Download QR Code
                </MenuItem>
              </Menu>
            </TableCell>
          </TableRow>
        );
      })}
    </>
  );
}

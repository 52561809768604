export class CustomArray {
    constructor(maxLength = 10) {
      this.array = [];
      this.maxLength = maxLength;
    }
  
    push(...values) {
      values.forEach(value => {
        if (this.array.length < this.maxLength) {
          this.array.push(value);
        } else {
          console.log('Error: Maximum array length reached. Cannot add more elements.');
        }
      });
    }
  
    pop() {
      return this.array.pop();
    }
    
    reset() {
      this.array = [];
    }
    get length() {
      return this.array.length;
    }
  
    getValues() {
      return this.array;
    }
  }
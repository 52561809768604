export const setupLocationActionTypes = {
    StoreSetupLocations: '[Initialization Setup Location Entities] Action',
    StoreSetupLocationsSuccess: '[Get Setup Location Entities Success] Action',
    StoreSetupCurrentLocation: '[Get Setup Current Location Entities Success] Action',
    StoreSetupLocationsError: '[Get Setup Location Entities Failer] Action',
    clearSetupLocationsData : '[clearSetupLocationsData] Action'
  };
  
  export const setupLocationActions = {
    initializeSetupLocation: payload => ({
        type: setupLocationActionTypes.SelectActiveCustomer,
        payload: payload
      }),
    storeSetupLocations: payload => ({
      type: setupLocationActionTypes.StoreSetupLocationsSuccess,
      payload: payload
    }),
    storeSetupCurrentLocations: payload => ({
        type: setupLocationActionTypes.StoreSetupCurrentLocation,
        payload: payload
      }),
    storeSetupLocationsError: payload => ({
      type: setupLocationActionTypes.StoreSetupLocationsError,
      payload: payload
    }),
    clearSetupLocationsData: () => ({
      type: setupLocationActionTypes.clearSetupLocationsData,
      payload: {},
    }),
  };
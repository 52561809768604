import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import { EditSharp } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';

import { getDefaultStyles } from '../../../../../services/table';

const headCells = [
  { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
  { id: 'accountId', numeric: false, disablePadding: false, label: 'Account ID' },
  { id: 'contact', numeric: false, disablePadding: false, label: 'Contact' },
  { id: 'inventory', numeric: false, disablePadding: false, label: 'Inventory System' },
  { id: 'creditCardProcessor', numeric: false, disablePadding: false, label: 'Credit Card Processor' },
  { id: 'actions', numeric: false, disablePadding: false, label: 'Actions' }
];

function EnhancedTableHead() {
  return (
    <TableHead  id={`setup_operators_table_header`}>
      <TableRow id={`setup_operators_table_header_row`}>
        {headCells.map(headCell => (
          <TableCell
          id={`setup_operators_table_header_cell_${headCell.id}`}
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles(getDefaultStyles);

export default function SetupOperatorsTable(props) {
  const { push } = useHistory();
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const {
    rows,
    totalCount,
    onPaginationChange,
  } = props;

  function handlePaginationChange({ target: { value } }, newPage = -1) {
    if (value) {
      const newSize = parseInt(value, 10);
      const newP = parseInt((page * rowsPerPage + 1) / newSize);

      onPaginationChange({
        page: newP + 1,
        limit: newSize,
      });
      setPage(newP);
      setRowsPerPage(newSize);
    }

    if (newPage >= 0 && typeof newPage !== "object") {
      onPaginationChange({
        page: newPage + 1,
        limit: rowsPerPage,
      });
      setPage(newPage);
    }
  }

  return (
    <div id={`setup_operators_root_container`}  className={classes.root}>
      <Paper  id={`setup_operators_paper_container`} className={classes.paper}>
        <TableContainer id={`setup_operators_table_container`}>
          <Table
            id={`setup_operators_table_container`}
            className={classes.table}
            aria-labelledby="tableTitle"
            size="small"
            aria-label="enhanced table"
          >
            <EnhancedTableHead/>
            <TableBody id={`setup_operators_table_body_container`}>
              {rows.map(row => {
                const {
                  id,
                  name,
                  firstName,
                  lastName,
                  provider: {name: inventoryManagementSystem} = {name: ""},
                  processor: {name: paymentProcessor} = {name: ""},
                  parent
                } = row;

                return (
                  <TableRow
                  id={`setup_operators_table_row_${id}`}
                    hover
                    key={id}
                  >
                    <TableCell  id={`setup_operators_table_name_cell_${id}`} align="left" padding="normal">
                      {name}
                    </TableCell>
                    <TableCell id={`setup_operators_table_id_cell_${id}`} align="left" padding="normal">
                      {id}
                    </TableCell>
                    <TableCell id={`setup_operators_table_full_name_cell_${id}`} align="left" padding="normal">
                      {firstName && lastName ? `${firstName} ${lastName}` : 'N/A'}
                    </TableCell>
                    <TableCell id={`setup_operators_table_inventory_management_cell_${id}`} align="left" padding="normal">
                      {!inventoryManagementSystem ? 'N/A' : inventoryManagementSystem}
                    </TableCell>
                    <TableCell  id={`setup_operators_table_payment_process_cell_${id}`}  align="left" padding="normal">
                      {!paymentProcessor ? 'N/A' : paymentProcessor}
                    </TableCell>
                    <TableCell id={`setup_operators_table_icon_buttton_cell_${id}`} align="left" padding="normal">
                      <IconButton onClick={() => push({ pathname: `/setup/operators/${id}` })}>
                        <EditSharp/>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
         id={`setup_operators_table_pagination`}
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handlePaginationChange}
          onRowsPerPageChange={handlePaginationChange}
        />
      </Paper>
    </div>
  );
}

import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  DialogTitle
} from '@material-ui/core';
import { DeleteForever } from '@material-ui/icons';

import styles from './remove-from-invite-list.module.scss';

export default function RemoveFromInviteList(props) {
  const [isModalOpen, setModalOpen] = useState(false);
  const {
    selectedCustomers,
    onUpdate
  } = props;

  /**
   * Handle cancel click
   */
  function onCancelClick() {
    setModalOpen(false);
  }

  /**
   * Handle remove from group
   */
  function onDeleteFromGroup() {
    setModalOpen(false);
    onUpdate();
  }

  return (
    <>
      <Button
        id="remove_from_invite_button"
        startIcon={<DeleteForever/>}
        className={styles.btn}
        size="medium"
        variant="contained"
        color="secondary"
        disableElevation
        disabled={!selectedCustomers.length}
        onClick={() => setModalOpen(true)}
      >
        Remove
      </Button>

      {isModalOpen ? (
        <Dialog
          id="remove_from_invite_alert_dialog"
          open
          onClose={onCancelClick}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="remove_from_invite_alert_dialog_title">
            Remove From Invite List
          </DialogTitle>
          <DialogContent id="remove_from_invite_alert_dialog_content">
            Are you sure you want to remove
            &nbsp;
            <b>
              #
              {selectedCustomers.length}
              &nbsp;
              selected
              &nbsp;
              {selectedCustomers.length === 1 ? 'row' : 'rows'}
            </b>
            ?
          </DialogContent>
          <DialogActions id="remove_from_invite_alert_dialog_action_button">
            <Button id="remove_from_invite_alert_dialog_cancel_action_button" onClick={onCancelClick} color="primary">
              Cancel
            </Button>
            <Button
              id="remove_from_invite_alert_dialog_remove_action_button"
              startIcon={<DeleteForever/>}
              onClick={onDeleteFromGroup}
              autoFocus
              variant="contained"
              color="primary"
              disableElevation
            >
              Remove
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
    </>
  );
}

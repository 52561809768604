export const resizeBase64Image = (base64Image, width = 560, height=500) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = base64Image;
      img.onload = function () {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext('2d');
        const newWidth = width
        const newHeight = height;
        canvas.width = newWidth;
        canvas.height = newHeight;
        ctx.drawImage(img, 0, 0, newWidth, newHeight);
        let quality = 0.8;
        let dataURL = canvas.toDataURL('image/png', quality);
        resolve(dataURL);
      };
    });
  }
  
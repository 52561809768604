import React from 'react';

import '../../../_metronic/_assets/sass/pages/error/error-1.scss';

export function ErrorPage1() {
  return (
    <>
      <div className="kt-grid kt-grid--ver kt-grid--root">
        <div
          className="kt-grid__item kt-grid__item--fluid kt-grid kt-error-v1"
        >
          <div className="kt-error-v1__container">
            <h1 className="kt-error-v1__number">Page not found.</h1>
            <p className="kt-error-v1__desc">We couldn't find the page you were looking for. Are you sure you have the address right?</p>
          </div>
        </div>
      </div>
    </>
  );
}

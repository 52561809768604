import { getScope } from "app/services/helpers/account-id";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import UserNotifications from "../../../app/partials/layout/UserNotifications";
import UserProfile from "../../../app/partials/layout/UserProfile";

export default function Topbar() {
  const selectedOperator = useSelector(state => state.subHeader.selectedOperator);


  return (
    <div className="kt-header__topbar">
      {(selectedOperator?.loyaltyProgram == 'globalConnect' ? false : getScope() === "user admin" || getScope() === "super admin") && (
        <div
          id="kt_header_menu"
          className="kt-header-menu kt-header-menu-mobile kt-header-menu--layout-tab"
        >
          <ul className="kt-menu__nav ">
            <li
              className="kt-menu__item kt-menu__item--rel"
              aria-haspopup="true"
              data-ktmenu-submenu-toggle="hover"
            >
              <Link to="/tickets" className="kt-menu__link">
                <i className="kt-menu__link-icon fa fa-ticket-alt" />
                <span className="kt-menu__item-here" />
                <span className="kt-menu__link-text">Tickets</span>
              </Link>
            </li>
          </ul>
        </div>
      )}

      <UserNotifications
        skin="light"
        iconType=""
        icon="flaticon2-bell-alarm-symbol"
        type="primary"
        dot="false"
      />

      <UserProfile showBadge />
    </div>
  );
}

import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React from "react";

import { useStyles } from "../Customers/components/Groups/components/GroupCustomers/Styles";

function BubbleContainer(props) {
  const { title, options, removeElements } = props;

  const bubbleStyles = useStyles();

  function TempBubble(props) {
    const { ele } = props;
    return (
      <div id={`bubble_container_temp_wrapper`} className={bubbleStyles.bubbleContainer}>
        <div
          id={`bubble_container_temp_wrapper_namw`}
          className="customerName"
          style={{ whiteSpace: "nowrap", textAlign: "center" }}
        >
          {`${ele.name}`}
        </div>
        <IconButton
          id={`bubble_container_icon_button`}
          onClick={() => {
            removeElements(ele);
          }}
        >
          <Close />
        </IconButton>
      </div>
    );
  }

  return (
    <>
      <div id={`bubble_container_wrapper`} className={bubbleStyles.container}>
          
        <div id={`bubble_container_box_wrapper`} className={bubbleStyles.bubbleBox}>
        <h6 id={`bubble_container_heading`} className={bubbleStyles.containerTitle}>{title}</h6>
          {options.map((ele, index) => (
            <TempBubble ele={ele} key={index} />
          ))}
        </div>
      </div>
    </>
  );
}

export default React.memo(BubbleContainer);

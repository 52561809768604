import { add, format, sub, subMonths ,startOfWeek , startOfMonth, startOfYear, isSameDay } from "date-fns";
import {
  ALL_OPERATORS,
  DASHBOARD_PLAIN_DATE_FORMAT,
  EDateFilterRanges,
  FILTERS,
  OPERATOR_PARAM,
} from "app/common/constant";
import { getScope, getLoggedUserAccountId2 } from "./account-id";
import { ROLE } from "app/common/roleConstant";

export const getDefaultParameters = (start) => {
  return start
    ? window.location.search
    : "&" + window.location.search.substring(1);
};
export function getStartDateAndEndDateInISOFormat() {
  let startDate, endDate;
  let urlParams = new URLSearchParams(window.location.search);
  const selectedTimeFilter = urlParams.get("period");
  const startDateParam = urlParams.get("startDate")
  const endDateParam = urlParams.get("endDate") 
  const isCustomDate = ( startDateParam || endDateParam ) ? true : false;
  const {startDateBasedOnTimePeriod,endDateBasedOnTimePeriod} = getStartAndEndDatesBasedOnTimePeriod(selectedTimeFilter,isCustomDate,endDateParam)
  startDate = new Date(
    startDateParam ? startDateParam : startDateBasedOnTimePeriod
  );
  isCustomDate && startDate.setHours(0, 0, 0);
  startDate = startDate.toISOString();

  endDate = endDateBasedOnTimePeriod
  endDate = endDate.toISOString();
  return { startDate, endDate ,selectedTimeFilter};
}
// V2 for updated start date and end date with corrected hours and minutes and startDate and end always in query string
export const getDefaultParameters_V2 = (start) => {
  const parametersString = window.location.search.substring(1);

  let parametersArray = parametersString.split("&");
  const { startDate, endDate } = getStartDateAndEndDateInISOFormat();

  parametersArray = parametersArray.filter(
    (params) => !params.includes("startDate") && !params.includes("endDate")
  );
  parametersArray.push("startDate=" + startDate);
  parametersArray.push("endDate=" + endDate);

  for (let i = 0; i < parametersArray.length; i++) {
    const current = parametersArray[i];
    const [key, value] = current.split("=");
    if (
      key === OPERATOR_PARAM &&
      value === ALL_OPERATORS.id &&
      !(getScope() === ROLE.SUPERADMIN)
    ) {
      parametersArray[i] = `${OPERATOR_PARAM}=${getLoggedUserAccountId2()}`;
    }
  }
  let result = parametersArray.join("&");

  return start ? "?" + result : "&" + result;
};

export const getStartAndEndDate = (dateFormat = "") => {
  let urlParams = new URLSearchParams(window.location.search);
  const today = new Date();
  const selectedTimeFilter = urlParams.get("period");
  let startDate, endDate;

  if (dateFormat !== "") {
    startDate = urlParams.get("startDate")
      ? format(new Date(urlParams.get("startDate")), dateFormat)
      : format(calculateInitialTime(today, selectedTimeFilter), dateFormat);
    endDate = urlParams.get("endDate")
      ? format(new Date(urlParams.get("endDate")), dateFormat)
      : format(today, dateFormat);
  } else {
    startDate = urlParams.get("startDate")
      ? urlParams.get("startDate")
      : calculateInitialTime(today, selectedTimeFilter);
    endDate = urlParams.get("endDate") ? urlParams.get("endDate") : today;
  }

  return `&startDate=${startDate}&endDate=${endDate}`;
};

export function calculateInitialTime(lastDate, timeFrame) {
  let tempDate = sub(lastDate, { [timeFrame]: 1 });
  return add(tempDate, { ["days"]: 1 });
}

export function getStartAndEndDatesBasedOnTimePeriod(period = EDateFilterRanges.DAYS,isCustomDate,customEndDate) {
  const today = new Date();
  let startDateBasedOnTimePeriod, endDateBasedOnTimePeriod;

  if (period === EDateFilterRanges.DAYS) {
    startDateBasedOnTimePeriod = new Date(today);
    startDateBasedOnTimePeriod.setHours(0, 0, 0);
  } else if (period === EDateFilterRanges.WEEKS) {
    startDateBasedOnTimePeriod = startOfWeek(today, { weekStartsOn: 0 }); // Start of the week (Sunday)
    startDateBasedOnTimePeriod.setHours(0, 0, 0);
  } else if (period === EDateFilterRanges.MONTHS) {
    startDateBasedOnTimePeriod = startOfMonth(today); // Start of the month (1st day)
    startDateBasedOnTimePeriod.setHours(0, 0, 0);
  } else if (period === EDateFilterRanges.YEARS) {
    startDateBasedOnTimePeriod = startOfYear(today); // Start of the year (Jan 1st)
    startDateBasedOnTimePeriod.setHours(0, 0, 0);
  } else {
    startDateBasedOnTimePeriod = new Date(today); // Default to "day"
    startDateBasedOnTimePeriod.setHours(0, 0, 0);
  }
  customEndDate = new Date(customEndDate);
  if (!isSameDay(customEndDate, today)) {
    customEndDate.setHours(23, 59, 59);
  }
  endDateBasedOnTimePeriod = isCustomDate ? customEndDate : today;
  return { startDateBasedOnTimePeriod ,endDateBasedOnTimePeriod };
}

export function getPlainStartAndEndDates(timePeriodFilter, startDate, endDate) {
  let plainStartDate = format(new Date(), DASHBOARD_PLAIN_DATE_FORMAT); //Setting Initial Values
  let plainEndDate = format(new Date(), DASHBOARD_PLAIN_DATE_FORMAT); //Setting Initial Values
  const timeFrame = !timePeriodFilter ? FILTERS[3].value : timePeriodFilter;
  const lastDate = endDate ?? new Date();
  const initialDate =
    timeFrame === EDateFilterRanges.QUARTERS
      ? subMonths(lastDate, 3)
      : startDate ?? calculateInitialTime(lastDate, timeFrame);
  plainStartDate = format(new Date(initialDate), DASHBOARD_PLAIN_DATE_FORMAT); //Dashboard dates format
  plainEndDate = format(new Date(lastDate), DASHBOARD_PLAIN_DATE_FORMAT); //Dashboard dates format
  return { plainStartDate, plainEndDate };
}

export function formatDateToPlainStringToPassInURL(date) {
  date = new Date(date);
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is 0-based
  const day = String(date.getDate()).padStart(2, "0");
  const year = date.getFullYear();
  return `${month}-${day}-${year}`;
}

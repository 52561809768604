import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { Add } from '@material-ui/icons';
import { Button } from '@material-ui/core';

import { accessPermission } from 'app/common/helperFunction';

import Search from '../../../../partials/content/Customers/Search';
import InviteTable from './components/InviteTable/InviteTable';
import RemoveFromInviteList from './components/RemoveFromInviteList/RemoveFromInviteList';
import { getCustomers, filterCustomers, addNewCustomer, updateCustomer, removeCustomers } from './services/customers';

import styles from './invite-customers.module.scss';

export default function InviteCustomers() {
  const {push} = useHistory();
  const tableRef = useRef(null);
  const [searchQuery, setSearchQuery] = useState();
  const [customers, setCustomers] = useState(getCustomers());
  const [selectedCustomers, setSelectedCustomers] = useState([]);

  /**
   * Change subheader text
   */
  useEffect(() => {

    if(!accessPermission("CustomersAll")){
      push({pathname: "/dashboard"});
    }

    const subheader = document.getElementById('subheader-title');
    const arrowBack = document.getElementById('subheader-back-button');
    const mainFilters = document.getElementById('main-filters');

    // Manually set subheader
    subheader.innerText = 'Invite Customers';
    // Show back button
    arrowBack.style.display = 'block';
    // Hide main filters
    mainFilters.style.display = 'none';

    return () => {
      arrowBack.style.cssText = '';
      mainFilters.style.cssText = '';
    };
  }, []);

  /**
   * Handle changes of search query and group filter
   */
  useEffect(() => {
    setCustomers(filterCustomers(searchQuery));
  }, [searchQuery]);

  /**
   * Add new customer
   */
  function onAddNewCustomerButtonClick() {
    tableRef.current.dataManager.changeRowEditing();
    tableRef.current.setState({
      ...tableRef.current.state,
      showAddRow: !tableRef.current.state.showAddRow
    });
  }

  /**
   * Update customers row
   * @param customer
   */
  function onUpdateCustomer(customer) {
    updateCustomer(customer);
    setCustomers(filterCustomers(searchQuery));
  }

  /**
   * Add new customer
   * @param customer
   */
  function onAddCustomer(customer) {
    addNewCustomer(customer);
    setCustomers(filterCustomers(searchQuery));
  }

  /**
   * Remove customers
   */
  function onRemoveCustomers() {
    removeCustomers(selectedCustomers);
    setCustomers(filterCustomers(searchQuery));
  }

  return (
    <>
      <Search
        id="inviteCustomer"
        iconPosition="start"
        hasLabel={false}
        placeholder="Filter"
        query={searchQuery}
        onChange={e => setSearchQuery(e.target.value)}
      />

      <div id="inviteCustomer_button_wrapper" className={clsx(styles.buttonsWrapper, 'd-flex', 'justify-content-between')}>
        <div id="inviteCustomer_button_inner_wrapper" >
          <Button
            id="inviteCustomer_primary_button"
            startIcon={<Add/>}
            size="medium"
            variant="contained"
            color="primary"
            disableElevation
            className={styles.addBtn}
            onClick={onAddNewCustomerButtonClick}
          >
            Add
          </Button>

          <RemoveFromInviteList
            selectedCustomers={selectedCustomers}
            onUpdate={onRemoveCustomers}
          />
        </div>
        <div/>
      </div>

      <InviteTable
        tableRef={tableRef}
        rows={customers}
        onUpdate={onUpdateCustomer}
        onRowAdd={onAddCustomer}
        onSelectionChange={e => setSelectedCustomers(e)}
      />
    </>
  );
}

import React, { useState, Fragment, useEffect } from 'react';
import to from 'await-to-js';
import {
  Divider,
  Card,
  CardContent,
  Typography,
  Switch,
  FormControlLabel,
  FormControl,
  FormHelperText,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Button
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import { accessPermission } from 'app/common/helperFunction';
import ResponseModal from 'app/components/Helpers/ResponseModal';

import useCustomerHeader from '../../../../../hooks/useCustomHeader';
import { getLoggedUserAccountId } from '../../../../../services/helpers/account-id';
import { createNewRole, getSelectedRole, updateSelectedRole } from '../../../../../services/roles';
import { getAccountGroups } from '../../../../../services/account';
import { getLoggedUserAccountId2, hasMultipleAccounts } from '../../../../../services/helpers/account-id';
import  OperatorSpecificModal  from '../../../../Helpers/OperatorSpecificModal';
import {
  defaultView,
  mapRoleToView,
  mapViewToRequest2
} from './helpers/role-details';

import styles2 from '_metronic/_assets/sass/loading-text.module.scss';
import styles from './role-details.module.scss';
import { useCustomResponse } from 'app/hooks/useCustomResponse';

const classNames = {
  h4: styles.header4,
  h6: styles.header6,
};

export default function RoleDetails(props) {
  const {push, goBack} = useHistory();
  const { filter: { locations } } = props;
  const { id, isNew } = useCustomerHeader({
    property: 'name',
    suffix: 'Role',
    isCreationAllowed: true,
    createNew: 'Role'
  });
  const [permissions, setPermissions] = useState(defaultView);
  const [availableGroups, setAvailableGroups] = useState([]);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [selectedGroup, setSelectedGroup] = useState('');
  const [isLoad, setLoaded] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [operatorSpecificModalOpen, setOperatorSpecificModalOpen] = useState(false);
  const [hasError , setHasError] = useState(false);
  const [responseValue, setResponseValue] = useCustomResponse();
  /**
   * Fetch data on component rendering
   */
  useEffect(() => {

    if(!accessPermission("SetupAll")){
      push({pathname: "/dashboard"});
    }

    if (locations && locations.length && !isNew)
      getRoleDetails();

    //if (isNew)
    getGroups();
    setIsLoaded(true);
    setLoaded(true);
    // eslint-disable-next-line
  }, []);

    /**
   * Get groups
   * @returns {Promise<void>}
   */
     async function getGroups() {

      if(hasMultipleAccounts()){
        setOperatorSpecificModalOpen(true);
        return
      }

      const accountId = getLoggedUserAccountId();

      const [err, res] = await to(getAccountGroups(accountId));
  
      setIsLoaded(true);
      if (err){
        Sentry.captureException(err);
        if (!hasMultipleAccounts())
        setResponseValue({...responseValue, isMessageOpen: true, isSuccess:false, message:`Error while getting groups. ${err}`});      
        return console.error('Error on available groups fetching: ', err);
      }
      setAvailableGroups(res.data);

     }


  /**
   * Get role details and permissions
   * @returns {Promise<void>}
   */
  async function getRoleDetails() {

    if(hasMultipleAccounts()){
      setOperatorSpecificModalOpen(true);
      return
    }

    setLoaded(false);

    const accountId = getLoggedUserAccountId();
    const [err, res] = await to(getSelectedRole(accountId, id));
  
    setIsLoaded(true);
    if (err && !isNew){
      setResponseValue({...responseValue, isMessageOpen: true, isSuccess:false, message:`Error while getting group details. ${err}`});
      Sentry.captureException(err);
      return console.error('Error on role details fetching: ', err);
    }
    const { userGroupAccounts, userPermissions } = res.data;


    if (userGroupAccounts && userGroupAccounts.length) {
      const [attachedGroup] = userGroupAccounts;
      const currentGroup = availableGroups.find(g => g.id === attachedGroup.id);

      setSelectedGroup(!currentGroup ? '' : currentGroup.id);
    }

    const [{ role }] = !userPermissions || !userPermissions.length ? [{}] : userPermissions;
    setName(role?.name)
    setDescription(role?.description)
    setSelectedGroup(role?.GroupId)
    setPermissions(mapRoleToView(role));
  }

  /**
   * Change permission
   */
  function changePermission(levelId, id, value, variant) {
    const updatedPermissions = permissions.map(permission => {
      if (permission.id !== levelId && variant === 'h4')
        return permission;

      const updatedValues = permission.values.map(item => {
        if (item.id !== id && variant === 'h6')
          return item;

        return {
          ...item,
          access: value
        };
      });

      return {
        ...permission,
        values: [...updatedValues]
      };
    });

    setPermissions(updatedPermissions);
  }

  /**
   * Handle role create or update
   */
  async function onUpdateRole() {
    const accountId = getLoggedUserAccountId2();
    if (!selectedGroup){
      setHasError(true);
      return
    }

    const userPermissions = mapViewToRequest2({ name, description, GroupId: selectedGroup }, permissions);

    const [err, res] = await to(isNew
      ? createNewRole(selectedGroup, userPermissions[0]?.role)
      : updateSelectedRole(accountId, id, userPermissions[0]?.role ));

    if (!isNew && err) {
      Sentry.captureException(err);
      setResponseValue({
        ...responseValue,
        isMessageOpen: true,
        isSuccess: false,
        message: `Error while updating role. ${err}`,
      });
      return console.error("Role was not saved: ", err);
    } else if (isNew && err) {
      const { success, message } = err?.response?.data;
      Sentry.captureException(err);
      setResponseValue({
        ...responseValue,
        isMessageOpen: true,
        isSuccess: success,
        isServerError: true,
        message: message,
      });
      return console.error("Error while  creating a new role: ", err);
    }
    const displayMessage = isNew ? "Role created !" : "Role updated !"
    setResponseValue({...responseValue, isMessageOpen: true, isSuccess:true, message:displayMessage});
    
  }

  function handleChangeLocationGroup(e) {
    setHasError(false);
    setSelectedGroup(e.target.value);
  }

  function handleResponseClose(value) {
    setResponseValue({...responseValue, isMessageOpen:value});
    goBack();
  }

  return (
    <>
      {isLoad ? (
        <Card id={`roles_details_loading_container`}>
          {
            !isLoaded && <div className={styles2.loadingContainer}>
              <p id={`roles_details_loading_content`} className ={styles2.loadingText}>
                Loading...
              </p>
            </div>
          }
          <div id={`roles_details_form_wrapper`} className={styles.permissionMeta}>
            <FormControl id={`roles_details_form_container_role_name`} className={styles.roleName}>
              <TextField
                id="roleName"
                value={name}
                label="Name"
                placeholder="Enter the group name"
                variant="outlined"
                onChange={e => setName(e.target.value)}
              />
            </FormControl>
            <FormControl id={`roles_details_form_container_role_description`} className={styles.roleDescription}>
              <TextField
                id="roleDescription"
                value={description}
                variant="outlined"
                label="Description"
                onChange={e => setDescription(e.target.value)}
              />
            </FormControl>
            <FormControl id={`roles_details_form_container_assign_group`} className={styles.selectGroup}>
              <InputLabel  id={`roles_details_form_container_assign_group_label`}  htmlFor="assignedGroups">
                Location Group
              </InputLabel>

              <Select
                id="assignedGroups"
                labelId="assignedGroups"
                label="Location Group"
                value={selectedGroup}
                variant="outlined"
                onChange={e => handleChangeLocationGroup(e)}
              >
                {availableGroups.map(group => (
                  <MenuItem  id={`roles_details_form_menu_item`}  key={group.id} value={group.id}>
                    {group.name}
                  </MenuItem>
                ))}
              </Select>
                {hasError &&  <FormHelperText  id={`roles_details_form_helper_text_content`}  style={{color: "red", fontSize: "1rem"}}>This is required!</FormHelperText>}
            </FormControl>
          </div>
          {permissions.map((rule, i) => {
            const { id: ruleId, values } = rule;
            return (
              <Fragment key={ruleId}>
                <CardContent  id={`roles_details_card_content_container`}  className="d-flex flex-column">
                  {values.map(({ variant, access, name, id }) => (
                    <div id={`roles_details_card_inner_content_container`} key={id} className={classNames[variant]}>
                      <Typography id={`roles_details_inner_card_content_typography`} variant={variant}>{name}</Typography>
                      <div>
                        <FormControlLabel
                          id={`roles_details_inner_form_controll_label_switch`}
                          control={(
                            <Switch
                              id={`roles_details_switch_input`}
                              checked={access === 'read' || access === 'write'}
                              color="primary"
                              onChange={e => changePermission(ruleId, id, (e.target.checked ? 'read' : null), variant)}
                            />
                          )}
                          labelPlacement="start"
                          label="Can View:"
                        />
                      </div>
                    </div>
                  ))}
                </CardContent>
                {i !== permissions.length - 1 ? <Divider/> : null}
              </Fragment>
            );
          })}

          <div  id={`roles_details_button_wrapper`} className={styles.bottomAction}>
            <Button
              id={`roles_details_save_button`}
              size="large"
              variant="contained"
              color="primary"
              disableElevation
              disabled={name?.trim()?.length === 0}
              onClick={() => onUpdateRole()}
            >
              Save
            </Button>
          </div>
        </Card>
      ) : null}
      {operatorSpecificModalOpen && (
        <OperatorSpecificModal
          open={operatorSpecificModalOpen}
          onClose={value => setOperatorSpecificModalOpen(value)}
        />
      )}
      <ResponseModal
        isSuccess={responseValue?.isSuccess}
        message={responseValue?.message}
        open={responseValue?.isMessageOpen}
        onClose={handleResponseClose}
      />
    </>
  );
}

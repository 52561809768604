import React from 'react';
import {
  FormControl, RadioGroup, Radio, FormControlLabel, CardContent, Typography, Divider, TextField, IconButton, Button
} from '@material-ui/core';
import { Add, DeleteForever } from '@material-ui/icons';

import styles from '../../../../../../../Customers/components/InviteCustomers/invite-customers.module.scss';

const availableTypes = [{
  id: 1,
  name: 'Limit to a Customer Group',
  description: 'Only customers from chosen group will receive a promotion.',
  value: 'limit'
}, {
  id: 2,
  name: 'Apply a Promo Code',
  description: 'Only customers with a promo code can redeem this promotion.',
  value: 'promo-code'
}];

export function ExclusivePromotion(props) {
  const {
    type,
    promoCodes = [],
    changeExclusiveOptions
  } = props;

  function changePromoCode(value, property, item) {
    const updatedPromoCodes = promoCodes.map(promoCode => {
      if (promoCode.id !== item.id)
        return promoCode;

      return {
        ...promoCode,
        [property]: value
      };
    });

    changeExclusiveOptions(updatedPromoCodes, 'promoCodes');
  }

  function removePromoCode(id) {
    const updatedPromoCodes = promoCodes.filter(promoCode => promoCode.id !== id);

    changeExclusiveOptions(updatedPromoCodes, 'promoCodes');
  }

  function addPromoCode() {
    const updatedPromoCodes = [...promoCodes, {
      id: Date.now(),
      code: '',
      number: ''
    }];

    changeExclusiveOptions(updatedPromoCodes, 'promoCodes');
  }

  return (
    <>
      <CardContent id={`exclusive_promotion_card_content_wrapper`}>
        <FormControl id={`exclusive_promotion_form_control_wrapper`} component="fieldset">
          <RadioGroup
            id={`exclusive_promotion_radio_group`}
            aria-label="promotion-target"
            name="promotion-target"
            value={type}
            onChange={e => changeExclusiveOptions(e.target.value, 'type')}
          >
            {availableTypes.map(availableType => (
              <React.Fragment key={availableType.id}>
                <FormControlLabel
                  id={`exclusive_promotion_form_control_label`}
                  value={availableType.value}
                  control={<Radio/>}
                  label={availableType.name}
                />
                <Typography id={`exclusive_promotion_form_control_typography`} variant="body1">{availableType.description}</Typography>
                <br/>
              </React.Fragment>
            ))}
          </RadioGroup>
        </FormControl>
      </CardContent>

      {type === 'limit' ? (
        <CardContent id={`exclusive_promotion_card_content_limit`} className="d-flex justify-content-center">
          <div className="col-6">
          </div>
        </CardContent>
      ) : null}

      {type === 'promo-code' ? (
        <CardContent id={`exclusive_promotion_card_content_promo_code`} className="d-flex justify-content-center">
          <div id={`exclusive_promotion_card_content_add_promo_code`} className="col-6">
            <Typography id={`exclusive_promotion_card_content_add_promo_code_title`} variant="h4">Add Promo Codes</Typography>
            <br/>
            <Typography id={`exclusive_promotion_card_content_add_promo_code_desc`}  variant="h6">
              These codes can be redeemed as many times as you decide. Use this to reach a
              wide audience. Custom codes cannot be created more than once across all promotions.
            </Typography>
            <br/>
            <br/>
            <div id={`exclusive_promotion_card_content_add_promo_code_divider`}  className="d-flex">
              <Divider/>
              <div id={`exclusive_promotion_card_content_custom_promo_code_title`}  className="col-5">
                <Typography id={`exclusive_promotion_card_content_custom_promo_code_title_desc`}   variant="h5">Custom Promo Code</Typography>
              </div>
              <div id={`exclusive_promotion_card_content_number_promo_code_wrapper`}  className="col-5">
                <Typography id={`exclusive_promotion_card_content_number_promo_code_typography`} variant="h5">Number of redemption</Typography>
                <Typography  id={`exclusive_promotion_card_content_leave_blank_typography`}>Leave blank for unlimited</Typography>
              </div>
              <div className="col-2"/>
              <Divider/>
            </div>
            {promoCodes.map(item => (
              <div  id={`exclusive_promotion_card_content_promo_code_item_${item.id}`} key={item.id} className="d-flex align-items-center">
                <div  id={`exclusive_promotion_card_content_promo_code_list_item_${item.id}`} className="col-5">
                  <FormControl  id={`exclusive_promotion_card_content_promo_code_item_form_control_${item.id}`} fullWidth>
                    <TextField
                      margin="normal"
                      id="code"
                      label="Custom code"
                      placeholder="Enter a custom code, eg. FBAana50%"
                      value={item.code}
                      onChange={e => changePromoCode(e.target.value, 'code', item)}
                      variant="outlined"
                    />
                  </FormControl>
                </div>
                <div id={`exclusive_promotion_card_content_promo_code_item_number_${item.id}`} className="col-5">
                  <TextField
                    margin="normal"
                    id="number"
                    label="Number of redemption"
                    placeholder="∞"
                    type="number"
                    value={item.number}
                    onChange={e => changePromoCode(e.target.value, 'number', item)}
                    variant="outlined"
                  />
                </div>
                <div  id={`exclusive_promotion_card_content_promo_code_item_icon_${item.id}`} className="col-2">
                  <IconButton  id={`exclusive_promotion_card_content_promo_code_icon_button_${item.id}`} onClick={() => removePromoCode(item.id)}>
                    <DeleteForever/>
                  </IconButton>
                </div>
              </div>
            ))}

            <br/>
            <div  id={`exclusive_promotion_card_content_promo_code_item_button`} className="d-flex">
              <Button
                 id={`exclusive_promotion_add_promo_code_button`}
                startIcon={<Add/>}
                size="medium"
                variant="contained"
                color="primary"
                disableElevation
                className={styles.addBtn}
                onClick={addPromoCode}
              >
                Add Another Promo Code
              </Button>
            </div>
          </div>
        </CardContent>
      ) : null}
    </>
  );
}

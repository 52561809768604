import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { topProductActionTypes } from "../actions/topProduct.action";
import { RESET_STATE } from "../actions";

const initialTopProductState = {
  loading: false,
  error: "",
  data: [],
};

export const reducer = persistReducer(
  {
    storage,
    key: "yoke-topProducts",
    whitelist: ["topProducts"],
  },
  (state = initialTopProductState, action) => {
    switch (action.type) {
      case topProductActionTypes.GetTopProduct: {
        return {
          ...state,
          loading: true,
        };
      }

      case topProductActionTypes.StoreTopProducts: {
        return {
          ...state,
          loading: false,
          data: [...action.payload],
        };
      }

      case topProductActionTypes.ClearTopProducts: {
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      }
      case RESET_STATE: {
        return initialTopProductState;
      }
      default: {
        return state;
      }
    }
  }
);

import React, { useState, useEffect } from 'react';
import to from 'await-to-js';
import { Card, CardContent, Divider, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import * as Sentry from "@sentry/react";

import { uploadImageToAws } from 'app/common/uploadImageToAws';
import { accessPermission } from 'app/common/helperFunction';
import ResponseModal from 'app/components/Helpers/ResponseModal';
import { useCustomResponse } from 'app/hooks/useCustomResponse';

import Barcodes from './components/Barcodes/Barcodes';
import Images from './components/Images/Images';
import Inventory from './components/Inventory/Inventory';
import useCustomerHeader from '../../../../../../hooks/useCustomHeader';
import { getLocationsProductById, createProductImage } from '../../../../../../services/products';

import styles from '_metronic/_assets/sass/loading-text.module.scss';
import styles1 from './product-details.module.scss';

export default function ProductDetails(props) {
  const {push} = useHistory();
  const {
    filter: { locations }
  } = props;
  const [product, setProduct] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);
  const [img, setImg] = useState("")
  const { id } = useCustomerHeader({
    staticHeader: 'Product details'
  });
  const [responseValue, setResponseValue] = useCustomResponse()

  useEffect(() => {
    if(!accessPermission("ProductsAll")){
      push({pathname: "/dashboard"});
    }

    const arrowBack = document.getElementById("subheader-back-button");
    arrowBack.style.display = 'block';
    if (locations && locations.length)
      getProductDetails();
  }, []);

  /**
   * Get and validate product details
   */
  async function getProductDetails() {
    const [err, res] = await to(getLocationsProductById(id));

    setIsLoaded(true);
    if (err){
      Sentry.captureException(err);
      setResponseValue({...responseValue, isMessageOpen: true, isSuccess:false, message:`Error while getting product details. ${err}`});
      return console.error('Error on fetching product details: ', err);
    }
    setProduct(res.data.data);
  }

  /**
   * Add barcode to a product
   */
  function addBarcode(newBarcode) {
    const updatedProduct = {
      ...product,
      barcodes: [...product.barcodes, newBarcode]
    };
    setProduct(updatedProduct);
  }

  /**
   * Remove selected image
   */
  function removeImage(id) {
    const updatedImages = product.images.filter(item => item.id !== id);
    const newProduct = { ...product, images: updatedImages };
    setProduct(newProduct);
  }

  /**
   * Add image to product
   * @param {file} newImage
   */
  async function addImage(newImage) {
    const [error, createImageRes] = await to(createProductImage(product.operatorProductId));
    const [err1, res] = await to(uploadImageToAws(newImage, createImageRes.data.data.fileName))
    if (res) {
      getProductDetails();
    }
    setImg(res)
  }

  return (
    <>
      <Card id={`product_details_outer_wrapper`} >
      {
        !isLoaded && <div className={styles.loadingContainer}>
          <p className ={styles.loadingText}>
            Loading...
          </p>
        </div>
      }
        <CardContent id={`product_details_content_wrapper_0`} className="d-flex justify-content-between">
          <div id={`product_details_content_inner_wrapper_0`}>
            <Typography id={`product_details_content_name_label`} variant="body1">Name</Typography>
            <Typography variant="h6" id={`product_details_content_name_value`}>{product?.name}</Typography>
          </div>
          <div id={`product_details_content_inner_wrapper_1`}>
            <Typography id={`product_details_content_brand_label`} variant="body1">Brand</Typography>
            <Typography id={`product_details_content_brand_value`} variant="h6">{product?.product?.brand?.name || '-'}</Typography>
          </div>
          <div id={`product_details_content_inner_wrapper_1`}>
            <Typography id={`product_details_content_catagory_label`} variant="body1">Category</Typography>
            <Typography id={`product_details_content_catagory_value`} variant="h6">{product?.category?.name}</Typography>
          </div>
        </CardContent>
        <CardContent  id={`product_details_content_wrapper_1`}>
          <Typography id={`product_details_content_barcodes_label`} variant="h5">Barcodes</Typography>
          <Divider/>
          <Barcodes
            barcodes={product?.product?.barcodes || []}
            onAdd={newValue => addBarcode(newValue)}
          />
        </CardContent>
        <CardContent  id={`product_details_content_wrapper_2`}>
          <Typography id={`product_details_content_image_label`} variant="h5">Images</Typography>
          <Divider/>
          <Images
            images={product?.product?.images || []}
            onRemoveImage={id => removeImage(id)}
            onAddImage={(newImage) => addImage(newImage)}
          />
        </CardContent>
        <CardContent  id={`product_details_content_wrapper_3`}>
          <Typography id={`product_details_content_inventory_label`} variant="h5">Inventory</Typography>
          <Divider/>
          <Inventory inventory={product.stocks}/>
        </CardContent>
        <CardContent  id={`product_details_content_wrapper_4`}>
          <Typography id={`product_details_content_price_label`} variant="h5">Price</Typography>
          <Divider/>
          <CardContent className="d-flex justify-content-between">
            <div>
              <Typography id={`product_details_content_supply_price_label`} variant="body1">Supply Price</Typography>
              <Typography id={`product_details_content_supply_price_value`} variant="h6">
                $
                {parseFloat(product.cost).toFixed(2)}
              </Typography>
            </div>
            <div>
              <Typography id={`product_details_content_product_price_label`} variant="body1">Retails Price (ex Tax)</Typography>
              <Typography id={`product_details_content_product_price_value`} variant="h6">
                $
                {parseFloat(product.price).toFixed(2)}
              </Typography>
            </div>
            <div>
              <Typography id={`product_details_content_markup_label`} variant="body1">Markup</Typography>
              <Typography id={`product_details_content__markup_value`} variant="h6">
                {((((product.price)-(product.cost))/(product.cost)) * 100).toFixed(2)  || '-'}
                %
              </Typography>
            </div>
          </CardContent>
        </CardContent>
      </Card>
      <ResponseModal
      isSuccess={responseValue?.isSuccess}
      message={responseValue?.message}
      open={responseValue?.isMessageOpen}
      onClose={value => setResponseValue({...responseValue, isMessageOpen: value})}
      />
    </>
  );
}

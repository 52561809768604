import React, { useState, useEffect, cloneElement } from 'react';
import { connect, useSelector } from 'react-redux';
import objectPath from 'object-path';
import { withRouter, useLocation } from 'react-router-dom';
import queryString from 'query-string';

import Header from './header/Header';
import SubHeader from './sub-header/SubHeader';
import HeaderMobile from './header/HeaderMobile';
import AsideLeft from './aside/AsideLeft';
import Footer from './footer/Footer';
import ScrollTop from '../../app/partials/layout/ScrollTop';
import HTMLClassService from './HTMLClassService';
import LayoutConfig from './LayoutConfig';
import getMenuConfig, { getLoyaltyProgram } from './MenuConfig';
import LayoutInitializer from './LayoutInitializer';
import KtContent from './KtContent';
import { LayoutSplashScreen } from '../index';
import * as utils from '../../_metronic/utils/utils';

import './assets/Base.scss';

const htmlClassService = new HTMLClassService();

function Layout(props) {
  const loyaltyProgram = useSelector(getLoyaltyProgram);

  const [filter, setFilter] = useState({});
  const [menuConfig, setMenuConfig] = useState(getMenuConfig(loyaltyProgram));
  const [isSearchReady, setSearchReady] = useState(false);
  const [cashedPath, setCashedPath] = useState('');
  const { pathname, search } = useLocation();
  const {
    children,
    asideDisplay,
    subheaderDisplay,
    selfLayout,
    layoutConfig,
    isAuthorized
  } = props;

  useEffect(() => {
    setMenuConfig(getMenuConfig(loyaltyProgram))
  }, [isAuthorized, search, loyaltyProgram])

  /**
   * This effect keeps us from unnecessary displaying components tree
   * if we have not prepared or parsed the query string from url and filters
   */
  useEffect(() => {
    const parsedSearch = queryString.parse(search);

    if (cashedPath === pathname || !parsedSearch.location)
      return;

    if (!parsedSearch.location || !parsedSearch.operator) {
      setSearchReady(false);
      return;
    }

    setSearchReady(true);
    setCashedPath(pathname);
  }, [pathname, search]);

  htmlClassService.setConfig(layoutConfig);
  window.scrollTo(0, 0);

  const contentCssClasses = htmlClassService.classes.content.join(' ');

  const localStorageLastLocationKey = 'demo1-lastLocation';

  const localStorageLocations = utils.getStorage(localStorageLastLocationKey);
  const _useLocations = localStorageLocations
    ? JSON.parse(localStorageLocations)
    : [];
  

  return selfLayout !== 'blank' ? (
    <LayoutInitializer
      styles={[]}
      menuConfig={menuConfig}
      layoutConfig={LayoutConfig}
      htmlClassService={htmlClassService}
    >
      <HeaderMobile/>
      <div className="kt-grid kt-grid--hor kt-grid--root">
        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
          {asideDisplay && (
            <>
              <AsideLeft/>
            </>
          )}
          <div
            className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper"
            id="kt_wrapper"
          >
            <Header/>
            <div
              id="kt_content"
              className={`kt-content ${contentCssClasses} kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor`}
              style={{height: 'calc(100% -60px'}}
            >

              {subheaderDisplay ? (
                <SubHeader
                  cashedPath={_useLocations[_useLocations.length - 2]}
                  onChange={setFilter}
                />
              ) : null}

              {isSearchReady && cashedPath === pathname && filter.locations?.length ? (
                <KtContent>
                  {cloneElement(children, { filter })}
                </KtContent>
              ) : (
                <KtContent>
                  <LayoutSplashScreen/>
                </KtContent>
              )}
            </div>
            <Footer/>
          </div>
        </div>
      </div>
      <ScrollTop/>
    </LayoutInitializer>
  ) : (
    <div className="kt-grid kt-grid--ver kt-grid--root kt-page">
      <KtContent>{children}</KtContent>
    </div>
  );
}

const mapStateToProps = ({ builder: { layoutConfig }, auth }) => ({
  layoutConfig,
  selfLayout: objectPath.get(layoutConfig, 'self.layout'),
  asideDisplay: objectPath.get(layoutConfig, 'aside.self.display'),
  subheaderDisplay: objectPath.get(layoutConfig, 'subheader.display'),
  isAuthorized: auth?.user != null
});

export default withRouter(connect(mapStateToProps)(Layout));

import React, { useEffect, useState } from 'react';
import to from 'await-to-js';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import csvDownload from 'json-to-csv-export';
import 'jspdf-autotable';
import { jsPDF } from "jspdf";
import { Button } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import clsx from 'clsx';
import * as Sentry from "@sentry/react";

import ResponseModal from 'app/components/Helpers/ResponseModal';
import { accessPermission } from 'app/common/helperFunction';
import LoadingSpinner from 'app/partials/content/LoadingSpinner';
import { getComparator, stableSort } from 'app/services/table';
import { CANCEL_TOKEN } from "app/common/constant";

import ExportAndCustomFilters from '../../../partials/content/ExportAndCustomFilters';
import Search from '../../../partials/content/Customers/Search';
import OperatorsTable from './components/OperatorsTable/OperatorsTable';
import { getOperatorV2 } from '../../../services/account';

import styles from './operators.module.scss';
import { useCustomResponse } from 'app/hooks/useCustomResponse';
import { reportFormats } from 'app/services/reports';
import { getStartDateAndEndDateInISOFormat } from 'app/services/helpers/getDefaultParameters';

export default function Operators() {
  const { push } = useHistory();
  const [searchQuery, setSearchQuery] = useState('');
  const [operators, setOperators] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoaded, setIsLoaded] = useState(true);
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
  });
  const [responseValue, setResponseValue] = useCustomResponse()

  const order = "asc";
  const orderBy = "id";

  /**
   * Get all operators
   */
  useEffect(() => {
    if(!accessPermission()){
      push({pathname: "/dashboard"});
    }
  }, []);

  useEffect(() =>{
    getOperatorsList();
  }, [pagination, searchQuery]);

  /**
   * Get all operators
   * @returns {Promise<void>}
   */
  async function getOperatorsList() {
  const  { startDate, endDate } = getStartDateAndEndDateInISOFormat()

    const [err, res] = await to(getOperatorV2(pagination, searchQuery,startDate, endDate));
    setIsLoaded(true);

    if (err){
      if(err.message === CANCEL_TOKEN)return;
      Sentry.captureException(err);
      setResponseValue({...responseValue, isMessageOpen: true, isSuccess:false,message:`Error while getting operator list. ${err}`});    
      return console.error('Error while fetching operators', err);
    }

    const {rows , count} = res.data.account;

    const sortedOperators = stableSort(rows, getComparator(order, orderBy))

    setOperators(sortedOperators);
    setTotalCount(count);
  }

  async function handleExport(type) {

    // check there is data to download or not
    if(operators.length === 0) return;

    if(type === 'pdf'){

      const exportRows = operators.map(ele => [
        ele.name? ele.name: '',
        ele.id? ele.id: '' ,
        ele.firstName && ele.lastName ? `${ele.firstName} ${ele.lastName}` : 'N/A',
        ele.provider?.name ? ele.provider.name: 'N/A' ,
        ele.processor?.name ? ele.processor.name: 'N/A' ,
        ele.notes? ele.notes: '-',
       ]);

        const unit = "pt";
        const size = "A4";
        const orientation = "portrait";
        const marginLeft = 30;
        const doc = new jsPDF(orientation,unit, size);
        doc.setFontSize(15);

        const title = "Operators";
        const headers = [['Operator Name', 'Account ID', 'Contact User', 'Inventory System', 'Credit Card Processor', 'Notes']];

        let content = {
          startY: 50,
          head: headers,
          body: exportRows,
          styles: {overflow: 'linebreak',
                fontSize: 8},
        };

        doc.text(title, marginLeft, 30);
        doc.autoTable(content);
        doc.save(`Operators - ${format(new Date(), 'MM/dd/yyyy')}.${type}`);

    } else if (type === 'csv') {

        const exportRows = [];
        operators.forEach(ele => {
          let obj = {
            'Operator Name' : ele.name? ele.name: '',
            'Account ID' : ele.id? ele.id: "",
            'Contact User': ele.firstName && ele.lastName ? `${ele.firstName} ${ele.lastName}` : 'N/A' ,
            'Inventory System': ele.provider?.name ? ele.provider.name: 'N/A',
            'Credit Card Processor': ele.processor?.name ? ele.processor.name: 'N/A' ,
            'Notes': ele.notes? ele.notes: '-',
          }
          exportRows.push(obj);
        })

      csvDownload(exportRows, `Operators - ${format(new Date(), 'MM/dd/yyyy')}.${type}`);
    }
  }

  return (
    <>
      <ExportAndCustomFilters onExport={handleExport} newFilterTag={reportFormats.PDF}/>
      <div id={`operator_search_wrpper`} className={styles.wrapper}>
        <Search
          id="operator"
          label="Search"
          placeholder="Search"
          query={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>
      <div id={`operator_button_wrpper`} className={clsx('d-flex justify-content-end', styles.btn)}>
        <Button
          id={`operator_add_button`}
          startIcon={<Add/>}
          size="large"
          variant="contained"
          color="primary"
          disableElevation
          onClick={() => push({ pathname: '/operators/new' })}
        >
          Add
        </Button>
      </div>
      {!isLoaded ? (
        <LoadingSpinner/>
      ) : (
        <>
          <OperatorsTable
            rows={operators}
            totalCount={totalCount}
            onEditRowClick={({ id }) => push({ pathname: `/operators/${id}` })}
            onPaginationChange={(value) => setPagination(value)}
          />
          <ResponseModal
            isSuccess={responseValue?.isSuccess}
            message={responseValue?.message}
            open={responseValue?.isMessageOpen}
            onClose={(value) => setResponseValue({...responseValue, isMessageOpen:value})}
          />
        </>
      )}
    </>
  );
}

import React, { useState, useEffect } from "react";
import to from "await-to-js";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import * as Sentry from "@sentry/react";

import ExportAndCustomFilters from "../../../../partials/content/ExportAndCustomFilters";
import LedgerTable from "../LedgerTable/LedgerTable";
import { getOrderHistory } from "../../../../services/reports";
import * as fromActions from "../../../../store/actions";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import csvDownload from "json-to-csv-export";
import "jspdf-autotable";
import { jsPDF } from "jspdf";
import { format } from "date-fns";

import styles2 from "_metronic/_assets/sass/loading-text.module.scss";
import ResponseModal from "app/components/Helpers/ResponseModal";
import { iphoneNames, paymentMethods } from "app/common/LedgerConst";
import ResendModal from "app/components/Helpers/ResendModel";
import { getScope } from "app/services/helpers/account-id";
import { useCustomResponse } from "app/hooks/useCustomResponse";

import { receipt } from "../../services/sales";
import styles from "../Sales/sales.module.scss";
import { ROLE } from "app/common/roleConstant";

export function OrderHistory({
  selectSaleOrder,
}) {
  const { push } = useHistory();
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
  });
  const [orderTotal, setOrderTotal] = useState(0);
  const [sales, setSales] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [resendModalOpen, setResendModalOpen] = useState(false);
  const [resendModalOrderId, setResendModalOrderId] = useState("");
  const [resendModalEmailId, setResendModalEmailId] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);
  const [responseValue, setResponseValue] = useCustomResponse();
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    if (getScope() !== "user") {
      push({ pathname: "/dashboard" });
    }
  }, []);

  /**
   * Fetch data on every pagination change and component mounting
   */
  useEffect(() => {
    getOrders();
    // eslint-disable-next-line
  }, [pagination]);

  /**
   * Proceed sales
   * @returns {Promise<void>}
   */

  async function getOrders() {
    setIsLoaded(false);
    setSales([]);

    const [err, res] = await to(
      getOrderHistory(pagination)
    );

    setIsLoaded(true);
    if (err) {
      Sentry.captureException(err);
      setResponseValue({...responseValue, isMessageOpen: true, isSuccess:false, message:`Error while getting sales ${err}`});     
      return console.error("[Sales]. Error while fetching data. ", err);
    }
    const { meta:{totalItems} = {totalItems : 0}, orders } = res.data;
    setOrderTotal(totalItems);
    setSales(orders);
  }

  /**
   * Expand row
   * @param row
   */
  function onExpandRow(row) {
    const updatedSales = sales.map((sale) => {
      if (sale.id === row.id)
        return {
          ...sale,
          isExpanded: !sale.isExpanded,
        };

      return sale;
    });

    setSales(updatedSales);
  }

  function getPaymentMethods(payment) {
    let paymentString = "";
    if(payment)
      payment.forEach((ele, index) => {
        paymentString =
          paymentString + (index > 0 ? " | " : "") + paymentMethods[ele.method];
      });
    return paymentString;
  }

  async function handleResendReceipt(rowId, emailId) {  
    const [err, refundResponse] = await to(receipt(rowId, emailId));
    if (err) {
      if (err.response) {
        setResponseValue({...responseValue, isMessageOpen: true, isSuccess:false, isServerError:false, message:`${err.response.data.message}`});
        return;
      }

      Sentry.captureException(err);
      setModalOpen(true);
      setModalTitle(`Order ${rowId}`);
      setResponseValue({
        ...responseValue,  
        isMessageOpen: true,
        message:`Failed resending receipt Order #${rowId} - ${err.message}`
    });
    } else {
      setModalOpen(true);
      setModalTitle(`Order ${rowId}`);
      setResponseValue({
        ...responseValue,  
       isMessageOpen: true,
       message:`Successfully resent receipt for Order #${rowId} on mail - ${emailId}`,
    });
    }
  }

  function onResendReceipt(rowId, emailId) {
    setResendModalOrderId(rowId);
    setResendModalEmailId(emailId);
    setResendModalOpen(true);
  }

  async function handleExport(type) {
    const [err, res] = await to(
      getOrderHistory({page:1,limit:orderTotal})
    );

    if (err) return console.error("[orderHistory]. Error while fetching data. ", err);

    const rows = res.data.orders;

    if (type === "pdf") {
      const exportRows = rows.map((ele) => [
        format(new Date(ele.creationDate), "MM/dd/yyyy - hh:mm aa"),
        ele.id ? ele.id : "",
        ele.location.name ? ele.location.name : "",
        !ele.user ? "N/A" : `${ele.user.firstName} ${ele.user.lastName}`,
        ele.DeviceTypeStart
          ? `${iphoneNames[ele.DeviceTypeStart] ?? ele.DeviceTypeStart}  `
          : "" + ele.DeviceTypeEnd
          ? iphoneNames[ele.DeviceTypeEnd] ?? ele.DeviceTypeEnd
          : "",
        getPaymentMethods(ele.order.payment),
        `$ ${ele.total}`,
      ]);

      const unit = "pt";
      const size = "A4";
      const orientation = "portrait";
      const marginLeft = 30;
      const doc = new jsPDF(orientation, unit, size);
      doc.setFontSize(15);

      const title = "Sales";
      const headers = [
        [
          "Date",
          "Order No",
          "Location",
          "Customer",
          "Device",
          "Payment Method",
          "Amount",
        ],
      ];

      let content = {
        startY: 50,
        head: headers,
        body: exportRows,
        styles: { overflow: "linebreak", fontSize: 8 },
      };

      doc.text(title, marginLeft, 30);
      doc.autoTable(content);
      doc.save(`Order history - ${format(new Date(),"MM/dd/yyyy")}.${type}`);
    } else if (type === "csv") {
      const exportRows = [];
      rows.forEach((ele) => {
        let obj = {
          Date: format(new Date(ele.creationDate), "MM/dd/yyyy - hh:mm aa"),
          "Order No": ele.id ? ele.id : "",
          Location: ele.location.name ? ele.location.name : "",
          Customer: !ele.user
            ? "N/A"
            : `${ele.user.firstName} ${ele.user.lastName}`,
          Device: ele.DeviceTypeStart
            ? `${iphoneNames[ele.DeviceTypeStart] ?? ele.DeviceTypeStart}  `
            : "" + ele.DeviceTypeEnd
            ? iphoneNames[ele.DeviceTypeEnd] ?? ele.DeviceTypeEnd
            : "",
          "Payment Method": getPaymentMethods(ele.order.payment),
          Amount: `$ ${ele.total}`,
        };
        exportRows.push(obj);
      });

      csvDownload(exportRows, `Order history - ${format(new Date(),"MM/dd/yyyy")}.${type}`);
    }
  }

  /**
   * Set store property on edit item click
   */
  function handleRowEditClick(row) {
    const { id } = row;
    let type = "ledgers";

    selectSaleOrder({ id, type });
    push({ pathname: `/sales/${row.id}` });
  }

  /**
   * Update pagination data on table pagination component change
   */
  function onPaginationChange(newPagination) {
    setPagination(newPagination);
  }

  /**
     * Mark row as selected (checkbox)
     * @param id
     */
  function selectRow(id) {
    if (selectedRows.includes(id))
      setSelectedRows(selectedRows.filter(item => item !== id));
    else
      setSelectedRows([...selectedRows, id]);
  }

  /**
   * Select all rows
   */
  function masterToggle() {
    if (selectedRows.length)
      setSelectedRows([]);
    else
      setSelectedRows(sales.map(({ id }) => id));
  }

  return (
    <>
      <ExportAndCustomFilters onExport={handleExport} />

      {!isLoaded && (
        <div id="order_history_loading_wrapper" className={styles2.loadingContainer}>
          <p  id="order_history_loading_content" className={styles2.loadingText}>Loading...</p>
        </div>
      )}

      <div  id="order_history_ledger_table_wrapper" className={styles.wrapper}>
        <LedgerTable
          isLoads={true}
          isOrderHistory={true}
          onResendReceipt={onResendReceipt}
          onExpandRow={onExpandRow}
          onPaginationChange={onPaginationChange}
          onEditRowClick={handleRowEditClick}
          onMasterToggle={() => masterToggle()}
          onSelectRow={id => selectRow(id)}
          selectedRows={selectedRows}
          rows={sales}
          pagination={pagination}
          salesTotal={orderTotal}
          hideColumns = {getScope() !== ROLE.SUPERADMIN ? ['customer','orderNumber']:['customer']}
        />
      </div>

      {isModalOpen ? (
        <Dialog
        id="order_history_dialog_modal_wrapper"
          open
          onClose={() => setModalOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{modalTitle}</DialogTitle>
          <DialogContent  id="order_history_dialog_content_wrapper" className="d-flex flex-column">
            {responseValue?.message}
          </DialogContent>
          <DialogActions  id="order_history_dialog_action_wrapper">
            <Button  id="order_history_dialog_close_action_button" onClick={() => setModalOpen(false)} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
      <ResponseModal
        isSuccess={responseValue?.isSuccess}
        isServerError={responseValue?.isServerError}
        message={responseValue?.message}
        open={responseValue?.isMessageOpen}
        onClose={(value) => setResponseValue({...responseValue, isMessageOpen:value})}
      />
      {Boolean(resendModalOpen) && (
        <ResendModal
          open={resendModalOpen}
          onClose={() => setResendModalOpen(false)}
          handleResendReceipt={handleResendReceipt}
          rowId={resendModalOrderId}
          emailId={resendModalEmailId}
        />
      )}
    </>
  );
}

export default connect(() => ({}), {
  storeLedgers: fromActions.ledgerActions.storeLedgers,
  selectSaleOrder: fromActions.ledgerActions.selectSaleOrder,
})(OrderHistory);

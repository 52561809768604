import axios from 'axios';
import { getDefaultParameters_V2 } from './helpers/getDefaultParameters';
import axiosInstance from './helpers/axiosInstance';

/**
 * Get list of roles for current account
 */
export function getRolesForAccount(accountId) {
  return axios.get(`/accounts/${accountId}/roles`);
}

/**
 * Create new role
 */
export function createNewRole(groupId, data) {
  return axios.post(`/groups/${groupId}/roles`, data);
}

/**
 * Get selected role details
 */
export function getSelectedRole(accountId, roleId) {
  return axios.get(`/accounts/${accountId}/roles/${roleId}`+getDefaultParameters_V2(true));
}

/**
 * Update existing role
 */
export function updateSelectedRole(accountId, roleId, data) {
  // return axios.patch(`/roles/${roleId}`, data);
  return axios.post(`/roles/${roleId}`, data);
}

/**
 * Assign user an existing role
 */
 export function assignUserRole(userId, roleId) {
  return axios.post(`/users/${userId}/roles/${roleId}`);
}


/**
 * Assign user an existing role
 */
 export function removeUserFromRole(userId, roleId) {
  return axiosInstance.delete(`/users/${userId}/roles/${roleId}`);
}
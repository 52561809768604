import React, { useState } from 'react';
import clsx from 'clsx';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle
} from '@material-ui/core';
import { HighlightOff } from '@material-ui/icons';

import styles from '../../tabs.module.scss';

export default function CloseTab(props) {
  const [isModalOpen, setModalOpen] = useState(false);
  const {
    selectedCustomers,
    onUpdate
  } = props;

  /**
   * Handle onClose click
   */
  function onClose() {
    setModalOpen(false);
    onUpdate(true, selectedCustomers);
  }

  /**
   * Handle onZeroOut click
   */
  function onZeroOut() {
    setModalOpen(false);
    onUpdate(false, selectedCustomers);
  }

  return (
    <>
      <div id="close_tab_container" className={clsx(styles.footerWrapper, 'd-flex', 'justify-content-end')}>
        <Button
          id="close_tab_primary_button"
          startIcon={<HighlightOff/>}
          size="large"
          variant="contained"
          color="primary"
          disableElevation
          disabled={!selectedCustomers.length}
          onClick={() => setModalOpen(true)}
        >
          Close Tabs
        </Button>
      </div>

      {isModalOpen ? (
        <Dialog
          id="close_tab_alert_dialog_container"
          open
          onClose={() => setModalOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="close_tab_alert_dialog_title" style={{ textAlign: 'center' }}>
            Close Tabs
          </DialogTitle>
          <DialogActions id="close_tab_action_container" className="d-flex justify-content-between">
            <Button
              id="close_tab_close_action_button"
              size="medium"
              variant="contained"
              color="primary"
              disableElevation
              onClick={onClose}
            >
              Close
            </Button>

            <Button
              id="close_tab_zero_out_action_button"
              size="medium"
              variant="contained"
              color="primary"
              disableElevation
              onClick={onZeroOut}
            >
              Zero Out
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
    </>
  );
}

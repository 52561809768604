import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  TextField
} from '@material-ui/core';

export default function ResendModal(props) {
  const {
    open,
    onClose,
    handleResendReceipt,
    row,
  } = props;
  const [receiverEmail, setReceiverEmail] = useState(row.user?.email || "");
 
  function handleResendButton() {
    onClose();
    handleResendReceipt(row, receiverEmail);
  }

  return (
    <>
      <Dialog
        id={`resent_modal_dialog_wrapper`}
        open={open}
        onClose={onClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <DialogTitle id="simple-modal-title">{"Resend Receipt"}</DialogTitle>
        <DialogContent id="simple-modal-description" >
          <Box
            sx={{
              width: 500,
              maxWidth: "100%",
              height: 55,
            }}
          >
            <TextField
              fullWidth
              label="email"
              placeholder="email"
              id="email"
              variant="outlined"
              value={receiverEmail}
              onChange={(e) => setReceiverEmail(e.target.value)}
            />
          </Box>
        </DialogContent>
        <DialogActions id={`resent_modal_dialog_action_wrapper`}>
          <Button
            id={`resent_modal_dialog_cancel_action_button`}
            variant="contained"
            size="large"
            color="primary"
            onClick={() => onClose(false)}
          >
            Cancel
          </Button>
          <Button 
            id={`resent_modal_dialog_resend_action_button`}
            variant="contained" 
            size="large" 
            color="primary"
            onClick={() => handleResendButton()}
          >
            Resend
          </Button>
        </DialogActions>
      </Dialog>
     </> 
);
}

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
  Checkbox,
  Button,
  makeStyles
} from '@material-ui/core';
import { EditSharp, Archive } from '@material-ui/icons';

import { stableSort, getDefaultStyles, getComparator } from '../../../services/table';

const headCells = [
  { id: 'email', numeric: false, disablePadding: true, label: 'Email' },
  { id: 'firstName', numeric: false, disablePadding: false, label: 'First Name' },
  { id: 'lastName', numeric: false, disablePadding: false, label: 'Last Name' },
  { id: 'balance', numeric: false, disablePadding: false, label: 'Balance' },
  { id: 'limit', numeric: false, disablePadding: false, label: 'Limit' },
  { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
  { id: 'actions', numeric: false, disablePadding: false, label: 'Actions' }
];

function EnhancedTableHead(props) {
  const {
    numSelected,
    rowCount,
    onSelectAllClick
  } = props;

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired
};

const useStyles = makeStyles(getDefaultStyles);

export default function TabsTable(props) {
  const classes = useStyles();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('id');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const {
    rows,
    resetSelected,
    onEditRowClick,
    onArchiveRowClick,
    onSelectCustomers
  } = props;

  /**
   * Reset selected customers after updating their group
   */
  useEffect(() => {
    if (!resetSelected)
      return;
    setSelected([]);
    onSelectCustomers([]);
  }, [resetSelected]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n.email);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, email) => {
    const selectedIndex = selected.indexOf(email);
    let newSelected = [];

    if (selectedIndex === -1)
      newSelected = newSelected.concat(selected, email);
    else if (selectedIndex === 0)
      newSelected = newSelected.concat(selected.slice(1));
    else if (selectedIndex === selected.length - 1)
      newSelected = newSelected.concat(selected.slice(0, -1));
    else if (selectedIndex > 0)
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );

    setSelected(newSelected);
    console.log((rows.filter(c => newSelected.includes(c.email))))
    onSelectCustomers(rows.filter(c => newSelected.includes(c.email)));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    const newSize = parseInt(event.target.value, 10);
    const newP = parseInt((page * rowsPerPage + 1) / newSize) ;
    setRowsPerPage(newSize);
    setPage(newP);
  };

  const isSelected = email => selected.indexOf(email) !== -1;

  /**
   * Handle click on Edit icon
   * @param e
   * @param row
   */
  function onEditIconClick(e, row) {
    e.stopPropagation();

    onEditRowClick(row);
  }

  /**
   * Handle click on Archive icon
   * @param e
   * @param row
   */
  function onArchiveIconClick(e, row) {
    e.stopPropagation();

    onArchiveRowClick(row);
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="small"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const { email, firstName, lastName, tab: {limit: tabLimit, balance: tabBalance, status: tabStatus}} = row;
                  const isItemSelected = isSelected(email);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={event => handleClick(event, email)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={email}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </TableCell>
                      <TableCell align="left" padding="none" scope="row">
                        {email}
                      </TableCell>
                      <TableCell align="left" padding="normal">{firstName}</TableCell>
                      <TableCell align="left" padding="normal">{lastName}</TableCell>
                      <TableCell align="left" padding="normal">{tabBalance}</TableCell>
                      <TableCell align="left" padding="normal">
                        {tabLimit === 0 ? 'None' : `$${tabLimit}`}
                      </TableCell>
                      <TableCell align="left" padding="normal">{tabStatus == 0 ? "Good" : tabStatus == 1 ? "Approaching Limit": "Delinquent"}</TableCell>
                      <TableCell align="center" padding="normal" style={{ width: 250 }}>
                        <Button
                          type="button"
                          size="small"
                          variant="outlined"
                          color="primary"
                          disableElevation
                          style={{ marginRight: 4 }}
                          onClick={e => onEditIconClick(e, row)}
                        >
                          <EditSharp/>
                          &nbsp;
                          Edit Limit
                        </Button>

                        <Button
                          type="button"
                          size="small"
                          variant="outlined"
                          color="primary"
                          disableElevation
                          onClick={e => onArchiveIconClick(e, row)}
                        >
                          <Archive/>
                          &nbsp;
                          Archive
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}

import React, { useState } from 'react';
import {
  Button,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow
} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import FileDownload from 'js-file-download';
import * as Sentry from '@sentry/react';

import StockRowExpanded from './components/StockRowExpanded/StockRowExpanded';
import StockModal from './components/StockModal/StockModal';
import { getInventoryPrekitsReport } from '../../../../../../services/products';
import { getDefaultStyles } from '../../../../../../services/table';

const headCells = [
  { id: 'name', numeric: false, disablePadding: false, label: 'Location Name' },
  { id: 'actions', numeric: false, disablePadding: false, label: 'Actions' }
];

const useStyles = makeStyles(getDefaultStyles);

function EnhancedTableHead() {
  return (
    <TableHead id={`stock_control_table_header`}>
      <TableRow id={`stock_control_table_header_row`}>
        <TableCell padding="checkbox" style={{ width: 45 }}/>
        {headCells.map(headCell => (
          <TableCell
            id={`stock_control_table_header_cell_${headCell.id}`}
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function StockControlTable(props) {
  const {
    rows,
    pagination,
    totalStocks,
    onExpandRow,
    onPaginationChange,
    onPrekit
  } = props;
  const [open, setOpen] = useState(false);
  const [modalData, setModalData] = useState({ id: null, count: null });
  const [page, setPage] = useState(pagination.page - 1);
  const [rowsPerPage, setRowsPerPage] = useState(pagination.limit);
  const classes = useStyles();

  /**
   * Handle modal event for open
   */
  function handleOpen({ id, count }) {
    setOpen(true);
    setModalData({ id, count });
  }

  /**
   * Handle close event on modal finished
   */
  function handleClose(isDownload, exportType) {
    if (!isDownload) {
      setOpen(false);
      setModalData({ id: null, count: null });
      return;
    }

    getInventoryPrekitsReport(modalData.id, exportType)
      .then(res => {       
        FileDownload(res.data, `PreKit - ${new Date}.${exportType}`);

      })
      .catch(err => {
        Sentry.captureException(err);       
        console.error('Issues with report fetching: ', err);
      });
  }

  /**
   * Handle pagination change
   * @param {Synthetic Event} param0
   * @param {number} newPage
   */
  function handlePaginationChange({ target: { value } }, newPage = -1) {
    if (value) {
      const newSize = parseInt(value, 10);
      const newP = parseInt((page * rowsPerPage + 1) / newSize) ;

      onPaginationChange({
        page: newP + 1,
        limit: newSize
      });
      setPage(newP);
      setRowsPerPage(newSize);
    }

    if (newPage >= 0 && typeof newPage !== 'object') {
      onPaginationChange({
        page: newPage + 1,
        limit: rowsPerPage
      });
      setPage(newPage);
    }
  }

  return (
    <>
      <div id={`stock_control_table_root_wrapper`} className={classes.root}>
        <Paper id={`stock_control_table_paper_wrapper`} className={classes.paper}>
          <TableContainer id={`stock_control_table_container_wrapper`}>
            <Table
              id={`stock_control_table_inner_wrapper`}
              className={classes.table}
              aria-labelledby="tableTitle"
              size="small"
              aria-label="enhanced table"
            >
              <EnhancedTableHead/>
              <TableBody id={`stock_control_table_body_wrapper`}>
                {rows
                  .map(row => {
                    const {
                      id,
                      name,
                      prekits,
                      isExpanded
                    } = row;

                    return (
                      <React.Fragment key={id}>
                        <TableRow
                          id={`stock_control_table_row_wrapper_${id}`}
                          hover
                          tabIndex={-1}
                          selected={isExpanded}
                        >
                          <TableCell id={`stock_control_table_icon_cell_wrapper_${id}`} align="left" padding="checkbox">
                            <IconButton onClick={() => onExpandRow(id)}>
                              {isExpanded ? <ExpandLess/> : <ExpandMore/>}
                            </IconButton>
                          </TableCell>
                          <TableCell id={`stock_control_table_name_wrapper_${id}`} align="left" padding="normal">
                            {name}
                          </TableCell>
                          <TableCell id={`stock_control_table_button_wrapper_${id}`} align="left" padding="normal">
                            <Button
                              id={`stock_control_pre_kit_button_${id}`}
                              type="button"
                              color="primary"
                              size="medium"
                              variant="contained"
                              disableElevation
                              onClick={() => onPrekit(id)}
                            >
                              Pre Kit
                            </Button>
                          </TableCell>
                        </TableRow>
                        {isExpanded ? (
                          <StockRowExpanded
                            prekits={prekits}
                            onDownload={data => handleOpen(data)}
                          />
                        ) : null}
                      </React.Fragment>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            id={`stock_control_table_pagination`}
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={totalStocks}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handlePaginationChange}
            onRowsPerPageChange={handlePaginationChange}
          />
        </Paper>
      </div>
      <StockModal
        open={open}
        modalData={modalData}
        onClose={handleClose}
      />
    </>
  );
}

import React, { useState, useCallback, useEffect } from 'react';
import {
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
  CardContent,
  Typography,
  Divider,
  TextField,
  IconButton,
  Button,
} from '@material-ui/core';
import {
  Add, 
  DeleteForever,
} from '@material-ui/icons';
import to from "await-to-js";
import * as Sentry from "@sentry/react";

import Filter from 'app/partials/content/Filter';
import { getLoggedUserAccountId2 } from 'app/services/helpers/account-id';
import { getUserGroups } from 'app/services/groups';

import styles from './exclusive-audience.module.scss';


const availableTypes = [
  {
    id: 1,
    name: 'Limit to a Customer Group',
    description: 'Only customers from chosen group will receive a promotion.',
    value: 'limit'
  },
  {
    id: 2,
    name: 'Apply a Promo Code',
    description: 'Only customers with a promo code can redeem this promotion.',
    value: 'promo-code'
  }
];

export default function ExclusiveAudience(props) {
  const {
    userGroupId = '',
    promoCodes = '',
    handleChange,
    isNew
  } = props
  const [exclusiveType, setExclusiveType] = useState(promoCodes ? 'promo-code' : 'limit');
  const [promoCodeCount, setPromoCodeCount] = useState(promoCodes? 1 : 0)
  const [filters, setFilters] = useState([]);
  const [selectedGroupsFilter, setSelectedGroupsFilter] = useState(userGroupId);

  const addPromoCode = useCallback( () => {
    handleChange('couponCode', '');
    handleChange('redemptions', '');
    setPromoCodeCount(1);
  }, [promoCodes,handleChange]);

  const removePromoCode = useCallback( () => {
    handleChange('couponCode', '');
    handleChange('redemptions', '');
    setPromoCodeCount(0);
  }, [promoCodes,handleChange]);

  useEffect(() => {
    getGroupsForFilters()
  }, [])

  useEffect(() => {
    if (promoCodes) {
      setExclusiveType('promo-code');
      setPromoCodeCount(1);
    }

    if (userGroupId) {
      setSelectedGroupsFilter(userGroupId)
    }
  }, [promoCodes, userGroupId])
  
  async function getGroupsForFilters() {
    const id = getLoggedUserAccountId2();
    const [err, res] = await to(getUserGroups(id, { limit: 100, page: 1 }));
    if (err) {
      Sentry.captureException(err);
      return console.error("[Groups]. Error while fetching data.");
    }
    setFilters([
      { id: 0, name: "All Groups", description: "" },
      ...res?.data?.groups,
    ]);

    if (!selectedGroupsFilter) setSelectedGroupsFilter(0);
  }

  function handleGroupChange(value) {
    handleChange("userGroupId", value);
    setSelectedGroupsFilter(value)
  }

  return (
    <>
      <CardContent id={`dealDetails_redeemLimit_card_content_0`}>
        <FormControl id={`dealDetails_redeemLimit_card_content_form_control`} component="fieldset">
          <RadioGroup
            id={`dealDetails_redeemLimit_radio_group`}
            aria-label="promotion-target"
            name="promotion-target"
            value={exclusiveType}
            onChange={({ target: { value } }) => setExclusiveType(value)}
          >
            {availableTypes.map(({ id, value, name, description }) => (
              <React.Fragment key={id}>
                <FormControlLabel
                  id={`dealDetails_redeemLimit_radio_group_form_label`}
                  value={value}
                  control={<Radio/>}
                  label={name}
                />
                <Typography  id={`dealDetails_redeemLimit_radio_group_typography`} variant="body1">{description}</Typography>
                <br/>
              </React.Fragment>
            ))}
          </RadioGroup>
        </FormControl>
      </CardContent>

      {exclusiveType === 'promo-code' ? (
        <CardContent id={`dealDetails_redeemLimit_card_content_1`} className="d-flex justify-content-center">
          <div id={`dealDetails_redeemLimit_card_content_promo_box`} className={styles.promoCodeBox}>
            <Typography id={`dealDetails_redeemLimit_card_content_promo_box_label`} variant="h4">Add Promo Codes</Typography>
            <br/>
            <Typography id={`dealDetails_redeemLimit_card_content_promo_box_description`} variant="h6">
              These codes can be redeemed as many times as you decide. Use this to reach a
              wide audience. Custom codes cannot be created more than once across all promotions.
            </Typography>
            <br/>
            <br/>
            <div id={`dealDetails_redeemLimit_card_content_custom_promo_box`} className="d-flex">
              <Divider/>
              <div id={`dealDetails_redeemLimit_card_content_custom_promo_box_inner_wrapper`} className="col-5">
                <Typography id={`dealDetails_redeemLimit_card_content_custom_promo_box_label`} variant="h5">Custom Promo Code</Typography>
              </div>
              <div className="col-2"/>
              <Divider/>
            </div>
            {promoCodeCount === 1 && (
              <div id={`dealDetails_redeemLimit_card_content_form_control_wrapper`} className="d-flex align-items-center">
                <div id={`dealDetails_redeemLimit_card_content_form_control_inner_wrapper`} className="col-5">
                  <FormControl id={`dealDetails_redeemLimit_card_content_form_control_custom_code`} fullWidth>
                    <TextField
                      margin="normal"
                      id="dealDetails_exclusiveAudience_code"
                      label="Custom code"
                      placeholder="Enter a custom code, eg. FBAana50%"
                      value={promoCodes}
                      onChange={e => handleChange('couponCode' , e.target.value )}
                      variant="outlined"
                    />
                  </FormControl>
                </div>
                <div id={`dealDetails_redeemLimit_card_content_icon_button_wrapper`} className="col-2">
                  <IconButton id={`dealDetails_redeemLimit_card_content_delete_icon_button`} onClick={() => removePromoCode()}>
                    <DeleteForever/>
                  </IconButton>
                </div>
              </div>
            )}

            <br/>
            {isNew && promoCodeCount < 1 && (<div className="d-flex">
              <Button
                id={`dealDetails_redeemLimit_add_promo_button`}
                startIcon={<Add/>}
                size="medium"
                variant="contained"
                color="primary"
                disableElevation
                className={styles.addBtn}
                onClick={addPromoCode}
              >
                Add Promo Code
              </Button>
            </div>)}
          </div>
        </CardContent>
      ) : (
        <CardContent  id={`dealDetails_redeemLimit_card_content_2`} className="d-flex justify-content-center">
          <div id={`dealDetails_redeemLimit_card_content_promo_box_container`} className={styles.promoCodeBox}>
            <Typography id={`dealDetails_redeemLimit_select_group`} variant="h4">Select Group</Typography>
            <br/>
            <div id={`dealDetails_redeemLimit_filter_wrapper`} style={{width: "250px"}}>
              <Filter
                isFullWidth
                label="Group"
                options={filters}
                idBased={true}
                selectedFilter={selectedGroupsFilter}
                onChange={(e) => handleGroupChange(e.target.value)}
              />
            </div>
          </div>
        </CardContent>
      )}
    </>
  );
}

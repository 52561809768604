import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  DialogTitle
} from '@material-ui/core';
import { DeleteForever } from '@material-ui/icons';

import styles from '../../../../_metronic/_assets/sass/pages/customers.module.scss';

export default function RemoveFromGroup(props) {
  const [isModalOpen, setModalOpen] = useState(false);
  const {
    selectedGroup,
    selectedRows,
    onUpdate
  } = props;

  /**
   * Handle cancel click
   */
  function onCancelClick() {
    setModalOpen(false);
  }

  /**
   * Handle remove from group
   */
  function onDeleteFromGroup() {
    setModalOpen(false);
    onUpdate(selectedGroup);
  }

  return (
    <>
      <Button
        startIcon={<DeleteForever/>}
        className={styles.btn}
        size="large"
        variant="contained"
        color="primary"
        disableElevation
        disabled={!selectedRows.length}
        onClick={() => setModalOpen(true)}
      >
        Remove Selected
      </Button>

      {isModalOpen ? (
        <Dialog
          open
          onClose={onCancelClick}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Remove From Group
          </DialogTitle>
          <DialogContent id="alert-dialog-title">
            Are you sure you want to remove
            &nbsp;
            <b>
              #
              {selectedRows.length}
              &nbsp;
              selected
              &nbsp;
              {selectedRows.length === 1 ? 'row' : 'rows'}
            </b>
            &nbsp;
            from group
            &nbsp;
            <b>{selectedGroup}</b>
            ?
          </DialogContent>
          <DialogActions>
            <Button onClick={onCancelClick} color="primary">
              Cancel
            </Button>
            <Button
              startIcon={<DeleteForever/>}
              onClick={onDeleteFromGroup}
              autoFocus
              variant="contained"
              color="primary"
              disableElevation
            >
              Remove From Group
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
    </>
  );
}

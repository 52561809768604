import React from "react";

import styles from "./platformInsights.module.scss";
import { MARKET_INSIGHTS } from "app/common/constant";
import { platformInsightsObj } from "app/common/dashboardConst";
import { formatNumber } from "app/common/utils/generic";

function InsightItem(props) {
  const { item } = props;
  return (
    <div className={styles.items}>
      <span className={styles.itemHeader}>{item.name}</span>
      <div className={styles.itemData}>
        { item.name === platformInsightsObj[4]?.name ? `${formatNumber(item.value)}`: item.value}
        { item.name === platformInsightsObj[4]?.name && <span className={styles.tooltiptext}>{`$${item.value}`}</span>}
      </div>
    </div>
  );
}

export default function PlatFormInsights(props) {
  const { rows } = props;
  return (
    <>
      <div id="platform_insight_container" className={styles.insight_container}>
        <div id="platform_insight_header" className={styles.insight_header}>{MARKET_INSIGHTS}</div>
        <div id="platform_insight_item_container" className={styles.itemContainer}>
          {rows.map((ele, index) => (
            <InsightItem item={ele} key={index} />
          ))}
        </div>
      </div>
    </>
  );
}

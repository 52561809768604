import { useState, useEffect } from "react";
import { useRouteMatch } from "react-router-dom";

export default function useCustomerHeader(props) {
  const match = useRouteMatch();
  const [id] = useState(match.params.id);
  const [isNew] = useState(id === "new");
  const [relatedItem, setRelatedItem] = useState({});
  const {
    property,
    suffix = "",
    staticHeader = "",
    isCreationAllowed = false,
    createNew = "",
    getRelatedItem = () => {},
    cb = () => {},
  } = props;
  const [subHeader, setSubHeader] = useState(
    document.getElementById("subheader-title")
  );
  const [arrowBack, setSubArrowBack] = useState(
    document.getElementById("subheader-back-button")
  );

  useEffect(() => {
    let tempArrowBack = arrowBack;
    let tempSubHeader = subHeader;

    if (!isNew && !staticHeader) {
      // Show back button
      if(tempArrowBack)
      tempArrowBack.style.display = "block";

      const relatedItem = getRelatedItem(id);

      if (relatedItem) {
        let title = relatedItem[property] ?? "";

        if (suffix) title += ` ${suffix}`;

        // fix the max length 50 chars
        if (title.length > 50) title = title.slice(0, 50) + "...";

        // Manually set subheader
        tempSubHeader.innerText = title;

        setRelatedItem(relatedItem);

        if (typeof cb === "function") cb(relatedItem);
      }
    } else if (isCreationAllowed) {
      // Manually set subheader
      tempSubHeader.innerText = `Create New ${createNew}`;

      // Show back button
      if(tempArrowBack)
      tempArrowBack.style.display = "block";
    } else if (staticHeader) {
      // Manually set subheader
      tempSubHeader.innerText = staticHeader;
    }
    setSubArrowBack(tempArrowBack);
    setSubHeader(tempSubHeader);

    return () => {
      if(tempArrowBack)
      tempArrowBack.style.cssText = "";
    };
    // eslint-disable-next-line
  }, []);

  return {
    id,
    isNew,
    relatedItem,
    subHeader,
    arrowBack,
    setSubArrowBack,
    setSubHeader
  };
}

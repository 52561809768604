import React from "react";
import { Button, Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import to from "await-to-js";
import * as Sentry from "@sentry/react";
import { refund } from "../../components/Ledger/services/sales";

import styles from "./response.module.scss";

const BulkRefundModal = ({
  open,
  onClose,
  selectedTransactionData,
  openResponse,
  setIsRefunded,
  reset,
}) => {
  function handleModalClose() {
    onClose(false);
  }

  async function refundAction() {
    let failedTransaction = [];
    let promises = [];
    let successTransaction = [];
    for (let i = 0; i <= selectedTransactionData?.length; i++) {
      const saleData = selectedTransactionData[i];
      for (let j = 0; j <= saleData?.order?.transactions?.length - 1; j++) {
        const data = saleData?.order?.transactions?.[j];
        try {
          const promise = refund(
            saleData?.id,
            data?.amount,
            data?.transactionId
          )
            .then((response) => {
              return response;
            })
            .then((data) => {
              if (data) {
                successTransaction.push(
                  data?.data?.refundResponse?.transactionId
                );
              }
              return data;
            })
            .catch((error) => {
              failedTransaction.push(
                error?.response?.data?.refundResponse?.transactionId
              );
              Sentry.captureException(error);
            });
          promises.push(promise);
        } catch (error) {
          failedTransaction.push(
            error?.response?.data?.refundResponse?.transactionId
          );
          Sentry.captureException(error);
          console.log(
            "refund failed for transaction id",
            error?.response?.data?.refundResponse?.transactionId
          );
        }
      }
    }
    try {
      const [error, results] = await to(Promise.all(promises));
      console.log("failedTransaction",failedTransaction,"successTransaction",successTransaction);
      if (failedTransaction?.length > 0) {
        handleModalClose();
        Sentry.captureException(error);
        openResponse(
          false,
          true,
          `Refund failed for transaction id ${failedTransaction?.toString()}`
        );
        reset()
        console.error("Error calling API:", error);
      }
      if (successTransaction?.length > 0) {
        // console.log("results",results);
        handleModalClose();
        reset()
        openResponse(
          true,
          false,
          `Refund Successful for transaction id ${successTransaction?.toString()} `
        );
        setIsRefunded(true);
      }
    } catch (error) {
      console.log("error", error);
      Sentry.captureException(error);
      handleModalClose();
      reset()
      openResponse(false, true, `${error} `);
    }
  }

  return (
    <Dialog
      id={`bulk_refund_modal_dialog_wrapper`}
      open={open}
      onClose={handleModalClose}
      aria-labelledby="bulk_refund_modal-title"
      aria-describedby="bulk_refund_modal-description"
    >
      <DialogTitle id="bulk_refund_modals-title">{`Refund Bulk Orders`}</DialogTitle>
      <DialogContent
        id="bulk_refund_modal-description"
        className={styles.wrapper}
      >
        {`Multiple transactions can be applied with respect to all orders if a customer uses two payment methods on checkout with respect to specific orders. Please review the following transactions for all respective order carefully before refunding. Once refunding is initiated it cannot be reversed.`}
      </DialogContent>
      <DialogContent
        id="bulk_refund_modal-actions"
        className={styles.cancelWrapper}
      >
        <div style={{ display: "flex", justifyItems: "space-between" }}>
          <Button
            id={`bulk_refund_modal_dialog_refund_buttons_1`}
            style={{ marginRight: "1rem" }}
            className={styles.buttonWrapper}
            size="large"
            variant="contained"
            color="primary"
            onClick={() => refundAction()}
            disableElevation
          >
            Refund
          </Button>
          <Button
            color="primary"
            size="large"
            variant="contained"
            onClick={handleModalClose}
            id={`bulk_refund_modal_dialog_cancel_buttons`}
          >
            Cancel
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default BulkRefundModal;

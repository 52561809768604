/*eslint-disable*/
let mockedTickets = [
];

/**
 * Get original array
 */
export function getTickets() {
  return mockedTickets;
}

/**
 * Get filtered array
 * @param visibilityFilter
 * @param statusFilter
 */
export function filterTickets(visibilityFilter, statusFilter) {
  let filteredArray = mockedTickets;

  if (visibilityFilter && !statusFilter) {
    filteredArray = filterByVisibility(visibilityFilter, mockedTickets);
  } else if (!visibilityFilter && statusFilter) {
    filteredArray = filterByStatus(statusFilter, mockedTickets);
  } else if (visibilityFilter && statusFilter) {
    filteredArray = filterByVisibility(visibilityFilter, mockedTickets);
    filteredArray = filterByStatus(statusFilter, filteredArray);
  }

  return filteredArray;
}

/**
 * Filter array by visibility
 * @param visibility
 * @param array
 */
function filterByVisibility(visibility, array) {
  if (visibility === 'open')
    return array.filter(ticket => ticket.isOpen);
  else if (visibility === 'closed')
    return array.filter(ticket => !ticket.isOpen);
}

/**
 * Filter array by status
 * @param status
 * @param array
 */
function filterByStatus(status, array) {
  if (status === 'good')
    return array.filter(ticket => ticket.type === 'Good');
  else if (status === 'bad')
    return array.filter(ticket => ticket.type === 'Bad');
  else if (status === 'solved')
    return array.filter(ticket => ticket.type === 'Solved');
}

/**
 * Add new ticket
 * @param ticket
 */
export function addTicket(ticket) {
  mockedTickets = [ticket, ...mockedTickets]
}

/**
 * Update existing ticket
 * @param ticket
 */
export function updateTicket(ticket) {
  mockedTickets = mockedTickets.map(item => {
    if (item.id === ticket.id)
      return ticket;

    return item;
  })
}

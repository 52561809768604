export const topProductActionTypes = {
  GetTopProduct: '[Get Top Products Entities] Action',
  StoreTopProducts: '[Store Top Products Entities] Action',
  ClearTopProducts: '[Clear Top Products Entities] Action',
};

export const topProductActions = {
  getTopProducts: topProducts => ({
    type: topProductActionTypes.GetTopProduct,
    payload: topProducts
  }),
  StoreTopProducts: topProducts => {
    return({
    type: topProductActionTypes.StoreTopProducts,
    payload: topProducts
  })
},
  ClearTopProducts: (topProductsError) => ({
    type: topProductActionTypes.ClearTopProducts,
    payload: topProductsError
  }),
};

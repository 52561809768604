import React, { useEffect } from 'react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography
} from '@material-ui/core';
import {
  AddShoppingCart,
  ShoppingCart,
  Fireplace,
  Print,
  Star
} from '@material-ui/icons';

import styles from './order-equipment.module.scss';

const equipment = [{
  id: 2,
  name: 'Printer',
  currencyIcon: '$',
  price: '119.00',
  icon: <Print className={styles.icon} color="primary"/>
}, {
  id: 3,
  name: 'Fire Extinguisher',
  currencyIcon: '$',
  price: '1049.00',
  icon: <Fireplace className={styles.icon} color="primary"/>
}, {
  id: 4,
  name: 'Shopping Baskets',
  currencyIcon: '$',
  price: '39.99',
  icon: <ShoppingCart className={styles.icon} color="primary"/>
}, {
  id: 5,
  name: 'Moon Rocket',
  currencyIcon: '$',
  price: '11.99',
  icon: <Star className={styles.icon} color="primary"/>
}];

export default function OrderEquipment() {
  useEffect(() => {
    const subheader = document.getElementById('subheader-title');
    const arrowBack = document.getElementById('subheader-back-button');

    const relatedItem = { name: 'FreshPay Smart Market' };

    if (relatedItem) {
      // Manually set subheader
      subheader.innerText = `Equipment (${relatedItem.name})`;

      // Show back button
      arrowBack.style.display = 'block';
    }

    return () => {
      arrowBack.style.cssText = '';
    };
  }, []);

  function renderEquipmentCard(item) {
    const { id, icon, name, price, currencyIcon } = item;

    return (
      <div id={`order_equipment_outer_wrapper`} className="col-3">
        <Card id={`order_equipment_card_wrapper`} key={id} variant="outlined">
          <CardContent id={`order_equipment_card_content_wrapper`} className="text-center">
            {icon}
            <Typography id={`order_equipment_name_typography`} variant="h5" component="h2">
              {name}
            </Typography>
          </CardContent>
          <CardActions id={`order_equipment_card_action_wrapper`}>
            <div id={`order_equipment_card_action_content_wrapper`} className="col-6 text-center">
              <Typography id={`order_equipment_currency_typography`} variant="h6" color="primary">
                {currencyIcon}
                {price}
              </Typography>
            </div>
            <div id={`order_equipment_action_order_button_wrapper`}  className="col-6">
              <Button
                id={`order_equipment_action_order_button`}
                color="primary"
                startIcon={<AddShoppingCart/>}
                fullWidth
                size="medium"
                onClick={() => {}}
              >
                Order
              </Button>
            </div>
          </CardActions>
        </Card>
      </div>
    );
  }

  return (
    <div id={`order_equipment_root_card_wrapper`} className="d-flex flex-wrap">
      {equipment.map(renderEquipmentCard)}
    </div>
  );
}

/**
 * Empty deal object to fill with data on deal creation
 */
export const emptyDeal = {
  priceAdjustMaxUnits: null,
  actionLimits: {
    amount: null,
    quantity: null
  },
  id: null,
  name: '',
  description: '',
  image: null,
  tags: null,
  couponCode: null,
  canEarnLoyalty: false,
  status: null,
  startDate: null,
  endDate: null,
  targetAudienceType: null,
  frequencyMetric: null,
  frequencyQuantity: null,
  frequencyMaxUse: null,
  maxUse: null,
  maxTotalUse: null,
  maxTotalUseCounter: null,
  promoOverlap: null,
  conditionTargetType: null,
  thresholdAmount: null,
  thresholdQuantity: null,
  actionTargetType: null,
  actionTargetLimitAmount: null,
  actionTargetLimitQuantity: null,
  discountType: null,
  discountAmount: null,
  discountAmountCurrency: null,
  combo: null,
  priceAdjustMinBundleSize: 3,
  priceAdjustUnitAmount: '1',
  priceAdjustBundleStep: 3,
  parentDealId: null,
  prevDealId: null,
  nextDealId: null,
  locations: [],
  products: []
};

// promotion Frequency
export const promotionPerOptions = [
  { label: 'Day', value: 'day' },
  { value: 'week', label: 'Week' },
  { value: 'month', label: 'Month' },
  { value: 'year', label: 'Yer' }
];

/**
 * Available deal types
 */
export const availableDealTypes = [
  {
    id: 1,
    title: 'Basic',
    variant: 'h5',
    hasDivider: true,
    description: 'Offer customers a discount.',
    value: 'basic'
  },
  {
    id: 2,
    title: 'Advanced',
    variant: 'h5',
    hasDivider: true,
    description: 'Offer customers a discount or gift, based on what they buy or how much they spend.',
    value: 'advanced'
  }
];

/**
 * Available target audience options
 */
export const availableTargetAudience = [
  {
    id: 1,
    title: 'Available to Everyone',
    variant: 'h5',
    hasDivider: true,
    description: 'All customers can receive this promotion.',
    value: 'ALL'
  },
  {
    id: 2,
    title: 'Exclusive to Some',
    variant: 'h5',
    hasDivider: true,
    description: 'Only customers within a customer group, or with a promo code, can redeem this promotion.',
    value: 'exclusive'
  }
];

/**
 * Frequency configuration options
 */
export const availableFrequencyTypes = [
  {
    id: 1,
    title: 'None',
    variant: 'h5',
    hasDivider: true,
    description: 'This promotion has no use limits.',
    value: 'none'
  },
  {
    id: 2,
    title: 'Customer',
    variant: 'h5',
    hasDivider: true,
    description: 'Limit promotion based on customer usage.',
    value: 'customer'
  },
  {
    id: 3,
    title: 'Order',
    variant: 'h5',
    hasDivider: true,
    description: 'Limit promotion based on per order usage.',
    value: 'promotion'
  }
];

/**
 * Discount type by the received bonus
 */
export const predefinedDiscounts = [
  {
    id: 1,
    title: '%',
    value: 'monetaryPercent'
  },
  {
    id: 2,
    title: '$',
    value: 'monetaryAbsolute'
  }
];

/**
 * Promotion target options
 */
export const dealAffectiveScope = [
  {
    id: 1,
    title: 'All',
    value: 'ALL'
  },
  {
    id: 2,
    title: 'Specific',
    value: 'products'
  }
];

/**
 * Options for product filter select
 */
export const productFilterSelect = [
  {
    id: 1,
    title: 'Include',
    value: 'include'
  },
  {
    id: 2,
    title: 'Exclude',
    value: 'exclude'
  }
];

export const testFilledDeal = {
  priceAdjustMaxUnits: 3,
  actionLimits: {
    amount: '24',
    quantity: 12
  },
  id: '1085d53d-e15a-41cb-b2fa-2d2bfafbdf4a',
  name: 'Choco Bars',
  description: '3 bars for 3 dollars',
  image:
    'https://wichitaonthecheap.com/lotc-cms/wp-content/uploads/2014/03/little-ceasars-lunch-combo.png',
  tags: null,
  couponCode: null,
  canEarnLoyalty: false,
  AccountId: 'a5925d96-f77c-4063-9f95-fb9be78a92e9',
  status: 'active',
  startDate: '2019-09-23T01:54:02.076Z',
  endDate: '2021-09-23T01:54:02.076Z',
  targetAudienceType: 'ALL',
  frequencyMetric: 'week',
  frequencyQuantity: 2,
  frequencyMaxUse: 99,
  maxUse: null,
  maxTotalUse: null,
  maxTotalUseCounter: null,
  promoOverlap: 'ALL',
  conditionTargetType: 'Product',
  thresholdAmount: '0',
  thresholdQuantity: 0,
  actionTargetType: 'ProductAny',
  actionTargetLimitAmount: '24',
  actionTargetLimitQuantity: 12,
  discountType: 'monetaryPriceAdjust',
  discountAmount: null,
  discountAmountCurrency: 'USD',
  combo: null,
  priceAdjustMinBundleSize: 3,
  priceAdjustUnitAmount: '1',
  priceAdjustBundleStep: 3,
  parentDealId: null,
  prevDealId: null,
  nextDealId: null,
  creationDate: '2020-09-23T17:04:02.123Z',
  updatedOn: '2020-09-23T17:04:02.123Z',
  deletionDate: null,
  locations: [
    {
      id: '39a380bc-2355-4f3e-b4d2-950bacb1581b',
      LocationDeals: {
        LocationId: '39a380bc-2355-4f3e-b4d2-950bacb1581b',
        DealId: '1085d53d-e15a-41cb-b2fa-2d2bfafbdf4a',
        creationDate: '2020-09-04T04:03:59.893Z',
        updatedOn: '2020-09-04T04:05:09.123Z',
        deletionDate: null
      }
    }
  ],
  products: [
    {
      id: 'a9359c89-a730-4769-b37c-f1f4ebb84b84',
      DealId: '1085d53d-e15a-41cb-b2fa-2d2bfafbdf4a',
      type: 'operatorProductId',
      uuidkey: 'cca6613c-2789-4016-909c-f20c43ef7861',
      key: null,
      usedfor: 'condaction',
      excludeCondition: null,
      excludeAction: null,
      creationDate: '2020-09-23T13:03:01.123Z',
      updatedOn: '2020-09-23T13:04:02.139Z',
      deletionDate: null
    }
  ]
};

import React from 'react';
import { FormattedMessage } from 'react-intl';

export default function MenuItemText(props) {
  const { item, parentItem } = props;

  return (
    <>
      {item.icon && <i className={`kt-menu__link-icon ${item.icon}`}/>}

      {parentItem && parentItem.bullet === 'dot' && (
        <i id="kt-menu__link-bullet kt-menu__link-bullet--dot" className="kt-menu__link-bullet kt-menu__link-bullet--dot">
          <span/>
        </i>
      )}

      {parentItem && parentItem.bullet === 'line' && (
        <i id="kt-menu__link-bullet kt-menu__link-bullet--line" className="kt-menu__link-bullet kt-menu__link-bullet--line">
          <span/>
        </i>
      )}

      <span id="kt-menu__link-text" className="kt-menu__link-text">
        {!item.translate ? (
          item.title
        ) : (
          <FormattedMessage id={item.translate} defaultMessage={item.title}/>
        )}
      </span>

      {item.badge && (
        <span  id="kt-menu__link-badge" className="kt-menu__link-badge">
          <span id="kt-badge" className={`kt-badge ${item.badge.type}`}>
            {item.badge.value}
          </span>
        </span>
      )}

      {/* {item.submenu && <i className="kt-menu__ver-arrow la la-angle-right" />} */}
    </>
  );
}

import React, { useEffect, useState } from 'react';
import to from 'await-to-js';
import clsx from 'clsx';
import { Button, Paper, Tabs, Tab } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import csvDownload from 'json-to-csv-export';
import { Add } from '@material-ui/icons';
import { format } from 'date-fns';
import 'jspdf-autotable';
import jsPDF from 'jspdf';
import * as Sentry from '@sentry/react';

import { accessPermission } from 'app/common/helperFunction';
import { getComparator, stableSort } from 'app/services/table';
import { useCustomResponse } from 'app/hooks/useCustomResponse';
import ResponseModal from 'app/components/Helpers/ResponseModal';

import useCustomHeader from '../../../../../hooks/useCustomHeader';
import ExportAndCustomFilters from '../../../../../partials/content/ExportAndCustomFilters';
import LocationGroupsTable from './components/LocationGroupsTable/LocationGroupsTable';
import { getAccountGroups } from '../../../../../services/account';
import { getLoggedUserAccountId2,hasMultipleAccounts } from '../../../../../services/helpers/account-id';
import  OperatorSpecificModal  from '../../../../Helpers/OperatorSpecificModal';

import styles from './location-groups.module.scss';
import styles2 from '_metronic/_assets/sass/loading-text.module.scss';
import { reportFormats } from 'app/services/reports';

export default function LocationGroups() {
  const { push } = useHistory();
  const [groups, setGroups] = useState([]);
  const [operatorSpecificModalOpen, setOperatorSpecificModalOpen] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [pagination, setPagination] = useState({page: 0, rowsPerPage: 10})
  const [responseValue, setResponseValue] = useCustomResponse()

  const order = "asc";
  const orderBy = "id";

  useCustomHeader({
    staticHeader: 'Groups'
  });

  useEffect(() => {
    
    if(!accessPermission("SetupAll")){
      push({pathname: "/dashboard"});
    }

    getGroupsList();
  }, []);

  /**
   * Load all groups that has location groups
   */
  async function getGroupsList() {

    if(hasMultipleAccounts()){
      setOperatorSpecificModalOpen(true);
      return
    }

    const id = getLoggedUserAccountId2();
    if (id) {
      const [err, res] = await to(getAccountGroups(id));
   
      setIsLoaded(true);
      if (err){
        Sentry.captureException(err);
        if (!hasMultipleAccounts())
        setResponseValue({...responseValue, isMessageOpen: true, isSuccess:false, message:`Error while getting groups. ${err}`});      
        return console.error(err);
      }
      
      const sortedData = stableSort(res.data, getComparator(order, orderBy))
      setGroups(sortedData);
    }
  }

  /**
   * Handle navigation to group
   */
  function goToGroup(id, name) {
    sessionStorage.setItem('current-group-name', name);
    push({ pathname: `/setup/groups/${id}` });
  }

  function handleExport(type) {

    if(groups.length === 0) return;

    const {page, rowsPerPage} = pagination;
    let rows = groups.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

    if (type === "pdf") {
      const exportRows = rows.map((ele) => [
        ele.name ? ele.name : "",
        ele.grplocs.length === 0
          ? "All locations"
          : ele.grplocs.length === 1
          ? "1 location"
          : `${ele.grplocs.length} locations`,
      ]);

      const unit = "pt";
      const size = "A4";
      const orientation = "portrait";
      const marginLeft = 30;
      const doc = new jsPDF(orientation, unit, size);
      doc.setFontSize(15);

      const title = "Location Groups";
      const headers = [["Name", "Number of Locations"]];

      let content = {
        startY: 50,
        head: headers,
        body: exportRows,
        styles: { overflow: "linebreak", fontSize: 8 },
      };

      doc.text(title, marginLeft, 30);
      doc.autoTable(content);
      doc.save(`Location groups - ${format(new Date(), 'MM/dd/yyyy')}.${type}`);
    } else if (type === "csv") {
      const exportRows = [];
      rows.forEach((ele) => {
        let obj = {
          Name: ele.name ? ele.name : "",
          "Number of Locations":
            ele.grplocs.length === 0
              ? "All locations"
              : ele.grplocs.length === 1
              ? "1 location"
              : `${ele.grplocs.length} locations`,
        };
        exportRows.push(obj);
      });

      csvDownload(exportRows, `Location groups - ${format(new Date(), 'MM/dd/yyyy')}.${type}`);
    }
  }

  function renderAddButton() {
    return (
      <div id={`location_group_render_add_button`} className={clsx('d-flex justify-content-end', styles.btn)}>
        <Button
          startIcon={<Add/>}
          size="large"
          variant="contained"
          color="primary"
          disableElevation
          onClick={() => push({ pathname: '/setup/groups/new' })}
        >
          Add
        </Button>
      </div>
    );
  }

  return (
    <>
      <Paper id={`location_group_paper_wrapper`} className={clsx(styles.wrapper, 'd-flex justify-content-between')}>
        <Tabs
          id={`location_group_tabs_wrapper`}
          value="groups"
          onChange={() => push({ pathname: '/setup/locations' })}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab  id={`location_group_location_tab`} label="Locations" value="locations"/>
          <Tab  id={`location_group_tab_grpoup_tab`} label="Groups" value="groups"/>
        </Tabs>
        {renderAddButton()}
      </Paper>

      <div id={`location_group_search_wrapper`} className={styles.searchWrapper}>
        <ExportAndCustomFilters onExport={handleExport} newFilterTag={reportFormats.PDF}/>
      </div>

      {
        !isLoaded && <div id={`location_group_loading_container`} className={styles2.loadingContainer}>
          <p className ={styles2.loadingText}>
            Loading...
          </p>
        </div>
      }

      <div id={`location_group_table_container`}>
        <LocationGroupsTable
          rows={groups}
          onEditClick={(id, name) => goToGroup(id, name)}
          pagination={pagination}
          setPagination={setPagination}
        />
      </div>
      {operatorSpecificModalOpen && (
        <OperatorSpecificModal
          open={operatorSpecificModalOpen}
          onClose={value => setOperatorSpecificModalOpen(value)}
        />
      )}
      <ResponseModal
      isSuccess={responseValue?.isSuccess}
      message={responseValue?.message}
      open={responseValue?.isMessageOpen}
      onClose={value => setResponseValue({...responseValue, isMessageOpen:value})}
      />
    </>
  );
}

import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    outerContainer: {
      width: "100%",
      height: "calc(100% - 45px)",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      justifyContent: "flex-end",
      backgroundColor: "lightgrey",
    },
    msgArea: {
      height: "100%",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      backgroundColor: "#FFFFFF",
      padding: "0px 10px 5px 10px",
      boxSizing: "border-box",
    },
    msgControlArea: {
      height: "auto",
      maxHeight: "300px",
      width: "100%",
      display: "flex",
      flexDirection: "row",
      backgroundColor: "#ffffff",
      padding: "0px",
      border: "none",
      justifyContent: "space-between",
      alignItems: "flex-end",
      "@media (max-width: 500px)": {
        flexDirection: "column",
      }
    },
    inputFieldArea: {
      width: "calc(100% - 305px)",
      height: "100%",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      borderRadius:"10px",
      overflowY: "auto",
      "@media (max-width: 500px)": {
        width: "100%",
        marginBottom: "5px",
      }
    },
    buttonArea: {
      width: "300px",
      height: "60px",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      borderRadius:"6px",
      "@media (max-width: 500px)": {
        height: "60px",
        width: "100%",
      }
    },
    inputField: {
      width: "100%",
      height: "auto",
      backgroundColor: "#ebebeb",
      "& .MuiOutlinedInput-input": {
        fontSize: "16px",
        letterSpacing: "1px",
        fontFamily: "Rubik",
        color: "#434349"
      },
      "& .MuiOutlinedInput-root": {
        height: "auto",
        border: "none",
        borderRadius: "6px",
      },
      "&:hover": {
        border: "none",
      },
      "&:focus-within": {
        backgroundColor: "#ebebeb",
        border: "none",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
    btn: {
  
      "&.MuiButton-root": {
        height: "100%",
        width: "49.5%",
        backgroundColor: "#1997e0",
        color: "#FFFFFF",
        textTransform: "capitalize",
        fontSize: "18px",
        whiteSpace: "nowrap",
        borderRadius: "10px",
        "@media (max-width: 500px) ": {
          width: "48%",
        }
      }
  
    },
    msgBox:{
        paddingLeft:"10px",
        marginTop:"10px",
        borderRadius: "3px",
        width: "100%",
        boxShadow: "2px 2px 10px #f5f5f5",
        height: "auto",
        padding: "10px",
    },
    sender: {
        color: "#8a8a8a",
        fontSize:"1.2rem",
        fontWeight:"500"
       
    },
    msgBody: {
        color: "#424242",
        fontSize: "1.1rem",
    },
    prevButton: {
      marginBottom :"5px",
      height: "40px",
    }
  
  }));
import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
  IconButton,
  makeStyles
} from '@material-ui/core';
import { EditSharp } from '@material-ui/icons';

import { getDefaultStyles } from '../../../../../../../services/table';

const headCells = [
  { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
  { id: 'locations', numeric: false, disablePadding: false, label: 'Number of Locations' },
  { id: 'actions', numeric: false, disablePadding: false, label: 'Actions' }
];

function EnhancedTableHead() {
  return (
    <TableHead id={`location_group_table_header`}>
      <TableRow id={`location_group_table_header_row`}>
        {headCells.map(headCell => (
          <TableCell
            id={`location_group_table_header_cell_${headCell.id}`}
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles(getDefaultStyles);

export default function LocationGroupsTable(props) {
  const classes = useStyles();
  const [order] = useState('asc');
  const [orderBy] = useState('id');
  const {
    rows,
    onEditClick,
    pagination:{page,rowsPerPage}, 
    setPagination,
  } = props;

  const handleChangePage = (event, newPage) => {
    setPagination((prevValue)=>{
      return {...prevValue, page: newPage};
    })
  };

  const handleChangeRowsPerPage = event => {
    const newSize = parseInt(event.target.value, 10);
    const newP = parseInt((page * rowsPerPage + 1) / newSize) ;
    setPagination({ page: newP, rowsPerPage: newSize});
  };

  return (
    <div id={`location_group_table_root_wrapper`} className={classes.root}>
      <Paper id={`location_group_table_paper_wrapper`}  className={classes.paper}>
        <TableContainer id={`location_group_table_container_wrapper`} >
          <Table
            id={`location_group_table_inner_container`} 
            className={classes.table}
            aria-labelledby="tableTitle"
            size="small"
            aria-label="enhanced table"
          >
            <EnhancedTableHead/>
            <TableBody id={`location_group_table_body_container`} >
              {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(row => {
                  const { id, name, grplocs: groupLocations } = row;

                  return (
                    <TableRow
                      hover
                      key={id}
                      id={`location_group_table_row_${id}`} 
                    >
                      <TableCell id={`location_group_table_name_cell_${id}`} align="left" padding="normal">
                        {name}
                      </TableCell>
                      <TableCell id={`location_group_table_group_location_cell_${id}`} align="left" padding="normal">
                        {groupLocations.length === 0 ? 'All' : groupLocations.length}
                        &nbsp;
                        location
                        {groupLocations.length > 1 ? 's' : groupLocations.length === 0 ? 's' : ''}
                      </TableCell>
                      <TableCell id={`location_group_table_admin_cell_${id}`}  align="left" padding="normal">
                        {Boolean (name.includes("Admins")) ? null : 
                        <IconButton id={`location_group_table_edit_icon_butto_cell_${id}`} onClick={() => onEditClick(id, name)}>
                          <EditSharp/>
                        </IconButton>
                        }
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          id={`location_group_table_pagination`}
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import to from "await-to-js";
import clsx from "clsx";
import {
  Divider,
  Typography,
  Card,
  CardContent,
  makeStyles,
  Avatar,
  Button,
  Box
} from "@material-ui/core";
import { PersonAddDisabledSharp } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import csvDownload from "json-to-csv-export";
import "jspdf-autotable";
import { jsPDF } from "jspdf";
import * as Sentry from "@sentry/react";

import { accessPermission } from "app/common/helperFunction";
import QRCode from "qrcode.react";
import LoadingSpinner from "app/partials/content/LoadingSpinner";
import { useCustomResponse } from "app/hooks/useCustomResponse";
import { CANCEL_TOKEN } from "app/common/constant";

import ConfirmationModal from "../Helpers/ConfirmationModal";
import ResponseModal from "../Helpers/ResponseModal";
import Search from "../../partials/content/Customers/Search";
import ExportAndCustomFilters from "../../partials/content/ExportAndCustomFilters";
import useCustomerHeader from "../../hooks/useCustomHeader";
import LocationsTable from "../Operators/Locations/components/LocationsTable/LocationsTable";
import OperatorCreate from "./components/SetupOperatorDetails/OperatorCreate";
import { deleteOperatorDetails, getOperatorDetails, getOwnerDetails } from "../../services/account";
import { getLocations } from "../../services/locations";
import { getLoggedUserAccountId } from "../../services/helpers/account-id";

import styles from "./operator-details.module.scss";
import { PAGINATION_INITIAL_STATE } from "app/common/utils/generic";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    textAlign: "center",
    marginRight: 20,
  },
  icon: {
    fontSize: 44,
    marginBottom: 20,
  },
});

export default function OperatorDetails() {
  const classes = useStyles();
  const { push, goBack } = useHistory();
  const [searchQuery, setSearchQuery] = useState("");
  const { id, isNew, subHeader, setSubHeader } = useCustomerHeader({
    property: "name",
    isCreationAllowed: true,
    createNew: "Operator",
  });
  const [currentOperator, setCurrentOperator] = useState({
    id: "",
    firstName: "",
    lastName: "",
    paymentsMerchantId: "",
    phone: "",
    email: "",
    address: "",
    provider: "",
    processor: "",
    loyaltyProgram: ""
  });
  const [newOperator, setNewOperator] = useState({
    firstName: "",
    lastName: "",
    password: "",
    parent: {
      id: -1,
      name: "No parent",
    },
    email: "",
    enabled: true,
    role: {
      id: 2,
      name: "Admin",
    },
    outlet: {
      id: -1,
      name: "All Outlets",
    },
  });
  const [locations, setLocations] = useState([]);

  const [locationId, setLocationId] = useState("");
  const [currentOwner, setCurrentOwner] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
  });
  const [showQrCode, setShowQrCode] = useState(false);
  const [isLoaded, setIsloaded] = useState(false);
  const [responseValue, setResponseValue] = useCustomResponse();
  const [deactivated, setDeactivated] = useState(false);
  const [pagination, setPagination] = useState(PAGINATION_INITIAL_STATE);
  const [totalCount, setCount] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      const id = getLoggedUserAccountId();
    }, 500);

    if (id) getOwnerInfo(id);
  }, []);


  //Fetching owner details
  function getOwnerInfo(accountId) {
    getOwnerDetails(accountId)
      .then((res) => {
        if (res?.data?.data) {
          setCurrentOwner({
            firstName: res?.data?.data?.firstName || "",
            lastName: res?.data?.data?.lastName || "",
            phone: res?.data?.data?.phone || "",
            email: res?.data?.data?.email || "",
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  }
  /**
   * Fetch all data on component first rendering
   */
  useEffect(() => {
    if (!accessPermission()) {
      push({ pathname: "/dashboard" });
    }

    if (!isNew) {
      getCurrentOperatorUser();
      getLocationsList();
    }
    // eslint-disable-next-line
  }, []);

  /**
   * Fetch all data on component first rendering
   */
  useEffect(() => {
    if (!isNew) {
      getLocationsList();
    }
    // eslint-disable-next-line
  }, [searchQuery,pagination]);

  /**
   * Get current operator related info by user id
   * @returns {Promise<void>}
   */
  async function getCurrentOperatorUser() {
    const [err, res] = await to(getOperatorDetails(id));

    if (err) {
      Sentry.captureException(err);
      setResponseValue({...responseValue, isMessageOpen: true, isSuccess:false, message:`Error while getting operator details. ${err}`});    
      return console.error(err);
    }

    const currentOperator = res.data;

    if (currentOperator && Object.keys(currentOperator).length) {
      currentOperator.enabled = true;
      currentOperator.email = "test@mail.com";
      currentOperator.phone = "7438563875";
    }

    // Manually set subheader
    const tempSubHeader = subHeader;
    tempSubHeader.innerText = currentOperator.name;
    setSubHeader(tempSubHeader);

    setCurrentOperator(currentOperator);
  }

  /**
   * Get selected type of export data
   */
  async function getCurrentOperatorExport(type) {
    const [err, res] = await to(
      getLocations(true, false, searchQuery, false, id)
    );

    if (err) {
      Sentry.captureException(err);
      return console.error("Export data was not fetched: ", err);
    }

    const rows = res.data.Locations;

    if (type === "pdf") {
      const exportRows = rows.map((ele) => [
        ele.name ? ele.name : "",
        ele.kiosks && ele.kiosks.length ? "-" : ele.connected ? "Yes" : "No",
        ele.kiosks && ele.kiosks.length ? "-" : ele.power ? "Yes" : "No",
      ]);

      const unit = "pt";
      const size = "A4";
      const orientation = "portrait";
      const marginLeft = 30;
      const doc = new jsPDF(orientation, unit, size);
      doc.setFontSize(15);

      const title = "Operators Locations";
      const headers = [["Location Name", "Connected", "Power"]];

      let content = {
        startY: 50,
        head: headers,
        body: exportRows,
        styles: { overflow: "linebreak", fontSize: 8 },
      };

      doc.text(title, marginLeft, 30);
      doc.autoTable(content);
      doc.save(`Operators Locations - ${new Date()}.${type}`);
    } else if (type === "csv") {
      const exportRows = [];
      rows.forEach((ele) => {
        let obj = {
          "Location Name": ele.name ? ele.name : "",
          Connected:
            ele.kiosks && ele.kiosks.length
              ? "-"
              : ele.connected
              ? "Yes"
              : "No",
          Power:
            ele.kiosks && ele.kiosks.length ? "-" : ele.power ? "Yes" : "No",
        };
        exportRows.push(obj);
      });

      csvDownload(exportRows, `Operators locations - ${new Date()}.${type}`);
    }
  }

  /**
   * Get list of locations related to this account
   * @returns {Promise<void>}
   */
  async function getLocationsList() {
    const [err, res] = await to(
      getLocations(true, false, searchQuery, false, id, pagination?.page, pagination?.limit)
    );

    if (err) {
      if (err.message == CANCEL_TOKEN) return;
      Sentry.captureException(err);
      setResponseValue({...responseValue, isSuccess:false,message:`Error while getting location list. ${err}`});
      return console.error(err);
    }
    setCount(res?.data?.pagination?.totalRecords)
    setLocations(res?.data?.Locations);
    setIsloaded(true);
  }

  function downloadLocationQRCode(downloadLocationId) {
    setLocationId(`https://app.yokeinc.com/checkin/${downloadLocationId}`);
    setShowQrCode(true);

    setTimeout(() => {
      getQRCode(downloadLocationId);
    }, 300);
  }

  const deleteOperator = async (id)=>{
    const [err, res] = await to(deleteOperatorDetails(id));
    if (err) {
      Sentry.captureException(err);
      setResponseValue({...responseValue, isSuccess:false, isMessageOpen:true, message:`Error while deleting operator. ${err}`});
      return console.error(err);
    }
    setDeactivated(false);
    setResponseValue({...responseValue, isSuccess:true, isMessageOpen:true, message:`Operator deleted successfully.`});
  }

  function getQRCode(downloadLocationId) {
    const qrCodeURL = document
      .getElementById("qrCodeEl")
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    console.log(qrCodeURL);
    let aEl = document.createElement("a");
    aEl.href = qrCodeURL;
    aEl.download = `${downloadLocationId}_QR_Code.png`;
    document.body.appendChild(aEl);
    aEl.click();
    document.body.removeChild(aEl);
    setShowQrCode(false);
  }

  /**
   * Expand row
   * @param row
   * @param isDetailsRow
   */
  function onExpandRow(row, isDetailsRow) {
    const updatedLocations = locations.map((location) => {
      if (!isDetailsRow && location.id === row.id)
        return {
          ...location,
          isExpanded: !location.isExpanded,
        };

      if (isDetailsRow)
        return {
          ...location,
          kiosks: location.kiosks.map((detailsRow) => {
            if (detailsRow.id === row.id)
              return {
                ...detailsRow,
                isExpanded: !detailsRow.isExpanded,
              };

            return detailsRow;
          }),
        };

      return location;
    });

    setLocations(updatedLocations);
  }

  const handleClose =(value)=>{
    setResponseValue({...responseValue, isMessageOpen: value});
    goBack()
  }

  function renderGeneralInformation() {
    return (
      <Card id={`operator_details_card_root_wrapper`}>
        <CardContent id={`operator_details_card_content_root_wrapper`} className="d-flex flex-wrap">
          <div id={`operator_details_avatar_wrapper`} className={styles.card}>
            <Avatar alt="Remy Sharp" className={classes.large} />
          </div>
          <div id={`operator_details_item_wrapper`} className={styles.itemWrapper}>
            <div className={styles.item}>
              <Typography id={`operator_details_operator_label`} variant="h6">Operator ID</Typography>
              <Typography id={`operator_details_operator_value`} variant="body1">{currentOperator.id}</Typography>
            </div>
            <div className={styles.item}>
              <Typography id={`operator_details_email_label`} variant="h6">Email</Typography>
              <Typography id={`operator_details_email_value`} variant="body1">{currentOwner.email}</Typography>
            </div>
            <div className={styles.item}>
              <Typography id={`operator_details_merchant_id_label`} variant="h6">Merchant ID</Typography>
              <Typography id={`operator_details_merchant_id_value`} variant="body1">
                {currentOperator && currentOperator.paymentsMerchantId
                  ? currentOperator.paymentsMerchantId
                  : "N/A"}
              </Typography>
            </div>
            <div className={styles.item}>
              <Typography id={`operator_details_contact_name_label`} variant="h6">Contact Name</Typography>
              <Typography id={`operator_details_contact_name_value_label`} variant="body1">
                {!currentOwner.firstName
                  ? "N/A"
                  : `${currentOwner.firstName} ${currentOwner.lastName}`}
              </Typography>
            </div>
            <div className={clsx(styles.item)}>
              <Typography id={`operator_details_vms_label`} variant="h6">VMS</Typography>
              <Typography id={`operator_details_vms_value`} variant="body1">
                {currentOperator && currentOperator.provider.name
                  ? currentOperator.provider.name
                  : "N/A"}
              </Typography>
            </div>
            <div className={styles.item}>
              <Typography id={`operator_details_phone_label`} variant="h6">Phone Number</Typography>
              <Typography id={`operator_details_phone_value`} variant="body1">
                {!currentOwner.phone ? "N/A" : currentOwner.phone}
              </Typography>
            </div>
            <div className={styles.item}>
              <Typography id={`operator_details_processer_label`} variant="h6">Processor</Typography>
              <Typography id={`operator_details_processer_value`} variant="body1">
                {currentOperator && currentOperator.processor.name
                  ? currentOperator.processor.name
                  : "N/A"}
              </Typography>
            </div>
            <div className={styles.item}>
              <Typography id={`operator_details_onboard_label`} variant="h6">Onboarded Date</Typography>
              <Typography id={`operator_details_onboard_value`} variant="body1">
                {currentOperator && currentOperator.creationDate
                  ? new Date(currentOperator.creationDate).toDateString()
                  : "N/A"}
              </Typography>
            </div>
            
          </div>
        </CardContent>
        <Box sx={{
          p: 4,
        }}>
              <Button
                id={`operator_details_delete_button`}
                variant="outlined"
                style={{ color: "red", borderColor: "red" }}
                startIcon={<PersonAddDisabledSharp />}
                onClick={() => setDeactivated(!deactivated)}
              >
                Delete Operator
              </Button>
        </Box>
      </Card>
    );
  }

  let modalTitle = currentOperator?.name ? `Deactivate ${currentOperator?.name} `: "Deactivate Operator"
  return (
    <>
      {isNew ? (
        <OperatorCreate
          newOperator={newOperator}
          setNewOperator={setNewOperator}
        />
      ) : (
        <>
          {renderGeneralInformation()}

          <div id={`operator_details_wrapper`} className={styles.wrapper}>
            <Typography id={`operator_details_locations_label`} variant="h4">Locations</Typography>
            <Divider />

            <div id={`operator_details_search_wrapper`} className={styles.innerWrapper}>
              <Search
                id="operatorDetails"
                hasLabel={false}
                placeholder="Search"
                iconPosition="start"
                query={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
            <div id={`operator_details_export_custom_filter`}className={styles.innerWrapper}>
              <ExportAndCustomFilters
                onExport={(type) => getCurrentOperatorExport(type)}
              />
            </div>

            <>
              {!isLoaded ? (
                <LoadingSpinner />
              ) : locations.length ? (
                <LocationsTable
                  isDetailsRowExpandable={false}
                  rows={locations}
                  pagination={pagination}
                  onExpandRow={onExpandRow}
                  onEditRowClick={({ id }) =>
                    push({ pathname: `/locations/${id}` })
                  }
                  downloadLocationQRCode={downloadLocationQRCode}
                  totalCount={totalCount}
                  onPaginationChange={setPagination}
                />
              ) : (
                <p className="text-center">
                  {"Current Operator doesn't have any related location"}
                </p>
              )}
            </>
          </div>
        </>
      )}
      <ResponseModal
         isSuccess={responseValue?.isSuccess}
         message={responseValue?.message}
         open={responseValue?.isMessageOpen}
         onClose={(value) => handleClose(value)}
      />
      <ConfirmationModal
        title={modalTitle}
        open={deactivated}
        onClick={()=> deleteOperator(currentOperator.id)}
        onClose={()=>setDeactivated(!deactivated)}
      />
      {showQrCode && (
        <div style={{ display: "none" }}>
          <QRCode id="qrCodeEl" size={250} value={locationId} />
        </div>
      )}
    </>
  );
}

import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { customerActionTypes } from '../actions/customers.action';
import { RESET_STATE } from '../actions';

const initialCustomersState = {
  customers: {},
  customersList: [],
  selectedCustomer: {},
  lastTab: '',
  giftCards :[]
};

export const reducer = persistReducer(
  {
    storage,
    key: 'yoke-customers',
    whitelist: [
      'customers',
      'customersList',
      'selectedCustomer',
      'lastTab',
      'giftCards'
    ]
  },
  (state = initialCustomersState, action) => {
    switch (action.type) {
      case customerActionTypes.StoreCustomers: {
        let customers = {};
        const customersList = [...action.payload];

        if (action.payload && action.payload.length)
          customers = action.payload.reduce((acc, customer) => {
            const { id } = customer;

            return {
              ...acc,
              [id]: customer
            };
          }, {});

        return {
          ...state,
          customers,
          customersList
        };
      }

      case customerActionTypes.SelectActiveCustomer: {
        const id = action.payload;
        const selectedCustomer = { ...state.customers[id] };

        return {
          ...state,
          selectedCustomer: {
            ...selectedCustomer,
            pageTitle: `${selectedCustomer.firstName} ${selectedCustomer.lastName}`
          }
        };
      }

      case customerActionTypes.ClearSelectedCustomer: {
        return {
          ...state,
          selectedCustomer: {}
        };
      }

      case customerActionTypes.StoreLastActiveTab: {
        return {
          ...state,
          lastTab: action.payload
        };
      }
      case customerActionTypes.storeGiftCards:{
        return {
          ...state,
          giftCards:action.payload
        }
      }

      case RESET_STATE: {
        return initialCustomersState;
      }

      default: {
        return state;
      }
    }
  }
);

import { isEmpty } from "lodash";

export const setupLocationFilter = (locationLists, locationByID) => {
  const result = locationLists?.filter(
    (location) => location.id === locationByID.id
  );
  const location = {
    ...locationByID,
    kiosks: isEmpty(result) ? locationByID.kiosks : result[0]?.kiosks,
  };
  return location;
};

export const customerActionTypes = {
  StoreCustomers: '[Get Customers Entities] Action',
  storeGiftCards: '[Get Giftcards Entities] Action',
  SelectActiveCustomer: '[Select Active Customer from List] Action',
  ClearSelectedCustomer: '[Clear Selected Customer Property] Action',
  StoreLastActiveTab: '[Get Last Active Tab] Action',
};

export const customerActions = {
  storeCustomers: customers => ({
    type: customerActionTypes.StoreCustomers,
    payload: [...customers]
  }),
  storeGiftCards: giftCards => ({
    type: customerActionTypes.storeGiftCards,
    payload: [...giftCards]
  }),
  selectActiveCustomer: customerId => ({
    type: customerActionTypes.SelectActiveCustomer,
    payload: customerId
  }),
  clearSelectedCustomer: () => ({
    type: customerActionTypes.ClearSelectedCustomer,
    payload: null
  }),
  storeLastcustomerDetailTab: tab => ({
    type: customerActionTypes.StoreLastActiveTab,
    payload: tab
  })
};

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles,
  Menu,
  MenuItem,
  IconButton,
} from "@material-ui/core";
import { Settings } from "@material-ui/icons";
import { format } from "date-fns";
import _ from "lodash";

import { getKiosksThunk } from "app/store/thunks/dashboardKiosks.thunk";
import { getDefaultStyles } from "../../../../services/table";
import styles from "./kiosks-table.module.scss";
import {
  GLOBAL_CONNECT,
  KIOSKS_HEADER,
  TOP_PRODUCTS_HEADER,
} from "app/common/constant";
import { getLoyaltyProgram } from "_metronic/layout/MenuConfig";
import TableRowsLoader from "app/components/Helpers/Loader/TableLoader";
import { KIOSK_TITLE, TOP_PRODUCT_SOLD } from "app/common/dashboardConst";

/**
 * Build heading for a table for selected type of data
 * @param {*[]} headers
 */
function composeHeader(headers, loyaltyProgram) {
  return (
    <TableHead>
      <TableRow>
        {headers.map((column) => (
          <TableCell
            key={column.id}
            colSpan={!column.colSpan ? 1 : column.colSpan}
            align="left"
            padding={column.disablePadding ? "none" : "normal"}
          >
            {((loyaltyProgram == GLOBAL_CONNECT && column.gcSupported) ||
              loyaltyProgram != GLOBAL_CONNECT) &&
              column.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

/**
 * Sort products array by a certain type of property
 * @param {*[]} array
 * @param {'Qty' | '$$$'} sortType
 */
function sortProducts(array, sortType) {
  const sortKey = sortType === "Qty" ? "sum" : "Price";
  const newArray = [...array];

  newArray.sort((a, b) => parseFloat(b[sortKey]) - parseFloat(a[sortKey]));

  return newArray;
}

const useStyles = makeStyles(getDefaultStyles);

export default function KiosksTable(props) {
  const classes = useStyles();
  const { topProducts, topByKiosk, isKiosk, handleAction } = props;
  const [kioskPagination, setKioskPagination] = useState({
    kioskPage: 1,
    kioskLimit: 10,
  });
  const [topProductsPagination, setTopProductsPagination] = useState({
    topProductsPage: 1,
    topProductsLimit: 10,
  });
  const { topProductsPage, topProductsLimit } = topProductsPagination;
  const { kioskPage, kioskLimit } = kioskPagination;
  let topProductsStartIndex = (topProductsPage - 1) * topProductsLimit;
  let topProductsEndIndex = topProductsStartIndex + topProductsLimit;
  const [sortOrder, setSortOrder] = useState("Qty");
  const [anchorEl, setAnchorEl] = React.useState({
    target: null,
    index: -1,
  });
  const currentTable = isKiosk ? KIOSK_TITLE : TOP_PRODUCT_SOLD;
  const kiosks = useSelector((state) => state.subHeader.kiosks);
  const loyaltyProgram = useSelector(getLoyaltyProgram);

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    dispatch(
      getKiosksThunk(kioskPagination.kioskPage, kioskPagination.kioskLimit)
    )
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, [kioskPagination]);

  /**
   * Handle click on Edit icon
   * @param e
   */
  function onSettingsClick(e, i) {
    e.stopPropagation();

    setAnchorEl({
      target: anchorEl?.target ? null : e.currentTarget,
      index: i,
    });
  }

  const onSettingsClose = (action, id, LocationId) => {
    setAnchorEl(null);

    if (action) {
      handleAction(action, id, LocationId);
    }
  };

  /**
   * Handle pagination change
   * @param {Synthetic Event} param0
   * @param {number} newPage
   */
  function handlePaginationChange({ target: { value } }, newPage = -1) {
    const { kioskPage, kioskLimit } = kioskPagination;
    const { topProductsPage, topProductsLimit } = topProductsPagination;
    const page = isKiosk ? kioskPage : topProductsPage;
    const limit = isKiosk ? kioskLimit : topProductsLimit;
    if (value) {
      const newSize = parseInt(value, 10);

      const newP = parseInt(((page - 1) * limit + 1) / newSize);
      isKiosk
        ? setKioskPagination({
            kioskPage: newP + 1,
            kioskLimit: newSize,
          })
        : setTopProductsPagination({
            topProductsPage: newP + 1,
            topProductsLimit: newSize,
          });
    }
    if (newPage >= 0 && typeof newPage !== "object") {
      setKioskPagination({
        kioskPage: newPage + 1,
        kioskLimit,
      });
      setTopProductsPagination({
        topProductsPage: newPage + 1,
        topProductsLimit,
      });
    }
  }

  return (
    <>
      <div className={styles.aboveTable}>
        <Typography variant="h6">{currentTable}</Typography>
        <div className={styles.actions}>
          {!isKiosk && (
            <Button
              className={styles.spacedButton}
              variant="contained"
              color="primary"
              id="sort_By_Button"
              onClick={() => setSortOrder(sortOrder === "Qty" ? "$$$" : "Qty")}
            >
              Sort by {sortOrder === "Qty" ? "$$$" : "Qty"}
            </Button>
          )}
        </div>
      </div>
      <div className={classes.root}>
          <Paper className={classes.paper}>
            <TableContainer>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                id="kiosk_tableTitle"
                size="small"
                aria-label="enhanced table"
              >
                {composeHeader(
                  isKiosk ? KIOSKS_HEADER : TOP_PRODUCTS_HEADER,
                  loyaltyProgram
                )}
                <TableBody>
                  {!loading ? (
                    isKiosk ? (
                      kiosks.kiosk.rows.map(
                        (
                          {
                            id,
                            LocationId,
                            name,
                            OrderId,
                            KioskEndId,
                            statusDate,
                            diagnosticReport,
                          },
                          i
                        ) => (
                          <TableRow
                            id={`kiosk-table-row-${id}`}
                            key={`${OrderId}${statusDate}${KioskEndId}${20 * i -
                              1}`}
                            hover
                          >
                            <TableCell
                              id={`kiosk_table_cell_name-${id}`}
                              align="left"
                              padding="normal"
                            >
                              {!name ? "Grab and Go Kiosk" : name}
                            </TableCell>
                            <TableCell align="left" padding="normal">
                            {!_.isEmpty(diagnosticReport)
                              ? new Date() -
                                  new Date(
                                    diagnosticReport?.creationDate ?? ""
                                  ) <
                                60 * 15 * 1000
                                ? `🌐 Connected via ${
                                    diagnosticReport?.blob?.netStateType
                                ? diagnosticReport?.blob?.netStateType != "none"
                                        ? diagnosticReport?.blob?.netStateType
                                        : "cell"
                                      : diagnosticReport?.isWifi
                                      ? "WiFi"
                                      : "Cellular"
                                  }`
                                : `❌ Offline`
                              : "💭"}
                            </TableCell>
                            <TableCell align="left" padding="normal">
                            {!_.isEmpty(diagnosticReport)
                              ? diagnosticReport?.hasPower
                                ? `🔋 Getting Power - Battery ${diagnosticReport?.batteryLevel}%`
                                : `🔌 Unplugged ${diagnosticReport?.batteryLevel}%`
                              : "💭"}
                            </TableCell>
                            <TableCell
                              id={`kiosk_table_cell_hasPower-${id}`}
                              align="left"
                              padding="normal"
                            >
                              {!_.isEmpty(diagnosticReport)
                              ? format(
                                  new Date(
                                    diagnosticReport?.creationDate ?? ""
                                  ),
                                  "MM/dd/yyyy - hh:mm aa"
                                )
                              : "Never Checked In"}
                            </TableCell>
                            <TableCell
                              id={`kiosk_table_cell_action-${id}`}
                              align="left"
                              padding="normal"
                            >
                              {loyaltyProgram != "globalConnect" && (
                                <IconButton
                                  onClick={(e) => onSettingsClick(e, i)}
                                >
                                  <Settings />
                                </IconButton>
                              )}
                              <Menu
                                id={"menu"}
                                anchorEl={anchorEl?.target}
                                keepMounted={false}
                                open={
                                  Boolean(anchorEl?.target) &&
                                  i === anchorEl?.index
                                }
                                onClose={() => {
                                  setAnchorEl(null);
                                }}
                              >
                                <MenuItem
                                  onClick={() =>
                                    onSettingsClose("reboot", id, LocationId)
                                  }
                                >
                                  Reboot Kiosk
                                </MenuItem>
                              </Menu>
                            </TableCell>
                          </TableRow>
                        )
                      )
                    ) : null
                  ) : (
                    <TableRowsLoader rowsNum={isKiosk ? 10 : 0} colsNum={!isKiosk ? 3 : 5} />
                  )}

                  {!loading ? (!isKiosk
                    ? sortProducts(
                        topProducts?.slice(
                          topProductsStartIndex,
                          topProductsEndIndex
                        ),
                        sortOrder
                      ).map(
                        (
                          {
                            Name,
                            Price,
                            sum,
                            quantity,
                            OrderId,
                            OperatorProductId,
                          },
                          i
                        ) => (
                          <TableRow
                            id={`table-row-${OrderId}`}
                            key={`${OrderId}${OperatorProductId}${23 * i - 1}`}
                            hover
                          >
                            <TableCell
                              id={`table_cell_${Name}`}
                              align="left"
                              padding="normal"
                            >
                              {!Name ? "N/A" : Name}
                            </TableCell>
                            <TableCell
                              id={`table_cell_${quantity}`}
                              align="left"
                              padding="normal"
                            >
                              {!quantity ? "N/A" : quantity}
                            </TableCell>
                            <TableCell
                              id={`table_cell_${Price}`}
                              align="left"
                              padding="normal"
                            >
                              {!Price ? "N/A" : `$${Price.toFixed(2)}`}
                            </TableCell>
                          </TableRow>
                        )
                      )
                    : null):<TableRowsLoader rowsNum={!isKiosk ? 10 : 0} colsNum={!isKiosk ? 3 : 5} />}
                </TableBody>
              </Table>
            </TableContainer>
            {!loading && (isKiosk && <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={
                isKiosk ? kiosks.meta.totalItems ?? 0 : topProducts?.length ?? 0
              }
              rowsPerPage={isKiosk ? kioskLimit : topProductsLimit}
              page={isKiosk ? kioskPage - 1 : topProductsPage - 1}
              onPageChange={handlePaginationChange}
              onRowsPerPageChange={handlePaginationChange}
            />)}
          </Paper>
      </div>
    </>
  );
}

import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { salesReportActionTypes } from "../actions/salesReport.action";
import { RESET_STATE } from "../actions";

const initialSalesReportsState = {
  loading: false,
  error: "",
  data: null,
};

export const reducer = persistReducer(
  {
    storage,
    key: "yoke-salesReport",
    whitelist: ["salesReport"],
  },
  (state = initialSalesReportsState, action) => {
    switch (action.type) {
      case salesReportActionTypes.GetSalesReports: {
        return {
          ...state,
          loading: true,
        };
      }

      case salesReportActionTypes.StoreSalesReports: {
        return {
          ...state,
          loading: false,
          data: {
            ...action.payload,
          },
        };
      }

      case salesReportActionTypes.ClearSalesReports: {
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      }
      case RESET_STATE: {
        return initialSalesReportsState;
      }
      default: {
        return state;
      }
    }
  }
);

import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
  IconButton,
  makeStyles
} from '@material-ui/core';
import { EditSharp } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';

import { getDefaultStyles } from '../../../services/table';

const headCells = [
  { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
  { id: 'numberOfCustomers', numeric: false, disablePadding: false, label: 'Number of Customers' },
  { id: 'actions', numeric: false, disablePadding: false, label: 'Actions' }
];

function EnhancedTableHead() {
  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles(getDefaultStyles);

export default function GroupsTable(props) {
  const {
    rows,
    pagination = {
      page: 1,
      limit: 10
    },
    total,
    onPaginationChange
  } = props;
  const { push } = useHistory();
  const classes = useStyles();
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(pagination.page - 1);
  const [rowsPerPage, setRowsPerPage] = useState(pagination.limit);


  /**
   * Handle master toggle
   * @param {Synthetic Event} event
   */
  function handleSelectAllClick(event) {
    if (event.target.checked) {
      const newSelectedItems = rows.map(n => n.email);
      setSelected(newSelectedItems);
      return;
    }
    setSelected([]);
  }

  /**
   * Handle pagination change
   * @param {Synthetic Event} param0
   * @param {number} newPage
   */
  function handlePaginationChange({ target: { value } }, newPage = -1) {
    if (value) {
      const newSize = parseInt(value, 10);
      const newP = parseInt((page * rowsPerPage + 1) / newSize) ;

      onPaginationChange({
        page: newP + 1,
        limit: newSize
      });
      setPage(newP);
      setRowsPerPage(newSize);
    }

    if (newPage >= 0 && typeof newPage !== 'object') {
      onPaginationChange({
        page: newPage + 1,
        limit: rowsPerPage
      });
      setPage(newPage);
    }
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="small"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              onSelectAllClick={handleSelectAllClick}
              rowCount={rows.length}
            />
            <TableBody>
              { rows.map(row => {
                  const { id, name, numberOfUsers } = row;

                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={id}
                    >
                      <TableCell align="left" padding="normal" scope="row">
                        {!name ? 'N/A' : name}
                      </TableCell>
                      <TableCell align="left" padding="normal">
                        {!numberOfUsers && numberOfUsers !== 0 ? 'N/A' : numberOfUsers}
                      </TableCell>
                      <TableCell align="left" padding="normal">
                        <IconButton onClick={() => push({ pathname: `/groups/${id}` })}>
                          <EditSharp/>
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handlePaginationChange}
          onRowsPerPageChange={handlePaginationChange}
        />
      </Paper>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import clsx from "clsx";
import {
  Typography,
  Grid,
  Divider,
  TextField,
  InputAdornment,
  Box,
  IconButton,
  FormHelperText,
} from "@material-ui/core";
import Select from "react-select";
import map from "lodash/map";
import without from "lodash/without";
import { Autocomplete } from "@material-ui/lab";
import {
  getLocationsProductById,
  getLocationsProducts,
} from "app/services/products";
import to from "await-to-js";
import { Delete } from "@material-ui/icons";

import CustomButton from "../../../PromotionDetails/components/CustomButton/CustomButton";

import styles from "./advanced-deal.module.scss";
import { useStyles } from "app/components/Customers/components/Groups/components/GroupCustomers/Styles";

const conditionOptions = [
  {
    id: 1,
    label: "Choose the condition",
    value: null,
  },
  {
    id: 2,
    label: "Buys the following items",
    value: "items",
    text: "Buys",
  },
  {
    id: 3,
    label: "Spends the following amount",
    value: "amount",
    text: "Spends",
  },
];
const actionOptions = [
  {
    id: 1,
    label: "Choose a reward",
    value: null,
  },
  {
    id: 2,
    label: "Get the following items",
    value: "following_items",
    text: "Get",
  },
  {
    id: 3,
    label: "Save a certain amount",
    value: "certain_amount",
    text: "Save",
  },
];
const filters = [
  {
    label: "Include",
    value: "include",
  },
  {
    label: "Exclude",
    value: "exclude",
  },
];
const productsSwitchBtns = [
  {
    id: 1,
    title: "All",
    value: "ProductAny",
  },
  {
    id: 2,
    title: "Specific",
    value: "Product",
  },
];
const discountSwitchBtns = [
  { id: 1, title: "%", value: "monetaryPercent" },
  { id: 2, title: "$", value: "monetaryAbsolute" },
];

export default function AdvancedDeal({
  deal = {},
  setDeal,
  isNew,
  isBuyValue,
  isSpendValue, 
  isGetValue, 
  noActionProducts,
  noDiscountAmount2,
  noDiscountAmount,
  noTargetActionType,
  noConditionProducts2,
  noConditionTargetType,
  noThresholdAmount,
  noThresholdQuantity,
}) {
  const [condition, setCondition] = useState({});
  const [action, setAction] = useState(actionOptions[0]);
  const [discountType, setDiscountType] = useState("free");
  const [conditionProducts2, setConditionProducts2] = useState([]);
  const [discountAmount, setDiscountAmount] = useState([]);
  const [products2, setProducts2] = useState([]);
  const [products1, setProducts1] = useState([]);
  const bubbleStyles = useStyles();
  const [search, setSearch] = useState("");
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
  });
  const [actionProducts, setActionProducts] = useState([]);
  const [productBtnForThenTheyWill, setProductBtnAction] = useState({});
  const [productBtnForWhenACustomer, setProductBtnCustomer] = useState({});
  const [noConditionShow, setNoConditionShow] = useState(true);
  const [showAction, setShowAction] = useState(true);
  const [filters, setFilters] = useState({
    keywords: "",
    status: "Enabled",
  });

  class dealProduct {
    constructor(usedFor, uuidKey) {
      this.type = "operatorProductId";
      this.uuidkey = uuidKey;
      this.key = null;
      this.usedfor = usedFor;
      this.excludeAction = null;
      this.excludeCondition = null;
    }
  }

  function addProduct(type, product) {
    if (product == null) return;
    const newProductPayload = new dealProduct(type, product.operatorProductId);

    if (type == "action") {
      setActionProducts([...actionProducts, product]);
    } else {
      setConditionProducts2([...conditionProducts2, product]);
    }
    setSearch("");
    setDeal({ ...deal, products: [...deal.products, newProductPayload] });
  }

  useEffect(() => {
    getProducts({
      ...filters,
      keywords: search,
    });
  }, [search, deal.products]);

  async function getProducts(filters) {
    const productsRes = await to(getLocationsProducts(pagination, filters));
    const products = productsRes[1]?.data?.data?.products.length > 0 ? productsRes[1].data.data.products : []
      if(conditionProducts2.length > 0){
        const sortedProducts = products.filter(product => !conditionProducts2?.find(conditionProduct2 => (conditionProduct2.name === product.name)))
        setProducts1(sortedProducts || []);
      }else{
        setProducts1( products || []);
      }
      if(actionProducts.length > 0){
        const sortedProducts =  products.filter(product => !actionProducts?.find(actionProduct => (actionProduct.name === product.name)))
        setProducts2(sortedProducts || []);
      }else{
        setProducts2(products || []);
      }
  }

  function handleTypeSearch(value) {
    setSearch(value)
  }

  function setConditionProperty(condition) {
    setCondition(condition);
    setNoConditionShow(condition.id != 1 ? false : true);
    setDeal({
      ...deal,
      conditionTargetType:
        condition.id == 1 ? "" : condition.id == 2 ? "Product" : "AllCart",
    });
  }

  function setActionProperty(action) {
    setAction(action);
    setShowAction(action.id != 1 ? false : true);
    setDeal({
      ...deal,
      actionTargetType:
        action.id == 1 ? "" : action.id == 3 ? "AllCart" : "Product",
    });
    if (action.id == 4) {
      setDeal({ ...deal, discountType: "monetaryPriceAdjust" });
    }
  }

  useEffect(() => {
    !deal.conditionTargetType &&
      setDeal({ ...deal, conditionTargetType: "AllCart" });
    deal.conditionTargetType &&
      deal.conditionTargetType === "Product" &&
      setDeal({ ...deal, conditionTargetType: "ProductAny" });
    !deal.actionTargetType && setDeal({ ...deal, actionTargetType: "AllCart" });
    deal.actionTargetType &&
      deal.actionTargetType === "Product" &&
      setDeal({ ...deal, actionTargetType: "ProductAny" });
  }, []);

  useEffect(() => {
    productsParse();
    conditionParse();
    actionParse();
  }, [deal?.AccountId]);

  useEffect(() => {
    productBtnOfActionTargetParse();
    ProductBtnOfConditionTargetParse();
  }, [deal]);

  function conditionParse() {
    const selectCondition =
      deal.conditionTargetType === "Product" ||
      deal.conditionTargetType === "ProductAny"
        ? conditionOptions[1]
        : deal.conditionTargetType === "AllCart"
        ? conditionOptions[2]
        : conditionOptions[0];
    setCondition(selectCondition);
  }

  function actionParse() {
    if (deal.actionTargetType == "Product") {
      if (deal.discountType == "monetaryPriceAdjust") {
        setAction(actionOptions[3]);
      } else {
        setAction(actionOptions[1]);
      }
    } else if (deal.actionTargetType == "AllCart") {
      setAction(actionOptions[2]);
    } else {
      setAction(actionOptions[0]);
    }

    if (deal.discountType == "monetaryAbsolute") {
      setDiscountType("amount");
    }
    if (deal.discountType == "monetaryPercent") {
      setDiscountType("percent");
    }

    setDiscountAmount(deal.discountAmount);
  }

  function productBtnOfActionTargetParse() {
    setProductBtnAction(productsSwitchBtns[1]);
  }

  function ProductBtnOfConditionTargetParse() {
    setProductBtnCustomer(productsSwitchBtns[1]);
  }

  function productsParse() {
    if (!isNew) {
      const actionOperatorProductIds = deal.products
        ?.filter((product) => product.usedfor == "condaction" || product.usedfor === "action")
        .map((x) => x.uuidkey);
      const conditionOperatorProductIds = deal.products
        ?.filter((product) => product.usedfor == "condaction" || product.usedfor === "condition")
        .map((x) => x.uuidkey);
      if (
        actionOperatorProductIds == null &&
        conditionOperatorProductIds == null
      )
        return;

      let actionCounter = 0;
      let conditionCounter = 0;
      let actionsProducts = [];
      let conditionProducts = [];
      actionOperatorProductIds.forEach(async (id) => {
        actionCounter++;
        const [err, product] = await to(getLocationsProductById(id));

        if (err) {
          actionCounter--;
        } else {
          actionsProducts.push(product.data.data);
          actionCounter--;

          if (actionCounter === 0) {
            setActionProducts(actionsProducts);
          }
        }
      });
      conditionOperatorProductIds.forEach(async (id) => {
        conditionCounter++;
        const [err, product] = await to(getLocationsProductById(id));

        if (err) {
          conditionCounter--;
        } else {
          conditionProducts.push(product.data.data);
          conditionCounter--;

          if (conditionCounter === 0) {
            setConditionProducts2(conditionProducts);
          }
        }
      });
    }
  }

  function removeFromConditionProducts(id) {
    const products = conditionProducts2.filter(
      (value) => value.operatorProductId !== id
    );
    const dealProducts = deal.products.filter(
      (value) => value.uuidkey !== id && value.usedfor !== "action"
    );

    setDeal({ ...deal, products: dealProducts });
    setConditionProducts2(products);
  }

  function removeFromActionProducts(id) {
    const products = actionProducts.filter(
      (value) => value.operatorProductId !== id
    );

    const dealProducts = deal.products.filter(
      (value) => value.uuidkey !== id && value.usedfor !== "condition"
    );

    setDeal({ ...deal, products: dealProducts });
    setActionProducts(products);
  }

  function Bubble(props) {
    const { product, type } = props;
    return (
      <div className={bubbleStyles.bubbleContainer}>
        <div
          className="customerName"
          style={{ whiteSpace: "nowrap", textAlign: "center" }}
        >
          {`${product != null ? product.name : ""}`}
        </div>
        <IconButton
          onClick={() => {
            if (type == "action")
              removeFromActionProducts(product.operatorProductId);
            else removeFromConditionProducts(product.operatorProductId);
          }}
        >
          <Delete />
        </IconButton>
      </div>
    );
  }

  function HelperText() {
    return (
      <>
        <FormHelperText style={{ color: "red", fontSize: "1rem", paddingLeft: "30px" }}>
          This is required!
        </FormHelperText>
      </>
    );
  }

  return (
    <div id={`advance_deal_root_wrapper`}>
      <Grid id={`advance_deal_grid_container_wrapper`} container direction="column" justifyContent="center" spacing={2}>
        <Grid id={`advance_deal_grid_item_container`} item xs={12} container direction="column" alignItems="center">
          <Grid
            id={`advance_deal_wrapper_grid_container_`}
            container
            direction="row"
            alignItems="center"
            className={styles.wrapperGrid}
          >
            <Typography id={`advance_deal_advance_header_title`} variant="h6" className={styles.advancedHead}>
              When a customer
            </Typography>
            <Select
              id="advanceDeal_condition"
              value={condition}
              options={conditionOptions}
              className={clsx("basic-multi-select", styles.filter)}
              onChange={setConditionProperty}
            />
          </Grid>
          {noConditionTargetType && noConditionShow && HelperText()}
        </Grid>

        {condition.value && (
        <Grid  id={`advance_deal_advance_wrapper_grid_condition`} container direction="row" alignItems="center" className={styles.wrapperGrid}>
          <Grid id={`advance_deal_advance_item_wrapper_grid_condition`} item md={7} sm={12} xs={12} className={styles.borderBox}>
            <Grid id={`advance_deal_advance_item_wrapper_grid_row_condition`} container direction="row" justifyContent="space-between" alignItems="center">
              <Typography id={`advance_deal_advance_item_condition_text_typography`} variant="h5" className={styles.advancedHead}>{condition.text}</Typography>

              {condition.value === 'amount' && (
                <div id={`advance_deal_advance_item_condition_form_wrapper_1`}>
                <TextField
                  id="advanceDeal_amount"
                  placeholder="0.00"
                  value={deal.thresholdAmount || ""}
                  error={!isSpendValue}
                  helperText={!isSpendValue ? "Enter Valid Value" : ""}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">$</InputAdornment>
                  }}
                  variant="outlined"
                  onChange={e => {setDeal({ ...deal, thresholdAmount: e.target.value })}}
                />
                {!deal?.thresholdAmount && noThresholdAmount && HelperText()}</div>
              )}

              {condition.value === 'items' && (
                <div id={`advance_deal_advance_item_condition_form_wrapper_2`}>
                <TextField
                  id="advanceDeal_items"
                  placeholder="0"
                  variant="outlined"
                  value={deal.thresholdQuantity || ""}
                  error={!isBuyValue}
                  helperText={!isBuyValue ? "Enter Valid Quantity" : ""}
                  onChange={e => {setDeal({ ...deal, thresholdQuantity: e.target.value })}}
                />
                {!deal?.thresholdQuantity && noThresholdQuantity && HelperText()}
                </div>
              )}

                {condition.value !== "amount" && (
                  <Grid  id={`advance_deal_advance_item_condition_btn_group`}item className={styles.btnGroup}>
                  </Grid>
                )}
              </Grid>

              {deal &&
                deal.conditionTargetType !== "ProductAny" &&
                action.value !== "amount" &&
                condition.value !== "amount" && (
                  <>
                    <Divider style={{ margin: "20px 0" }} />
                    <Grid id={`advance_deal_advance_item_box_condition`} item>
                      <Box mb={1}>
                        <Typography id={`advance_deal_advance_item_box_filter_title`} variant="body1">
                          Filter products by name
                        </Typography>
                      </Box>

                      <Grid
                        id={`advance_deal_advance_bubble_wrapper`}
                        container
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        spacing={2}
                      >
                    <Grid id={`advance_deal_advance_item_bubble_wrapper`} item xs={8}>
                      {conditionProducts2.length > 0 && (<div className={bubbleStyles.bubbleBox} style={{ marginTop: "10px" }}>
                        {conditionProducts2.map((ele,index) => (
                          <Bubble product={ele} type={'condition'} key={index} />
                        ))}
                      </div>)}

                          <Autocomplete
                            required
                            id="advanceDeal_conditionProducts"
                            noOptionsText={"Searching..."}
                            fullWidth
                            options={products1}
                            getOptionLabel={(option) => option.name ?? ""}
                            onChange={(e, value) =>
                              addProduct("condition", value)
                            }
                            value={conditionProducts2 || ""}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                required
                                id="advanceDeal_conditionProductsInput"
                                label="Add product"
                                placeholder="Type to search products"
                                multiline
                                fullWidth
                                variant="outlined"
                                onChange={(e) => handleTypeSearch(e.target.value)}
                                onClick={(e) => handleTypeSearch(e.target.value)}
                                helperText={noConditionProducts2 && !(conditionProducts2.length > 0) && HelperText()}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                )}
              <Grid
                id={`advance_deal_advance_item_bubble_wrapper_container`}
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              />
            </Grid>
          </Grid>
        )}

        <Grid id={`advance_deal_advance_item_select_wrapper_container`} item xs={12} container direction="column" alignItems="center">
          <Grid
            id={`advance_deal_advance_item_select_row_container`}
            container
            direction="row"
            alignItems="center"
            className={styles.wrapperGrid}
          >
            <Typography id={`advance_deal_advance_item_select_typography`} className={styles.advancedHead} variant="h6">
              Then they will
            </Typography>
            <Select
              id="advanceDeal_action"
              required
              value={action}
              options={actionOptions}
              className={clsx("basic-multi-select", styles.filter)}
              onChange={setActionProperty}
            />
          </Grid>
          {noTargetActionType && showAction && HelperText()}
        </Grid>

        {action.value && (
        <Grid id={`advance_deal_advance_custom_button_wrapper`} container direction="row" alignItems="center" className={styles.wrapperGrid}>
          <Grid  id={`advance_deal_advance_item_custom_button_inner_wrapper`}  item xs={7} className={styles.borderBox}>
            <Grid  id={`advance_deal_advance_item_custom_button_inner_container_wrapper`}  container direction="row" justifyContent="space-between" alignItems="center">
              <Typography  id={`advance_deal_advance_header_typography`}  variant="h5" className={styles.advancedHead}>{action.text}</Typography>

                {action.value === "following_items" && (
                  <>
                    <Grid  id={`advance_deal_advance_item_custom_button_discount_wrapper`}  item className={styles.btnGroup}>
                      {map(discountSwitchBtns, (btn) => (
                        <CustomButton
                          {...btn}
                          isActive={deal.discountType === btn.value}
                          onClick={(value) =>
                            setDeal({ ...deal, discountType: value })
                          }
                          key={btn.id}
                          hasMinWidth={false}
                          hasDivider={false}
                          small
                        />
                      ))}
                    </Grid>

                    {discountType === "free" && (
                      <div  id={`advance_deal_advance_item_custom_button_discount_type_wrapper`} >
                      <TextField
                        id="advanceDeal_discountAmount"
                        InputProps={{
                          endAdornment: <InputAdornment position="end">{ discountType === 'amount' ? '$' : '%'}</InputAdornment>
                        }}
                        error={!isGetValue}
                        helperText={!isGetValue ? "Enter Valid Value" : ""}
                        placeholder="0.00"
                        variant="outlined"
                        value={deal?.discountAmount || ""}
                        onChange={(e) =>
                          setDeal({ ...deal, discountAmount: e.target.value })
                        }
                      />
                      {!(deal?.discountAmount) && noDiscountAmount && HelperText()}
                      </div>
                    )}

                    {discountType !== "free" && (
                      <>
                      <div  id={`advance_deal_advance_item_custom_button_discount_type_wrapper_1`}>
                        <TextField
                          id="advanceDeal_discountAmount"
                          required
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {discountType === "amount" ? "$" : "%"}
                              </InputAdornment>
                            ),
                          }}
                          placeholder="0.00"
                          variant="outlined"
                          value={deal.discountAmount || null}
                          error={!isGetValue}
                          helperText={!isGetValue ? "Enter Valid Amount" : ""}
                          onChange={(e) =>
                            setDeal({ ...deal, discountAmount: e.target.value })
                          }
                        />
                      {!(deal.discountAmount) && noDiscountAmount && HelperText()}
                      </div>
                      </>
                    )}
                    <Grid  id={`advance_deal_advance_item_custom_button_discount_type_wrapper_2`} item className={styles.btnGroup}>
                    </Grid>
                  </>
                )}
                {action.value === "certain_amount" && (
                  <>
                    <Grid  id={`advance_deal_advance_item_custom_button_discount_type_wrapper_3`} item className={styles.btnGroup}>
                      {map(without(discountSwitchBtns), (btn) => (
                        <CustomButton
                          {...btn}
                          isActive={deal.discountType === btn.value}
                          onClick={(value) =>
                            setDeal({ ...deal, discountType: value })
                          }
                          key={btn.id}
                          hasMinWidth={false}
                          hasDivider={false}
                          small
                        />
                      ))}
                    </Grid>
                    <Grid  id={`advance_deal_advance_item_custom_button_discount_type_wrapper_4`} item>
                      <Grid  id={`advance_deal_advance_item_custom_button_discount_type_wrapper_5`} container direction="row" alignItems="center">
                      <div  id={`advance_deal_advance_item_custom_button_discount_type_wrapper_6`}>
                        <TextField
                          required
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">$</InputAdornment>
                            ),
                          }}
                          id="advanceDeal_discountAmount"
                          placeholder="0.00"
                          value={deal.discountAmount || null}
                          variant="outlined"
                          onChange={(e) =>
                            setDeal({ ...deal, discountAmount: e.target.value })
                          }
                          error={!isGetValue}
                          helperText={!isGetValue ? "Enter Valid Value" : ""}
                        />
                        </div>
                        <Box
                          ml={2}
                          variant="body1"
                          className={styles.advancedHead}
                        >
                          off the entire sale.
                        </Box>
                      </Grid>
                      {!(deal.discountAmount) && noDiscountAmount2 && HelperText()}
                    </Grid>
                  </>
                )}
                {action.value === "fixed_price" && (
                  <>
                    <TextField
                      id="advanceDeal_thresholdAmount"
                      placeholder="0"
                      value={deal.thresholdAmount || null}
                      variant="outlined"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">$</InputAdornment>
                        ),
                      }}
                    />
                    <Grid  id={`advance_deal_advance_item_custom_button_discount_type_wrapper_7`} item>
                      <Grid  id={`advance_deal_advance_item_custom_button_discount_type_wrapper_8`} container direction="row" alignItems="center">
                        <TextField
                          id="advanceDeal_discountAmount"
                          InputProps={{ endAdornment: <InputAdornment position="end">$</InputAdornment> }}
                          placeholder="0.00"
                          value={deal.discountAmount || null}
                          variant="outlined"
                          error={!isGetValue}
                          helperText={!isGetValue ? "Enter Valid Amount" : ""}
                          onChange={e => setDeal({ ...deal, discountAmount:e.target.value})}
                        />
                        <Box ml={2} variant="body1" className={styles.advancedHead}>
                          off the entire sale.
                        </Box>
                      </Grid>
                      {!(deal.discountAmount) && noDiscountAmount2 && HelperText()}
                    </Grid>
                    <div />
                  </>
                )}
              </Grid>

              {deal &&
                deal.actionTargetType !== "ProductAny" &&
                action.value === "following_items" && (
                  <>
                    <Divider style={{ margin: "20px 0" }} />
                    <Grid  id={`advance_deal_advance_item_custom_button_discount_type_wrapper_9`} item>
                      <Box mb={1}>
                        <Typography variant="body1">
                          Filter products by name
                        </Typography>
                      </Box>

                      <Grid
                         id={`advance_deal_advance_item_custom_button_discount_type_wrapper_01`}
                        container
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        spacing={2}
                      >
                        
                    <Grid  id={`advance_deal_advance_item_custom_button_discount_type_wrapper_02`} item xs={8}>
                      {actionProducts.length > 0 && (<div className={bubbleStyles.bubbleBox} style={{ marginTop: "10px" }}>
                        {actionProducts.map((ele,index) => (
                          <Bubble product={ele} type={'action'} key={index} />
                        ))}
                      </div>)}

                          <Autocomplete
                            required
                            id="advanceDeal_actionProducts"
                            noOptionsText={"Searching..."}
                            fullWidth
                            options={products2}
                            getOptionLabel={(option) => option.name ?? ""}
                            onChange={(e, value) => addProduct("action", value)}
                            value={actionProducts || ""}
                            renderInput={(params) => (
                              <TextField
                                required
                                {...params}
                                id="advanceDeal_actionProductsInput"
                                label="Add product"
                                placeholder="Type to search products"
                                multiline
                                fullWidth
                                variant="outlined"
                                onChange={(e) => handleTypeSearch(e.target.value)}
                                onClick={(e) => handleTypeSearch(e.target.value)}
                                helperText={noActionProducts && !(actionProducts.length > 0) && HelperText()}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                )}
            </Grid>
          </Grid>
        )}
      </Grid>
    </div>
  );
}

import React, { useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import {  Visibility } from "@material-ui/icons";

import {
  getDefaultStyles,
} from "../../../../services/table";
import { getScope } from "app/services/helpers/account-id";
import { ROLE } from "app/common/roleConstant";

const headCells = [
  { id: "location", numeric: false, disablePadding: false, label: "Location" },
  { id: "kiosk", numeric: false, disablePadding: false, label: "Kiosk" },
  {
    id: "totalYokeCashCollected",
    numeric: false,
    disablePadding: false,
    label: "Total Yoke Cash Collected",
  },
  {
    id: "amountUncollected",
    numeric: false,
    disablePadding: false,
    label: "Amount Uncollected",
  },
  {
    id: "lastCollected",
    numeric: false,
    disablePadding: false,
    label: "Last Collected",
  },
  { id: "actions", numeric: false, disablePadding: false, label: "Actions" },
];
const idHeading = { id: "id", numeric: false, disablePadding: false, label: "Id" };

function EnhancedTableHead() {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles(getDefaultStyles);

export default function BillValidatorsTable(props) {
  const {
    rows,
    pagination:{page,limit:rowsPerPage},
    cashCollectionsTotal,
    onEditRowClick,
    onPaginationChange,
  } = props;
  const classes = useStyles();

  const isSuperAdmin = getScope() === ROLE.SUPERADMIN
  useEffect(() => {
    if (isSuperAdmin && headCells.length === 6) {
      headCells.unshift(idHeading);
    }else if (!isSuperAdmin && headCells.length === 7) {
      headCells.shift();
    }
  }, []);

  /**
   * Handle pagination value change
   */
  function handlePaginationChange({ target: { value } }, newPage = -1) {
    if (value) {
      const newSize = parseInt(value, 10);
      const newP = parseInt(((page-1) * rowsPerPage + 1) / newSize);

      onPaginationChange({
        page: newP + 1,
        limit: newSize,
      });
    }

    if (newPage >= 0 && typeof newPage !== "object") {
      onPaginationChange({
        page: newPage + 1,
        limit: rowsPerPage,
      });
    }
  }

  return (
    <div id="bill_validators_table_root_wrapper" className={classes.root}>
      <Paper id="bill_validators_table_paper_wrapper" className={classes.paper}>
        <TableContainer id="bill_validators_table_container_wrapper">
          <Table
            id="bill_validators_table_wrapper"
            className={classes.table}
            aria-labelledby="tableTitle"
            size="small"
            aria-label="enhanced table"
          >
            <EnhancedTableHead />
            <TableBody id="bill_validators_table_body_wrapper">
              {rows.map((row) => {
                const { id, LocationName, KioskName, amountUnCollected, amountCollected, lastCollected } = row;

                return (
                  <TableRow id={`bill_validators_table_row_${id}`} hover key={id}>
                   {isSuperAdmin && <TableCell id={`bill_validators_table_id_cell_${id}`} align="left" padding="normal">{id}</TableCell>}
                    <TableCell id={`bill_validators_table_location_name_cell_${id}`} align="left" padding="normal">{LocationName}</TableCell>
                    <TableCell id={`bill_validators_table_kiosk_name_cell_${id}`} align="left" padding="normal">{KioskName}</TableCell>
                    <TableCell id={`bill_validators_table_amount_collected_cell_${id}`} align="left" padding="normal">{`$ ${amountCollected ?? "N/A"}`}</TableCell>
                    <TableCell id={`bill_validators_table_amount_uncollected_cell_${id}`} align="left" padding="normal">{`$ ${amountUnCollected ?? "N/A"}`}</TableCell>
                    <TableCell id={`bill_validators_table_last_collected_cell_${id}`} align="left" padding="normal">{lastCollected ?? "N/A"}</TableCell>
                    <TableCell id={`bill_validators_table_button_cell_${id}`} align="left" padding="normal">
                      <IconButton onClick={() => onEditRowClick(id)}>
                      <Visibility/>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          id={`bill_validators_table_pagination`}
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={cashCollectionsTotal}
          rowsPerPage={rowsPerPage}
          page={page-1}
          onPageChange={handlePaginationChange}
          onRowsPerPageChange={handlePaginationChange}
        />
      </Paper>
    </div>
  );
}

/**
 * @typedef useState
 * @method {import('react').useState} useState
*/
import { useState } from "react";

/** 
 * @type {initialState}
  */
const initialState = {
  message: "",
  isSuccess: false,
  isMessageOpen: false,
  isServerError: false
}
/** 
 * @param {initialState} props 
 * */
export const useCustomResponse = () => {
  const [responseValue, setResponseValue] = useState(initialState);
  return [responseValue,  setResponseValue];
};

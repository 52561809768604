import { all } from 'redux-saga/effects';
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import * as authReducer from './auth.reducer';
import * as promotionsReducer from './promotions.reducer';
import * as ledgerReducer from './ledger.reducer';
import * as customerReducer from './customers.reducer';
import * as fromActions from '../actions';
import * as subHeaderReducer from './subHeader.reducer';
import * as setupLocationReducer from './setupLocation.reducer';
import * as salesReportReducer from './salesReport.reducer';
import * as topProductsReducer from './topProduct.reducer';
import * as userTransactionReportReducer from './userTransactionReport.reducer';
import * as fundsReportReducer from './fundsReport.reducer';
import { metronic } from '../../../_metronic';




const appReducer = combineReducers({
  /* your app’s top-level reducers */
  auth: authReducer.reducer,
  i18n: metronic.i18n.reducer,
  builder: metronic.builder.reducer,
  promotions: promotionsReducer.reducer,
  ledgers: ledgerReducer.reducer,
  customers: customerReducer.reducer,
  subHeader: subHeaderReducer.reducer,
  setupLocation: setupLocationReducer.reducer,
  salesReports: salesReportReducer.reducer,
  topProducts: topProductsReducer.reducer,
  userTransactionReports: userTransactionReportReducer.reducer,
  fundsReports: fundsReportReducer.reducer,
  form: formReducer
})

export const rootReducer = (state, action) => {
  return appReducer(state, action)
}

export function* rootSaga() {
  yield all([fromActions.saga()]);
}

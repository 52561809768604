import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import to from "await-to-js";
import { format } from "date-fns";
import * as Sentry from "@sentry/react";

import { getStartDateAndEndDateInISOFormat } from "app/services/helpers/getDefaultParameters";
import { getKiosksThunk } from "app/store/thunks/dashboardKiosks.thunk";
import { accessPermission } from "app/common/helperFunction";
import { getScope } from "app/services/helpers/account-id";
import {
  averageItemsPerSaleObj,
  averageSaleObj,
  grossProfitObj,
  platformInsightsObj,
  revenueObj,
} from "app/common/dashboardConst";
import {
  CANCEL_TOKEN,
  ALL_OPERATORS,
  FILTERS,
  ALL_LOCATIONS,
} from "app/common/constant";
import { useCustomResponse } from "app/hooks/useCustomResponse";
import { ROLE } from "app/common/roleConstant";

import {
  Portlet,
  PortletBody,
  PortletHeader,
} from "../../partials/content/Portlet";
import { metronic } from "../../../_metronic";
import {
  getFundsreport,
  getSalesreportV2,
  getUsertransactionreport,
} from "../../services/reports";
import KiosksTable from "./components/KiosksTable/KiosksTable";
import WelcomeText from "../../partials/layout/WelcomeText";
import LineChart from "./components/LineChart/LineChart";
import ResponseModal from "../Helpers/ResponseModal";
import { sendKioskCommand } from "../../services/locations";
import PlatFormInsights from "./components/platformInsights/PlatFormInsights";
import InsiteLoader from "../Helpers/Loader/InsiteLoader";
import PortletLoader from "../Helpers/Loader/PortletLoader";
import { fetchTopProducts } from "app/store/thunks/topProducts.thunk";
import { salesReportingActions } from "app/store/actions/salesReport.action";
import { userTransactionReportActions } from "app/store/actions/userTransactionReport.action";
import { fundsReportActions } from "app/store/actions/fundsreport.action";

export default function Dashboard(props) {
  const { push } = useHistory();
  const {
    filter: { period, location, operator },
  } = props;
  const [grossProfit, setGrossProfit] = useState(grossProfitObj);
  const dispatch = useDispatch();

  const [revenue, setRevenue] = useState(revenueObj);
  const [averageSale, setAverageSale] = useState(averageSaleObj);
  const [averageItemsPerSale, setAverageItemsPerSale] = useState(
    averageItemsPerSaleObj
  );
  const [platformInsights, setPlatformInsights] = useState(platformInsightsObj);
  const compressedLocations = useSelector(
    (state) => state.subHeader.compressedLocationList
  );
  const operatorList = useSelector((state) => state.subHeader.operatorList);
  const locationList = useSelector((state) => state.subHeader.locationList);
  const kiosks = useSelector((state) => state.subHeader.kiosks);
  const { loading: topProductLoader, data: topSellProduct } = useSelector(
    (state) => state.topProducts
  );

  const [topByKiosk, setTopByKiosk] = useState([]);
  const { brandColor, dangerColor, successColor, primaryColor } = useSelector(
    (state) => ({
      brandColor: metronic.builder.selectors.getConfig(
        state,
        "colors.state.brand"
      ),
      dangerColor: metronic.builder.selectors.getConfig(
        state,
        "colors.state.danger"
      ),
      successColor: metronic.builder.selectors.getConfig(
        state,
        "colors.state.success"
      ),
      primaryColor: metronic.builder.selectors.getConfig(
        state,
        "colors.state.primary"
      ),
    })
  );
  const chartOptions = useMemo(
    () => ({
      chart1: {
        color: brandColor,
        border: 3,
      },
      chart2: {
        color: dangerColor,
        border: 3,
      },
      chart3: {
        color: successColor,
        border: 3,
      },
      chart4: {
        color: primaryColor,
        border: 3,
      },
    }),
    [brandColor, dangerColor, primaryColor, successColor]
  );

  const [isLoaded, setIsLoaded] = useState(false);
  const [loading, setLoading] = useState({
    initialFetch: false,
    noData: false,
  });

  const [responseValue, setResponseValue] = useCustomResponse();
  useEffect(() => {
    if (getScope() === ROLE.USER) {
      push({ pathname: "/orderHistory" });
    } else if (!accessPermission("SalesAndReportingAll")) {
      setResponseValue({
        ...responseValue,
        isMessageOpen: true,
        isSuccess: false,
        message: "YOU HAVE NO ACCESS, LOGOUT IN 5 sec",
      });
      setTimeout(() => {
        push({ pathname: "/logout" });
      }, 5000);
    }
    dispatch(getKiosksThunk());
  }, []);

  /**
   * Fetch dashboard data only when location context has the value
   * fetch locationlist and operatorlist from store
   */
  useEffect(() => {
    if (compressedLocations && compressedLocations.length) {
      getLocationsList();
      getSalereports();
      getUserTransactionReports();
      getFundReports();
    }
    setIsLoaded(true)
    // eslint-disable-next-line
  }, [compressedLocations]);

  async function getLocationsList() {
    setPlatformInsights(() => {
      const tempPlatformInsights = [...platformInsights];
      tempPlatformInsights[0].value = operatorList?.length ?? 0;
      tempPlatformInsights[1].value = locationList?.length ?? 0;
      return tempPlatformInsights;
    });
  }

  const getPayload = () => {
    const timeFrame = !period ? FILTERS[3].value : period;
    const {
      startDate,
      endDate,
      selectedTimeFilter,
    } = getStartDateAndEndDateInISOFormat();
    const payload = {
      accountIds: operator,
      startDate: startDate,
      endDate: endDate,
      timeMetric: selectedTimeFilter,
    };
    /**
     * dont send locationIds if both are 1(all)
     */

    if (location !== ALL_LOCATIONS.id || operator !== ALL_OPERATORS.id) {
      payload.locationIds = location;
    }
    return payload;
  };

  async function getSalereports() {
    const timeFrame = !period ? FILTERS[3].value : period;
    const payload = getPayload();
    dispatch(fetchTopProducts(payload));
    try {
      const [err, res] = await to(getSalesreportV2(payload));
      if (err) {
        if (err.message === CANCEL_TOKEN) return;

        /**
         * handle scenario when "selected location_Id is not realated with any selected account_Id"
         */
        if (
          err?.response?.status === 400 &&
          err?.response?.data?.message === "Invalid LocationIds"
        ) {
          prepareWeekCompareData([], timeFrame);
          setIsLoaded(true);
          return;
        }
        dispatch(salesReportingActions.ClearSalesReports(err));
        Sentry.captureException(err);
        setResponseValue({
          ...responseValue,
          isMessageOpen: true,
          isSuccess: false,
          message: `Error while getting sales report data. ${err}`,
        });
        setLoading({ noData: true });
        return console.error(err);
      }
      dispatch(salesReportingActions.storeSalesReport(res?.data?.reports));
      prepareWeekCompareData(res?.data?.reports, timeFrame);
      setIsLoaded(true);
    } catch (error) {
      dispatch(salesReportingActions.ClearSalesReports(error));
      Sentry.captureException(error);
      setResponseValue({
        ...responseValue,
        isMessageOpen: true,
        isSuccess: false,
        message: `Error while getting sales report data.. ${error}`,
      });
      setLoading({ noData: true });
      setIsLoaded(true);
      return console.error(error);
    }
  }

  async function getUserTransactionReports() {
    const payload = getPayload();
    try {
      const [err, res] = await to(getUsertransactionreport(payload));
      if (err) {
        if (err.message === CANCEL_TOKEN) return;

        /**
         * handle scenario when "selected location_Id is not realated with any selected account_Id"
         */
        if (
          err?.response?.status === 400 &&
          err?.response?.data?.message === "Invalid LocationIds"
        ) {
          setIsLoaded(true);
          return;
        }
        dispatch(userTransactionReportActions.ClearUserTransactionReport(err));
        Sentry.captureException(err);
        setResponseValue({
          ...responseValue,
          isMessageOpen: true,
          isSuccess: false,
          message: `Error while getting user transaction report data. ${err}`,
        });
        setLoading({ noData: true });
        return console.error(err);
      }
      dispatch(
        userTransactionReportActions.StoreUserTransactionReport(
          res?.data?.reports
        )
      );
      setPlatformInsights((prevValue) => {
        const tempPlatformInsights = [...platformInsights];
        tempPlatformInsights[2].value = res?.data?.reports?.usersCount ?? 0;
        tempPlatformInsights[3].value = res?.data?.reports?.ordersCount ?? 0;
        return tempPlatformInsights;
      });
      setIsLoaded(true);
    } catch (error) {
      dispatch(userTransactionReportActions.ClearUserTransactionReport(error));
      Sentry.captureException(error);
      setResponseValue({
        ...responseValue,
        isMessageOpen: true,
        isSuccess: false,
        message: `Error while getting user transaction report data.. ${error}`,
      });
      setLoading({ noData: true });
      return console.error(error);
    }
  }

  async function getFundReports() {
    const payload = getPayload();
    try {
      const [err, res] = await to(getFundsreport(payload));
      if (err) {
        if (err.message === CANCEL_TOKEN) return;

        /**
         * handle scenario when "selected location_Id is not realated with any selected account_Id"
         */
        if (
          err?.response?.status === 400 &&
          err?.response?.data?.message === "Invalid LocationIds"
        ) {
          setIsLoaded(true);
          return;
        }
        dispatch(fundsReportActions.ClearFundsReport(err));
        Sentry.captureException(err);
        setResponseValue({
          ...responseValue,
          isMessageOpen: true,
          isSuccess: false,
          message: `Error while getting funds reports data. ${err}`,
        });
        setLoading({ noData: true });
        return console.error(err);
      }
      dispatch(fundsReportActions.StoreFundsReport(res?.data?.reports));
      const totalFunds = (
        res?.data?.reports?.giftCardFunds
         + res?.data?.reports?.totalFunds
      )?.toFixed(2);
      setPlatformInsights((prevValue) => {
        const tempPlatformInsights = [...platformInsights];
        tempPlatformInsights[4].value = totalFunds;
        return tempPlatformInsights;
      });
      setIsLoaded(true);
    } catch (error) {
      dispatch(fundsReportActions.ClearFundsReport(error));
      Sentry.captureException(error);
      setResponseValue({
        ...responseValue,
        isMessageOpen: true,
        isSuccess: false,
        message: `Error while getting funds reports data. ${error}`,
      });
      setLoading({ noData: true });
      return console.error(error);
    }
  }

  async function handleAction(action, id, LocationId) {
    const [err, res] = await to(sendKioskCommand(LocationId, id, action));

    if (err) {
      Sentry.captureException(err);
      setResponseValue({
        ...responseValue,
        isMessageOpen: true,
        isSuccess: false,
        message: `Error while trying to ${action} kisok. ${err}`,
      });
      return console.error(`Add-on status - failed to ${action}: , ${err}`);
    }
    setResponseValue({
      ...responseValue,
      isMessageOpen: true,
      isSuccess: true,
      message: "Action sent! This might take a few minutes",
    });
  }

  /**
   * Process general data to be represented on the dashboard
   */
  function prepareWeekCompareData({
    revenue,
    grossProfit,
    avgSale,
    avgItemsPerSale: averItems,
  }) {
    if (grossProfit) {
      const currentGrossProfit = grossProfit.currentTotals;
      const previousGrossProfit = grossProfit.previousTotals;

      /**
       * safety check if there is actual data
       */
      if (currentGrossProfit && currentGrossProfit.length)
        setGrossProfit({
          ...prepareChartData(
            currentGrossProfit.length > 1
              ? currentGrossProfit
              : previousGrossProfit.concat(currentGrossProfit),
            "value"
          ),
          ...calculateWeekDiff(
            previousGrossProfit,
            currentGrossProfit,
            "value",
            true
          ),
        });
    }

    if (revenue) {
      const currentRevenue = revenue.currentTotals;
      const previousRevenue = revenue.previousTotals;

      /**
       * safety check if there is actual data
       */
      if (currentRevenue && currentRevenue.length)
        setRevenue({
          ...prepareChartData(
            currentRevenue.length > 1
              ? currentRevenue
              : previousRevenue.concat(currentRevenue),
            "value"
          ),
          ...calculateWeekDiff(previousRevenue, currentRevenue, "value", true),
          prevData: previousRevenue.map((item) => item.revenue),
        });
    }

    if (avgSale) {
      const currentAverageSale = avgSale.currentTotals;
      const previousAverageSale = avgSale.previousTotals;

      /**
       * safety check if there is actual data
       */
      if (currentAverageSale && currentAverageSale.length)
        setAverageSale({
          ...prepareChartData(
            currentAverageSale.length > 1
              ? currentAverageSale
              : previousAverageSale.concat(currentAverageSale),
            "value"
          ),
          ...calculateWeekDiff(
            previousAverageSale,
            currentAverageSale,
            "value"
          ),
        });
    }

    if (averItems) {
      const currentAverageItemsPerSale = averItems.currentTotals;
      const previousAverageItemsPerSale = averItems.previousTotals;

      /**
       * safety check if there is actual data
       */
      if (currentAverageItemsPerSale && currentAverageItemsPerSale.length)
        setAverageItemsPerSale({
          ...prepareChartData(
            currentAverageItemsPerSale.length > 1
              ? currentAverageItemsPerSale
              : previousAverageItemsPerSale.concat(currentAverageItemsPerSale),
            "value"
          ),
          ...calculateWeekDiff(
            previousAverageItemsPerSale,
            currentAverageItemsPerSale,
            "value"
          ),
        });
    }
    setLoading({ initialFetch: true });
  }

  /**
   * Split labels from chart data
   */
  function prepareChartData(array, key) {
    return array.reduce(
      (acc, item) => {
        acc.data.push(
          parseFloat(item[key])
            .toFixed(2)
            .toString()
        );
        acc.labels.push(
          format(
            new Date(item.date),
            period === "years" ? "yyyy" : period === "months" ? "MM" : "dd"
          )
        );
        return acc;
      },
      { data: [], labels: [] }
    );
  }

  /**
   * Calculate absolute and relative values and compares them to previous period
   */
  function calculateWeekDiff(prev, curr, key, includeTotal) {
    const prevValue =
      curr.length > 1 ? curr[curr.length - 2][key] : prev[0][key];
    const total = curr.reduce((acc, item) => acc + +item[key], 0);
    const currValue = total / curr.length;
    const diff =
      ((curr[curr.length - 1][key] - prevValue) / Math.abs(prevValue)) * 100;

    if (includeTotal) return { prevValue, currValue, diff, total };

    return { prevValue, currValue, diff };
  }

  return (
    <>
      <WelcomeText />
      <>
        <div id="dashboard_container" className="row">
          <div id="dashboard_col_xl_6" className="col-xl-6">
            {isLoaded ? (
              <PlatFormInsights
                rows={
                  getScope() === ROLE.SUPERADMIN
                    ? platformInsights
                    : platformInsights.slice(1)
                }
              />
            ) : (
              <InsiteLoader colsNum={5} />
            )}

            <div id="dashboard_inner_row" className="row">
              <div
                id="dashboard_inner_wrapper"
                className="col-sm-12 col-md-12 col-lg-6"
              >
                {isLoaded ? (
                  <Portlet
                    id="kt-portlet_height_fluid_half"
                    className="kt-portlet--height-fluid-half kt-portlet--border-bottom-brand"
                  >
                    <PortletHeader title="Gross Profit" />
                    <PortletBody fluid>
                      <LineChart
                        key={Math.random()}
                        value={`$${grossProfit.currValue.toFixed(2)}`}
                        desc={`That's ${Math.abs(
                          Number.isNaN(grossProfit.diff) ? 0 : grossProfit.diff
                        ).toFixed(2)}% ${
                          grossProfit.diff < 0 ? "decrease" : "increase"
                        } from last ${period.slice(0, -1)}!`}
                        chartData={grossProfit.data}
                        color={chartOptions.chart1.color}
                        labels={grossProfit.labels}
                      />
                    </PortletBody>
                  </Portlet>
                ) : (
                  <PortletLoader />
                )}

                <div id="kt_space_20" className="kt-space-20" />

                {isLoaded ? (
                  <Portlet
                    id="kt_portlet_border_bottom_brand"
                    className="kt-portlet--height-fluid-half kt-portlet--border-bottom-brand"
                  >
                    <PortletHeader title="Revenue" />
                    <PortletBody fluid>
                      <LineChart
                        key={Math.random()}
                        value={`$${revenue.currValue.toFixed(2)}`}
                        desc={`That's ${Math.abs(
                          Number.isNaN(revenue.diff) ? 0 : revenue.diff
                        ).toFixed(2)}% ${
                          revenue.diff < 0 ? "decrease" : "increase"
                        } from last ${period.slice(0, -1)}!`}
                        chartData={revenue.data}
                        color={chartOptions.chart2.color}
                        labels={revenue.labels}
                      />
                    </PortletBody>
                  </Portlet>
                ) : (
                  <PortletLoader />
                )}
              </div>

              <div id="kt_space_21" className="col-sm-12 col-md-12 col-lg-6">
                {isLoaded ? (
                  <Portlet
                    id="kt_portlet_border_bottom_brand_2"
                    className="kt-portlet--height-fluid-half kt-portlet--border-bottom-brand"
                  >
                    <PortletHeader title="Avg. Sale Value" />
                    <PortletBody fluid>
                      <LineChart
                        key={Math.random()}
                        value={`$${averageSale.currValue.toFixed(2)}`}
                        desc={`That's ${Math.abs(
                          Number.isNaN(averageSale.diff) ? 0 : averageSale.diff
                        ).toFixed(2)}% ${
                          averageSale.diff < 0 ? "decrease" : "increase"
                        } from last ${period.slice(0, -1)}!`}
                        chartData={averageSale.data}
                        color={chartOptions.chart3.color}
                        labels={averageSale.labels}
                      />
                    </PortletBody>
                  </Portlet>
                ) : (
                  <PortletLoader />
                )}
                <div id="kt_space_21" className="kt-space-20" />

                {isLoaded ? (
                  <Portlet
                    id="kt_portlet_border_bottom_brand_3"
                    className="kt-portlet--height-fluid-half kt-portlet--border-bottom-brand"
                  >
                    <PortletHeader title="Avg. Items Per Sale" />
                    <PortletBody fluid>
                      <LineChart
                        key={Math.random()}
                        value={averageItemsPerSale.currValue.toFixed(2)}
                        desc={`That's ${Math.abs(
                          Number.isNaN(averageItemsPerSale.diff)
                            ? 0
                            : averageItemsPerSale.diff
                        ).toFixed(2)}% ${
                          averageItemsPerSale.diff < 0 ? "decrease" : "increase"
                        } from last ${period.slice(0, -1)}!`}
                        chartData={averageItemsPerSale.data}
                        color={chartOptions.chart4.color}
                        labels={averageItemsPerSale.labels}
                      />
                    </PortletBody>
                  </Portlet>
                ) : (
                  <PortletLoader />
                )}
              </div>
            </div>
          </div>

          <div id="kt_space_21" className="col-xl-6">
            <KiosksTable
              topProducts={topSellProduct}
              topByKiosk={kiosks.kiosk.rows}
              isKiosk={true}
              handleAction={handleAction}
            />
            <KiosksTable
              topProducts={topSellProduct}
              topByKiosk={topByKiosk}
              topProductLoader={topProductLoader}
              isKiosk={false}
              handleAction={handleAction}
            />
          </div>
        </div>
      </>
      <ResponseModal
        isSuccess={responseValue?.isSuccess}
        message={responseValue?.message}
        open={responseValue?.isMessageOpen}
        onClose={(value) =>
          setResponseValue({ ...responseValue, isMessageOpen: value })
        }
      />
    </>
  );
}

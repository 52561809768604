import { getLoggedUserAccountId2 } from 'app/services/helpers/account-id';
import axiosInstance from 'app/services/helpers/axiosInstance';
import axios from 'axios';

export function createGiftCard(payload) {
    let url = `/utilities/accounts/${getLoggedUserAccountId2()}/giftCards`
    return axios.post(url, payload);
  }
  
export async function updateGiftCard(payload,giftCardId) {
  let url = `/utilities/accounts/${getLoggedUserAccountId2()}/giftCards/${giftCardId}`
  let config = {
    method: 'PATCH',
    url: url,
    data: payload,
  };
  return axiosInstance.patch(url, payload,config);
}
import React from 'react';

export default function MenuSection(props) {
  const { item } = props;

  return (
    <li id="kt-menu__section" className="kt-menu__section">
      <h4 id="kt-menu__section-text" className="kt-menu__section-text">{item.section}</h4>
      <i  id="kt-menu__section-icon " className="kt-menu__section-icon flaticon-more-v2"/>
    </li>
  );
}

import React, { useState } from 'react';
import clsx from 'clsx';
import {
  Tab,
  TableRow,
  TableCell,
  Tabs,
  Button,
  Typography
} from '@material-ui/core';

import Inventory from '../ProductDetails/components/Inventory/Inventory';

import styles from './product-row-expanded.module.scss';

export default function ProductRowExpanded(props) {
  const [currentTab, setCurrentTab] = useState('inventory');
  const {
    product: {
      stocks,
      category: {
        name: categoryName
      },
      tags,
      description,
      supplier,
      cost
    },
    onDuplicateProduct
  } = props;

  return (
    <>
      <TableRow id={`product_row_expanded`}>
        <TableCell  id={`product_row_tabs_cell_expanded`} padding="default" colSpan={9}>
          <Tabs
             id={`product_row_tabs`}
            value={currentTab}
            onChange={(e, value) => setCurrentTab(value)}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab  id={`product_row_inventory_tab`} label="Inventory" value="inventory"/>
            <Tab  id={`product_row_details_tab`} label="Details" value="details"/>
          </Tabs>
          {currentTab === 'inventory' ? (
            <Inventory
              inventory={Array.isArray(stocks) ? stocks : []}
              className={styles.table}
            />
          ) : (
            <div id={`product_row_type_wrapper`} className={clsx('d-flex justify-content-between', styles.details)}>
              <div id={`product_row_type_inner_wrapper_0`}>
                <Typography id={`product_row_type_label`} variant="body1">Type</Typography>
                <Typography id={`product_row_type_value`} variant="h6">{categoryName}</Typography>
              </div>
              <div id={`product_row_type_inner_wrapper_1`}>
                <Typography id={`product_row_tags_label`}  variant="body1">Tags</Typography>
                <Typography id={`product_row_tags_value`}  variant="h6">
                  {tags?.length
                    ? tags.map(({ id, name }) => <span key={id}>{name}</span>)
                    : '-'}
                </Typography>
              </div>
              <div  id={`product_row_type_inner_wrapper_2`}>
                <Typography id={`product_row_description_label`} variant="body1">Description</Typography>
                <Typography id={`product_row_description_value`} variant="h6">{description}</Typography>
              </div>
              <div  id={`product_row_type_inner_wrapper_3`}>
                <Typography id={`product_row_supplier_label`} variant="body1">Supplier</Typography>
                <Typography id={`product_row_supplier_value`} variant="h6">{supplier}</Typography>
              </div>
              <div  id={`product_row_type_inner_wrapper_4`}>
                <Typography id={`product_row_price_label`} variant="body1">Supplier Price</Typography>
                <Typography id={`product_row_price_value`} variant="h6">
                  $
                  {cost}
                </Typography>
              </div>
            </div>
          )}
        </TableCell>
        <TableCell id={`product_row_button_cell`} padding="default" colSpan={2} className={styles.serviceBlock}>
          <Button
            id={`product_row_dublicate_button`} 
            type="button"
            color="primary"
            size="medium"
            variant="contained"
            disableElevation
            fullWidth
            onClick={onDuplicateProduct}
          >
            Duplicate Product
          </Button>
        </TableCell>
      </TableRow>
    </>
  );
}

import React, { useState, useEffect } from 'react';
import to from 'await-to-js';
import { Button } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import jsPDF from "jspdf";
import csvDownload from "json-to-csv-export";
import "jspdf-autotable";
import { format } from "date-fns";
import * as Sentry from "@sentry/react";

import LoadingSpinner from 'app/partials/content/LoadingSpinner';
import { accessPermission } from 'app/common/helperFunction';
import OperatorSpecificModal from 'app/components/Helpers/OperatorSpecificModal';
import ResponseModal from 'app/components/Helpers/ResponseModal';
import { useCustomResponse } from 'app/hooks/useCustomResponse';

import ExportAndCustomFilters from '../../../../partials/content/ExportAndCustomFilters';
import GroupsTable from '../../../../partials/content/Groups/GroupsTable';
import { getUserGroups } from '../../../../services/groups';
import { getLoggedUserAccountId2, hasMultipleAccounts } from '../../../../services/helpers/account-id';

import styles from "../../../../../_metronic/_assets/sass/pages/groups.module.scss";
import { reportFormats } from 'app/services/reports';

export function Groups(props) {
  const { filter: { locations } } = props;
  const { push } = useHistory();
  const [groups, setGroups] = useState([]);
  const [operatorSpecificModalOpen, setOperatorSpecificModalOpen] = useState(false);

  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10
  });
  const [groupsTotal, setGroupsTotal] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);

  /**
   * @function useCustomResponse sets model response
   * */ 
  const [responseValue, setResponseValue] = useCustomResponse();


  useEffect(() => {
    if(!accessPermission("CustomersAll")){
      push({pathname: "/dashboard"});
    }
  }, []);

  /**
   * Get list with all groups
   */
  useEffect(() => {
    if (locations && locations.length)
      getCustomerGroupsList();
  }, [pagination]);

  /**
   * Get list with all groups
   */
  async function getCustomerGroupsList() {
    if(hasMultipleAccounts()){
    setOperatorSpecificModalOpen(true);
    return
    }
    const id = getLoggedUserAccountId2();
    const [err, res] = await to(getUserGroups(id, pagination));

    if (err) {
      Sentry.captureException(err); 
      setResponseValue({
        ...responseValue,  
        isMessageOpen: true,
        isSuccess: false,
        message:`We had trouble fetching your groups. ${err}`
      })   
      return console.error('[Groups]. Error while fetching data.');
    }

    const { groups, meta: { totalItems } } = res.data;
    setGroupsTotal(totalItems);
    setGroups(groups);
    setIsLoaded(true);
  }

  /**
   * Request data export to a certain format
   */
  function handleExport(type) {
    if(groups.length === 0 ) return;

    if (type === "pdf") {
      const exportRows = groups.map((ele) => [
        ele.name? ele.name: '',
        ele.numberOfUsers? ele.numberOfUsers: 0,
      ]);

      const unit = "pt";
      const size = "A4";
      const orientation = "portrait";
      const marginLeft = 30;
      const doc = new jsPDF(orientation, unit, size);
      doc.setFontSize(15);

      const title = "Groups";
      const headers = [
        [
          "Name",
          "Number Of Customers",
        ],
      ];

      let content = {
        startY: 50,
        head: headers,
        body: exportRows,
        styles: { overflow: "linebreak", fontSize: 8 },
      };

      doc.text(title, marginLeft, 30);
      doc.autoTable(content);
      doc.save(`Groups - ${format(new Date(), 'MM/dd/yyyy')}.${type}`);
    } else if (type === "csv") {
      const exportRows = [];
      groups.forEach((ele) => {
        let obj = {
          Name: ele.name? ele.name: '',
          'Number Of Customers': ele.numberOfUsers? ele.numberOfUsers: 0,
        };
        exportRows.push(obj);
      });
      csvDownload(exportRows, `Groups - ${format(new Date(), 'MM/dd/yyyy')}.${type}`);
    }
  }

  return (
    <>
      <ExportAndCustomFilters onExport={handleExport} newFilterTag={reportFormats.PDF}/>
      {!isLoaded ? (
        <LoadingSpinner/>
      ) : (
      <>
        <div id="group_wrapper" className={styles.wrapper}>
          <GroupsTable
            rows={groups}
            total={groupsTotal}
            pagination={pagination}
            onPaginationChange={value => setPagination(value)}
          />
        </div>

        <div id="group_button_wrapper" className="d-flex justify-content-end">
          <Button
            startIcon={<Add/>}
            className={styles.buttonWrapper}
            id="add_group_button_wrapper"
            size="large"
            variant="contained"
            color="primary"
            onClick={() => push({ pathname: '/groups/new' })}
            disableElevation
          >
            Add Group
          </Button>
        </div>
        <ResponseModal
        isSuccess={responseValue?.isSuccess}
        message={responseValue?.message}
        open={responseValue?.isMessageOpen}
        onClose={value => setResponseValue({...responseValue,isMessageOpen:value})}
        />
      </>
      )}
      {operatorSpecificModalOpen && (
        <OperatorSpecificModal
          open={operatorSpecificModalOpen}
          onClose={value => setOperatorSpecificModalOpen(value)}
        />
      )}
    </>
  );
}

export default React.memo(Groups);

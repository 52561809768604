/* eslint-disable import/extensions */
import React from 'react';
import { useSelector } from 'react-redux';
import { IntlProvider } from 'react-intl';
// eslint-disable-next-line import/no-extraneous-dependencies
import '@formatjs/intl-relativetimeformat/polyfill';

import deMessages from './messages/de';
import enMessages from './messages/en';
import esMessages from './messages/es';
import frMessages from './messages/fr';
import jaMessages from './messages/ja';
import zhMessages from './messages/zh';

const allMessages = {
  de: deMessages,
  en: enMessages,
  es: esMessages,
  fr: frMessages,
  ja: jaMessages,
  zh: zhMessages
};

export default function I18nProvider({ children }) {
  const locale = useSelector(({ i18n }) => i18n.lang);
  const messages = allMessages[locale];

  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <IntlProvider locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  );
}

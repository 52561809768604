import React, { useEffect, useState } from 'react';
import to from 'await-to-js';
import clsx from 'clsx';
import { Button, Paper, Tab, Tabs } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import { accessPermission } from 'app/common/helperFunction';
import ResponseModal from 'app/components/Helpers/ResponseModal';

import RolesTable from './components/RolesTable/RolesTable';
import useCustomHeader from '../../../hooks/useCustomHeader';
import { getLoggedUserAccountId2, hasMultipleAccounts } from '../../../services/helpers/account-id';
import { getRolesForAccount } from '../../../services/roles';
import  OperatorSpecificModal  from '../../Helpers/OperatorSpecificModal'

import styles2 from '_metronic/_assets/sass/loading-text.module.scss';
import styles from './roles.module.scss';
import { useCustomResponse } from 'app/hooks/useCustomResponse';

export default function Roles(props) {
  const { filter: { locations } } = props;
  const { push } = useHistory();
  const [roles, setRoles] = useState([]);
  const [operatorSpecificModalOpen, setOperatorSpecificModalOpen] = useState(false);
  useCustomHeader({
    staticHeader: 'Roles'
  });
  const [isLoaded, setIsLoaded] = useState(false);
  const [responseValue, setResponseValue] = useCustomResponse();
  /**
   * Fetch roles data on component rendering
   */
  useEffect(() => {

    if(!accessPermission("SetupAll")){
      push({pathname: "/dashboard"});
    }

    console.log("get roles")
    if (locations && locations.length)
      getRolesList();
  }, []);

  /**
   * Get list of roles for current account
   * @returns {Promise<void>}
   */
  async function getRolesList() {

    if(hasMultipleAccounts()){
      setOperatorSpecificModalOpen(true);
      return
    }    

    const id = getLoggedUserAccountId2();
    const [err, res] = await to(getRolesForAccount(id));
  
    setIsLoaded(true);
    if (err){
      Sentry.captureException(err);
      if (!hasMultipleAccounts())
      setResponseValue({...responseValue, isMessageOpen: true, isSuccess:false, message:`Error while getting roles. ${err}`});    
      return console.error('Error with roles retrieving: ', err);
    }
    setRoles(res.data);
  }

  function renderAddButton() {
    return (
      <div id={`roles_add_button_wrapper`} className={clsx('d-flex justify-content-end', styles.btn)}>
        <Button
          id={`roles_add_button`}
          startIcon={<Add/>}
          size="large"
          variant="contained"
          color="primary"
          disableElevation
          onClick={() => push({ pathname: '/setup/roles/new' })}
        >
          Add
        </Button>
      </div>
    );
  }

  return (
    <>
      <Paper  id={`roles_wrapper`}className={clsx(styles.wrapper, 'd-flex justify-content-between')}>
        <Tabs
          id={`roles_tabs_wrapper`}
          value="roles"
          onChange={() => push({ pathname: '/setup/users' })}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab id={`roles_tab_users`} label="Users" value="users"/>
          <Tab id={`roles_tab_roles`} label="Roles" value="roles"/>
        </Tabs>
        {renderAddButton()}
      </Paper>

      {
        !isLoaded && <div  id={`roles_loading_container`} className={styles2.loadingContainer}>
          <p id={`roles_loading_containt`} className ={styles2.loadingText}>
            Loading...
          </p>
        </div>
      }

      <RolesTable
        rows={roles}
      />
      {operatorSpecificModalOpen && (
        <OperatorSpecificModal
          open={operatorSpecificModalOpen}
          onClose={value => setOperatorSpecificModalOpen(value)}
        />
      )}
      <ResponseModal
      isSuccess={responseValue?.isSuccess}
      message={responseValue?.message}
      open={responseValue?.isMessageOpen}
      onClose={value => setResponseValue({...responseValue, isMessageOpen:value})}
      />
    </>
  );
}

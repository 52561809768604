import React from 'react';

import MenuSection from './MenuSection';
import MenuItemSeparator from './MenuItemSeparator';
import MenuItem from './MenuItem';

export default function MenuList(props) {
  const { currentUrl, menuConfig, layoutConfig } = props;

  return menuConfig.aside.items.map((child, index) => (
    <React.Fragment id={`menuList${index + (12 * 2.1)}`} key={`menuList${index + (12 * 2.1)}`}>
      {child.section && <MenuSection item={child}/>}
      {child.separator && <MenuItemSeparator item={child}/>}
      {child.title && (
      <MenuItem
        item={child}
        currentUrl={currentUrl}
        layoutConfig={layoutConfig}
      />
      )}
    </React.Fragment>
  ));
}

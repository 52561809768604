import React from 'react'
import {TableRow, TableCell} from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'

const TableRowsLoader = ({ rowsNum, colsNum }) => {
  return [...Array(rowsNum)].map((row, index) => (
    <TableRow key={index}>
      {[...Array(colsNum)].map((col, index) => (
        <TableCell key={col + index} component="th" scope="row">
        <Skeleton animation="wave" variant="text" />
      </TableCell>
      ))}
    </TableRow>
  ));
}

export default TableRowsLoader
import React from 'react';
import {
    FormControl,
    makeStyles,
    TextField
  } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

const useStyles = makeStyles(theme => ({
    formControl: {
      margin: theme.spacing(1),
      width: 200,
    },
    selectEmpty: {
      marginTop: theme.spacing(2)
    }
}));  

const AutocompleteFilter = props => {
    const classes = useStyles();
    const {
      isFullWidth = false,
      options,
      variant = 'outlined',
      label,
      placeholder,
      TextFieldOnChange,
      addElements,
    } = props;
  
    return (
      <>
        <FormControl id={`auto_complet_filter_wrapper`} fullWidth={isFullWidth} variant={variant} className={classes.formControl}>
            <Autocomplete
            id="combo-box-demo"
            fullWidth
            options={options}
            getOptionLabel={(option) => option.name}
            onChange={(e, value) => {value && addElements(value)}}
            renderInput={(params) => (
              <TextField
                 id={`auto_complet_filter_text_field`}
                {...params}
                label={label}
                placeholder={placeholder}
                variant="outlined"
                onChange={(e) => {TextFieldOnChange(e.target.value)}}
                onClick={(e) => {TextFieldOnChange(e.target.value)}}
                fullWidth
              />
            )}
          />
        </FormControl>
      </>
    );
  };
  
  export default React.memo(AutocompleteFilter);